import "./month.scss";
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom';


function Aug() {

 const {id} = useParams();

  // Day 1
  const [starthour1, setStart1] = useState('')
  const [endhour1, setEnd1] = useState('')
  const [type1, settype1] = useState('')
  const handleSubmit1 = (event) => {
      event.preventDefault(); 
      axios.post('http://localhost:8800/aug1/'+id, {starthour1, endhour1, type1})
      .then(res => {
        window.location.reload(true);
      })
      .catch(err => console.log(err));
  }
  const [data1, set1] = useState([]);
  useEffect(() => {
    axios.get('http://localhost:8800/getaug1/'+id)
    .then(res => {
      if(res.data.Status === "Success"){
        set1(res.data.Result);
      }else{
        alert("Error")
      }
    })
    .catch(err => console.log(err));
  }, [id])

  // Day 2
  const [starthour2, setStart2] = useState('')
  const [endhour2, setEnd2] = useState('')
  const [type2, settype2] = useState('')
  const handleSubmit2 = (event) => {
      event.preventDefault(); 
      axios.post('http://localhost:8800/aug2/'+id, {starthour2, endhour2, type2})
      .then(res => {
        window.location.reload(true);
      })
      .catch(err => console.log(err));
  }
  const [data2, set2] = useState([]);
  useEffect(() => {
    axios.get('http://localhost:8800/getaug2/'+id)
    .then(res => {
      if(res.data.Status === "Success"){
        set2(res.data.Result);
      }else{
        alert("Error")
      }
    })
    .catch(err => console.log(err));
  }, [id])
  // Day 3
  const [starthour3, setStart3] = useState('')
  const [endhour3, setEnd3] = useState('')
  const [type3, settype3] = useState('')
  const handleSubmit3 = (event) => {
      event.preventDefault(); 
      axios.post('http://localhost:8800/aug3/'+id, {starthour3, endhour3, type3})
      .then(res => {
        window.location.reload(true);
      })
      .catch(err => console.log(err));
  }
  const [data3, set3] = useState([]);
  useEffect(() => {
    axios.get('http://localhost:8800/getaug3/'+id)
    .then(res => {
      if(res.data.Status === "Success"){
        set3(res.data.Result);
      }else{
        alert("Error")
      }
    })
    .catch(err => console.log(err));
  }, [id])
  // Day 4
  const [starthour4, setStart4] = useState('')
  const [endhour4, setEnd4] = useState('')
  const [type4, settype4] = useState('')
  const handleSubmit4 = (event) => {
      event.preventDefault(); 
      axios.post('http://localhost:8800/aug4/'+id, {starthour4, endhour4, type4})
      .then(res => {
        window.location.reload(true);
      })
      .catch(err => console.log(err));
  }
  const [data4, set4] = useState([]);
  useEffect(() => {
    axios.get('http://localhost:8800/getaug4/'+id)
    .then(res => {
      if(res.data.Status === "Success"){
        set4(res.data.Result);
      }else{
        alert("Error")
      }
    })
    .catch(err => console.log(err));
  }, [id])
  // Day 5
  const [starthour5, setStart5] = useState('')
  const [endhour5, setEnd5] = useState('')
  const [type5, settype5] = useState('')
  const handleSubmit5 = (event) => {
      event.preventDefault(); 
      axios.post('http://localhost:8800/aug5/'+id, {starthour5, endhour5, type5})
      .then(res => {
        window.location.reload(true);
      })
      .catch(err => console.log(err));
  }
  const [data5, set5] = useState([]);
  useEffect(() => {
    axios.get('http://localhost:8800/getaug5/'+id)
    .then(res => {
      if(res.data.Status === "Success"){
        set5(res.data.Result);
      }else{
        alert("Error")
      }
    })
    .catch(err => console.log(err));
  }, [id])
  // Day 6
  const [starthour6, setStart6] = useState('')
  const [endhour6, setEnd6] = useState('')
  const [type6, settype6] = useState('')
  const handleSubmit6 = (event) => {
      event.preventDefault(); 
      axios.post('http://localhost:8800/aug6/'+id, {starthour6, endhour6, type6})
      .then(res => {
        window.location.reload(true);
      })
      .catch(err => console.log(err));
  }
  const [data6, set6] = useState([]);
  useEffect(() => {
    axios.get('http://localhost:8800/getaug6/'+id)
    .then(res => {
      if(res.data.Status === "Success"){
        set6(res.data.Result);
      }else{
        alert("Error")
      }
    })
    .catch(err => console.log(err));
  }, [id])
  // Day 7
  const [starthour7, setStart7] = useState('')
  const [endhour7, setEnd7] = useState('')
  const [type7, settype7] = useState('')
  const handleSubmit7 = (event) => {
      event.preventDefault(); 
      axios.post('http://localhost:8800/aug7/'+id, {starthour7, endhour7, type7})
      .then(res => {
        window.location.reload(true);
      })
      .catch(err => console.log(err));
  }
  const [data7, set7] = useState([]);
  useEffect(() => {
    axios.get('http://localhost:8800/getaug7/'+id)
    .then(res => {
      if(res.data.Status === "Success"){
        set7(res.data.Result);
      }else{
        alert("Error")
      }
    })
    .catch(err => console.log(err));
  }, [id])
  // Day 8
  const [starthour8, setStart8] = useState('')
  const [endhour8, setEnd8] = useState('')
  const [type8, settype8] = useState('')
  const handleSubmit8 = (event) => {
      event.preventDefault(); 
      axios.post('http://localhost:8800/aug8/'+id, {starthour8, endhour8, type8})
      .then(res => {
        window.location.reload(true);
      })
      .catch(err => console.log(err));
  }
  const [data8, set8] = useState([]);
  useEffect(() => {
    axios.get('http://localhost:8800/getaug8/'+id)
    .then(res => {
      if(res.data.Status === "Success"){
        set8(res.data.Result);
      }else{
        alert("Error")
      }
    })
    .catch(err => console.log(err));
  }, [id])
  // Day 9
  const [starthour9, setStart9] = useState('')
  const [endhour9, setEnd9] = useState('')
  const [type9, settype9] = useState('')
  const handleSubmit9 = (event) => {
      event.preventDefault(); 
      axios.post('http://localhost:8800/aug9/'+id, {starthour9, endhour9, type9})
      .then(res => {
        window.location.reload(true);
      })
      .catch(err => console.log(err));
  }
  const [data9, set9] = useState([]);
  useEffect(() => {
    axios.get('http://localhost:8800/getaug9/'+id)
    .then(res => {
      if(res.data.Status === "Success"){
        set9(res.data.Result);
      }else{
        alert("Error")
      }
    })
    .catch(err => console.log(err));
  }, [id])
  // Day 10
  const [starthour10, setStart10] = useState('')
  const [endhour10, setEnd10] = useState('')
  const [type10, settype10] = useState('')
  const handleSubmit10 = (event) => {
      event.preventDefault(); 
      axios.post('http://localhost:8800/aug10/'+id, {starthour10, endhour10, type10})
      .then(res => {
        window.location.reload(true);
      })
      .catch(err => console.log(err));
  }
  const [data10, set10] = useState([]);
  useEffect(() => {
    axios.get('http://localhost:8800/getaug10/'+id)
    .then(res => {
      if(res.data.Status === "Success"){
        set10(res.data.Result);
      }else{
        alert("Error")
      }
    })
    .catch(err => console.log(err));
  }, [id])
  // Day 11
  const [starthour11, setStart11] = useState('')
  const [endhour11, setEnd11] = useState('')
  const [type11, settype11] = useState('')
  const handleSubmit11 = (event) => {
      event.preventDefault(); 
      axios.post('http://localhost:8800/aug11/'+id, {starthour11, endhour11, type11})
      .then(res => {
        window.location.reload(true);
      })
      .catch(err => console.log(err));
  }
  const [data11, set11] = useState([]);
  useEffect(() => {
    axios.get('http://localhost:8800/getaug11/'+id)
    .then(res => {
      if(res.data.Status === "Success"){
        set11(res.data.Result);
      }else{
        alert("Error")
      }
    })
    .catch(err => console.log(err));
  }, [id])
  // Day 12
  const [starthour12, setStart12] = useState('')
  const [endhour12, setEnd12] = useState('')
  const [type12, settype12] = useState('')
  const handleSubmit12 = (event) => {
      event.preventDefault(); 
      axios.post('http://localhost:8800/aug12/'+id, {starthour12, endhour12, type12})
      .then(res => {
        window.location.reload(true);
      })
      .catch(err => console.log(err));
  }
  const [data12, set12] = useState([]);
  useEffect(() => {
    axios.get('http://localhost:8800/getaug12/'+id)
    .then(res => {
      if(res.data.Status === "Success"){
        set12(res.data.Result);
      }else{
        alert("Error")
      }
    })
    .catch(err => console.log(err));
  }, [id])
  // Day 13
  const [starthour13, setStart13] = useState('')
  const [endhour13, setEnd13] = useState('')
  const [type13, settype13] = useState('')
  const handleSubmit13 = (event) => {
      event.preventDefault(); 
      axios.post('http://localhost:8800/aug13/'+id, {starthour13, endhour13, type13})
      .then(res => {
        window.location.reload(true);
      })
      .catch(err => console.log(err));
  }
  const [data13, set13] = useState([]);
  useEffect(() => {
    axios.get('http://localhost:8800/getaug13/'+id)
    .then(res => {
      if(res.data.Status === "Success"){
        set13(res.data.Result);
      }else{
        alert("Error")
      }
    })
    .catch(err => console.log(err));
  }, [id])
  // Day 14
  const [starthour14, setStart14] = useState('')
  const [endhour14, setEnd14] = useState('')
  const [type14, settype14] = useState('')
  const handleSubmit14 = (event) => {
      event.preventDefault(); 
      axios.post('http://localhost:8800/aug14/'+id, {starthour14, endhour14, type14})
      .then(res => {
        window.location.reload(true);
      })
      .catch(err => console.log(err));
  }
  const [data14, set14] = useState([]);
  useEffect(() => {
    axios.get('http://localhost:8800/getaug14/'+id)
    .then(res => {
      if(res.data.Status === "Success"){
        set14(res.data.Result);
      }else{
        alert("Error")
      }
    })
    .catch(err => console.log(err));
  }, [id])
  // Day 15
  const [starthour15, setStart15] = useState('')
  const [endhour15, setEnd15] = useState('')
  const [type15, settype15] = useState('')
  const handleSubmit15 = (event) => {
      event.preventDefault(); 
      axios.post('http://localhost:8800/aug15/'+id, {starthour15, endhour15, type15})
      .then(res => {
        window.location.reload(true);
      })
      .catch(err => console.log(err));
  }
  const [data15, set15] = useState([]);
  useEffect(() => {
    axios.get('http://localhost:8800/getaug15/'+id)
    .then(res => {
      if(res.data.Status === "Success"){
        set15(res.data.Result);
      }else{
        alert("Error")
      }
    })
    .catch(err => console.log(err));
  }, [id])
  // Day 16
  const [starthour16, setStart16] = useState('')
  const [endhour16, setEnd16] = useState('')
  const [type16, settype16] = useState('')
  const handleSubmit16 = (event) => {
      event.preventDefault(); 
      axios.post('http://localhost:8800/aug16/'+id, {starthour16, endhour16, type16})
      .then(res => {
        window.location.reload(true);
      })
      .catch(err => console.log(err));
  }
  const [data16, set16] = useState([]);
  useEffect(() => {
    axios.get('http://localhost:8800/getaug16/'+id)
    .then(res => {
      if(res.data.Status === "Success"){
        set16(res.data.Result);
      }else{
        alert("Error")
      }
    })
    .catch(err => console.log(err));
  }, [id])
  // Day 17
  const [starthour17, setStart17] = useState('')
  const [endhour17, setEnd17] = useState('')
  const [type17, settype17] = useState('')
  const handleSubmit17 = (event) => {
      event.preventDefault(); 
      axios.post('http://localhost:8800/aug17/'+id, {starthour17, endhour17, type17})
      .then(res => {
        window.location.reload(true);
      })
      .catch(err => console.log(err));
  }
  const [data17, set17] = useState([]);
  useEffect(() => {
    axios.get('http://localhost:8800/getaug17/'+id)
    .then(res => {
      if(res.data.Status === "Success"){
        set17(res.data.Result);
      }else{
        alert("Error")
      }
    })
    .catch(err => console.log(err));
  }, [id])
  // Day 18
  const [starthour18, setStart18] = useState('')
  const [endhour18, setEnd18] = useState('')
  const [type18, settype18] = useState('')
  const handleSubmit18 = (event) => {
      event.preventDefault(); 
      axios.post('http://localhost:8800/aug18/'+id, {starthour18, endhour18, type18})
      .then(res => {
        window.location.reload(true);
      })
      .catch(err => console.log(err));
  }
  const [data18, set18] = useState([]);
  useEffect(() => {
    axios.get('http://localhost:8800/getaug18/'+id)
    .then(res => {
      if(res.data.Status === "Success"){
        set18(res.data.Result);
      }else{
        alert("Error")
      }
    })
    .catch(err => console.log(err));
  }, [id])
  // Day 19
  const [starthour19, setStart19] = useState('')
  const [endhour19, setEnd19] = useState('')
  const [type19, settype19] = useState('')
  const handleSubmit19 = (event) => {
      event.preventDefault(); 
      axios.post('http://localhost:8800/aug19/'+id, {starthour19, endhour19, type19})
      .then(res => {
        window.location.reload(true);
      })
      .catch(err => console.log(err));
  }
  const [data19, set19] = useState([]);
  useEffect(() => {
    axios.get('http://localhost:8800/getaug19/'+id)
    .then(res => {
      if(res.data.Status === "Success"){
        set19(res.data.Result);
      }else{
        alert("Error")
      }
    })
    .catch(err => console.log(err));
  }, [id])
  // Day 20
  const [starthour20, setStart20] = useState('')
  const [endhour20, setEnd20] = useState('')
  const [type20, settype20] = useState('')
  const handleSubmit20 = (event) => {
      event.preventDefault(); 
      axios.post('http://localhost:8800/aug20/'+id, {starthour20, endhour20, type20})
      .then(res => {
        window.location.reload(true);
      })
      .catch(err => console.log(err));
  }
  const [data20, set20] = useState([]);
  useEffect(() => {
    axios.get('http://localhost:8800/getaug20/'+id)
    .then(res => {
      if(res.data.Status === "Success"){
        set20(res.data.Result);
      }else{
        alert("Error")
      }
    })
    .catch(err => console.log(err));
  }, [id])
  // Day 21
  const [starthour21, setStart21] = useState('')
  const [endhour21, setEnd21] = useState('')
  const [type21, settype21] = useState('')
  const handleSubmit21 = (event) => {
      event.preventDefault(); 
      axios.post('http://localhost:8800/aug21/'+id, {starthour21, endhour21, type21})
      .then(res => {
        window.location.reload(true);
      })
      .catch(err => console.log(err));
  }
  const [data21, set21] = useState([]);
  useEffect(() => {
    axios.get('http://localhost:8800/getaug21/'+id)
    .then(res => {
      if(res.data.Status === "Success"){
        set21(res.data.Result);
      }else{
        alert("Error")
      }
    })
    .catch(err => console.log(err));
  }, [id])
  // Day 20
  const [starthour22, setStart22] = useState('')
  const [endhour22, setEnd22] = useState('')
  const [type22, settype22] = useState('')
  const handleSubmit22 = (event) => {
      event.preventDefault(); 
      axios.post('http://localhost:8800/aug22/'+id, {starthour22, endhour22, type22})
      .then(res => {
        window.location.reload(true);
      })
      .catch(err => console.log(err));
  }
  const [data22, set22] = useState([]);
  useEffect(() => {
    axios.get('http://localhost:8800/getaug22/'+id)
    .then(res => {
      if(res.data.Status === "Success"){
        set22(res.data.Result);
      }else{
        alert("Error")
      }
    })
    .catch(err => console.log(err));
  }, [id])
  // Day 23
  const [starthour23, setStart23] = useState('')
  const [endhour23, setEnd23] = useState('')
  const [type23, settype23] = useState('')
  const handleSubmit23 = (event) => {
      event.preventDefault(); 
      axios.post('http://localhost:8800/aug23/'+id, {starthour23, endhour23, type23})
      .then(res => {
        window.location.reload(true);
      })
      .catch(err => console.log(err));
  }
  const [data23, set23] = useState([]);
  useEffect(() => {
    axios.get('http://localhost:8800/getaug23/'+id)
    .then(res => {
      if(res.data.Status === "Success"){
        set23(res.data.Result);
      }else{
        alert("Error")
      }
    })
    .catch(err => console.log(err));
  }, [id])
  // Day 24
  const [starthour24, setStart24] = useState('')
  const [endhour24, setEnd24] = useState('')
  const [type24, settype24] = useState('')
  const handleSubmit24 = (event) => {
      event.preventDefault(); 
      axios.post('http://localhost:8800/aug24/'+id, {starthour24, endhour24, type24})
      .then(res => {
        window.location.reload(true);
      })
      .catch(err => console.log(err));
  }
  const [data24, set24] = useState([]);
  useEffect(() => {
    axios.get('http://localhost:8800/getaug24/'+id)
    .then(res => {
      if(res.data.Status === "Success"){
        set24(res.data.Result);
      }else{
        alert("Error")
      }
    })
    .catch(err => console.log(err));
  }, [id])
  // Day 25
  const [starthour25, setStart25] = useState('')
  const [endhour25, setEnd25] = useState('')
  const [type25, settype25] = useState('')
  const handleSubmit25 = (event) => {
      event.preventDefault(); 
      axios.post('http://localhost:8800/aug25/'+id, {starthour25, endhour25, type25})
      .then(res => {
        window.location.reload(true);
      })
      .catch(err => console.log(err));
  }
  const [data25, set25] = useState([]);
  useEffect(() => {
    axios.get('http://localhost:8800/getaug25/'+id)
    .then(res => {
      if(res.data.Status === "Success"){
        set25(res.data.Result);
      }else{
        alert("Error")
      }
    })
    .catch(err => console.log(err));
  }, [id])
  // Day 26
  const [starthour26, setStart26] = useState('')
  const [endhour26, setEnd26] = useState('')
  const [type26, settype26] = useState('')
  const handleSubmit26 = (event) => {
      event.preventDefault(); 
      axios.post('http://localhost:8800/aug26/'+id, {starthour26, endhour26, type26})
      .then(res => {
        window.location.reload(true);
      })
      .catch(err => console.log(err));
  }
  const [data26, set26] = useState([]);
  useEffect(() => {
    axios.get('http://localhost:8800/getaug26/'+id)
    .then(res => {
      if(res.data.Status === "Success"){
        set26(res.data.Result);
      }else{
        alert("Error")
      }
    })
    .catch(err => console.log(err));
  }, [id])
  // Day 27
  const [starthour27, setStart27] = useState('')
  const [endhour27, setEnd27] = useState('')
  const [type27, settype27] = useState('')
  const handleSubmit27 = (event) => {
      event.preventDefault(); 
      axios.post('http://localhost:8800/aug27/'+id, {starthour27, endhour27, type27})
      .then(res => {
        window.location.reload(true);
      })
      .catch(err => console.log(err));
  }
  const [data27, set27] = useState([]);
  useEffect(() => {
    axios.get('http://localhost:8800/getaug27/'+id)
    .then(res => {
      if(res.data.Status === "Success"){
        set27(res.data.Result);
      }else{
        alert("Error")
      }
    })
    .catch(err => console.log(err));
  }, [id])
  // Day 20
  const [starthour28, setStart28] = useState('')
  const [endhour28, setEnd28] = useState('')
  const [type28, settype28] = useState('')
  const handleSubmit28 = (event) => {
      event.preventDefault(); 
      axios.post('http://localhost:8800/aug28/'+id, {starthour28, endhour28, type28})
      .then(res => {
        window.location.reload(true);
      })
      .catch(err => console.log(err));
  }
  const [data28, set28] = useState([]);
  useEffect(() => {
    axios.get('http://localhost:8800/getaug28/'+id)
    .then(res => {
      if(res.data.Status === "Success"){
        set28(res.data.Result);
      }else{
        alert("Error")
      }
    })
    .catch(err => console.log(err));
  }, [id])
  // Day 29
  const [starthour29, setStart29] = useState('')
  const [endhour29, setEnd29] = useState('')
  const [type29, settype29] = useState('')
  const handleSubmit29 = (event) => {
      event.preventDefault(); 
      axios.post('http://localhost:8800/aug29/'+id, {starthour29, endhour29, type29})
      .then(res => {
        window.location.reload(true);
      })
      .catch(err => console.log(err));
  }
  const [data29, set29] = useState([]);
  useEffect(() => {
    axios.get('http://localhost:8800/getaug29/'+id)
    .then(res => {
      if(res.data.Status === "Success"){
        set29(res.data.Result);
      }else{
        alert("Error")
      }
    })
    .catch(err => console.log(err));
  }, [id])
  // Day 30
  const [starthour30, setStart30] = useState('')
  const [endhour30, setEnd30] = useState('')
  const [type30, settype30] = useState('')
  const handleSubmit30 = (event) => {
      event.preventDefault(); 
      axios.post('http://localhost:8800/aug30/'+id, {starthour30, endhour30, type30})
      .then(res => {
        window.location.reload(true);
      })
      .catch(err => console.log(err));
  }
  const [data30, set30] = useState([]);
  useEffect(() => {
    axios.get('http://localhost:8800/getaug30/'+id)
    .then(res => {
      if(res.data.Status === "Success"){
        set30(res.data.Result);
      }else{
        alert("Error")
      }
    })
    .catch(err => console.log(err));
  }, [id])
  // Day 31
  const [starthour31, setStart31] = useState('')
  const [endhour31, setEnd31] = useState('')
  const [type31, settype31] = useState('')
  const handleSubmit31 = (event) => {
      event.preventDefault(); 
      axios.post('http://localhost:8800/aug31/'+id, {starthour31, endhour31, type31})
      .then(res => {
        window.location.reload(true);
      })
      .catch(err => console.log(err));
  }
  const [data31, set31] = useState([]);
  useEffect(() => {
    axios.get('http://localhost:8800/getaug31/'+id)
    .then(res => {
      if(res.data.Status === "Success"){
        set31(res.data.Result);
      }else{
        alert("Error")
      }
    })
    .catch(err => console.log(err));
  }, [id])

  return (
    <div className="timetable">
      <div className="top">
        <div className="title">
        <h4>August</h4>
        </div>
        <div className="button">
        <Link to={`/timetable/`+id} className='btn btn-info btn-sm me-2'>Back</Link>
        </div>
      </div><hr />
      <div className="row">
          <div className="col-md-12">
            <div className="box">
                <div className="d-flex flex-column p-2">
                  <form action="" className="row" onSubmit={handleSubmit1}>
                        <div className="col-md-1"> 01 </div>
                        <div className="col-md-1"> Tuesday </div>
                        <div className="col-md-1" > 
                            {data1.map((types, index) =>{
                              return<tr key={index}>
                                   <p style={types.type === "Normal" ? {backgroundColor:'rgba(7, 190, 7, 0.648)', color:'#000', padding:'5px 10px'} : 
                                     (types.type === "Holiday" ? {backgroundColor:'rgba(255, 0, 0, 0.438)', color:'#000', padding:'5px 10px'} : 
                                      {backgroundColor:'#008cff5a', color:'#000', padding:'5px 10px'}) }> {types.type}</p>
                                    </tr>
                                })}
                        </div>
                        <div className="col-md-2">
                            <select name="starthour1" onChange={e =>  setStart1(e.target.value)} className="form-control">
                                <option value="">Start Time</option>
                                <option value="05.00">05:00 AM</option>
                                <option value="06.00">06:00 AM</option>
                                <option value="06.8">06:05 AM</option>
                                <option value="06.16">06:10 AM</option>
                                <option value="06.25">06:15 AM</option>
                                <option value="06.33">06:20 AM</option>
                                <option value="06.41">06:25 AM</option>
                                <option value="06.50">06:30 AM</option>
                                <option value="06.58">06:35 AM</option>
                                <option value="06.66">06:40 AM</option>
                                <option value="06.75">06:45 AM</option>
                                <option value="06.83">06:50 AM</option>
                                <option value="06.91">06:55 AM</option>
                                <option value="07.00">07:00 AM - Normal</option>
                                <option value="07.8">07:05 AM</option>
                                <option value="07.16">07:10 AM</option>
                                <option value="07.25">07:15 AM</option>
                                <option value="07.33">07:20 AM</option>
                                <option value="07.41">07:25 AM</option>
                                <option value="07.50">07:30 AM</option>
                                <option value="07.58">07:35 AM</option>
                                <option value="07.66">07:40 AM</option>
                                <option value="07.75">07:45 AM</option>
                                <option value="07.83">07:50 AM</option>
                                <option value="07.91">07:55 AM</option>
                                <option value="08.00">08:00 AM - o'clock</option>
                                <option value="08.8">08:05 AM</option>
                                <option value="08.16">08:10 AM</option>
                                <option value="08.25">08:15 AM</option>
                                <option value="08.33">08:20 AM</option>
                                <option value="08.41">08:25 AM</option>
                                <option value="08.50">08:30 AM</option>
                                <option value="08.58">08:35 AM</option>
                                <option value="08.66">08:40 AM</option>
                                <option value="08.75">08:45 AM</option>
                                <option value="08.83">08:50 AM</option>
                                <option value="08.91">08:55 AM</option>
                                <option value="09.00">09:00 AM - o'clock</option>
                                <option value="09.8">09:05 AM</option>
                                <option value="09.16">09:10 AM</option>
                                <option value="09.25">09:15 AM</option>
                                <option value="09.33">09:20 AM</option>
                                <option value="09.41">09:25 AM</option>
                                <option value="09.50">09:30 AM</option>
                                <option value="09.58">09:35 AM</option>
                                <option value="09.66">09:40 AM</option>
                                <option value="09.75">09:45 AM</option>
                                <option value="09.83">09:50 AM</option>
                                <option value="09.91">09:55 AM</option>
                                <option value="10.00">10:00 AM - o'clock</option>
                                <option value="10.8">10:05 AM</option>
                                <option value="10.16">10:10 AM</option>
                                <option value="10.25">10:15 AM</option>
                                <option value="10.33">10:20 AM</option>
                                <option value="10.41">10:25 AM</option>
                                <option value="10.50">10:30 AM</option>
                                <option value="10.58">10:35 AM</option>
                                <option value="10.66">10:40 AM</option>
                                <option value="10.75">10:45 AM</option>
                                <option value="10.83">10:50 AM</option>
                                <option value="10.91">10:55 AM</option>
                                <option value="11.00">11:00 AM - o'clock</option>
                                <option value="11.8">11:05 AM</option>
                                <option value="11.16">11:10 AM</option>
                                <option value="11.25">11:15 AM</option>
                                <option value="11.33">11:20 AM</option>
                                <option value="11.41">11:25 AM</option>
                                <option value="11.50">11:30 AM</option>
                                <option value="11.58">11:35 AM</option>
                                <option value="11.66">11:40 AM</option>
                                <option value="11.75">11:45 AM</option>
                                <option value="11.83">11:50 AM</option>
                                <option value="11.91">11:55 AM</option>
                                <option value="12.00">12:00 PM  - o'clock</option>
                                <option value="12.8">12:05 PM</option>
                                <option value="12.16">12:10 PM</option>
                                <option value="12.25">12:15 PM</option>
                                <option value="12.33">12:20 PM</option>
                                <option value="12.41">12:25 PM</option>
                                <option value="12.50">12:30 PM</option>
                                <option value="12.58">12:35 PM</option>
                                <option value="12.66">12:40 PM</option>
                                <option value="12.75">12:45 PM</option>
                                <option value="12.83">12:50 PM</option>
                                <option value="12.91">12:55 PM</option>
                                <option value="13.00">13:00 PM - o'clock</option>
                                <option value="13.8">13:05 PM</option>
                                <option value="13.16">13:10 PM</option>
                                <option value="13.25">13:15 PM</option>
                                <option value="13.33">13:20 PM</option>
                                <option value="13.41">13:25 PM</option>
                                <option value="13.50">13:30 PM</option>
                                <option value="13.58">13:35 PM</option>
                                <option value="13.66">13:40 PM</option>
                                <option value="13.75">13:45 PM</option>
                                <option value="13.83">13:50 PM</option>
                                <option value="13.91">13:55 PM</option>
                                <option value="14.00">14:00 PM - o'clock</option>
                                <option value="14.8">14:05 PM</option>
                                <option value="14.16">14:10 PM</option>
                                <option value="14.25">14:15 PM</option>
                                <option value="14.33">14:20 PM</option>
                                <option value="14.41">14:25 PM</option>
                                <option value="14.50">14:30 PM</option>
                                <option value="14.58">14:35 PM</option>
                                <option value="14.66">14:40 PM</option>
                                <option value="14.75">14:45 PM</option>
                                <option value="14.83">14:50 PM</option>
                                <option value="14.91">14:55 PM</option>
                                <option value="15.00">15:00 PM - o'clock</option>
                                <option value="15.8">15:05 PM</option>
                                <option value="15.16">15:10 PM</option>
                                <option value="15.25">15:15 PM</option>
                                <option value="15.33">15:20 PM</option>
                                <option value="15.41">15:25 PM</option>
                                <option value="15.50">15:30 PM</option>
                                <option value="15.58">15:35 PM</option>
                                <option value="15.66">15:40 PM</option>
                                <option value="15.75">15:45 PM</option>
                                <option value="15.83">15:50 PM</option>
                                <option value="15.91">15:55 PM</option>
                                <option value="16.00">16:00 PM - o'clock</option>
                                <option value="16.8">16:05 PM</option>
                                <option value="16.16">16:10 PM</option>
                                <option value="16.25">16:15 PM</option>
                                <option value="16.33">16:20 PM</option>
                                <option value="16.41">16:25 PM</option>
                                <option value="16.50">16:30 PM</option>
                                <option value="16.58">16:35 PM</option>
                                <option value="16.66">16:40 PM</option>
                                <option value="16.75">16:45 PM</option>
                                <option value="16.83">16:50 PM</option>
                                <option value="16.91">16:55 PM</option>
                                <option value="17.00">17:00 PM - o'clock</option>
                                <option value="17.8">17:05 PM</option>
                                <option value="17.16">17:10 PM</option>
                                <option value="17.25">17:15 PM</option>
                                <option value="17.33">17:20 PM</option>
                                <option value="17.41">17:25 PM</option>
                                <option value="17.50">17:30 PM</option>
                                <option value="17.58">17:35 PM</option>
                                <option value="17.66">17:40 PM</option>
                                <option value="17.75">17:45 PM</option>
                                <option value="17.83">17:50 PM</option>
                                <option value="17.91">17:55 PM</option>
                                <option value="18.00">18:00 PM - Normal</option>
                                <option value="18.8">18:05 PM</option>
                                <option value="18.16">18:10 PM</option>
                                <option value="18.25">18:15 PM</option>
                                <option value="18.33">18:20 PM</option>
                                <option value="18.41">18:25 PM</option>
                                <option value="18.50">18:30 PM</option>
                                <option value="18.58">18:35 PM</option>
                                <option value="18.66">18:40 PM</option>
                                <option value="18.75">18:45 PM</option>
                                <option value="18.83">18:50 PM</option>
                                <option value="18.91">18:55 PM</option>
                                <option value="19.00">19:00 PM - o'clock</option>
                                <option value="19.8">19:05 PM</option>
                                <option value="19.16">19:10 PM</option>
                                <option value="19.25">19:15 PM</option>
                                <option value="19.33">19:20 PM</option>
                                <option value="19.41">19:25 PM</option>
                                <option value="19.50">19:30 PM</option>
                                <option value="19.58">19:35 PM</option>
                                <option value="19.66">19:40 PM</option>
                                <option value="19.75">19:45 PM</option>
                                <option value="19.83">19:50 PM</option>
                                <option value="19.91">19:55 PM</option>
                                <option value="20.00">20:00 PM - o'clock</option>
                                <option value="20.8">20:05 PM</option>
                                <option value="20.16">20:10 PM</option>
                                <option value="20.25">20:15 PM</option>
                                <option value="20.33">20:20 PM</option>
                                <option value="20.41">20:25 PM</option>
                                <option value="20.50">20:30 PM</option>
                                <option value="20.58">20:35 PM</option>
                                <option value="20.66">20:40 PM</option>
                                <option value="20.75">20:45 PM</option>
                                <option value="20.83">20:50 PM</option>
                                <option value="20.91">20:55 PM</option>
                                <option value="21.00">21:00 PM - o'clock</option>
                                <option value="21.8">21:05 PM</option>
                                <option value="21.16">21:10 PM</option>
                                <option value="21.25">21:15 PM</option>
                                <option value="21.33">21:20 PM</option>
                                <option value="21.41">21:25 PM</option>
                                <option value="21.50">21:30 PM</option>
                                <option value="21.58">21:35 PM</option>
                                <option value="21.66">21:40 PM</option>
                                <option value="21.75">21:45 PM</option>
                                <option value="21.83">21:50 PM</option>
                                <option value="21.91">21:55 PM</option>
                                <option value="22.00">22:00 PM - o'clock</option>
                                <option value="22.8">22:05 PM</option>
                                <option value="22.16">22:10 PM</option>
                                <option value="22.25">22:15 PM</option>
                                <option value="22.33">22:20 PM</option>
                                <option value="22.41">22:25 PM</option>
                                <option value="22.50">22:30 PM</option>
                                <option value="22.58">22:35 PM</option>
                                <option value="22.66">22:40 PM</option>
                                <option value="22.75">22:45 PM</option>
                                <option value="22.83">22:50 PM</option>
                                <option value="22.91">22:55 PM</option>
                                <option value="23.00">23:00 PM - o'clock</option>
                                <option value="23.8">23:05 PM</option>
                                <option value="23.16">23:10 PM</option>
                                <option value="23.25">23:15 PM</option>
                                <option value="23.33">23:20 PM</option>
                                <option value="23.41">23:25 PM</option>
                                <option value="23.50">23:30 PM</option>
                                <option value="23.58">23:35 PM</option>
                                <option value="23.66">23:40 PM</option>
                                <option value="23.75">23:45 PM</option>
                                <option value="23.83">23:50 PM</option>
                                <option value="23.91">23:55 PM</option>
                                <option value="24.00">24:00 PM - o'clock</option>
                                <option value="24.8">15:05 PM</option>
                                <option value="24.16">24:10 PM</option>
                                <option value="24.25">24:15 PM</option>
                                <option value="24.33">24:20 PM</option>
                                <option value="24.41">24:25 PM</option>
                                <option value="24.50">24:30 PM</option>
                                <option value="24.58">24:35 PM</option>
                                <option value="24.66">24:40 PM</option>
                                <option value="24.75">24:45 PM</option>
                                <option value="24.83">24:50 PM</option>
                                <option value="24.91">24:55 PM</option>
                            </select>
                            {data1.map((datas, index) =>{
                              return<div key={index}>
                                        <p>{datas.starthour}</p>
                                    </div>
                                })}
                        </div>
                        <div className="col-md-2">
                            <select name="endhour1" onChange={e =>  setEnd1(e.target.value)} className="form-control">
                                <option value="">Finish Time</option>
                                <option value="05.00">05:00 AM</option>
                                <option value="06.00">06:00 AM</option>
                                <option value="06.8">06:05 AM</option>
                                <option value="06.16">06:10 AM</option>
                                <option value="06.25">06:15 AM</option>
                                <option value="06.33">06:20 AM</option>
                                <option value="06.41">06:25 AM</option>
                                <option value="06.50">06:30 AM</option>
                                <option value="06.58">06:35 AM</option>
                                <option value="06.66">06:40 AM</option>
                                <option value="06.75">06:45 AM</option>
                                <option value="06.83">06:50 AM</option>
                                <option value="06.91">06:55 AM</option>
                                <option value="07.00">07:00 AM - Normal</option>
                                <option value="07.8">07:05 AM</option>
                                <option value="07.16">07:10 AM</option>
                                <option value="07.25">07:15 AM</option>
                                <option value="07.33">07:20 AM</option>
                                <option value="07.41">07:25 AM</option>
                                <option value="07.50">07:30 AM</option>
                                <option value="07.58">07:35 AM</option>
                                <option value="07.66">07:40 AM</option>
                                <option value="07.75">07:45 AM</option>
                                <option value="07.83">07:50 AM</option>
                                <option value="07.91">07:55 AM</option>
                                <option value="08.00">08:00 AM - o'clock</option>
                                <option value="08.8">08:05 AM</option>
                                <option value="08.16">08:10 AM</option>
                                <option value="08.25">08:15 AM</option>
                                <option value="08.33">08:20 AM</option>
                                <option value="08.41">08:25 AM</option>
                                <option value="08.50">08:30 AM</option>
                                <option value="08.58">08:35 AM</option>
                                <option value="08.66">08:40 AM</option>
                                <option value="08.75">08:45 AM</option>
                                <option value="08.83">08:50 AM</option>
                                <option value="08.91">08:55 AM</option>
                                <option value="09.00">09:00 AM - o'clock</option>
                                <option value="09.8">09:05 AM</option>
                                <option value="09.16">09:10 AM</option>
                                <option value="09.25">09:15 AM</option>
                                <option value="09.33">09:20 AM</option>
                                <option value="09.41">09:25 AM</option>
                                <option value="09.50">09:30 AM</option>
                                <option value="09.58">09:35 AM</option>
                                <option value="09.66">09:40 AM</option>
                                <option value="09.75">09:45 AM</option>
                                <option value="09.83">09:50 AM</option>
                                <option value="09.91">09:55 AM</option>
                                <option value="10.00">10:00 AM - o'clock</option>
                                <option value="10.8">10:05 AM</option>
                                <option value="10.16">10:10 AM</option>
                                <option value="10.25">10:15 AM</option>
                                <option value="10.33">10:20 AM</option>
                                <option value="10.41">10:25 AM</option>
                                <option value="10.50">10:30 AM</option>
                                <option value="10.58">10:35 AM</option>
                                <option value="10.66">10:40 AM</option>
                                <option value="10.75">10:45 AM</option>
                                <option value="10.83">10:50 AM</option>
                                <option value="10.91">10:55 AM</option>
                                <option value="11.00">11:00 AM - o'clock</option>
                                <option value="11.8">11:05 AM</option>
                                <option value="11.16">11:10 AM</option>
                                <option value="11.25">11:15 AM</option>
                                <option value="11.33">11:20 AM</option>
                                <option value="11.41">11:25 AM</option>
                                <option value="11.50">11:30 AM</option>
                                <option value="11.58">11:35 AM</option>
                                <option value="11.66">11:40 AM</option>
                                <option value="11.75">11:45 AM</option>
                                <option value="11.83">11:50 AM</option>
                                <option value="11.91">11:55 AM</option>
                                <option value="12.00">12:00 PM  - o'clock</option>
                                <option value="12.8">12:05 PM</option>
                                <option value="12.16">12:10 PM</option>
                                <option value="12.25">12:15 PM</option>
                                <option value="12.33">12:20 PM</option>
                                <option value="12.41">12:25 PM</option>
                                <option value="12.50">12:30 PM</option>
                                <option value="12.58">12:35 PM</option>
                                <option value="12.66">12:40 PM</option>
                                <option value="12.75">12:45 PM</option>
                                <option value="12.83">12:50 PM</option>
                                <option value="12.91">12:55 PM</option>
                                <option value="13.00">13:00 PM - o'clock</option>
                                <option value="13.8">13:05 PM</option>
                                <option value="13.16">13:10 PM</option>
                                <option value="13.25">13:15 PM</option>
                                <option value="13.33">13:20 PM</option>
                                <option value="13.41">13:25 PM</option>
                                <option value="13.50">13:30 PM</option>
                                <option value="13.58">13:35 PM</option>
                                <option value="13.66">13:40 PM</option>
                                <option value="13.75">13:45 PM</option>
                                <option value="13.83">13:50 PM</option>
                                <option value="13.91">13:55 PM</option>
                                <option value="14.00">14:00 PM - o'clock</option>
                                <option value="14.8">14:05 PM</option>
                                <option value="14.16">14:10 PM</option>
                                <option value="14.25">14:15 PM</option>
                                <option value="14.33">14:20 PM</option>
                                <option value="14.41">14:25 PM</option>
                                <option value="14.50">14:30 PM</option>
                                <option value="14.58">14:35 PM</option>
                                <option value="14.66">14:40 PM</option>
                                <option value="14.75">14:45 PM</option>
                                <option value="14.83">14:50 PM</option>
                                <option value="14.91">14:55 PM</option>
                                <option value="15.00">15:00 PM - o'clock</option>
                                <option value="15.8">15:05 PM</option>
                                <option value="15.16">15:10 PM</option>
                                <option value="15.25">15:15 PM</option>
                                <option value="15.33">15:20 PM</option>
                                <option value="15.41">15:25 PM</option>
                                <option value="15.50">15:30 PM</option>
                                <option value="15.58">15:35 PM</option>
                                <option value="15.66">15:40 PM</option>
                                <option value="15.75">15:45 PM</option>
                                <option value="15.83">15:50 PM</option>
                                <option value="15.91">15:55 PM</option>
                                <option value="16.00">16:00 PM - o'clock</option>
                                <option value="16.8">16:05 PM</option>
                                <option value="16.16">16:10 PM</option>
                                <option value="16.25">16:15 PM</option>
                                <option value="16.33">16:20 PM</option>
                                <option value="16.41">16:25 PM</option>
                                <option value="16.50">16:30 PM</option>
                                <option value="16.58">16:35 PM</option>
                                <option value="16.66">16:40 PM</option>
                                <option value="16.75">16:45 PM</option>
                                <option value="16.83">16:50 PM</option>
                                <option value="16.91">16:55 PM</option>
                                <option value="17.00">17:00 PM - o'clock</option>
                                <option value="17.8">17:05 PM</option>
                                <option value="17.16">17:10 PM</option>
                                <option value="17.25">17:15 PM</option>
                                <option value="17.33">17:20 PM</option>
                                <option value="17.41">17:25 PM</option>
                                <option value="17.50">17:30 PM</option>
                                <option value="17.58">17:35 PM</option>
                                <option value="17.66">17:40 PM</option>
                                <option value="17.75">17:45 PM</option>
                                <option value="17.83">17:50 PM</option>
                                <option value="17.91">17:55 PM</option>
                                <option value="18.00">18:00 PM - Normal</option>
                                <option value="18.8">18:05 PM</option>
                                <option value="18.16">18:10 PM</option>
                                <option value="18.25">18:15 PM</option>
                                <option value="18.33">18:20 PM</option>
                                <option value="18.41">18:25 PM</option>
                                <option value="18.50">18:30 PM</option>
                                <option value="18.58">18:35 PM</option>
                                <option value="18.66">18:40 PM</option>
                                <option value="18.75">18:45 PM</option>
                                <option value="18.83">18:50 PM</option>
                                <option value="18.91">18:55 PM</option>
                                <option value="19.00">19:00 PM - o'clock</option>
                                <option value="19.8">19:05 PM</option>
                                <option value="19.16">19:10 PM</option>
                                <option value="19.25">19:15 PM</option>
                                <option value="19.33">19:20 PM</option>
                                <option value="19.41">19:25 PM</option>
                                <option value="19.50">19:30 PM</option>
                                <option value="19.58">19:35 PM</option>
                                <option value="19.66">19:40 PM</option>
                                <option value="19.75">19:45 PM</option>
                                <option value="19.83">19:50 PM</option>
                                <option value="19.91">19:55 PM</option>
                                <option value="20.00">20:00 PM - o'clock</option>
                                <option value="20.8">20:05 PM</option>
                                <option value="20.16">20:10 PM</option>
                                <option value="20.25">20:15 PM</option>
                                <option value="20.33">20:20 PM</option>
                                <option value="20.41">20:25 PM</option>
                                <option value="20.50">20:30 PM</option>
                                <option value="20.58">20:35 PM</option>
                                <option value="20.66">20:40 PM</option>
                                <option value="20.75">20:45 PM</option>
                                <option value="20.83">20:50 PM</option>
                                <option value="20.91">20:55 PM</option>
                                <option value="21.00">21:00 PM - o'clock</option>
                                <option value="21.8">21:05 PM</option>
                                <option value="21.16">21:10 PM</option>
                                <option value="21.25">21:15 PM</option>
                                <option value="21.33">21:20 PM</option>
                                <option value="21.41">21:25 PM</option>
                                <option value="21.50">21:30 PM</option>
                                <option value="21.58">21:35 PM</option>
                                <option value="21.66">21:40 PM</option>
                                <option value="21.75">21:45 PM</option>
                                <option value="21.83">21:50 PM</option>
                                <option value="21.91">21:55 PM</option>
                                <option value="22.00">22:00 PM - o'clock</option>
                                <option value="22.8">22:05 PM</option>
                                <option value="22.16">22:10 PM</option>
                                <option value="22.25">22:15 PM</option>
                                <option value="22.33">22:20 PM</option>
                                <option value="22.41">22:25 PM</option>
                                <option value="22.50">22:30 PM</option>
                                <option value="22.58">22:35 PM</option>
                                <option value="22.66">22:40 PM</option>
                                <option value="22.75">22:45 PM</option>
                                <option value="22.83">22:50 PM</option>
                                <option value="22.91">22:55 PM</option>
                                <option value="23.00">23:00 PM - o'clock</option>
                                <option value="23.8">23:05 PM</option>
                                <option value="23.16">23:10 PM</option>
                                <option value="23.25">23:15 PM</option>
                                <option value="23.33">23:20 PM</option>
                                <option value="23.41">23:25 PM</option>
                                <option value="23.50">23:30 PM</option>
                                <option value="23.58">23:35 PM</option>
                                <option value="23.66">23:40 PM</option>
                                <option value="23.75">23:45 PM</option>
                                <option value="23.83">23:50 PM</option>
                                <option value="23.91">23:55 PM</option>
                                <option value="24.00">24:00 PM - o'clock</option>
                                <option value="24.8">15:05 PM</option>
                                <option value="24.16">24:10 PM</option>
                                <option value="24.25">24:15 PM</option>
                                <option value="24.33">24:20 PM</option>
                                <option value="24.41">24:25 PM</option>
                                <option value="24.50">24:30 PM</option>
                                <option value="24.58">24:35 PM</option>
                                <option value="24.66">24:40 PM</option>
                                <option value="24.75">24:45 PM</option>
                                <option value="24.83">24:50 PM</option>
                                <option value="24.91">24:55 PM</option>
                            </select>
                            {data1.map((datas, index) =>{
                              return<div key={index}>
                                        <p>{datas.endhour}</p>
                                    </div>
                                })}
                        </div>
                        <div className="col-md-2">
                            <select name="type1" onChange={e =>  settype1(e.target.value)} className="form-control" required>
                                <option value="">Select Type</option>
                                <option value="Normal">Normal</option>
                                <option value="SJUK">SJUK</option>
                                <option value="Holiday">Holiday</option>
                            </select>
                            {data1.map((datas, index) =>{
                              return<div key={index}>
                                        <p>{datas.type}</p>
                                    </div>
                                })}
                          </div>
                          <div className="col-md-2"> 
                            <button type='submit' className="btn btn-primary">Submit</button>
                        </div>
                      </form>
                  </div>
                  <div className="d-flex flex-column p-2">
                     <form action="" className="row"  onSubmit={handleSubmit2}>
                        <div className="col-md-1"> 02 </div>
                        <div className="col-md-1"> Wednesday </div>
                        <div className="col-md-1" > 
                            {data2.map((types, index) =>{
                              return<tr key={index}>
                                   <p style={types.type === "Normal" ? {backgroundColor:'rgba(7, 190, 7, 0.648)', color:'#000', padding:'5px 10px'} : 
                                     (types.type === "Holiday" ? {backgroundColor:'rgba(255, 0, 0, 0.438)', color:'#000', padding:'5px 10px'} : 
                                      {backgroundColor:'#008cff5a', color:'#000', padding:'5px 10px'}) }> {types.type}</p>
                                    </tr>
                                })}
                        </div>
                        <div className="col-md-2">
                            <select name="starthour2" onChange={e =>  setStart2(e.target.value)} className="form-control">
                                <option value="">Start Time</option>
                                <option value="05.00">05:00 AM</option>
                                <option value="06.00">06:00 AM</option>
                                <option value="06.8">06:05 AM</option>
                                <option value="06.16">06:10 AM</option>
                                <option value="06.25">06:15 AM</option>
                                <option value="06.33">06:20 AM</option>
                                <option value="06.41">06:25 AM</option>
                                <option value="06.50">06:30 AM</option>
                                <option value="06.58">06:35 AM</option>
                                <option value="06.66">06:40 AM</option>
                                <option value="06.75">06:45 AM</option>
                                <option value="06.83">06:50 AM</option>
                                <option value="06.91">06:55 AM</option>
                                <option value="07.00">07:00 AM - Normal</option>
                                <option value="07.8">07:05 AM</option>
                                <option value="07.16">07:10 AM</option>
                                <option value="07.25">07:15 AM</option>
                                <option value="07.33">07:20 AM</option>
                                <option value="07.41">07:25 AM</option>
                                <option value="07.50">07:30 AM</option>
                                <option value="07.58">07:35 AM</option>
                                <option value="07.66">07:40 AM</option>
                                <option value="07.75">07:45 AM</option>
                                <option value="07.83">07:50 AM</option>
                                <option value="07.91">07:55 AM</option>
                                <option value="08.00">08:00 AM - o'clock</option>
                                <option value="08.8">08:05 AM</option>
                                <option value="08.16">08:10 AM</option>
                                <option value="08.25">08:15 AM</option>
                                <option value="08.33">08:20 AM</option>
                                <option value="08.41">08:25 AM</option>
                                <option value="08.50">08:30 AM</option>
                                <option value="08.58">08:35 AM</option>
                                <option value="08.66">08:40 AM</option>
                                <option value="08.75">08:45 AM</option>
                                <option value="08.83">08:50 AM</option>
                                <option value="08.91">08:55 AM</option>
                                <option value="09.00">09:00 AM - o'clock</option>
                                <option value="09.8">09:05 AM</option>
                                <option value="09.16">09:10 AM</option>
                                <option value="09.25">09:15 AM</option>
                                <option value="09.33">09:20 AM</option>
                                <option value="09.41">09:25 AM</option>
                                <option value="09.50">09:30 AM</option>
                                <option value="09.58">09:35 AM</option>
                                <option value="09.66">09:40 AM</option>
                                <option value="09.75">09:45 AM</option>
                                <option value="09.83">09:50 AM</option>
                                <option value="09.91">09:55 AM</option>
                                <option value="10.00">10:00 AM - o'clock</option>
                                <option value="10.8">10:05 AM</option>
                                <option value="10.16">10:10 AM</option>
                                <option value="10.25">10:15 AM</option>
                                <option value="10.33">10:20 AM</option>
                                <option value="10.41">10:25 AM</option>
                                <option value="10.50">10:30 AM</option>
                                <option value="10.58">10:35 AM</option>
                                <option value="10.66">10:40 AM</option>
                                <option value="10.75">10:45 AM</option>
                                <option value="10.83">10:50 AM</option>
                                <option value="10.91">10:55 AM</option>
                                <option value="11.00">11:00 AM - o'clock</option>
                                <option value="11.8">11:05 AM</option>
                                <option value="11.16">11:10 AM</option>
                                <option value="11.25">11:15 AM</option>
                                <option value="11.33">11:20 AM</option>
                                <option value="11.41">11:25 AM</option>
                                <option value="11.50">11:30 AM</option>
                                <option value="11.58">11:35 AM</option>
                                <option value="11.66">11:40 AM</option>
                                <option value="11.75">11:45 AM</option>
                                <option value="11.83">11:50 AM</option>
                                <option value="11.91">11:55 AM</option>
                                <option value="12.00">12:00 PM  - o'clock</option>
                                <option value="12.8">12:05 PM</option>
                                <option value="12.16">12:10 PM</option>
                                <option value="12.25">12:15 PM</option>
                                <option value="12.33">12:20 PM</option>
                                <option value="12.41">12:25 PM</option>
                                <option value="12.50">12:30 PM</option>
                                <option value="12.58">12:35 PM</option>
                                <option value="12.66">12:40 PM</option>
                                <option value="12.75">12:45 PM</option>
                                <option value="12.83">12:50 PM</option>
                                <option value="12.91">12:55 PM</option>
                                <option value="13.00">13:00 PM - o'clock</option>
                                <option value="13.8">13:05 PM</option>
                                <option value="13.16">13:10 PM</option>
                                <option value="13.25">13:15 PM</option>
                                <option value="13.33">13:20 PM</option>
                                <option value="13.41">13:25 PM</option>
                                <option value="13.50">13:30 PM</option>
                                <option value="13.58">13:35 PM</option>
                                <option value="13.66">13:40 PM</option>
                                <option value="13.75">13:45 PM</option>
                                <option value="13.83">13:50 PM</option>
                                <option value="13.91">13:55 PM</option>
                                <option value="14.00">14:00 PM - o'clock</option>
                                <option value="14.8">14:05 PM</option>
                                <option value="14.16">14:10 PM</option>
                                <option value="14.25">14:15 PM</option>
                                <option value="14.33">14:20 PM</option>
                                <option value="14.41">14:25 PM</option>
                                <option value="14.50">14:30 PM</option>
                                <option value="14.58">14:35 PM</option>
                                <option value="14.66">14:40 PM</option>
                                <option value="14.75">14:45 PM</option>
                                <option value="14.83">14:50 PM</option>
                                <option value="14.91">14:55 PM</option>
                                <option value="15.00">15:00 PM - o'clock</option>
                                <option value="15.8">15:05 PM</option>
                                <option value="15.16">15:10 PM</option>
                                <option value="15.25">15:15 PM</option>
                                <option value="15.33">15:20 PM</option>
                                <option value="15.41">15:25 PM</option>
                                <option value="15.50">15:30 PM</option>
                                <option value="15.58">15:35 PM</option>
                                <option value="15.66">15:40 PM</option>
                                <option value="15.75">15:45 PM</option>
                                <option value="15.83">15:50 PM</option>
                                <option value="15.91">15:55 PM</option>
                                <option value="16.00">16:00 PM - o'clock</option>
                                <option value="16.8">16:05 PM</option>
                                <option value="16.16">16:10 PM</option>
                                <option value="16.25">16:15 PM</option>
                                <option value="16.33">16:20 PM</option>
                                <option value="16.41">16:25 PM</option>
                                <option value="16.50">16:30 PM</option>
                                <option value="16.58">16:35 PM</option>
                                <option value="16.66">16:40 PM</option>
                                <option value="16.75">16:45 PM</option>
                                <option value="16.83">16:50 PM</option>
                                <option value="16.91">16:55 PM</option>
                                <option value="17.00">17:00 PM - o'clock</option>
                                <option value="17.8">17:05 PM</option>
                                <option value="17.16">17:10 PM</option>
                                <option value="17.25">17:15 PM</option>
                                <option value="17.33">17:20 PM</option>
                                <option value="17.41">17:25 PM</option>
                                <option value="17.50">17:30 PM</option>
                                <option value="17.58">17:35 PM</option>
                                <option value="17.66">17:40 PM</option>
                                <option value="17.75">17:45 PM</option>
                                <option value="17.83">17:50 PM</option>
                                <option value="17.91">17:55 PM</option>
                                <option value="18.00">18:00 PM - Normal</option>
                                <option value="18.8">18:05 PM</option>
                                <option value="18.16">18:10 PM</option>
                                <option value="18.25">18:15 PM</option>
                                <option value="18.33">18:20 PM</option>
                                <option value="18.41">18:25 PM</option>
                                <option value="18.50">18:30 PM</option>
                                <option value="18.58">18:35 PM</option>
                                <option value="18.66">18:40 PM</option>
                                <option value="18.75">18:45 PM</option>
                                <option value="18.83">18:50 PM</option>
                                <option value="18.91">18:55 PM</option>
                                <option value="19.00">19:00 PM - o'clock</option>
                                <option value="19.8">19:05 PM</option>
                                <option value="19.16">19:10 PM</option>
                                <option value="19.25">19:15 PM</option>
                                <option value="19.33">19:20 PM</option>
                                <option value="19.41">19:25 PM</option>
                                <option value="19.50">19:30 PM</option>
                                <option value="19.58">19:35 PM</option>
                                <option value="19.66">19:40 PM</option>
                                <option value="19.75">19:45 PM</option>
                                <option value="19.83">19:50 PM</option>
                                <option value="19.91">19:55 PM</option>
                                <option value="20.00">20:00 PM - o'clock</option>
                                <option value="20.8">20:05 PM</option>
                                <option value="20.16">20:10 PM</option>
                                <option value="20.25">20:15 PM</option>
                                <option value="20.33">20:20 PM</option>
                                <option value="20.41">20:25 PM</option>
                                <option value="20.50">20:30 PM</option>
                                <option value="20.58">20:35 PM</option>
                                <option value="20.66">20:40 PM</option>
                                <option value="20.75">20:45 PM</option>
                                <option value="20.83">20:50 PM</option>
                                <option value="20.91">20:55 PM</option>
                                <option value="21.00">21:00 PM - o'clock</option>
                                <option value="21.8">21:05 PM</option>
                                <option value="21.16">21:10 PM</option>
                                <option value="21.25">21:15 PM</option>
                                <option value="21.33">21:20 PM</option>
                                <option value="21.41">21:25 PM</option>
                                <option value="21.50">21:30 PM</option>
                                <option value="21.58">21:35 PM</option>
                                <option value="21.66">21:40 PM</option>
                                <option value="21.75">21:45 PM</option>
                                <option value="21.83">21:50 PM</option>
                                <option value="21.91">21:55 PM</option>
                                <option value="22.00">22:00 PM - o'clock</option>
                                <option value="22.8">22:05 PM</option>
                                <option value="22.16">22:10 PM</option>
                                <option value="22.25">22:15 PM</option>
                                <option value="22.33">22:20 PM</option>
                                <option value="22.41">22:25 PM</option>
                                <option value="22.50">22:30 PM</option>
                                <option value="22.58">22:35 PM</option>
                                <option value="22.66">22:40 PM</option>
                                <option value="22.75">22:45 PM</option>
                                <option value="22.83">22:50 PM</option>
                                <option value="22.91">22:55 PM</option>
                                <option value="23.00">23:00 PM - o'clock</option>
                                <option value="23.8">23:05 PM</option>
                                <option value="23.16">23:10 PM</option>
                                <option value="23.25">23:15 PM</option>
                                <option value="23.33">23:20 PM</option>
                                <option value="23.41">23:25 PM</option>
                                <option value="23.50">23:30 PM</option>
                                <option value="23.58">23:35 PM</option>
                                <option value="23.66">23:40 PM</option>
                                <option value="23.75">23:45 PM</option>
                                <option value="23.83">23:50 PM</option>
                                <option value="23.91">23:55 PM</option>
                                <option value="24.00">24:00 PM - o'clock</option>
                                <option value="24.8">15:05 PM</option>
                                <option value="24.16">24:10 PM</option>
                                <option value="24.25">24:15 PM</option>
                                <option value="24.33">24:20 PM</option>
                                <option value="24.41">24:25 PM</option>
                                <option value="24.50">24:30 PM</option>
                                <option value="24.58">24:35 PM</option>
                                <option value="24.66">24:40 PM</option>
                                <option value="24.75">24:45 PM</option>
                                <option value="24.83">24:50 PM</option>
                                <option value="24.91">24:55 PM</option>
                            </select>
                            {data2.map((datas, index) =>{
                              return<div key={index}>
                                        <p>{datas.starthour}</p>
                                    </div>
                                })}
                        </div>
                        <div className="col-md-2">
                            <select name="endhour2" onChange={e =>  setEnd2(e.target.value)} className="form-control">
                                <option value="">Finish Time</option>
                                <option value="05.00">05:00 AM</option>
                                <option value="06.00">06:00 AM</option>
                                <option value="06.8">06:05 AM</option>
                                <option value="06.16">06:10 AM</option>
                                <option value="06.25">06:15 AM</option>
                                <option value="06.33">06:20 AM</option>
                                <option value="06.41">06:25 AM</option>
                                <option value="06.50">06:30 AM</option>
                                <option value="06.58">06:35 AM</option>
                                <option value="06.66">06:40 AM</option>
                                <option value="06.75">06:45 AM</option>
                                <option value="06.83">06:50 AM</option>
                                <option value="06.91">06:55 AM</option>
                                <option value="07.00">07:00 AM - Normal</option>
                                <option value="07.8">07:05 AM</option>
                                <option value="07.16">07:10 AM</option>
                                <option value="07.25">07:15 AM</option>
                                <option value="07.33">07:20 AM</option>
                                <option value="07.41">07:25 AM</option>
                                <option value="07.50">07:30 AM</option>
                                <option value="07.58">07:35 AM</option>
                                <option value="07.66">07:40 AM</option>
                                <option value="07.75">07:45 AM</option>
                                <option value="07.83">07:50 AM</option>
                                <option value="07.91">07:55 AM</option>
                                <option value="08.00">08:00 AM - o'clock</option>
                                <option value="08.8">08:05 AM</option>
                                <option value="08.16">08:10 AM</option>
                                <option value="08.25">08:15 AM</option>
                                <option value="08.33">08:20 AM</option>
                                <option value="08.41">08:25 AM</option>
                                <option value="08.50">08:30 AM</option>
                                <option value="08.58">08:35 AM</option>
                                <option value="08.66">08:40 AM</option>
                                <option value="08.75">08:45 AM</option>
                                <option value="08.83">08:50 AM</option>
                                <option value="08.91">08:55 AM</option>
                                <option value="09.00">09:00 AM - o'clock</option>
                                <option value="09.8">09:05 AM</option>
                                <option value="09.16">09:10 AM</option>
                                <option value="09.25">09:15 AM</option>
                                <option value="09.33">09:20 AM</option>
                                <option value="09.41">09:25 AM</option>
                                <option value="09.50">09:30 AM</option>
                                <option value="09.58">09:35 AM</option>
                                <option value="09.66">09:40 AM</option>
                                <option value="09.75">09:45 AM</option>
                                <option value="09.83">09:50 AM</option>
                                <option value="09.91">09:55 AM</option>
                                <option value="10.00">10:00 AM - o'clock</option>
                                <option value="10.8">10:05 AM</option>
                                <option value="10.16">10:10 AM</option>
                                <option value="10.25">10:15 AM</option>
                                <option value="10.33">10:20 AM</option>
                                <option value="10.41">10:25 AM</option>
                                <option value="10.50">10:30 AM</option>
                                <option value="10.58">10:35 AM</option>
                                <option value="10.66">10:40 AM</option>
                                <option value="10.75">10:45 AM</option>
                                <option value="10.83">10:50 AM</option>
                                <option value="10.91">10:55 AM</option>
                                <option value="11.00">11:00 AM - o'clock</option>
                                <option value="11.8">11:05 AM</option>
                                <option value="11.16">11:10 AM</option>
                                <option value="11.25">11:15 AM</option>
                                <option value="11.33">11:20 AM</option>
                                <option value="11.41">11:25 AM</option>
                                <option value="11.50">11:30 AM</option>
                                <option value="11.58">11:35 AM</option>
                                <option value="11.66">11:40 AM</option>
                                <option value="11.75">11:45 AM</option>
                                <option value="11.83">11:50 AM</option>
                                <option value="11.91">11:55 AM</option>
                                <option value="12.00">12:00 PM  - o'clock</option>
                                <option value="12.8">12:05 PM</option>
                                <option value="12.16">12:10 PM</option>
                                <option value="12.25">12:15 PM</option>
                                <option value="12.33">12:20 PM</option>
                                <option value="12.41">12:25 PM</option>
                                <option value="12.50">12:30 PM</option>
                                <option value="12.58">12:35 PM</option>
                                <option value="12.66">12:40 PM</option>
                                <option value="12.75">12:45 PM</option>
                                <option value="12.83">12:50 PM</option>
                                <option value="12.91">12:55 PM</option>
                                <option value="13.00">13:00 PM - o'clock</option>
                                <option value="13.8">13:05 PM</option>
                                <option value="13.16">13:10 PM</option>
                                <option value="13.25">13:15 PM</option>
                                <option value="13.33">13:20 PM</option>
                                <option value="13.41">13:25 PM</option>
                                <option value="13.50">13:30 PM</option>
                                <option value="13.58">13:35 PM</option>
                                <option value="13.66">13:40 PM</option>
                                <option value="13.75">13:45 PM</option>
                                <option value="13.83">13:50 PM</option>
                                <option value="13.91">13:55 PM</option>
                                <option value="14.00">14:00 PM - o'clock</option>
                                <option value="14.8">14:05 PM</option>
                                <option value="14.16">14:10 PM</option>
                                <option value="14.25">14:15 PM</option>
                                <option value="14.33">14:20 PM</option>
                                <option value="14.41">14:25 PM</option>
                                <option value="14.50">14:30 PM</option>
                                <option value="14.58">14:35 PM</option>
                                <option value="14.66">14:40 PM</option>
                                <option value="14.75">14:45 PM</option>
                                <option value="14.83">14:50 PM</option>
                                <option value="14.91">14:55 PM</option>
                                <option value="15.00">15:00 PM - o'clock</option>
                                <option value="15.8">15:05 PM</option>
                                <option value="15.16">15:10 PM</option>
                                <option value="15.25">15:15 PM</option>
                                <option value="15.33">15:20 PM</option>
                                <option value="15.41">15:25 PM</option>
                                <option value="15.50">15:30 PM</option>
                                <option value="15.58">15:35 PM</option>
                                <option value="15.66">15:40 PM</option>
                                <option value="15.75">15:45 PM</option>
                                <option value="15.83">15:50 PM</option>
                                <option value="15.91">15:55 PM</option>
                                <option value="16.00">16:00 PM - o'clock</option>
                                <option value="16.8">16:05 PM</option>
                                <option value="16.16">16:10 PM</option>
                                <option value="16.25">16:15 PM</option>
                                <option value="16.33">16:20 PM</option>
                                <option value="16.41">16:25 PM</option>
                                <option value="16.50">16:30 PM</option>
                                <option value="16.58">16:35 PM</option>
                                <option value="16.66">16:40 PM</option>
                                <option value="16.75">16:45 PM</option>
                                <option value="16.83">16:50 PM</option>
                                <option value="16.91">16:55 PM</option>
                                <option value="17.00">17:00 PM - o'clock</option>
                                <option value="17.8">17:05 PM</option>
                                <option value="17.16">17:10 PM</option>
                                <option value="17.25">17:15 PM</option>
                                <option value="17.33">17:20 PM</option>
                                <option value="17.41">17:25 PM</option>
                                <option value="17.50">17:30 PM</option>
                                <option value="17.58">17:35 PM</option>
                                <option value="17.66">17:40 PM</option>
                                <option value="17.75">17:45 PM</option>
                                <option value="17.83">17:50 PM</option>
                                <option value="17.91">17:55 PM</option>
                                <option value="18.00">18:00 PM - Normal</option>
                                <option value="18.8">18:05 PM</option>
                                <option value="18.16">18:10 PM</option>
                                <option value="18.25">18:15 PM</option>
                                <option value="18.33">18:20 PM</option>
                                <option value="18.41">18:25 PM</option>
                                <option value="18.50">18:30 PM</option>
                                <option value="18.58">18:35 PM</option>
                                <option value="18.66">18:40 PM</option>
                                <option value="18.75">18:45 PM</option>
                                <option value="18.83">18:50 PM</option>
                                <option value="18.91">18:55 PM</option>
                                <option value="19.00">19:00 PM - o'clock</option>
                                <option value="19.8">19:05 PM</option>
                                <option value="19.16">19:10 PM</option>
                                <option value="19.25">19:15 PM</option>
                                <option value="19.33">19:20 PM</option>
                                <option value="19.41">19:25 PM</option>
                                <option value="19.50">19:30 PM</option>
                                <option value="19.58">19:35 PM</option>
                                <option value="19.66">19:40 PM</option>
                                <option value="19.75">19:45 PM</option>
                                <option value="19.83">19:50 PM</option>
                                <option value="19.91">19:55 PM</option>
                                <option value="20.00">20:00 PM - o'clock</option>
                                <option value="20.8">20:05 PM</option>
                                <option value="20.16">20:10 PM</option>
                                <option value="20.25">20:15 PM</option>
                                <option value="20.33">20:20 PM</option>
                                <option value="20.41">20:25 PM</option>
                                <option value="20.50">20:30 PM</option>
                                <option value="20.58">20:35 PM</option>
                                <option value="20.66">20:40 PM</option>
                                <option value="20.75">20:45 PM</option>
                                <option value="20.83">20:50 PM</option>
                                <option value="20.91">20:55 PM</option>
                                <option value="21.00">21:00 PM - o'clock</option>
                                <option value="21.8">21:05 PM</option>
                                <option value="21.16">21:10 PM</option>
                                <option value="21.25">21:15 PM</option>
                                <option value="21.33">21:20 PM</option>
                                <option value="21.41">21:25 PM</option>
                                <option value="21.50">21:30 PM</option>
                                <option value="21.58">21:35 PM</option>
                                <option value="21.66">21:40 PM</option>
                                <option value="21.75">21:45 PM</option>
                                <option value="21.83">21:50 PM</option>
                                <option value="21.91">21:55 PM</option>
                                <option value="22.00">22:00 PM - o'clock</option>
                                <option value="22.8">22:05 PM</option>
                                <option value="22.16">22:10 PM</option>
                                <option value="22.25">22:15 PM</option>
                                <option value="22.33">22:20 PM</option>
                                <option value="22.41">22:25 PM</option>
                                <option value="22.50">22:30 PM</option>
                                <option value="22.58">22:35 PM</option>
                                <option value="22.66">22:40 PM</option>
                                <option value="22.75">22:45 PM</option>
                                <option value="22.83">22:50 PM</option>
                                <option value="22.91">22:55 PM</option>
                                <option value="23.00">23:00 PM - o'clock</option>
                                <option value="23.8">23:05 PM</option>
                                <option value="23.16">23:10 PM</option>
                                <option value="23.25">23:15 PM</option>
                                <option value="23.33">23:20 PM</option>
                                <option value="23.41">23:25 PM</option>
                                <option value="23.50">23:30 PM</option>
                                <option value="23.58">23:35 PM</option>
                                <option value="23.66">23:40 PM</option>
                                <option value="23.75">23:45 PM</option>
                                <option value="23.83">23:50 PM</option>
                                <option value="23.91">23:55 PM</option>
                                <option value="24.00">24:00 PM - o'clock</option>
                                <option value="24.8">15:05 PM</option>
                                <option value="24.16">24:10 PM</option>
                                <option value="24.25">24:15 PM</option>
                                <option value="24.33">24:20 PM</option>
                                <option value="24.41">24:25 PM</option>
                                <option value="24.50">24:30 PM</option>
                                <option value="24.58">24:35 PM</option>
                                <option value="24.66">24:40 PM</option>
                                <option value="24.75">24:45 PM</option>
                                <option value="24.83">24:50 PM</option>
                                <option value="24.91">24:55 PM</option>
                            </select>
                            {data2.map((datas, index) =>{
                              return<div key={index}>
                                        <p>{datas.endhour}</p>
                                    </div>
                                })}
                        </div>
                        <div className="col-md-2">
                            <select name="type2" onChange={e =>  settype2(e.target.value)} className="form-control" required>
                                <option value="">Select Type</option>
                                <option value="Normal">Normal</option>
                                <option value="SJUK">SJUK</option>
                                <option value="Holiday">Holiday</option>
                            </select>
                            {data2.map((datas, index) =>{
                              return<div key={index}>
                                        <p>{datas.type}</p>
                                    </div>
                                })}
                          </div>
                          <div className="col-md-2"> 
                              <button type='submit' className="btn btn-primary">Submit</button>
                        </div>
                      </form>
                  </div>
                  <div className="d-flex flex-column p-2">
                     <form action="" className="row"  onSubmit={handleSubmit3}>
                        <div className="col-md-1"> 03 </div>
                        <div className="col-md-1"> Thursday </div>
                        <div className="col-md-1" > 
                            {data3.map((types, index) =>{
                              return<tr key={index}>
                                   <p style={types.type === "Normal" ? {backgroundColor:'rgba(7, 190, 7, 0.648)', color:'#000', padding:'5px 10px'} : 
                                     (types.type === "Holiday" ? {backgroundColor:'rgba(255, 0, 0, 0.438)', color:'#000', padding:'5px 10px'} : 
                                      {backgroundColor:'#008cff5a', color:'#000', padding:'5px 10px'}) }> {types.type}</p>
                                    </tr>
                                })}
                        </div>
                        <div className="col-md-2">
                            <select name="starthour3" onChange={e =>  setStart3(e.target.value)} className="form-control">
                                <option value="">Start Time</option>
                                <option value="05.00">05:00 AM</option>
                                <option value="06.00">06:00 AM</option>
                                <option value="06.8">06:05 AM</option>
                                <option value="06.16">06:10 AM</option>
                                <option value="06.25">06:15 AM</option>
                                <option value="06.33">06:20 AM</option>
                                <option value="06.41">06:25 AM</option>
                                <option value="06.50">06:30 AM</option>
                                <option value="06.58">06:35 AM</option>
                                <option value="06.66">06:40 AM</option>
                                <option value="06.75">06:45 AM</option>
                                <option value="06.83">06:50 AM</option>
                                <option value="06.91">06:55 AM</option>
                                <option value="07.00">07:00 AM - Normal</option>
                                <option value="07.8">07:05 AM</option>
                                <option value="07.16">07:10 AM</option>
                                <option value="07.25">07:15 AM</option>
                                <option value="07.33">07:20 AM</option>
                                <option value="07.41">07:25 AM</option>
                                <option value="07.50">07:30 AM</option>
                                <option value="07.58">07:35 AM</option>
                                <option value="07.66">07:40 AM</option>
                                <option value="07.75">07:45 AM</option>
                                <option value="07.83">07:50 AM</option>
                                <option value="07.91">07:55 AM</option>
                                <option value="08.00">08:00 AM - o'clock</option>
                                <option value="08.8">08:05 AM</option>
                                <option value="08.16">08:10 AM</option>
                                <option value="08.25">08:15 AM</option>
                                <option value="08.33">08:20 AM</option>
                                <option value="08.41">08:25 AM</option>
                                <option value="08.50">08:30 AM</option>
                                <option value="08.58">08:35 AM</option>
                                <option value="08.66">08:40 AM</option>
                                <option value="08.75">08:45 AM</option>
                                <option value="08.83">08:50 AM</option>
                                <option value="08.91">08:55 AM</option>
                                <option value="09.00">09:00 AM - o'clock</option>
                                <option value="09.8">09:05 AM</option>
                                <option value="09.16">09:10 AM</option>
                                <option value="09.25">09:15 AM</option>
                                <option value="09.33">09:20 AM</option>
                                <option value="09.41">09:25 AM</option>
                                <option value="09.50">09:30 AM</option>
                                <option value="09.58">09:35 AM</option>
                                <option value="09.66">09:40 AM</option>
                                <option value="09.75">09:45 AM</option>
                                <option value="09.83">09:50 AM</option>
                                <option value="09.91">09:55 AM</option>
                                <option value="10.00">10:00 AM - o'clock</option>
                                <option value="10.8">10:05 AM</option>
                                <option value="10.16">10:10 AM</option>
                                <option value="10.25">10:15 AM</option>
                                <option value="10.33">10:20 AM</option>
                                <option value="10.41">10:25 AM</option>
                                <option value="10.50">10:30 AM</option>
                                <option value="10.58">10:35 AM</option>
                                <option value="10.66">10:40 AM</option>
                                <option value="10.75">10:45 AM</option>
                                <option value="10.83">10:50 AM</option>
                                <option value="10.91">10:55 AM</option>
                                <option value="11.00">11:00 AM - o'clock</option>
                                <option value="11.8">11:05 AM</option>
                                <option value="11.16">11:10 AM</option>
                                <option value="11.25">11:15 AM</option>
                                <option value="11.33">11:20 AM</option>
                                <option value="11.41">11:25 AM</option>
                                <option value="11.50">11:30 AM</option>
                                <option value="11.58">11:35 AM</option>
                                <option value="11.66">11:40 AM</option>
                                <option value="11.75">11:45 AM</option>
                                <option value="11.83">11:50 AM</option>
                                <option value="11.91">11:55 AM</option>
                                <option value="12.00">12:00 PM  - o'clock</option>
                                <option value="12.8">12:05 PM</option>
                                <option value="12.16">12:10 PM</option>
                                <option value="12.25">12:15 PM</option>
                                <option value="12.33">12:20 PM</option>
                                <option value="12.41">12:25 PM</option>
                                <option value="12.50">12:30 PM</option>
                                <option value="12.58">12:35 PM</option>
                                <option value="12.66">12:40 PM</option>
                                <option value="12.75">12:45 PM</option>
                                <option value="12.83">12:50 PM</option>
                                <option value="12.91">12:55 PM</option>
                                <option value="13.00">13:00 PM - o'clock</option>
                                <option value="13.8">13:05 PM</option>
                                <option value="13.16">13:10 PM</option>
                                <option value="13.25">13:15 PM</option>
                                <option value="13.33">13:20 PM</option>
                                <option value="13.41">13:25 PM</option>
                                <option value="13.50">13:30 PM</option>
                                <option value="13.58">13:35 PM</option>
                                <option value="13.66">13:40 PM</option>
                                <option value="13.75">13:45 PM</option>
                                <option value="13.83">13:50 PM</option>
                                <option value="13.91">13:55 PM</option>
                                <option value="14.00">14:00 PM - o'clock</option>
                                <option value="14.8">14:05 PM</option>
                                <option value="14.16">14:10 PM</option>
                                <option value="14.25">14:15 PM</option>
                                <option value="14.33">14:20 PM</option>
                                <option value="14.41">14:25 PM</option>
                                <option value="14.50">14:30 PM</option>
                                <option value="14.58">14:35 PM</option>
                                <option value="14.66">14:40 PM</option>
                                <option value="14.75">14:45 PM</option>
                                <option value="14.83">14:50 PM</option>
                                <option value="14.91">14:55 PM</option>
                                <option value="15.00">15:00 PM - o'clock</option>
                                <option value="15.8">15:05 PM</option>
                                <option value="15.16">15:10 PM</option>
                                <option value="15.25">15:15 PM</option>
                                <option value="15.33">15:20 PM</option>
                                <option value="15.41">15:25 PM</option>
                                <option value="15.50">15:30 PM</option>
                                <option value="15.58">15:35 PM</option>
                                <option value="15.66">15:40 PM</option>
                                <option value="15.75">15:45 PM</option>
                                <option value="15.83">15:50 PM</option>
                                <option value="15.91">15:55 PM</option>
                                <option value="16.00">16:00 PM - o'clock</option>
                                <option value="16.8">16:05 PM</option>
                                <option value="16.16">16:10 PM</option>
                                <option value="16.25">16:15 PM</option>
                                <option value="16.33">16:20 PM</option>
                                <option value="16.41">16:25 PM</option>
                                <option value="16.50">16:30 PM</option>
                                <option value="16.58">16:35 PM</option>
                                <option value="16.66">16:40 PM</option>
                                <option value="16.75">16:45 PM</option>
                                <option value="16.83">16:50 PM</option>
                                <option value="16.91">16:55 PM</option>
                                <option value="17.00">17:00 PM - o'clock</option>
                                <option value="17.8">17:05 PM</option>
                                <option value="17.16">17:10 PM</option>
                                <option value="17.25">17:15 PM</option>
                                <option value="17.33">17:20 PM</option>
                                <option value="17.41">17:25 PM</option>
                                <option value="17.50">17:30 PM</option>
                                <option value="17.58">17:35 PM</option>
                                <option value="17.66">17:40 PM</option>
                                <option value="17.75">17:45 PM</option>
                                <option value="17.83">17:50 PM</option>
                                <option value="17.91">17:55 PM</option>
                                <option value="18.00">18:00 PM - Normal</option>
                                <option value="18.8">18:05 PM</option>
                                <option value="18.16">18:10 PM</option>
                                <option value="18.25">18:15 PM</option>
                                <option value="18.33">18:20 PM</option>
                                <option value="18.41">18:25 PM</option>
                                <option value="18.50">18:30 PM</option>
                                <option value="18.58">18:35 PM</option>
                                <option value="18.66">18:40 PM</option>
                                <option value="18.75">18:45 PM</option>
                                <option value="18.83">18:50 PM</option>
                                <option value="18.91">18:55 PM</option>
                                <option value="19.00">19:00 PM - o'clock</option>
                                <option value="19.8">19:05 PM</option>
                                <option value="19.16">19:10 PM</option>
                                <option value="19.25">19:15 PM</option>
                                <option value="19.33">19:20 PM</option>
                                <option value="19.41">19:25 PM</option>
                                <option value="19.50">19:30 PM</option>
                                <option value="19.58">19:35 PM</option>
                                <option value="19.66">19:40 PM</option>
                                <option value="19.75">19:45 PM</option>
                                <option value="19.83">19:50 PM</option>
                                <option value="19.91">19:55 PM</option>
                                <option value="20.00">20:00 PM - o'clock</option>
                                <option value="20.8">20:05 PM</option>
                                <option value="20.16">20:10 PM</option>
                                <option value="20.25">20:15 PM</option>
                                <option value="20.33">20:20 PM</option>
                                <option value="20.41">20:25 PM</option>
                                <option value="20.50">20:30 PM</option>
                                <option value="20.58">20:35 PM</option>
                                <option value="20.66">20:40 PM</option>
                                <option value="20.75">20:45 PM</option>
                                <option value="20.83">20:50 PM</option>
                                <option value="20.91">20:55 PM</option>
                                <option value="21.00">21:00 PM - o'clock</option>
                                <option value="21.8">21:05 PM</option>
                                <option value="21.16">21:10 PM</option>
                                <option value="21.25">21:15 PM</option>
                                <option value="21.33">21:20 PM</option>
                                <option value="21.41">21:25 PM</option>
                                <option value="21.50">21:30 PM</option>
                                <option value="21.58">21:35 PM</option>
                                <option value="21.66">21:40 PM</option>
                                <option value="21.75">21:45 PM</option>
                                <option value="21.83">21:50 PM</option>
                                <option value="21.91">21:55 PM</option>
                                <option value="22.00">22:00 PM - o'clock</option>
                                <option value="22.8">22:05 PM</option>
                                <option value="22.16">22:10 PM</option>
                                <option value="22.25">22:15 PM</option>
                                <option value="22.33">22:20 PM</option>
                                <option value="22.41">22:25 PM</option>
                                <option value="22.50">22:30 PM</option>
                                <option value="22.58">22:35 PM</option>
                                <option value="22.66">22:40 PM</option>
                                <option value="22.75">22:45 PM</option>
                                <option value="22.83">22:50 PM</option>
                                <option value="22.91">22:55 PM</option>
                                <option value="23.00">23:00 PM - o'clock</option>
                                <option value="23.8">23:05 PM</option>
                                <option value="23.16">23:10 PM</option>
                                <option value="23.25">23:15 PM</option>
                                <option value="23.33">23:20 PM</option>
                                <option value="23.41">23:25 PM</option>
                                <option value="23.50">23:30 PM</option>
                                <option value="23.58">23:35 PM</option>
                                <option value="23.66">23:40 PM</option>
                                <option value="23.75">23:45 PM</option>
                                <option value="23.83">23:50 PM</option>
                                <option value="23.91">23:55 PM</option>
                                <option value="24.00">24:00 PM - o'clock</option>
                                <option value="24.8">15:05 PM</option>
                                <option value="24.16">24:10 PM</option>
                                <option value="24.25">24:15 PM</option>
                                <option value="24.33">24:20 PM</option>
                                <option value="24.41">24:25 PM</option>
                                <option value="24.50">24:30 PM</option>
                                <option value="24.58">24:35 PM</option>
                                <option value="24.66">24:40 PM</option>
                                <option value="24.75">24:45 PM</option>
                                <option value="24.83">24:50 PM</option>
                                <option value="24.91">24:55 PM</option>
                            </select>
                            {data3.map((datas, index) =>{
                              return<div key={index}>
                                        <p>{datas.starthour}</p>
                                    </div>
                                })}
                        </div>
                        <div className="col-md-2">
                            <select name="endhour3" onChange={e =>  setEnd3(e.target.value)} className="form-control">
                                <option value="">Finish Time</option>
                                <option value="05.00">05:00 AM</option>
                                <option value="06.00">06:00 AM</option>
                                <option value="06.8">06:05 AM</option>
                                <option value="06.16">06:10 AM</option>
                                <option value="06.25">06:15 AM</option>
                                <option value="06.33">06:20 AM</option>
                                <option value="06.41">06:25 AM</option>
                                <option value="06.50">06:30 AM</option>
                                <option value="06.58">06:35 AM</option>
                                <option value="06.66">06:40 AM</option>
                                <option value="06.75">06:45 AM</option>
                                <option value="06.83">06:50 AM</option>
                                <option value="06.91">06:55 AM</option>
                                <option value="07.00">07:00 AM - Normal</option>
                                <option value="07.8">07:05 AM</option>
                                <option value="07.16">07:10 AM</option>
                                <option value="07.25">07:15 AM</option>
                                <option value="07.33">07:20 AM</option>
                                <option value="07.41">07:25 AM</option>
                                <option value="07.50">07:30 AM</option>
                                <option value="07.58">07:35 AM</option>
                                <option value="07.66">07:40 AM</option>
                                <option value="07.75">07:45 AM</option>
                                <option value="07.83">07:50 AM</option>
                                <option value="07.91">07:55 AM</option>
                                <option value="08.00">08:00 AM - o'clock</option>
                                <option value="08.8">08:05 AM</option>
                                <option value="08.16">08:10 AM</option>
                                <option value="08.25">08:15 AM</option>
                                <option value="08.33">08:20 AM</option>
                                <option value="08.41">08:25 AM</option>
                                <option value="08.50">08:30 AM</option>
                                <option value="08.58">08:35 AM</option>
                                <option value="08.66">08:40 AM</option>
                                <option value="08.75">08:45 AM</option>
                                <option value="08.83">08:50 AM</option>
                                <option value="08.91">08:55 AM</option>
                                <option value="09.00">09:00 AM - o'clock</option>
                                <option value="09.8">09:05 AM</option>
                                <option value="09.16">09:10 AM</option>
                                <option value="09.25">09:15 AM</option>
                                <option value="09.33">09:20 AM</option>
                                <option value="09.41">09:25 AM</option>
                                <option value="09.50">09:30 AM</option>
                                <option value="09.58">09:35 AM</option>
                                <option value="09.66">09:40 AM</option>
                                <option value="09.75">09:45 AM</option>
                                <option value="09.83">09:50 AM</option>
                                <option value="09.91">09:55 AM</option>
                                <option value="10.00">10:00 AM - o'clock</option>
                                <option value="10.8">10:05 AM</option>
                                <option value="10.16">10:10 AM</option>
                                <option value="10.25">10:15 AM</option>
                                <option value="10.33">10:20 AM</option>
                                <option value="10.41">10:25 AM</option>
                                <option value="10.50">10:30 AM</option>
                                <option value="10.58">10:35 AM</option>
                                <option value="10.66">10:40 AM</option>
                                <option value="10.75">10:45 AM</option>
                                <option value="10.83">10:50 AM</option>
                                <option value="10.91">10:55 AM</option>
                                <option value="11.00">11:00 AM - o'clock</option>
                                <option value="11.8">11:05 AM</option>
                                <option value="11.16">11:10 AM</option>
                                <option value="11.25">11:15 AM</option>
                                <option value="11.33">11:20 AM</option>
                                <option value="11.41">11:25 AM</option>
                                <option value="11.50">11:30 AM</option>
                                <option value="11.58">11:35 AM</option>
                                <option value="11.66">11:40 AM</option>
                                <option value="11.75">11:45 AM</option>
                                <option value="11.83">11:50 AM</option>
                                <option value="11.91">11:55 AM</option>
                                <option value="12.00">12:00 PM  - o'clock</option>
                                <option value="12.8">12:05 PM</option>
                                <option value="12.16">12:10 PM</option>
                                <option value="12.25">12:15 PM</option>
                                <option value="12.33">12:20 PM</option>
                                <option value="12.41">12:25 PM</option>
                                <option value="12.50">12:30 PM</option>
                                <option value="12.58">12:35 PM</option>
                                <option value="12.66">12:40 PM</option>
                                <option value="12.75">12:45 PM</option>
                                <option value="12.83">12:50 PM</option>
                                <option value="12.91">12:55 PM</option>
                                <option value="13.00">13:00 PM - o'clock</option>
                                <option value="13.8">13:05 PM</option>
                                <option value="13.16">13:10 PM</option>
                                <option value="13.25">13:15 PM</option>
                                <option value="13.33">13:20 PM</option>
                                <option value="13.41">13:25 PM</option>
                                <option value="13.50">13:30 PM</option>
                                <option value="13.58">13:35 PM</option>
                                <option value="13.66">13:40 PM</option>
                                <option value="13.75">13:45 PM</option>
                                <option value="13.83">13:50 PM</option>
                                <option value="13.91">13:55 PM</option>
                                <option value="14.00">14:00 PM - o'clock</option>
                                <option value="14.8">14:05 PM</option>
                                <option value="14.16">14:10 PM</option>
                                <option value="14.25">14:15 PM</option>
                                <option value="14.33">14:20 PM</option>
                                <option value="14.41">14:25 PM</option>
                                <option value="14.50">14:30 PM</option>
                                <option value="14.58">14:35 PM</option>
                                <option value="14.66">14:40 PM</option>
                                <option value="14.75">14:45 PM</option>
                                <option value="14.83">14:50 PM</option>
                                <option value="14.91">14:55 PM</option>
                                <option value="15.00">15:00 PM - o'clock</option>
                                <option value="15.8">15:05 PM</option>
                                <option value="15.16">15:10 PM</option>
                                <option value="15.25">15:15 PM</option>
                                <option value="15.33">15:20 PM</option>
                                <option value="15.41">15:25 PM</option>
                                <option value="15.50">15:30 PM</option>
                                <option value="15.58">15:35 PM</option>
                                <option value="15.66">15:40 PM</option>
                                <option value="15.75">15:45 PM</option>
                                <option value="15.83">15:50 PM</option>
                                <option value="15.91">15:55 PM</option>
                                <option value="16.00">16:00 PM - o'clock</option>
                                <option value="16.8">16:05 PM</option>
                                <option value="16.16">16:10 PM</option>
                                <option value="16.25">16:15 PM</option>
                                <option value="16.33">16:20 PM</option>
                                <option value="16.41">16:25 PM</option>
                                <option value="16.50">16:30 PM</option>
                                <option value="16.58">16:35 PM</option>
                                <option value="16.66">16:40 PM</option>
                                <option value="16.75">16:45 PM</option>
                                <option value="16.83">16:50 PM</option>
                                <option value="16.91">16:55 PM</option>
                                <option value="17.00">17:00 PM - o'clock</option>
                                <option value="17.8">17:05 PM</option>
                                <option value="17.16">17:10 PM</option>
                                <option value="17.25">17:15 PM</option>
                                <option value="17.33">17:20 PM</option>
                                <option value="17.41">17:25 PM</option>
                                <option value="17.50">17:30 PM</option>
                                <option value="17.58">17:35 PM</option>
                                <option value="17.66">17:40 PM</option>
                                <option value="17.75">17:45 PM</option>
                                <option value="17.83">17:50 PM</option>
                                <option value="17.91">17:55 PM</option>
                                <option value="18.00">18:00 PM - Normal</option>
                                <option value="18.8">18:05 PM</option>
                                <option value="18.16">18:10 PM</option>
                                <option value="18.25">18:15 PM</option>
                                <option value="18.33">18:20 PM</option>
                                <option value="18.41">18:25 PM</option>
                                <option value="18.50">18:30 PM</option>
                                <option value="18.58">18:35 PM</option>
                                <option value="18.66">18:40 PM</option>
                                <option value="18.75">18:45 PM</option>
                                <option value="18.83">18:50 PM</option>
                                <option value="18.91">18:55 PM</option>
                                <option value="19.00">19:00 PM - o'clock</option>
                                <option value="19.8">19:05 PM</option>
                                <option value="19.16">19:10 PM</option>
                                <option value="19.25">19:15 PM</option>
                                <option value="19.33">19:20 PM</option>
                                <option value="19.41">19:25 PM</option>
                                <option value="19.50">19:30 PM</option>
                                <option value="19.58">19:35 PM</option>
                                <option value="19.66">19:40 PM</option>
                                <option value="19.75">19:45 PM</option>
                                <option value="19.83">19:50 PM</option>
                                <option value="19.91">19:55 PM</option>
                                <option value="20.00">20:00 PM - o'clock</option>
                                <option value="20.8">20:05 PM</option>
                                <option value="20.16">20:10 PM</option>
                                <option value="20.25">20:15 PM</option>
                                <option value="20.33">20:20 PM</option>
                                <option value="20.41">20:25 PM</option>
                                <option value="20.50">20:30 PM</option>
                                <option value="20.58">20:35 PM</option>
                                <option value="20.66">20:40 PM</option>
                                <option value="20.75">20:45 PM</option>
                                <option value="20.83">20:50 PM</option>
                                <option value="20.91">20:55 PM</option>
                                <option value="21.00">21:00 PM - o'clock</option>
                                <option value="21.8">21:05 PM</option>
                                <option value="21.16">21:10 PM</option>
                                <option value="21.25">21:15 PM</option>
                                <option value="21.33">21:20 PM</option>
                                <option value="21.41">21:25 PM</option>
                                <option value="21.50">21:30 PM</option>
                                <option value="21.58">21:35 PM</option>
                                <option value="21.66">21:40 PM</option>
                                <option value="21.75">21:45 PM</option>
                                <option value="21.83">21:50 PM</option>
                                <option value="21.91">21:55 PM</option>
                                <option value="22.00">22:00 PM - o'clock</option>
                                <option value="22.8">22:05 PM</option>
                                <option value="22.16">22:10 PM</option>
                                <option value="22.25">22:15 PM</option>
                                <option value="22.33">22:20 PM</option>
                                <option value="22.41">22:25 PM</option>
                                <option value="22.50">22:30 PM</option>
                                <option value="22.58">22:35 PM</option>
                                <option value="22.66">22:40 PM</option>
                                <option value="22.75">22:45 PM</option>
                                <option value="22.83">22:50 PM</option>
                                <option value="22.91">22:55 PM</option>
                                <option value="23.00">23:00 PM - o'clock</option>
                                <option value="23.8">23:05 PM</option>
                                <option value="23.16">23:10 PM</option>
                                <option value="23.25">23:15 PM</option>
                                <option value="23.33">23:20 PM</option>
                                <option value="23.41">23:25 PM</option>
                                <option value="23.50">23:30 PM</option>
                                <option value="23.58">23:35 PM</option>
                                <option value="23.66">23:40 PM</option>
                                <option value="23.75">23:45 PM</option>
                                <option value="23.83">23:50 PM</option>
                                <option value="23.91">23:55 PM</option>
                                <option value="24.00">24:00 PM - o'clock</option>
                                <option value="24.8">15:05 PM</option>
                                <option value="24.16">24:10 PM</option>
                                <option value="24.25">24:15 PM</option>
                                <option value="24.33">24:20 PM</option>
                                <option value="24.41">24:25 PM</option>
                                <option value="24.50">24:30 PM</option>
                                <option value="24.58">24:35 PM</option>
                                <option value="24.66">24:40 PM</option>
                                <option value="24.75">24:45 PM</option>
                                <option value="24.83">24:50 PM</option>
                                <option value="24.91">24:55 PM</option>
                            </select>
                            {data3.map((datas, index) =>{
                              return<div key={index}>
                                        <p>{datas.endhour}</p>
                                    </div>
                                })}
                        </div>
                        <div className="col-md-2">
                            <select name="type3" onChange={e =>  settype3(e.target.value)} className="form-control" required>
                                <option value="">Select Type</option>
                                <option value="Normal">Normal</option>
                                <option value="SJUK">SJUK</option>
                                <option value="Holiday">Holiday</option>
                            </select>
                            {data3.map((datas, index) =>{
                              return<div key={index}>
                                        <p>{datas.type}</p>
                                    </div>
                                })}
                          </div>
                          <div className="col-md-2"> 
                              <button type='submit' className="btn btn-primary">Submit</button>
                        </div>
                      </form>
                  </div>
                  <div className="d-flex flex-column p-2">
                     <form action="" className="row"  onSubmit={handleSubmit4}>
                        <div className="col-md-1"> 04 </div>
                        <div className="col-md-1"> Friday </div>
                        <div className="col-md-1" > 
                            {data4.map((types, index) =>{
                              return<tr key={index}>
                                   <p style={types.type === "Normal" ? {backgroundColor:'rgba(7, 190, 7, 0.648)', color:'#000', padding:'5px 10px'} : 
                                     (types.type === "Holiday" ? {backgroundColor:'rgba(255, 0, 0, 0.438)', color:'#000', padding:'5px 10px'} : 
                                      {backgroundColor:'#008cff5a', color:'#000', padding:'5px 10px'}) }> {types.type}</p>
                                    </tr>
                                })}
                        </div>
                        <div className="col-md-2">
                            <select name="starthour4" onChange={e =>  setStart4(e.target.value)} className="form-control">
                                <option value="">Start Time</option>
                                <option value="05.00">05:00 AM</option>
                                <option value="06.00">06:00 AM</option>
                                <option value="06.8">06:05 AM</option>
                                <option value="06.16">06:10 AM</option>
                                <option value="06.25">06:15 AM</option>
                                <option value="06.33">06:20 AM</option>
                                <option value="06.41">06:25 AM</option>
                                <option value="06.50">06:30 AM</option>
                                <option value="06.58">06:35 AM</option>
                                <option value="06.66">06:40 AM</option>
                                <option value="06.75">06:45 AM</option>
                                <option value="06.83">06:50 AM</option>
                                <option value="06.91">06:55 AM</option>
                                <option value="07.00">07:00 AM - Normal</option>
                                <option value="07.8">07:05 AM</option>
                                <option value="07.16">07:10 AM</option>
                                <option value="07.25">07:15 AM</option>
                                <option value="07.33">07:20 AM</option>
                                <option value="07.41">07:25 AM</option>
                                <option value="07.50">07:30 AM</option>
                                <option value="07.58">07:35 AM</option>
                                <option value="07.66">07:40 AM</option>
                                <option value="07.75">07:45 AM</option>
                                <option value="07.83">07:50 AM</option>
                                <option value="07.91">07:55 AM</option>
                                <option value="08.00">08:00 AM - o'clock</option>
                                <option value="08.8">08:05 AM</option>
                                <option value="08.16">08:10 AM</option>
                                <option value="08.25">08:15 AM</option>
                                <option value="08.33">08:20 AM</option>
                                <option value="08.41">08:25 AM</option>
                                <option value="08.50">08:30 AM</option>
                                <option value="08.58">08:35 AM</option>
                                <option value="08.66">08:40 AM</option>
                                <option value="08.75">08:45 AM</option>
                                <option value="08.83">08:50 AM</option>
                                <option value="08.91">08:55 AM</option>
                                <option value="09.00">09:00 AM - o'clock</option>
                                <option value="09.8">09:05 AM</option>
                                <option value="09.16">09:10 AM</option>
                                <option value="09.25">09:15 AM</option>
                                <option value="09.33">09:20 AM</option>
                                <option value="09.41">09:25 AM</option>
                                <option value="09.50">09:30 AM</option>
                                <option value="09.58">09:35 AM</option>
                                <option value="09.66">09:40 AM</option>
                                <option value="09.75">09:45 AM</option>
                                <option value="09.83">09:50 AM</option>
                                <option value="09.91">09:55 AM</option>
                                <option value="10.00">10:00 AM - o'clock</option>
                                <option value="10.8">10:05 AM</option>
                                <option value="10.16">10:10 AM</option>
                                <option value="10.25">10:15 AM</option>
                                <option value="10.33">10:20 AM</option>
                                <option value="10.41">10:25 AM</option>
                                <option value="10.50">10:30 AM</option>
                                <option value="10.58">10:35 AM</option>
                                <option value="10.66">10:40 AM</option>
                                <option value="10.75">10:45 AM</option>
                                <option value="10.83">10:50 AM</option>
                                <option value="10.91">10:55 AM</option>
                                <option value="11.00">11:00 AM - o'clock</option>
                                <option value="11.8">11:05 AM</option>
                                <option value="11.16">11:10 AM</option>
                                <option value="11.25">11:15 AM</option>
                                <option value="11.33">11:20 AM</option>
                                <option value="11.41">11:25 AM</option>
                                <option value="11.50">11:30 AM</option>
                                <option value="11.58">11:35 AM</option>
                                <option value="11.66">11:40 AM</option>
                                <option value="11.75">11:45 AM</option>
                                <option value="11.83">11:50 AM</option>
                                <option value="11.91">11:55 AM</option>
                                <option value="12.00">12:00 PM  - o'clock</option>
                                <option value="12.8">12:05 PM</option>
                                <option value="12.16">12:10 PM</option>
                                <option value="12.25">12:15 PM</option>
                                <option value="12.33">12:20 PM</option>
                                <option value="12.41">12:25 PM</option>
                                <option value="12.50">12:30 PM</option>
                                <option value="12.58">12:35 PM</option>
                                <option value="12.66">12:40 PM</option>
                                <option value="12.75">12:45 PM</option>
                                <option value="12.83">12:50 PM</option>
                                <option value="12.91">12:55 PM</option>
                                <option value="13.00">13:00 PM - o'clock</option>
                                <option value="13.8">13:05 PM</option>
                                <option value="13.16">13:10 PM</option>
                                <option value="13.25">13:15 PM</option>
                                <option value="13.33">13:20 PM</option>
                                <option value="13.41">13:25 PM</option>
                                <option value="13.50">13:30 PM</option>
                                <option value="13.58">13:35 PM</option>
                                <option value="13.66">13:40 PM</option>
                                <option value="13.75">13:45 PM</option>
                                <option value="13.83">13:50 PM</option>
                                <option value="13.91">13:55 PM</option>
                                <option value="14.00">14:00 PM - o'clock</option>
                                <option value="14.8">14:05 PM</option>
                                <option value="14.16">14:10 PM</option>
                                <option value="14.25">14:15 PM</option>
                                <option value="14.33">14:20 PM</option>
                                <option value="14.41">14:25 PM</option>
                                <option value="14.50">14:30 PM</option>
                                <option value="14.58">14:35 PM</option>
                                <option value="14.66">14:40 PM</option>
                                <option value="14.75">14:45 PM</option>
                                <option value="14.83">14:50 PM</option>
                                <option value="14.91">14:55 PM</option>
                                <option value="15.00">15:00 PM - o'clock</option>
                                <option value="15.8">15:05 PM</option>
                                <option value="15.16">15:10 PM</option>
                                <option value="15.25">15:15 PM</option>
                                <option value="15.33">15:20 PM</option>
                                <option value="15.41">15:25 PM</option>
                                <option value="15.50">15:30 PM</option>
                                <option value="15.58">15:35 PM</option>
                                <option value="15.66">15:40 PM</option>
                                <option value="15.75">15:45 PM</option>
                                <option value="15.83">15:50 PM</option>
                                <option value="15.91">15:55 PM</option>
                                <option value="16.00">16:00 PM - o'clock</option>
                                <option value="16.8">16:05 PM</option>
                                <option value="16.16">16:10 PM</option>
                                <option value="16.25">16:15 PM</option>
                                <option value="16.33">16:20 PM</option>
                                <option value="16.41">16:25 PM</option>
                                <option value="16.50">16:30 PM</option>
                                <option value="16.58">16:35 PM</option>
                                <option value="16.66">16:40 PM</option>
                                <option value="16.75">16:45 PM</option>
                                <option value="16.83">16:50 PM</option>
                                <option value="16.91">16:55 PM</option>
                                <option value="17.00">17:00 PM - o'clock</option>
                                <option value="17.8">17:05 PM</option>
                                <option value="17.16">17:10 PM</option>
                                <option value="17.25">17:15 PM</option>
                                <option value="17.33">17:20 PM</option>
                                <option value="17.41">17:25 PM</option>
                                <option value="17.50">17:30 PM</option>
                                <option value="17.58">17:35 PM</option>
                                <option value="17.66">17:40 PM</option>
                                <option value="17.75">17:45 PM</option>
                                <option value="17.83">17:50 PM</option>
                                <option value="17.91">17:55 PM</option>
                                <option value="18.00">18:00 PM - Normal</option>
                                <option value="18.8">18:05 PM</option>
                                <option value="18.16">18:10 PM</option>
                                <option value="18.25">18:15 PM</option>
                                <option value="18.33">18:20 PM</option>
                                <option value="18.41">18:25 PM</option>
                                <option value="18.50">18:30 PM</option>
                                <option value="18.58">18:35 PM</option>
                                <option value="18.66">18:40 PM</option>
                                <option value="18.75">18:45 PM</option>
                                <option value="18.83">18:50 PM</option>
                                <option value="18.91">18:55 PM</option>
                                <option value="19.00">19:00 PM - o'clock</option>
                                <option value="19.8">19:05 PM</option>
                                <option value="19.16">19:10 PM</option>
                                <option value="19.25">19:15 PM</option>
                                <option value="19.33">19:20 PM</option>
                                <option value="19.41">19:25 PM</option>
                                <option value="19.50">19:30 PM</option>
                                <option value="19.58">19:35 PM</option>
                                <option value="19.66">19:40 PM</option>
                                <option value="19.75">19:45 PM</option>
                                <option value="19.83">19:50 PM</option>
                                <option value="19.91">19:55 PM</option>
                                <option value="20.00">20:00 PM - o'clock</option>
                                <option value="20.8">20:05 PM</option>
                                <option value="20.16">20:10 PM</option>
                                <option value="20.25">20:15 PM</option>
                                <option value="20.33">20:20 PM</option>
                                <option value="20.41">20:25 PM</option>
                                <option value="20.50">20:30 PM</option>
                                <option value="20.58">20:35 PM</option>
                                <option value="20.66">20:40 PM</option>
                                <option value="20.75">20:45 PM</option>
                                <option value="20.83">20:50 PM</option>
                                <option value="20.91">20:55 PM</option>
                                <option value="21.00">21:00 PM - o'clock</option>
                                <option value="21.8">21:05 PM</option>
                                <option value="21.16">21:10 PM</option>
                                <option value="21.25">21:15 PM</option>
                                <option value="21.33">21:20 PM</option>
                                <option value="21.41">21:25 PM</option>
                                <option value="21.50">21:30 PM</option>
                                <option value="21.58">21:35 PM</option>
                                <option value="21.66">21:40 PM</option>
                                <option value="21.75">21:45 PM</option>
                                <option value="21.83">21:50 PM</option>
                                <option value="21.91">21:55 PM</option>
                                <option value="22.00">22:00 PM - o'clock</option>
                                <option value="22.8">22:05 PM</option>
                                <option value="22.16">22:10 PM</option>
                                <option value="22.25">22:15 PM</option>
                                <option value="22.33">22:20 PM</option>
                                <option value="22.41">22:25 PM</option>
                                <option value="22.50">22:30 PM</option>
                                <option value="22.58">22:35 PM</option>
                                <option value="22.66">22:40 PM</option>
                                <option value="22.75">22:45 PM</option>
                                <option value="22.83">22:50 PM</option>
                                <option value="22.91">22:55 PM</option>
                                <option value="23.00">23:00 PM - o'clock</option>
                                <option value="23.8">23:05 PM</option>
                                <option value="23.16">23:10 PM</option>
                                <option value="23.25">23:15 PM</option>
                                <option value="23.33">23:20 PM</option>
                                <option value="23.41">23:25 PM</option>
                                <option value="23.50">23:30 PM</option>
                                <option value="23.58">23:35 PM</option>
                                <option value="23.66">23:40 PM</option>
                                <option value="23.75">23:45 PM</option>
                                <option value="23.83">23:50 PM</option>
                                <option value="23.91">23:55 PM</option>
                                <option value="24.00">24:00 PM - o'clock</option>
                                <option value="24.8">15:05 PM</option>
                                <option value="24.16">24:10 PM</option>
                                <option value="24.25">24:15 PM</option>
                                <option value="24.33">24:20 PM</option>
                                <option value="24.41">24:25 PM</option>
                                <option value="24.50">24:30 PM</option>
                                <option value="24.58">24:35 PM</option>
                                <option value="24.66">24:40 PM</option>
                                <option value="24.75">24:45 PM</option>
                                <option value="24.83">24:50 PM</option>
                                <option value="24.91">24:55 PM</option>
                            </select>
                            {data4.map((datas, index) =>{
                              return<div key={index}>
                                        <p>{datas.starthour}</p>
                                    </div>
                                })}
                        </div>
                        <div className="col-md-2">
                            <select name="endhour4" onChange={e =>  setEnd4(e.target.value)} className="form-control">
                                <option value="">Finish Time</option>
                                <option value="05.00">05:00 AM</option>
                                <option value="06.00">06:00 AM</option>
                                <option value="06.8">06:05 AM</option>
                                <option value="06.16">06:10 AM</option>
                                <option value="06.25">06:15 AM</option>
                                <option value="06.33">06:20 AM</option>
                                <option value="06.41">06:25 AM</option>
                                <option value="06.50">06:30 AM</option>
                                <option value="06.58">06:35 AM</option>
                                <option value="06.66">06:40 AM</option>
                                <option value="06.75">06:45 AM</option>
                                <option value="06.83">06:50 AM</option>
                                <option value="06.91">06:55 AM</option>
                                <option value="07.00">07:00 AM - Normal</option>
                                <option value="07.8">07:05 AM</option>
                                <option value="07.16">07:10 AM</option>
                                <option value="07.25">07:15 AM</option>
                                <option value="07.33">07:20 AM</option>
                                <option value="07.41">07:25 AM</option>
                                <option value="07.50">07:30 AM</option>
                                <option value="07.58">07:35 AM</option>
                                <option value="07.66">07:40 AM</option>
                                <option value="07.75">07:45 AM</option>
                                <option value="07.83">07:50 AM</option>
                                <option value="07.91">07:55 AM</option>
                                <option value="08.00">08:00 AM - o'clock</option>
                                <option value="08.8">08:05 AM</option>
                                <option value="08.16">08:10 AM</option>
                                <option value="08.25">08:15 AM</option>
                                <option value="08.33">08:20 AM</option>
                                <option value="08.41">08:25 AM</option>
                                <option value="08.50">08:30 AM</option>
                                <option value="08.58">08:35 AM</option>
                                <option value="08.66">08:40 AM</option>
                                <option value="08.75">08:45 AM</option>
                                <option value="08.83">08:50 AM</option>
                                <option value="08.91">08:55 AM</option>
                                <option value="09.00">09:00 AM - o'clock</option>
                                <option value="09.8">09:05 AM</option>
                                <option value="09.16">09:10 AM</option>
                                <option value="09.25">09:15 AM</option>
                                <option value="09.33">09:20 AM</option>
                                <option value="09.41">09:25 AM</option>
                                <option value="09.50">09:30 AM</option>
                                <option value="09.58">09:35 AM</option>
                                <option value="09.66">09:40 AM</option>
                                <option value="09.75">09:45 AM</option>
                                <option value="09.83">09:50 AM</option>
                                <option value="09.91">09:55 AM</option>
                                <option value="10.00">10:00 AM - o'clock</option>
                                <option value="10.8">10:05 AM</option>
                                <option value="10.16">10:10 AM</option>
                                <option value="10.25">10:15 AM</option>
                                <option value="10.33">10:20 AM</option>
                                <option value="10.41">10:25 AM</option>
                                <option value="10.50">10:30 AM</option>
                                <option value="10.58">10:35 AM</option>
                                <option value="10.66">10:40 AM</option>
                                <option value="10.75">10:45 AM</option>
                                <option value="10.83">10:50 AM</option>
                                <option value="10.91">10:55 AM</option>
                                <option value="11.00">11:00 AM - o'clock</option>
                                <option value="11.8">11:05 AM</option>
                                <option value="11.16">11:10 AM</option>
                                <option value="11.25">11:15 AM</option>
                                <option value="11.33">11:20 AM</option>
                                <option value="11.41">11:25 AM</option>
                                <option value="11.50">11:30 AM</option>
                                <option value="11.58">11:35 AM</option>
                                <option value="11.66">11:40 AM</option>
                                <option value="11.75">11:45 AM</option>
                                <option value="11.83">11:50 AM</option>
                                <option value="11.91">11:55 AM</option>
                                <option value="12.00">12:00 PM  - o'clock</option>
                                <option value="12.8">12:05 PM</option>
                                <option value="12.16">12:10 PM</option>
                                <option value="12.25">12:15 PM</option>
                                <option value="12.33">12:20 PM</option>
                                <option value="12.41">12:25 PM</option>
                                <option value="12.50">12:30 PM</option>
                                <option value="12.58">12:35 PM</option>
                                <option value="12.66">12:40 PM</option>
                                <option value="12.75">12:45 PM</option>
                                <option value="12.83">12:50 PM</option>
                                <option value="12.91">12:55 PM</option>
                                <option value="13.00">13:00 PM - o'clock</option>
                                <option value="13.8">13:05 PM</option>
                                <option value="13.16">13:10 PM</option>
                                <option value="13.25">13:15 PM</option>
                                <option value="13.33">13:20 PM</option>
                                <option value="13.41">13:25 PM</option>
                                <option value="13.50">13:30 PM</option>
                                <option value="13.58">13:35 PM</option>
                                <option value="13.66">13:40 PM</option>
                                <option value="13.75">13:45 PM</option>
                                <option value="13.83">13:50 PM</option>
                                <option value="13.91">13:55 PM</option>
                                <option value="14.00">14:00 PM - o'clock</option>
                                <option value="14.8">14:05 PM</option>
                                <option value="14.16">14:10 PM</option>
                                <option value="14.25">14:15 PM</option>
                                <option value="14.33">14:20 PM</option>
                                <option value="14.41">14:25 PM</option>
                                <option value="14.50">14:30 PM</option>
                                <option value="14.58">14:35 PM</option>
                                <option value="14.66">14:40 PM</option>
                                <option value="14.75">14:45 PM</option>
                                <option value="14.83">14:50 PM</option>
                                <option value="14.91">14:55 PM</option>
                                <option value="15.00">15:00 PM - o'clock</option>
                                <option value="15.8">15:05 PM</option>
                                <option value="15.16">15:10 PM</option>
                                <option value="15.25">15:15 PM</option>
                                <option value="15.33">15:20 PM</option>
                                <option value="15.41">15:25 PM</option>
                                <option value="15.50">15:30 PM</option>
                                <option value="15.58">15:35 PM</option>
                                <option value="15.66">15:40 PM</option>
                                <option value="15.75">15:45 PM</option>
                                <option value="15.83">15:50 PM</option>
                                <option value="15.91">15:55 PM</option>
                                <option value="16.00">16:00 PM - o'clock</option>
                                <option value="16.8">16:05 PM</option>
                                <option value="16.16">16:10 PM</option>
                                <option value="16.25">16:15 PM</option>
                                <option value="16.33">16:20 PM</option>
                                <option value="16.41">16:25 PM</option>
                                <option value="16.50">16:30 PM</option>
                                <option value="16.58">16:35 PM</option>
                                <option value="16.66">16:40 PM</option>
                                <option value="16.75">16:45 PM</option>
                                <option value="16.83">16:50 PM</option>
                                <option value="16.91">16:55 PM</option>
                                <option value="17.00">17:00 PM - o'clock</option>
                                <option value="17.8">17:05 PM</option>
                                <option value="17.16">17:10 PM</option>
                                <option value="17.25">17:15 PM</option>
                                <option value="17.33">17:20 PM</option>
                                <option value="17.41">17:25 PM</option>
                                <option value="17.50">17:30 PM</option>
                                <option value="17.58">17:35 PM</option>
                                <option value="17.66">17:40 PM</option>
                                <option value="17.75">17:45 PM</option>
                                <option value="17.83">17:50 PM</option>
                                <option value="17.91">17:55 PM</option>
                                <option value="18.00">18:00 PM - Normal</option>
                                <option value="18.8">18:05 PM</option>
                                <option value="18.16">18:10 PM</option>
                                <option value="18.25">18:15 PM</option>
                                <option value="18.33">18:20 PM</option>
                                <option value="18.41">18:25 PM</option>
                                <option value="18.50">18:30 PM</option>
                                <option value="18.58">18:35 PM</option>
                                <option value="18.66">18:40 PM</option>
                                <option value="18.75">18:45 PM</option>
                                <option value="18.83">18:50 PM</option>
                                <option value="18.91">18:55 PM</option>
                                <option value="19.00">19:00 PM - o'clock</option>
                                <option value="19.8">19:05 PM</option>
                                <option value="19.16">19:10 PM</option>
                                <option value="19.25">19:15 PM</option>
                                <option value="19.33">19:20 PM</option>
                                <option value="19.41">19:25 PM</option>
                                <option value="19.50">19:30 PM</option>
                                <option value="19.58">19:35 PM</option>
                                <option value="19.66">19:40 PM</option>
                                <option value="19.75">19:45 PM</option>
                                <option value="19.83">19:50 PM</option>
                                <option value="19.91">19:55 PM</option>
                                <option value="20.00">20:00 PM - o'clock</option>
                                <option value="20.8">20:05 PM</option>
                                <option value="20.16">20:10 PM</option>
                                <option value="20.25">20:15 PM</option>
                                <option value="20.33">20:20 PM</option>
                                <option value="20.41">20:25 PM</option>
                                <option value="20.50">20:30 PM</option>
                                <option value="20.58">20:35 PM</option>
                                <option value="20.66">20:40 PM</option>
                                <option value="20.75">20:45 PM</option>
                                <option value="20.83">20:50 PM</option>
                                <option value="20.91">20:55 PM</option>
                                <option value="21.00">21:00 PM - o'clock</option>
                                <option value="21.8">21:05 PM</option>
                                <option value="21.16">21:10 PM</option>
                                <option value="21.25">21:15 PM</option>
                                <option value="21.33">21:20 PM</option>
                                <option value="21.41">21:25 PM</option>
                                <option value="21.50">21:30 PM</option>
                                <option value="21.58">21:35 PM</option>
                                <option value="21.66">21:40 PM</option>
                                <option value="21.75">21:45 PM</option>
                                <option value="21.83">21:50 PM</option>
                                <option value="21.91">21:55 PM</option>
                                <option value="22.00">22:00 PM - o'clock</option>
                                <option value="22.8">22:05 PM</option>
                                <option value="22.16">22:10 PM</option>
                                <option value="22.25">22:15 PM</option>
                                <option value="22.33">22:20 PM</option>
                                <option value="22.41">22:25 PM</option>
                                <option value="22.50">22:30 PM</option>
                                <option value="22.58">22:35 PM</option>
                                <option value="22.66">22:40 PM</option>
                                <option value="22.75">22:45 PM</option>
                                <option value="22.83">22:50 PM</option>
                                <option value="22.91">22:55 PM</option>
                                <option value="23.00">23:00 PM - o'clock</option>
                                <option value="23.8">23:05 PM</option>
                                <option value="23.16">23:10 PM</option>
                                <option value="23.25">23:15 PM</option>
                                <option value="23.33">23:20 PM</option>
                                <option value="23.41">23:25 PM</option>
                                <option value="23.50">23:30 PM</option>
                                <option value="23.58">23:35 PM</option>
                                <option value="23.66">23:40 PM</option>
                                <option value="23.75">23:45 PM</option>
                                <option value="23.83">23:50 PM</option>
                                <option value="23.91">23:55 PM</option>
                                <option value="24.00">24:00 PM - o'clock</option>
                                <option value="24.8">15:05 PM</option>
                                <option value="24.16">24:10 PM</option>
                                <option value="24.25">24:15 PM</option>
                                <option value="24.33">24:20 PM</option>
                                <option value="24.41">24:25 PM</option>
                                <option value="24.50">24:30 PM</option>
                                <option value="24.58">24:35 PM</option>
                                <option value="24.66">24:40 PM</option>
                                <option value="24.75">24:45 PM</option>
                                <option value="24.83">24:50 PM</option>
                                <option value="24.91">24:55 PM</option>
                            </select>
                            {data4.map((datas, index) =>{
                              return<div key={index}>
                                        <p>{datas.endhour}</p>
                                    </div>
                                })}
                        </div>
                        <div className="col-md-2">
                            <select name="type4" onChange={e =>  settype4(e.target.value)} className="form-control" required>
                                <option value="">Select Type</option>
                                <option value="Normal">Normal</option>
                                <option value="SJUK">SJUK</option>
                                <option value="Holiday">Holiday</option>
                            </select>
                            {data4.map((datas, index) =>{
                              return<div key={index}>
                                        <p>{datas.type}</p>
                                    </div>
                                })}
                          </div>
                          <div className="col-md-2"> 
                              <button type='submit' className="btn btn-primary">Submit</button>
                        </div>
                      </form>
                  </div>
                  <div className="d-flex flex-column p-2">
                     <form action="" className="row"  onSubmit={handleSubmit5}>
                        <div className="col-md-1"> 05 </div>
                        <div className="col-md-1"> Saturday </div>
                        <div className="col-md-1" > 
                            {data5.map((types, index) =>{
                              return<tr key={index}>
                                   <p style={types.type === "Normal" ? {backgroundColor:'rgba(7, 190, 7, 0.648)', color:'#000', padding:'5px 10px'} : 
                                     (types.type === "Holiday" ? {backgroundColor:'rgba(255, 0, 0, 0.438)', color:'#000', padding:'5px 10px'} : 
                                      {backgroundColor:'#008cff5a', color:'#000', padding:'5px 10px'}) }> {types.type}</p>
                                    </tr>
                                })}
                        </div>
                        <div className="col-md-2">
                            <select name="starthour5" onChange={e =>  setStart5(e.target.value)} className="form-control">
                                <option value="">Start Time</option>
                                <option value="05.00">05:00 AM</option>
                                <option value="06.00">06:00 AM</option>
                                <option value="06.8">06:05 AM</option>
                                <option value="06.16">06:10 AM</option>
                                <option value="06.25">06:15 AM</option>
                                <option value="06.33">06:20 AM</option>
                                <option value="06.41">06:25 AM</option>
                                <option value="06.50">06:30 AM</option>
                                <option value="06.58">06:35 AM</option>
                                <option value="06.66">06:40 AM</option>
                                <option value="06.75">06:45 AM</option>
                                <option value="06.83">06:50 AM</option>
                                <option value="06.91">06:55 AM</option>
                                <option value="07.00">07:00 AM - Normal</option>
                                <option value="07.8">07:05 AM</option>
                                <option value="07.16">07:10 AM</option>
                                <option value="07.25">07:15 AM</option>
                                <option value="07.33">07:20 AM</option>
                                <option value="07.41">07:25 AM</option>
                                <option value="07.50">07:30 AM</option>
                                <option value="07.58">07:35 AM</option>
                                <option value="07.66">07:40 AM</option>
                                <option value="07.75">07:45 AM</option>
                                <option value="07.83">07:50 AM</option>
                                <option value="07.91">07:55 AM</option>
                                <option value="08.00">08:00 AM - o'clock</option>
                                <option value="08.8">08:05 AM</option>
                                <option value="08.16">08:10 AM</option>
                                <option value="08.25">08:15 AM</option>
                                <option value="08.33">08:20 AM</option>
                                <option value="08.41">08:25 AM</option>
                                <option value="08.50">08:30 AM</option>
                                <option value="08.58">08:35 AM</option>
                                <option value="08.66">08:40 AM</option>
                                <option value="08.75">08:45 AM</option>
                                <option value="08.83">08:50 AM</option>
                                <option value="08.91">08:55 AM</option>
                                <option value="09.00">09:00 AM - o'clock</option>
                                <option value="09.8">09:05 AM</option>
                                <option value="09.16">09:10 AM</option>
                                <option value="09.25">09:15 AM</option>
                                <option value="09.33">09:20 AM</option>
                                <option value="09.41">09:25 AM</option>
                                <option value="09.50">09:30 AM</option>
                                <option value="09.58">09:35 AM</option>
                                <option value="09.66">09:40 AM</option>
                                <option value="09.75">09:45 AM</option>
                                <option value="09.83">09:50 AM</option>
                                <option value="09.91">09:55 AM</option>
                                <option value="10.00">10:00 AM - o'clock</option>
                                <option value="10.8">10:05 AM</option>
                                <option value="10.16">10:10 AM</option>
                                <option value="10.25">10:15 AM</option>
                                <option value="10.33">10:20 AM</option>
                                <option value="10.41">10:25 AM</option>
                                <option value="10.50">10:30 AM</option>
                                <option value="10.58">10:35 AM</option>
                                <option value="10.66">10:40 AM</option>
                                <option value="10.75">10:45 AM</option>
                                <option value="10.83">10:50 AM</option>
                                <option value="10.91">10:55 AM</option>
                                <option value="11.00">11:00 AM - o'clock</option>
                                <option value="11.8">11:05 AM</option>
                                <option value="11.16">11:10 AM</option>
                                <option value="11.25">11:15 AM</option>
                                <option value="11.33">11:20 AM</option>
                                <option value="11.41">11:25 AM</option>
                                <option value="11.50">11:30 AM</option>
                                <option value="11.58">11:35 AM</option>
                                <option value="11.66">11:40 AM</option>
                                <option value="11.75">11:45 AM</option>
                                <option value="11.83">11:50 AM</option>
                                <option value="11.91">11:55 AM</option>
                                <option value="12.00">12:00 PM  - o'clock</option>
                                <option value="12.8">12:05 PM</option>
                                <option value="12.16">12:10 PM</option>
                                <option value="12.25">12:15 PM</option>
                                <option value="12.33">12:20 PM</option>
                                <option value="12.41">12:25 PM</option>
                                <option value="12.50">12:30 PM</option>
                                <option value="12.58">12:35 PM</option>
                                <option value="12.66">12:40 PM</option>
                                <option value="12.75">12:45 PM</option>
                                <option value="12.83">12:50 PM</option>
                                <option value="12.91">12:55 PM</option>
                                <option value="13.00">13:00 PM - o'clock</option>
                                <option value="13.8">13:05 PM</option>
                                <option value="13.16">13:10 PM</option>
                                <option value="13.25">13:15 PM</option>
                                <option value="13.33">13:20 PM</option>
                                <option value="13.41">13:25 PM</option>
                                <option value="13.50">13:30 PM</option>
                                <option value="13.58">13:35 PM</option>
                                <option value="13.66">13:40 PM</option>
                                <option value="13.75">13:45 PM</option>
                                <option value="13.83">13:50 PM</option>
                                <option value="13.91">13:55 PM</option>
                                <option value="14.00">14:00 PM - o'clock</option>
                                <option value="14.8">14:05 PM</option>
                                <option value="14.16">14:10 PM</option>
                                <option value="14.25">14:15 PM</option>
                                <option value="14.33">14:20 PM</option>
                                <option value="14.41">14:25 PM</option>
                                <option value="14.50">14:30 PM</option>
                                <option value="14.58">14:35 PM</option>
                                <option value="14.66">14:40 PM</option>
                                <option value="14.75">14:45 PM</option>
                                <option value="14.83">14:50 PM</option>
                                <option value="14.91">14:55 PM</option>
                                <option value="15.00">15:00 PM - o'clock</option>
                                <option value="15.8">15:05 PM</option>
                                <option value="15.16">15:10 PM</option>
                                <option value="15.25">15:15 PM</option>
                                <option value="15.33">15:20 PM</option>
                                <option value="15.41">15:25 PM</option>
                                <option value="15.50">15:30 PM</option>
                                <option value="15.58">15:35 PM</option>
                                <option value="15.66">15:40 PM</option>
                                <option value="15.75">15:45 PM</option>
                                <option value="15.83">15:50 PM</option>
                                <option value="15.91">15:55 PM</option>
                                <option value="16.00">16:00 PM - o'clock</option>
                                <option value="16.8">16:05 PM</option>
                                <option value="16.16">16:10 PM</option>
                                <option value="16.25">16:15 PM</option>
                                <option value="16.33">16:20 PM</option>
                                <option value="16.41">16:25 PM</option>
                                <option value="16.50">16:30 PM</option>
                                <option value="16.58">16:35 PM</option>
                                <option value="16.66">16:40 PM</option>
                                <option value="16.75">16:45 PM</option>
                                <option value="16.83">16:50 PM</option>
                                <option value="16.91">16:55 PM</option>
                                <option value="17.00">17:00 PM - o'clock</option>
                                <option value="17.8">17:05 PM</option>
                                <option value="17.16">17:10 PM</option>
                                <option value="17.25">17:15 PM</option>
                                <option value="17.33">17:20 PM</option>
                                <option value="17.41">17:25 PM</option>
                                <option value="17.50">17:30 PM</option>
                                <option value="17.58">17:35 PM</option>
                                <option value="17.66">17:40 PM</option>
                                <option value="17.75">17:45 PM</option>
                                <option value="17.83">17:50 PM</option>
                                <option value="17.91">17:55 PM</option>
                                <option value="18.00">18:00 PM - Normal</option>
                                <option value="18.8">18:05 PM</option>
                                <option value="18.16">18:10 PM</option>
                                <option value="18.25">18:15 PM</option>
                                <option value="18.33">18:20 PM</option>
                                <option value="18.41">18:25 PM</option>
                                <option value="18.50">18:30 PM</option>
                                <option value="18.58">18:35 PM</option>
                                <option value="18.66">18:40 PM</option>
                                <option value="18.75">18:45 PM</option>
                                <option value="18.83">18:50 PM</option>
                                <option value="18.91">18:55 PM</option>
                                <option value="19.00">19:00 PM - o'clock</option>
                                <option value="19.8">19:05 PM</option>
                                <option value="19.16">19:10 PM</option>
                                <option value="19.25">19:15 PM</option>
                                <option value="19.33">19:20 PM</option>
                                <option value="19.41">19:25 PM</option>
                                <option value="19.50">19:30 PM</option>
                                <option value="19.58">19:35 PM</option>
                                <option value="19.66">19:40 PM</option>
                                <option value="19.75">19:45 PM</option>
                                <option value="19.83">19:50 PM</option>
                                <option value="19.91">19:55 PM</option>
                                <option value="20.00">20:00 PM - o'clock</option>
                                <option value="20.8">20:05 PM</option>
                                <option value="20.16">20:10 PM</option>
                                <option value="20.25">20:15 PM</option>
                                <option value="20.33">20:20 PM</option>
                                <option value="20.41">20:25 PM</option>
                                <option value="20.50">20:30 PM</option>
                                <option value="20.58">20:35 PM</option>
                                <option value="20.66">20:40 PM</option>
                                <option value="20.75">20:45 PM</option>
                                <option value="20.83">20:50 PM</option>
                                <option value="20.91">20:55 PM</option>
                                <option value="21.00">21:00 PM - o'clock</option>
                                <option value="21.8">21:05 PM</option>
                                <option value="21.16">21:10 PM</option>
                                <option value="21.25">21:15 PM</option>
                                <option value="21.33">21:20 PM</option>
                                <option value="21.41">21:25 PM</option>
                                <option value="21.50">21:30 PM</option>
                                <option value="21.58">21:35 PM</option>
                                <option value="21.66">21:40 PM</option>
                                <option value="21.75">21:45 PM</option>
                                <option value="21.83">21:50 PM</option>
                                <option value="21.91">21:55 PM</option>
                                <option value="22.00">22:00 PM - o'clock</option>
                                <option value="22.8">22:05 PM</option>
                                <option value="22.16">22:10 PM</option>
                                <option value="22.25">22:15 PM</option>
                                <option value="22.33">22:20 PM</option>
                                <option value="22.41">22:25 PM</option>
                                <option value="22.50">22:30 PM</option>
                                <option value="22.58">22:35 PM</option>
                                <option value="22.66">22:40 PM</option>
                                <option value="22.75">22:45 PM</option>
                                <option value="22.83">22:50 PM</option>
                                <option value="22.91">22:55 PM</option>
                                <option value="23.00">23:00 PM - o'clock</option>
                                <option value="23.8">23:05 PM</option>
                                <option value="23.16">23:10 PM</option>
                                <option value="23.25">23:15 PM</option>
                                <option value="23.33">23:20 PM</option>
                                <option value="23.41">23:25 PM</option>
                                <option value="23.50">23:30 PM</option>
                                <option value="23.58">23:35 PM</option>
                                <option value="23.66">23:40 PM</option>
                                <option value="23.75">23:45 PM</option>
                                <option value="23.83">23:50 PM</option>
                                <option value="23.91">23:55 PM</option>
                                <option value="24.00">24:00 PM - o'clock</option>
                                <option value="24.8">15:05 PM</option>
                                <option value="24.16">24:10 PM</option>
                                <option value="24.25">24:15 PM</option>
                                <option value="24.33">24:20 PM</option>
                                <option value="24.41">24:25 PM</option>
                                <option value="24.50">24:30 PM</option>
                                <option value="24.58">24:35 PM</option>
                                <option value="24.66">24:40 PM</option>
                                <option value="24.75">24:45 PM</option>
                                <option value="24.83">24:50 PM</option>
                                <option value="24.91">24:55 PM</option>
                            </select>
                            {data5.map((datas, index) =>{
                              return<div key={index}>
                                        <p>{datas.starthour}</p>
                                    </div>
                                })}
                        </div>
                        <div className="col-md-2">
                            <select name="endhour5" onChange={e =>  setEnd5(e.target.value)} className="form-control">
                                <option value="">Finish Time</option>
                                <option value="05.00">05:00 AM</option>
                                <option value="06.00">06:00 AM</option>
                                <option value="06.8">06:05 AM</option>
                                <option value="06.16">06:10 AM</option>
                                <option value="06.25">06:15 AM</option>
                                <option value="06.33">06:20 AM</option>
                                <option value="06.41">06:25 AM</option>
                                <option value="06.50">06:30 AM</option>
                                <option value="06.58">06:35 AM</option>
                                <option value="06.66">06:40 AM</option>
                                <option value="06.75">06:45 AM</option>
                                <option value="06.83">06:50 AM</option>
                                <option value="06.91">06:55 AM</option>
                                <option value="07.00">07:00 AM - Normal</option>
                                <option value="07.8">07:05 AM</option>
                                <option value="07.16">07:10 AM</option>
                                <option value="07.25">07:15 AM</option>
                                <option value="07.33">07:20 AM</option>
                                <option value="07.41">07:25 AM</option>
                                <option value="07.50">07:30 AM</option>
                                <option value="07.58">07:35 AM</option>
                                <option value="07.66">07:40 AM</option>
                                <option value="07.75">07:45 AM</option>
                                <option value="07.83">07:50 AM</option>
                                <option value="07.91">07:55 AM</option>
                                <option value="08.00">08:00 AM - o'clock</option>
                                <option value="08.8">08:05 AM</option>
                                <option value="08.16">08:10 AM</option>
                                <option value="08.25">08:15 AM</option>
                                <option value="08.33">08:20 AM</option>
                                <option value="08.41">08:25 AM</option>
                                <option value="08.50">08:30 AM</option>
                                <option value="08.58">08:35 AM</option>
                                <option value="08.66">08:40 AM</option>
                                <option value="08.75">08:45 AM</option>
                                <option value="08.83">08:50 AM</option>
                                <option value="08.91">08:55 AM</option>
                                <option value="09.00">09:00 AM - o'clock</option>
                                <option value="09.8">09:05 AM</option>
                                <option value="09.16">09:10 AM</option>
                                <option value="09.25">09:15 AM</option>
                                <option value="09.33">09:20 AM</option>
                                <option value="09.41">09:25 AM</option>
                                <option value="09.50">09:30 AM</option>
                                <option value="09.58">09:35 AM</option>
                                <option value="09.66">09:40 AM</option>
                                <option value="09.75">09:45 AM</option>
                                <option value="09.83">09:50 AM</option>
                                <option value="09.91">09:55 AM</option>
                                <option value="10.00">10:00 AM - o'clock</option>
                                <option value="10.8">10:05 AM</option>
                                <option value="10.16">10:10 AM</option>
                                <option value="10.25">10:15 AM</option>
                                <option value="10.33">10:20 AM</option>
                                <option value="10.41">10:25 AM</option>
                                <option value="10.50">10:30 AM</option>
                                <option value="10.58">10:35 AM</option>
                                <option value="10.66">10:40 AM</option>
                                <option value="10.75">10:45 AM</option>
                                <option value="10.83">10:50 AM</option>
                                <option value="10.91">10:55 AM</option>
                                <option value="11.00">11:00 AM - o'clock</option>
                                <option value="11.8">11:05 AM</option>
                                <option value="11.16">11:10 AM</option>
                                <option value="11.25">11:15 AM</option>
                                <option value="11.33">11:20 AM</option>
                                <option value="11.41">11:25 AM</option>
                                <option value="11.50">11:30 AM</option>
                                <option value="11.58">11:35 AM</option>
                                <option value="11.66">11:40 AM</option>
                                <option value="11.75">11:45 AM</option>
                                <option value="11.83">11:50 AM</option>
                                <option value="11.91">11:55 AM</option>
                                <option value="12.00">12:00 PM  - o'clock</option>
                                <option value="12.8">12:05 PM</option>
                                <option value="12.16">12:10 PM</option>
                                <option value="12.25">12:15 PM</option>
                                <option value="12.33">12:20 PM</option>
                                <option value="12.41">12:25 PM</option>
                                <option value="12.50">12:30 PM</option>
                                <option value="12.58">12:35 PM</option>
                                <option value="12.66">12:40 PM</option>
                                <option value="12.75">12:45 PM</option>
                                <option value="12.83">12:50 PM</option>
                                <option value="12.91">12:55 PM</option>
                                <option value="13.00">13:00 PM - o'clock</option>
                                <option value="13.8">13:05 PM</option>
                                <option value="13.16">13:10 PM</option>
                                <option value="13.25">13:15 PM</option>
                                <option value="13.33">13:20 PM</option>
                                <option value="13.41">13:25 PM</option>
                                <option value="13.50">13:30 PM</option>
                                <option value="13.58">13:35 PM</option>
                                <option value="13.66">13:40 PM</option>
                                <option value="13.75">13:45 PM</option>
                                <option value="13.83">13:50 PM</option>
                                <option value="13.91">13:55 PM</option>
                                <option value="14.00">14:00 PM - o'clock</option>
                                <option value="14.8">14:05 PM</option>
                                <option value="14.16">14:10 PM</option>
                                <option value="14.25">14:15 PM</option>
                                <option value="14.33">14:20 PM</option>
                                <option value="14.41">14:25 PM</option>
                                <option value="14.50">14:30 PM</option>
                                <option value="14.58">14:35 PM</option>
                                <option value="14.66">14:40 PM</option>
                                <option value="14.75">14:45 PM</option>
                                <option value="14.83">14:50 PM</option>
                                <option value="14.91">14:55 PM</option>
                                <option value="15.00">15:00 PM - o'clock</option>
                                <option value="15.8">15:05 PM</option>
                                <option value="15.16">15:10 PM</option>
                                <option value="15.25">15:15 PM</option>
                                <option value="15.33">15:20 PM</option>
                                <option value="15.41">15:25 PM</option>
                                <option value="15.50">15:30 PM</option>
                                <option value="15.58">15:35 PM</option>
                                <option value="15.66">15:40 PM</option>
                                <option value="15.75">15:45 PM</option>
                                <option value="15.83">15:50 PM</option>
                                <option value="15.91">15:55 PM</option>
                                <option value="16.00">16:00 PM - o'clock</option>
                                <option value="16.8">16:05 PM</option>
                                <option value="16.16">16:10 PM</option>
                                <option value="16.25">16:15 PM</option>
                                <option value="16.33">16:20 PM</option>
                                <option value="16.41">16:25 PM</option>
                                <option value="16.50">16:30 PM</option>
                                <option value="16.58">16:35 PM</option>
                                <option value="16.66">16:40 PM</option>
                                <option value="16.75">16:45 PM</option>
                                <option value="16.83">16:50 PM</option>
                                <option value="16.91">16:55 PM</option>
                                <option value="17.00">17:00 PM - o'clock</option>
                                <option value="17.8">17:05 PM</option>
                                <option value="17.16">17:10 PM</option>
                                <option value="17.25">17:15 PM</option>
                                <option value="17.33">17:20 PM</option>
                                <option value="17.41">17:25 PM</option>
                                <option value="17.50">17:30 PM</option>
                                <option value="17.58">17:35 PM</option>
                                <option value="17.66">17:40 PM</option>
                                <option value="17.75">17:45 PM</option>
                                <option value="17.83">17:50 PM</option>
                                <option value="17.91">17:55 PM</option>
                                <option value="18.00">18:00 PM - Normal</option>
                                <option value="18.8">18:05 PM</option>
                                <option value="18.16">18:10 PM</option>
                                <option value="18.25">18:15 PM</option>
                                <option value="18.33">18:20 PM</option>
                                <option value="18.41">18:25 PM</option>
                                <option value="18.50">18:30 PM</option>
                                <option value="18.58">18:35 PM</option>
                                <option value="18.66">18:40 PM</option>
                                <option value="18.75">18:45 PM</option>
                                <option value="18.83">18:50 PM</option>
                                <option value="18.91">18:55 PM</option>
                                <option value="19.00">19:00 PM - o'clock</option>
                                <option value="19.8">19:05 PM</option>
                                <option value="19.16">19:10 PM</option>
                                <option value="19.25">19:15 PM</option>
                                <option value="19.33">19:20 PM</option>
                                <option value="19.41">19:25 PM</option>
                                <option value="19.50">19:30 PM</option>
                                <option value="19.58">19:35 PM</option>
                                <option value="19.66">19:40 PM</option>
                                <option value="19.75">19:45 PM</option>
                                <option value="19.83">19:50 PM</option>
                                <option value="19.91">19:55 PM</option>
                                <option value="20.00">20:00 PM - o'clock</option>
                                <option value="20.8">20:05 PM</option>
                                <option value="20.16">20:10 PM</option>
                                <option value="20.25">20:15 PM</option>
                                <option value="20.33">20:20 PM</option>
                                <option value="20.41">20:25 PM</option>
                                <option value="20.50">20:30 PM</option>
                                <option value="20.58">20:35 PM</option>
                                <option value="20.66">20:40 PM</option>
                                <option value="20.75">20:45 PM</option>
                                <option value="20.83">20:50 PM</option>
                                <option value="20.91">20:55 PM</option>
                                <option value="21.00">21:00 PM - o'clock</option>
                                <option value="21.8">21:05 PM</option>
                                <option value="21.16">21:10 PM</option>
                                <option value="21.25">21:15 PM</option>
                                <option value="21.33">21:20 PM</option>
                                <option value="21.41">21:25 PM</option>
                                <option value="21.50">21:30 PM</option>
                                <option value="21.58">21:35 PM</option>
                                <option value="21.66">21:40 PM</option>
                                <option value="21.75">21:45 PM</option>
                                <option value="21.83">21:50 PM</option>
                                <option value="21.91">21:55 PM</option>
                                <option value="22.00">22:00 PM - o'clock</option>
                                <option value="22.8">22:05 PM</option>
                                <option value="22.16">22:10 PM</option>
                                <option value="22.25">22:15 PM</option>
                                <option value="22.33">22:20 PM</option>
                                <option value="22.41">22:25 PM</option>
                                <option value="22.50">22:30 PM</option>
                                <option value="22.58">22:35 PM</option>
                                <option value="22.66">22:40 PM</option>
                                <option value="22.75">22:45 PM</option>
                                <option value="22.83">22:50 PM</option>
                                <option value="22.91">22:55 PM</option>
                                <option value="23.00">23:00 PM - o'clock</option>
                                <option value="23.8">23:05 PM</option>
                                <option value="23.16">23:10 PM</option>
                                <option value="23.25">23:15 PM</option>
                                <option value="23.33">23:20 PM</option>
                                <option value="23.41">23:25 PM</option>
                                <option value="23.50">23:30 PM</option>
                                <option value="23.58">23:35 PM</option>
                                <option value="23.66">23:40 PM</option>
                                <option value="23.75">23:45 PM</option>
                                <option value="23.83">23:50 PM</option>
                                <option value="23.91">23:55 PM</option>
                                <option value="24.00">24:00 PM - o'clock</option>
                                <option value="24.8">15:05 PM</option>
                                <option value="24.16">24:10 PM</option>
                                <option value="24.25">24:15 PM</option>
                                <option value="24.33">24:20 PM</option>
                                <option value="24.41">24:25 PM</option>
                                <option value="24.50">24:30 PM</option>
                                <option value="24.58">24:35 PM</option>
                                <option value="24.66">24:40 PM</option>
                                <option value="24.75">24:45 PM</option>
                                <option value="24.83">24:50 PM</option>
                                <option value="24.91">24:55 PM</option>
                            </select>
                            {data5.map((datas, index) =>{
                              return<div key={index}>
                                        <p>{datas.endhour}</p>
                                    </div>
                                })}
                        </div>
                        <div className="col-md-2">
                            <select name="type5" onChange={e =>  settype5(e.target.value)} className="form-control" required>
                                <option value="">Select Type</option>
                                <option value="Normal">Normal</option>
                                <option value="SJUK">SJUK</option>
                                <option value="Holiday">Holiday</option>
                            </select>
                            {data5.map((datas, index) =>{
                              return<div key={index}>
                                        <p>{datas.type}</p>
                                    </div>
                                })}
                          </div>
                          <div className="col-md-2"> 
                              <button type='submit' className="btn btn-primary">Submit</button>
                        </div>
                      </form>
                  </div>
                  <div className="d-flex flex-column p-2">
                     <form action="" className="row"  onSubmit={handleSubmit6}>
                        <div className="col-md-1"> 06 </div>
                        <div className="col-md-1">  Sunday </div>
                        <div className="col-md-1" > 
                            {data6.map((types, index) =>{
                              return<tr key={index}>
                                   <p style={types.type === "Normal" ? {backgroundColor:'rgba(7, 190, 7, 0.648)', color:'#000', padding:'5px 10px'} : 
                                     (types.type === "Holiday" ? {backgroundColor:'rgba(255, 0, 0, 0.438)', color:'#000', padding:'5px 10px'} : 
                                      {backgroundColor:'#008cff5a', color:'#000', padding:'5px 10px'}) }> {types.type}</p>
                                    </tr>
                                })}
                        </div>
                        <div className="col-md-2">
                            <select name="starthour6" onChange={e =>  setStart6(e.target.value)} className="form-control">
                                <option value="">Start Time</option>
                                <option value="05.00">05:00 AM</option>
                                <option value="06.00">06:00 AM</option>
                                <option value="06.8">06:05 AM</option>
                                <option value="06.16">06:10 AM</option>
                                <option value="06.25">06:15 AM</option>
                                <option value="06.33">06:20 AM</option>
                                <option value="06.41">06:25 AM</option>
                                <option value="06.50">06:30 AM</option>
                                <option value="06.58">06:35 AM</option>
                                <option value="06.66">06:40 AM</option>
                                <option value="06.75">06:45 AM</option>
                                <option value="06.83">06:50 AM</option>
                                <option value="06.91">06:55 AM</option>
                                <option value="07.00">07:00 AM - Normal</option>
                                <option value="07.8">07:05 AM</option>
                                <option value="07.16">07:10 AM</option>
                                <option value="07.25">07:15 AM</option>
                                <option value="07.33">07:20 AM</option>
                                <option value="07.41">07:25 AM</option>
                                <option value="07.50">07:30 AM</option>
                                <option value="07.58">07:35 AM</option>
                                <option value="07.66">07:40 AM</option>
                                <option value="07.75">07:45 AM</option>
                                <option value="07.83">07:50 AM</option>
                                <option value="07.91">07:55 AM</option>
                                <option value="08.00">08:00 AM - o'clock</option>
                                <option value="08.8">08:05 AM</option>
                                <option value="08.16">08:10 AM</option>
                                <option value="08.25">08:15 AM</option>
                                <option value="08.33">08:20 AM</option>
                                <option value="08.41">08:25 AM</option>
                                <option value="08.50">08:30 AM</option>
                                <option value="08.58">08:35 AM</option>
                                <option value="08.66">08:40 AM</option>
                                <option value="08.75">08:45 AM</option>
                                <option value="08.83">08:50 AM</option>
                                <option value="08.91">08:55 AM</option>
                                <option value="09.00">09:00 AM - o'clock</option>
                                <option value="09.8">09:05 AM</option>
                                <option value="09.16">09:10 AM</option>
                                <option value="09.25">09:15 AM</option>
                                <option value="09.33">09:20 AM</option>
                                <option value="09.41">09:25 AM</option>
                                <option value="09.50">09:30 AM</option>
                                <option value="09.58">09:35 AM</option>
                                <option value="09.66">09:40 AM</option>
                                <option value="09.75">09:45 AM</option>
                                <option value="09.83">09:50 AM</option>
                                <option value="09.91">09:55 AM</option>
                                <option value="10.00">10:00 AM - o'clock</option>
                                <option value="10.8">10:05 AM</option>
                                <option value="10.16">10:10 AM</option>
                                <option value="10.25">10:15 AM</option>
                                <option value="10.33">10:20 AM</option>
                                <option value="10.41">10:25 AM</option>
                                <option value="10.50">10:30 AM</option>
                                <option value="10.58">10:35 AM</option>
                                <option value="10.66">10:40 AM</option>
                                <option value="10.75">10:45 AM</option>
                                <option value="10.83">10:50 AM</option>
                                <option value="10.91">10:55 AM</option>
                                <option value="11.00">11:00 AM - o'clock</option>
                                <option value="11.8">11:05 AM</option>
                                <option value="11.16">11:10 AM</option>
                                <option value="11.25">11:15 AM</option>
                                <option value="11.33">11:20 AM</option>
                                <option value="11.41">11:25 AM</option>
                                <option value="11.50">11:30 AM</option>
                                <option value="11.58">11:35 AM</option>
                                <option value="11.66">11:40 AM</option>
                                <option value="11.75">11:45 AM</option>
                                <option value="11.83">11:50 AM</option>
                                <option value="11.91">11:55 AM</option>
                                <option value="12.00">12:00 PM  - o'clock</option>
                                <option value="12.8">12:05 PM</option>
                                <option value="12.16">12:10 PM</option>
                                <option value="12.25">12:15 PM</option>
                                <option value="12.33">12:20 PM</option>
                                <option value="12.41">12:25 PM</option>
                                <option value="12.50">12:30 PM</option>
                                <option value="12.58">12:35 PM</option>
                                <option value="12.66">12:40 PM</option>
                                <option value="12.75">12:45 PM</option>
                                <option value="12.83">12:50 PM</option>
                                <option value="12.91">12:55 PM</option>
                                <option value="13.00">13:00 PM - o'clock</option>
                                <option value="13.8">13:05 PM</option>
                                <option value="13.16">13:10 PM</option>
                                <option value="13.25">13:15 PM</option>
                                <option value="13.33">13:20 PM</option>
                                <option value="13.41">13:25 PM</option>
                                <option value="13.50">13:30 PM</option>
                                <option value="13.58">13:35 PM</option>
                                <option value="13.66">13:40 PM</option>
                                <option value="13.75">13:45 PM</option>
                                <option value="13.83">13:50 PM</option>
                                <option value="13.91">13:55 PM</option>
                                <option value="14.00">14:00 PM - o'clock</option>
                                <option value="14.8">14:05 PM</option>
                                <option value="14.16">14:10 PM</option>
                                <option value="14.25">14:15 PM</option>
                                <option value="14.33">14:20 PM</option>
                                <option value="14.41">14:25 PM</option>
                                <option value="14.50">14:30 PM</option>
                                <option value="14.58">14:35 PM</option>
                                <option value="14.66">14:40 PM</option>
                                <option value="14.75">14:45 PM</option>
                                <option value="14.83">14:50 PM</option>
                                <option value="14.91">14:55 PM</option>
                                <option value="15.00">15:00 PM - o'clock</option>
                                <option value="15.8">15:05 PM</option>
                                <option value="15.16">15:10 PM</option>
                                <option value="15.25">15:15 PM</option>
                                <option value="15.33">15:20 PM</option>
                                <option value="15.41">15:25 PM</option>
                                <option value="15.50">15:30 PM</option>
                                <option value="15.58">15:35 PM</option>
                                <option value="15.66">15:40 PM</option>
                                <option value="15.75">15:45 PM</option>
                                <option value="15.83">15:50 PM</option>
                                <option value="15.91">15:55 PM</option>
                                <option value="16.00">16:00 PM - o'clock</option>
                                <option value="16.8">16:05 PM</option>
                                <option value="16.16">16:10 PM</option>
                                <option value="16.25">16:15 PM</option>
                                <option value="16.33">16:20 PM</option>
                                <option value="16.41">16:25 PM</option>
                                <option value="16.50">16:30 PM</option>
                                <option value="16.58">16:35 PM</option>
                                <option value="16.66">16:40 PM</option>
                                <option value="16.75">16:45 PM</option>
                                <option value="16.83">16:50 PM</option>
                                <option value="16.91">16:55 PM</option>
                                <option value="17.00">17:00 PM - o'clock</option>
                                <option value="17.8">17:05 PM</option>
                                <option value="17.16">17:10 PM</option>
                                <option value="17.25">17:15 PM</option>
                                <option value="17.33">17:20 PM</option>
                                <option value="17.41">17:25 PM</option>
                                <option value="17.50">17:30 PM</option>
                                <option value="17.58">17:35 PM</option>
                                <option value="17.66">17:40 PM</option>
                                <option value="17.75">17:45 PM</option>
                                <option value="17.83">17:50 PM</option>
                                <option value="17.91">17:55 PM</option>
                                <option value="18.00">18:00 PM - Normal</option>
                                <option value="18.8">18:05 PM</option>
                                <option value="18.16">18:10 PM</option>
                                <option value="18.25">18:15 PM</option>
                                <option value="18.33">18:20 PM</option>
                                <option value="18.41">18:25 PM</option>
                                <option value="18.50">18:30 PM</option>
                                <option value="18.58">18:35 PM</option>
                                <option value="18.66">18:40 PM</option>
                                <option value="18.75">18:45 PM</option>
                                <option value="18.83">18:50 PM</option>
                                <option value="18.91">18:55 PM</option>
                                <option value="19.00">19:00 PM - o'clock</option>
                                <option value="19.8">19:05 PM</option>
                                <option value="19.16">19:10 PM</option>
                                <option value="19.25">19:15 PM</option>
                                <option value="19.33">19:20 PM</option>
                                <option value="19.41">19:25 PM</option>
                                <option value="19.50">19:30 PM</option>
                                <option value="19.58">19:35 PM</option>
                                <option value="19.66">19:40 PM</option>
                                <option value="19.75">19:45 PM</option>
                                <option value="19.83">19:50 PM</option>
                                <option value="19.91">19:55 PM</option>
                                <option value="20.00">20:00 PM - o'clock</option>
                                <option value="20.8">20:05 PM</option>
                                <option value="20.16">20:10 PM</option>
                                <option value="20.25">20:15 PM</option>
                                <option value="20.33">20:20 PM</option>
                                <option value="20.41">20:25 PM</option>
                                <option value="20.50">20:30 PM</option>
                                <option value="20.58">20:35 PM</option>
                                <option value="20.66">20:40 PM</option>
                                <option value="20.75">20:45 PM</option>
                                <option value="20.83">20:50 PM</option>
                                <option value="20.91">20:55 PM</option>
                                <option value="21.00">21:00 PM - o'clock</option>
                                <option value="21.8">21:05 PM</option>
                                <option value="21.16">21:10 PM</option>
                                <option value="21.25">21:15 PM</option>
                                <option value="21.33">21:20 PM</option>
                                <option value="21.41">21:25 PM</option>
                                <option value="21.50">21:30 PM</option>
                                <option value="21.58">21:35 PM</option>
                                <option value="21.66">21:40 PM</option>
                                <option value="21.75">21:45 PM</option>
                                <option value="21.83">21:50 PM</option>
                                <option value="21.91">21:55 PM</option>
                                <option value="22.00">22:00 PM - o'clock</option>
                                <option value="22.8">22:05 PM</option>
                                <option value="22.16">22:10 PM</option>
                                <option value="22.25">22:15 PM</option>
                                <option value="22.33">22:20 PM</option>
                                <option value="22.41">22:25 PM</option>
                                <option value="22.50">22:30 PM</option>
                                <option value="22.58">22:35 PM</option>
                                <option value="22.66">22:40 PM</option>
                                <option value="22.75">22:45 PM</option>
                                <option value="22.83">22:50 PM</option>
                                <option value="22.91">22:55 PM</option>
                                <option value="23.00">23:00 PM - o'clock</option>
                                <option value="23.8">23:05 PM</option>
                                <option value="23.16">23:10 PM</option>
                                <option value="23.25">23:15 PM</option>
                                <option value="23.33">23:20 PM</option>
                                <option value="23.41">23:25 PM</option>
                                <option value="23.50">23:30 PM</option>
                                <option value="23.58">23:35 PM</option>
                                <option value="23.66">23:40 PM</option>
                                <option value="23.75">23:45 PM</option>
                                <option value="23.83">23:50 PM</option>
                                <option value="23.91">23:55 PM</option>
                                <option value="24.00">24:00 PM - o'clock</option>
                                <option value="24.8">15:05 PM</option>
                                <option value="24.16">24:10 PM</option>
                                <option value="24.25">24:15 PM</option>
                                <option value="24.33">24:20 PM</option>
                                <option value="24.41">24:25 PM</option>
                                <option value="24.50">24:30 PM</option>
                                <option value="24.58">24:35 PM</option>
                                <option value="24.66">24:40 PM</option>
                                <option value="24.75">24:45 PM</option>
                                <option value="24.83">24:50 PM</option>
                                <option value="24.91">24:55 PM</option>
                            </select>
                            {data6.map((datas, index) =>{
                              return<div key={index}>
                                        <p>{datas.starthour}</p>
                                    </div>
                                })}
                        </div>
                        <div className="col-md-2">
                            <select name="endhour6" onChange={e =>  setEnd6(e.target.value)} className="form-control">
                                <option value="">Finish Time</option>
                                <option value="05.00">05:00 AM</option>
                                <option value="06.00">06:00 AM</option>
                                <option value="06.8">06:05 AM</option>
                                <option value="06.16">06:10 AM</option>
                                <option value="06.25">06:15 AM</option>
                                <option value="06.33">06:20 AM</option>
                                <option value="06.41">06:25 AM</option>
                                <option value="06.50">06:30 AM</option>
                                <option value="06.58">06:35 AM</option>
                                <option value="06.66">06:40 AM</option>
                                <option value="06.75">06:45 AM</option>
                                <option value="06.83">06:50 AM</option>
                                <option value="06.91">06:55 AM</option>
                                <option value="07.00">07:00 AM - Normal</option>
                                <option value="07.8">07:05 AM</option>
                                <option value="07.16">07:10 AM</option>
                                <option value="07.25">07:15 AM</option>
                                <option value="07.33">07:20 AM</option>
                                <option value="07.41">07:25 AM</option>
                                <option value="07.50">07:30 AM</option>
                                <option value="07.58">07:35 AM</option>
                                <option value="07.66">07:40 AM</option>
                                <option value="07.75">07:45 AM</option>
                                <option value="07.83">07:50 AM</option>
                                <option value="07.91">07:55 AM</option>
                                <option value="08.00">08:00 AM - o'clock</option>
                                <option value="08.8">08:05 AM</option>
                                <option value="08.16">08:10 AM</option>
                                <option value="08.25">08:15 AM</option>
                                <option value="08.33">08:20 AM</option>
                                <option value="08.41">08:25 AM</option>
                                <option value="08.50">08:30 AM</option>
                                <option value="08.58">08:35 AM</option>
                                <option value="08.66">08:40 AM</option>
                                <option value="08.75">08:45 AM</option>
                                <option value="08.83">08:50 AM</option>
                                <option value="08.91">08:55 AM</option>
                                <option value="09.00">09:00 AM - o'clock</option>
                                <option value="09.8">09:05 AM</option>
                                <option value="09.16">09:10 AM</option>
                                <option value="09.25">09:15 AM</option>
                                <option value="09.33">09:20 AM</option>
                                <option value="09.41">09:25 AM</option>
                                <option value="09.50">09:30 AM</option>
                                <option value="09.58">09:35 AM</option>
                                <option value="09.66">09:40 AM</option>
                                <option value="09.75">09:45 AM</option>
                                <option value="09.83">09:50 AM</option>
                                <option value="09.91">09:55 AM</option>
                                <option value="10.00">10:00 AM - o'clock</option>
                                <option value="10.8">10:05 AM</option>
                                <option value="10.16">10:10 AM</option>
                                <option value="10.25">10:15 AM</option>
                                <option value="10.33">10:20 AM</option>
                                <option value="10.41">10:25 AM</option>
                                <option value="10.50">10:30 AM</option>
                                <option value="10.58">10:35 AM</option>
                                <option value="10.66">10:40 AM</option>
                                <option value="10.75">10:45 AM</option>
                                <option value="10.83">10:50 AM</option>
                                <option value="10.91">10:55 AM</option>
                                <option value="11.00">11:00 AM - o'clock</option>
                                <option value="11.8">11:05 AM</option>
                                <option value="11.16">11:10 AM</option>
                                <option value="11.25">11:15 AM</option>
                                <option value="11.33">11:20 AM</option>
                                <option value="11.41">11:25 AM</option>
                                <option value="11.50">11:30 AM</option>
                                <option value="11.58">11:35 AM</option>
                                <option value="11.66">11:40 AM</option>
                                <option value="11.75">11:45 AM</option>
                                <option value="11.83">11:50 AM</option>
                                <option value="11.91">11:55 AM</option>
                                <option value="12.00">12:00 PM  - o'clock</option>
                                <option value="12.8">12:05 PM</option>
                                <option value="12.16">12:10 PM</option>
                                <option value="12.25">12:15 PM</option>
                                <option value="12.33">12:20 PM</option>
                                <option value="12.41">12:25 PM</option>
                                <option value="12.50">12:30 PM</option>
                                <option value="12.58">12:35 PM</option>
                                <option value="12.66">12:40 PM</option>
                                <option value="12.75">12:45 PM</option>
                                <option value="12.83">12:50 PM</option>
                                <option value="12.91">12:55 PM</option>
                                <option value="13.00">13:00 PM - o'clock</option>
                                <option value="13.8">13:05 PM</option>
                                <option value="13.16">13:10 PM</option>
                                <option value="13.25">13:15 PM</option>
                                <option value="13.33">13:20 PM</option>
                                <option value="13.41">13:25 PM</option>
                                <option value="13.50">13:30 PM</option>
                                <option value="13.58">13:35 PM</option>
                                <option value="13.66">13:40 PM</option>
                                <option value="13.75">13:45 PM</option>
                                <option value="13.83">13:50 PM</option>
                                <option value="13.91">13:55 PM</option>
                                <option value="14.00">14:00 PM - o'clock</option>
                                <option value="14.8">14:05 PM</option>
                                <option value="14.16">14:10 PM</option>
                                <option value="14.25">14:15 PM</option>
                                <option value="14.33">14:20 PM</option>
                                <option value="14.41">14:25 PM</option>
                                <option value="14.50">14:30 PM</option>
                                <option value="14.58">14:35 PM</option>
                                <option value="14.66">14:40 PM</option>
                                <option value="14.75">14:45 PM</option>
                                <option value="14.83">14:50 PM</option>
                                <option value="14.91">14:55 PM</option>
                                <option value="15.00">15:00 PM - o'clock</option>
                                <option value="15.8">15:05 PM</option>
                                <option value="15.16">15:10 PM</option>
                                <option value="15.25">15:15 PM</option>
                                <option value="15.33">15:20 PM</option>
                                <option value="15.41">15:25 PM</option>
                                <option value="15.50">15:30 PM</option>
                                <option value="15.58">15:35 PM</option>
                                <option value="15.66">15:40 PM</option>
                                <option value="15.75">15:45 PM</option>
                                <option value="15.83">15:50 PM</option>
                                <option value="15.91">15:55 PM</option>
                                <option value="16.00">16:00 PM - o'clock</option>
                                <option value="16.8">16:05 PM</option>
                                <option value="16.16">16:10 PM</option>
                                <option value="16.25">16:15 PM</option>
                                <option value="16.33">16:20 PM</option>
                                <option value="16.41">16:25 PM</option>
                                <option value="16.50">16:30 PM</option>
                                <option value="16.58">16:35 PM</option>
                                <option value="16.66">16:40 PM</option>
                                <option value="16.75">16:45 PM</option>
                                <option value="16.83">16:50 PM</option>
                                <option value="16.91">16:55 PM</option>
                                <option value="17.00">17:00 PM - o'clock</option>
                                <option value="17.8">17:05 PM</option>
                                <option value="17.16">17:10 PM</option>
                                <option value="17.25">17:15 PM</option>
                                <option value="17.33">17:20 PM</option>
                                <option value="17.41">17:25 PM</option>
                                <option value="17.50">17:30 PM</option>
                                <option value="17.58">17:35 PM</option>
                                <option value="17.66">17:40 PM</option>
                                <option value="17.75">17:45 PM</option>
                                <option value="17.83">17:50 PM</option>
                                <option value="17.91">17:55 PM</option>
                                <option value="18.00">18:00 PM - Normal</option>
                                <option value="18.8">18:05 PM</option>
                                <option value="18.16">18:10 PM</option>
                                <option value="18.25">18:15 PM</option>
                                <option value="18.33">18:20 PM</option>
                                <option value="18.41">18:25 PM</option>
                                <option value="18.50">18:30 PM</option>
                                <option value="18.58">18:35 PM</option>
                                <option value="18.66">18:40 PM</option>
                                <option value="18.75">18:45 PM</option>
                                <option value="18.83">18:50 PM</option>
                                <option value="18.91">18:55 PM</option>
                                <option value="19.00">19:00 PM - o'clock</option>
                                <option value="19.8">19:05 PM</option>
                                <option value="19.16">19:10 PM</option>
                                <option value="19.25">19:15 PM</option>
                                <option value="19.33">19:20 PM</option>
                                <option value="19.41">19:25 PM</option>
                                <option value="19.50">19:30 PM</option>
                                <option value="19.58">19:35 PM</option>
                                <option value="19.66">19:40 PM</option>
                                <option value="19.75">19:45 PM</option>
                                <option value="19.83">19:50 PM</option>
                                <option value="19.91">19:55 PM</option>
                                <option value="20.00">20:00 PM - o'clock</option>
                                <option value="20.8">20:05 PM</option>
                                <option value="20.16">20:10 PM</option>
                                <option value="20.25">20:15 PM</option>
                                <option value="20.33">20:20 PM</option>
                                <option value="20.41">20:25 PM</option>
                                <option value="20.50">20:30 PM</option>
                                <option value="20.58">20:35 PM</option>
                                <option value="20.66">20:40 PM</option>
                                <option value="20.75">20:45 PM</option>
                                <option value="20.83">20:50 PM</option>
                                <option value="20.91">20:55 PM</option>
                                <option value="21.00">21:00 PM - o'clock</option>
                                <option value="21.8">21:05 PM</option>
                                <option value="21.16">21:10 PM</option>
                                <option value="21.25">21:15 PM</option>
                                <option value="21.33">21:20 PM</option>
                                <option value="21.41">21:25 PM</option>
                                <option value="21.50">21:30 PM</option>
                                <option value="21.58">21:35 PM</option>
                                <option value="21.66">21:40 PM</option>
                                <option value="21.75">21:45 PM</option>
                                <option value="21.83">21:50 PM</option>
                                <option value="21.91">21:55 PM</option>
                                <option value="22.00">22:00 PM - o'clock</option>
                                <option value="22.8">22:05 PM</option>
                                <option value="22.16">22:10 PM</option>
                                <option value="22.25">22:15 PM</option>
                                <option value="22.33">22:20 PM</option>
                                <option value="22.41">22:25 PM</option>
                                <option value="22.50">22:30 PM</option>
                                <option value="22.58">22:35 PM</option>
                                <option value="22.66">22:40 PM</option>
                                <option value="22.75">22:45 PM</option>
                                <option value="22.83">22:50 PM</option>
                                <option value="22.91">22:55 PM</option>
                                <option value="23.00">23:00 PM - o'clock</option>
                                <option value="23.8">23:05 PM</option>
                                <option value="23.16">23:10 PM</option>
                                <option value="23.25">23:15 PM</option>
                                <option value="23.33">23:20 PM</option>
                                <option value="23.41">23:25 PM</option>
                                <option value="23.50">23:30 PM</option>
                                <option value="23.58">23:35 PM</option>
                                <option value="23.66">23:40 PM</option>
                                <option value="23.75">23:45 PM</option>
                                <option value="23.83">23:50 PM</option>
                                <option value="23.91">23:55 PM</option>
                                <option value="24.00">24:00 PM - o'clock</option>
                                <option value="24.8">15:05 PM</option>
                                <option value="24.16">24:10 PM</option>
                                <option value="24.25">24:15 PM</option>
                                <option value="24.33">24:20 PM</option>
                                <option value="24.41">24:25 PM</option>
                                <option value="24.50">24:30 PM</option>
                                <option value="24.58">24:35 PM</option>
                                <option value="24.66">24:40 PM</option>
                                <option value="24.75">24:45 PM</option>
                                <option value="24.83">24:50 PM</option>
                                <option value="24.91">24:55 PM</option>
                            </select>
                            {data6.map((datas, index) =>{
                              return<div key={index}>
                                        <p>{datas.endhour}</p>
                                    </div>
                                })}
                        </div>
                        <div className="col-md-2">
                            <select name="type6" onChange={e =>  settype6(e.target.value)} className="form-control" required>
                                <option value="">Select Type</option>
                                <option value="Normal">Normal</option>
                                <option value="SJUK">SJUK</option>
                                <option value="Holiday">Holiday</option>
                            </select>
                            {data6.map((datas, index) =>{
                              return<div key={index}>
                                        <p>{datas.type}</p>
                                    </div>
                                })}
                          </div>
                          <div className="col-md-2"> 
                              <button type='submit' className="btn btn-primary">Submit</button>
                        </div>
                      </form>
                  </div>
                  <div className="d-flex flex-column p-2">
                     <form action="" className="row"  onSubmit={handleSubmit7}>
                        <div className="col-md-1"> 07 </div>
                        <div className="col-md-1"> Monday </div>
                        <div className="col-md-1" > 
                            {data7.map((types, index) =>{
                              return<tr key={index}>
                                   <p style={types.type === "Normal" ? {backgroundColor:'rgba(7, 190, 7, 0.648)', color:'#000', padding:'5px 10px'} : 
                                     (types.type === "Holiday" ? {backgroundColor:'rgba(255, 0, 0, 0.438)', color:'#000', padding:'5px 10px'} : 
                                      {backgroundColor:'#008cff5a', color:'#000', padding:'5px 10px'}) }> {types.type}</p>
                                    </tr>
                                })}
                        </div>
                        <div className="col-md-2">
                            <select name="starthour7" onChange={e =>  setStart7(e.target.value)} className="form-control">
                                <option value="">Start Time</option>
                                <option value="05.00">05:00 AM</option>
                                <option value="06.00">06:00 AM</option>
                                <option value="06.8">06:05 AM</option>
                                <option value="06.16">06:10 AM</option>
                                <option value="06.25">06:15 AM</option>
                                <option value="06.33">06:20 AM</option>
                                <option value="06.41">06:25 AM</option>
                                <option value="06.50">06:30 AM</option>
                                <option value="06.58">06:35 AM</option>
                                <option value="06.66">06:40 AM</option>
                                <option value="06.75">06:45 AM</option>
                                <option value="06.83">06:50 AM</option>
                                <option value="06.91">06:55 AM</option>
                                <option value="07.00">07:00 AM - Normal</option>
                                <option value="07.8">07:05 AM</option>
                                <option value="07.16">07:10 AM</option>
                                <option value="07.25">07:15 AM</option>
                                <option value="07.33">07:20 AM</option>
                                <option value="07.41">07:25 AM</option>
                                <option value="07.50">07:30 AM</option>
                                <option value="07.58">07:35 AM</option>
                                <option value="07.66">07:40 AM</option>
                                <option value="07.75">07:45 AM</option>
                                <option value="07.83">07:50 AM</option>
                                <option value="07.91">07:55 AM</option>
                                <option value="08.00">08:00 AM - o'clock</option>
                                <option value="08.8">08:05 AM</option>
                                <option value="08.16">08:10 AM</option>
                                <option value="08.25">08:15 AM</option>
                                <option value="08.33">08:20 AM</option>
                                <option value="08.41">08:25 AM</option>
                                <option value="08.50">08:30 AM</option>
                                <option value="08.58">08:35 AM</option>
                                <option value="08.66">08:40 AM</option>
                                <option value="08.75">08:45 AM</option>
                                <option value="08.83">08:50 AM</option>
                                <option value="08.91">08:55 AM</option>
                                <option value="09.00">09:00 AM - o'clock</option>
                                <option value="09.8">09:05 AM</option>
                                <option value="09.16">09:10 AM</option>
                                <option value="09.25">09:15 AM</option>
                                <option value="09.33">09:20 AM</option>
                                <option value="09.41">09:25 AM</option>
                                <option value="09.50">09:30 AM</option>
                                <option value="09.58">09:35 AM</option>
                                <option value="09.66">09:40 AM</option>
                                <option value="09.75">09:45 AM</option>
                                <option value="09.83">09:50 AM</option>
                                <option value="09.91">09:55 AM</option>
                                <option value="10.00">10:00 AM - o'clock</option>
                                <option value="10.8">10:05 AM</option>
                                <option value="10.16">10:10 AM</option>
                                <option value="10.25">10:15 AM</option>
                                <option value="10.33">10:20 AM</option>
                                <option value="10.41">10:25 AM</option>
                                <option value="10.50">10:30 AM</option>
                                <option value="10.58">10:35 AM</option>
                                <option value="10.66">10:40 AM</option>
                                <option value="10.75">10:45 AM</option>
                                <option value="10.83">10:50 AM</option>
                                <option value="10.91">10:55 AM</option>
                                <option value="11.00">11:00 AM - o'clock</option>
                                <option value="11.8">11:05 AM</option>
                                <option value="11.16">11:10 AM</option>
                                <option value="11.25">11:15 AM</option>
                                <option value="11.33">11:20 AM</option>
                                <option value="11.41">11:25 AM</option>
                                <option value="11.50">11:30 AM</option>
                                <option value="11.58">11:35 AM</option>
                                <option value="11.66">11:40 AM</option>
                                <option value="11.75">11:45 AM</option>
                                <option value="11.83">11:50 AM</option>
                                <option value="11.91">11:55 AM</option>
                                <option value="12.00">12:00 PM  - o'clock</option>
                                <option value="12.8">12:05 PM</option>
                                <option value="12.16">12:10 PM</option>
                                <option value="12.25">12:15 PM</option>
                                <option value="12.33">12:20 PM</option>
                                <option value="12.41">12:25 PM</option>
                                <option value="12.50">12:30 PM</option>
                                <option value="12.58">12:35 PM</option>
                                <option value="12.66">12:40 PM</option>
                                <option value="12.75">12:45 PM</option>
                                <option value="12.83">12:50 PM</option>
                                <option value="12.91">12:55 PM</option>
                                <option value="13.00">13:00 PM - o'clock</option>
                                <option value="13.8">13:05 PM</option>
                                <option value="13.16">13:10 PM</option>
                                <option value="13.25">13:15 PM</option>
                                <option value="13.33">13:20 PM</option>
                                <option value="13.41">13:25 PM</option>
                                <option value="13.50">13:30 PM</option>
                                <option value="13.58">13:35 PM</option>
                                <option value="13.66">13:40 PM</option>
                                <option value="13.75">13:45 PM</option>
                                <option value="13.83">13:50 PM</option>
                                <option value="13.91">13:55 PM</option>
                                <option value="14.00">14:00 PM - o'clock</option>
                                <option value="14.8">14:05 PM</option>
                                <option value="14.16">14:10 PM</option>
                                <option value="14.25">14:15 PM</option>
                                <option value="14.33">14:20 PM</option>
                                <option value="14.41">14:25 PM</option>
                                <option value="14.50">14:30 PM</option>
                                <option value="14.58">14:35 PM</option>
                                <option value="14.66">14:40 PM</option>
                                <option value="14.75">14:45 PM</option>
                                <option value="14.83">14:50 PM</option>
                                <option value="14.91">14:55 PM</option>
                                <option value="15.00">15:00 PM - o'clock</option>
                                <option value="15.8">15:05 PM</option>
                                <option value="15.16">15:10 PM</option>
                                <option value="15.25">15:15 PM</option>
                                <option value="15.33">15:20 PM</option>
                                <option value="15.41">15:25 PM</option>
                                <option value="15.50">15:30 PM</option>
                                <option value="15.58">15:35 PM</option>
                                <option value="15.66">15:40 PM</option>
                                <option value="15.75">15:45 PM</option>
                                <option value="15.83">15:50 PM</option>
                                <option value="15.91">15:55 PM</option>
                                <option value="16.00">16:00 PM - o'clock</option>
                                <option value="16.8">16:05 PM</option>
                                <option value="16.16">16:10 PM</option>
                                <option value="16.25">16:15 PM</option>
                                <option value="16.33">16:20 PM</option>
                                <option value="16.41">16:25 PM</option>
                                <option value="16.50">16:30 PM</option>
                                <option value="16.58">16:35 PM</option>
                                <option value="16.66">16:40 PM</option>
                                <option value="16.75">16:45 PM</option>
                                <option value="16.83">16:50 PM</option>
                                <option value="16.91">16:55 PM</option>
                                <option value="17.00">17:00 PM - o'clock</option>
                                <option value="17.8">17:05 PM</option>
                                <option value="17.16">17:10 PM</option>
                                <option value="17.25">17:15 PM</option>
                                <option value="17.33">17:20 PM</option>
                                <option value="17.41">17:25 PM</option>
                                <option value="17.50">17:30 PM</option>
                                <option value="17.58">17:35 PM</option>
                                <option value="17.66">17:40 PM</option>
                                <option value="17.75">17:45 PM</option>
                                <option value="17.83">17:50 PM</option>
                                <option value="17.91">17:55 PM</option>
                                <option value="18.00">18:00 PM - Normal</option>
                                <option value="18.8">18:05 PM</option>
                                <option value="18.16">18:10 PM</option>
                                <option value="18.25">18:15 PM</option>
                                <option value="18.33">18:20 PM</option>
                                <option value="18.41">18:25 PM</option>
                                <option value="18.50">18:30 PM</option>
                                <option value="18.58">18:35 PM</option>
                                <option value="18.66">18:40 PM</option>
                                <option value="18.75">18:45 PM</option>
                                <option value="18.83">18:50 PM</option>
                                <option value="18.91">18:55 PM</option>
                                <option value="19.00">19:00 PM - o'clock</option>
                                <option value="19.8">19:05 PM</option>
                                <option value="19.16">19:10 PM</option>
                                <option value="19.25">19:15 PM</option>
                                <option value="19.33">19:20 PM</option>
                                <option value="19.41">19:25 PM</option>
                                <option value="19.50">19:30 PM</option>
                                <option value="19.58">19:35 PM</option>
                                <option value="19.66">19:40 PM</option>
                                <option value="19.75">19:45 PM</option>
                                <option value="19.83">19:50 PM</option>
                                <option value="19.91">19:55 PM</option>
                                <option value="20.00">20:00 PM - o'clock</option>
                                <option value="20.8">20:05 PM</option>
                                <option value="20.16">20:10 PM</option>
                                <option value="20.25">20:15 PM</option>
                                <option value="20.33">20:20 PM</option>
                                <option value="20.41">20:25 PM</option>
                                <option value="20.50">20:30 PM</option>
                                <option value="20.58">20:35 PM</option>
                                <option value="20.66">20:40 PM</option>
                                <option value="20.75">20:45 PM</option>
                                <option value="20.83">20:50 PM</option>
                                <option value="20.91">20:55 PM</option>
                                <option value="21.00">21:00 PM - o'clock</option>
                                <option value="21.8">21:05 PM</option>
                                <option value="21.16">21:10 PM</option>
                                <option value="21.25">21:15 PM</option>
                                <option value="21.33">21:20 PM</option>
                                <option value="21.41">21:25 PM</option>
                                <option value="21.50">21:30 PM</option>
                                <option value="21.58">21:35 PM</option>
                                <option value="21.66">21:40 PM</option>
                                <option value="21.75">21:45 PM</option>
                                <option value="21.83">21:50 PM</option>
                                <option value="21.91">21:55 PM</option>
                                <option value="22.00">22:00 PM - o'clock</option>
                                <option value="22.8">22:05 PM</option>
                                <option value="22.16">22:10 PM</option>
                                <option value="22.25">22:15 PM</option>
                                <option value="22.33">22:20 PM</option>
                                <option value="22.41">22:25 PM</option>
                                <option value="22.50">22:30 PM</option>
                                <option value="22.58">22:35 PM</option>
                                <option value="22.66">22:40 PM</option>
                                <option value="22.75">22:45 PM</option>
                                <option value="22.83">22:50 PM</option>
                                <option value="22.91">22:55 PM</option>
                                <option value="23.00">23:00 PM - o'clock</option>
                                <option value="23.8">23:05 PM</option>
                                <option value="23.16">23:10 PM</option>
                                <option value="23.25">23:15 PM</option>
                                <option value="23.33">23:20 PM</option>
                                <option value="23.41">23:25 PM</option>
                                <option value="23.50">23:30 PM</option>
                                <option value="23.58">23:35 PM</option>
                                <option value="23.66">23:40 PM</option>
                                <option value="23.75">23:45 PM</option>
                                <option value="23.83">23:50 PM</option>
                                <option value="23.91">23:55 PM</option>
                                <option value="24.00">24:00 PM - o'clock</option>
                                <option value="24.8">15:05 PM</option>
                                <option value="24.16">24:10 PM</option>
                                <option value="24.25">24:15 PM</option>
                                <option value="24.33">24:20 PM</option>
                                <option value="24.41">24:25 PM</option>
                                <option value="24.50">24:30 PM</option>
                                <option value="24.58">24:35 PM</option>
                                <option value="24.66">24:40 PM</option>
                                <option value="24.75">24:45 PM</option>
                                <option value="24.83">24:50 PM</option>
                                <option value="24.91">24:55 PM</option>
                            </select>
                            {data7.map((datas, index) =>{
                              return<div key={index}>
                                        <p>{datas.starthour}</p>
                                    </div>
                                })}
                        </div>

                        <div className="col-md-2">
                            <select name="endhour7" onChange={e =>  setEnd7(e.target.value)} className="form-control">
                                <option value="">Finish Time</option>
                                <option value="05.00">05:00 AM</option>
                                <option value="06.00">06:00 AM</option>
                                <option value="06.8">06:05 AM</option>
                                <option value="06.16">06:10 AM</option>
                                <option value="06.25">06:15 AM</option>
                                <option value="06.33">06:20 AM</option>
                                <option value="06.41">06:25 AM</option>
                                <option value="06.50">06:30 AM</option>
                                <option value="06.58">06:35 AM</option>
                                <option value="06.66">06:40 AM</option>
                                <option value="06.75">06:45 AM</option>
                                <option value="06.83">06:50 AM</option>
                                <option value="06.91">06:55 AM</option>
                                <option value="07.00">07:00 AM - Normal</option>
                                <option value="07.8">07:05 AM</option>
                                <option value="07.16">07:10 AM</option>
                                <option value="07.25">07:15 AM</option>
                                <option value="07.33">07:20 AM</option>
                                <option value="07.41">07:25 AM</option>
                                <option value="07.50">07:30 AM</option>
                                <option value="07.58">07:35 AM</option>
                                <option value="07.66">07:40 AM</option>
                                <option value="07.75">07:45 AM</option>
                                <option value="07.83">07:50 AM</option>
                                <option value="07.91">07:55 AM</option>
                                <option value="08.00">08:00 AM - o'clock</option>
                                <option value="08.8">08:05 AM</option>
                                <option value="08.16">08:10 AM</option>
                                <option value="08.25">08:15 AM</option>
                                <option value="08.33">08:20 AM</option>
                                <option value="08.41">08:25 AM</option>
                                <option value="08.50">08:30 AM</option>
                                <option value="08.58">08:35 AM</option>
                                <option value="08.66">08:40 AM</option>
                                <option value="08.75">08:45 AM</option>
                                <option value="08.83">08:50 AM</option>
                                <option value="08.91">08:55 AM</option>
                                <option value="09.00">09:00 AM - o'clock</option>
                                <option value="09.8">09:05 AM</option>
                                <option value="09.16">09:10 AM</option>
                                <option value="09.25">09:15 AM</option>
                                <option value="09.33">09:20 AM</option>
                                <option value="09.41">09:25 AM</option>
                                <option value="09.50">09:30 AM</option>
                                <option value="09.58">09:35 AM</option>
                                <option value="09.66">09:40 AM</option>
                                <option value="09.75">09:45 AM</option>
                                <option value="09.83">09:50 AM</option>
                                <option value="09.91">09:55 AM</option>
                                <option value="10.00">10:00 AM - o'clock</option>
                                <option value="10.8">10:05 AM</option>
                                <option value="10.16">10:10 AM</option>
                                <option value="10.25">10:15 AM</option>
                                <option value="10.33">10:20 AM</option>
                                <option value="10.41">10:25 AM</option>
                                <option value="10.50">10:30 AM</option>
                                <option value="10.58">10:35 AM</option>
                                <option value="10.66">10:40 AM</option>
                                <option value="10.75">10:45 AM</option>
                                <option value="10.83">10:50 AM</option>
                                <option value="10.91">10:55 AM</option>
                                <option value="11.00">11:00 AM - o'clock</option>
                                <option value="11.8">11:05 AM</option>
                                <option value="11.16">11:10 AM</option>
                                <option value="11.25">11:15 AM</option>
                                <option value="11.33">11:20 AM</option>
                                <option value="11.41">11:25 AM</option>
                                <option value="11.50">11:30 AM</option>
                                <option value="11.58">11:35 AM</option>
                                <option value="11.66">11:40 AM</option>
                                <option value="11.75">11:45 AM</option>
                                <option value="11.83">11:50 AM</option>
                                <option value="11.91">11:55 AM</option>
                                <option value="12.00">12:00 PM  - o'clock</option>
                                <option value="12.8">12:05 PM</option>
                                <option value="12.16">12:10 PM</option>
                                <option value="12.25">12:15 PM</option>
                                <option value="12.33">12:20 PM</option>
                                <option value="12.41">12:25 PM</option>
                                <option value="12.50">12:30 PM</option>
                                <option value="12.58">12:35 PM</option>
                                <option value="12.66">12:40 PM</option>
                                <option value="12.75">12:45 PM</option>
                                <option value="12.83">12:50 PM</option>
                                <option value="12.91">12:55 PM</option>
                                <option value="13.00">13:00 PM - o'clock</option>
                                <option value="13.8">13:05 PM</option>
                                <option value="13.16">13:10 PM</option>
                                <option value="13.25">13:15 PM</option>
                                <option value="13.33">13:20 PM</option>
                                <option value="13.41">13:25 PM</option>
                                <option value="13.50">13:30 PM</option>
                                <option value="13.58">13:35 PM</option>
                                <option value="13.66">13:40 PM</option>
                                <option value="13.75">13:45 PM</option>
                                <option value="13.83">13:50 PM</option>
                                <option value="13.91">13:55 PM</option>
                                <option value="14.00">14:00 PM - o'clock</option>
                                <option value="14.8">14:05 PM</option>
                                <option value="14.16">14:10 PM</option>
                                <option value="14.25">14:15 PM</option>
                                <option value="14.33">14:20 PM</option>
                                <option value="14.41">14:25 PM</option>
                                <option value="14.50">14:30 PM</option>
                                <option value="14.58">14:35 PM</option>
                                <option value="14.66">14:40 PM</option>
                                <option value="14.75">14:45 PM</option>
                                <option value="14.83">14:50 PM</option>
                                <option value="14.91">14:55 PM</option>
                                <option value="15.00">15:00 PM - o'clock</option>
                                <option value="15.8">15:05 PM</option>
                                <option value="15.16">15:10 PM</option>
                                <option value="15.25">15:15 PM</option>
                                <option value="15.33">15:20 PM</option>
                                <option value="15.41">15:25 PM</option>
                                <option value="15.50">15:30 PM</option>
                                <option value="15.58">15:35 PM</option>
                                <option value="15.66">15:40 PM</option>
                                <option value="15.75">15:45 PM</option>
                                <option value="15.83">15:50 PM</option>
                                <option value="15.91">15:55 PM</option>
                                <option value="16.00">16:00 PM - o'clock</option>
                                <option value="16.8">16:05 PM</option>
                                <option value="16.16">16:10 PM</option>
                                <option value="16.25">16:15 PM</option>
                                <option value="16.33">16:20 PM</option>
                                <option value="16.41">16:25 PM</option>
                                <option value="16.50">16:30 PM</option>
                                <option value="16.58">16:35 PM</option>
                                <option value="16.66">16:40 PM</option>
                                <option value="16.75">16:45 PM</option>
                                <option value="16.83">16:50 PM</option>
                                <option value="16.91">16:55 PM</option>
                                <option value="17.00">17:00 PM - o'clock</option>
                                <option value="17.8">17:05 PM</option>
                                <option value="17.16">17:10 PM</option>
                                <option value="17.25">17:15 PM</option>
                                <option value="17.33">17:20 PM</option>
                                <option value="17.41">17:25 PM</option>
                                <option value="17.50">17:30 PM</option>
                                <option value="17.58">17:35 PM</option>
                                <option value="17.66">17:40 PM</option>
                                <option value="17.75">17:45 PM</option>
                                <option value="17.83">17:50 PM</option>
                                <option value="17.91">17:55 PM</option>
                                <option value="18.00">18:00 PM - Normal</option>
                                <option value="18.8">18:05 PM</option>
                                <option value="18.16">18:10 PM</option>
                                <option value="18.25">18:15 PM</option>
                                <option value="18.33">18:20 PM</option>
                                <option value="18.41">18:25 PM</option>
                                <option value="18.50">18:30 PM</option>
                                <option value="18.58">18:35 PM</option>
                                <option value="18.66">18:40 PM</option>
                                <option value="18.75">18:45 PM</option>
                                <option value="18.83">18:50 PM</option>
                                <option value="18.91">18:55 PM</option>
                                <option value="19.00">19:00 PM - o'clock</option>
                                <option value="19.8">19:05 PM</option>
                                <option value="19.16">19:10 PM</option>
                                <option value="19.25">19:15 PM</option>
                                <option value="19.33">19:20 PM</option>
                                <option value="19.41">19:25 PM</option>
                                <option value="19.50">19:30 PM</option>
                                <option value="19.58">19:35 PM</option>
                                <option value="19.66">19:40 PM</option>
                                <option value="19.75">19:45 PM</option>
                                <option value="19.83">19:50 PM</option>
                                <option value="19.91">19:55 PM</option>
                                <option value="20.00">20:00 PM - o'clock</option>
                                <option value="20.8">20:05 PM</option>
                                <option value="20.16">20:10 PM</option>
                                <option value="20.25">20:15 PM</option>
                                <option value="20.33">20:20 PM</option>
                                <option value="20.41">20:25 PM</option>
                                <option value="20.50">20:30 PM</option>
                                <option value="20.58">20:35 PM</option>
                                <option value="20.66">20:40 PM</option>
                                <option value="20.75">20:45 PM</option>
                                <option value="20.83">20:50 PM</option>
                                <option value="20.91">20:55 PM</option>
                                <option value="21.00">21:00 PM - o'clock</option>
                                <option value="21.8">21:05 PM</option>
                                <option value="21.16">21:10 PM</option>
                                <option value="21.25">21:15 PM</option>
                                <option value="21.33">21:20 PM</option>
                                <option value="21.41">21:25 PM</option>
                                <option value="21.50">21:30 PM</option>
                                <option value="21.58">21:35 PM</option>
                                <option value="21.66">21:40 PM</option>
                                <option value="21.75">21:45 PM</option>
                                <option value="21.83">21:50 PM</option>
                                <option value="21.91">21:55 PM</option>
                                <option value="22.00">22:00 PM - o'clock</option>
                                <option value="22.8">22:05 PM</option>
                                <option value="22.16">22:10 PM</option>
                                <option value="22.25">22:15 PM</option>
                                <option value="22.33">22:20 PM</option>
                                <option value="22.41">22:25 PM</option>
                                <option value="22.50">22:30 PM</option>
                                <option value="22.58">22:35 PM</option>
                                <option value="22.66">22:40 PM</option>
                                <option value="22.75">22:45 PM</option>
                                <option value="22.83">22:50 PM</option>
                                <option value="22.91">22:55 PM</option>
                                <option value="23.00">23:00 PM - o'clock</option>
                                <option value="23.8">23:05 PM</option>
                                <option value="23.16">23:10 PM</option>
                                <option value="23.25">23:15 PM</option>
                                <option value="23.33">23:20 PM</option>
                                <option value="23.41">23:25 PM</option>
                                <option value="23.50">23:30 PM</option>
                                <option value="23.58">23:35 PM</option>
                                <option value="23.66">23:40 PM</option>
                                <option value="23.75">23:45 PM</option>
                                <option value="23.83">23:50 PM</option>
                                <option value="23.91">23:55 PM</option>
                                <option value="24.00">24:00 PM - o'clock</option>
                                <option value="24.8">15:05 PM</option>
                                <option value="24.16">24:10 PM</option>
                                <option value="24.25">24:15 PM</option>
                                <option value="24.33">24:20 PM</option>
                                <option value="24.41">24:25 PM</option>
                                <option value="24.50">24:30 PM</option>
                                <option value="24.58">24:35 PM</option>
                                <option value="24.66">24:40 PM</option>
                                <option value="24.75">24:45 PM</option>
                                <option value="24.83">24:50 PM</option>
                                <option value="24.91">24:55 PM</option>
                            </select>
                            {data7.map((datas, index) =>{
                              return<div key={index}>
                                        <p>{datas.endhour}</p>
                                    </div>
                                })}
                        </div>
                        <div className="col-md-2">
                            <select name="type7" onChange={e =>  settype7(e.target.value)} className="form-control" required>
                                <option value="">Select Type</option>
                                <option value="Normal">Normal</option>
                                <option value="SJUK">SJUK</option>
                                <option value="Holiday">Holiday</option>
                            </select>
                            {data7.map((datas, index) =>{
                              return<div key={index}>
                                        <p>{datas.type}</p>
                                    </div>
                                })}
                          </div>
                          <div className="col-md-2"> 
                              <button type='submit' className="btn btn-primary">Submit</button>
                        </div>
                      </form>
                  </div>
                  <div className="d-flex flex-column p-2">
                     <form action="" className="row"  onSubmit={handleSubmit8}>
                        <div className="col-md-1"> 08 </div>
                        <div className="col-md-1"> Tuesday  </div>
                        <div className="col-md-1" > 
                            {data8.map((types, index) =>{
                              return<tr key={index}>
                                   <p style={types.type === "Normal" ? {backgroundColor:'rgba(7, 190, 7, 0.648)', color:'#000', padding:'5px 10px'} : 
                                     (types.type === "Holiday" ? {backgroundColor:'rgba(255, 0, 0, 0.438)', color:'#000', padding:'5px 10px'} : 
                                      {backgroundColor:'#008cff5a', color:'#000', padding:'5px 10px'}) }> {types.type}</p>
                                    </tr>
                                })}
                        </div>
                        <div className="col-md-2">
                            <select name="starthour8" onChange={e =>  setStart8(e.target.value)} className="form-control">
                                <option value="">Start Time</option>
                                <option value="05.00">05:00 AM</option>
                                <option value="06.00">06:00 AM</option>
                                <option value="06.8">06:05 AM</option>
                                <option value="06.16">06:10 AM</option>
                                <option value="06.25">06:15 AM</option>
                                <option value="06.33">06:20 AM</option>
                                <option value="06.41">06:25 AM</option>
                                <option value="06.50">06:30 AM</option>
                                <option value="06.58">06:35 AM</option>
                                <option value="06.66">06:40 AM</option>
                                <option value="06.75">06:45 AM</option>
                                <option value="06.83">06:50 AM</option>
                                <option value="06.91">06:55 AM</option>
                                <option value="07.00">07:00 AM - Normal</option>
                                <option value="07.8">07:05 AM</option>
                                <option value="07.16">07:10 AM</option>
                                <option value="07.25">07:15 AM</option>
                                <option value="07.33">07:20 AM</option>
                                <option value="07.41">07:25 AM</option>
                                <option value="07.50">07:30 AM</option>
                                <option value="07.58">07:35 AM</option>
                                <option value="07.66">07:40 AM</option>
                                <option value="07.75">07:45 AM</option>
                                <option value="07.83">07:50 AM</option>
                                <option value="07.91">07:55 AM</option>
                                <option value="08.00">08:00 AM - o'clock</option>
                                <option value="08.8">08:05 AM</option>
                                <option value="08.16">08:10 AM</option>
                                <option value="08.25">08:15 AM</option>
                                <option value="08.33">08:20 AM</option>
                                <option value="08.41">08:25 AM</option>
                                <option value="08.50">08:30 AM</option>
                                <option value="08.58">08:35 AM</option>
                                <option value="08.66">08:40 AM</option>
                                <option value="08.75">08:45 AM</option>
                                <option value="08.83">08:50 AM</option>
                                <option value="08.91">08:55 AM</option>
                                <option value="09.00">09:00 AM - o'clock</option>
                                <option value="09.8">09:05 AM</option>
                                <option value="09.16">09:10 AM</option>
                                <option value="09.25">09:15 AM</option>
                                <option value="09.33">09:20 AM</option>
                                <option value="09.41">09:25 AM</option>
                                <option value="09.50">09:30 AM</option>
                                <option value="09.58">09:35 AM</option>
                                <option value="09.66">09:40 AM</option>
                                <option value="09.75">09:45 AM</option>
                                <option value="09.83">09:50 AM</option>
                                <option value="09.91">09:55 AM</option>
                                <option value="10.00">10:00 AM - o'clock</option>
                                <option value="10.8">10:05 AM</option>
                                <option value="10.16">10:10 AM</option>
                                <option value="10.25">10:15 AM</option>
                                <option value="10.33">10:20 AM</option>
                                <option value="10.41">10:25 AM</option>
                                <option value="10.50">10:30 AM</option>
                                <option value="10.58">10:35 AM</option>
                                <option value="10.66">10:40 AM</option>
                                <option value="10.75">10:45 AM</option>
                                <option value="10.83">10:50 AM</option>
                                <option value="10.91">10:55 AM</option>
                                <option value="11.00">11:00 AM - o'clock</option>
                                <option value="11.8">11:05 AM</option>
                                <option value="11.16">11:10 AM</option>
                                <option value="11.25">11:15 AM</option>
                                <option value="11.33">11:20 AM</option>
                                <option value="11.41">11:25 AM</option>
                                <option value="11.50">11:30 AM</option>
                                <option value="11.58">11:35 AM</option>
                                <option value="11.66">11:40 AM</option>
                                <option value="11.75">11:45 AM</option>
                                <option value="11.83">11:50 AM</option>
                                <option value="11.91">11:55 AM</option>
                                <option value="12.00">12:00 PM  - o'clock</option>
                                <option value="12.8">12:05 PM</option>
                                <option value="12.16">12:10 PM</option>
                                <option value="12.25">12:15 PM</option>
                                <option value="12.33">12:20 PM</option>
                                <option value="12.41">12:25 PM</option>
                                <option value="12.50">12:30 PM</option>
                                <option value="12.58">12:35 PM</option>
                                <option value="12.66">12:40 PM</option>
                                <option value="12.75">12:45 PM</option>
                                <option value="12.83">12:50 PM</option>
                                <option value="12.91">12:55 PM</option>
                                <option value="13.00">13:00 PM - o'clock</option>
                                <option value="13.8">13:05 PM</option>
                                <option value="13.16">13:10 PM</option>
                                <option value="13.25">13:15 PM</option>
                                <option value="13.33">13:20 PM</option>
                                <option value="13.41">13:25 PM</option>
                                <option value="13.50">13:30 PM</option>
                                <option value="13.58">13:35 PM</option>
                                <option value="13.66">13:40 PM</option>
                                <option value="13.75">13:45 PM</option>
                                <option value="13.83">13:50 PM</option>
                                <option value="13.91">13:55 PM</option>
                                <option value="14.00">14:00 PM - o'clock</option>
                                <option value="14.8">14:05 PM</option>
                                <option value="14.16">14:10 PM</option>
                                <option value="14.25">14:15 PM</option>
                                <option value="14.33">14:20 PM</option>
                                <option value="14.41">14:25 PM</option>
                                <option value="14.50">14:30 PM</option>
                                <option value="14.58">14:35 PM</option>
                                <option value="14.66">14:40 PM</option>
                                <option value="14.75">14:45 PM</option>
                                <option value="14.83">14:50 PM</option>
                                <option value="14.91">14:55 PM</option>
                                <option value="15.00">15:00 PM - o'clock</option>
                                <option value="15.8">15:05 PM</option>
                                <option value="15.16">15:10 PM</option>
                                <option value="15.25">15:15 PM</option>
                                <option value="15.33">15:20 PM</option>
                                <option value="15.41">15:25 PM</option>
                                <option value="15.50">15:30 PM</option>
                                <option value="15.58">15:35 PM</option>
                                <option value="15.66">15:40 PM</option>
                                <option value="15.75">15:45 PM</option>
                                <option value="15.83">15:50 PM</option>
                                <option value="15.91">15:55 PM</option>
                                <option value="16.00">16:00 PM - o'clock</option>
                                <option value="16.8">16:05 PM</option>
                                <option value="16.16">16:10 PM</option>
                                <option value="16.25">16:15 PM</option>
                                <option value="16.33">16:20 PM</option>
                                <option value="16.41">16:25 PM</option>
                                <option value="16.50">16:30 PM</option>
                                <option value="16.58">16:35 PM</option>
                                <option value="16.66">16:40 PM</option>
                                <option value="16.75">16:45 PM</option>
                                <option value="16.83">16:50 PM</option>
                                <option value="16.91">16:55 PM</option>
                                <option value="17.00">17:00 PM - o'clock</option>
                                <option value="17.8">17:05 PM</option>
                                <option value="17.16">17:10 PM</option>
                                <option value="17.25">17:15 PM</option>
                                <option value="17.33">17:20 PM</option>
                                <option value="17.41">17:25 PM</option>
                                <option value="17.50">17:30 PM</option>
                                <option value="17.58">17:35 PM</option>
                                <option value="17.66">17:40 PM</option>
                                <option value="17.75">17:45 PM</option>
                                <option value="17.83">17:50 PM</option>
                                <option value="17.91">17:55 PM</option>
                                <option value="18.00">18:00 PM - Normal</option>
                                <option value="18.8">18:05 PM</option>
                                <option value="18.16">18:10 PM</option>
                                <option value="18.25">18:15 PM</option>
                                <option value="18.33">18:20 PM</option>
                                <option value="18.41">18:25 PM</option>
                                <option value="18.50">18:30 PM</option>
                                <option value="18.58">18:35 PM</option>
                                <option value="18.66">18:40 PM</option>
                                <option value="18.75">18:45 PM</option>
                                <option value="18.83">18:50 PM</option>
                                <option value="18.91">18:55 PM</option>
                                <option value="19.00">19:00 PM - o'clock</option>
                                <option value="19.8">19:05 PM</option>
                                <option value="19.16">19:10 PM</option>
                                <option value="19.25">19:15 PM</option>
                                <option value="19.33">19:20 PM</option>
                                <option value="19.41">19:25 PM</option>
                                <option value="19.50">19:30 PM</option>
                                <option value="19.58">19:35 PM</option>
                                <option value="19.66">19:40 PM</option>
                                <option value="19.75">19:45 PM</option>
                                <option value="19.83">19:50 PM</option>
                                <option value="19.91">19:55 PM</option>
                                <option value="20.00">20:00 PM - o'clock</option>
                                <option value="20.8">20:05 PM</option>
                                <option value="20.16">20:10 PM</option>
                                <option value="20.25">20:15 PM</option>
                                <option value="20.33">20:20 PM</option>
                                <option value="20.41">20:25 PM</option>
                                <option value="20.50">20:30 PM</option>
                                <option value="20.58">20:35 PM</option>
                                <option value="20.66">20:40 PM</option>
                                <option value="20.75">20:45 PM</option>
                                <option value="20.83">20:50 PM</option>
                                <option value="20.91">20:55 PM</option>
                                <option value="21.00">21:00 PM - o'clock</option>
                                <option value="21.8">21:05 PM</option>
                                <option value="21.16">21:10 PM</option>
                                <option value="21.25">21:15 PM</option>
                                <option value="21.33">21:20 PM</option>
                                <option value="21.41">21:25 PM</option>
                                <option value="21.50">21:30 PM</option>
                                <option value="21.58">21:35 PM</option>
                                <option value="21.66">21:40 PM</option>
                                <option value="21.75">21:45 PM</option>
                                <option value="21.83">21:50 PM</option>
                                <option value="21.91">21:55 PM</option>
                                <option value="22.00">22:00 PM - o'clock</option>
                                <option value="22.8">22:05 PM</option>
                                <option value="22.16">22:10 PM</option>
                                <option value="22.25">22:15 PM</option>
                                <option value="22.33">22:20 PM</option>
                                <option value="22.41">22:25 PM</option>
                                <option value="22.50">22:30 PM</option>
                                <option value="22.58">22:35 PM</option>
                                <option value="22.66">22:40 PM</option>
                                <option value="22.75">22:45 PM</option>
                                <option value="22.83">22:50 PM</option>
                                <option value="22.91">22:55 PM</option>
                                <option value="23.00">23:00 PM - o'clock</option>
                                <option value="23.8">23:05 PM</option>
                                <option value="23.16">23:10 PM</option>
                                <option value="23.25">23:15 PM</option>
                                <option value="23.33">23:20 PM</option>
                                <option value="23.41">23:25 PM</option>
                                <option value="23.50">23:30 PM</option>
                                <option value="23.58">23:35 PM</option>
                                <option value="23.66">23:40 PM</option>
                                <option value="23.75">23:45 PM</option>
                                <option value="23.83">23:50 PM</option>
                                <option value="23.91">23:55 PM</option>
                                <option value="24.00">24:00 PM - o'clock</option>
                                <option value="24.8">15:05 PM</option>
                                <option value="24.16">24:10 PM</option>
                                <option value="24.25">24:15 PM</option>
                                <option value="24.33">24:20 PM</option>
                                <option value="24.41">24:25 PM</option>
                                <option value="24.50">24:30 PM</option>
                                <option value="24.58">24:35 PM</option>
                                <option value="24.66">24:40 PM</option>
                                <option value="24.75">24:45 PM</option>
                                <option value="24.83">24:50 PM</option>
                                <option value="24.91">24:55 PM</option>
                            </select>
                            {data8.map((datas, index) =>{
                              return<div key={index}>
                                        <p>{datas.starthour}</p>
                                    </div>
                                })}
                        </div>
                        <div className="col-md-2">
                            <select name="endhour8" onChange={e =>  setEnd8(e.target.value)} className="form-control">
                                <option value="">Finish Time</option>
                                <option value="05.00">05:00 AM</option>
                                <option value="06.00">06:00 AM</option>
                                <option value="06.8">06:05 AM</option>
                                <option value="06.16">06:10 AM</option>
                                <option value="06.25">06:15 AM</option>
                                <option value="06.33">06:20 AM</option>
                                <option value="06.41">06:25 AM</option>
                                <option value="06.50">06:30 AM</option>
                                <option value="06.58">06:35 AM</option>
                                <option value="06.66">06:40 AM</option>
                                <option value="06.75">06:45 AM</option>
                                <option value="06.83">06:50 AM</option>
                                <option value="06.91">06:55 AM</option>
                                <option value="07.00">07:00 AM - Normal</option>
                                <option value="07.8">07:05 AM</option>
                                <option value="07.16">07:10 AM</option>
                                <option value="07.25">07:15 AM</option>
                                <option value="07.33">07:20 AM</option>
                                <option value="07.41">07:25 AM</option>
                                <option value="07.50">07:30 AM</option>
                                <option value="07.58">07:35 AM</option>
                                <option value="07.66">07:40 AM</option>
                                <option value="07.75">07:45 AM</option>
                                <option value="07.83">07:50 AM</option>
                                <option value="07.91">07:55 AM</option>
                                <option value="08.00">08:00 AM - o'clock</option>
                                <option value="08.8">08:05 AM</option>
                                <option value="08.16">08:10 AM</option>
                                <option value="08.25">08:15 AM</option>
                                <option value="08.33">08:20 AM</option>
                                <option value="08.41">08:25 AM</option>
                                <option value="08.50">08:30 AM</option>
                                <option value="08.58">08:35 AM</option>
                                <option value="08.66">08:40 AM</option>
                                <option value="08.75">08:45 AM</option>
                                <option value="08.83">08:50 AM</option>
                                <option value="08.91">08:55 AM</option>
                                <option value="09.00">09:00 AM - o'clock</option>
                                <option value="09.8">09:05 AM</option>
                                <option value="09.16">09:10 AM</option>
                                <option value="09.25">09:15 AM</option>
                                <option value="09.33">09:20 AM</option>
                                <option value="09.41">09:25 AM</option>
                                <option value="09.50">09:30 AM</option>
                                <option value="09.58">09:35 AM</option>
                                <option value="09.66">09:40 AM</option>
                                <option value="09.75">09:45 AM</option>
                                <option value="09.83">09:50 AM</option>
                                <option value="09.91">09:55 AM</option>
                                <option value="10.00">10:00 AM - o'clock</option>
                                <option value="10.8">10:05 AM</option>
                                <option value="10.16">10:10 AM</option>
                                <option value="10.25">10:15 AM</option>
                                <option value="10.33">10:20 AM</option>
                                <option value="10.41">10:25 AM</option>
                                <option value="10.50">10:30 AM</option>
                                <option value="10.58">10:35 AM</option>
                                <option value="10.66">10:40 AM</option>
                                <option value="10.75">10:45 AM</option>
                                <option value="10.83">10:50 AM</option>
                                <option value="10.91">10:55 AM</option>
                                <option value="11.00">11:00 AM - o'clock</option>
                                <option value="11.8">11:05 AM</option>
                                <option value="11.16">11:10 AM</option>
                                <option value="11.25">11:15 AM</option>
                                <option value="11.33">11:20 AM</option>
                                <option value="11.41">11:25 AM</option>
                                <option value="11.50">11:30 AM</option>
                                <option value="11.58">11:35 AM</option>
                                <option value="11.66">11:40 AM</option>
                                <option value="11.75">11:45 AM</option>
                                <option value="11.83">11:50 AM</option>
                                <option value="11.91">11:55 AM</option>
                                <option value="12.00">12:00 PM  - o'clock</option>
                                <option value="12.8">12:05 PM</option>
                                <option value="12.16">12:10 PM</option>
                                <option value="12.25">12:15 PM</option>
                                <option value="12.33">12:20 PM</option>
                                <option value="12.41">12:25 PM</option>
                                <option value="12.50">12:30 PM</option>
                                <option value="12.58">12:35 PM</option>
                                <option value="12.66">12:40 PM</option>
                                <option value="12.75">12:45 PM</option>
                                <option value="12.83">12:50 PM</option>
                                <option value="12.91">12:55 PM</option>
                                <option value="13.00">13:00 PM - o'clock</option>
                                <option value="13.8">13:05 PM</option>
                                <option value="13.16">13:10 PM</option>
                                <option value="13.25">13:15 PM</option>
                                <option value="13.33">13:20 PM</option>
                                <option value="13.41">13:25 PM</option>
                                <option value="13.50">13:30 PM</option>
                                <option value="13.58">13:35 PM</option>
                                <option value="13.66">13:40 PM</option>
                                <option value="13.75">13:45 PM</option>
                                <option value="13.83">13:50 PM</option>
                                <option value="13.91">13:55 PM</option>
                                <option value="14.00">14:00 PM - o'clock</option>
                                <option value="14.8">14:05 PM</option>
                                <option value="14.16">14:10 PM</option>
                                <option value="14.25">14:15 PM</option>
                                <option value="14.33">14:20 PM</option>
                                <option value="14.41">14:25 PM</option>
                                <option value="14.50">14:30 PM</option>
                                <option value="14.58">14:35 PM</option>
                                <option value="14.66">14:40 PM</option>
                                <option value="14.75">14:45 PM</option>
                                <option value="14.83">14:50 PM</option>
                                <option value="14.91">14:55 PM</option>
                                <option value="15.00">15:00 PM - o'clock</option>
                                <option value="15.8">15:05 PM</option>
                                <option value="15.16">15:10 PM</option>
                                <option value="15.25">15:15 PM</option>
                                <option value="15.33">15:20 PM</option>
                                <option value="15.41">15:25 PM</option>
                                <option value="15.50">15:30 PM</option>
                                <option value="15.58">15:35 PM</option>
                                <option value="15.66">15:40 PM</option>
                                <option value="15.75">15:45 PM</option>
                                <option value="15.83">15:50 PM</option>
                                <option value="15.91">15:55 PM</option>
                                <option value="16.00">16:00 PM - o'clock</option>
                                <option value="16.8">16:05 PM</option>
                                <option value="16.16">16:10 PM</option>
                                <option value="16.25">16:15 PM</option>
                                <option value="16.33">16:20 PM</option>
                                <option value="16.41">16:25 PM</option>
                                <option value="16.50">16:30 PM</option>
                                <option value="16.58">16:35 PM</option>
                                <option value="16.66">16:40 PM</option>
                                <option value="16.75">16:45 PM</option>
                                <option value="16.83">16:50 PM</option>
                                <option value="16.91">16:55 PM</option>
                                <option value="17.00">17:00 PM - o'clock</option>
                                <option value="17.8">17:05 PM</option>
                                <option value="17.16">17:10 PM</option>
                                <option value="17.25">17:15 PM</option>
                                <option value="17.33">17:20 PM</option>
                                <option value="17.41">17:25 PM</option>
                                <option value="17.50">17:30 PM</option>
                                <option value="17.58">17:35 PM</option>
                                <option value="17.66">17:40 PM</option>
                                <option value="17.75">17:45 PM</option>
                                <option value="17.83">17:50 PM</option>
                                <option value="17.91">17:55 PM</option>
                                <option value="18.00">18:00 PM - Normal</option>
                                <option value="18.8">18:05 PM</option>
                                <option value="18.16">18:10 PM</option>
                                <option value="18.25">18:15 PM</option>
                                <option value="18.33">18:20 PM</option>
                                <option value="18.41">18:25 PM</option>
                                <option value="18.50">18:30 PM</option>
                                <option value="18.58">18:35 PM</option>
                                <option value="18.66">18:40 PM</option>
                                <option value="18.75">18:45 PM</option>
                                <option value="18.83">18:50 PM</option>
                                <option value="18.91">18:55 PM</option>
                                <option value="19.00">19:00 PM - o'clock</option>
                                <option value="19.8">19:05 PM</option>
                                <option value="19.16">19:10 PM</option>
                                <option value="19.25">19:15 PM</option>
                                <option value="19.33">19:20 PM</option>
                                <option value="19.41">19:25 PM</option>
                                <option value="19.50">19:30 PM</option>
                                <option value="19.58">19:35 PM</option>
                                <option value="19.66">19:40 PM</option>
                                <option value="19.75">19:45 PM</option>
                                <option value="19.83">19:50 PM</option>
                                <option value="19.91">19:55 PM</option>
                                <option value="20.00">20:00 PM - o'clock</option>
                                <option value="20.8">20:05 PM</option>
                                <option value="20.16">20:10 PM</option>
                                <option value="20.25">20:15 PM</option>
                                <option value="20.33">20:20 PM</option>
                                <option value="20.41">20:25 PM</option>
                                <option value="20.50">20:30 PM</option>
                                <option value="20.58">20:35 PM</option>
                                <option value="20.66">20:40 PM</option>
                                <option value="20.75">20:45 PM</option>
                                <option value="20.83">20:50 PM</option>
                                <option value="20.91">20:55 PM</option>
                                <option value="21.00">21:00 PM - o'clock</option>
                                <option value="21.8">21:05 PM</option>
                                <option value="21.16">21:10 PM</option>
                                <option value="21.25">21:15 PM</option>
                                <option value="21.33">21:20 PM</option>
                                <option value="21.41">21:25 PM</option>
                                <option value="21.50">21:30 PM</option>
                                <option value="21.58">21:35 PM</option>
                                <option value="21.66">21:40 PM</option>
                                <option value="21.75">21:45 PM</option>
                                <option value="21.83">21:50 PM</option>
                                <option value="21.91">21:55 PM</option>
                                <option value="22.00">22:00 PM - o'clock</option>
                                <option value="22.8">22:05 PM</option>
                                <option value="22.16">22:10 PM</option>
                                <option value="22.25">22:15 PM</option>
                                <option value="22.33">22:20 PM</option>
                                <option value="22.41">22:25 PM</option>
                                <option value="22.50">22:30 PM</option>
                                <option value="22.58">22:35 PM</option>
                                <option value="22.66">22:40 PM</option>
                                <option value="22.75">22:45 PM</option>
                                <option value="22.83">22:50 PM</option>
                                <option value="22.91">22:55 PM</option>
                                <option value="23.00">23:00 PM - o'clock</option>
                                <option value="23.8">23:05 PM</option>
                                <option value="23.16">23:10 PM</option>
                                <option value="23.25">23:15 PM</option>
                                <option value="23.33">23:20 PM</option>
                                <option value="23.41">23:25 PM</option>
                                <option value="23.50">23:30 PM</option>
                                <option value="23.58">23:35 PM</option>
                                <option value="23.66">23:40 PM</option>
                                <option value="23.75">23:45 PM</option>
                                <option value="23.83">23:50 PM</option>
                                <option value="23.91">23:55 PM</option>
                                <option value="24.00">24:00 PM - o'clock</option>
                                <option value="24.8">15:05 PM</option>
                                <option value="24.16">24:10 PM</option>
                                <option value="24.25">24:15 PM</option>
                                <option value="24.33">24:20 PM</option>
                                <option value="24.41">24:25 PM</option>
                                <option value="24.50">24:30 PM</option>
                                <option value="24.58">24:35 PM</option>
                                <option value="24.66">24:40 PM</option>
                                <option value="24.75">24:45 PM</option>
                                <option value="24.83">24:50 PM</option>
                                <option value="24.91">24:55 PM</option>
                            </select>
                            {data8.map((datas, index) =>{
                              return<div key={index}>
                                        <p>{datas.endhour}</p>
                                    </div>
                                })}
                        </div>
                        <div className="col-md-2">
                            <select name="type8" onChange={e =>  settype8(e.target.value)} className="form-control" required>
                                <option value="">Select Type</option>
                                <option value="Normal">Normal</option>
                                <option value="SJUK">SJUK</option>
                                <option value="Holiday">Holiday</option>
                            </select>
                            {data8.map((datas, index) =>{
                              return<div key={index}>
                                        <p>{datas.type}</p>
                                    </div>
                                })}
                          </div>
                          <div className="col-md-2"> 
                              <button type='submit' className="btn btn-primary">Submit</button>
                        </div>
                      </form>
                  </div>
                  <div className="d-flex flex-column p-2">
                     <form action="" className="row"  onSubmit={handleSubmit9}>
                        <div className="col-md-1"> 09 </div>
                        <div className="col-md-1"> Wednesday  </div>
                        <div className="col-md-1" > 
                            {data9.map((types, index) =>{
                              return<tr key={index}>
                                   <p style={types.type === "Normal" ? {backgroundColor:'rgba(7, 190, 7, 0.648)', color:'#000', padding:'5px 10px'} : 
                                     (types.type === "Holiday" ? {backgroundColor:'rgba(255, 0, 0, 0.438)', color:'#000', padding:'5px 10px'} : 
                                      {backgroundColor:'#008cff5a', color:'#000', padding:'5px 10px'}) }> {types.type}</p>
                                    </tr>
                                })}
                        </div>
                        <div className="col-md-2">
                            <select name="starthour9" onChange={e =>  setStart9(e.target.value)} className="form-control">
                                <option value="">Start Time</option>
                                <option value="05.00">05:00 AM</option>
                                <option value="06.00">06:00 AM</option>
                                <option value="06.8">06:05 AM</option>
                                <option value="06.16">06:10 AM</option>
                                <option value="06.25">06:15 AM</option>
                                <option value="06.33">06:20 AM</option>
                                <option value="06.41">06:25 AM</option>
                                <option value="06.50">06:30 AM</option>
                                <option value="06.58">06:35 AM</option>
                                <option value="06.66">06:40 AM</option>
                                <option value="06.75">06:45 AM</option>
                                <option value="06.83">06:50 AM</option>
                                <option value="06.91">06:55 AM</option>
                                <option value="07.00">07:00 AM - Normal</option>
                                <option value="07.8">07:05 AM</option>
                                <option value="07.16">07:10 AM</option>
                                <option value="07.25">07:15 AM</option>
                                <option value="07.33">07:20 AM</option>
                                <option value="07.41">07:25 AM</option>
                                <option value="07.50">07:30 AM</option>
                                <option value="07.58">07:35 AM</option>
                                <option value="07.66">07:40 AM</option>
                                <option value="07.75">07:45 AM</option>
                                <option value="07.83">07:50 AM</option>
                                <option value="07.91">07:55 AM</option>
                                <option value="08.00">08:00 AM - o'clock</option>
                                <option value="08.8">08:05 AM</option>
                                <option value="08.16">08:10 AM</option>
                                <option value="08.25">08:15 AM</option>
                                <option value="08.33">08:20 AM</option>
                                <option value="08.41">08:25 AM</option>
                                <option value="08.50">08:30 AM</option>
                                <option value="08.58">08:35 AM</option>
                                <option value="08.66">08:40 AM</option>
                                <option value="08.75">08:45 AM</option>
                                <option value="08.83">08:50 AM</option>
                                <option value="08.91">08:55 AM</option>
                                <option value="09.00">09:00 AM - o'clock</option>
                                <option value="09.8">09:05 AM</option>
                                <option value="09.16">09:10 AM</option>
                                <option value="09.25">09:15 AM</option>
                                <option value="09.33">09:20 AM</option>
                                <option value="09.41">09:25 AM</option>
                                <option value="09.50">09:30 AM</option>
                                <option value="09.58">09:35 AM</option>
                                <option value="09.66">09:40 AM</option>
                                <option value="09.75">09:45 AM</option>
                                <option value="09.83">09:50 AM</option>
                                <option value="09.91">09:55 AM</option>
                                <option value="10.00">10:00 AM - o'clock</option>
                                <option value="10.8">10:05 AM</option>
                                <option value="10.16">10:10 AM</option>
                                <option value="10.25">10:15 AM</option>
                                <option value="10.33">10:20 AM</option>
                                <option value="10.41">10:25 AM</option>
                                <option value="10.50">10:30 AM</option>
                                <option value="10.58">10:35 AM</option>
                                <option value="10.66">10:40 AM</option>
                                <option value="10.75">10:45 AM</option>
                                <option value="10.83">10:50 AM</option>
                                <option value="10.91">10:55 AM</option>
                                <option value="11.00">11:00 AM - o'clock</option>
                                <option value="11.8">11:05 AM</option>
                                <option value="11.16">11:10 AM</option>
                                <option value="11.25">11:15 AM</option>
                                <option value="11.33">11:20 AM</option>
                                <option value="11.41">11:25 AM</option>
                                <option value="11.50">11:30 AM</option>
                                <option value="11.58">11:35 AM</option>
                                <option value="11.66">11:40 AM</option>
                                <option value="11.75">11:45 AM</option>
                                <option value="11.83">11:50 AM</option>
                                <option value="11.91">11:55 AM</option>
                                <option value="12.00">12:00 PM  - o'clock</option>
                                <option value="12.8">12:05 PM</option>
                                <option value="12.16">12:10 PM</option>
                                <option value="12.25">12:15 PM</option>
                                <option value="12.33">12:20 PM</option>
                                <option value="12.41">12:25 PM</option>
                                <option value="12.50">12:30 PM</option>
                                <option value="12.58">12:35 PM</option>
                                <option value="12.66">12:40 PM</option>
                                <option value="12.75">12:45 PM</option>
                                <option value="12.83">12:50 PM</option>
                                <option value="12.91">12:55 PM</option>
                                <option value="13.00">13:00 PM - o'clock</option>
                                <option value="13.8">13:05 PM</option>
                                <option value="13.16">13:10 PM</option>
                                <option value="13.25">13:15 PM</option>
                                <option value="13.33">13:20 PM</option>
                                <option value="13.41">13:25 PM</option>
                                <option value="13.50">13:30 PM</option>
                                <option value="13.58">13:35 PM</option>
                                <option value="13.66">13:40 PM</option>
                                <option value="13.75">13:45 PM</option>
                                <option value="13.83">13:50 PM</option>
                                <option value="13.91">13:55 PM</option>
                                <option value="14.00">14:00 PM - o'clock</option>
                                <option value="14.8">14:05 PM</option>
                                <option value="14.16">14:10 PM</option>
                                <option value="14.25">14:15 PM</option>
                                <option value="14.33">14:20 PM</option>
                                <option value="14.41">14:25 PM</option>
                                <option value="14.50">14:30 PM</option>
                                <option value="14.58">14:35 PM</option>
                                <option value="14.66">14:40 PM</option>
                                <option value="14.75">14:45 PM</option>
                                <option value="14.83">14:50 PM</option>
                                <option value="14.91">14:55 PM</option>
                                <option value="15.00">15:00 PM - o'clock</option>
                                <option value="15.8">15:05 PM</option>
                                <option value="15.16">15:10 PM</option>
                                <option value="15.25">15:15 PM</option>
                                <option value="15.33">15:20 PM</option>
                                <option value="15.41">15:25 PM</option>
                                <option value="15.50">15:30 PM</option>
                                <option value="15.58">15:35 PM</option>
                                <option value="15.66">15:40 PM</option>
                                <option value="15.75">15:45 PM</option>
                                <option value="15.83">15:50 PM</option>
                                <option value="15.91">15:55 PM</option>
                                <option value="16.00">16:00 PM - o'clock</option>
                                <option value="16.8">16:05 PM</option>
                                <option value="16.16">16:10 PM</option>
                                <option value="16.25">16:15 PM</option>
                                <option value="16.33">16:20 PM</option>
                                <option value="16.41">16:25 PM</option>
                                <option value="16.50">16:30 PM</option>
                                <option value="16.58">16:35 PM</option>
                                <option value="16.66">16:40 PM</option>
                                <option value="16.75">16:45 PM</option>
                                <option value="16.83">16:50 PM</option>
                                <option value="16.91">16:55 PM</option>
                                <option value="17.00">17:00 PM - o'clock</option>
                                <option value="17.8">17:05 PM</option>
                                <option value="17.16">17:10 PM</option>
                                <option value="17.25">17:15 PM</option>
                                <option value="17.33">17:20 PM</option>
                                <option value="17.41">17:25 PM</option>
                                <option value="17.50">17:30 PM</option>
                                <option value="17.58">17:35 PM</option>
                                <option value="17.66">17:40 PM</option>
                                <option value="17.75">17:45 PM</option>
                                <option value="17.83">17:50 PM</option>
                                <option value="17.91">17:55 PM</option>
                                <option value="18.00">18:00 PM - Normal</option>
                                <option value="18.8">18:05 PM</option>
                                <option value="18.16">18:10 PM</option>
                                <option value="18.25">18:15 PM</option>
                                <option value="18.33">18:20 PM</option>
                                <option value="18.41">18:25 PM</option>
                                <option value="18.50">18:30 PM</option>
                                <option value="18.58">18:35 PM</option>
                                <option value="18.66">18:40 PM</option>
                                <option value="18.75">18:45 PM</option>
                                <option value="18.83">18:50 PM</option>
                                <option value="18.91">18:55 PM</option>
                                <option value="19.00">19:00 PM - o'clock</option>
                                <option value="19.8">19:05 PM</option>
                                <option value="19.16">19:10 PM</option>
                                <option value="19.25">19:15 PM</option>
                                <option value="19.33">19:20 PM</option>
                                <option value="19.41">19:25 PM</option>
                                <option value="19.50">19:30 PM</option>
                                <option value="19.58">19:35 PM</option>
                                <option value="19.66">19:40 PM</option>
                                <option value="19.75">19:45 PM</option>
                                <option value="19.83">19:50 PM</option>
                                <option value="19.91">19:55 PM</option>
                                <option value="20.00">20:00 PM - o'clock</option>
                                <option value="20.8">20:05 PM</option>
                                <option value="20.16">20:10 PM</option>
                                <option value="20.25">20:15 PM</option>
                                <option value="20.33">20:20 PM</option>
                                <option value="20.41">20:25 PM</option>
                                <option value="20.50">20:30 PM</option>
                                <option value="20.58">20:35 PM</option>
                                <option value="20.66">20:40 PM</option>
                                <option value="20.75">20:45 PM</option>
                                <option value="20.83">20:50 PM</option>
                                <option value="20.91">20:55 PM</option>
                                <option value="21.00">21:00 PM - o'clock</option>
                                <option value="21.8">21:05 PM</option>
                                <option value="21.16">21:10 PM</option>
                                <option value="21.25">21:15 PM</option>
                                <option value="21.33">21:20 PM</option>
                                <option value="21.41">21:25 PM</option>
                                <option value="21.50">21:30 PM</option>
                                <option value="21.58">21:35 PM</option>
                                <option value="21.66">21:40 PM</option>
                                <option value="21.75">21:45 PM</option>
                                <option value="21.83">21:50 PM</option>
                                <option value="21.91">21:55 PM</option>
                                <option value="22.00">22:00 PM - o'clock</option>
                                <option value="22.8">22:05 PM</option>
                                <option value="22.16">22:10 PM</option>
                                <option value="22.25">22:15 PM</option>
                                <option value="22.33">22:20 PM</option>
                                <option value="22.41">22:25 PM</option>
                                <option value="22.50">22:30 PM</option>
                                <option value="22.58">22:35 PM</option>
                                <option value="22.66">22:40 PM</option>
                                <option value="22.75">22:45 PM</option>
                                <option value="22.83">22:50 PM</option>
                                <option value="22.91">22:55 PM</option>
                                <option value="23.00">23:00 PM - o'clock</option>
                                <option value="23.8">23:05 PM</option>
                                <option value="23.16">23:10 PM</option>
                                <option value="23.25">23:15 PM</option>
                                <option value="23.33">23:20 PM</option>
                                <option value="23.41">23:25 PM</option>
                                <option value="23.50">23:30 PM</option>
                                <option value="23.58">23:35 PM</option>
                                <option value="23.66">23:40 PM</option>
                                <option value="23.75">23:45 PM</option>
                                <option value="23.83">23:50 PM</option>
                                <option value="23.91">23:55 PM</option>
                                <option value="24.00">24:00 PM - o'clock</option>
                                <option value="24.8">15:05 PM</option>
                                <option value="24.16">24:10 PM</option>
                                <option value="24.25">24:15 PM</option>
                                <option value="24.33">24:20 PM</option>
                                <option value="24.41">24:25 PM</option>
                                <option value="24.50">24:30 PM</option>
                                <option value="24.58">24:35 PM</option>
                                <option value="24.66">24:40 PM</option>
                                <option value="24.75">24:45 PM</option>
                                <option value="24.83">24:50 PM</option>
                                <option value="24.91">24:55 PM</option>
                            </select>
                            {data9.map((datas, index) =>{
                              return<div key={index}>
                                        <p>{datas.starthour}</p>
                                    </div>
                                })}
                        </div>
                        <div className="col-md-2">
                            <select name="endhour9" onChange={e =>  setEnd9(e.target.value)} className="form-control">
                                <option value="">Finish Time</option>
                                <option value="05.00">05:00 AM</option>
                                <option value="06.00">06:00 AM</option>
                                <option value="06.8">06:05 AM</option>
                                <option value="06.16">06:10 AM</option>
                                <option value="06.25">06:15 AM</option>
                                <option value="06.33">06:20 AM</option>
                                <option value="06.41">06:25 AM</option>
                                <option value="06.50">06:30 AM</option>
                                <option value="06.58">06:35 AM</option>
                                <option value="06.66">06:40 AM</option>
                                <option value="06.75">06:45 AM</option>
                                <option value="06.83">06:50 AM</option>
                                <option value="06.91">06:55 AM</option>
                                <option value="07.00">07:00 AM - Normal</option>
                                <option value="07.8">07:05 AM</option>
                                <option value="07.16">07:10 AM</option>
                                <option value="07.25">07:15 AM</option>
                                <option value="07.33">07:20 AM</option>
                                <option value="07.41">07:25 AM</option>
                                <option value="07.50">07:30 AM</option>
                                <option value="07.58">07:35 AM</option>
                                <option value="07.66">07:40 AM</option>
                                <option value="07.75">07:45 AM</option>
                                <option value="07.83">07:50 AM</option>
                                <option value="07.91">07:55 AM</option>
                                <option value="08.00">08:00 AM - o'clock</option>
                                <option value="08.8">08:05 AM</option>
                                <option value="08.16">08:10 AM</option>
                                <option value="08.25">08:15 AM</option>
                                <option value="08.33">08:20 AM</option>
                                <option value="08.41">08:25 AM</option>
                                <option value="08.50">08:30 AM</option>
                                <option value="08.58">08:35 AM</option>
                                <option value="08.66">08:40 AM</option>
                                <option value="08.75">08:45 AM</option>
                                <option value="08.83">08:50 AM</option>
                                <option value="08.91">08:55 AM</option>
                                <option value="09.00">09:00 AM - o'clock</option>
                                <option value="09.8">09:05 AM</option>
                                <option value="09.16">09:10 AM</option>
                                <option value="09.25">09:15 AM</option>
                                <option value="09.33">09:20 AM</option>
                                <option value="09.41">09:25 AM</option>
                                <option value="09.50">09:30 AM</option>
                                <option value="09.58">09:35 AM</option>
                                <option value="09.66">09:40 AM</option>
                                <option value="09.75">09:45 AM</option>
                                <option value="09.83">09:50 AM</option>
                                <option value="09.91">09:55 AM</option>
                                <option value="10.00">10:00 AM - o'clock</option>
                                <option value="10.8">10:05 AM</option>
                                <option value="10.16">10:10 AM</option>
                                <option value="10.25">10:15 AM</option>
                                <option value="10.33">10:20 AM</option>
                                <option value="10.41">10:25 AM</option>
                                <option value="10.50">10:30 AM</option>
                                <option value="10.58">10:35 AM</option>
                                <option value="10.66">10:40 AM</option>
                                <option value="10.75">10:45 AM</option>
                                <option value="10.83">10:50 AM</option>
                                <option value="10.91">10:55 AM</option>
                                <option value="11.00">11:00 AM - o'clock</option>
                                <option value="11.8">11:05 AM</option>
                                <option value="11.16">11:10 AM</option>
                                <option value="11.25">11:15 AM</option>
                                <option value="11.33">11:20 AM</option>
                                <option value="11.41">11:25 AM</option>
                                <option value="11.50">11:30 AM</option>
                                <option value="11.58">11:35 AM</option>
                                <option value="11.66">11:40 AM</option>
                                <option value="11.75">11:45 AM</option>
                                <option value="11.83">11:50 AM</option>
                                <option value="11.91">11:55 AM</option>
                                <option value="12.00">12:00 PM  - o'clock</option>
                                <option value="12.8">12:05 PM</option>
                                <option value="12.16">12:10 PM</option>
                                <option value="12.25">12:15 PM</option>
                                <option value="12.33">12:20 PM</option>
                                <option value="12.41">12:25 PM</option>
                                <option value="12.50">12:30 PM</option>
                                <option value="12.58">12:35 PM</option>
                                <option value="12.66">12:40 PM</option>
                                <option value="12.75">12:45 PM</option>
                                <option value="12.83">12:50 PM</option>
                                <option value="12.91">12:55 PM</option>
                                <option value="13.00">13:00 PM - o'clock</option>
                                <option value="13.8">13:05 PM</option>
                                <option value="13.16">13:10 PM</option>
                                <option value="13.25">13:15 PM</option>
                                <option value="13.33">13:20 PM</option>
                                <option value="13.41">13:25 PM</option>
                                <option value="13.50">13:30 PM</option>
                                <option value="13.58">13:35 PM</option>
                                <option value="13.66">13:40 PM</option>
                                <option value="13.75">13:45 PM</option>
                                <option value="13.83">13:50 PM</option>
                                <option value="13.91">13:55 PM</option>
                                <option value="14.00">14:00 PM - o'clock</option>
                                <option value="14.8">14:05 PM</option>
                                <option value="14.16">14:10 PM</option>
                                <option value="14.25">14:15 PM</option>
                                <option value="14.33">14:20 PM</option>
                                <option value="14.41">14:25 PM</option>
                                <option value="14.50">14:30 PM</option>
                                <option value="14.58">14:35 PM</option>
                                <option value="14.66">14:40 PM</option>
                                <option value="14.75">14:45 PM</option>
                                <option value="14.83">14:50 PM</option>
                                <option value="14.91">14:55 PM</option>
                                <option value="15.00">15:00 PM - o'clock</option>
                                <option value="15.8">15:05 PM</option>
                                <option value="15.16">15:10 PM</option>
                                <option value="15.25">15:15 PM</option>
                                <option value="15.33">15:20 PM</option>
                                <option value="15.41">15:25 PM</option>
                                <option value="15.50">15:30 PM</option>
                                <option value="15.58">15:35 PM</option>
                                <option value="15.66">15:40 PM</option>
                                <option value="15.75">15:45 PM</option>
                                <option value="15.83">15:50 PM</option>
                                <option value="15.91">15:55 PM</option>
                                <option value="16.00">16:00 PM - o'clock</option>
                                <option value="16.8">16:05 PM</option>
                                <option value="16.16">16:10 PM</option>
                                <option value="16.25">16:15 PM</option>
                                <option value="16.33">16:20 PM</option>
                                <option value="16.41">16:25 PM</option>
                                <option value="16.50">16:30 PM</option>
                                <option value="16.58">16:35 PM</option>
                                <option value="16.66">16:40 PM</option>
                                <option value="16.75">16:45 PM</option>
                                <option value="16.83">16:50 PM</option>
                                <option value="16.91">16:55 PM</option>
                                <option value="17.00">17:00 PM - o'clock</option>
                                <option value="17.8">17:05 PM</option>
                                <option value="17.16">17:10 PM</option>
                                <option value="17.25">17:15 PM</option>
                                <option value="17.33">17:20 PM</option>
                                <option value="17.41">17:25 PM</option>
                                <option value="17.50">17:30 PM</option>
                                <option value="17.58">17:35 PM</option>
                                <option value="17.66">17:40 PM</option>
                                <option value="17.75">17:45 PM</option>
                                <option value="17.83">17:50 PM</option>
                                <option value="17.91">17:55 PM</option>
                                <option value="18.00">18:00 PM - Normal</option>
                                <option value="18.8">18:05 PM</option>
                                <option value="18.16">18:10 PM</option>
                                <option value="18.25">18:15 PM</option>
                                <option value="18.33">18:20 PM</option>
                                <option value="18.41">18:25 PM</option>
                                <option value="18.50">18:30 PM</option>
                                <option value="18.58">18:35 PM</option>
                                <option value="18.66">18:40 PM</option>
                                <option value="18.75">18:45 PM</option>
                                <option value="18.83">18:50 PM</option>
                                <option value="18.91">18:55 PM</option>
                                <option value="19.00">19:00 PM - o'clock</option>
                                <option value="19.8">19:05 PM</option>
                                <option value="19.16">19:10 PM</option>
                                <option value="19.25">19:15 PM</option>
                                <option value="19.33">19:20 PM</option>
                                <option value="19.41">19:25 PM</option>
                                <option value="19.50">19:30 PM</option>
                                <option value="19.58">19:35 PM</option>
                                <option value="19.66">19:40 PM</option>
                                <option value="19.75">19:45 PM</option>
                                <option value="19.83">19:50 PM</option>
                                <option value="19.91">19:55 PM</option>
                                <option value="20.00">20:00 PM - o'clock</option>
                                <option value="20.8">20:05 PM</option>
                                <option value="20.16">20:10 PM</option>
                                <option value="20.25">20:15 PM</option>
                                <option value="20.33">20:20 PM</option>
                                <option value="20.41">20:25 PM</option>
                                <option value="20.50">20:30 PM</option>
                                <option value="20.58">20:35 PM</option>
                                <option value="20.66">20:40 PM</option>
                                <option value="20.75">20:45 PM</option>
                                <option value="20.83">20:50 PM</option>
                                <option value="20.91">20:55 PM</option>
                                <option value="21.00">21:00 PM - o'clock</option>
                                <option value="21.8">21:05 PM</option>
                                <option value="21.16">21:10 PM</option>
                                <option value="21.25">21:15 PM</option>
                                <option value="21.33">21:20 PM</option>
                                <option value="21.41">21:25 PM</option>
                                <option value="21.50">21:30 PM</option>
                                <option value="21.58">21:35 PM</option>
                                <option value="21.66">21:40 PM</option>
                                <option value="21.75">21:45 PM</option>
                                <option value="21.83">21:50 PM</option>
                                <option value="21.91">21:55 PM</option>
                                <option value="22.00">22:00 PM - o'clock</option>
                                <option value="22.8">22:05 PM</option>
                                <option value="22.16">22:10 PM</option>
                                <option value="22.25">22:15 PM</option>
                                <option value="22.33">22:20 PM</option>
                                <option value="22.41">22:25 PM</option>
                                <option value="22.50">22:30 PM</option>
                                <option value="22.58">22:35 PM</option>
                                <option value="22.66">22:40 PM</option>
                                <option value="22.75">22:45 PM</option>
                                <option value="22.83">22:50 PM</option>
                                <option value="22.91">22:55 PM</option>
                                <option value="23.00">23:00 PM - o'clock</option>
                                <option value="23.8">23:05 PM</option>
                                <option value="23.16">23:10 PM</option>
                                <option value="23.25">23:15 PM</option>
                                <option value="23.33">23:20 PM</option>
                                <option value="23.41">23:25 PM</option>
                                <option value="23.50">23:30 PM</option>
                                <option value="23.58">23:35 PM</option>
                                <option value="23.66">23:40 PM</option>
                                <option value="23.75">23:45 PM</option>
                                <option value="23.83">23:50 PM</option>
                                <option value="23.91">23:55 PM</option>
                                <option value="24.00">24:00 PM - o'clock</option>
                                <option value="24.8">15:05 PM</option>
                                <option value="24.16">24:10 PM</option>
                                <option value="24.25">24:15 PM</option>
                                <option value="24.33">24:20 PM</option>
                                <option value="24.41">24:25 PM</option>
                                <option value="24.50">24:30 PM</option>
                                <option value="24.58">24:35 PM</option>
                                <option value="24.66">24:40 PM</option>
                                <option value="24.75">24:45 PM</option>
                                <option value="24.83">24:50 PM</option>
                                <option value="24.91">24:55 PM</option>
                            </select>
                            {data9.map((datas, index) =>{
                              return<div key={index}>
                                        <p>{datas.endhour}</p>
                                    </div>
                                })}
                        </div>
                        <div className="col-md-2">
                            <select name="type9" onChange={e =>  settype9(e.target.value)} className="form-control" required>
                                <option value="">Select Type</option>
                                <option value="Normal">Normal</option>
                                <option value="SJUK">SJUK</option>
                                <option value="Holiday">Holiday</option>
                            </select>
                            {data9.map((datas, index) =>{
                              return<div key={index}>
                                        <p>{datas.type}</p>
                                    </div>
                                })}
                          </div>
                          <div className="col-md-2"> 
                              <button type='submit' className="btn btn-primary">Submit</button>
                        </div>
                      </form>
                  </div>
                  <div className="d-flex flex-column p-2">
                     <form action="" className="row"  onSubmit={handleSubmit10}>
                        <div className="col-md-1"> 10 </div>
                        <div className="col-md-1"> Thursday </div>
                        <div className="col-md-1" > 
                            {data10.map((types, index) =>{
                              return<tr key={index}>
                                   <p style={types.type === "Normal" ? {backgroundColor:'rgba(7, 190, 7, 0.648)', color:'#000', padding:'5px 10px'} : 
                                     (types.type === "Holiday" ? {backgroundColor:'rgba(255, 0, 0, 0.438)', color:'#000', padding:'5px 10px'} : 
                                      {backgroundColor:'#008cff5a', color:'#000', padding:'5px 10px'}) }> {types.type}</p>
                                    </tr>
                                })}
                        </div>
                        <div className="col-md-2">
                            <select name="starthour10" onChange={e =>  setStart10(e.target.value)} className="form-control">
                                <option value="">Start Time</option>
                                <option value="05.00">05:00 AM</option>
                                <option value="06.00">06:00 AM</option>
                                <option value="06.8">06:05 AM</option>
                                <option value="06.16">06:10 AM</option>
                                <option value="06.25">06:15 AM</option>
                                <option value="06.33">06:20 AM</option>
                                <option value="06.41">06:25 AM</option>
                                <option value="06.50">06:30 AM</option>
                                <option value="06.58">06:35 AM</option>
                                <option value="06.66">06:40 AM</option>
                                <option value="06.75">06:45 AM</option>
                                <option value="06.83">06:50 AM</option>
                                <option value="06.91">06:55 AM</option>
                                <option value="07.00">07:00 AM - Normal</option>
                                <option value="07.8">07:05 AM</option>
                                <option value="07.16">07:10 AM</option>
                                <option value="07.25">07:15 AM</option>
                                <option value="07.33">07:20 AM</option>
                                <option value="07.41">07:25 AM</option>
                                <option value="07.50">07:30 AM</option>
                                <option value="07.58">07:35 AM</option>
                                <option value="07.66">07:40 AM</option>
                                <option value="07.75">07:45 AM</option>
                                <option value="07.83">07:50 AM</option>
                                <option value="07.91">07:55 AM</option>
                                <option value="08.00">08:00 AM - o'clock</option>
                                <option value="08.8">08:05 AM</option>
                                <option value="08.16">08:10 AM</option>
                                <option value="08.25">08:15 AM</option>
                                <option value="08.33">08:20 AM</option>
                                <option value="08.41">08:25 AM</option>
                                <option value="08.50">08:30 AM</option>
                                <option value="08.58">08:35 AM</option>
                                <option value="08.66">08:40 AM</option>
                                <option value="08.75">08:45 AM</option>
                                <option value="08.83">08:50 AM</option>
                                <option value="08.91">08:55 AM</option>
                                <option value="09.00">09:00 AM - o'clock</option>
                                <option value="09.8">09:05 AM</option>
                                <option value="09.16">09:10 AM</option>
                                <option value="09.25">09:15 AM</option>
                                <option value="09.33">09:20 AM</option>
                                <option value="09.41">09:25 AM</option>
                                <option value="09.50">09:30 AM</option>
                                <option value="09.58">09:35 AM</option>
                                <option value="09.66">09:40 AM</option>
                                <option value="09.75">09:45 AM</option>
                                <option value="09.83">09:50 AM</option>
                                <option value="09.91">09:55 AM</option>
                                <option value="10.00">10:00 AM - o'clock</option>
                                <option value="10.8">10:05 AM</option>
                                <option value="10.16">10:10 AM</option>
                                <option value="10.25">10:15 AM</option>
                                <option value="10.33">10:20 AM</option>
                                <option value="10.41">10:25 AM</option>
                                <option value="10.50">10:30 AM</option>
                                <option value="10.58">10:35 AM</option>
                                <option value="10.66">10:40 AM</option>
                                <option value="10.75">10:45 AM</option>
                                <option value="10.83">10:50 AM</option>
                                <option value="10.91">10:55 AM</option>
                                <option value="11.00">11:00 AM - o'clock</option>
                                <option value="11.8">11:05 AM</option>
                                <option value="11.16">11:10 AM</option>
                                <option value="11.25">11:15 AM</option>
                                <option value="11.33">11:20 AM</option>
                                <option value="11.41">11:25 AM</option>
                                <option value="11.50">11:30 AM</option>
                                <option value="11.58">11:35 AM</option>
                                <option value="11.66">11:40 AM</option>
                                <option value="11.75">11:45 AM</option>
                                <option value="11.83">11:50 AM</option>
                                <option value="11.91">11:55 AM</option>
                                <option value="12.00">12:00 PM  - o'clock</option>
                                <option value="12.8">12:05 PM</option>
                                <option value="12.16">12:10 PM</option>
                                <option value="12.25">12:15 PM</option>
                                <option value="12.33">12:20 PM</option>
                                <option value="12.41">12:25 PM</option>
                                <option value="12.50">12:30 PM</option>
                                <option value="12.58">12:35 PM</option>
                                <option value="12.66">12:40 PM</option>
                                <option value="12.75">12:45 PM</option>
                                <option value="12.83">12:50 PM</option>
                                <option value="12.91">12:55 PM</option>
                                <option value="13.00">13:00 PM - o'clock</option>
                                <option value="13.8">13:05 PM</option>
                                <option value="13.16">13:10 PM</option>
                                <option value="13.25">13:15 PM</option>
                                <option value="13.33">13:20 PM</option>
                                <option value="13.41">13:25 PM</option>
                                <option value="13.50">13:30 PM</option>
                                <option value="13.58">13:35 PM</option>
                                <option value="13.66">13:40 PM</option>
                                <option value="13.75">13:45 PM</option>
                                <option value="13.83">13:50 PM</option>
                                <option value="13.91">13:55 PM</option>
                                <option value="14.00">14:00 PM - o'clock</option>
                                <option value="14.8">14:05 PM</option>
                                <option value="14.16">14:10 PM</option>
                                <option value="14.25">14:15 PM</option>
                                <option value="14.33">14:20 PM</option>
                                <option value="14.41">14:25 PM</option>
                                <option value="14.50">14:30 PM</option>
                                <option value="14.58">14:35 PM</option>
                                <option value="14.66">14:40 PM</option>
                                <option value="14.75">14:45 PM</option>
                                <option value="14.83">14:50 PM</option>
                                <option value="14.91">14:55 PM</option>
                                <option value="15.00">15:00 PM - o'clock</option>
                                <option value="15.8">15:05 PM</option>
                                <option value="15.16">15:10 PM</option>
                                <option value="15.25">15:15 PM</option>
                                <option value="15.33">15:20 PM</option>
                                <option value="15.41">15:25 PM</option>
                                <option value="15.50">15:30 PM</option>
                                <option value="15.58">15:35 PM</option>
                                <option value="15.66">15:40 PM</option>
                                <option value="15.75">15:45 PM</option>
                                <option value="15.83">15:50 PM</option>
                                <option value="15.91">15:55 PM</option>
                                <option value="16.00">16:00 PM - o'clock</option>
                                <option value="16.8">16:05 PM</option>
                                <option value="16.16">16:10 PM</option>
                                <option value="16.25">16:15 PM</option>
                                <option value="16.33">16:20 PM</option>
                                <option value="16.41">16:25 PM</option>
                                <option value="16.50">16:30 PM</option>
                                <option value="16.58">16:35 PM</option>
                                <option value="16.66">16:40 PM</option>
                                <option value="16.75">16:45 PM</option>
                                <option value="16.83">16:50 PM</option>
                                <option value="16.91">16:55 PM</option>
                                <option value="17.00">17:00 PM - o'clock</option>
                                <option value="17.8">17:05 PM</option>
                                <option value="17.16">17:10 PM</option>
                                <option value="17.25">17:15 PM</option>
                                <option value="17.33">17:20 PM</option>
                                <option value="17.41">17:25 PM</option>
                                <option value="17.50">17:30 PM</option>
                                <option value="17.58">17:35 PM</option>
                                <option value="17.66">17:40 PM</option>
                                <option value="17.75">17:45 PM</option>
                                <option value="17.83">17:50 PM</option>
                                <option value="17.91">17:55 PM</option>
                                <option value="18.00">18:00 PM - Normal</option>
                                <option value="18.8">18:05 PM</option>
                                <option value="18.16">18:10 PM</option>
                                <option value="18.25">18:15 PM</option>
                                <option value="18.33">18:20 PM</option>
                                <option value="18.41">18:25 PM</option>
                                <option value="18.50">18:30 PM</option>
                                <option value="18.58">18:35 PM</option>
                                <option value="18.66">18:40 PM</option>
                                <option value="18.75">18:45 PM</option>
                                <option value="18.83">18:50 PM</option>
                                <option value="18.91">18:55 PM</option>
                                <option value="19.00">19:00 PM - o'clock</option>
                                <option value="19.8">19:05 PM</option>
                                <option value="19.16">19:10 PM</option>
                                <option value="19.25">19:15 PM</option>
                                <option value="19.33">19:20 PM</option>
                                <option value="19.41">19:25 PM</option>
                                <option value="19.50">19:30 PM</option>
                                <option value="19.58">19:35 PM</option>
                                <option value="19.66">19:40 PM</option>
                                <option value="19.75">19:45 PM</option>
                                <option value="19.83">19:50 PM</option>
                                <option value="19.91">19:55 PM</option>
                                <option value="20.00">20:00 PM - o'clock</option>
                                <option value="20.8">20:05 PM</option>
                                <option value="20.16">20:10 PM</option>
                                <option value="20.25">20:15 PM</option>
                                <option value="20.33">20:20 PM</option>
                                <option value="20.41">20:25 PM</option>
                                <option value="20.50">20:30 PM</option>
                                <option value="20.58">20:35 PM</option>
                                <option value="20.66">20:40 PM</option>
                                <option value="20.75">20:45 PM</option>
                                <option value="20.83">20:50 PM</option>
                                <option value="20.91">20:55 PM</option>
                                <option value="21.00">21:00 PM - o'clock</option>
                                <option value="21.8">21:05 PM</option>
                                <option value="21.16">21:10 PM</option>
                                <option value="21.25">21:15 PM</option>
                                <option value="21.33">21:20 PM</option>
                                <option value="21.41">21:25 PM</option>
                                <option value="21.50">21:30 PM</option>
                                <option value="21.58">21:35 PM</option>
                                <option value="21.66">21:40 PM</option>
                                <option value="21.75">21:45 PM</option>
                                <option value="21.83">21:50 PM</option>
                                <option value="21.91">21:55 PM</option>
                                <option value="22.00">22:00 PM - o'clock</option>
                                <option value="22.8">22:05 PM</option>
                                <option value="22.16">22:10 PM</option>
                                <option value="22.25">22:15 PM</option>
                                <option value="22.33">22:20 PM</option>
                                <option value="22.41">22:25 PM</option>
                                <option value="22.50">22:30 PM</option>
                                <option value="22.58">22:35 PM</option>
                                <option value="22.66">22:40 PM</option>
                                <option value="22.75">22:45 PM</option>
                                <option value="22.83">22:50 PM</option>
                                <option value="22.91">22:55 PM</option>
                                <option value="23.00">23:00 PM - o'clock</option>
                                <option value="23.8">23:05 PM</option>
                                <option value="23.16">23:10 PM</option>
                                <option value="23.25">23:15 PM</option>
                                <option value="23.33">23:20 PM</option>
                                <option value="23.41">23:25 PM</option>
                                <option value="23.50">23:30 PM</option>
                                <option value="23.58">23:35 PM</option>
                                <option value="23.66">23:40 PM</option>
                                <option value="23.75">23:45 PM</option>
                                <option value="23.83">23:50 PM</option>
                                <option value="23.91">23:55 PM</option>
                                <option value="24.00">24:00 PM - o'clock</option>
                                <option value="24.8">15:05 PM</option>
                                <option value="24.16">24:10 PM</option>
                                <option value="24.25">24:15 PM</option>
                                <option value="24.33">24:20 PM</option>
                                <option value="24.41">24:25 PM</option>
                                <option value="24.50">24:30 PM</option>
                                <option value="24.58">24:35 PM</option>
                                <option value="24.66">24:40 PM</option>
                                <option value="24.75">24:45 PM</option>
                                <option value="24.83">24:50 PM</option>
                                <option value="24.91">24:55 PM</option>
                            </select>
                            {data10.map((datas, index) =>{
                              return<div key={index}>
                                        <p>{datas.starthour}</p>
                                    </div>
                                })}
                        </div>
                        <div className="col-md-2">
                            <select name="endhour10" onChange={e =>  setEnd10(e.target.value)} className="form-control">
                                <option value="">Finish Time</option>
                                <option value="05.00">05:00 AM</option>
                                <option value="06.00">06:00 AM</option>
                                <option value="06.8">06:05 AM</option>
                                <option value="06.16">06:10 AM</option>
                                <option value="06.25">06:15 AM</option>
                                <option value="06.33">06:20 AM</option>
                                <option value="06.41">06:25 AM</option>
                                <option value="06.50">06:30 AM</option>
                                <option value="06.58">06:35 AM</option>
                                <option value="06.66">06:40 AM</option>
                                <option value="06.75">06:45 AM</option>
                                <option value="06.83">06:50 AM</option>
                                <option value="06.91">06:55 AM</option>
                                <option value="07.00">07:00 AM - Normal</option>
                                <option value="07.8">07:05 AM</option>
                                <option value="07.16">07:10 AM</option>
                                <option value="07.25">07:15 AM</option>
                                <option value="07.33">07:20 AM</option>
                                <option value="07.41">07:25 AM</option>
                                <option value="07.50">07:30 AM</option>
                                <option value="07.58">07:35 AM</option>
                                <option value="07.66">07:40 AM</option>
                                <option value="07.75">07:45 AM</option>
                                <option value="07.83">07:50 AM</option>
                                <option value="07.91">07:55 AM</option>
                                <option value="08.00">08:00 AM - o'clock</option>
                                <option value="08.8">08:05 AM</option>
                                <option value="08.16">08:10 AM</option>
                                <option value="08.25">08:15 AM</option>
                                <option value="08.33">08:20 AM</option>
                                <option value="08.41">08:25 AM</option>
                                <option value="08.50">08:30 AM</option>
                                <option value="08.58">08:35 AM</option>
                                <option value="08.66">08:40 AM</option>
                                <option value="08.75">08:45 AM</option>
                                <option value="08.83">08:50 AM</option>
                                <option value="08.91">08:55 AM</option>
                                <option value="09.00">09:00 AM - o'clock</option>
                                <option value="09.8">09:05 AM</option>
                                <option value="09.16">09:10 AM</option>
                                <option value="09.25">09:15 AM</option>
                                <option value="09.33">09:20 AM</option>
                                <option value="09.41">09:25 AM</option>
                                <option value="09.50">09:30 AM</option>
                                <option value="09.58">09:35 AM</option>
                                <option value="09.66">09:40 AM</option>
                                <option value="09.75">09:45 AM</option>
                                <option value="09.83">09:50 AM</option>
                                <option value="09.91">09:55 AM</option>
                                <option value="10.00">10:00 AM - o'clock</option>
                                <option value="10.8">10:05 AM</option>
                                <option value="10.16">10:10 AM</option>
                                <option value="10.25">10:15 AM</option>
                                <option value="10.33">10:20 AM</option>
                                <option value="10.41">10:25 AM</option>
                                <option value="10.50">10:30 AM</option>
                                <option value="10.58">10:35 AM</option>
                                <option value="10.66">10:40 AM</option>
                                <option value="10.75">10:45 AM</option>
                                <option value="10.83">10:50 AM</option>
                                <option value="10.91">10:55 AM</option>
                                <option value="11.00">11:00 AM - o'clock</option>
                                <option value="11.8">11:05 AM</option>
                                <option value="11.16">11:10 AM</option>
                                <option value="11.25">11:15 AM</option>
                                <option value="11.33">11:20 AM</option>
                                <option value="11.41">11:25 AM</option>
                                <option value="11.50">11:30 AM</option>
                                <option value="11.58">11:35 AM</option>
                                <option value="11.66">11:40 AM</option>
                                <option value="11.75">11:45 AM</option>
                                <option value="11.83">11:50 AM</option>
                                <option value="11.91">11:55 AM</option>
                                <option value="12.00">12:00 PM  - o'clock</option>
                                <option value="12.8">12:05 PM</option>
                                <option value="12.16">12:10 PM</option>
                                <option value="12.25">12:15 PM</option>
                                <option value="12.33">12:20 PM</option>
                                <option value="12.41">12:25 PM</option>
                                <option value="12.50">12:30 PM</option>
                                <option value="12.58">12:35 PM</option>
                                <option value="12.66">12:40 PM</option>
                                <option value="12.75">12:45 PM</option>
                                <option value="12.83">12:50 PM</option>
                                <option value="12.91">12:55 PM</option>
                                <option value="13.00">13:00 PM - o'clock</option>
                                <option value="13.8">13:05 PM</option>
                                <option value="13.16">13:10 PM</option>
                                <option value="13.25">13:15 PM</option>
                                <option value="13.33">13:20 PM</option>
                                <option value="13.41">13:25 PM</option>
                                <option value="13.50">13:30 PM</option>
                                <option value="13.58">13:35 PM</option>
                                <option value="13.66">13:40 PM</option>
                                <option value="13.75">13:45 PM</option>
                                <option value="13.83">13:50 PM</option>
                                <option value="13.91">13:55 PM</option>
                                <option value="14.00">14:00 PM - o'clock</option>
                                <option value="14.8">14:05 PM</option>
                                <option value="14.16">14:10 PM</option>
                                <option value="14.25">14:15 PM</option>
                                <option value="14.33">14:20 PM</option>
                                <option value="14.41">14:25 PM</option>
                                <option value="14.50">14:30 PM</option>
                                <option value="14.58">14:35 PM</option>
                                <option value="14.66">14:40 PM</option>
                                <option value="14.75">14:45 PM</option>
                                <option value="14.83">14:50 PM</option>
                                <option value="14.91">14:55 PM</option>
                                <option value="15.00">15:00 PM - o'clock</option>
                                <option value="15.8">15:05 PM</option>
                                <option value="15.16">15:10 PM</option>
                                <option value="15.25">15:15 PM</option>
                                <option value="15.33">15:20 PM</option>
                                <option value="15.41">15:25 PM</option>
                                <option value="15.50">15:30 PM</option>
                                <option value="15.58">15:35 PM</option>
                                <option value="15.66">15:40 PM</option>
                                <option value="15.75">15:45 PM</option>
                                <option value="15.83">15:50 PM</option>
                                <option value="15.91">15:55 PM</option>
                                <option value="16.00">16:00 PM - o'clock</option>
                                <option value="16.8">16:05 PM</option>
                                <option value="16.16">16:10 PM</option>
                                <option value="16.25">16:15 PM</option>
                                <option value="16.33">16:20 PM</option>
                                <option value="16.41">16:25 PM</option>
                                <option value="16.50">16:30 PM</option>
                                <option value="16.58">16:35 PM</option>
                                <option value="16.66">16:40 PM</option>
                                <option value="16.75">16:45 PM</option>
                                <option value="16.83">16:50 PM</option>
                                <option value="16.91">16:55 PM</option>
                                <option value="17.00">17:00 PM - o'clock</option>
                                <option value="17.8">17:05 PM</option>
                                <option value="17.16">17:10 PM</option>
                                <option value="17.25">17:15 PM</option>
                                <option value="17.33">17:20 PM</option>
                                <option value="17.41">17:25 PM</option>
                                <option value="17.50">17:30 PM</option>
                                <option value="17.58">17:35 PM</option>
                                <option value="17.66">17:40 PM</option>
                                <option value="17.75">17:45 PM</option>
                                <option value="17.83">17:50 PM</option>
                                <option value="17.91">17:55 PM</option>
                                <option value="18.00">18:00 PM - Normal</option>
                                <option value="18.8">18:05 PM</option>
                                <option value="18.16">18:10 PM</option>
                                <option value="18.25">18:15 PM</option>
                                <option value="18.33">18:20 PM</option>
                                <option value="18.41">18:25 PM</option>
                                <option value="18.50">18:30 PM</option>
                                <option value="18.58">18:35 PM</option>
                                <option value="18.66">18:40 PM</option>
                                <option value="18.75">18:45 PM</option>
                                <option value="18.83">18:50 PM</option>
                                <option value="18.91">18:55 PM</option>
                                <option value="19.00">19:00 PM - o'clock</option>
                                <option value="19.8">19:05 PM</option>
                                <option value="19.16">19:10 PM</option>
                                <option value="19.25">19:15 PM</option>
                                <option value="19.33">19:20 PM</option>
                                <option value="19.41">19:25 PM</option>
                                <option value="19.50">19:30 PM</option>
                                <option value="19.58">19:35 PM</option>
                                <option value="19.66">19:40 PM</option>
                                <option value="19.75">19:45 PM</option>
                                <option value="19.83">19:50 PM</option>
                                <option value="19.91">19:55 PM</option>
                                <option value="20.00">20:00 PM - o'clock</option>
                                <option value="20.8">20:05 PM</option>
                                <option value="20.16">20:10 PM</option>
                                <option value="20.25">20:15 PM</option>
                                <option value="20.33">20:20 PM</option>
                                <option value="20.41">20:25 PM</option>
                                <option value="20.50">20:30 PM</option>
                                <option value="20.58">20:35 PM</option>
                                <option value="20.66">20:40 PM</option>
                                <option value="20.75">20:45 PM</option>
                                <option value="20.83">20:50 PM</option>
                                <option value="20.91">20:55 PM</option>
                                <option value="21.00">21:00 PM - o'clock</option>
                                <option value="21.8">21:05 PM</option>
                                <option value="21.16">21:10 PM</option>
                                <option value="21.25">21:15 PM</option>
                                <option value="21.33">21:20 PM</option>
                                <option value="21.41">21:25 PM</option>
                                <option value="21.50">21:30 PM</option>
                                <option value="21.58">21:35 PM</option>
                                <option value="21.66">21:40 PM</option>
                                <option value="21.75">21:45 PM</option>
                                <option value="21.83">21:50 PM</option>
                                <option value="21.91">21:55 PM</option>
                                <option value="22.00">22:00 PM - o'clock</option>
                                <option value="22.8">22:05 PM</option>
                                <option value="22.16">22:10 PM</option>
                                <option value="22.25">22:15 PM</option>
                                <option value="22.33">22:20 PM</option>
                                <option value="22.41">22:25 PM</option>
                                <option value="22.50">22:30 PM</option>
                                <option value="22.58">22:35 PM</option>
                                <option value="22.66">22:40 PM</option>
                                <option value="22.75">22:45 PM</option>
                                <option value="22.83">22:50 PM</option>
                                <option value="22.91">22:55 PM</option>
                                <option value="23.00">23:00 PM - o'clock</option>
                                <option value="23.8">23:05 PM</option>
                                <option value="23.16">23:10 PM</option>
                                <option value="23.25">23:15 PM</option>
                                <option value="23.33">23:20 PM</option>
                                <option value="23.41">23:25 PM</option>
                                <option value="23.50">23:30 PM</option>
                                <option value="23.58">23:35 PM</option>
                                <option value="23.66">23:40 PM</option>
                                <option value="23.75">23:45 PM</option>
                                <option value="23.83">23:50 PM</option>
                                <option value="23.91">23:55 PM</option>
                                <option value="24.00">24:00 PM - o'clock</option>
                                <option value="24.8">15:05 PM</option>
                                <option value="24.16">24:10 PM</option>
                                <option value="24.25">24:15 PM</option>
                                <option value="24.33">24:20 PM</option>
                                <option value="24.41">24:25 PM</option>
                                <option value="24.50">24:30 PM</option>
                                <option value="24.58">24:35 PM</option>
                                <option value="24.66">24:40 PM</option>
                                <option value="24.75">24:45 PM</option>
                                <option value="24.83">24:50 PM</option>
                                <option value="24.91">24:55 PM</option>
                            </select>
                            {data10.map((datas, index) =>{
                              return<div key={index}>
                                        <p>{datas.endhour}</p>
                                    </div>
                                })}
                        </div>
                        <div className="col-md-2">
                            <select name="type10" onChange={e =>  settype10(e.target.value)} className="form-control" required>
                                <option value="">Select Type</option>
                                <option value="Normal">Normal</option>
                                <option value="SJUK">SJUK</option>
                                <option value="Holiday">Holiday</option>
                            </select>
                            {data10.map((datas, index) =>{
                              return<div key={index}>
                                        <p>{datas.type}</p>
                                    </div>
                                })}
                          </div>
                          <div className="col-md-2"> 
                              <button type='submit' className="btn btn-primary">Submit</button>
                        </div>
                      </form>
                  </div>
                  <div className="d-flex flex-column p-2">
                     <form action="" className="row"  onSubmit={handleSubmit11}>
                        <div className="col-md-1"> 11 </div>
                        <div className="col-md-1"> Friday</div>
                        <div className="col-md-1" > 
                            {data11.map((types, index) =>{
                              return<tr key={index}>
                                   <p style={types.type === "Normal" ? {backgroundColor:'rgba(7, 190, 7, 0.648)', color:'#000', padding:'5px 10px'} : 
                                     (types.type === "Holiday" ? {backgroundColor:'rgba(255, 0, 0, 0.438)', color:'#000', padding:'5px 10px'} : 
                                      {backgroundColor:'#008cff5a', color:'#000', padding:'5px 10px'}) }> {types.type}</p>
                                    </tr>
                                })}
                        </div>
                        <div className="col-md-2">
                            <select name="starthour11" onChange={e =>  setStart11(e.target.value)} className="form-control">
                                <option value="">Start Time</option>
                                <option value="05.00">05:00 AM</option>
                                <option value="06.00">06:00 AM</option>
                                <option value="06.8">06:05 AM</option>
                                <option value="06.16">06:10 AM</option>
                                <option value="06.25">06:15 AM</option>
                                <option value="06.33">06:20 AM</option>
                                <option value="06.41">06:25 AM</option>
                                <option value="06.50">06:30 AM</option>
                                <option value="06.58">06:35 AM</option>
                                <option value="06.66">06:40 AM</option>
                                <option value="06.75">06:45 AM</option>
                                <option value="06.83">06:50 AM</option>
                                <option value="06.91">06:55 AM</option>
                                <option value="07.00">07:00 AM - Normal</option>
                                <option value="07.8">07:05 AM</option>
                                <option value="07.16">07:10 AM</option>
                                <option value="07.25">07:15 AM</option>
                                <option value="07.33">07:20 AM</option>
                                <option value="07.41">07:25 AM</option>
                                <option value="07.50">07:30 AM</option>
                                <option value="07.58">07:35 AM</option>
                                <option value="07.66">07:40 AM</option>
                                <option value="07.75">07:45 AM</option>
                                <option value="07.83">07:50 AM</option>
                                <option value="07.91">07:55 AM</option>
                                <option value="08.00">08:00 AM - o'clock</option>
                                <option value="08.8">08:05 AM</option>
                                <option value="08.16">08:10 AM</option>
                                <option value="08.25">08:15 AM</option>
                                <option value="08.33">08:20 AM</option>
                                <option value="08.41">08:25 AM</option>
                                <option value="08.50">08:30 AM</option>
                                <option value="08.58">08:35 AM</option>
                                <option value="08.66">08:40 AM</option>
                                <option value="08.75">08:45 AM</option>
                                <option value="08.83">08:50 AM</option>
                                <option value="08.91">08:55 AM</option>
                                <option value="09.00">09:00 AM - o'clock</option>
                                <option value="09.8">09:05 AM</option>
                                <option value="09.16">09:10 AM</option>
                                <option value="09.25">09:15 AM</option>
                                <option value="09.33">09:20 AM</option>
                                <option value="09.41">09:25 AM</option>
                                <option value="09.50">09:30 AM</option>
                                <option value="09.58">09:35 AM</option>
                                <option value="09.66">09:40 AM</option>
                                <option value="09.75">09:45 AM</option>
                                <option value="09.83">09:50 AM</option>
                                <option value="09.91">09:55 AM</option>
                                <option value="10.00">10:00 AM - o'clock</option>
                                <option value="10.8">10:05 AM</option>
                                <option value="10.16">10:10 AM</option>
                                <option value="10.25">10:15 AM</option>
                                <option value="10.33">10:20 AM</option>
                                <option value="10.41">10:25 AM</option>
                                <option value="10.50">10:30 AM</option>
                                <option value="10.58">10:35 AM</option>
                                <option value="10.66">10:40 AM</option>
                                <option value="10.75">10:45 AM</option>
                                <option value="10.83">10:50 AM</option>
                                <option value="10.91">10:55 AM</option>
                                <option value="11.00">11:00 AM - o'clock</option>
                                <option value="11.8">11:05 AM</option>
                                <option value="11.16">11:10 AM</option>
                                <option value="11.25">11:15 AM</option>
                                <option value="11.33">11:20 AM</option>
                                <option value="11.41">11:25 AM</option>
                                <option value="11.50">11:30 AM</option>
                                <option value="11.58">11:35 AM</option>
                                <option value="11.66">11:40 AM</option>
                                <option value="11.75">11:45 AM</option>
                                <option value="11.83">11:50 AM</option>
                                <option value="11.91">11:55 AM</option>
                                <option value="12.00">12:00 PM  - o'clock</option>
                                <option value="12.8">12:05 PM</option>
                                <option value="12.16">12:10 PM</option>
                                <option value="12.25">12:15 PM</option>
                                <option value="12.33">12:20 PM</option>
                                <option value="12.41">12:25 PM</option>
                                <option value="12.50">12:30 PM</option>
                                <option value="12.58">12:35 PM</option>
                                <option value="12.66">12:40 PM</option>
                                <option value="12.75">12:45 PM</option>
                                <option value="12.83">12:50 PM</option>
                                <option value="12.91">12:55 PM</option>
                                <option value="13.00">13:00 PM - o'clock</option>
                                <option value="13.8">13:05 PM</option>
                                <option value="13.16">13:10 PM</option>
                                <option value="13.25">13:15 PM</option>
                                <option value="13.33">13:20 PM</option>
                                <option value="13.41">13:25 PM</option>
                                <option value="13.50">13:30 PM</option>
                                <option value="13.58">13:35 PM</option>
                                <option value="13.66">13:40 PM</option>
                                <option value="13.75">13:45 PM</option>
                                <option value="13.83">13:50 PM</option>
                                <option value="13.91">13:55 PM</option>
                                <option value="14.00">14:00 PM - o'clock</option>
                                <option value="14.8">14:05 PM</option>
                                <option value="14.16">14:10 PM</option>
                                <option value="14.25">14:15 PM</option>
                                <option value="14.33">14:20 PM</option>
                                <option value="14.41">14:25 PM</option>
                                <option value="14.50">14:30 PM</option>
                                <option value="14.58">14:35 PM</option>
                                <option value="14.66">14:40 PM</option>
                                <option value="14.75">14:45 PM</option>
                                <option value="14.83">14:50 PM</option>
                                <option value="14.91">14:55 PM</option>
                                <option value="15.00">15:00 PM - o'clock</option>
                                <option value="15.8">15:05 PM</option>
                                <option value="15.16">15:10 PM</option>
                                <option value="15.25">15:15 PM</option>
                                <option value="15.33">15:20 PM</option>
                                <option value="15.41">15:25 PM</option>
                                <option value="15.50">15:30 PM</option>
                                <option value="15.58">15:35 PM</option>
                                <option value="15.66">15:40 PM</option>
                                <option value="15.75">15:45 PM</option>
                                <option value="15.83">15:50 PM</option>
                                <option value="15.91">15:55 PM</option>
                                <option value="16.00">16:00 PM - o'clock</option>
                                <option value="16.8">16:05 PM</option>
                                <option value="16.16">16:10 PM</option>
                                <option value="16.25">16:15 PM</option>
                                <option value="16.33">16:20 PM</option>
                                <option value="16.41">16:25 PM</option>
                                <option value="16.50">16:30 PM</option>
                                <option value="16.58">16:35 PM</option>
                                <option value="16.66">16:40 PM</option>
                                <option value="16.75">16:45 PM</option>
                                <option value="16.83">16:50 PM</option>
                                <option value="16.91">16:55 PM</option>
                                <option value="17.00">17:00 PM - o'clock</option>
                                <option value="17.8">17:05 PM</option>
                                <option value="17.16">17:10 PM</option>
                                <option value="17.25">17:15 PM</option>
                                <option value="17.33">17:20 PM</option>
                                <option value="17.41">17:25 PM</option>
                                <option value="17.50">17:30 PM</option>
                                <option value="17.58">17:35 PM</option>
                                <option value="17.66">17:40 PM</option>
                                <option value="17.75">17:45 PM</option>
                                <option value="17.83">17:50 PM</option>
                                <option value="17.91">17:55 PM</option>
                                <option value="18.00">18:00 PM - Normal</option>
                                <option value="18.8">18:05 PM</option>
                                <option value="18.16">18:10 PM</option>
                                <option value="18.25">18:15 PM</option>
                                <option value="18.33">18:20 PM</option>
                                <option value="18.41">18:25 PM</option>
                                <option value="18.50">18:30 PM</option>
                                <option value="18.58">18:35 PM</option>
                                <option value="18.66">18:40 PM</option>
                                <option value="18.75">18:45 PM</option>
                                <option value="18.83">18:50 PM</option>
                                <option value="18.91">18:55 PM</option>
                                <option value="19.00">19:00 PM - o'clock</option>
                                <option value="19.8">19:05 PM</option>
                                <option value="19.16">19:10 PM</option>
                                <option value="19.25">19:15 PM</option>
                                <option value="19.33">19:20 PM</option>
                                <option value="19.41">19:25 PM</option>
                                <option value="19.50">19:30 PM</option>
                                <option value="19.58">19:35 PM</option>
                                <option value="19.66">19:40 PM</option>
                                <option value="19.75">19:45 PM</option>
                                <option value="19.83">19:50 PM</option>
                                <option value="19.91">19:55 PM</option>
                                <option value="20.00">20:00 PM - o'clock</option>
                                <option value="20.8">20:05 PM</option>
                                <option value="20.16">20:10 PM</option>
                                <option value="20.25">20:15 PM</option>
                                <option value="20.33">20:20 PM</option>
                                <option value="20.41">20:25 PM</option>
                                <option value="20.50">20:30 PM</option>
                                <option value="20.58">20:35 PM</option>
                                <option value="20.66">20:40 PM</option>
                                <option value="20.75">20:45 PM</option>
                                <option value="20.83">20:50 PM</option>
                                <option value="20.91">20:55 PM</option>
                                <option value="21.00">21:00 PM - o'clock</option>
                                <option value="21.8">21:05 PM</option>
                                <option value="21.16">21:10 PM</option>
                                <option value="21.25">21:15 PM</option>
                                <option value="21.33">21:20 PM</option>
                                <option value="21.41">21:25 PM</option>
                                <option value="21.50">21:30 PM</option>
                                <option value="21.58">21:35 PM</option>
                                <option value="21.66">21:40 PM</option>
                                <option value="21.75">21:45 PM</option>
                                <option value="21.83">21:50 PM</option>
                                <option value="21.91">21:55 PM</option>
                                <option value="22.00">22:00 PM - o'clock</option>
                                <option value="22.8">22:05 PM</option>
                                <option value="22.16">22:10 PM</option>
                                <option value="22.25">22:15 PM</option>
                                <option value="22.33">22:20 PM</option>
                                <option value="22.41">22:25 PM</option>
                                <option value="22.50">22:30 PM</option>
                                <option value="22.58">22:35 PM</option>
                                <option value="22.66">22:40 PM</option>
                                <option value="22.75">22:45 PM</option>
                                <option value="22.83">22:50 PM</option>
                                <option value="22.91">22:55 PM</option>
                                <option value="23.00">23:00 PM - o'clock</option>
                                <option value="23.8">23:05 PM</option>
                                <option value="23.16">23:10 PM</option>
                                <option value="23.25">23:15 PM</option>
                                <option value="23.33">23:20 PM</option>
                                <option value="23.41">23:25 PM</option>
                                <option value="23.50">23:30 PM</option>
                                <option value="23.58">23:35 PM</option>
                                <option value="23.66">23:40 PM</option>
                                <option value="23.75">23:45 PM</option>
                                <option value="23.83">23:50 PM</option>
                                <option value="23.91">23:55 PM</option>
                                <option value="24.00">24:00 PM - o'clock</option>
                                <option value="24.8">15:05 PM</option>
                                <option value="24.16">24:10 PM</option>
                                <option value="24.25">24:15 PM</option>
                                <option value="24.33">24:20 PM</option>
                                <option value="24.41">24:25 PM</option>
                                <option value="24.50">24:30 PM</option>
                                <option value="24.58">24:35 PM</option>
                                <option value="24.66">24:40 PM</option>
                                <option value="24.75">24:45 PM</option>
                                <option value="24.83">24:50 PM</option>
                                <option value="24.91">24:55 PM</option>
                            </select>
                            {data11.map((datas, index) =>{
                              return<div key={index}>
                                        <p>{datas.starthour}</p>
                                    </div>
                                })}
                        </div>
                        <div className="col-md-2">
                            <select name="endhour11" onChange={e =>  setEnd11(e.target.value)} className="form-control">
                                <option value="">Finish Time</option>
                                <option value="05.00">05:00 AM</option>
                                <option value="06.00">06:00 AM</option>
                                <option value="06.8">06:05 AM</option>
                                <option value="06.16">06:10 AM</option>
                                <option value="06.25">06:15 AM</option>
                                <option value="06.33">06:20 AM</option>
                                <option value="06.41">06:25 AM</option>
                                <option value="06.50">06:30 AM</option>
                                <option value="06.58">06:35 AM</option>
                                <option value="06.66">06:40 AM</option>
                                <option value="06.75">06:45 AM</option>
                                <option value="06.83">06:50 AM</option>
                                <option value="06.91">06:55 AM</option>
                                <option value="07.00">07:00 AM - Normal</option>
                                <option value="07.8">07:05 AM</option>
                                <option value="07.16">07:10 AM</option>
                                <option value="07.25">07:15 AM</option>
                                <option value="07.33">07:20 AM</option>
                                <option value="07.41">07:25 AM</option>
                                <option value="07.50">07:30 AM</option>
                                <option value="07.58">07:35 AM</option>
                                <option value="07.66">07:40 AM</option>
                                <option value="07.75">07:45 AM</option>
                                <option value="07.83">07:50 AM</option>
                                <option value="07.91">07:55 AM</option>
                                <option value="08.00">08:00 AM - o'clock</option>
                                <option value="08.8">08:05 AM</option>
                                <option value="08.16">08:10 AM</option>
                                <option value="08.25">08:15 AM</option>
                                <option value="08.33">08:20 AM</option>
                                <option value="08.41">08:25 AM</option>
                                <option value="08.50">08:30 AM</option>
                                <option value="08.58">08:35 AM</option>
                                <option value="08.66">08:40 AM</option>
                                <option value="08.75">08:45 AM</option>
                                <option value="08.83">08:50 AM</option>
                                <option value="08.91">08:55 AM</option>
                                <option value="09.00">09:00 AM - o'clock</option>
                                <option value="09.8">09:05 AM</option>
                                <option value="09.16">09:10 AM</option>
                                <option value="09.25">09:15 AM</option>
                                <option value="09.33">09:20 AM</option>
                                <option value="09.41">09:25 AM</option>
                                <option value="09.50">09:30 AM</option>
                                <option value="09.58">09:35 AM</option>
                                <option value="09.66">09:40 AM</option>
                                <option value="09.75">09:45 AM</option>
                                <option value="09.83">09:50 AM</option>
                                <option value="09.91">09:55 AM</option>
                                <option value="10.00">10:00 AM - o'clock</option>
                                <option value="10.8">10:05 AM</option>
                                <option value="10.16">10:10 AM</option>
                                <option value="10.25">10:15 AM</option>
                                <option value="10.33">10:20 AM</option>
                                <option value="10.41">10:25 AM</option>
                                <option value="10.50">10:30 AM</option>
                                <option value="10.58">10:35 AM</option>
                                <option value="10.66">10:40 AM</option>
                                <option value="10.75">10:45 AM</option>
                                <option value="10.83">10:50 AM</option>
                                <option value="10.91">10:55 AM</option>
                                <option value="11.00">11:00 AM - o'clock</option>
                                <option value="11.8">11:05 AM</option>
                                <option value="11.16">11:10 AM</option>
                                <option value="11.25">11:15 AM</option>
                                <option value="11.33">11:20 AM</option>
                                <option value="11.41">11:25 AM</option>
                                <option value="11.50">11:30 AM</option>
                                <option value="11.58">11:35 AM</option>
                                <option value="11.66">11:40 AM</option>
                                <option value="11.75">11:45 AM</option>
                                <option value="11.83">11:50 AM</option>
                                <option value="11.91">11:55 AM</option>
                                <option value="12.00">12:00 PM  - o'clock</option>
                                <option value="12.8">12:05 PM</option>
                                <option value="12.16">12:10 PM</option>
                                <option value="12.25">12:15 PM</option>
                                <option value="12.33">12:20 PM</option>
                                <option value="12.41">12:25 PM</option>
                                <option value="12.50">12:30 PM</option>
                                <option value="12.58">12:35 PM</option>
                                <option value="12.66">12:40 PM</option>
                                <option value="12.75">12:45 PM</option>
                                <option value="12.83">12:50 PM</option>
                                <option value="12.91">12:55 PM</option>
                                <option value="13.00">13:00 PM - o'clock</option>
                                <option value="13.8">13:05 PM</option>
                                <option value="13.16">13:10 PM</option>
                                <option value="13.25">13:15 PM</option>
                                <option value="13.33">13:20 PM</option>
                                <option value="13.41">13:25 PM</option>
                                <option value="13.50">13:30 PM</option>
                                <option value="13.58">13:35 PM</option>
                                <option value="13.66">13:40 PM</option>
                                <option value="13.75">13:45 PM</option>
                                <option value="13.83">13:50 PM</option>
                                <option value="13.91">13:55 PM</option>
                                <option value="14.00">14:00 PM - o'clock</option>
                                <option value="14.8">14:05 PM</option>
                                <option value="14.16">14:10 PM</option>
                                <option value="14.25">14:15 PM</option>
                                <option value="14.33">14:20 PM</option>
                                <option value="14.41">14:25 PM</option>
                                <option value="14.50">14:30 PM</option>
                                <option value="14.58">14:35 PM</option>
                                <option value="14.66">14:40 PM</option>
                                <option value="14.75">14:45 PM</option>
                                <option value="14.83">14:50 PM</option>
                                <option value="14.91">14:55 PM</option>
                                <option value="15.00">15:00 PM - o'clock</option>
                                <option value="15.8">15:05 PM</option>
                                <option value="15.16">15:10 PM</option>
                                <option value="15.25">15:15 PM</option>
                                <option value="15.33">15:20 PM</option>
                                <option value="15.41">15:25 PM</option>
                                <option value="15.50">15:30 PM</option>
                                <option value="15.58">15:35 PM</option>
                                <option value="15.66">15:40 PM</option>
                                <option value="15.75">15:45 PM</option>
                                <option value="15.83">15:50 PM</option>
                                <option value="15.91">15:55 PM</option>
                                <option value="16.00">16:00 PM - o'clock</option>
                                <option value="16.8">16:05 PM</option>
                                <option value="16.16">16:10 PM</option>
                                <option value="16.25">16:15 PM</option>
                                <option value="16.33">16:20 PM</option>
                                <option value="16.41">16:25 PM</option>
                                <option value="16.50">16:30 PM</option>
                                <option value="16.58">16:35 PM</option>
                                <option value="16.66">16:40 PM</option>
                                <option value="16.75">16:45 PM</option>
                                <option value="16.83">16:50 PM</option>
                                <option value="16.91">16:55 PM</option>
                                <option value="17.00">17:00 PM - o'clock</option>
                                <option value="17.8">17:05 PM</option>
                                <option value="17.16">17:10 PM</option>
                                <option value="17.25">17:15 PM</option>
                                <option value="17.33">17:20 PM</option>
                                <option value="17.41">17:25 PM</option>
                                <option value="17.50">17:30 PM</option>
                                <option value="17.58">17:35 PM</option>
                                <option value="17.66">17:40 PM</option>
                                <option value="17.75">17:45 PM</option>
                                <option value="17.83">17:50 PM</option>
                                <option value="17.91">17:55 PM</option>
                                <option value="18.00">18:00 PM - Normal</option>
                                <option value="18.8">18:05 PM</option>
                                <option value="18.16">18:10 PM</option>
                                <option value="18.25">18:15 PM</option>
                                <option value="18.33">18:20 PM</option>
                                <option value="18.41">18:25 PM</option>
                                <option value="18.50">18:30 PM</option>
                                <option value="18.58">18:35 PM</option>
                                <option value="18.66">18:40 PM</option>
                                <option value="18.75">18:45 PM</option>
                                <option value="18.83">18:50 PM</option>
                                <option value="18.91">18:55 PM</option>
                                <option value="19.00">19:00 PM - o'clock</option>
                                <option value="19.8">19:05 PM</option>
                                <option value="19.16">19:10 PM</option>
                                <option value="19.25">19:15 PM</option>
                                <option value="19.33">19:20 PM</option>
                                <option value="19.41">19:25 PM</option>
                                <option value="19.50">19:30 PM</option>
                                <option value="19.58">19:35 PM</option>
                                <option value="19.66">19:40 PM</option>
                                <option value="19.75">19:45 PM</option>
                                <option value="19.83">19:50 PM</option>
                                <option value="19.91">19:55 PM</option>
                                <option value="20.00">20:00 PM - o'clock</option>
                                <option value="20.8">20:05 PM</option>
                                <option value="20.16">20:10 PM</option>
                                <option value="20.25">20:15 PM</option>
                                <option value="20.33">20:20 PM</option>
                                <option value="20.41">20:25 PM</option>
                                <option value="20.50">20:30 PM</option>
                                <option value="20.58">20:35 PM</option>
                                <option value="20.66">20:40 PM</option>
                                <option value="20.75">20:45 PM</option>
                                <option value="20.83">20:50 PM</option>
                                <option value="20.91">20:55 PM</option>
                                <option value="21.00">21:00 PM - o'clock</option>
                                <option value="21.8">21:05 PM</option>
                                <option value="21.16">21:10 PM</option>
                                <option value="21.25">21:15 PM</option>
                                <option value="21.33">21:20 PM</option>
                                <option value="21.41">21:25 PM</option>
                                <option value="21.50">21:30 PM</option>
                                <option value="21.58">21:35 PM</option>
                                <option value="21.66">21:40 PM</option>
                                <option value="21.75">21:45 PM</option>
                                <option value="21.83">21:50 PM</option>
                                <option value="21.91">21:55 PM</option>
                                <option value="22.00">22:00 PM - o'clock</option>
                                <option value="22.8">22:05 PM</option>
                                <option value="22.16">22:10 PM</option>
                                <option value="22.25">22:15 PM</option>
                                <option value="22.33">22:20 PM</option>
                                <option value="22.41">22:25 PM</option>
                                <option value="22.50">22:30 PM</option>
                                <option value="22.58">22:35 PM</option>
                                <option value="22.66">22:40 PM</option>
                                <option value="22.75">22:45 PM</option>
                                <option value="22.83">22:50 PM</option>
                                <option value="22.91">22:55 PM</option>
                                <option value="23.00">23:00 PM - o'clock</option>
                                <option value="23.8">23:05 PM</option>
                                <option value="23.16">23:10 PM</option>
                                <option value="23.25">23:15 PM</option>
                                <option value="23.33">23:20 PM</option>
                                <option value="23.41">23:25 PM</option>
                                <option value="23.50">23:30 PM</option>
                                <option value="23.58">23:35 PM</option>
                                <option value="23.66">23:40 PM</option>
                                <option value="23.75">23:45 PM</option>
                                <option value="23.83">23:50 PM</option>
                                <option value="23.91">23:55 PM</option>
                                <option value="24.00">24:00 PM - o'clock</option>
                                <option value="24.8">15:05 PM</option>
                                <option value="24.16">24:10 PM</option>
                                <option value="24.25">24:15 PM</option>
                                <option value="24.33">24:20 PM</option>
                                <option value="24.41">24:25 PM</option>
                                <option value="24.50">24:30 PM</option>
                                <option value="24.58">24:35 PM</option>
                                <option value="24.66">24:40 PM</option>
                                <option value="24.75">24:45 PM</option>
                                <option value="24.83">24:50 PM</option>
                                <option value="24.91">24:55 PM</option>
                            </select>
                            {data11.map((datas, index) =>{
                              return<div key={index}>
                                        <p>{datas.endhour}</p>
                                    </div>
                                })}
                        </div>
                        <div className="col-md-2">
                            <select name="type11" onChange={e =>  settype11(e.target.value)} className="form-control" required>
                                <option value="">Select Type</option>
                                <option value="Normal">Normal</option>
                                <option value="SJUK">SJUK</option>
                                <option value="Holiday">Holiday</option>
                            </select>
                            {data11.map((datas, index) =>{
                              return<div key={index}>
                                        <p>{datas.type}</p>
                                    </div>
                                })}
                          </div>
                          <div className="col-md-2"> 
                              <button type='submit' className="btn btn-primary">Submit</button>
                        </div>
                      </form>
                  </div>
                  <div className="d-flex flex-column p-2">
                     <form action="" className="row"  onSubmit={handleSubmit12}>
                        <div className="col-md-1"> 12 </div>
                        <div className="col-md-1"> Saturday  </div>
                        <div className="col-md-1" > 
                            {data12.map((types, index) =>{
                              return<tr key={index}>
                                   <p style={types.type === "Normal" ? {backgroundColor:'rgba(7, 190, 7, 0.648)', color:'#000', padding:'5px 10px'} : 
                                     (types.type === "Holiday" ? {backgroundColor:'rgba(255, 0, 0, 0.438)', color:'#000', padding:'5px 10px'} : 
                                      {backgroundColor:'#008cff5a', color:'#000', padding:'5px 10px'}) }> {types.type}</p>
                                    </tr>
                                })}
                        </div>
                        <div className="col-md-2">
                            <select name="starthour12" onChange={e =>  setStart12(e.target.value)} className="form-control">
                                <option value="">Start Time</option>
                                <option value="05.00">05:00 AM</option>
                                <option value="06.00">06:00 AM</option>
                                <option value="06.8">06:05 AM</option>
                                <option value="06.16">06:10 AM</option>
                                <option value="06.25">06:15 AM</option>
                                <option value="06.33">06:20 AM</option>
                                <option value="06.41">06:25 AM</option>
                                <option value="06.50">06:30 AM</option>
                                <option value="06.58">06:35 AM</option>
                                <option value="06.66">06:40 AM</option>
                                <option value="06.75">06:45 AM</option>
                                <option value="06.83">06:50 AM</option>
                                <option value="06.91">06:55 AM</option>
                                <option value="07.00">07:00 AM - Normal</option>
                                <option value="07.8">07:05 AM</option>
                                <option value="07.16">07:10 AM</option>
                                <option value="07.25">07:15 AM</option>
                                <option value="07.33">07:20 AM</option>
                                <option value="07.41">07:25 AM</option>
                                <option value="07.50">07:30 AM</option>
                                <option value="07.58">07:35 AM</option>
                                <option value="07.66">07:40 AM</option>
                                <option value="07.75">07:45 AM</option>
                                <option value="07.83">07:50 AM</option>
                                <option value="07.91">07:55 AM</option>
                                <option value="08.00">08:00 AM - o'clock</option>
                                <option value="08.8">08:05 AM</option>
                                <option value="08.16">08:10 AM</option>
                                <option value="08.25">08:15 AM</option>
                                <option value="08.33">08:20 AM</option>
                                <option value="08.41">08:25 AM</option>
                                <option value="08.50">08:30 AM</option>
                                <option value="08.58">08:35 AM</option>
                                <option value="08.66">08:40 AM</option>
                                <option value="08.75">08:45 AM</option>
                                <option value="08.83">08:50 AM</option>
                                <option value="08.91">08:55 AM</option>
                                <option value="09.00">09:00 AM - o'clock</option>
                                <option value="09.8">09:05 AM</option>
                                <option value="09.16">09:10 AM</option>
                                <option value="09.25">09:15 AM</option>
                                <option value="09.33">09:20 AM</option>
                                <option value="09.41">09:25 AM</option>
                                <option value="09.50">09:30 AM</option>
                                <option value="09.58">09:35 AM</option>
                                <option value="09.66">09:40 AM</option>
                                <option value="09.75">09:45 AM</option>
                                <option value="09.83">09:50 AM</option>
                                <option value="09.91">09:55 AM</option>
                                <option value="10.00">10:00 AM - o'clock</option>
                                <option value="10.8">10:05 AM</option>
                                <option value="10.16">10:10 AM</option>
                                <option value="10.25">10:15 AM</option>
                                <option value="10.33">10:20 AM</option>
                                <option value="10.41">10:25 AM</option>
                                <option value="10.50">10:30 AM</option>
                                <option value="10.58">10:35 AM</option>
                                <option value="10.66">10:40 AM</option>
                                <option value="10.75">10:45 AM</option>
                                <option value="10.83">10:50 AM</option>
                                <option value="10.91">10:55 AM</option>
                                <option value="11.00">11:00 AM - o'clock</option>
                                <option value="11.8">11:05 AM</option>
                                <option value="11.16">11:10 AM</option>
                                <option value="11.25">11:15 AM</option>
                                <option value="11.33">11:20 AM</option>
                                <option value="11.41">11:25 AM</option>
                                <option value="11.50">11:30 AM</option>
                                <option value="11.58">11:35 AM</option>
                                <option value="11.66">11:40 AM</option>
                                <option value="11.75">11:45 AM</option>
                                <option value="11.83">11:50 AM</option>
                                <option value="11.91">11:55 AM</option>
                                <option value="12.00">12:00 PM  - o'clock</option>
                                <option value="12.8">12:05 PM</option>
                                <option value="12.16">12:10 PM</option>
                                <option value="12.25">12:15 PM</option>
                                <option value="12.33">12:20 PM</option>
                                <option value="12.41">12:25 PM</option>
                                <option value="12.50">12:30 PM</option>
                                <option value="12.58">12:35 PM</option>
                                <option value="12.66">12:40 PM</option>
                                <option value="12.75">12:45 PM</option>
                                <option value="12.83">12:50 PM</option>
                                <option value="12.91">12:55 PM</option>
                                <option value="13.00">13:00 PM - o'clock</option>
                                <option value="13.8">13:05 PM</option>
                                <option value="13.16">13:10 PM</option>
                                <option value="13.25">13:15 PM</option>
                                <option value="13.33">13:20 PM</option>
                                <option value="13.41">13:25 PM</option>
                                <option value="13.50">13:30 PM</option>
                                <option value="13.58">13:35 PM</option>
                                <option value="13.66">13:40 PM</option>
                                <option value="13.75">13:45 PM</option>
                                <option value="13.83">13:50 PM</option>
                                <option value="13.91">13:55 PM</option>
                                <option value="14.00">14:00 PM - o'clock</option>
                                <option value="14.8">14:05 PM</option>
                                <option value="14.16">14:10 PM</option>
                                <option value="14.25">14:15 PM</option>
                                <option value="14.33">14:20 PM</option>
                                <option value="14.41">14:25 PM</option>
                                <option value="14.50">14:30 PM</option>
                                <option value="14.58">14:35 PM</option>
                                <option value="14.66">14:40 PM</option>
                                <option value="14.75">14:45 PM</option>
                                <option value="14.83">14:50 PM</option>
                                <option value="14.91">14:55 PM</option>
                                <option value="15.00">15:00 PM - o'clock</option>
                                <option value="15.8">15:05 PM</option>
                                <option value="15.16">15:10 PM</option>
                                <option value="15.25">15:15 PM</option>
                                <option value="15.33">15:20 PM</option>
                                <option value="15.41">15:25 PM</option>
                                <option value="15.50">15:30 PM</option>
                                <option value="15.58">15:35 PM</option>
                                <option value="15.66">15:40 PM</option>
                                <option value="15.75">15:45 PM</option>
                                <option value="15.83">15:50 PM</option>
                                <option value="15.91">15:55 PM</option>
                                <option value="16.00">16:00 PM - o'clock</option>
                                <option value="16.8">16:05 PM</option>
                                <option value="16.16">16:10 PM</option>
                                <option value="16.25">16:15 PM</option>
                                <option value="16.33">16:20 PM</option>
                                <option value="16.41">16:25 PM</option>
                                <option value="16.50">16:30 PM</option>
                                <option value="16.58">16:35 PM</option>
                                <option value="16.66">16:40 PM</option>
                                <option value="16.75">16:45 PM</option>
                                <option value="16.83">16:50 PM</option>
                                <option value="16.91">16:55 PM</option>
                                <option value="17.00">17:00 PM - o'clock</option>
                                <option value="17.8">17:05 PM</option>
                                <option value="17.16">17:10 PM</option>
                                <option value="17.25">17:15 PM</option>
                                <option value="17.33">17:20 PM</option>
                                <option value="17.41">17:25 PM</option>
                                <option value="17.50">17:30 PM</option>
                                <option value="17.58">17:35 PM</option>
                                <option value="17.66">17:40 PM</option>
                                <option value="17.75">17:45 PM</option>
                                <option value="17.83">17:50 PM</option>
                                <option value="17.91">17:55 PM</option>
                                <option value="18.00">18:00 PM - Normal</option>
                                <option value="18.8">18:05 PM</option>
                                <option value="18.16">18:10 PM</option>
                                <option value="18.25">18:15 PM</option>
                                <option value="18.33">18:20 PM</option>
                                <option value="18.41">18:25 PM</option>
                                <option value="18.50">18:30 PM</option>
                                <option value="18.58">18:35 PM</option>
                                <option value="18.66">18:40 PM</option>
                                <option value="18.75">18:45 PM</option>
                                <option value="18.83">18:50 PM</option>
                                <option value="18.91">18:55 PM</option>
                                <option value="19.00">19:00 PM - o'clock</option>
                                <option value="19.8">19:05 PM</option>
                                <option value="19.16">19:10 PM</option>
                                <option value="19.25">19:15 PM</option>
                                <option value="19.33">19:20 PM</option>
                                <option value="19.41">19:25 PM</option>
                                <option value="19.50">19:30 PM</option>
                                <option value="19.58">19:35 PM</option>
                                <option value="19.66">19:40 PM</option>
                                <option value="19.75">19:45 PM</option>
                                <option value="19.83">19:50 PM</option>
                                <option value="19.91">19:55 PM</option>
                                <option value="20.00">20:00 PM - o'clock</option>
                                <option value="20.8">20:05 PM</option>
                                <option value="20.16">20:10 PM</option>
                                <option value="20.25">20:15 PM</option>
                                <option value="20.33">20:20 PM</option>
                                <option value="20.41">20:25 PM</option>
                                <option value="20.50">20:30 PM</option>
                                <option value="20.58">20:35 PM</option>
                                <option value="20.66">20:40 PM</option>
                                <option value="20.75">20:45 PM</option>
                                <option value="20.83">20:50 PM</option>
                                <option value="20.91">20:55 PM</option>
                                <option value="21.00">21:00 PM - o'clock</option>
                                <option value="21.8">21:05 PM</option>
                                <option value="21.16">21:10 PM</option>
                                <option value="21.25">21:15 PM</option>
                                <option value="21.33">21:20 PM</option>
                                <option value="21.41">21:25 PM</option>
                                <option value="21.50">21:30 PM</option>
                                <option value="21.58">21:35 PM</option>
                                <option value="21.66">21:40 PM</option>
                                <option value="21.75">21:45 PM</option>
                                <option value="21.83">21:50 PM</option>
                                <option value="21.91">21:55 PM</option>
                                <option value="22.00">22:00 PM - o'clock</option>
                                <option value="22.8">22:05 PM</option>
                                <option value="22.16">22:10 PM</option>
                                <option value="22.25">22:15 PM</option>
                                <option value="22.33">22:20 PM</option>
                                <option value="22.41">22:25 PM</option>
                                <option value="22.50">22:30 PM</option>
                                <option value="22.58">22:35 PM</option>
                                <option value="22.66">22:40 PM</option>
                                <option value="22.75">22:45 PM</option>
                                <option value="22.83">22:50 PM</option>
                                <option value="22.91">22:55 PM</option>
                                <option value="23.00">23:00 PM - o'clock</option>
                                <option value="23.8">23:05 PM</option>
                                <option value="23.16">23:10 PM</option>
                                <option value="23.25">23:15 PM</option>
                                <option value="23.33">23:20 PM</option>
                                <option value="23.41">23:25 PM</option>
                                <option value="23.50">23:30 PM</option>
                                <option value="23.58">23:35 PM</option>
                                <option value="23.66">23:40 PM</option>
                                <option value="23.75">23:45 PM</option>
                                <option value="23.83">23:50 PM</option>
                                <option value="23.91">23:55 PM</option>
                                <option value="24.00">24:00 PM - o'clock</option>
                                <option value="24.8">15:05 PM</option>
                                <option value="24.16">24:10 PM</option>
                                <option value="24.25">24:15 PM</option>
                                <option value="24.33">24:20 PM</option>
                                <option value="24.41">24:25 PM</option>
                                <option value="24.50">24:30 PM</option>
                                <option value="24.58">24:35 PM</option>
                                <option value="24.66">24:40 PM</option>
                                <option value="24.75">24:45 PM</option>
                                <option value="24.83">24:50 PM</option>
                                <option value="24.91">24:55 PM</option>
                            </select>
                            {data12.map((datas, index) =>{
                              return<div key={index}>
                                        <p>{datas.starthour}</p>
                                    </div>
                                })}
                        </div>
                        <div className="col-md-2">
                            <select name="endhour12" onChange={e =>  setEnd12(e.target.value)} className="form-control">
                                <option value="">Finish Time</option>
                                <option value="05.00">05:00 AM</option>
                                <option value="06.00">06:00 AM</option>
                                <option value="06.8">06:05 AM</option>
                                <option value="06.16">06:10 AM</option>
                                <option value="06.25">06:15 AM</option>
                                <option value="06.33">06:20 AM</option>
                                <option value="06.41">06:25 AM</option>
                                <option value="06.50">06:30 AM</option>
                                <option value="06.58">06:35 AM</option>
                                <option value="06.66">06:40 AM</option>
                                <option value="06.75">06:45 AM</option>
                                <option value="06.83">06:50 AM</option>
                                <option value="06.91">06:55 AM</option>
                                <option value="07.00">07:00 AM - Normal</option>
                                <option value="07.8">07:05 AM</option>
                                <option value="07.16">07:10 AM</option>
                                <option value="07.25">07:15 AM</option>
                                <option value="07.33">07:20 AM</option>
                                <option value="07.41">07:25 AM</option>
                                <option value="07.50">07:30 AM</option>
                                <option value="07.58">07:35 AM</option>
                                <option value="07.66">07:40 AM</option>
                                <option value="07.75">07:45 AM</option>
                                <option value="07.83">07:50 AM</option>
                                <option value="07.91">07:55 AM</option>
                                <option value="08.00">08:00 AM - o'clock</option>
                                <option value="08.8">08:05 AM</option>
                                <option value="08.16">08:10 AM</option>
                                <option value="08.25">08:15 AM</option>
                                <option value="08.33">08:20 AM</option>
                                <option value="08.41">08:25 AM</option>
                                <option value="08.50">08:30 AM</option>
                                <option value="08.58">08:35 AM</option>
                                <option value="08.66">08:40 AM</option>
                                <option value="08.75">08:45 AM</option>
                                <option value="08.83">08:50 AM</option>
                                <option value="08.91">08:55 AM</option>
                                <option value="09.00">09:00 AM - o'clock</option>
                                <option value="09.8">09:05 AM</option>
                                <option value="09.16">09:10 AM</option>
                                <option value="09.25">09:15 AM</option>
                                <option value="09.33">09:20 AM</option>
                                <option value="09.41">09:25 AM</option>
                                <option value="09.50">09:30 AM</option>
                                <option value="09.58">09:35 AM</option>
                                <option value="09.66">09:40 AM</option>
                                <option value="09.75">09:45 AM</option>
                                <option value="09.83">09:50 AM</option>
                                <option value="09.91">09:55 AM</option>
                                <option value="10.00">10:00 AM - o'clock</option>
                                <option value="10.8">10:05 AM</option>
                                <option value="10.16">10:10 AM</option>
                                <option value="10.25">10:15 AM</option>
                                <option value="10.33">10:20 AM</option>
                                <option value="10.41">10:25 AM</option>
                                <option value="10.50">10:30 AM</option>
                                <option value="10.58">10:35 AM</option>
                                <option value="10.66">10:40 AM</option>
                                <option value="10.75">10:45 AM</option>
                                <option value="10.83">10:50 AM</option>
                                <option value="10.91">10:55 AM</option>
                                <option value="11.00">11:00 AM - o'clock</option>
                                <option value="11.8">11:05 AM</option>
                                <option value="11.16">11:10 AM</option>
                                <option value="11.25">11:15 AM</option>
                                <option value="11.33">11:20 AM</option>
                                <option value="11.41">11:25 AM</option>
                                <option value="11.50">11:30 AM</option>
                                <option value="11.58">11:35 AM</option>
                                <option value="11.66">11:40 AM</option>
                                <option value="11.75">11:45 AM</option>
                                <option value="11.83">11:50 AM</option>
                                <option value="11.91">11:55 AM</option>
                                <option value="12.00">12:00 PM  - o'clock</option>
                                <option value="12.8">12:05 PM</option>
                                <option value="12.16">12:10 PM</option>
                                <option value="12.25">12:15 PM</option>
                                <option value="12.33">12:20 PM</option>
                                <option value="12.41">12:25 PM</option>
                                <option value="12.50">12:30 PM</option>
                                <option value="12.58">12:35 PM</option>
                                <option value="12.66">12:40 PM</option>
                                <option value="12.75">12:45 PM</option>
                                <option value="12.83">12:50 PM</option>
                                <option value="12.91">12:55 PM</option>
                                <option value="13.00">13:00 PM - o'clock</option>
                                <option value="13.8">13:05 PM</option>
                                <option value="13.16">13:10 PM</option>
                                <option value="13.25">13:15 PM</option>
                                <option value="13.33">13:20 PM</option>
                                <option value="13.41">13:25 PM</option>
                                <option value="13.50">13:30 PM</option>
                                <option value="13.58">13:35 PM</option>
                                <option value="13.66">13:40 PM</option>
                                <option value="13.75">13:45 PM</option>
                                <option value="13.83">13:50 PM</option>
                                <option value="13.91">13:55 PM</option>
                                <option value="14.00">14:00 PM - o'clock</option>
                                <option value="14.8">14:05 PM</option>
                                <option value="14.16">14:10 PM</option>
                                <option value="14.25">14:15 PM</option>
                                <option value="14.33">14:20 PM</option>
                                <option value="14.41">14:25 PM</option>
                                <option value="14.50">14:30 PM</option>
                                <option value="14.58">14:35 PM</option>
                                <option value="14.66">14:40 PM</option>
                                <option value="14.75">14:45 PM</option>
                                <option value="14.83">14:50 PM</option>
                                <option value="14.91">14:55 PM</option>
                                <option value="15.00">15:00 PM - o'clock</option>
                                <option value="15.8">15:05 PM</option>
                                <option value="15.16">15:10 PM</option>
                                <option value="15.25">15:15 PM</option>
                                <option value="15.33">15:20 PM</option>
                                <option value="15.41">15:25 PM</option>
                                <option value="15.50">15:30 PM</option>
                                <option value="15.58">15:35 PM</option>
                                <option value="15.66">15:40 PM</option>
                                <option value="15.75">15:45 PM</option>
                                <option value="15.83">15:50 PM</option>
                                <option value="15.91">15:55 PM</option>
                                <option value="16.00">16:00 PM - o'clock</option>
                                <option value="16.8">16:05 PM</option>
                                <option value="16.16">16:10 PM</option>
                                <option value="16.25">16:15 PM</option>
                                <option value="16.33">16:20 PM</option>
                                <option value="16.41">16:25 PM</option>
                                <option value="16.50">16:30 PM</option>
                                <option value="16.58">16:35 PM</option>
                                <option value="16.66">16:40 PM</option>
                                <option value="16.75">16:45 PM</option>
                                <option value="16.83">16:50 PM</option>
                                <option value="16.91">16:55 PM</option>
                                <option value="17.00">17:00 PM - o'clock</option>
                                <option value="17.8">17:05 PM</option>
                                <option value="17.16">17:10 PM</option>
                                <option value="17.25">17:15 PM</option>
                                <option value="17.33">17:20 PM</option>
                                <option value="17.41">17:25 PM</option>
                                <option value="17.50">17:30 PM</option>
                                <option value="17.58">17:35 PM</option>
                                <option value="17.66">17:40 PM</option>
                                <option value="17.75">17:45 PM</option>
                                <option value="17.83">17:50 PM</option>
                                <option value="17.91">17:55 PM</option>
                                <option value="18.00">18:00 PM - Normal</option>
                                <option value="18.8">18:05 PM</option>
                                <option value="18.16">18:10 PM</option>
                                <option value="18.25">18:15 PM</option>
                                <option value="18.33">18:20 PM</option>
                                <option value="18.41">18:25 PM</option>
                                <option value="18.50">18:30 PM</option>
                                <option value="18.58">18:35 PM</option>
                                <option value="18.66">18:40 PM</option>
                                <option value="18.75">18:45 PM</option>
                                <option value="18.83">18:50 PM</option>
                                <option value="18.91">18:55 PM</option>
                                <option value="19.00">19:00 PM - o'clock</option>
                                <option value="19.8">19:05 PM</option>
                                <option value="19.16">19:10 PM</option>
                                <option value="19.25">19:15 PM</option>
                                <option value="19.33">19:20 PM</option>
                                <option value="19.41">19:25 PM</option>
                                <option value="19.50">19:30 PM</option>
                                <option value="19.58">19:35 PM</option>
                                <option value="19.66">19:40 PM</option>
                                <option value="19.75">19:45 PM</option>
                                <option value="19.83">19:50 PM</option>
                                <option value="19.91">19:55 PM</option>
                                <option value="20.00">20:00 PM - o'clock</option>
                                <option value="20.8">20:05 PM</option>
                                <option value="20.16">20:10 PM</option>
                                <option value="20.25">20:15 PM</option>
                                <option value="20.33">20:20 PM</option>
                                <option value="20.41">20:25 PM</option>
                                <option value="20.50">20:30 PM</option>
                                <option value="20.58">20:35 PM</option>
                                <option value="20.66">20:40 PM</option>
                                <option value="20.75">20:45 PM</option>
                                <option value="20.83">20:50 PM</option>
                                <option value="20.91">20:55 PM</option>
                                <option value="21.00">21:00 PM - o'clock</option>
                                <option value="21.8">21:05 PM</option>
                                <option value="21.16">21:10 PM</option>
                                <option value="21.25">21:15 PM</option>
                                <option value="21.33">21:20 PM</option>
                                <option value="21.41">21:25 PM</option>
                                <option value="21.50">21:30 PM</option>
                                <option value="21.58">21:35 PM</option>
                                <option value="21.66">21:40 PM</option>
                                <option value="21.75">21:45 PM</option>
                                <option value="21.83">21:50 PM</option>
                                <option value="21.91">21:55 PM</option>
                                <option value="22.00">22:00 PM - o'clock</option>
                                <option value="22.8">22:05 PM</option>
                                <option value="22.16">22:10 PM</option>
                                <option value="22.25">22:15 PM</option>
                                <option value="22.33">22:20 PM</option>
                                <option value="22.41">22:25 PM</option>
                                <option value="22.50">22:30 PM</option>
                                <option value="22.58">22:35 PM</option>
                                <option value="22.66">22:40 PM</option>
                                <option value="22.75">22:45 PM</option>
                                <option value="22.83">22:50 PM</option>
                                <option value="22.91">22:55 PM</option>
                                <option value="23.00">23:00 PM - o'clock</option>
                                <option value="23.8">23:05 PM</option>
                                <option value="23.16">23:10 PM</option>
                                <option value="23.25">23:15 PM</option>
                                <option value="23.33">23:20 PM</option>
                                <option value="23.41">23:25 PM</option>
                                <option value="23.50">23:30 PM</option>
                                <option value="23.58">23:35 PM</option>
                                <option value="23.66">23:40 PM</option>
                                <option value="23.75">23:45 PM</option>
                                <option value="23.83">23:50 PM</option>
                                <option value="23.91">23:55 PM</option>
                                <option value="24.00">24:00 PM - o'clock</option>
                                <option value="24.8">15:05 PM</option>
                                <option value="24.16">24:10 PM</option>
                                <option value="24.25">24:15 PM</option>
                                <option value="24.33">24:20 PM</option>
                                <option value="24.41">24:25 PM</option>
                                <option value="24.50">24:30 PM</option>
                                <option value="24.58">24:35 PM</option>
                                <option value="24.66">24:40 PM</option>
                                <option value="24.75">24:45 PM</option>
                                <option value="24.83">24:50 PM</option>
                                <option value="24.91">24:55 PM</option>
                            </select>
                            {data12.map((datas, index) =>{
                              return<div key={index}>
                                        <p>{datas.endhour}</p>
                                    </div>
                                })}
                        </div>
                        <div className="col-md-2">
                            <select name="type12" onChange={e =>  settype12(e.target.value)} className="form-control" required>
                                <option value="">Select Type</option>
                                <option value="Normal">Normal</option>
                                <option value="SJUK">SJUK</option>
                                <option value="Holiday">Holiday</option>
                            </select>
                            {data12.map((datas, index) =>{
                              return<div key={index}>
                                        <p>{datas.type}</p>
                                    </div>
                                })}
                          </div>
                          <div className="col-md-2"> 
                              <button type='submit' className="btn btn-primary">Submit</button>
                        </div>
                      </form>
                  </div>
                  <div className="d-flex flex-column p-2">
                     <form action="" className="row"  onSubmit={handleSubmit13}>
                        <div className="col-md-1"> 13 </div>
                        <div className="col-md-1"> Sunday  </div>
                        <div className="col-md-1" > 
                            {data13.map((types, index) =>{
                              return<tr key={index}>
                                   <p style={types.type === "Normal" ? {backgroundColor:'rgba(7, 190, 7, 0.648)', color:'#000', padding:'5px 10px'} : 
                                     (types.type === "Holiday" ? {backgroundColor:'rgba(255, 0, 0, 0.438)', color:'#000', padding:'5px 10px'} : 
                                      {backgroundColor:'#008cff5a', color:'#000', padding:'5px 10px'}) }> {types.type}</p>
                                    </tr>
                                })}
                        </div>
                        <div className="col-md-2">
                            <select name="starthour13" onChange={e =>  setStart13(e.target.value)} className="form-control">
                                <option value="">Start Time</option>
                                <option value="05.00">05:00 AM</option>
                                <option value="06.00">06:00 AM</option>
                                <option value="06.8">06:05 AM</option>
                                <option value="06.16">06:10 AM</option>
                                <option value="06.25">06:15 AM</option>
                                <option value="06.33">06:20 AM</option>
                                <option value="06.41">06:25 AM</option>
                                <option value="06.50">06:30 AM</option>
                                <option value="06.58">06:35 AM</option>
                                <option value="06.66">06:40 AM</option>
                                <option value="06.75">06:45 AM</option>
                                <option value="06.83">06:50 AM</option>
                                <option value="06.91">06:55 AM</option>
                                <option value="07.00">07:00 AM - Normal</option>
                                <option value="07.8">07:05 AM</option>
                                <option value="07.16">07:10 AM</option>
                                <option value="07.25">07:15 AM</option>
                                <option value="07.33">07:20 AM</option>
                                <option value="07.41">07:25 AM</option>
                                <option value="07.50">07:30 AM</option>
                                <option value="07.58">07:35 AM</option>
                                <option value="07.66">07:40 AM</option>
                                <option value="07.75">07:45 AM</option>
                                <option value="07.83">07:50 AM</option>
                                <option value="07.91">07:55 AM</option>
                                <option value="08.00">08:00 AM - o'clock</option>
                                <option value="08.8">08:05 AM</option>
                                <option value="08.16">08:10 AM</option>
                                <option value="08.25">08:15 AM</option>
                                <option value="08.33">08:20 AM</option>
                                <option value="08.41">08:25 AM</option>
                                <option value="08.50">08:30 AM</option>
                                <option value="08.58">08:35 AM</option>
                                <option value="08.66">08:40 AM</option>
                                <option value="08.75">08:45 AM</option>
                                <option value="08.83">08:50 AM</option>
                                <option value="08.91">08:55 AM</option>
                                <option value="09.00">09:00 AM - o'clock</option>
                                <option value="09.8">09:05 AM</option>
                                <option value="09.16">09:10 AM</option>
                                <option value="09.25">09:15 AM</option>
                                <option value="09.33">09:20 AM</option>
                                <option value="09.41">09:25 AM</option>
                                <option value="09.50">09:30 AM</option>
                                <option value="09.58">09:35 AM</option>
                                <option value="09.66">09:40 AM</option>
                                <option value="09.75">09:45 AM</option>
                                <option value="09.83">09:50 AM</option>
                                <option value="09.91">09:55 AM</option>
                                <option value="10.00">10:00 AM - o'clock</option>
                                <option value="10.8">10:05 AM</option>
                                <option value="10.16">10:10 AM</option>
                                <option value="10.25">10:15 AM</option>
                                <option value="10.33">10:20 AM</option>
                                <option value="10.41">10:25 AM</option>
                                <option value="10.50">10:30 AM</option>
                                <option value="10.58">10:35 AM</option>
                                <option value="10.66">10:40 AM</option>
                                <option value="10.75">10:45 AM</option>
                                <option value="10.83">10:50 AM</option>
                                <option value="10.91">10:55 AM</option>
                                <option value="11.00">11:00 AM - o'clock</option>
                                <option value="11.8">11:05 AM</option>
                                <option value="11.16">11:10 AM</option>
                                <option value="11.25">11:15 AM</option>
                                <option value="11.33">11:20 AM</option>
                                <option value="11.41">11:25 AM</option>
                                <option value="11.50">11:30 AM</option>
                                <option value="11.58">11:35 AM</option>
                                <option value="11.66">11:40 AM</option>
                                <option value="11.75">11:45 AM</option>
                                <option value="11.83">11:50 AM</option>
                                <option value="11.91">11:55 AM</option>
                                <option value="12.00">12:00 PM  - o'clock</option>
                                <option value="12.8">12:05 PM</option>
                                <option value="12.16">12:10 PM</option>
                                <option value="12.25">12:15 PM</option>
                                <option value="12.33">12:20 PM</option>
                                <option value="12.41">12:25 PM</option>
                                <option value="12.50">12:30 PM</option>
                                <option value="12.58">12:35 PM</option>
                                <option value="12.66">12:40 PM</option>
                                <option value="12.75">12:45 PM</option>
                                <option value="12.83">12:50 PM</option>
                                <option value="12.91">12:55 PM</option>
                                <option value="13.00">13:00 PM - o'clock</option>
                                <option value="13.8">13:05 PM</option>
                                <option value="13.16">13:10 PM</option>
                                <option value="13.25">13:15 PM</option>
                                <option value="13.33">13:20 PM</option>
                                <option value="13.41">13:25 PM</option>
                                <option value="13.50">13:30 PM</option>
                                <option value="13.58">13:35 PM</option>
                                <option value="13.66">13:40 PM</option>
                                <option value="13.75">13:45 PM</option>
                                <option value="13.83">13:50 PM</option>
                                <option value="13.91">13:55 PM</option>
                                <option value="14.00">14:00 PM - o'clock</option>
                                <option value="14.8">14:05 PM</option>
                                <option value="14.16">14:10 PM</option>
                                <option value="14.25">14:15 PM</option>
                                <option value="14.33">14:20 PM</option>
                                <option value="14.41">14:25 PM</option>
                                <option value="14.50">14:30 PM</option>
                                <option value="14.58">14:35 PM</option>
                                <option value="14.66">14:40 PM</option>
                                <option value="14.75">14:45 PM</option>
                                <option value="14.83">14:50 PM</option>
                                <option value="14.91">14:55 PM</option>
                                <option value="15.00">15:00 PM - o'clock</option>
                                <option value="15.8">15:05 PM</option>
                                <option value="15.16">15:10 PM</option>
                                <option value="15.25">15:15 PM</option>
                                <option value="15.33">15:20 PM</option>
                                <option value="15.41">15:25 PM</option>
                                <option value="15.50">15:30 PM</option>
                                <option value="15.58">15:35 PM</option>
                                <option value="15.66">15:40 PM</option>
                                <option value="15.75">15:45 PM</option>
                                <option value="15.83">15:50 PM</option>
                                <option value="15.91">15:55 PM</option>
                                <option value="16.00">16:00 PM - o'clock</option>
                                <option value="16.8">16:05 PM</option>
                                <option value="16.16">16:10 PM</option>
                                <option value="16.25">16:15 PM</option>
                                <option value="16.33">16:20 PM</option>
                                <option value="16.41">16:25 PM</option>
                                <option value="16.50">16:30 PM</option>
                                <option value="16.58">16:35 PM</option>
                                <option value="16.66">16:40 PM</option>
                                <option value="16.75">16:45 PM</option>
                                <option value="16.83">16:50 PM</option>
                                <option value="16.91">16:55 PM</option>
                                <option value="17.00">17:00 PM - o'clock</option>
                                <option value="17.8">17:05 PM</option>
                                <option value="17.16">17:10 PM</option>
                                <option value="17.25">17:15 PM</option>
                                <option value="17.33">17:20 PM</option>
                                <option value="17.41">17:25 PM</option>
                                <option value="17.50">17:30 PM</option>
                                <option value="17.58">17:35 PM</option>
                                <option value="17.66">17:40 PM</option>
                                <option value="17.75">17:45 PM</option>
                                <option value="17.83">17:50 PM</option>
                                <option value="17.91">17:55 PM</option>
                                <option value="18.00">18:00 PM - Normal</option>
                                <option value="18.8">18:05 PM</option>
                                <option value="18.16">18:10 PM</option>
                                <option value="18.25">18:15 PM</option>
                                <option value="18.33">18:20 PM</option>
                                <option value="18.41">18:25 PM</option>
                                <option value="18.50">18:30 PM</option>
                                <option value="18.58">18:35 PM</option>
                                <option value="18.66">18:40 PM</option>
                                <option value="18.75">18:45 PM</option>
                                <option value="18.83">18:50 PM</option>
                                <option value="18.91">18:55 PM</option>
                                <option value="19.00">19:00 PM - o'clock</option>
                                <option value="19.8">19:05 PM</option>
                                <option value="19.16">19:10 PM</option>
                                <option value="19.25">19:15 PM</option>
                                <option value="19.33">19:20 PM</option>
                                <option value="19.41">19:25 PM</option>
                                <option value="19.50">19:30 PM</option>
                                <option value="19.58">19:35 PM</option>
                                <option value="19.66">19:40 PM</option>
                                <option value="19.75">19:45 PM</option>
                                <option value="19.83">19:50 PM</option>
                                <option value="19.91">19:55 PM</option>
                                <option value="20.00">20:00 PM - o'clock</option>
                                <option value="20.8">20:05 PM</option>
                                <option value="20.16">20:10 PM</option>
                                <option value="20.25">20:15 PM</option>
                                <option value="20.33">20:20 PM</option>
                                <option value="20.41">20:25 PM</option>
                                <option value="20.50">20:30 PM</option>
                                <option value="20.58">20:35 PM</option>
                                <option value="20.66">20:40 PM</option>
                                <option value="20.75">20:45 PM</option>
                                <option value="20.83">20:50 PM</option>
                                <option value="20.91">20:55 PM</option>
                                <option value="21.00">21:00 PM - o'clock</option>
                                <option value="21.8">21:05 PM</option>
                                <option value="21.16">21:10 PM</option>
                                <option value="21.25">21:15 PM</option>
                                <option value="21.33">21:20 PM</option>
                                <option value="21.41">21:25 PM</option>
                                <option value="21.50">21:30 PM</option>
                                <option value="21.58">21:35 PM</option>
                                <option value="21.66">21:40 PM</option>
                                <option value="21.75">21:45 PM</option>
                                <option value="21.83">21:50 PM</option>
                                <option value="21.91">21:55 PM</option>
                                <option value="22.00">22:00 PM - o'clock</option>
                                <option value="22.8">22:05 PM</option>
                                <option value="22.16">22:10 PM</option>
                                <option value="22.25">22:15 PM</option>
                                <option value="22.33">22:20 PM</option>
                                <option value="22.41">22:25 PM</option>
                                <option value="22.50">22:30 PM</option>
                                <option value="22.58">22:35 PM</option>
                                <option value="22.66">22:40 PM</option>
                                <option value="22.75">22:45 PM</option>
                                <option value="22.83">22:50 PM</option>
                                <option value="22.91">22:55 PM</option>
                                <option value="23.00">23:00 PM - o'clock</option>
                                <option value="23.8">23:05 PM</option>
                                <option value="23.16">23:10 PM</option>
                                <option value="23.25">23:15 PM</option>
                                <option value="23.33">23:20 PM</option>
                                <option value="23.41">23:25 PM</option>
                                <option value="23.50">23:30 PM</option>
                                <option value="23.58">23:35 PM</option>
                                <option value="23.66">23:40 PM</option>
                                <option value="23.75">23:45 PM</option>
                                <option value="23.83">23:50 PM</option>
                                <option value="23.91">23:55 PM</option>
                                <option value="24.00">24:00 PM - o'clock</option>
                                <option value="24.8">15:05 PM</option>
                                <option value="24.16">24:10 PM</option>
                                <option value="24.25">24:15 PM</option>
                                <option value="24.33">24:20 PM</option>
                                <option value="24.41">24:25 PM</option>
                                <option value="24.50">24:30 PM</option>
                                <option value="24.58">24:35 PM</option>
                                <option value="24.66">24:40 PM</option>
                                <option value="24.75">24:45 PM</option>
                                <option value="24.83">24:50 PM</option>
                                <option value="24.91">24:55 PM</option>
                            </select>
                            {data13.map((datas, index) =>{
                              return<div key={index}>
                                        <p>{datas.starthour}</p>
                                    </div>
                                })}
                        </div>
                        <div className="col-md-2">
                            <select name="endhour13" onChange={e =>  setEnd13(e.target.value)} className="form-control">
                                <option value="">Finish Time</option>
                                <option value="05.00">05:00 AM</option>
                                <option value="06.00">06:00 AM</option>
                                <option value="06.8">06:05 AM</option>
                                <option value="06.16">06:10 AM</option>
                                <option value="06.25">06:15 AM</option>
                                <option value="06.33">06:20 AM</option>
                                <option value="06.41">06:25 AM</option>
                                <option value="06.50">06:30 AM</option>
                                <option value="06.58">06:35 AM</option>
                                <option value="06.66">06:40 AM</option>
                                <option value="06.75">06:45 AM</option>
                                <option value="06.83">06:50 AM</option>
                                <option value="06.91">06:55 AM</option>
                                <option value="07.00">07:00 AM - Normal</option>
                                <option value="07.8">07:05 AM</option>
                                <option value="07.16">07:10 AM</option>
                                <option value="07.25">07:15 AM</option>
                                <option value="07.33">07:20 AM</option>
                                <option value="07.41">07:25 AM</option>
                                <option value="07.50">07:30 AM</option>
                                <option value="07.58">07:35 AM</option>
                                <option value="07.66">07:40 AM</option>
                                <option value="07.75">07:45 AM</option>
                                <option value="07.83">07:50 AM</option>
                                <option value="07.91">07:55 AM</option>
                                <option value="08.00">08:00 AM - o'clock</option>
                                <option value="08.8">08:05 AM</option>
                                <option value="08.16">08:10 AM</option>
                                <option value="08.25">08:15 AM</option>
                                <option value="08.33">08:20 AM</option>
                                <option value="08.41">08:25 AM</option>
                                <option value="08.50">08:30 AM</option>
                                <option value="08.58">08:35 AM</option>
                                <option value="08.66">08:40 AM</option>
                                <option value="08.75">08:45 AM</option>
                                <option value="08.83">08:50 AM</option>
                                <option value="08.91">08:55 AM</option>
                                <option value="09.00">09:00 AM - o'clock</option>
                                <option value="09.8">09:05 AM</option>
                                <option value="09.16">09:10 AM</option>
                                <option value="09.25">09:15 AM</option>
                                <option value="09.33">09:20 AM</option>
                                <option value="09.41">09:25 AM</option>
                                <option value="09.50">09:30 AM</option>
                                <option value="09.58">09:35 AM</option>
                                <option value="09.66">09:40 AM</option>
                                <option value="09.75">09:45 AM</option>
                                <option value="09.83">09:50 AM</option>
                                <option value="09.91">09:55 AM</option>
                                <option value="10.00">10:00 AM - o'clock</option>
                                <option value="10.8">10:05 AM</option>
                                <option value="10.16">10:10 AM</option>
                                <option value="10.25">10:15 AM</option>
                                <option value="10.33">10:20 AM</option>
                                <option value="10.41">10:25 AM</option>
                                <option value="10.50">10:30 AM</option>
                                <option value="10.58">10:35 AM</option>
                                <option value="10.66">10:40 AM</option>
                                <option value="10.75">10:45 AM</option>
                                <option value="10.83">10:50 AM</option>
                                <option value="10.91">10:55 AM</option>
                                <option value="11.00">11:00 AM - o'clock</option>
                                <option value="11.8">11:05 AM</option>
                                <option value="11.16">11:10 AM</option>
                                <option value="11.25">11:15 AM</option>
                                <option value="11.33">11:20 AM</option>
                                <option value="11.41">11:25 AM</option>
                                <option value="11.50">11:30 AM</option>
                                <option value="11.58">11:35 AM</option>
                                <option value="11.66">11:40 AM</option>
                                <option value="11.75">11:45 AM</option>
                                <option value="11.83">11:50 AM</option>
                                <option value="11.91">11:55 AM</option>
                                <option value="12.00">12:00 PM  - o'clock</option>
                                <option value="12.8">12:05 PM</option>
                                <option value="12.16">12:10 PM</option>
                                <option value="12.25">12:15 PM</option>
                                <option value="12.33">12:20 PM</option>
                                <option value="12.41">12:25 PM</option>
                                <option value="12.50">12:30 PM</option>
                                <option value="12.58">12:35 PM</option>
                                <option value="12.66">12:40 PM</option>
                                <option value="12.75">12:45 PM</option>
                                <option value="12.83">12:50 PM</option>
                                <option value="12.91">12:55 PM</option>
                                <option value="13.00">13:00 PM - o'clock</option>
                                <option value="13.8">13:05 PM</option>
                                <option value="13.16">13:10 PM</option>
                                <option value="13.25">13:15 PM</option>
                                <option value="13.33">13:20 PM</option>
                                <option value="13.41">13:25 PM</option>
                                <option value="13.50">13:30 PM</option>
                                <option value="13.58">13:35 PM</option>
                                <option value="13.66">13:40 PM</option>
                                <option value="13.75">13:45 PM</option>
                                <option value="13.83">13:50 PM</option>
                                <option value="13.91">13:55 PM</option>
                                <option value="14.00">14:00 PM - o'clock</option>
                                <option value="14.8">14:05 PM</option>
                                <option value="14.16">14:10 PM</option>
                                <option value="14.25">14:15 PM</option>
                                <option value="14.33">14:20 PM</option>
                                <option value="14.41">14:25 PM</option>
                                <option value="14.50">14:30 PM</option>
                                <option value="14.58">14:35 PM</option>
                                <option value="14.66">14:40 PM</option>
                                <option value="14.75">14:45 PM</option>
                                <option value="14.83">14:50 PM</option>
                                <option value="14.91">14:55 PM</option>
                                <option value="15.00">15:00 PM - o'clock</option>
                                <option value="15.8">15:05 PM</option>
                                <option value="15.16">15:10 PM</option>
                                <option value="15.25">15:15 PM</option>
                                <option value="15.33">15:20 PM</option>
                                <option value="15.41">15:25 PM</option>
                                <option value="15.50">15:30 PM</option>
                                <option value="15.58">15:35 PM</option>
                                <option value="15.66">15:40 PM</option>
                                <option value="15.75">15:45 PM</option>
                                <option value="15.83">15:50 PM</option>
                                <option value="15.91">15:55 PM</option>
                                <option value="16.00">16:00 PM - o'clock</option>
                                <option value="16.8">16:05 PM</option>
                                <option value="16.16">16:10 PM</option>
                                <option value="16.25">16:15 PM</option>
                                <option value="16.33">16:20 PM</option>
                                <option value="16.41">16:25 PM</option>
                                <option value="16.50">16:30 PM</option>
                                <option value="16.58">16:35 PM</option>
                                <option value="16.66">16:40 PM</option>
                                <option value="16.75">16:45 PM</option>
                                <option value="16.83">16:50 PM</option>
                                <option value="16.91">16:55 PM</option>
                                <option value="17.00">17:00 PM - o'clock</option>
                                <option value="17.8">17:05 PM</option>
                                <option value="17.16">17:10 PM</option>
                                <option value="17.25">17:15 PM</option>
                                <option value="17.33">17:20 PM</option>
                                <option value="17.41">17:25 PM</option>
                                <option value="17.50">17:30 PM</option>
                                <option value="17.58">17:35 PM</option>
                                <option value="17.66">17:40 PM</option>
                                <option value="17.75">17:45 PM</option>
                                <option value="17.83">17:50 PM</option>
                                <option value="17.91">17:55 PM</option>
                                <option value="18.00">18:00 PM - Normal</option>
                                <option value="18.8">18:05 PM</option>
                                <option value="18.16">18:10 PM</option>
                                <option value="18.25">18:15 PM</option>
                                <option value="18.33">18:20 PM</option>
                                <option value="18.41">18:25 PM</option>
                                <option value="18.50">18:30 PM</option>
                                <option value="18.58">18:35 PM</option>
                                <option value="18.66">18:40 PM</option>
                                <option value="18.75">18:45 PM</option>
                                <option value="18.83">18:50 PM</option>
                                <option value="18.91">18:55 PM</option>
                                <option value="19.00">19:00 PM - o'clock</option>
                                <option value="19.8">19:05 PM</option>
                                <option value="19.16">19:10 PM</option>
                                <option value="19.25">19:15 PM</option>
                                <option value="19.33">19:20 PM</option>
                                <option value="19.41">19:25 PM</option>
                                <option value="19.50">19:30 PM</option>
                                <option value="19.58">19:35 PM</option>
                                <option value="19.66">19:40 PM</option>
                                <option value="19.75">19:45 PM</option>
                                <option value="19.83">19:50 PM</option>
                                <option value="19.91">19:55 PM</option>
                                <option value="20.00">20:00 PM - o'clock</option>
                                <option value="20.8">20:05 PM</option>
                                <option value="20.16">20:10 PM</option>
                                <option value="20.25">20:15 PM</option>
                                <option value="20.33">20:20 PM</option>
                                <option value="20.41">20:25 PM</option>
                                <option value="20.50">20:30 PM</option>
                                <option value="20.58">20:35 PM</option>
                                <option value="20.66">20:40 PM</option>
                                <option value="20.75">20:45 PM</option>
                                <option value="20.83">20:50 PM</option>
                                <option value="20.91">20:55 PM</option>
                                <option value="21.00">21:00 PM - o'clock</option>
                                <option value="21.8">21:05 PM</option>
                                <option value="21.16">21:10 PM</option>
                                <option value="21.25">21:15 PM</option>
                                <option value="21.33">21:20 PM</option>
                                <option value="21.41">21:25 PM</option>
                                <option value="21.50">21:30 PM</option>
                                <option value="21.58">21:35 PM</option>
                                <option value="21.66">21:40 PM</option>
                                <option value="21.75">21:45 PM</option>
                                <option value="21.83">21:50 PM</option>
                                <option value="21.91">21:55 PM</option>
                                <option value="22.00">22:00 PM - o'clock</option>
                                <option value="22.8">22:05 PM</option>
                                <option value="22.16">22:10 PM</option>
                                <option value="22.25">22:15 PM</option>
                                <option value="22.33">22:20 PM</option>
                                <option value="22.41">22:25 PM</option>
                                <option value="22.50">22:30 PM</option>
                                <option value="22.58">22:35 PM</option>
                                <option value="22.66">22:40 PM</option>
                                <option value="22.75">22:45 PM</option>
                                <option value="22.83">22:50 PM</option>
                                <option value="22.91">22:55 PM</option>
                                <option value="23.00">23:00 PM - o'clock</option>
                                <option value="23.8">23:05 PM</option>
                                <option value="23.16">23:10 PM</option>
                                <option value="23.25">23:15 PM</option>
                                <option value="23.33">23:20 PM</option>
                                <option value="23.41">23:25 PM</option>
                                <option value="23.50">23:30 PM</option>
                                <option value="23.58">23:35 PM</option>
                                <option value="23.66">23:40 PM</option>
                                <option value="23.75">23:45 PM</option>
                                <option value="23.83">23:50 PM</option>
                                <option value="23.91">23:55 PM</option>
                                <option value="24.00">24:00 PM - o'clock</option>
                                <option value="24.8">15:05 PM</option>
                                <option value="24.16">24:10 PM</option>
                                <option value="24.25">24:15 PM</option>
                                <option value="24.33">24:20 PM</option>
                                <option value="24.41">24:25 PM</option>
                                <option value="24.50">24:30 PM</option>
                                <option value="24.58">24:35 PM</option>
                                <option value="24.66">24:40 PM</option>
                                <option value="24.75">24:45 PM</option>
                                <option value="24.83">24:50 PM</option>
                                <option value="24.91">24:55 PM</option>
                            </select>
                            {data13.map((datas, index) =>{
                              return<div key={index}>
                                        <p>{datas.endhour}</p>
                                    </div>
                                })}
                        </div>
                        <div className="col-md-2">
                            <select name="type13" onChange={e =>  settype13(e.target.value)} className="form-control" required>
                                <option value="">Select Type</option>
                                <option value="Normal">Normal</option>
                                <option value="SJUK">SJUK</option>
                                <option value="Holiday">Holiday</option>
                            </select>
                            {data13.map((datas, index) =>{
                              return<div key={index}>
                                        <p>{datas.type}</p>
                                    </div>
                                })}
                          </div>
                          <div className="col-md-2"> 
                              <button type='submit' className="btn btn-primary">Submit</button>
                        </div>
                      </form>
                  </div>
                  <div className="d-flex flex-column p-2">
                     <form action="" className="row"  onSubmit={handleSubmit14}>
                        <div className="col-md-1"> 14 </div>
                        <div className="col-md-1"> Monday </div>
                        <div className="col-md-1" > 
                            {data14.map((types, index) =>{
                              return<tr key={index}>
                                   <p style={types.type === "Normal" ? {backgroundColor:'rgba(7, 190, 7, 0.648)', color:'#000', padding:'5px 10px'} : 
                                     (types.type === "Holiday" ? {backgroundColor:'rgba(255, 0, 0, 0.438)', color:'#000', padding:'5px 10px'} : 
                                      {backgroundColor:'#008cff5a', color:'#000', padding:'5px 10px'}) }> {types.type}</p>
                                    </tr>
                                })}
                        </div>
                        <div className="col-md-2">
                            <select name="starthour14" onChange={e =>  setStart14(e.target.value)} className="form-control">
                                <option value="">Start Time</option>
                                <option value="05.00">05:00 AM</option>
                                <option value="06.00">06:00 AM</option>
                                <option value="06.8">06:05 AM</option>
                                <option value="06.16">06:10 AM</option>
                                <option value="06.25">06:15 AM</option>
                                <option value="06.33">06:20 AM</option>
                                <option value="06.41">06:25 AM</option>
                                <option value="06.50">06:30 AM</option>
                                <option value="06.58">06:35 AM</option>
                                <option value="06.66">06:40 AM</option>
                                <option value="06.75">06:45 AM</option>
                                <option value="06.83">06:50 AM</option>
                                <option value="06.91">06:55 AM</option>
                                <option value="07.00">07:00 AM - Normal</option>
                                <option value="07.8">07:05 AM</option>
                                <option value="07.16">07:10 AM</option>
                                <option value="07.25">07:15 AM</option>
                                <option value="07.33">07:20 AM</option>
                                <option value="07.41">07:25 AM</option>
                                <option value="07.50">07:30 AM</option>
                                <option value="07.58">07:35 AM</option>
                                <option value="07.66">07:40 AM</option>
                                <option value="07.75">07:45 AM</option>
                                <option value="07.83">07:50 AM</option>
                                <option value="07.91">07:55 AM</option>
                                <option value="08.00">08:00 AM - o'clock</option>
                                <option value="08.8">08:05 AM</option>
                                <option value="08.16">08:10 AM</option>
                                <option value="08.25">08:15 AM</option>
                                <option value="08.33">08:20 AM</option>
                                <option value="08.41">08:25 AM</option>
                                <option value="08.50">08:30 AM</option>
                                <option value="08.58">08:35 AM</option>
                                <option value="08.66">08:40 AM</option>
                                <option value="08.75">08:45 AM</option>
                                <option value="08.83">08:50 AM</option>
                                <option value="08.91">08:55 AM</option>
                                <option value="09.00">09:00 AM - o'clock</option>
                                <option value="09.8">09:05 AM</option>
                                <option value="09.16">09:10 AM</option>
                                <option value="09.25">09:15 AM</option>
                                <option value="09.33">09:20 AM</option>
                                <option value="09.41">09:25 AM</option>
                                <option value="09.50">09:30 AM</option>
                                <option value="09.58">09:35 AM</option>
                                <option value="09.66">09:40 AM</option>
                                <option value="09.75">09:45 AM</option>
                                <option value="09.83">09:50 AM</option>
                                <option value="09.91">09:55 AM</option>
                                <option value="10.00">10:00 AM - o'clock</option>
                                <option value="10.8">10:05 AM</option>
                                <option value="10.16">10:10 AM</option>
                                <option value="10.25">10:15 AM</option>
                                <option value="10.33">10:20 AM</option>
                                <option value="10.41">10:25 AM</option>
                                <option value="10.50">10:30 AM</option>
                                <option value="10.58">10:35 AM</option>
                                <option value="10.66">10:40 AM</option>
                                <option value="10.75">10:45 AM</option>
                                <option value="10.83">10:50 AM</option>
                                <option value="10.91">10:55 AM</option>
                                <option value="11.00">11:00 AM - o'clock</option>
                                <option value="11.8">11:05 AM</option>
                                <option value="11.16">11:10 AM</option>
                                <option value="11.25">11:15 AM</option>
                                <option value="11.33">11:20 AM</option>
                                <option value="11.41">11:25 AM</option>
                                <option value="11.50">11:30 AM</option>
                                <option value="11.58">11:35 AM</option>
                                <option value="11.66">11:40 AM</option>
                                <option value="11.75">11:45 AM</option>
                                <option value="11.83">11:50 AM</option>
                                <option value="11.91">11:55 AM</option>
                                <option value="12.00">12:00 PM  - o'clock</option>
                                <option value="12.8">12:05 PM</option>
                                <option value="12.16">12:10 PM</option>
                                <option value="12.25">12:15 PM</option>
                                <option value="12.33">12:20 PM</option>
                                <option value="12.41">12:25 PM</option>
                                <option value="12.50">12:30 PM</option>
                                <option value="12.58">12:35 PM</option>
                                <option value="12.66">12:40 PM</option>
                                <option value="12.75">12:45 PM</option>
                                <option value="12.83">12:50 PM</option>
                                <option value="12.91">12:55 PM</option>
                                <option value="13.00">13:00 PM - o'clock</option>
                                <option value="13.8">13:05 PM</option>
                                <option value="13.16">13:10 PM</option>
                                <option value="13.25">13:15 PM</option>
                                <option value="13.33">13:20 PM</option>
                                <option value="13.41">13:25 PM</option>
                                <option value="13.50">13:30 PM</option>
                                <option value="13.58">13:35 PM</option>
                                <option value="13.66">13:40 PM</option>
                                <option value="13.75">13:45 PM</option>
                                <option value="13.83">13:50 PM</option>
                                <option value="13.91">13:55 PM</option>
                                <option value="14.00">14:00 PM - o'clock</option>
                                <option value="14.8">14:05 PM</option>
                                <option value="14.16">14:10 PM</option>
                                <option value="14.25">14:15 PM</option>
                                <option value="14.33">14:20 PM</option>
                                <option value="14.41">14:25 PM</option>
                                <option value="14.50">14:30 PM</option>
                                <option value="14.58">14:35 PM</option>
                                <option value="14.66">14:40 PM</option>
                                <option value="14.75">14:45 PM</option>
                                <option value="14.83">14:50 PM</option>
                                <option value="14.91">14:55 PM</option>
                                <option value="15.00">15:00 PM - o'clock</option>
                                <option value="15.8">15:05 PM</option>
                                <option value="15.16">15:10 PM</option>
                                <option value="15.25">15:15 PM</option>
                                <option value="15.33">15:20 PM</option>
                                <option value="15.41">15:25 PM</option>
                                <option value="15.50">15:30 PM</option>
                                <option value="15.58">15:35 PM</option>
                                <option value="15.66">15:40 PM</option>
                                <option value="15.75">15:45 PM</option>
                                <option value="15.83">15:50 PM</option>
                                <option value="15.91">15:55 PM</option>
                                <option value="16.00">16:00 PM - o'clock</option>
                                <option value="16.8">16:05 PM</option>
                                <option value="16.16">16:10 PM</option>
                                <option value="16.25">16:15 PM</option>
                                <option value="16.33">16:20 PM</option>
                                <option value="16.41">16:25 PM</option>
                                <option value="16.50">16:30 PM</option>
                                <option value="16.58">16:35 PM</option>
                                <option value="16.66">16:40 PM</option>
                                <option value="16.75">16:45 PM</option>
                                <option value="16.83">16:50 PM</option>
                                <option value="16.91">16:55 PM</option>
                                <option value="17.00">17:00 PM - o'clock</option>
                                <option value="17.8">17:05 PM</option>
                                <option value="17.16">17:10 PM</option>
                                <option value="17.25">17:15 PM</option>
                                <option value="17.33">17:20 PM</option>
                                <option value="17.41">17:25 PM</option>
                                <option value="17.50">17:30 PM</option>
                                <option value="17.58">17:35 PM</option>
                                <option value="17.66">17:40 PM</option>
                                <option value="17.75">17:45 PM</option>
                                <option value="17.83">17:50 PM</option>
                                <option value="17.91">17:55 PM</option>
                                <option value="18.00">18:00 PM - Normal</option>
                                <option value="18.8">18:05 PM</option>
                                <option value="18.16">18:10 PM</option>
                                <option value="18.25">18:15 PM</option>
                                <option value="18.33">18:20 PM</option>
                                <option value="18.41">18:25 PM</option>
                                <option value="18.50">18:30 PM</option>
                                <option value="18.58">18:35 PM</option>
                                <option value="18.66">18:40 PM</option>
                                <option value="18.75">18:45 PM</option>
                                <option value="18.83">18:50 PM</option>
                                <option value="18.91">18:55 PM</option>
                                <option value="19.00">19:00 PM - o'clock</option>
                                <option value="19.8">19:05 PM</option>
                                <option value="19.16">19:10 PM</option>
                                <option value="19.25">19:15 PM</option>
                                <option value="19.33">19:20 PM</option>
                                <option value="19.41">19:25 PM</option>
                                <option value="19.50">19:30 PM</option>
                                <option value="19.58">19:35 PM</option>
                                <option value="19.66">19:40 PM</option>
                                <option value="19.75">19:45 PM</option>
                                <option value="19.83">19:50 PM</option>
                                <option value="19.91">19:55 PM</option>
                                <option value="20.00">20:00 PM - o'clock</option>
                                <option value="20.8">20:05 PM</option>
                                <option value="20.16">20:10 PM</option>
                                <option value="20.25">20:15 PM</option>
                                <option value="20.33">20:20 PM</option>
                                <option value="20.41">20:25 PM</option>
                                <option value="20.50">20:30 PM</option>
                                <option value="20.58">20:35 PM</option>
                                <option value="20.66">20:40 PM</option>
                                <option value="20.75">20:45 PM</option>
                                <option value="20.83">20:50 PM</option>
                                <option value="20.91">20:55 PM</option>
                                <option value="21.00">21:00 PM - o'clock</option>
                                <option value="21.8">21:05 PM</option>
                                <option value="21.16">21:10 PM</option>
                                <option value="21.25">21:15 PM</option>
                                <option value="21.33">21:20 PM</option>
                                <option value="21.41">21:25 PM</option>
                                <option value="21.50">21:30 PM</option>
                                <option value="21.58">21:35 PM</option>
                                <option value="21.66">21:40 PM</option>
                                <option value="21.75">21:45 PM</option>
                                <option value="21.83">21:50 PM</option>
                                <option value="21.91">21:55 PM</option>
                                <option value="22.00">22:00 PM - o'clock</option>
                                <option value="22.8">22:05 PM</option>
                                <option value="22.16">22:10 PM</option>
                                <option value="22.25">22:15 PM</option>
                                <option value="22.33">22:20 PM</option>
                                <option value="22.41">22:25 PM</option>
                                <option value="22.50">22:30 PM</option>
                                <option value="22.58">22:35 PM</option>
                                <option value="22.66">22:40 PM</option>
                                <option value="22.75">22:45 PM</option>
                                <option value="22.83">22:50 PM</option>
                                <option value="22.91">22:55 PM</option>
                                <option value="23.00">23:00 PM - o'clock</option>
                                <option value="23.8">23:05 PM</option>
                                <option value="23.16">23:10 PM</option>
                                <option value="23.25">23:15 PM</option>
                                <option value="23.33">23:20 PM</option>
                                <option value="23.41">23:25 PM</option>
                                <option value="23.50">23:30 PM</option>
                                <option value="23.58">23:35 PM</option>
                                <option value="23.66">23:40 PM</option>
                                <option value="23.75">23:45 PM</option>
                                <option value="23.83">23:50 PM</option>
                                <option value="23.91">23:55 PM</option>
                                <option value="24.00">24:00 PM - o'clock</option>
                                <option value="24.8">15:05 PM</option>
                                <option value="24.16">24:10 PM</option>
                                <option value="24.25">24:15 PM</option>
                                <option value="24.33">24:20 PM</option>
                                <option value="24.41">24:25 PM</option>
                                <option value="24.50">24:30 PM</option>
                                <option value="24.58">24:35 PM</option>
                                <option value="24.66">24:40 PM</option>
                                <option value="24.75">24:45 PM</option>
                                <option value="24.83">24:50 PM</option>
                                <option value="24.91">24:55 PM</option>
                            </select>
                            {data14.map((datas, index) =>{
                              return<div key={index}>
                                        <p>{datas.starthour}</p>
                                    </div>
                                })}
                        </div>
                        <div className="col-md-2">
                            <select name="endhour14" onChange={e =>  setEnd14(e.target.value)} className="form-control">
                                <option value="">Finish Time</option>
                                <option value="05.00">05:00 AM</option>
                                <option value="06.00">06:00 AM</option>
                                <option value="06.8">06:05 AM</option>
                                <option value="06.16">06:10 AM</option>
                                <option value="06.25">06:15 AM</option>
                                <option value="06.33">06:20 AM</option>
                                <option value="06.41">06:25 AM</option>
                                <option value="06.50">06:30 AM</option>
                                <option value="06.58">06:35 AM</option>
                                <option value="06.66">06:40 AM</option>
                                <option value="06.75">06:45 AM</option>
                                <option value="06.83">06:50 AM</option>
                                <option value="06.91">06:55 AM</option>
                                <option value="07.00">07:00 AM - Normal</option>
                                <option value="07.8">07:05 AM</option>
                                <option value="07.16">07:10 AM</option>
                                <option value="07.25">07:15 AM</option>
                                <option value="07.33">07:20 AM</option>
                                <option value="07.41">07:25 AM</option>
                                <option value="07.50">07:30 AM</option>
                                <option value="07.58">07:35 AM</option>
                                <option value="07.66">07:40 AM</option>
                                <option value="07.75">07:45 AM</option>
                                <option value="07.83">07:50 AM</option>
                                <option value="07.91">07:55 AM</option>
                                <option value="08.00">08:00 AM - o'clock</option>
                                <option value="08.8">08:05 AM</option>
                                <option value="08.16">08:10 AM</option>
                                <option value="08.25">08:15 AM</option>
                                <option value="08.33">08:20 AM</option>
                                <option value="08.41">08:25 AM</option>
                                <option value="08.50">08:30 AM</option>
                                <option value="08.58">08:35 AM</option>
                                <option value="08.66">08:40 AM</option>
                                <option value="08.75">08:45 AM</option>
                                <option value="08.83">08:50 AM</option>
                                <option value="08.91">08:55 AM</option>
                                <option value="09.00">09:00 AM - o'clock</option>
                                <option value="09.8">09:05 AM</option>
                                <option value="09.16">09:10 AM</option>
                                <option value="09.25">09:15 AM</option>
                                <option value="09.33">09:20 AM</option>
                                <option value="09.41">09:25 AM</option>
                                <option value="09.50">09:30 AM</option>
                                <option value="09.58">09:35 AM</option>
                                <option value="09.66">09:40 AM</option>
                                <option value="09.75">09:45 AM</option>
                                <option value="09.83">09:50 AM</option>
                                <option value="09.91">09:55 AM</option>
                                <option value="10.00">10:00 AM - o'clock</option>
                                <option value="10.8">10:05 AM</option>
                                <option value="10.16">10:10 AM</option>
                                <option value="10.25">10:15 AM</option>
                                <option value="10.33">10:20 AM</option>
                                <option value="10.41">10:25 AM</option>
                                <option value="10.50">10:30 AM</option>
                                <option value="10.58">10:35 AM</option>
                                <option value="10.66">10:40 AM</option>
                                <option value="10.75">10:45 AM</option>
                                <option value="10.83">10:50 AM</option>
                                <option value="10.91">10:55 AM</option>
                                <option value="11.00">11:00 AM - o'clock</option>
                                <option value="11.8">11:05 AM</option>
                                <option value="11.16">11:10 AM</option>
                                <option value="11.25">11:15 AM</option>
                                <option value="11.33">11:20 AM</option>
                                <option value="11.41">11:25 AM</option>
                                <option value="11.50">11:30 AM</option>
                                <option value="11.58">11:35 AM</option>
                                <option value="11.66">11:40 AM</option>
                                <option value="11.75">11:45 AM</option>
                                <option value="11.83">11:50 AM</option>
                                <option value="11.91">11:55 AM</option>
                                <option value="12.00">12:00 PM  - o'clock</option>
                                <option value="12.8">12:05 PM</option>
                                <option value="12.16">12:10 PM</option>
                                <option value="12.25">12:15 PM</option>
                                <option value="12.33">12:20 PM</option>
                                <option value="12.41">12:25 PM</option>
                                <option value="12.50">12:30 PM</option>
                                <option value="12.58">12:35 PM</option>
                                <option value="12.66">12:40 PM</option>
                                <option value="12.75">12:45 PM</option>
                                <option value="12.83">12:50 PM</option>
                                <option value="12.91">12:55 PM</option>
                                <option value="13.00">13:00 PM - o'clock</option>
                                <option value="13.8">13:05 PM</option>
                                <option value="13.16">13:10 PM</option>
                                <option value="13.25">13:15 PM</option>
                                <option value="13.33">13:20 PM</option>
                                <option value="13.41">13:25 PM</option>
                                <option value="13.50">13:30 PM</option>
                                <option value="13.58">13:35 PM</option>
                                <option value="13.66">13:40 PM</option>
                                <option value="13.75">13:45 PM</option>
                                <option value="13.83">13:50 PM</option>
                                <option value="13.91">13:55 PM</option>
                                <option value="14.00">14:00 PM - o'clock</option>
                                <option value="14.8">14:05 PM</option>
                                <option value="14.16">14:10 PM</option>
                                <option value="14.25">14:15 PM</option>
                                <option value="14.33">14:20 PM</option>
                                <option value="14.41">14:25 PM</option>
                                <option value="14.50">14:30 PM</option>
                                <option value="14.58">14:35 PM</option>
                                <option value="14.66">14:40 PM</option>
                                <option value="14.75">14:45 PM</option>
                                <option value="14.83">14:50 PM</option>
                                <option value="14.91">14:55 PM</option>
                                <option value="15.00">15:00 PM - o'clock</option>
                                <option value="15.8">15:05 PM</option>
                                <option value="15.16">15:10 PM</option>
                                <option value="15.25">15:15 PM</option>
                                <option value="15.33">15:20 PM</option>
                                <option value="15.41">15:25 PM</option>
                                <option value="15.50">15:30 PM</option>
                                <option value="15.58">15:35 PM</option>
                                <option value="15.66">15:40 PM</option>
                                <option value="15.75">15:45 PM</option>
                                <option value="15.83">15:50 PM</option>
                                <option value="15.91">15:55 PM</option>
                                <option value="16.00">16:00 PM - o'clock</option>
                                <option value="16.8">16:05 PM</option>
                                <option value="16.16">16:10 PM</option>
                                <option value="16.25">16:15 PM</option>
                                <option value="16.33">16:20 PM</option>
                                <option value="16.41">16:25 PM</option>
                                <option value="16.50">16:30 PM</option>
                                <option value="16.58">16:35 PM</option>
                                <option value="16.66">16:40 PM</option>
                                <option value="16.75">16:45 PM</option>
                                <option value="16.83">16:50 PM</option>
                                <option value="16.91">16:55 PM</option>
                                <option value="17.00">17:00 PM - o'clock</option>
                                <option value="17.8">17:05 PM</option>
                                <option value="17.16">17:10 PM</option>
                                <option value="17.25">17:15 PM</option>
                                <option value="17.33">17:20 PM</option>
                                <option value="17.41">17:25 PM</option>
                                <option value="17.50">17:30 PM</option>
                                <option value="17.58">17:35 PM</option>
                                <option value="17.66">17:40 PM</option>
                                <option value="17.75">17:45 PM</option>
                                <option value="17.83">17:50 PM</option>
                                <option value="17.91">17:55 PM</option>
                                <option value="18.00">18:00 PM - Normal</option>
                                <option value="18.8">18:05 PM</option>
                                <option value="18.16">18:10 PM</option>
                                <option value="18.25">18:15 PM</option>
                                <option value="18.33">18:20 PM</option>
                                <option value="18.41">18:25 PM</option>
                                <option value="18.50">18:30 PM</option>
                                <option value="18.58">18:35 PM</option>
                                <option value="18.66">18:40 PM</option>
                                <option value="18.75">18:45 PM</option>
                                <option value="18.83">18:50 PM</option>
                                <option value="18.91">18:55 PM</option>
                                <option value="19.00">19:00 PM - o'clock</option>
                                <option value="19.8">19:05 PM</option>
                                <option value="19.16">19:10 PM</option>
                                <option value="19.25">19:15 PM</option>
                                <option value="19.33">19:20 PM</option>
                                <option value="19.41">19:25 PM</option>
                                <option value="19.50">19:30 PM</option>
                                <option value="19.58">19:35 PM</option>
                                <option value="19.66">19:40 PM</option>
                                <option value="19.75">19:45 PM</option>
                                <option value="19.83">19:50 PM</option>
                                <option value="19.91">19:55 PM</option>
                                <option value="20.00">20:00 PM - o'clock</option>
                                <option value="20.8">20:05 PM</option>
                                <option value="20.16">20:10 PM</option>
                                <option value="20.25">20:15 PM</option>
                                <option value="20.33">20:20 PM</option>
                                <option value="20.41">20:25 PM</option>
                                <option value="20.50">20:30 PM</option>
                                <option value="20.58">20:35 PM</option>
                                <option value="20.66">20:40 PM</option>
                                <option value="20.75">20:45 PM</option>
                                <option value="20.83">20:50 PM</option>
                                <option value="20.91">20:55 PM</option>
                                <option value="21.00">21:00 PM - o'clock</option>
                                <option value="21.8">21:05 PM</option>
                                <option value="21.16">21:10 PM</option>
                                <option value="21.25">21:15 PM</option>
                                <option value="21.33">21:20 PM</option>
                                <option value="21.41">21:25 PM</option>
                                <option value="21.50">21:30 PM</option>
                                <option value="21.58">21:35 PM</option>
                                <option value="21.66">21:40 PM</option>
                                <option value="21.75">21:45 PM</option>
                                <option value="21.83">21:50 PM</option>
                                <option value="21.91">21:55 PM</option>
                                <option value="22.00">22:00 PM - o'clock</option>
                                <option value="22.8">22:05 PM</option>
                                <option value="22.16">22:10 PM</option>
                                <option value="22.25">22:15 PM</option>
                                <option value="22.33">22:20 PM</option>
                                <option value="22.41">22:25 PM</option>
                                <option value="22.50">22:30 PM</option>
                                <option value="22.58">22:35 PM</option>
                                <option value="22.66">22:40 PM</option>
                                <option value="22.75">22:45 PM</option>
                                <option value="22.83">22:50 PM</option>
                                <option value="22.91">22:55 PM</option>
                                <option value="23.00">23:00 PM - o'clock</option>
                                <option value="23.8">23:05 PM</option>
                                <option value="23.16">23:10 PM</option>
                                <option value="23.25">23:15 PM</option>
                                <option value="23.33">23:20 PM</option>
                                <option value="23.41">23:25 PM</option>
                                <option value="23.50">23:30 PM</option>
                                <option value="23.58">23:35 PM</option>
                                <option value="23.66">23:40 PM</option>
                                <option value="23.75">23:45 PM</option>
                                <option value="23.83">23:50 PM</option>
                                <option value="23.91">23:55 PM</option>
                                <option value="24.00">24:00 PM - o'clock</option>
                                <option value="24.8">15:05 PM</option>
                                <option value="24.16">24:10 PM</option>
                                <option value="24.25">24:15 PM</option>
                                <option value="24.33">24:20 PM</option>
                                <option value="24.41">24:25 PM</option>
                                <option value="24.50">24:30 PM</option>
                                <option value="24.58">24:35 PM</option>
                                <option value="24.66">24:40 PM</option>
                                <option value="24.75">24:45 PM</option>
                                <option value="24.83">24:50 PM</option>
                                <option value="24.91">24:55 PM</option>
                            </select>
                            {data14.map((datas, index) =>{
                              return<div key={index}>
                                        <p>{datas.endhour}</p>
                                    </div>
                                })}
                        </div>
                        <div className="col-md-2">
                            <select name="type14" onChange={e =>  settype14(e.target.value)} className="form-control" required>
                                <option value="">Select Type</option>
                                <option value="Normal">Normal</option>
                                <option value="SJUK">SJUK</option>
                                <option value="Holiday">Holiday</option>
                            </select>
                            {data14.map((datas, index) =>{
                              return<div key={index}>
                                        <p>{datas.type}</p>
                                    </div>
                                })}
                          </div>
                          <div className="col-md-2"> 
                              <button type='submit' className="btn btn-primary">Submit</button>
                        </div>
                      </form>
                  </div>
                  <div className="d-flex flex-column p-2">
                     <form action="" className="row"  onSubmit={handleSubmit15}>
                        <div className="col-md-1"> 15 </div>
                        <div className="col-md-1"> Tuesday </div>
                        <div className="col-md-1" > 
                            {data15.map((types, index) =>{
                              return<tr key={index}>
                                   <p style={types.type === "Normal" ? {backgroundColor:'rgba(7, 190, 7, 0.648)', color:'#000', padding:'5px 10px'} : 
                                     (types.type === "Holiday" ? {backgroundColor:'rgba(255, 0, 0, 0.438)', color:'#000', padding:'5px 10px'} : 
                                      {backgroundColor:'#008cff5a', color:'#000', padding:'5px 10px'}) }> {types.type}</p>
                                    </tr>
                                })}
                        </div>
                        <div className="col-md-2">
                            <select name="starthour15" onChange={e =>  setStart15(e.target.value)} className="form-control">
                                <option value="">Start Time</option>
                                <option value="05.00">05:00 AM</option>
                                <option value="06.00">06:00 AM</option>
                                <option value="06.8">06:05 AM</option>
                                <option value="06.16">06:10 AM</option>
                                <option value="06.25">06:15 AM</option>
                                <option value="06.33">06:20 AM</option>
                                <option value="06.41">06:25 AM</option>
                                <option value="06.50">06:30 AM</option>
                                <option value="06.58">06:35 AM</option>
                                <option value="06.66">06:40 AM</option>
                                <option value="06.75">06:45 AM</option>
                                <option value="06.83">06:50 AM</option>
                                <option value="06.91">06:55 AM</option>
                                <option value="07.00">07:00 AM - Normal</option>
                                <option value="07.8">07:05 AM</option>
                                <option value="07.16">07:10 AM</option>
                                <option value="07.25">07:15 AM</option>
                                <option value="07.33">07:20 AM</option>
                                <option value="07.41">07:25 AM</option>
                                <option value="07.50">07:30 AM</option>
                                <option value="07.58">07:35 AM</option>
                                <option value="07.66">07:40 AM</option>
                                <option value="07.75">07:45 AM</option>
                                <option value="07.83">07:50 AM</option>
                                <option value="07.91">07:55 AM</option>
                                <option value="08.00">08:00 AM - o'clock</option>
                                <option value="08.8">08:05 AM</option>
                                <option value="08.16">08:10 AM</option>
                                <option value="08.25">08:15 AM</option>
                                <option value="08.33">08:20 AM</option>
                                <option value="08.41">08:25 AM</option>
                                <option value="08.50">08:30 AM</option>
                                <option value="08.58">08:35 AM</option>
                                <option value="08.66">08:40 AM</option>
                                <option value="08.75">08:45 AM</option>
                                <option value="08.83">08:50 AM</option>
                                <option value="08.91">08:55 AM</option>
                                <option value="09.00">09:00 AM - o'clock</option>
                                <option value="09.8">09:05 AM</option>
                                <option value="09.16">09:10 AM</option>
                                <option value="09.25">09:15 AM</option>
                                <option value="09.33">09:20 AM</option>
                                <option value="09.41">09:25 AM</option>
                                <option value="09.50">09:30 AM</option>
                                <option value="09.58">09:35 AM</option>
                                <option value="09.66">09:40 AM</option>
                                <option value="09.75">09:45 AM</option>
                                <option value="09.83">09:50 AM</option>
                                <option value="09.91">09:55 AM</option>
                                <option value="10.00">10:00 AM - o'clock</option>
                                <option value="10.8">10:05 AM</option>
                                <option value="10.16">10:10 AM</option>
                                <option value="10.25">10:15 AM</option>
                                <option value="10.33">10:20 AM</option>
                                <option value="10.41">10:25 AM</option>
                                <option value="10.50">10:30 AM</option>
                                <option value="10.58">10:35 AM</option>
                                <option value="10.66">10:40 AM</option>
                                <option value="10.75">10:45 AM</option>
                                <option value="10.83">10:50 AM</option>
                                <option value="10.91">10:55 AM</option>
                                <option value="11.00">11:00 AM - o'clock</option>
                                <option value="11.8">11:05 AM</option>
                                <option value="11.16">11:10 AM</option>
                                <option value="11.25">11:15 AM</option>
                                <option value="11.33">11:20 AM</option>
                                <option value="11.41">11:25 AM</option>
                                <option value="11.50">11:30 AM</option>
                                <option value="11.58">11:35 AM</option>
                                <option value="11.66">11:40 AM</option>
                                <option value="11.75">11:45 AM</option>
                                <option value="11.83">11:50 AM</option>
                                <option value="11.91">11:55 AM</option>
                                <option value="12.00">12:00 PM  - o'clock</option>
                                <option value="12.8">12:05 PM</option>
                                <option value="12.16">12:10 PM</option>
                                <option value="12.25">12:15 PM</option>
                                <option value="12.33">12:20 PM</option>
                                <option value="12.41">12:25 PM</option>
                                <option value="12.50">12:30 PM</option>
                                <option value="12.58">12:35 PM</option>
                                <option value="12.66">12:40 PM</option>
                                <option value="12.75">12:45 PM</option>
                                <option value="12.83">12:50 PM</option>
                                <option value="12.91">12:55 PM</option>
                                <option value="13.00">13:00 PM - o'clock</option>
                                <option value="13.8">13:05 PM</option>
                                <option value="13.16">13:10 PM</option>
                                <option value="13.25">13:15 PM</option>
                                <option value="13.33">13:20 PM</option>
                                <option value="13.41">13:25 PM</option>
                                <option value="13.50">13:30 PM</option>
                                <option value="13.58">13:35 PM</option>
                                <option value="13.66">13:40 PM</option>
                                <option value="13.75">13:45 PM</option>
                                <option value="13.83">13:50 PM</option>
                                <option value="13.91">13:55 PM</option>
                                <option value="14.00">14:00 PM - o'clock</option>
                                <option value="14.8">14:05 PM</option>
                                <option value="14.16">14:10 PM</option>
                                <option value="14.25">14:15 PM</option>
                                <option value="14.33">14:20 PM</option>
                                <option value="14.41">14:25 PM</option>
                                <option value="14.50">14:30 PM</option>
                                <option value="14.58">14:35 PM</option>
                                <option value="14.66">14:40 PM</option>
                                <option value="14.75">14:45 PM</option>
                                <option value="14.83">14:50 PM</option>
                                <option value="14.91">14:55 PM</option>
                                <option value="15.00">15:00 PM - o'clock</option>
                                <option value="15.8">15:05 PM</option>
                                <option value="15.16">15:10 PM</option>
                                <option value="15.25">15:15 PM</option>
                                <option value="15.33">15:20 PM</option>
                                <option value="15.41">15:25 PM</option>
                                <option value="15.50">15:30 PM</option>
                                <option value="15.58">15:35 PM</option>
                                <option value="15.66">15:40 PM</option>
                                <option value="15.75">15:45 PM</option>
                                <option value="15.83">15:50 PM</option>
                                <option value="15.91">15:55 PM</option>
                                <option value="16.00">16:00 PM - o'clock</option>
                                <option value="16.8">16:05 PM</option>
                                <option value="16.16">16:10 PM</option>
                                <option value="16.25">16:15 PM</option>
                                <option value="16.33">16:20 PM</option>
                                <option value="16.41">16:25 PM</option>
                                <option value="16.50">16:30 PM</option>
                                <option value="16.58">16:35 PM</option>
                                <option value="16.66">16:40 PM</option>
                                <option value="16.75">16:45 PM</option>
                                <option value="16.83">16:50 PM</option>
                                <option value="16.91">16:55 PM</option>
                                <option value="17.00">17:00 PM - o'clock</option>
                                <option value="17.8">17:05 PM</option>
                                <option value="17.16">17:10 PM</option>
                                <option value="17.25">17:15 PM</option>
                                <option value="17.33">17:20 PM</option>
                                <option value="17.41">17:25 PM</option>
                                <option value="17.50">17:30 PM</option>
                                <option value="17.58">17:35 PM</option>
                                <option value="17.66">17:40 PM</option>
                                <option value="17.75">17:45 PM</option>
                                <option value="17.83">17:50 PM</option>
                                <option value="17.91">17:55 PM</option>
                                <option value="18.00">18:00 PM - Normal</option>
                                <option value="18.8">18:05 PM</option>
                                <option value="18.16">18:10 PM</option>
                                <option value="18.25">18:15 PM</option>
                                <option value="18.33">18:20 PM</option>
                                <option value="18.41">18:25 PM</option>
                                <option value="18.50">18:30 PM</option>
                                <option value="18.58">18:35 PM</option>
                                <option value="18.66">18:40 PM</option>
                                <option value="18.75">18:45 PM</option>
                                <option value="18.83">18:50 PM</option>
                                <option value="18.91">18:55 PM</option>
                                <option value="19.00">19:00 PM - o'clock</option>
                                <option value="19.8">19:05 PM</option>
                                <option value="19.16">19:10 PM</option>
                                <option value="19.25">19:15 PM</option>
                                <option value="19.33">19:20 PM</option>
                                <option value="19.41">19:25 PM</option>
                                <option value="19.50">19:30 PM</option>
                                <option value="19.58">19:35 PM</option>
                                <option value="19.66">19:40 PM</option>
                                <option value="19.75">19:45 PM</option>
                                <option value="19.83">19:50 PM</option>
                                <option value="19.91">19:55 PM</option>
                                <option value="20.00">20:00 PM - o'clock</option>
                                <option value="20.8">20:05 PM</option>
                                <option value="20.16">20:10 PM</option>
                                <option value="20.25">20:15 PM</option>
                                <option value="20.33">20:20 PM</option>
                                <option value="20.41">20:25 PM</option>
                                <option value="20.50">20:30 PM</option>
                                <option value="20.58">20:35 PM</option>
                                <option value="20.66">20:40 PM</option>
                                <option value="20.75">20:45 PM</option>
                                <option value="20.83">20:50 PM</option>
                                <option value="20.91">20:55 PM</option>
                                <option value="21.00">21:00 PM - o'clock</option>
                                <option value="21.8">21:05 PM</option>
                                <option value="21.16">21:10 PM</option>
                                <option value="21.25">21:15 PM</option>
                                <option value="21.33">21:20 PM</option>
                                <option value="21.41">21:25 PM</option>
                                <option value="21.50">21:30 PM</option>
                                <option value="21.58">21:35 PM</option>
                                <option value="21.66">21:40 PM</option>
                                <option value="21.75">21:45 PM</option>
                                <option value="21.83">21:50 PM</option>
                                <option value="21.91">21:55 PM</option>
                                <option value="22.00">22:00 PM - o'clock</option>
                                <option value="22.8">22:05 PM</option>
                                <option value="22.16">22:10 PM</option>
                                <option value="22.25">22:15 PM</option>
                                <option value="22.33">22:20 PM</option>
                                <option value="22.41">22:25 PM</option>
                                <option value="22.50">22:30 PM</option>
                                <option value="22.58">22:35 PM</option>
                                <option value="22.66">22:40 PM</option>
                                <option value="22.75">22:45 PM</option>
                                <option value="22.83">22:50 PM</option>
                                <option value="22.91">22:55 PM</option>
                                <option value="23.00">23:00 PM - o'clock</option>
                                <option value="23.8">23:05 PM</option>
                                <option value="23.16">23:10 PM</option>
                                <option value="23.25">23:15 PM</option>
                                <option value="23.33">23:20 PM</option>
                                <option value="23.41">23:25 PM</option>
                                <option value="23.50">23:30 PM</option>
                                <option value="23.58">23:35 PM</option>
                                <option value="23.66">23:40 PM</option>
                                <option value="23.75">23:45 PM</option>
                                <option value="23.83">23:50 PM</option>
                                <option value="23.91">23:55 PM</option>
                                <option value="24.00">24:00 PM - o'clock</option>
                                <option value="24.8">15:05 PM</option>
                                <option value="24.16">24:10 PM</option>
                                <option value="24.25">24:15 PM</option>
                                <option value="24.33">24:20 PM</option>
                                <option value="24.41">24:25 PM</option>
                                <option value="24.50">24:30 PM</option>
                                <option value="24.58">24:35 PM</option>
                                <option value="24.66">24:40 PM</option>
                                <option value="24.75">24:45 PM</option>
                                <option value="24.83">24:50 PM</option>
                                <option value="24.91">24:55 PM</option>
                            </select>
                            {data15.map((datas, index) =>{
                              return<div key={index}>
                                        <p>{datas.starthour}</p>
                                    </div>
                                })}
                        </div>
                        <div className="col-md-2">
                            <select name="endhour15" onChange={e =>  setEnd15(e.target.value)} className="form-control">
                                <option value="">Finish Time</option>
                                <option value="05.00">05:00 AM</option>
                                <option value="06.00">06:00 AM</option>
                                <option value="06.8">06:05 AM</option>
                                <option value="06.16">06:10 AM</option>
                                <option value="06.25">06:15 AM</option>
                                <option value="06.33">06:20 AM</option>
                                <option value="06.41">06:25 AM</option>
                                <option value="06.50">06:30 AM</option>
                                <option value="06.58">06:35 AM</option>
                                <option value="06.66">06:40 AM</option>
                                <option value="06.75">06:45 AM</option>
                                <option value="06.83">06:50 AM</option>
                                <option value="06.91">06:55 AM</option>
                                <option value="07.00">07:00 AM - Normal</option>
                                <option value="07.8">07:05 AM</option>
                                <option value="07.16">07:10 AM</option>
                                <option value="07.25">07:15 AM</option>
                                <option value="07.33">07:20 AM</option>
                                <option value="07.41">07:25 AM</option>
                                <option value="07.50">07:30 AM</option>
                                <option value="07.58">07:35 AM</option>
                                <option value="07.66">07:40 AM</option>
                                <option value="07.75">07:45 AM</option>
                                <option value="07.83">07:50 AM</option>
                                <option value="07.91">07:55 AM</option>
                                <option value="08.00">08:00 AM - o'clock</option>
                                <option value="08.8">08:05 AM</option>
                                <option value="08.16">08:10 AM</option>
                                <option value="08.25">08:15 AM</option>
                                <option value="08.33">08:20 AM</option>
                                <option value="08.41">08:25 AM</option>
                                <option value="08.50">08:30 AM</option>
                                <option value="08.58">08:35 AM</option>
                                <option value="08.66">08:40 AM</option>
                                <option value="08.75">08:45 AM</option>
                                <option value="08.83">08:50 AM</option>
                                <option value="08.91">08:55 AM</option>
                                <option value="09.00">09:00 AM - o'clock</option>
                                <option value="09.8">09:05 AM</option>
                                <option value="09.16">09:10 AM</option>
                                <option value="09.25">09:15 AM</option>
                                <option value="09.33">09:20 AM</option>
                                <option value="09.41">09:25 AM</option>
                                <option value="09.50">09:30 AM</option>
                                <option value="09.58">09:35 AM</option>
                                <option value="09.66">09:40 AM</option>
                                <option value="09.75">09:45 AM</option>
                                <option value="09.83">09:50 AM</option>
                                <option value="09.91">09:55 AM</option>
                                <option value="10.00">10:00 AM - o'clock</option>
                                <option value="10.8">10:05 AM</option>
                                <option value="10.16">10:10 AM</option>
                                <option value="10.25">10:15 AM</option>
                                <option value="10.33">10:20 AM</option>
                                <option value="10.41">10:25 AM</option>
                                <option value="10.50">10:30 AM</option>
                                <option value="10.58">10:35 AM</option>
                                <option value="10.66">10:40 AM</option>
                                <option value="10.75">10:45 AM</option>
                                <option value="10.83">10:50 AM</option>
                                <option value="10.91">10:55 AM</option>
                                <option value="11.00">11:00 AM - o'clock</option>
                                <option value="11.8">11:05 AM</option>
                                <option value="11.16">11:10 AM</option>
                                <option value="11.25">11:15 AM</option>
                                <option value="11.33">11:20 AM</option>
                                <option value="11.41">11:25 AM</option>
                                <option value="11.50">11:30 AM</option>
                                <option value="11.58">11:35 AM</option>
                                <option value="11.66">11:40 AM</option>
                                <option value="11.75">11:45 AM</option>
                                <option value="11.83">11:50 AM</option>
                                <option value="11.91">11:55 AM</option>
                                <option value="12.00">12:00 PM  - o'clock</option>
                                <option value="12.8">12:05 PM</option>
                                <option value="12.16">12:10 PM</option>
                                <option value="12.25">12:15 PM</option>
                                <option value="12.33">12:20 PM</option>
                                <option value="12.41">12:25 PM</option>
                                <option value="12.50">12:30 PM</option>
                                <option value="12.58">12:35 PM</option>
                                <option value="12.66">12:40 PM</option>
                                <option value="12.75">12:45 PM</option>
                                <option value="12.83">12:50 PM</option>
                                <option value="12.91">12:55 PM</option>
                                <option value="13.00">13:00 PM - o'clock</option>
                                <option value="13.8">13:05 PM</option>
                                <option value="13.16">13:10 PM</option>
                                <option value="13.25">13:15 PM</option>
                                <option value="13.33">13:20 PM</option>
                                <option value="13.41">13:25 PM</option>
                                <option value="13.50">13:30 PM</option>
                                <option value="13.58">13:35 PM</option>
                                <option value="13.66">13:40 PM</option>
                                <option value="13.75">13:45 PM</option>
                                <option value="13.83">13:50 PM</option>
                                <option value="13.91">13:55 PM</option>
                                <option value="14.00">14:00 PM - o'clock</option>
                                <option value="14.8">14:05 PM</option>
                                <option value="14.16">14:10 PM</option>
                                <option value="14.25">14:15 PM</option>
                                <option value="14.33">14:20 PM</option>
                                <option value="14.41">14:25 PM</option>
                                <option value="14.50">14:30 PM</option>
                                <option value="14.58">14:35 PM</option>
                                <option value="14.66">14:40 PM</option>
                                <option value="14.75">14:45 PM</option>
                                <option value="14.83">14:50 PM</option>
                                <option value="14.91">14:55 PM</option>
                                <option value="15.00">15:00 PM - o'clock</option>
                                <option value="15.8">15:05 PM</option>
                                <option value="15.16">15:10 PM</option>
                                <option value="15.25">15:15 PM</option>
                                <option value="15.33">15:20 PM</option>
                                <option value="15.41">15:25 PM</option>
                                <option value="15.50">15:30 PM</option>
                                <option value="15.58">15:35 PM</option>
                                <option value="15.66">15:40 PM</option>
                                <option value="15.75">15:45 PM</option>
                                <option value="15.83">15:50 PM</option>
                                <option value="15.91">15:55 PM</option>
                                <option value="16.00">16:00 PM - o'clock</option>
                                <option value="16.8">16:05 PM</option>
                                <option value="16.16">16:10 PM</option>
                                <option value="16.25">16:15 PM</option>
                                <option value="16.33">16:20 PM</option>
                                <option value="16.41">16:25 PM</option>
                                <option value="16.50">16:30 PM</option>
                                <option value="16.58">16:35 PM</option>
                                <option value="16.66">16:40 PM</option>
                                <option value="16.75">16:45 PM</option>
                                <option value="16.83">16:50 PM</option>
                                <option value="16.91">16:55 PM</option>
                                <option value="17.00">17:00 PM - o'clock</option>
                                <option value="17.8">17:05 PM</option>
                                <option value="17.16">17:10 PM</option>
                                <option value="17.25">17:15 PM</option>
                                <option value="17.33">17:20 PM</option>
                                <option value="17.41">17:25 PM</option>
                                <option value="17.50">17:30 PM</option>
                                <option value="17.58">17:35 PM</option>
                                <option value="17.66">17:40 PM</option>
                                <option value="17.75">17:45 PM</option>
                                <option value="17.83">17:50 PM</option>
                                <option value="17.91">17:55 PM</option>
                                <option value="18.00">18:00 PM - Normal</option>
                                <option value="18.8">18:05 PM</option>
                                <option value="18.16">18:10 PM</option>
                                <option value="18.25">18:15 PM</option>
                                <option value="18.33">18:20 PM</option>
                                <option value="18.41">18:25 PM</option>
                                <option value="18.50">18:30 PM</option>
                                <option value="18.58">18:35 PM</option>
                                <option value="18.66">18:40 PM</option>
                                <option value="18.75">18:45 PM</option>
                                <option value="18.83">18:50 PM</option>
                                <option value="18.91">18:55 PM</option>
                                <option value="19.00">19:00 PM - o'clock</option>
                                <option value="19.8">19:05 PM</option>
                                <option value="19.16">19:10 PM</option>
                                <option value="19.25">19:15 PM</option>
                                <option value="19.33">19:20 PM</option>
                                <option value="19.41">19:25 PM</option>
                                <option value="19.50">19:30 PM</option>
                                <option value="19.58">19:35 PM</option>
                                <option value="19.66">19:40 PM</option>
                                <option value="19.75">19:45 PM</option>
                                <option value="19.83">19:50 PM</option>
                                <option value="19.91">19:55 PM</option>
                                <option value="20.00">20:00 PM - o'clock</option>
                                <option value="20.8">20:05 PM</option>
                                <option value="20.16">20:10 PM</option>
                                <option value="20.25">20:15 PM</option>
                                <option value="20.33">20:20 PM</option>
                                <option value="20.41">20:25 PM</option>
                                <option value="20.50">20:30 PM</option>
                                <option value="20.58">20:35 PM</option>
                                <option value="20.66">20:40 PM</option>
                                <option value="20.75">20:45 PM</option>
                                <option value="20.83">20:50 PM</option>
                                <option value="20.91">20:55 PM</option>
                                <option value="21.00">21:00 PM - o'clock</option>
                                <option value="21.8">21:05 PM</option>
                                <option value="21.16">21:10 PM</option>
                                <option value="21.25">21:15 PM</option>
                                <option value="21.33">21:20 PM</option>
                                <option value="21.41">21:25 PM</option>
                                <option value="21.50">21:30 PM</option>
                                <option value="21.58">21:35 PM</option>
                                <option value="21.66">21:40 PM</option>
                                <option value="21.75">21:45 PM</option>
                                <option value="21.83">21:50 PM</option>
                                <option value="21.91">21:55 PM</option>
                                <option value="22.00">22:00 PM - o'clock</option>
                                <option value="22.8">22:05 PM</option>
                                <option value="22.16">22:10 PM</option>
                                <option value="22.25">22:15 PM</option>
                                <option value="22.33">22:20 PM</option>
                                <option value="22.41">22:25 PM</option>
                                <option value="22.50">22:30 PM</option>
                                <option value="22.58">22:35 PM</option>
                                <option value="22.66">22:40 PM</option>
                                <option value="22.75">22:45 PM</option>
                                <option value="22.83">22:50 PM</option>
                                <option value="22.91">22:55 PM</option>
                                <option value="23.00">23:00 PM - o'clock</option>
                                <option value="23.8">23:05 PM</option>
                                <option value="23.16">23:10 PM</option>
                                <option value="23.25">23:15 PM</option>
                                <option value="23.33">23:20 PM</option>
                                <option value="23.41">23:25 PM</option>
                                <option value="23.50">23:30 PM</option>
                                <option value="23.58">23:35 PM</option>
                                <option value="23.66">23:40 PM</option>
                                <option value="23.75">23:45 PM</option>
                                <option value="23.83">23:50 PM</option>
                                <option value="23.91">23:55 PM</option>
                                <option value="24.00">24:00 PM - o'clock</option>
                                <option value="24.8">15:05 PM</option>
                                <option value="24.16">24:10 PM</option>
                                <option value="24.25">24:15 PM</option>
                                <option value="24.33">24:20 PM</option>
                                <option value="24.41">24:25 PM</option>
                                <option value="24.50">24:30 PM</option>
                                <option value="24.58">24:35 PM</option>
                                <option value="24.66">24:40 PM</option>
                                <option value="24.75">24:45 PM</option>
                                <option value="24.83">24:50 PM</option>
                                <option value="24.91">24:55 PM</option>
                            </select>
                            {data15.map((datas, index) =>{
                              return<div key={index}>
                                        <p>{datas.endhour}</p>
                                    </div>
                                })}
                        </div>
                        <div className="col-md-2">
                            <select name="type15" onChange={e =>  settype15(e.target.value)} className="form-control" required>
                                <option value="">Select Type</option>
                                <option value="Normal">Normal</option>
                                <option value="SJUK">SJUK</option>
                                <option value="Holiday">Holiday</option>
                            </select>
                            {data15.map((datas, index) =>{
                              return<div key={index}>
                                        <p>{datas.type}</p>
                                    </div>
                                })}
                          </div>
                          <div className="col-md-2"> 
                              <button type='submit' className="btn btn-primary">Submit</button>
                        </div>
                      </form>
                  </div>
                  <div className="d-flex flex-column p-2">
                     <form action="" className="row"  onSubmit={handleSubmit16}>
                        <div className="col-md-1"> 16 </div>
                        <div className="col-md-1"> Wednesday  </div>
                        <div className="col-md-1" > 
                            {data16.map((types, index) =>{
                              return<tr key={index}>
                                   <p style={types.type === "Normal" ? {backgroundColor:'rgba(7, 190, 7, 0.648)', color:'#000', padding:'5px 10px'} : 
                                     (types.type === "Holiday" ? {backgroundColor:'rgba(255, 0, 0, 0.438)', color:'#000', padding:'5px 10px'} : 
                                      {backgroundColor:'#008cff5a', color:'#000', padding:'5px 10px'}) }> {types.type}</p>
                                    </tr>
                                })}
                        </div>
                        <div className="col-md-2">
                            <select name="starthour16" onChange={e =>  setStart16(e.target.value)} className="form-control">
                                <option value="">Start Time</option>
                                <option value="05.00">05:00 AM</option>
                                <option value="06.00">06:00 AM</option>
                                <option value="06.8">06:05 AM</option>
                                <option value="06.16">06:10 AM</option>
                                <option value="06.25">06:15 AM</option>
                                <option value="06.33">06:20 AM</option>
                                <option value="06.41">06:25 AM</option>
                                <option value="06.50">06:30 AM</option>
                                <option value="06.58">06:35 AM</option>
                                <option value="06.66">06:40 AM</option>
                                <option value="06.75">06:45 AM</option>
                                <option value="06.83">06:50 AM</option>
                                <option value="06.91">06:55 AM</option>
                                <option value="07.00">07:00 AM - Normal</option>
                                <option value="07.8">07:05 AM</option>
                                <option value="07.16">07:10 AM</option>
                                <option value="07.25">07:15 AM</option>
                                <option value="07.33">07:20 AM</option>
                                <option value="07.41">07:25 AM</option>
                                <option value="07.50">07:30 AM</option>
                                <option value="07.58">07:35 AM</option>
                                <option value="07.66">07:40 AM</option>
                                <option value="07.75">07:45 AM</option>
                                <option value="07.83">07:50 AM</option>
                                <option value="07.91">07:55 AM</option>
                                <option value="08.00">08:00 AM - o'clock</option>
                                <option value="08.8">08:05 AM</option>
                                <option value="08.16">08:10 AM</option>
                                <option value="08.25">08:15 AM</option>
                                <option value="08.33">08:20 AM</option>
                                <option value="08.41">08:25 AM</option>
                                <option value="08.50">08:30 AM</option>
                                <option value="08.58">08:35 AM</option>
                                <option value="08.66">08:40 AM</option>
                                <option value="08.75">08:45 AM</option>
                                <option value="08.83">08:50 AM</option>
                                <option value="08.91">08:55 AM</option>
                                <option value="09.00">09:00 AM - o'clock</option>
                                <option value="09.8">09:05 AM</option>
                                <option value="09.16">09:10 AM</option>
                                <option value="09.25">09:15 AM</option>
                                <option value="09.33">09:20 AM</option>
                                <option value="09.41">09:25 AM</option>
                                <option value="09.50">09:30 AM</option>
                                <option value="09.58">09:35 AM</option>
                                <option value="09.66">09:40 AM</option>
                                <option value="09.75">09:45 AM</option>
                                <option value="09.83">09:50 AM</option>
                                <option value="09.91">09:55 AM</option>
                                <option value="10.00">10:00 AM - o'clock</option>
                                <option value="10.8">10:05 AM</option>
                                <option value="10.16">10:10 AM</option>
                                <option value="10.25">10:15 AM</option>
                                <option value="10.33">10:20 AM</option>
                                <option value="10.41">10:25 AM</option>
                                <option value="10.50">10:30 AM</option>
                                <option value="10.58">10:35 AM</option>
                                <option value="10.66">10:40 AM</option>
                                <option value="10.75">10:45 AM</option>
                                <option value="10.83">10:50 AM</option>
                                <option value="10.91">10:55 AM</option>
                                <option value="11.00">11:00 AM - o'clock</option>
                                <option value="11.8">11:05 AM</option>
                                <option value="11.16">11:10 AM</option>
                                <option value="11.25">11:15 AM</option>
                                <option value="11.33">11:20 AM</option>
                                <option value="11.41">11:25 AM</option>
                                <option value="11.50">11:30 AM</option>
                                <option value="11.58">11:35 AM</option>
                                <option value="11.66">11:40 AM</option>
                                <option value="11.75">11:45 AM</option>
                                <option value="11.83">11:50 AM</option>
                                <option value="11.91">11:55 AM</option>
                                <option value="12.00">12:00 PM  - o'clock</option>
                                <option value="12.8">12:05 PM</option>
                                <option value="12.16">12:10 PM</option>
                                <option value="12.25">12:15 PM</option>
                                <option value="12.33">12:20 PM</option>
                                <option value="12.41">12:25 PM</option>
                                <option value="12.50">12:30 PM</option>
                                <option value="12.58">12:35 PM</option>
                                <option value="12.66">12:40 PM</option>
                                <option value="12.75">12:45 PM</option>
                                <option value="12.83">12:50 PM</option>
                                <option value="12.91">12:55 PM</option>
                                <option value="13.00">13:00 PM - o'clock</option>
                                <option value="13.8">13:05 PM</option>
                                <option value="13.16">13:10 PM</option>
                                <option value="13.25">13:15 PM</option>
                                <option value="13.33">13:20 PM</option>
                                <option value="13.41">13:25 PM</option>
                                <option value="13.50">13:30 PM</option>
                                <option value="13.58">13:35 PM</option>
                                <option value="13.66">13:40 PM</option>
                                <option value="13.75">13:45 PM</option>
                                <option value="13.83">13:50 PM</option>
                                <option value="13.91">13:55 PM</option>
                                <option value="14.00">14:00 PM - o'clock</option>
                                <option value="14.8">14:05 PM</option>
                                <option value="14.16">14:10 PM</option>
                                <option value="14.25">14:15 PM</option>
                                <option value="14.33">14:20 PM</option>
                                <option value="14.41">14:25 PM</option>
                                <option value="14.50">14:30 PM</option>
                                <option value="14.58">14:35 PM</option>
                                <option value="14.66">14:40 PM</option>
                                <option value="14.75">14:45 PM</option>
                                <option value="14.83">14:50 PM</option>
                                <option value="14.91">14:55 PM</option>
                                <option value="15.00">15:00 PM - o'clock</option>
                                <option value="15.8">15:05 PM</option>
                                <option value="15.16">15:10 PM</option>
                                <option value="15.25">15:15 PM</option>
                                <option value="15.33">15:20 PM</option>
                                <option value="15.41">15:25 PM</option>
                                <option value="15.50">15:30 PM</option>
                                <option value="15.58">15:35 PM</option>
                                <option value="15.66">15:40 PM</option>
                                <option value="15.75">15:45 PM</option>
                                <option value="15.83">15:50 PM</option>
                                <option value="15.91">15:55 PM</option>
                                <option value="16.00">16:00 PM - o'clock</option>
                                <option value="16.8">16:05 PM</option>
                                <option value="16.16">16:10 PM</option>
                                <option value="16.25">16:15 PM</option>
                                <option value="16.33">16:20 PM</option>
                                <option value="16.41">16:25 PM</option>
                                <option value="16.50">16:30 PM</option>
                                <option value="16.58">16:35 PM</option>
                                <option value="16.66">16:40 PM</option>
                                <option value="16.75">16:45 PM</option>
                                <option value="16.83">16:50 PM</option>
                                <option value="16.91">16:55 PM</option>
                                <option value="17.00">17:00 PM - o'clock</option>
                                <option value="17.8">17:05 PM</option>
                                <option value="17.16">17:10 PM</option>
                                <option value="17.25">17:15 PM</option>
                                <option value="17.33">17:20 PM</option>
                                <option value="17.41">17:25 PM</option>
                                <option value="17.50">17:30 PM</option>
                                <option value="17.58">17:35 PM</option>
                                <option value="17.66">17:40 PM</option>
                                <option value="17.75">17:45 PM</option>
                                <option value="17.83">17:50 PM</option>
                                <option value="17.91">17:55 PM</option>
                                <option value="18.00">18:00 PM - Normal</option>
                                <option value="18.8">18:05 PM</option>
                                <option value="18.16">18:10 PM</option>
                                <option value="18.25">18:15 PM</option>
                                <option value="18.33">18:20 PM</option>
                                <option value="18.41">18:25 PM</option>
                                <option value="18.50">18:30 PM</option>
                                <option value="18.58">18:35 PM</option>
                                <option value="18.66">18:40 PM</option>
                                <option value="18.75">18:45 PM</option>
                                <option value="18.83">18:50 PM</option>
                                <option value="18.91">18:55 PM</option>
                                <option value="19.00">19:00 PM - o'clock</option>
                                <option value="19.8">19:05 PM</option>
                                <option value="19.16">19:10 PM</option>
                                <option value="19.25">19:15 PM</option>
                                <option value="19.33">19:20 PM</option>
                                <option value="19.41">19:25 PM</option>
                                <option value="19.50">19:30 PM</option>
                                <option value="19.58">19:35 PM</option>
                                <option value="19.66">19:40 PM</option>
                                <option value="19.75">19:45 PM</option>
                                <option value="19.83">19:50 PM</option>
                                <option value="19.91">19:55 PM</option>
                                <option value="20.00">20:00 PM - o'clock</option>
                                <option value="20.8">20:05 PM</option>
                                <option value="20.16">20:10 PM</option>
                                <option value="20.25">20:15 PM</option>
                                <option value="20.33">20:20 PM</option>
                                <option value="20.41">20:25 PM</option>
                                <option value="20.50">20:30 PM</option>
                                <option value="20.58">20:35 PM</option>
                                <option value="20.66">20:40 PM</option>
                                <option value="20.75">20:45 PM</option>
                                <option value="20.83">20:50 PM</option>
                                <option value="20.91">20:55 PM</option>
                                <option value="21.00">21:00 PM - o'clock</option>
                                <option value="21.8">21:05 PM</option>
                                <option value="21.16">21:10 PM</option>
                                <option value="21.25">21:15 PM</option>
                                <option value="21.33">21:20 PM</option>
                                <option value="21.41">21:25 PM</option>
                                <option value="21.50">21:30 PM</option>
                                <option value="21.58">21:35 PM</option>
                                <option value="21.66">21:40 PM</option>
                                <option value="21.75">21:45 PM</option>
                                <option value="21.83">21:50 PM</option>
                                <option value="21.91">21:55 PM</option>
                                <option value="22.00">22:00 PM - o'clock</option>
                                <option value="22.8">22:05 PM</option>
                                <option value="22.16">22:10 PM</option>
                                <option value="22.25">22:15 PM</option>
                                <option value="22.33">22:20 PM</option>
                                <option value="22.41">22:25 PM</option>
                                <option value="22.50">22:30 PM</option>
                                <option value="22.58">22:35 PM</option>
                                <option value="22.66">22:40 PM</option>
                                <option value="22.75">22:45 PM</option>
                                <option value="22.83">22:50 PM</option>
                                <option value="22.91">22:55 PM</option>
                                <option value="23.00">23:00 PM - o'clock</option>
                                <option value="23.8">23:05 PM</option>
                                <option value="23.16">23:10 PM</option>
                                <option value="23.25">23:15 PM</option>
                                <option value="23.33">23:20 PM</option>
                                <option value="23.41">23:25 PM</option>
                                <option value="23.50">23:30 PM</option>
                                <option value="23.58">23:35 PM</option>
                                <option value="23.66">23:40 PM</option>
                                <option value="23.75">23:45 PM</option>
                                <option value="23.83">23:50 PM</option>
                                <option value="23.91">23:55 PM</option>
                                <option value="24.00">24:00 PM - o'clock</option>
                                <option value="24.8">15:05 PM</option>
                                <option value="24.16">24:10 PM</option>
                                <option value="24.25">24:15 PM</option>
                                <option value="24.33">24:20 PM</option>
                                <option value="24.41">24:25 PM</option>
                                <option value="24.50">24:30 PM</option>
                                <option value="24.58">24:35 PM</option>
                                <option value="24.66">24:40 PM</option>
                                <option value="24.75">24:45 PM</option>
                                <option value="24.83">24:50 PM</option>
                                <option value="24.91">24:55 PM</option>
                            </select>
                            {data16.map((datas, index) =>{
                              return<div key={index}>
                                        <p>{datas.starthour}</p>
                                    </div>
                                })}
                        </div>
                        <div className="col-md-2">
                            <select name="endhour16" onChange={e =>  setEnd16(e.target.value)} className="form-control">
                                <option value="">Finish Time</option>
                                <option value="05.00">05:00 AM</option>
                                <option value="06.00">06:00 AM</option>
                                <option value="06.8">06:05 AM</option>
                                <option value="06.16">06:10 AM</option>
                                <option value="06.25">06:15 AM</option>
                                <option value="06.33">06:20 AM</option>
                                <option value="06.41">06:25 AM</option>
                                <option value="06.50">06:30 AM</option>
                                <option value="06.58">06:35 AM</option>
                                <option value="06.66">06:40 AM</option>
                                <option value="06.75">06:45 AM</option>
                                <option value="06.83">06:50 AM</option>
                                <option value="06.91">06:55 AM</option>
                                <option value="07.00">07:00 AM - Normal</option>
                                <option value="07.8">07:05 AM</option>
                                <option value="07.16">07:10 AM</option>
                                <option value="07.25">07:15 AM</option>
                                <option value="07.33">07:20 AM</option>
                                <option value="07.41">07:25 AM</option>
                                <option value="07.50">07:30 AM</option>
                                <option value="07.58">07:35 AM</option>
                                <option value="07.66">07:40 AM</option>
                                <option value="07.75">07:45 AM</option>
                                <option value="07.83">07:50 AM</option>
                                <option value="07.91">07:55 AM</option>
                                <option value="08.00">08:00 AM - o'clock</option>
                                <option value="08.8">08:05 AM</option>
                                <option value="08.16">08:10 AM</option>
                                <option value="08.25">08:15 AM</option>
                                <option value="08.33">08:20 AM</option>
                                <option value="08.41">08:25 AM</option>
                                <option value="08.50">08:30 AM</option>
                                <option value="08.58">08:35 AM</option>
                                <option value="08.66">08:40 AM</option>
                                <option value="08.75">08:45 AM</option>
                                <option value="08.83">08:50 AM</option>
                                <option value="08.91">08:55 AM</option>
                                <option value="09.00">09:00 AM - o'clock</option>
                                <option value="09.8">09:05 AM</option>
                                <option value="09.16">09:10 AM</option>
                                <option value="09.25">09:15 AM</option>
                                <option value="09.33">09:20 AM</option>
                                <option value="09.41">09:25 AM</option>
                                <option value="09.50">09:30 AM</option>
                                <option value="09.58">09:35 AM</option>
                                <option value="09.66">09:40 AM</option>
                                <option value="09.75">09:45 AM</option>
                                <option value="09.83">09:50 AM</option>
                                <option value="09.91">09:55 AM</option>
                                <option value="10.00">10:00 AM - o'clock</option>
                                <option value="10.8">10:05 AM</option>
                                <option value="10.16">10:10 AM</option>
                                <option value="10.25">10:15 AM</option>
                                <option value="10.33">10:20 AM</option>
                                <option value="10.41">10:25 AM</option>
                                <option value="10.50">10:30 AM</option>
                                <option value="10.58">10:35 AM</option>
                                <option value="10.66">10:40 AM</option>
                                <option value="10.75">10:45 AM</option>
                                <option value="10.83">10:50 AM</option>
                                <option value="10.91">10:55 AM</option>
                                <option value="11.00">11:00 AM - o'clock</option>
                                <option value="11.8">11:05 AM</option>
                                <option value="11.16">11:10 AM</option>
                                <option value="11.25">11:15 AM</option>
                                <option value="11.33">11:20 AM</option>
                                <option value="11.41">11:25 AM</option>
                                <option value="11.50">11:30 AM</option>
                                <option value="11.58">11:35 AM</option>
                                <option value="11.66">11:40 AM</option>
                                <option value="11.75">11:45 AM</option>
                                <option value="11.83">11:50 AM</option>
                                <option value="11.91">11:55 AM</option>
                                <option value="12.00">12:00 PM  - o'clock</option>
                                <option value="12.8">12:05 PM</option>
                                <option value="12.16">12:10 PM</option>
                                <option value="12.25">12:15 PM</option>
                                <option value="12.33">12:20 PM</option>
                                <option value="12.41">12:25 PM</option>
                                <option value="12.50">12:30 PM</option>
                                <option value="12.58">12:35 PM</option>
                                <option value="12.66">12:40 PM</option>
                                <option value="12.75">12:45 PM</option>
                                <option value="12.83">12:50 PM</option>
                                <option value="12.91">12:55 PM</option>
                                <option value="13.00">13:00 PM - o'clock</option>
                                <option value="13.8">13:05 PM</option>
                                <option value="13.16">13:10 PM</option>
                                <option value="13.25">13:15 PM</option>
                                <option value="13.33">13:20 PM</option>
                                <option value="13.41">13:25 PM</option>
                                <option value="13.50">13:30 PM</option>
                                <option value="13.58">13:35 PM</option>
                                <option value="13.66">13:40 PM</option>
                                <option value="13.75">13:45 PM</option>
                                <option value="13.83">13:50 PM</option>
                                <option value="13.91">13:55 PM</option>
                                <option value="14.00">14:00 PM - o'clock</option>
                                <option value="14.8">14:05 PM</option>
                                <option value="14.16">14:10 PM</option>
                                <option value="14.25">14:15 PM</option>
                                <option value="14.33">14:20 PM</option>
                                <option value="14.41">14:25 PM</option>
                                <option value="14.50">14:30 PM</option>
                                <option value="14.58">14:35 PM</option>
                                <option value="14.66">14:40 PM</option>
                                <option value="14.75">14:45 PM</option>
                                <option value="14.83">14:50 PM</option>
                                <option value="14.91">14:55 PM</option>
                                <option value="15.00">15:00 PM - o'clock</option>
                                <option value="15.8">15:05 PM</option>
                                <option value="15.16">15:10 PM</option>
                                <option value="15.25">15:15 PM</option>
                                <option value="15.33">15:20 PM</option>
                                <option value="15.41">15:25 PM</option>
                                <option value="15.50">15:30 PM</option>
                                <option value="15.58">15:35 PM</option>
                                <option value="15.66">15:40 PM</option>
                                <option value="15.75">15:45 PM</option>
                                <option value="15.83">15:50 PM</option>
                                <option value="15.91">15:55 PM</option>
                                <option value="16.00">16:00 PM - o'clock</option>
                                <option value="16.8">16:05 PM</option>
                                <option value="16.16">16:10 PM</option>
                                <option value="16.25">16:15 PM</option>
                                <option value="16.33">16:20 PM</option>
                                <option value="16.41">16:25 PM</option>
                                <option value="16.50">16:30 PM</option>
                                <option value="16.58">16:35 PM</option>
                                <option value="16.66">16:40 PM</option>
                                <option value="16.75">16:45 PM</option>
                                <option value="16.83">16:50 PM</option>
                                <option value="16.91">16:55 PM</option>
                                <option value="17.00">17:00 PM - o'clock</option>
                                <option value="17.8">17:05 PM</option>
                                <option value="17.16">17:10 PM</option>
                                <option value="17.25">17:15 PM</option>
                                <option value="17.33">17:20 PM</option>
                                <option value="17.41">17:25 PM</option>
                                <option value="17.50">17:30 PM</option>
                                <option value="17.58">17:35 PM</option>
                                <option value="17.66">17:40 PM</option>
                                <option value="17.75">17:45 PM</option>
                                <option value="17.83">17:50 PM</option>
                                <option value="17.91">17:55 PM</option>
                                <option value="18.00">18:00 PM - Normal</option>
                                <option value="18.8">18:05 PM</option>
                                <option value="18.16">18:10 PM</option>
                                <option value="18.25">18:15 PM</option>
                                <option value="18.33">18:20 PM</option>
                                <option value="18.41">18:25 PM</option>
                                <option value="18.50">18:30 PM</option>
                                <option value="18.58">18:35 PM</option>
                                <option value="18.66">18:40 PM</option>
                                <option value="18.75">18:45 PM</option>
                                <option value="18.83">18:50 PM</option>
                                <option value="18.91">18:55 PM</option>
                                <option value="19.00">19:00 PM - o'clock</option>
                                <option value="19.8">19:05 PM</option>
                                <option value="19.16">19:10 PM</option>
                                <option value="19.25">19:15 PM</option>
                                <option value="19.33">19:20 PM</option>
                                <option value="19.41">19:25 PM</option>
                                <option value="19.50">19:30 PM</option>
                                <option value="19.58">19:35 PM</option>
                                <option value="19.66">19:40 PM</option>
                                <option value="19.75">19:45 PM</option>
                                <option value="19.83">19:50 PM</option>
                                <option value="19.91">19:55 PM</option>
                                <option value="20.00">20:00 PM - o'clock</option>
                                <option value="20.8">20:05 PM</option>
                                <option value="20.16">20:10 PM</option>
                                <option value="20.25">20:15 PM</option>
                                <option value="20.33">20:20 PM</option>
                                <option value="20.41">20:25 PM</option>
                                <option value="20.50">20:30 PM</option>
                                <option value="20.58">20:35 PM</option>
                                <option value="20.66">20:40 PM</option>
                                <option value="20.75">20:45 PM</option>
                                <option value="20.83">20:50 PM</option>
                                <option value="20.91">20:55 PM</option>
                                <option value="21.00">21:00 PM - o'clock</option>
                                <option value="21.8">21:05 PM</option>
                                <option value="21.16">21:10 PM</option>
                                <option value="21.25">21:15 PM</option>
                                <option value="21.33">21:20 PM</option>
                                <option value="21.41">21:25 PM</option>
                                <option value="21.50">21:30 PM</option>
                                <option value="21.58">21:35 PM</option>
                                <option value="21.66">21:40 PM</option>
                                <option value="21.75">21:45 PM</option>
                                <option value="21.83">21:50 PM</option>
                                <option value="21.91">21:55 PM</option>
                                <option value="22.00">22:00 PM - o'clock</option>
                                <option value="22.8">22:05 PM</option>
                                <option value="22.16">22:10 PM</option>
                                <option value="22.25">22:15 PM</option>
                                <option value="22.33">22:20 PM</option>
                                <option value="22.41">22:25 PM</option>
                                <option value="22.50">22:30 PM</option>
                                <option value="22.58">22:35 PM</option>
                                <option value="22.66">22:40 PM</option>
                                <option value="22.75">22:45 PM</option>
                                <option value="22.83">22:50 PM</option>
                                <option value="22.91">22:55 PM</option>
                                <option value="23.00">23:00 PM - o'clock</option>
                                <option value="23.8">23:05 PM</option>
                                <option value="23.16">23:10 PM</option>
                                <option value="23.25">23:15 PM</option>
                                <option value="23.33">23:20 PM</option>
                                <option value="23.41">23:25 PM</option>
                                <option value="23.50">23:30 PM</option>
                                <option value="23.58">23:35 PM</option>
                                <option value="23.66">23:40 PM</option>
                                <option value="23.75">23:45 PM</option>
                                <option value="23.83">23:50 PM</option>
                                <option value="23.91">23:55 PM</option>
                                <option value="24.00">24:00 PM - o'clock</option>
                                <option value="24.8">15:05 PM</option>
                                <option value="24.16">24:10 PM</option>
                                <option value="24.25">24:15 PM</option>
                                <option value="24.33">24:20 PM</option>
                                <option value="24.41">24:25 PM</option>
                                <option value="24.50">24:30 PM</option>
                                <option value="24.58">24:35 PM</option>
                                <option value="24.66">24:40 PM</option>
                                <option value="24.75">24:45 PM</option>
                                <option value="24.83">24:50 PM</option>
                                <option value="24.91">24:55 PM</option>
                            </select>
                            {data16.map((datas, index) =>{
                              return<div key={index}>
                                        <p>{datas.endhour}</p>
                                    </div>
                                })}
                        </div>
                        <div className="col-md-2">
                            <select name="type16" onChange={e =>  settype16(e.target.value)} className="form-control" required>
                                <option value="">Select Type</option>
                                <option value="Normal">Normal</option>
                                <option value="SJUK">SJUK</option>
                                <option value="Holiday">Holiday</option>
                            </select>
                            {data16.map((datas, index) =>{
                              return<div key={index}>
                                        <p>{datas.type}</p>
                                    </div>
                                })}
                          </div>
                          <div className="col-md-2"> 
                              <button type='submit' className="btn btn-primary">Submit</button>
                        </div>
                      </form>
                  </div>
                  <div className="d-flex flex-column p-2">
                     <form action="" className="row"  onSubmit={handleSubmit17}>
                        <div className="col-md-1"> 17 </div>
                        <div className="col-md-1"> Thursday  </div>
                        <div className="col-md-1" > 
                            {data17.map((types, index) =>{
                              return<tr key={index}>
                                   <p style={types.type === "Normal" ? {backgroundColor:'rgba(7, 190, 7, 0.648)', color:'#000', padding:'5px 10px'} : 
                                     (types.type === "Holiday" ? {backgroundColor:'rgba(255, 0, 0, 0.438)', color:'#000', padding:'5px 10px'} : 
                                      {backgroundColor:'#008cff5a', color:'#000', padding:'5px 10px'}) }> {types.type}</p>
                                    </tr>
                                })}
                        </div>
                        <div className="col-md-2">
                            <select name="starthour17" onChange={e =>  setStart17(e.target.value)} className="form-control">
                                <option value="">Start Time</option>
                                <option value="05.00">05:00 AM</option>
                                <option value="06.00">06:00 AM</option>
                                <option value="06.8">06:05 AM</option>
                                <option value="06.16">06:10 AM</option>
                                <option value="06.25">06:15 AM</option>
                                <option value="06.33">06:20 AM</option>
                                <option value="06.41">06:25 AM</option>
                                <option value="06.50">06:30 AM</option>
                                <option value="06.58">06:35 AM</option>
                                <option value="06.66">06:40 AM</option>
                                <option value="06.75">06:45 AM</option>
                                <option value="06.83">06:50 AM</option>
                                <option value="06.91">06:55 AM</option>
                                <option value="07.00">07:00 AM - Normal</option>
                                <option value="07.8">07:05 AM</option>
                                <option value="07.16">07:10 AM</option>
                                <option value="07.25">07:15 AM</option>
                                <option value="07.33">07:20 AM</option>
                                <option value="07.41">07:25 AM</option>
                                <option value="07.50">07:30 AM</option>
                                <option value="07.58">07:35 AM</option>
                                <option value="07.66">07:40 AM</option>
                                <option value="07.75">07:45 AM</option>
                                <option value="07.83">07:50 AM</option>
                                <option value="07.91">07:55 AM</option>
                                <option value="08.00">08:00 AM - o'clock</option>
                                <option value="08.8">08:05 AM</option>
                                <option value="08.16">08:10 AM</option>
                                <option value="08.25">08:15 AM</option>
                                <option value="08.33">08:20 AM</option>
                                <option value="08.41">08:25 AM</option>
                                <option value="08.50">08:30 AM</option>
                                <option value="08.58">08:35 AM</option>
                                <option value="08.66">08:40 AM</option>
                                <option value="08.75">08:45 AM</option>
                                <option value="08.83">08:50 AM</option>
                                <option value="08.91">08:55 AM</option>
                                <option value="09.00">09:00 AM - o'clock</option>
                                <option value="09.8">09:05 AM</option>
                                <option value="09.16">09:10 AM</option>
                                <option value="09.25">09:15 AM</option>
                                <option value="09.33">09:20 AM</option>
                                <option value="09.41">09:25 AM</option>
                                <option value="09.50">09:30 AM</option>
                                <option value="09.58">09:35 AM</option>
                                <option value="09.66">09:40 AM</option>
                                <option value="09.75">09:45 AM</option>
                                <option value="09.83">09:50 AM</option>
                                <option value="09.91">09:55 AM</option>
                                <option value="10.00">10:00 AM - o'clock</option>
                                <option value="10.8">10:05 AM</option>
                                <option value="10.16">10:10 AM</option>
                                <option value="10.25">10:15 AM</option>
                                <option value="10.33">10:20 AM</option>
                                <option value="10.41">10:25 AM</option>
                                <option value="10.50">10:30 AM</option>
                                <option value="10.58">10:35 AM</option>
                                <option value="10.66">10:40 AM</option>
                                <option value="10.75">10:45 AM</option>
                                <option value="10.83">10:50 AM</option>
                                <option value="10.91">10:55 AM</option>
                                <option value="11.00">11:00 AM - o'clock</option>
                                <option value="11.8">11:05 AM</option>
                                <option value="11.16">11:10 AM</option>
                                <option value="11.25">11:15 AM</option>
                                <option value="11.33">11:20 AM</option>
                                <option value="11.41">11:25 AM</option>
                                <option value="11.50">11:30 AM</option>
                                <option value="11.58">11:35 AM</option>
                                <option value="11.66">11:40 AM</option>
                                <option value="11.75">11:45 AM</option>
                                <option value="11.83">11:50 AM</option>
                                <option value="11.91">11:55 AM</option>
                                <option value="12.00">12:00 PM  - o'clock</option>
                                <option value="12.8">12:05 PM</option>
                                <option value="12.16">12:10 PM</option>
                                <option value="12.25">12:15 PM</option>
                                <option value="12.33">12:20 PM</option>
                                <option value="12.41">12:25 PM</option>
                                <option value="12.50">12:30 PM</option>
                                <option value="12.58">12:35 PM</option>
                                <option value="12.66">12:40 PM</option>
                                <option value="12.75">12:45 PM</option>
                                <option value="12.83">12:50 PM</option>
                                <option value="12.91">12:55 PM</option>
                                <option value="13.00">13:00 PM - o'clock</option>
                                <option value="13.8">13:05 PM</option>
                                <option value="13.16">13:10 PM</option>
                                <option value="13.25">13:15 PM</option>
                                <option value="13.33">13:20 PM</option>
                                <option value="13.41">13:25 PM</option>
                                <option value="13.50">13:30 PM</option>
                                <option value="13.58">13:35 PM</option>
                                <option value="13.66">13:40 PM</option>
                                <option value="13.75">13:45 PM</option>
                                <option value="13.83">13:50 PM</option>
                                <option value="13.91">13:55 PM</option>
                                <option value="14.00">14:00 PM - o'clock</option>
                                <option value="14.8">14:05 PM</option>
                                <option value="14.16">14:10 PM</option>
                                <option value="14.25">14:15 PM</option>
                                <option value="14.33">14:20 PM</option>
                                <option value="14.41">14:25 PM</option>
                                <option value="14.50">14:30 PM</option>
                                <option value="14.58">14:35 PM</option>
                                <option value="14.66">14:40 PM</option>
                                <option value="14.75">14:45 PM</option>
                                <option value="14.83">14:50 PM</option>
                                <option value="14.91">14:55 PM</option>
                                <option value="15.00">15:00 PM - o'clock</option>
                                <option value="15.8">15:05 PM</option>
                                <option value="15.16">15:10 PM</option>
                                <option value="15.25">15:15 PM</option>
                                <option value="15.33">15:20 PM</option>
                                <option value="15.41">15:25 PM</option>
                                <option value="15.50">15:30 PM</option>
                                <option value="15.58">15:35 PM</option>
                                <option value="15.66">15:40 PM</option>
                                <option value="15.75">15:45 PM</option>
                                <option value="15.83">15:50 PM</option>
                                <option value="15.91">15:55 PM</option>
                                <option value="16.00">16:00 PM - o'clock</option>
                                <option value="16.8">16:05 PM</option>
                                <option value="16.16">16:10 PM</option>
                                <option value="16.25">16:15 PM</option>
                                <option value="16.33">16:20 PM</option>
                                <option value="16.41">16:25 PM</option>
                                <option value="16.50">16:30 PM</option>
                                <option value="16.58">16:35 PM</option>
                                <option value="16.66">16:40 PM</option>
                                <option value="16.75">16:45 PM</option>
                                <option value="16.83">16:50 PM</option>
                                <option value="16.91">16:55 PM</option>
                                <option value="17.00">17:00 PM - o'clock</option>
                                <option value="17.8">17:05 PM</option>
                                <option value="17.16">17:10 PM</option>
                                <option value="17.25">17:15 PM</option>
                                <option value="17.33">17:20 PM</option>
                                <option value="17.41">17:25 PM</option>
                                <option value="17.50">17:30 PM</option>
                                <option value="17.58">17:35 PM</option>
                                <option value="17.66">17:40 PM</option>
                                <option value="17.75">17:45 PM</option>
                                <option value="17.83">17:50 PM</option>
                                <option value="17.91">17:55 PM</option>
                                <option value="18.00">18:00 PM - Normal</option>
                                <option value="18.8">18:05 PM</option>
                                <option value="18.16">18:10 PM</option>
                                <option value="18.25">18:15 PM</option>
                                <option value="18.33">18:20 PM</option>
                                <option value="18.41">18:25 PM</option>
                                <option value="18.50">18:30 PM</option>
                                <option value="18.58">18:35 PM</option>
                                <option value="18.66">18:40 PM</option>
                                <option value="18.75">18:45 PM</option>
                                <option value="18.83">18:50 PM</option>
                                <option value="18.91">18:55 PM</option>
                                <option value="19.00">19:00 PM - o'clock</option>
                                <option value="19.8">19:05 PM</option>
                                <option value="19.16">19:10 PM</option>
                                <option value="19.25">19:15 PM</option>
                                <option value="19.33">19:20 PM</option>
                                <option value="19.41">19:25 PM</option>
                                <option value="19.50">19:30 PM</option>
                                <option value="19.58">19:35 PM</option>
                                <option value="19.66">19:40 PM</option>
                                <option value="19.75">19:45 PM</option>
                                <option value="19.83">19:50 PM</option>
                                <option value="19.91">19:55 PM</option>
                                <option value="20.00">20:00 PM - o'clock</option>
                                <option value="20.8">20:05 PM</option>
                                <option value="20.16">20:10 PM</option>
                                <option value="20.25">20:15 PM</option>
                                <option value="20.33">20:20 PM</option>
                                <option value="20.41">20:25 PM</option>
                                <option value="20.50">20:30 PM</option>
                                <option value="20.58">20:35 PM</option>
                                <option value="20.66">20:40 PM</option>
                                <option value="20.75">20:45 PM</option>
                                <option value="20.83">20:50 PM</option>
                                <option value="20.91">20:55 PM</option>
                                <option value="21.00">21:00 PM - o'clock</option>
                                <option value="21.8">21:05 PM</option>
                                <option value="21.16">21:10 PM</option>
                                <option value="21.25">21:15 PM</option>
                                <option value="21.33">21:20 PM</option>
                                <option value="21.41">21:25 PM</option>
                                <option value="21.50">21:30 PM</option>
                                <option value="21.58">21:35 PM</option>
                                <option value="21.66">21:40 PM</option>
                                <option value="21.75">21:45 PM</option>
                                <option value="21.83">21:50 PM</option>
                                <option value="21.91">21:55 PM</option>
                                <option value="22.00">22:00 PM - o'clock</option>
                                <option value="22.8">22:05 PM</option>
                                <option value="22.16">22:10 PM</option>
                                <option value="22.25">22:15 PM</option>
                                <option value="22.33">22:20 PM</option>
                                <option value="22.41">22:25 PM</option>
                                <option value="22.50">22:30 PM</option>
                                <option value="22.58">22:35 PM</option>
                                <option value="22.66">22:40 PM</option>
                                <option value="22.75">22:45 PM</option>
                                <option value="22.83">22:50 PM</option>
                                <option value="22.91">22:55 PM</option>
                                <option value="23.00">23:00 PM - o'clock</option>
                                <option value="23.8">23:05 PM</option>
                                <option value="23.16">23:10 PM</option>
                                <option value="23.25">23:15 PM</option>
                                <option value="23.33">23:20 PM</option>
                                <option value="23.41">23:25 PM</option>
                                <option value="23.50">23:30 PM</option>
                                <option value="23.58">23:35 PM</option>
                                <option value="23.66">23:40 PM</option>
                                <option value="23.75">23:45 PM</option>
                                <option value="23.83">23:50 PM</option>
                                <option value="23.91">23:55 PM</option>
                                <option value="24.00">24:00 PM - o'clock</option>
                                <option value="24.8">15:05 PM</option>
                                <option value="24.16">24:10 PM</option>
                                <option value="24.25">24:15 PM</option>
                                <option value="24.33">24:20 PM</option>
                                <option value="24.41">24:25 PM</option>
                                <option value="24.50">24:30 PM</option>
                                <option value="24.58">24:35 PM</option>
                                <option value="24.66">24:40 PM</option>
                                <option value="24.75">24:45 PM</option>
                                <option value="24.83">24:50 PM</option>
                                <option value="24.91">24:55 PM</option>
                            </select>
                            {data17.map((datas, index) =>{
                              return<div key={index}>
                                        <p>{datas.starthour}</p>
                                    </div>
                                })}
                        </div>
                        <div className="col-md-2">
                            <select name="endhour17" onChange={e =>  setEnd17(e.target.value)} className="form-control">
                                <option value="">Finish Time</option>
                                <option value="05.00">05:00 AM</option>
                                <option value="06.00">06:00 AM</option>
                                <option value="06.8">06:05 AM</option>
                                <option value="06.16">06:10 AM</option>
                                <option value="06.25">06:15 AM</option>
                                <option value="06.33">06:20 AM</option>
                                <option value="06.41">06:25 AM</option>
                                <option value="06.50">06:30 AM</option>
                                <option value="06.58">06:35 AM</option>
                                <option value="06.66">06:40 AM</option>
                                <option value="06.75">06:45 AM</option>
                                <option value="06.83">06:50 AM</option>
                                <option value="06.91">06:55 AM</option>
                                <option value="07.00">07:00 AM - Normal</option>
                                <option value="07.8">07:05 AM</option>
                                <option value="07.16">07:10 AM</option>
                                <option value="07.25">07:15 AM</option>
                                <option value="07.33">07:20 AM</option>
                                <option value="07.41">07:25 AM</option>
                                <option value="07.50">07:30 AM</option>
                                <option value="07.58">07:35 AM</option>
                                <option value="07.66">07:40 AM</option>
                                <option value="07.75">07:45 AM</option>
                                <option value="07.83">07:50 AM</option>
                                <option value="07.91">07:55 AM</option>
                                <option value="08.00">08:00 AM - o'clock</option>
                                <option value="08.8">08:05 AM</option>
                                <option value="08.16">08:10 AM</option>
                                <option value="08.25">08:15 AM</option>
                                <option value="08.33">08:20 AM</option>
                                <option value="08.41">08:25 AM</option>
                                <option value="08.50">08:30 AM</option>
                                <option value="08.58">08:35 AM</option>
                                <option value="08.66">08:40 AM</option>
                                <option value="08.75">08:45 AM</option>
                                <option value="08.83">08:50 AM</option>
                                <option value="08.91">08:55 AM</option>
                                <option value="09.00">09:00 AM - o'clock</option>
                                <option value="09.8">09:05 AM</option>
                                <option value="09.16">09:10 AM</option>
                                <option value="09.25">09:15 AM</option>
                                <option value="09.33">09:20 AM</option>
                                <option value="09.41">09:25 AM</option>
                                <option value="09.50">09:30 AM</option>
                                <option value="09.58">09:35 AM</option>
                                <option value="09.66">09:40 AM</option>
                                <option value="09.75">09:45 AM</option>
                                <option value="09.83">09:50 AM</option>
                                <option value="09.91">09:55 AM</option>
                                <option value="10.00">10:00 AM - o'clock</option>
                                <option value="10.8">10:05 AM</option>
                                <option value="10.16">10:10 AM</option>
                                <option value="10.25">10:15 AM</option>
                                <option value="10.33">10:20 AM</option>
                                <option value="10.41">10:25 AM</option>
                                <option value="10.50">10:30 AM</option>
                                <option value="10.58">10:35 AM</option>
                                <option value="10.66">10:40 AM</option>
                                <option value="10.75">10:45 AM</option>
                                <option value="10.83">10:50 AM</option>
                                <option value="10.91">10:55 AM</option>
                                <option value="11.00">11:00 AM - o'clock</option>
                                <option value="11.8">11:05 AM</option>
                                <option value="11.16">11:10 AM</option>
                                <option value="11.25">11:15 AM</option>
                                <option value="11.33">11:20 AM</option>
                                <option value="11.41">11:25 AM</option>
                                <option value="11.50">11:30 AM</option>
                                <option value="11.58">11:35 AM</option>
                                <option value="11.66">11:40 AM</option>
                                <option value="11.75">11:45 AM</option>
                                <option value="11.83">11:50 AM</option>
                                <option value="11.91">11:55 AM</option>
                                <option value="12.00">12:00 PM  - o'clock</option>
                                <option value="12.8">12:05 PM</option>
                                <option value="12.16">12:10 PM</option>
                                <option value="12.25">12:15 PM</option>
                                <option value="12.33">12:20 PM</option>
                                <option value="12.41">12:25 PM</option>
                                <option value="12.50">12:30 PM</option>
                                <option value="12.58">12:35 PM</option>
                                <option value="12.66">12:40 PM</option>
                                <option value="12.75">12:45 PM</option>
                                <option value="12.83">12:50 PM</option>
                                <option value="12.91">12:55 PM</option>
                                <option value="13.00">13:00 PM - o'clock</option>
                                <option value="13.8">13:05 PM</option>
                                <option value="13.16">13:10 PM</option>
                                <option value="13.25">13:15 PM</option>
                                <option value="13.33">13:20 PM</option>
                                <option value="13.41">13:25 PM</option>
                                <option value="13.50">13:30 PM</option>
                                <option value="13.58">13:35 PM</option>
                                <option value="13.66">13:40 PM</option>
                                <option value="13.75">13:45 PM</option>
                                <option value="13.83">13:50 PM</option>
                                <option value="13.91">13:55 PM</option>
                                <option value="14.00">14:00 PM - o'clock</option>
                                <option value="14.8">14:05 PM</option>
                                <option value="14.16">14:10 PM</option>
                                <option value="14.25">14:15 PM</option>
                                <option value="14.33">14:20 PM</option>
                                <option value="14.41">14:25 PM</option>
                                <option value="14.50">14:30 PM</option>
                                <option value="14.58">14:35 PM</option>
                                <option value="14.66">14:40 PM</option>
                                <option value="14.75">14:45 PM</option>
                                <option value="14.83">14:50 PM</option>
                                <option value="14.91">14:55 PM</option>
                                <option value="15.00">15:00 PM - o'clock</option>
                                <option value="15.8">15:05 PM</option>
                                <option value="15.16">15:10 PM</option>
                                <option value="15.25">15:15 PM</option>
                                <option value="15.33">15:20 PM</option>
                                <option value="15.41">15:25 PM</option>
                                <option value="15.50">15:30 PM</option>
                                <option value="15.58">15:35 PM</option>
                                <option value="15.66">15:40 PM</option>
                                <option value="15.75">15:45 PM</option>
                                <option value="15.83">15:50 PM</option>
                                <option value="15.91">15:55 PM</option>
                                <option value="16.00">16:00 PM - o'clock</option>
                                <option value="16.8">16:05 PM</option>
                                <option value="16.16">16:10 PM</option>
                                <option value="16.25">16:15 PM</option>
                                <option value="16.33">16:20 PM</option>
                                <option value="16.41">16:25 PM</option>
                                <option value="16.50">16:30 PM</option>
                                <option value="16.58">16:35 PM</option>
                                <option value="16.66">16:40 PM</option>
                                <option value="16.75">16:45 PM</option>
                                <option value="16.83">16:50 PM</option>
                                <option value="16.91">16:55 PM</option>
                                <option value="17.00">17:00 PM - o'clock</option>
                                <option value="17.8">17:05 PM</option>
                                <option value="17.16">17:10 PM</option>
                                <option value="17.25">17:15 PM</option>
                                <option value="17.33">17:20 PM</option>
                                <option value="17.41">17:25 PM</option>
                                <option value="17.50">17:30 PM</option>
                                <option value="17.58">17:35 PM</option>
                                <option value="17.66">17:40 PM</option>
                                <option value="17.75">17:45 PM</option>
                                <option value="17.83">17:50 PM</option>
                                <option value="17.91">17:55 PM</option>
                                <option value="18.00">18:00 PM - Normal</option>
                                <option value="18.8">18:05 PM</option>
                                <option value="18.16">18:10 PM</option>
                                <option value="18.25">18:15 PM</option>
                                <option value="18.33">18:20 PM</option>
                                <option value="18.41">18:25 PM</option>
                                <option value="18.50">18:30 PM</option>
                                <option value="18.58">18:35 PM</option>
                                <option value="18.66">18:40 PM</option>
                                <option value="18.75">18:45 PM</option>
                                <option value="18.83">18:50 PM</option>
                                <option value="18.91">18:55 PM</option>
                                <option value="19.00">19:00 PM - o'clock</option>
                                <option value="19.8">19:05 PM</option>
                                <option value="19.16">19:10 PM</option>
                                <option value="19.25">19:15 PM</option>
                                <option value="19.33">19:20 PM</option>
                                <option value="19.41">19:25 PM</option>
                                <option value="19.50">19:30 PM</option>
                                <option value="19.58">19:35 PM</option>
                                <option value="19.66">19:40 PM</option>
                                <option value="19.75">19:45 PM</option>
                                <option value="19.83">19:50 PM</option>
                                <option value="19.91">19:55 PM</option>
                                <option value="20.00">20:00 PM - o'clock</option>
                                <option value="20.8">20:05 PM</option>
                                <option value="20.16">20:10 PM</option>
                                <option value="20.25">20:15 PM</option>
                                <option value="20.33">20:20 PM</option>
                                <option value="20.41">20:25 PM</option>
                                <option value="20.50">20:30 PM</option>
                                <option value="20.58">20:35 PM</option>
                                <option value="20.66">20:40 PM</option>
                                <option value="20.75">20:45 PM</option>
                                <option value="20.83">20:50 PM</option>
                                <option value="20.91">20:55 PM</option>
                                <option value="21.00">21:00 PM - o'clock</option>
                                <option value="21.8">21:05 PM</option>
                                <option value="21.16">21:10 PM</option>
                                <option value="21.25">21:15 PM</option>
                                <option value="21.33">21:20 PM</option>
                                <option value="21.41">21:25 PM</option>
                                <option value="21.50">21:30 PM</option>
                                <option value="21.58">21:35 PM</option>
                                <option value="21.66">21:40 PM</option>
                                <option value="21.75">21:45 PM</option>
                                <option value="21.83">21:50 PM</option>
                                <option value="21.91">21:55 PM</option>
                                <option value="22.00">22:00 PM - o'clock</option>
                                <option value="22.8">22:05 PM</option>
                                <option value="22.16">22:10 PM</option>
                                <option value="22.25">22:15 PM</option>
                                <option value="22.33">22:20 PM</option>
                                <option value="22.41">22:25 PM</option>
                                <option value="22.50">22:30 PM</option>
                                <option value="22.58">22:35 PM</option>
                                <option value="22.66">22:40 PM</option>
                                <option value="22.75">22:45 PM</option>
                                <option value="22.83">22:50 PM</option>
                                <option value="22.91">22:55 PM</option>
                                <option value="23.00">23:00 PM - o'clock</option>
                                <option value="23.8">23:05 PM</option>
                                <option value="23.16">23:10 PM</option>
                                <option value="23.25">23:15 PM</option>
                                <option value="23.33">23:20 PM</option>
                                <option value="23.41">23:25 PM</option>
                                <option value="23.50">23:30 PM</option>
                                <option value="23.58">23:35 PM</option>
                                <option value="23.66">23:40 PM</option>
                                <option value="23.75">23:45 PM</option>
                                <option value="23.83">23:50 PM</option>
                                <option value="23.91">23:55 PM</option>
                                <option value="24.00">24:00 PM - o'clock</option>
                                <option value="24.8">15:05 PM</option>
                                <option value="24.16">24:10 PM</option>
                                <option value="24.25">24:15 PM</option>
                                <option value="24.33">24:20 PM</option>
                                <option value="24.41">24:25 PM</option>
                                <option value="24.50">24:30 PM</option>
                                <option value="24.58">24:35 PM</option>
                                <option value="24.66">24:40 PM</option>
                                <option value="24.75">24:45 PM</option>
                                <option value="24.83">24:50 PM</option>
                                <option value="24.91">24:55 PM</option>
                            </select>
                            {data17.map((datas, index) =>{
                              return<div key={index}>
                                        <p>{datas.endhour}</p>
                                    </div>
                                })}
                        </div>
                        <div className="col-md-2">
                            <select name="type17" onChange={e =>  settype17(e.target.value)} className="form-control" required>
                                <option value="">Select Type</option>
                                <option value="Normal">Normal</option>
                                <option value="SJUK">SJUK</option>
                                <option value="Holiday">Holiday</option>
                            </select>
                            {data17.map((datas, index) =>{
                              return<div key={index}>
                                        <p>{datas.type}</p>
                                    </div>
                                })}
                          </div>
                          <div className="col-md-2"> 
                              <button type='submit' className="btn btn-primary">Submit</button>
                        </div>
                      </form>
                  </div>
                  <div className="d-flex flex-column p-2">
                     <form action="" className="row"  onSubmit={handleSubmit18}>
                        <div className="col-md-1"> 18 </div>
                        <div className="col-md-1"> Friday </div>
                        <div className="col-md-1" > 
                            {data18.map((types, index) =>{
                              return<tr key={index}>
                                   <p style={types.type === "Normal" ? {backgroundColor:'rgba(7, 190, 7, 0.648)', color:'#000', padding:'5px 10px'} : 
                                     (types.type === "Holiday" ? {backgroundColor:'rgba(255, 0, 0, 0.438)', color:'#000', padding:'5px 10px'} : 
                                      {backgroundColor:'#008cff5a', color:'#000', padding:'5px 10px'}) }> {types.type}</p>
                                    </tr>
                                })}
                        </div>
                        <div className="col-md-2">
                            <select name="starthour18" onChange={e =>  setStart18(e.target.value)} className="form-control">
                                <option value="">Start Time</option>
                                <option value="05.00">05:00 AM</option>
                                <option value="06.00">06:00 AM</option>
                                <option value="06.8">06:05 AM</option>
                                <option value="06.16">06:10 AM</option>
                                <option value="06.25">06:15 AM</option>
                                <option value="06.33">06:20 AM</option>
                                <option value="06.41">06:25 AM</option>
                                <option value="06.50">06:30 AM</option>
                                <option value="06.58">06:35 AM</option>
                                <option value="06.66">06:40 AM</option>
                                <option value="06.75">06:45 AM</option>
                                <option value="06.83">06:50 AM</option>
                                <option value="06.91">06:55 AM</option>
                                <option value="07.00">07:00 AM - Normal</option>
                                <option value="07.8">07:05 AM</option>
                                <option value="07.16">07:10 AM</option>
                                <option value="07.25">07:15 AM</option>
                                <option value="07.33">07:20 AM</option>
                                <option value="07.41">07:25 AM</option>
                                <option value="07.50">07:30 AM</option>
                                <option value="07.58">07:35 AM</option>
                                <option value="07.66">07:40 AM</option>
                                <option value="07.75">07:45 AM</option>
                                <option value="07.83">07:50 AM</option>
                                <option value="07.91">07:55 AM</option>
                                <option value="08.00">08:00 AM - o'clock</option>
                                <option value="08.8">08:05 AM</option>
                                <option value="08.16">08:10 AM</option>
                                <option value="08.25">08:15 AM</option>
                                <option value="08.33">08:20 AM</option>
                                <option value="08.41">08:25 AM</option>
                                <option value="08.50">08:30 AM</option>
                                <option value="08.58">08:35 AM</option>
                                <option value="08.66">08:40 AM</option>
                                <option value="08.75">08:45 AM</option>
                                <option value="08.83">08:50 AM</option>
                                <option value="08.91">08:55 AM</option>
                                <option value="09.00">09:00 AM - o'clock</option>
                                <option value="09.8">09:05 AM</option>
                                <option value="09.16">09:10 AM</option>
                                <option value="09.25">09:15 AM</option>
                                <option value="09.33">09:20 AM</option>
                                <option value="09.41">09:25 AM</option>
                                <option value="09.50">09:30 AM</option>
                                <option value="09.58">09:35 AM</option>
                                <option value="09.66">09:40 AM</option>
                                <option value="09.75">09:45 AM</option>
                                <option value="09.83">09:50 AM</option>
                                <option value="09.91">09:55 AM</option>
                                <option value="10.00">10:00 AM - o'clock</option>
                                <option value="10.8">10:05 AM</option>
                                <option value="10.16">10:10 AM</option>
                                <option value="10.25">10:15 AM</option>
                                <option value="10.33">10:20 AM</option>
                                <option value="10.41">10:25 AM</option>
                                <option value="10.50">10:30 AM</option>
                                <option value="10.58">10:35 AM</option>
                                <option value="10.66">10:40 AM</option>
                                <option value="10.75">10:45 AM</option>
                                <option value="10.83">10:50 AM</option>
                                <option value="10.91">10:55 AM</option>
                                <option value="11.00">11:00 AM - o'clock</option>
                                <option value="11.8">11:05 AM</option>
                                <option value="11.16">11:10 AM</option>
                                <option value="11.25">11:15 AM</option>
                                <option value="11.33">11:20 AM</option>
                                <option value="11.41">11:25 AM</option>
                                <option value="11.50">11:30 AM</option>
                                <option value="11.58">11:35 AM</option>
                                <option value="11.66">11:40 AM</option>
                                <option value="11.75">11:45 AM</option>
                                <option value="11.83">11:50 AM</option>
                                <option value="11.91">11:55 AM</option>
                                <option value="12.00">12:00 PM  - o'clock</option>
                                <option value="12.8">12:05 PM</option>
                                <option value="12.16">12:10 PM</option>
                                <option value="12.25">12:15 PM</option>
                                <option value="12.33">12:20 PM</option>
                                <option value="12.41">12:25 PM</option>
                                <option value="12.50">12:30 PM</option>
                                <option value="12.58">12:35 PM</option>
                                <option value="12.66">12:40 PM</option>
                                <option value="12.75">12:45 PM</option>
                                <option value="12.83">12:50 PM</option>
                                <option value="12.91">12:55 PM</option>
                                <option value="13.00">13:00 PM - o'clock</option>
                                <option value="13.8">13:05 PM</option>
                                <option value="13.16">13:10 PM</option>
                                <option value="13.25">13:15 PM</option>
                                <option value="13.33">13:20 PM</option>
                                <option value="13.41">13:25 PM</option>
                                <option value="13.50">13:30 PM</option>
                                <option value="13.58">13:35 PM</option>
                                <option value="13.66">13:40 PM</option>
                                <option value="13.75">13:45 PM</option>
                                <option value="13.83">13:50 PM</option>
                                <option value="13.91">13:55 PM</option>
                                <option value="14.00">14:00 PM - o'clock</option>
                                <option value="14.8">14:05 PM</option>
                                <option value="14.16">14:10 PM</option>
                                <option value="14.25">14:15 PM</option>
                                <option value="14.33">14:20 PM</option>
                                <option value="14.41">14:25 PM</option>
                                <option value="14.50">14:30 PM</option>
                                <option value="14.58">14:35 PM</option>
                                <option value="14.66">14:40 PM</option>
                                <option value="14.75">14:45 PM</option>
                                <option value="14.83">14:50 PM</option>
                                <option value="14.91">14:55 PM</option>
                                <option value="15.00">15:00 PM - o'clock</option>
                                <option value="15.8">15:05 PM</option>
                                <option value="15.16">15:10 PM</option>
                                <option value="15.25">15:15 PM</option>
                                <option value="15.33">15:20 PM</option>
                                <option value="15.41">15:25 PM</option>
                                <option value="15.50">15:30 PM</option>
                                <option value="15.58">15:35 PM</option>
                                <option value="15.66">15:40 PM</option>
                                <option value="15.75">15:45 PM</option>
                                <option value="15.83">15:50 PM</option>
                                <option value="15.91">15:55 PM</option>
                                <option value="16.00">16:00 PM - o'clock</option>
                                <option value="16.8">16:05 PM</option>
                                <option value="16.16">16:10 PM</option>
                                <option value="16.25">16:15 PM</option>
                                <option value="16.33">16:20 PM</option>
                                <option value="16.41">16:25 PM</option>
                                <option value="16.50">16:30 PM</option>
                                <option value="16.58">16:35 PM</option>
                                <option value="16.66">16:40 PM</option>
                                <option value="16.75">16:45 PM</option>
                                <option value="16.83">16:50 PM</option>
                                <option value="16.91">16:55 PM</option>
                                <option value="17.00">17:00 PM - o'clock</option>
                                <option value="17.8">17:05 PM</option>
                                <option value="17.16">17:10 PM</option>
                                <option value="17.25">17:15 PM</option>
                                <option value="17.33">17:20 PM</option>
                                <option value="17.41">17:25 PM</option>
                                <option value="17.50">17:30 PM</option>
                                <option value="17.58">17:35 PM</option>
                                <option value="17.66">17:40 PM</option>
                                <option value="17.75">17:45 PM</option>
                                <option value="17.83">17:50 PM</option>
                                <option value="17.91">17:55 PM</option>
                                <option value="18.00">18:00 PM - Normal</option>
                                <option value="18.8">18:05 PM</option>
                                <option value="18.16">18:10 PM</option>
                                <option value="18.25">18:15 PM</option>
                                <option value="18.33">18:20 PM</option>
                                <option value="18.41">18:25 PM</option>
                                <option value="18.50">18:30 PM</option>
                                <option value="18.58">18:35 PM</option>
                                <option value="18.66">18:40 PM</option>
                                <option value="18.75">18:45 PM</option>
                                <option value="18.83">18:50 PM</option>
                                <option value="18.91">18:55 PM</option>
                                <option value="19.00">19:00 PM - o'clock</option>
                                <option value="19.8">19:05 PM</option>
                                <option value="19.16">19:10 PM</option>
                                <option value="19.25">19:15 PM</option>
                                <option value="19.33">19:20 PM</option>
                                <option value="19.41">19:25 PM</option>
                                <option value="19.50">19:30 PM</option>
                                <option value="19.58">19:35 PM</option>
                                <option value="19.66">19:40 PM</option>
                                <option value="19.75">19:45 PM</option>
                                <option value="19.83">19:50 PM</option>
                                <option value="19.91">19:55 PM</option>
                                <option value="20.00">20:00 PM - o'clock</option>
                                <option value="20.8">20:05 PM</option>
                                <option value="20.16">20:10 PM</option>
                                <option value="20.25">20:15 PM</option>
                                <option value="20.33">20:20 PM</option>
                                <option value="20.41">20:25 PM</option>
                                <option value="20.50">20:30 PM</option>
                                <option value="20.58">20:35 PM</option>
                                <option value="20.66">20:40 PM</option>
                                <option value="20.75">20:45 PM</option>
                                <option value="20.83">20:50 PM</option>
                                <option value="20.91">20:55 PM</option>
                                <option value="21.00">21:00 PM - o'clock</option>
                                <option value="21.8">21:05 PM</option>
                                <option value="21.16">21:10 PM</option>
                                <option value="21.25">21:15 PM</option>
                                <option value="21.33">21:20 PM</option>
                                <option value="21.41">21:25 PM</option>
                                <option value="21.50">21:30 PM</option>
                                <option value="21.58">21:35 PM</option>
                                <option value="21.66">21:40 PM</option>
                                <option value="21.75">21:45 PM</option>
                                <option value="21.83">21:50 PM</option>
                                <option value="21.91">21:55 PM</option>
                                <option value="22.00">22:00 PM - o'clock</option>
                                <option value="22.8">22:05 PM</option>
                                <option value="22.16">22:10 PM</option>
                                <option value="22.25">22:15 PM</option>
                                <option value="22.33">22:20 PM</option>
                                <option value="22.41">22:25 PM</option>
                                <option value="22.50">22:30 PM</option>
                                <option value="22.58">22:35 PM</option>
                                <option value="22.66">22:40 PM</option>
                                <option value="22.75">22:45 PM</option>
                                <option value="22.83">22:50 PM</option>
                                <option value="22.91">22:55 PM</option>
                                <option value="23.00">23:00 PM - o'clock</option>
                                <option value="23.8">23:05 PM</option>
                                <option value="23.16">23:10 PM</option>
                                <option value="23.25">23:15 PM</option>
                                <option value="23.33">23:20 PM</option>
                                <option value="23.41">23:25 PM</option>
                                <option value="23.50">23:30 PM</option>
                                <option value="23.58">23:35 PM</option>
                                <option value="23.66">23:40 PM</option>
                                <option value="23.75">23:45 PM</option>
                                <option value="23.83">23:50 PM</option>
                                <option value="23.91">23:55 PM</option>
                                <option value="24.00">24:00 PM - o'clock</option>
                                <option value="24.8">15:05 PM</option>
                                <option value="24.16">24:10 PM</option>
                                <option value="24.25">24:15 PM</option>
                                <option value="24.33">24:20 PM</option>
                                <option value="24.41">24:25 PM</option>
                                <option value="24.50">24:30 PM</option>
                                <option value="24.58">24:35 PM</option>
                                <option value="24.66">24:40 PM</option>
                                <option value="24.75">24:45 PM</option>
                                <option value="24.83">24:50 PM</option>
                                <option value="24.91">24:55 PM</option>
                            </select>
                            {data18.map((datas, index) =>{
                              return<div key={index}>
                                        <p>{datas.starthour}</p>
                                    </div>
                                })}
                        </div>
                        <div className="col-md-2">
                            <select name="endhour18" onChange={e =>  setEnd18(e.target.value)} className="form-control">
                                <option value="">Finish Time</option>
                                <option value="05.00">05:00 AM</option>
                                <option value="06.00">06:00 AM</option>
                                <option value="06.8">06:05 AM</option>
                                <option value="06.16">06:10 AM</option>
                                <option value="06.25">06:15 AM</option>
                                <option value="06.33">06:20 AM</option>
                                <option value="06.41">06:25 AM</option>
                                <option value="06.50">06:30 AM</option>
                                <option value="06.58">06:35 AM</option>
                                <option value="06.66">06:40 AM</option>
                                <option value="06.75">06:45 AM</option>
                                <option value="06.83">06:50 AM</option>
                                <option value="06.91">06:55 AM</option>
                                <option value="07.00">07:00 AM - Normal</option>
                                <option value="07.8">07:05 AM</option>
                                <option value="07.16">07:10 AM</option>
                                <option value="07.25">07:15 AM</option>
                                <option value="07.33">07:20 AM</option>
                                <option value="07.41">07:25 AM</option>
                                <option value="07.50">07:30 AM</option>
                                <option value="07.58">07:35 AM</option>
                                <option value="07.66">07:40 AM</option>
                                <option value="07.75">07:45 AM</option>
                                <option value="07.83">07:50 AM</option>
                                <option value="07.91">07:55 AM</option>
                                <option value="08.00">08:00 AM - o'clock</option>
                                <option value="08.8">08:05 AM</option>
                                <option value="08.16">08:10 AM</option>
                                <option value="08.25">08:15 AM</option>
                                <option value="08.33">08:20 AM</option>
                                <option value="08.41">08:25 AM</option>
                                <option value="08.50">08:30 AM</option>
                                <option value="08.58">08:35 AM</option>
                                <option value="08.66">08:40 AM</option>
                                <option value="08.75">08:45 AM</option>
                                <option value="08.83">08:50 AM</option>
                                <option value="08.91">08:55 AM</option>
                                <option value="09.00">09:00 AM - o'clock</option>
                                <option value="09.8">09:05 AM</option>
                                <option value="09.16">09:10 AM</option>
                                <option value="09.25">09:15 AM</option>
                                <option value="09.33">09:20 AM</option>
                                <option value="09.41">09:25 AM</option>
                                <option value="09.50">09:30 AM</option>
                                <option value="09.58">09:35 AM</option>
                                <option value="09.66">09:40 AM</option>
                                <option value="09.75">09:45 AM</option>
                                <option value="09.83">09:50 AM</option>
                                <option value="09.91">09:55 AM</option>
                                <option value="10.00">10:00 AM - o'clock</option>
                                <option value="10.8">10:05 AM</option>
                                <option value="10.16">10:10 AM</option>
                                <option value="10.25">10:15 AM</option>
                                <option value="10.33">10:20 AM</option>
                                <option value="10.41">10:25 AM</option>
                                <option value="10.50">10:30 AM</option>
                                <option value="10.58">10:35 AM</option>
                                <option value="10.66">10:40 AM</option>
                                <option value="10.75">10:45 AM</option>
                                <option value="10.83">10:50 AM</option>
                                <option value="10.91">10:55 AM</option>
                                <option value="11.00">11:00 AM - o'clock</option>
                                <option value="11.8">11:05 AM</option>
                                <option value="11.16">11:10 AM</option>
                                <option value="11.25">11:15 AM</option>
                                <option value="11.33">11:20 AM</option>
                                <option value="11.41">11:25 AM</option>
                                <option value="11.50">11:30 AM</option>
                                <option value="11.58">11:35 AM</option>
                                <option value="11.66">11:40 AM</option>
                                <option value="11.75">11:45 AM</option>
                                <option value="11.83">11:50 AM</option>
                                <option value="11.91">11:55 AM</option>
                                <option value="12.00">12:00 PM  - o'clock</option>
                                <option value="12.8">12:05 PM</option>
                                <option value="12.16">12:10 PM</option>
                                <option value="12.25">12:15 PM</option>
                                <option value="12.33">12:20 PM</option>
                                <option value="12.41">12:25 PM</option>
                                <option value="12.50">12:30 PM</option>
                                <option value="12.58">12:35 PM</option>
                                <option value="12.66">12:40 PM</option>
                                <option value="12.75">12:45 PM</option>
                                <option value="12.83">12:50 PM</option>
                                <option value="12.91">12:55 PM</option>
                                <option value="13.00">13:00 PM - o'clock</option>
                                <option value="13.8">13:05 PM</option>
                                <option value="13.16">13:10 PM</option>
                                <option value="13.25">13:15 PM</option>
                                <option value="13.33">13:20 PM</option>
                                <option value="13.41">13:25 PM</option>
                                <option value="13.50">13:30 PM</option>
                                <option value="13.58">13:35 PM</option>
                                <option value="13.66">13:40 PM</option>
                                <option value="13.75">13:45 PM</option>
                                <option value="13.83">13:50 PM</option>
                                <option value="13.91">13:55 PM</option>
                                <option value="14.00">14:00 PM - o'clock</option>
                                <option value="14.8">14:05 PM</option>
                                <option value="14.16">14:10 PM</option>
                                <option value="14.25">14:15 PM</option>
                                <option value="14.33">14:20 PM</option>
                                <option value="14.41">14:25 PM</option>
                                <option value="14.50">14:30 PM</option>
                                <option value="14.58">14:35 PM</option>
                                <option value="14.66">14:40 PM</option>
                                <option value="14.75">14:45 PM</option>
                                <option value="14.83">14:50 PM</option>
                                <option value="14.91">14:55 PM</option>
                                <option value="15.00">15:00 PM - o'clock</option>
                                <option value="15.8">15:05 PM</option>
                                <option value="15.16">15:10 PM</option>
                                <option value="15.25">15:15 PM</option>
                                <option value="15.33">15:20 PM</option>
                                <option value="15.41">15:25 PM</option>
                                <option value="15.50">15:30 PM</option>
                                <option value="15.58">15:35 PM</option>
                                <option value="15.66">15:40 PM</option>
                                <option value="15.75">15:45 PM</option>
                                <option value="15.83">15:50 PM</option>
                                <option value="15.91">15:55 PM</option>
                                <option value="16.00">16:00 PM - o'clock</option>
                                <option value="16.8">16:05 PM</option>
                                <option value="16.16">16:10 PM</option>
                                <option value="16.25">16:15 PM</option>
                                <option value="16.33">16:20 PM</option>
                                <option value="16.41">16:25 PM</option>
                                <option value="16.50">16:30 PM</option>
                                <option value="16.58">16:35 PM</option>
                                <option value="16.66">16:40 PM</option>
                                <option value="16.75">16:45 PM</option>
                                <option value="16.83">16:50 PM</option>
                                <option value="16.91">16:55 PM</option>
                                <option value="17.00">17:00 PM - o'clock</option>
                                <option value="17.8">17:05 PM</option>
                                <option value="17.16">17:10 PM</option>
                                <option value="17.25">17:15 PM</option>
                                <option value="17.33">17:20 PM</option>
                                <option value="17.41">17:25 PM</option>
                                <option value="17.50">17:30 PM</option>
                                <option value="17.58">17:35 PM</option>
                                <option value="17.66">17:40 PM</option>
                                <option value="17.75">17:45 PM</option>
                                <option value="17.83">17:50 PM</option>
                                <option value="17.91">17:55 PM</option>
                                <option value="18.00">18:00 PM - Normal</option>
                                <option value="18.8">18:05 PM</option>
                                <option value="18.16">18:10 PM</option>
                                <option value="18.25">18:15 PM</option>
                                <option value="18.33">18:20 PM</option>
                                <option value="18.41">18:25 PM</option>
                                <option value="18.50">18:30 PM</option>
                                <option value="18.58">18:35 PM</option>
                                <option value="18.66">18:40 PM</option>
                                <option value="18.75">18:45 PM</option>
                                <option value="18.83">18:50 PM</option>
                                <option value="18.91">18:55 PM</option>
                                <option value="19.00">19:00 PM - o'clock</option>
                                <option value="19.8">19:05 PM</option>
                                <option value="19.16">19:10 PM</option>
                                <option value="19.25">19:15 PM</option>
                                <option value="19.33">19:20 PM</option>
                                <option value="19.41">19:25 PM</option>
                                <option value="19.50">19:30 PM</option>
                                <option value="19.58">19:35 PM</option>
                                <option value="19.66">19:40 PM</option>
                                <option value="19.75">19:45 PM</option>
                                <option value="19.83">19:50 PM</option>
                                <option value="19.91">19:55 PM</option>
                                <option value="20.00">20:00 PM - o'clock</option>
                                <option value="20.8">20:05 PM</option>
                                <option value="20.16">20:10 PM</option>
                                <option value="20.25">20:15 PM</option>
                                <option value="20.33">20:20 PM</option>
                                <option value="20.41">20:25 PM</option>
                                <option value="20.50">20:30 PM</option>
                                <option value="20.58">20:35 PM</option>
                                <option value="20.66">20:40 PM</option>
                                <option value="20.75">20:45 PM</option>
                                <option value="20.83">20:50 PM</option>
                                <option value="20.91">20:55 PM</option>
                                <option value="21.00">21:00 PM - o'clock</option>
                                <option value="21.8">21:05 PM</option>
                                <option value="21.16">21:10 PM</option>
                                <option value="21.25">21:15 PM</option>
                                <option value="21.33">21:20 PM</option>
                                <option value="21.41">21:25 PM</option>
                                <option value="21.50">21:30 PM</option>
                                <option value="21.58">21:35 PM</option>
                                <option value="21.66">21:40 PM</option>
                                <option value="21.75">21:45 PM</option>
                                <option value="21.83">21:50 PM</option>
                                <option value="21.91">21:55 PM</option>
                                <option value="22.00">22:00 PM - o'clock</option>
                                <option value="22.8">22:05 PM</option>
                                <option value="22.16">22:10 PM</option>
                                <option value="22.25">22:15 PM</option>
                                <option value="22.33">22:20 PM</option>
                                <option value="22.41">22:25 PM</option>
                                <option value="22.50">22:30 PM</option>
                                <option value="22.58">22:35 PM</option>
                                <option value="22.66">22:40 PM</option>
                                <option value="22.75">22:45 PM</option>
                                <option value="22.83">22:50 PM</option>
                                <option value="22.91">22:55 PM</option>
                                <option value="23.00">23:00 PM - o'clock</option>
                                <option value="23.8">23:05 PM</option>
                                <option value="23.16">23:10 PM</option>
                                <option value="23.25">23:15 PM</option>
                                <option value="23.33">23:20 PM</option>
                                <option value="23.41">23:25 PM</option>
                                <option value="23.50">23:30 PM</option>
                                <option value="23.58">23:35 PM</option>
                                <option value="23.66">23:40 PM</option>
                                <option value="23.75">23:45 PM</option>
                                <option value="23.83">23:50 PM</option>
                                <option value="23.91">23:55 PM</option>
                                <option value="24.00">24:00 PM - o'clock</option>
                                <option value="24.8">15:05 PM</option>
                                <option value="24.16">24:10 PM</option>
                                <option value="24.25">24:15 PM</option>
                                <option value="24.33">24:20 PM</option>
                                <option value="24.41">24:25 PM</option>
                                <option value="24.50">24:30 PM</option>
                                <option value="24.58">24:35 PM</option>
                                <option value="24.66">24:40 PM</option>
                                <option value="24.75">24:45 PM</option>
                                <option value="24.83">24:50 PM</option>
                                <option value="24.91">24:55 PM</option>
                            </select>
                            {data18.map((datas, index) =>{
                              return<div key={index}>
                                        <p>{datas.endhour}</p>
                                    </div>
                                })}
                        </div>
                        <div className="col-md-2">
                            <select name="type18" onChange={e =>  settype18(e.target.value)} className="form-control" required>
                                <option value="">Select Type</option>
                                <option value="Normal">Normal</option>
                                <option value="SJUK">SJUK</option>
                                <option value="Holiday">Holiday</option>
                            </select>
                            {data18.map((datas, index) =>{
                              return<div key={index}>
                                        <p>{datas.type}</p>
                                    </div>
                                })}
                          </div>
                          <div className="col-md-2"> 
                              <button type='submit' className="btn btn-primary">Submit</button>
                        </div>
                      </form>
                  </div>
                  <div className="d-flex flex-column p-2">
                     <form action="" className="row"  onSubmit={handleSubmit19}>
                        <div className="col-md-1"> 19 </div>
                        <div className="col-md-1"> Saturday  </div>
                        <div className="col-md-1" > 
                            {data19.map((types, index) =>{
                              return<tr key={index}>
                                   <p style={types.type === "Normal" ? {backgroundColor:'rgba(7, 190, 7, 0.648)', color:'#000', padding:'5px 10px'} : 
                                     (types.type === "Holiday" ? {backgroundColor:'rgba(255, 0, 0, 0.438)', color:'#000', padding:'5px 10px'} : 
                                      {backgroundColor:'#008cff5a', color:'#000', padding:'5px 10px'}) }> {types.type}</p>
                                    </tr>
                                })}
                        </div>
                        <div className="col-md-2">
                            <select name="starthour19" onChange={e =>  setStart19(e.target.value)} className="form-control">
                                <option value="">Start Time</option>
                                <option value="05.00">05:00 AM</option>
                                <option value="06.00">06:00 AM</option>
                                <option value="06.8">06:05 AM</option>
                                <option value="06.16">06:10 AM</option>
                                <option value="06.25">06:15 AM</option>
                                <option value="06.33">06:20 AM</option>
                                <option value="06.41">06:25 AM</option>
                                <option value="06.50">06:30 AM</option>
                                <option value="06.58">06:35 AM</option>
                                <option value="06.66">06:40 AM</option>
                                <option value="06.75">06:45 AM</option>
                                <option value="06.83">06:50 AM</option>
                                <option value="06.91">06:55 AM</option>
                                <option value="07.00">07:00 AM - Normal</option>
                                <option value="07.8">07:05 AM</option>
                                <option value="07.16">07:10 AM</option>
                                <option value="07.25">07:15 AM</option>
                                <option value="07.33">07:20 AM</option>
                                <option value="07.41">07:25 AM</option>
                                <option value="07.50">07:30 AM</option>
                                <option value="07.58">07:35 AM</option>
                                <option value="07.66">07:40 AM</option>
                                <option value="07.75">07:45 AM</option>
                                <option value="07.83">07:50 AM</option>
                                <option value="07.91">07:55 AM</option>
                                <option value="08.00">08:00 AM - o'clock</option>
                                <option value="08.8">08:05 AM</option>
                                <option value="08.16">08:10 AM</option>
                                <option value="08.25">08:15 AM</option>
                                <option value="08.33">08:20 AM</option>
                                <option value="08.41">08:25 AM</option>
                                <option value="08.50">08:30 AM</option>
                                <option value="08.58">08:35 AM</option>
                                <option value="08.66">08:40 AM</option>
                                <option value="08.75">08:45 AM</option>
                                <option value="08.83">08:50 AM</option>
                                <option value="08.91">08:55 AM</option>
                                <option value="09.00">09:00 AM - o'clock</option>
                                <option value="09.8">09:05 AM</option>
                                <option value="09.16">09:10 AM</option>
                                <option value="09.25">09:15 AM</option>
                                <option value="09.33">09:20 AM</option>
                                <option value="09.41">09:25 AM</option>
                                <option value="09.50">09:30 AM</option>
                                <option value="09.58">09:35 AM</option>
                                <option value="09.66">09:40 AM</option>
                                <option value="09.75">09:45 AM</option>
                                <option value="09.83">09:50 AM</option>
                                <option value="09.91">09:55 AM</option>
                                <option value="10.00">10:00 AM - o'clock</option>
                                <option value="10.8">10:05 AM</option>
                                <option value="10.16">10:10 AM</option>
                                <option value="10.25">10:15 AM</option>
                                <option value="10.33">10:20 AM</option>
                                <option value="10.41">10:25 AM</option>
                                <option value="10.50">10:30 AM</option>
                                <option value="10.58">10:35 AM</option>
                                <option value="10.66">10:40 AM</option>
                                <option value="10.75">10:45 AM</option>
                                <option value="10.83">10:50 AM</option>
                                <option value="10.91">10:55 AM</option>
                                <option value="11.00">11:00 AM - o'clock</option>
                                <option value="11.8">11:05 AM</option>
                                <option value="11.16">11:10 AM</option>
                                <option value="11.25">11:15 AM</option>
                                <option value="11.33">11:20 AM</option>
                                <option value="11.41">11:25 AM</option>
                                <option value="11.50">11:30 AM</option>
                                <option value="11.58">11:35 AM</option>
                                <option value="11.66">11:40 AM</option>
                                <option value="11.75">11:45 AM</option>
                                <option value="11.83">11:50 AM</option>
                                <option value="11.91">11:55 AM</option>
                                <option value="12.00">12:00 PM  - o'clock</option>
                                <option value="12.8">12:05 PM</option>
                                <option value="12.16">12:10 PM</option>
                                <option value="12.25">12:15 PM</option>
                                <option value="12.33">12:20 PM</option>
                                <option value="12.41">12:25 PM</option>
                                <option value="12.50">12:30 PM</option>
                                <option value="12.58">12:35 PM</option>
                                <option value="12.66">12:40 PM</option>
                                <option value="12.75">12:45 PM</option>
                                <option value="12.83">12:50 PM</option>
                                <option value="12.91">12:55 PM</option>
                                <option value="13.00">13:00 PM - o'clock</option>
                                <option value="13.8">13:05 PM</option>
                                <option value="13.16">13:10 PM</option>
                                <option value="13.25">13:15 PM</option>
                                <option value="13.33">13:20 PM</option>
                                <option value="13.41">13:25 PM</option>
                                <option value="13.50">13:30 PM</option>
                                <option value="13.58">13:35 PM</option>
                                <option value="13.66">13:40 PM</option>
                                <option value="13.75">13:45 PM</option>
                                <option value="13.83">13:50 PM</option>
                                <option value="13.91">13:55 PM</option>
                                <option value="14.00">14:00 PM - o'clock</option>
                                <option value="14.8">14:05 PM</option>
                                <option value="14.16">14:10 PM</option>
                                <option value="14.25">14:15 PM</option>
                                <option value="14.33">14:20 PM</option>
                                <option value="14.41">14:25 PM</option>
                                <option value="14.50">14:30 PM</option>
                                <option value="14.58">14:35 PM</option>
                                <option value="14.66">14:40 PM</option>
                                <option value="14.75">14:45 PM</option>
                                <option value="14.83">14:50 PM</option>
                                <option value="14.91">14:55 PM</option>
                                <option value="15.00">15:00 PM - o'clock</option>
                                <option value="15.8">15:05 PM</option>
                                <option value="15.16">15:10 PM</option>
                                <option value="15.25">15:15 PM</option>
                                <option value="15.33">15:20 PM</option>
                                <option value="15.41">15:25 PM</option>
                                <option value="15.50">15:30 PM</option>
                                <option value="15.58">15:35 PM</option>
                                <option value="15.66">15:40 PM</option>
                                <option value="15.75">15:45 PM</option>
                                <option value="15.83">15:50 PM</option>
                                <option value="15.91">15:55 PM</option>
                                <option value="16.00">16:00 PM - o'clock</option>
                                <option value="16.8">16:05 PM</option>
                                <option value="16.16">16:10 PM</option>
                                <option value="16.25">16:15 PM</option>
                                <option value="16.33">16:20 PM</option>
                                <option value="16.41">16:25 PM</option>
                                <option value="16.50">16:30 PM</option>
                                <option value="16.58">16:35 PM</option>
                                <option value="16.66">16:40 PM</option>
                                <option value="16.75">16:45 PM</option>
                                <option value="16.83">16:50 PM</option>
                                <option value="16.91">16:55 PM</option>
                                <option value="17.00">17:00 PM - o'clock</option>
                                <option value="17.8">17:05 PM</option>
                                <option value="17.16">17:10 PM</option>
                                <option value="17.25">17:15 PM</option>
                                <option value="17.33">17:20 PM</option>
                                <option value="17.41">17:25 PM</option>
                                <option value="17.50">17:30 PM</option>
                                <option value="17.58">17:35 PM</option>
                                <option value="17.66">17:40 PM</option>
                                <option value="17.75">17:45 PM</option>
                                <option value="17.83">17:50 PM</option>
                                <option value="17.91">17:55 PM</option>
                                <option value="18.00">18:00 PM - Normal</option>
                                <option value="18.8">18:05 PM</option>
                                <option value="18.16">18:10 PM</option>
                                <option value="18.25">18:15 PM</option>
                                <option value="18.33">18:20 PM</option>
                                <option value="18.41">18:25 PM</option>
                                <option value="18.50">18:30 PM</option>
                                <option value="18.58">18:35 PM</option>
                                <option value="18.66">18:40 PM</option>
                                <option value="18.75">18:45 PM</option>
                                <option value="18.83">18:50 PM</option>
                                <option value="18.91">18:55 PM</option>
                                <option value="19.00">19:00 PM - o'clock</option>
                                <option value="19.8">19:05 PM</option>
                                <option value="19.16">19:10 PM</option>
                                <option value="19.25">19:15 PM</option>
                                <option value="19.33">19:20 PM</option>
                                <option value="19.41">19:25 PM</option>
                                <option value="19.50">19:30 PM</option>
                                <option value="19.58">19:35 PM</option>
                                <option value="19.66">19:40 PM</option>
                                <option value="19.75">19:45 PM</option>
                                <option value="19.83">19:50 PM</option>
                                <option value="19.91">19:55 PM</option>
                                <option value="20.00">20:00 PM - o'clock</option>
                                <option value="20.8">20:05 PM</option>
                                <option value="20.16">20:10 PM</option>
                                <option value="20.25">20:15 PM</option>
                                <option value="20.33">20:20 PM</option>
                                <option value="20.41">20:25 PM</option>
                                <option value="20.50">20:30 PM</option>
                                <option value="20.58">20:35 PM</option>
                                <option value="20.66">20:40 PM</option>
                                <option value="20.75">20:45 PM</option>
                                <option value="20.83">20:50 PM</option>
                                <option value="20.91">20:55 PM</option>
                                <option value="21.00">21:00 PM - o'clock</option>
                                <option value="21.8">21:05 PM</option>
                                <option value="21.16">21:10 PM</option>
                                <option value="21.25">21:15 PM</option>
                                <option value="21.33">21:20 PM</option>
                                <option value="21.41">21:25 PM</option>
                                <option value="21.50">21:30 PM</option>
                                <option value="21.58">21:35 PM</option>
                                <option value="21.66">21:40 PM</option>
                                <option value="21.75">21:45 PM</option>
                                <option value="21.83">21:50 PM</option>
                                <option value="21.91">21:55 PM</option>
                                <option value="22.00">22:00 PM - o'clock</option>
                                <option value="22.8">22:05 PM</option>
                                <option value="22.16">22:10 PM</option>
                                <option value="22.25">22:15 PM</option>
                                <option value="22.33">22:20 PM</option>
                                <option value="22.41">22:25 PM</option>
                                <option value="22.50">22:30 PM</option>
                                <option value="22.58">22:35 PM</option>
                                <option value="22.66">22:40 PM</option>
                                <option value="22.75">22:45 PM</option>
                                <option value="22.83">22:50 PM</option>
                                <option value="22.91">22:55 PM</option>
                                <option value="23.00">23:00 PM - o'clock</option>
                                <option value="23.8">23:05 PM</option>
                                <option value="23.16">23:10 PM</option>
                                <option value="23.25">23:15 PM</option>
                                <option value="23.33">23:20 PM</option>
                                <option value="23.41">23:25 PM</option>
                                <option value="23.50">23:30 PM</option>
                                <option value="23.58">23:35 PM</option>
                                <option value="23.66">23:40 PM</option>
                                <option value="23.75">23:45 PM</option>
                                <option value="23.83">23:50 PM</option>
                                <option value="23.91">23:55 PM</option>
                                <option value="24.00">24:00 PM - o'clock</option>
                                <option value="24.8">15:05 PM</option>
                                <option value="24.16">24:10 PM</option>
                                <option value="24.25">24:15 PM</option>
                                <option value="24.33">24:20 PM</option>
                                <option value="24.41">24:25 PM</option>
                                <option value="24.50">24:30 PM</option>
                                <option value="24.58">24:35 PM</option>
                                <option value="24.66">24:40 PM</option>
                                <option value="24.75">24:45 PM</option>
                                <option value="24.83">24:50 PM</option>
                                <option value="24.91">24:55 PM</option>
                            </select>
                            {data19.map((datas, index) =>{
                              return<div key={index}>
                                        <p>{datas.starthour}</p>
                                    </div>
                                })}
                        </div>
                        <div className="col-md-2">
                            <select name="endhour19" onChange={e =>  setEnd19(e.target.value)} className="form-control">
                                <option value="">Finish Time</option>
                                <option value="05.00">05:00 AM</option>
                                <option value="06.00">06:00 AM</option>
                                <option value="06.8">06:05 AM</option>
                                <option value="06.16">06:10 AM</option>
                                <option value="06.25">06:15 AM</option>
                                <option value="06.33">06:20 AM</option>
                                <option value="06.41">06:25 AM</option>
                                <option value="06.50">06:30 AM</option>
                                <option value="06.58">06:35 AM</option>
                                <option value="06.66">06:40 AM</option>
                                <option value="06.75">06:45 AM</option>
                                <option value="06.83">06:50 AM</option>
                                <option value="06.91">06:55 AM</option>
                                <option value="07.00">07:00 AM - Normal</option>
                                <option value="07.8">07:05 AM</option>
                                <option value="07.16">07:10 AM</option>
                                <option value="07.25">07:15 AM</option>
                                <option value="07.33">07:20 AM</option>
                                <option value="07.41">07:25 AM</option>
                                <option value="07.50">07:30 AM</option>
                                <option value="07.58">07:35 AM</option>
                                <option value="07.66">07:40 AM</option>
                                <option value="07.75">07:45 AM</option>
                                <option value="07.83">07:50 AM</option>
                                <option value="07.91">07:55 AM</option>
                                <option value="08.00">08:00 AM - o'clock</option>
                                <option value="08.8">08:05 AM</option>
                                <option value="08.16">08:10 AM</option>
                                <option value="08.25">08:15 AM</option>
                                <option value="08.33">08:20 AM</option>
                                <option value="08.41">08:25 AM</option>
                                <option value="08.50">08:30 AM</option>
                                <option value="08.58">08:35 AM</option>
                                <option value="08.66">08:40 AM</option>
                                <option value="08.75">08:45 AM</option>
                                <option value="08.83">08:50 AM</option>
                                <option value="08.91">08:55 AM</option>
                                <option value="09.00">09:00 AM - o'clock</option>
                                <option value="09.8">09:05 AM</option>
                                <option value="09.16">09:10 AM</option>
                                <option value="09.25">09:15 AM</option>
                                <option value="09.33">09:20 AM</option>
                                <option value="09.41">09:25 AM</option>
                                <option value="09.50">09:30 AM</option>
                                <option value="09.58">09:35 AM</option>
                                <option value="09.66">09:40 AM</option>
                                <option value="09.75">09:45 AM</option>
                                <option value="09.83">09:50 AM</option>
                                <option value="09.91">09:55 AM</option>
                                <option value="10.00">10:00 AM - o'clock</option>
                                <option value="10.8">10:05 AM</option>
                                <option value="10.16">10:10 AM</option>
                                <option value="10.25">10:15 AM</option>
                                <option value="10.33">10:20 AM</option>
                                <option value="10.41">10:25 AM</option>
                                <option value="10.50">10:30 AM</option>
                                <option value="10.58">10:35 AM</option>
                                <option value="10.66">10:40 AM</option>
                                <option value="10.75">10:45 AM</option>
                                <option value="10.83">10:50 AM</option>
                                <option value="10.91">10:55 AM</option>
                                <option value="11.00">11:00 AM - o'clock</option>
                                <option value="11.8">11:05 AM</option>
                                <option value="11.16">11:10 AM</option>
                                <option value="11.25">11:15 AM</option>
                                <option value="11.33">11:20 AM</option>
                                <option value="11.41">11:25 AM</option>
                                <option value="11.50">11:30 AM</option>
                                <option value="11.58">11:35 AM</option>
                                <option value="11.66">11:40 AM</option>
                                <option value="11.75">11:45 AM</option>
                                <option value="11.83">11:50 AM</option>
                                <option value="11.91">11:55 AM</option>
                                <option value="12.00">12:00 PM  - o'clock</option>
                                <option value="12.8">12:05 PM</option>
                                <option value="12.16">12:10 PM</option>
                                <option value="12.25">12:15 PM</option>
                                <option value="12.33">12:20 PM</option>
                                <option value="12.41">12:25 PM</option>
                                <option value="12.50">12:30 PM</option>
                                <option value="12.58">12:35 PM</option>
                                <option value="12.66">12:40 PM</option>
                                <option value="12.75">12:45 PM</option>
                                <option value="12.83">12:50 PM</option>
                                <option value="12.91">12:55 PM</option>
                                <option value="13.00">13:00 PM - o'clock</option>
                                <option value="13.8">13:05 PM</option>
                                <option value="13.16">13:10 PM</option>
                                <option value="13.25">13:15 PM</option>
                                <option value="13.33">13:20 PM</option>
                                <option value="13.41">13:25 PM</option>
                                <option value="13.50">13:30 PM</option>
                                <option value="13.58">13:35 PM</option>
                                <option value="13.66">13:40 PM</option>
                                <option value="13.75">13:45 PM</option>
                                <option value="13.83">13:50 PM</option>
                                <option value="13.91">13:55 PM</option>
                                <option value="14.00">14:00 PM - o'clock</option>
                                <option value="14.8">14:05 PM</option>
                                <option value="14.16">14:10 PM</option>
                                <option value="14.25">14:15 PM</option>
                                <option value="14.33">14:20 PM</option>
                                <option value="14.41">14:25 PM</option>
                                <option value="14.50">14:30 PM</option>
                                <option value="14.58">14:35 PM</option>
                                <option value="14.66">14:40 PM</option>
                                <option value="14.75">14:45 PM</option>
                                <option value="14.83">14:50 PM</option>
                                <option value="14.91">14:55 PM</option>
                                <option value="15.00">15:00 PM - o'clock</option>
                                <option value="15.8">15:05 PM</option>
                                <option value="15.16">15:10 PM</option>
                                <option value="15.25">15:15 PM</option>
                                <option value="15.33">15:20 PM</option>
                                <option value="15.41">15:25 PM</option>
                                <option value="15.50">15:30 PM</option>
                                <option value="15.58">15:35 PM</option>
                                <option value="15.66">15:40 PM</option>
                                <option value="15.75">15:45 PM</option>
                                <option value="15.83">15:50 PM</option>
                                <option value="15.91">15:55 PM</option>
                                <option value="16.00">16:00 PM - o'clock</option>
                                <option value="16.8">16:05 PM</option>
                                <option value="16.16">16:10 PM</option>
                                <option value="16.25">16:15 PM</option>
                                <option value="16.33">16:20 PM</option>
                                <option value="16.41">16:25 PM</option>
                                <option value="16.50">16:30 PM</option>
                                <option value="16.58">16:35 PM</option>
                                <option value="16.66">16:40 PM</option>
                                <option value="16.75">16:45 PM</option>
                                <option value="16.83">16:50 PM</option>
                                <option value="16.91">16:55 PM</option>
                                <option value="17.00">17:00 PM - o'clock</option>
                                <option value="17.8">17:05 PM</option>
                                <option value="17.16">17:10 PM</option>
                                <option value="17.25">17:15 PM</option>
                                <option value="17.33">17:20 PM</option>
                                <option value="17.41">17:25 PM</option>
                                <option value="17.50">17:30 PM</option>
                                <option value="17.58">17:35 PM</option>
                                <option value="17.66">17:40 PM</option>
                                <option value="17.75">17:45 PM</option>
                                <option value="17.83">17:50 PM</option>
                                <option value="17.91">17:55 PM</option>
                                <option value="18.00">18:00 PM - Normal</option>
                                <option value="18.8">18:05 PM</option>
                                <option value="18.16">18:10 PM</option>
                                <option value="18.25">18:15 PM</option>
                                <option value="18.33">18:20 PM</option>
                                <option value="18.41">18:25 PM</option>
                                <option value="18.50">18:30 PM</option>
                                <option value="18.58">18:35 PM</option>
                                <option value="18.66">18:40 PM</option>
                                <option value="18.75">18:45 PM</option>
                                <option value="18.83">18:50 PM</option>
                                <option value="18.91">18:55 PM</option>
                                <option value="19.00">19:00 PM - o'clock</option>
                                <option value="19.8">19:05 PM</option>
                                <option value="19.16">19:10 PM</option>
                                <option value="19.25">19:15 PM</option>
                                <option value="19.33">19:20 PM</option>
                                <option value="19.41">19:25 PM</option>
                                <option value="19.50">19:30 PM</option>
                                <option value="19.58">19:35 PM</option>
                                <option value="19.66">19:40 PM</option>
                                <option value="19.75">19:45 PM</option>
                                <option value="19.83">19:50 PM</option>
                                <option value="19.91">19:55 PM</option>
                                <option value="20.00">20:00 PM - o'clock</option>
                                <option value="20.8">20:05 PM</option>
                                <option value="20.16">20:10 PM</option>
                                <option value="20.25">20:15 PM</option>
                                <option value="20.33">20:20 PM</option>
                                <option value="20.41">20:25 PM</option>
                                <option value="20.50">20:30 PM</option>
                                <option value="20.58">20:35 PM</option>
                                <option value="20.66">20:40 PM</option>
                                <option value="20.75">20:45 PM</option>
                                <option value="20.83">20:50 PM</option>
                                <option value="20.91">20:55 PM</option>
                                <option value="21.00">21:00 PM - o'clock</option>
                                <option value="21.8">21:05 PM</option>
                                <option value="21.16">21:10 PM</option>
                                <option value="21.25">21:15 PM</option>
                                <option value="21.33">21:20 PM</option>
                                <option value="21.41">21:25 PM</option>
                                <option value="21.50">21:30 PM</option>
                                <option value="21.58">21:35 PM</option>
                                <option value="21.66">21:40 PM</option>
                                <option value="21.75">21:45 PM</option>
                                <option value="21.83">21:50 PM</option>
                                <option value="21.91">21:55 PM</option>
                                <option value="22.00">22:00 PM - o'clock</option>
                                <option value="22.8">22:05 PM</option>
                                <option value="22.16">22:10 PM</option>
                                <option value="22.25">22:15 PM</option>
                                <option value="22.33">22:20 PM</option>
                                <option value="22.41">22:25 PM</option>
                                <option value="22.50">22:30 PM</option>
                                <option value="22.58">22:35 PM</option>
                                <option value="22.66">22:40 PM</option>
                                <option value="22.75">22:45 PM</option>
                                <option value="22.83">22:50 PM</option>
                                <option value="22.91">22:55 PM</option>
                                <option value="23.00">23:00 PM - o'clock</option>
                                <option value="23.8">23:05 PM</option>
                                <option value="23.16">23:10 PM</option>
                                <option value="23.25">23:15 PM</option>
                                <option value="23.33">23:20 PM</option>
                                <option value="23.41">23:25 PM</option>
                                <option value="23.50">23:30 PM</option>
                                <option value="23.58">23:35 PM</option>
                                <option value="23.66">23:40 PM</option>
                                <option value="23.75">23:45 PM</option>
                                <option value="23.83">23:50 PM</option>
                                <option value="23.91">23:55 PM</option>
                                <option value="24.00">24:00 PM - o'clock</option>
                                <option value="24.8">15:05 PM</option>
                                <option value="24.16">24:10 PM</option>
                                <option value="24.25">24:15 PM</option>
                                <option value="24.33">24:20 PM</option>
                                <option value="24.41">24:25 PM</option>
                                <option value="24.50">24:30 PM</option>
                                <option value="24.58">24:35 PM</option>
                                <option value="24.66">24:40 PM</option>
                                <option value="24.75">24:45 PM</option>
                                <option value="24.83">24:50 PM</option>
                                <option value="24.91">24:55 PM</option>
                            </select>
                            {data19.map((datas, index) =>{
                              return<div key={index}>
                                        <p>{datas.endhour}</p>
                                    </div>
                                })}
                        </div>
                        <div className="col-md-2">
                            <select name="type19" onChange={e =>  settype19(e.target.value)} className="form-control" required>
                                <option value="">Select Type</option>
                                <option value="Normal">Normal</option>
                                <option value="SJUK">SJUK</option>
                                <option value="Holiday">Holiday</option>
                            </select>
                            {data19.map((datas, index) =>{
                              return<div key={index}>
                                        <p>{datas.type}</p>
                                    </div>
                                })}
                          </div>
                          <div className="col-md-2"> 
                              <button type='submit' className="btn btn-primary">Submit</button>
                        </div>
                      </form>
                  </div>
                  <div className="d-flex flex-column p-2">
                     <form action="" className="row"  onSubmit={handleSubmit20}>
                        <div className="col-md-1"> 20 </div>
                        <div className="col-md-1"> Sunday </div>
                        <div className="col-md-1" > 
                            {data20.map((types, index) =>{
                              return<tr key={index}>
                                   <p style={types.type === "Normal" ? {backgroundColor:'rgba(7, 190, 7, 0.648)', color:'#000', padding:'5px 10px'} : 
                                     (types.type === "Holiday" ? {backgroundColor:'rgba(255, 0, 0, 0.438)', color:'#000', padding:'5px 10px'} : 
                                      {backgroundColor:'#008cff5a', color:'#000', padding:'5px 10px'}) }> {types.type}</p>
                                    </tr>
                                })}
                        </div>
                        <div className="col-md-2">
                            <select name="starthour20" onChange={e =>  setStart20(e.target.value)} className="form-control">
                                <option value="">Start Time</option>
                                <option value="05.00">05:00 AM</option>
                                <option value="06.00">06:00 AM</option>
                                <option value="06.8">06:05 AM</option>
                                <option value="06.16">06:10 AM</option>
                                <option value="06.25">06:15 AM</option>
                                <option value="06.33">06:20 AM</option>
                                <option value="06.41">06:25 AM</option>
                                <option value="06.50">06:30 AM</option>
                                <option value="06.58">06:35 AM</option>
                                <option value="06.66">06:40 AM</option>
                                <option value="06.75">06:45 AM</option>
                                <option value="06.83">06:50 AM</option>
                                <option value="06.91">06:55 AM</option>
                                <option value="07.00">07:00 AM - Normal</option>
                                <option value="07.8">07:05 AM</option>
                                <option value="07.16">07:10 AM</option>
                                <option value="07.25">07:15 AM</option>
                                <option value="07.33">07:20 AM</option>
                                <option value="07.41">07:25 AM</option>
                                <option value="07.50">07:30 AM</option>
                                <option value="07.58">07:35 AM</option>
                                <option value="07.66">07:40 AM</option>
                                <option value="07.75">07:45 AM</option>
                                <option value="07.83">07:50 AM</option>
                                <option value="07.91">07:55 AM</option>
                                <option value="08.00">08:00 AM - o'clock</option>
                                <option value="08.8">08:05 AM</option>
                                <option value="08.16">08:10 AM</option>
                                <option value="08.25">08:15 AM</option>
                                <option value="08.33">08:20 AM</option>
                                <option value="08.41">08:25 AM</option>
                                <option value="08.50">08:30 AM</option>
                                <option value="08.58">08:35 AM</option>
                                <option value="08.66">08:40 AM</option>
                                <option value="08.75">08:45 AM</option>
                                <option value="08.83">08:50 AM</option>
                                <option value="08.91">08:55 AM</option>
                                <option value="09.00">09:00 AM - o'clock</option>
                                <option value="09.8">09:05 AM</option>
                                <option value="09.16">09:10 AM</option>
                                <option value="09.25">09:15 AM</option>
                                <option value="09.33">09:20 AM</option>
                                <option value="09.41">09:25 AM</option>
                                <option value="09.50">09:30 AM</option>
                                <option value="09.58">09:35 AM</option>
                                <option value="09.66">09:40 AM</option>
                                <option value="09.75">09:45 AM</option>
                                <option value="09.83">09:50 AM</option>
                                <option value="09.91">09:55 AM</option>
                                <option value="10.00">10:00 AM - o'clock</option>
                                <option value="10.8">10:05 AM</option>
                                <option value="10.16">10:10 AM</option>
                                <option value="10.25">10:15 AM</option>
                                <option value="10.33">10:20 AM</option>
                                <option value="10.41">10:25 AM</option>
                                <option value="10.50">10:30 AM</option>
                                <option value="10.58">10:35 AM</option>
                                <option value="10.66">10:40 AM</option>
                                <option value="10.75">10:45 AM</option>
                                <option value="10.83">10:50 AM</option>
                                <option value="10.91">10:55 AM</option>
                                <option value="11.00">11:00 AM - o'clock</option>
                                <option value="11.8">11:05 AM</option>
                                <option value="11.16">11:10 AM</option>
                                <option value="11.25">11:15 AM</option>
                                <option value="11.33">11:20 AM</option>
                                <option value="11.41">11:25 AM</option>
                                <option value="11.50">11:30 AM</option>
                                <option value="11.58">11:35 AM</option>
                                <option value="11.66">11:40 AM</option>
                                <option value="11.75">11:45 AM</option>
                                <option value="11.83">11:50 AM</option>
                                <option value="11.91">11:55 AM</option>
                                <option value="12.00">12:00 PM  - o'clock</option>
                                <option value="12.8">12:05 PM</option>
                                <option value="12.16">12:10 PM</option>
                                <option value="12.25">12:15 PM</option>
                                <option value="12.33">12:20 PM</option>
                                <option value="12.41">12:25 PM</option>
                                <option value="12.50">12:30 PM</option>
                                <option value="12.58">12:35 PM</option>
                                <option value="12.66">12:40 PM</option>
                                <option value="12.75">12:45 PM</option>
                                <option value="12.83">12:50 PM</option>
                                <option value="12.91">12:55 PM</option>
                                <option value="13.00">13:00 PM - o'clock</option>
                                <option value="13.8">13:05 PM</option>
                                <option value="13.16">13:10 PM</option>
                                <option value="13.25">13:15 PM</option>
                                <option value="13.33">13:20 PM</option>
                                <option value="13.41">13:25 PM</option>
                                <option value="13.50">13:30 PM</option>
                                <option value="13.58">13:35 PM</option>
                                <option value="13.66">13:40 PM</option>
                                <option value="13.75">13:45 PM</option>
                                <option value="13.83">13:50 PM</option>
                                <option value="13.91">13:55 PM</option>
                                <option value="14.00">14:00 PM - o'clock</option>
                                <option value="14.8">14:05 PM</option>
                                <option value="14.16">14:10 PM</option>
                                <option value="14.25">14:15 PM</option>
                                <option value="14.33">14:20 PM</option>
                                <option value="14.41">14:25 PM</option>
                                <option value="14.50">14:30 PM</option>
                                <option value="14.58">14:35 PM</option>
                                <option value="14.66">14:40 PM</option>
                                <option value="14.75">14:45 PM</option>
                                <option value="14.83">14:50 PM</option>
                                <option value="14.91">14:55 PM</option>
                                <option value="15.00">15:00 PM - o'clock</option>
                                <option value="15.8">15:05 PM</option>
                                <option value="15.16">15:10 PM</option>
                                <option value="15.25">15:15 PM</option>
                                <option value="15.33">15:20 PM</option>
                                <option value="15.41">15:25 PM</option>
                                <option value="15.50">15:30 PM</option>
                                <option value="15.58">15:35 PM</option>
                                <option value="15.66">15:40 PM</option>
                                <option value="15.75">15:45 PM</option>
                                <option value="15.83">15:50 PM</option>
                                <option value="15.91">15:55 PM</option>
                                <option value="16.00">16:00 PM - o'clock</option>
                                <option value="16.8">16:05 PM</option>
                                <option value="16.16">16:10 PM</option>
                                <option value="16.25">16:15 PM</option>
                                <option value="16.33">16:20 PM</option>
                                <option value="16.41">16:25 PM</option>
                                <option value="16.50">16:30 PM</option>
                                <option value="16.58">16:35 PM</option>
                                <option value="16.66">16:40 PM</option>
                                <option value="16.75">16:45 PM</option>
                                <option value="16.83">16:50 PM</option>
                                <option value="16.91">16:55 PM</option>
                                <option value="17.00">17:00 PM - o'clock</option>
                                <option value="17.8">17:05 PM</option>
                                <option value="17.16">17:10 PM</option>
                                <option value="17.25">17:15 PM</option>
                                <option value="17.33">17:20 PM</option>
                                <option value="17.41">17:25 PM</option>
                                <option value="17.50">17:30 PM</option>
                                <option value="17.58">17:35 PM</option>
                                <option value="17.66">17:40 PM</option>
                                <option value="17.75">17:45 PM</option>
                                <option value="17.83">17:50 PM</option>
                                <option value="17.91">17:55 PM</option>
                                <option value="18.00">18:00 PM - Normal</option>
                                <option value="18.8">18:05 PM</option>
                                <option value="18.16">18:10 PM</option>
                                <option value="18.25">18:15 PM</option>
                                <option value="18.33">18:20 PM</option>
                                <option value="18.41">18:25 PM</option>
                                <option value="18.50">18:30 PM</option>
                                <option value="18.58">18:35 PM</option>
                                <option value="18.66">18:40 PM</option>
                                <option value="18.75">18:45 PM</option>
                                <option value="18.83">18:50 PM</option>
                                <option value="18.91">18:55 PM</option>
                                <option value="19.00">19:00 PM - o'clock</option>
                                <option value="19.8">19:05 PM</option>
                                <option value="19.16">19:10 PM</option>
                                <option value="19.25">19:15 PM</option>
                                <option value="19.33">19:20 PM</option>
                                <option value="19.41">19:25 PM</option>
                                <option value="19.50">19:30 PM</option>
                                <option value="19.58">19:35 PM</option>
                                <option value="19.66">19:40 PM</option>
                                <option value="19.75">19:45 PM</option>
                                <option value="19.83">19:50 PM</option>
                                <option value="19.91">19:55 PM</option>
                                <option value="20.00">20:00 PM - o'clock</option>
                                <option value="20.8">20:05 PM</option>
                                <option value="20.16">20:10 PM</option>
                                <option value="20.25">20:15 PM</option>
                                <option value="20.33">20:20 PM</option>
                                <option value="20.41">20:25 PM</option>
                                <option value="20.50">20:30 PM</option>
                                <option value="20.58">20:35 PM</option>
                                <option value="20.66">20:40 PM</option>
                                <option value="20.75">20:45 PM</option>
                                <option value="20.83">20:50 PM</option>
                                <option value="20.91">20:55 PM</option>
                                <option value="21.00">21:00 PM - o'clock</option>
                                <option value="21.8">21:05 PM</option>
                                <option value="21.16">21:10 PM</option>
                                <option value="21.25">21:15 PM</option>
                                <option value="21.33">21:20 PM</option>
                                <option value="21.41">21:25 PM</option>
                                <option value="21.50">21:30 PM</option>
                                <option value="21.58">21:35 PM</option>
                                <option value="21.66">21:40 PM</option>
                                <option value="21.75">21:45 PM</option>
                                <option value="21.83">21:50 PM</option>
                                <option value="21.91">21:55 PM</option>
                                <option value="22.00">22:00 PM - o'clock</option>
                                <option value="22.8">22:05 PM</option>
                                <option value="22.16">22:10 PM</option>
                                <option value="22.25">22:15 PM</option>
                                <option value="22.33">22:20 PM</option>
                                <option value="22.41">22:25 PM</option>
                                <option value="22.50">22:30 PM</option>
                                <option value="22.58">22:35 PM</option>
                                <option value="22.66">22:40 PM</option>
                                <option value="22.75">22:45 PM</option>
                                <option value="22.83">22:50 PM</option>
                                <option value="22.91">22:55 PM</option>
                                <option value="23.00">23:00 PM - o'clock</option>
                                <option value="23.8">23:05 PM</option>
                                <option value="23.16">23:10 PM</option>
                                <option value="23.25">23:15 PM</option>
                                <option value="23.33">23:20 PM</option>
                                <option value="23.41">23:25 PM</option>
                                <option value="23.50">23:30 PM</option>
                                <option value="23.58">23:35 PM</option>
                                <option value="23.66">23:40 PM</option>
                                <option value="23.75">23:45 PM</option>
                                <option value="23.83">23:50 PM</option>
                                <option value="23.91">23:55 PM</option>
                                <option value="24.00">24:00 PM - o'clock</option>
                                <option value="24.8">15:05 PM</option>
                                <option value="24.16">24:10 PM</option>
                                <option value="24.25">24:15 PM</option>
                                <option value="24.33">24:20 PM</option>
                                <option value="24.41">24:25 PM</option>
                                <option value="24.50">24:30 PM</option>
                                <option value="24.58">24:35 PM</option>
                                <option value="24.66">24:40 PM</option>
                                <option value="24.75">24:45 PM</option>
                                <option value="24.83">24:50 PM</option>
                                <option value="24.91">24:55 PM</option>
                            </select>
                            {data20.map((datas, index) =>{
                              return<div key={index}>
                                        <p>{datas.starthour}</p>
                                    </div>
                                })}
                        </div>
                        <div className="col-md-2">
                            <select name="endhour20" onChange={e =>  setEnd20(e.target.value)} className="form-control">
                                <option value="">Finish Time</option>
                                <option value="05.00">05:00 AM</option>
                                <option value="06.00">06:00 AM</option>
                                <option value="06.8">06:05 AM</option>
                                <option value="06.16">06:10 AM</option>
                                <option value="06.25">06:15 AM</option>
                                <option value="06.33">06:20 AM</option>
                                <option value="06.41">06:25 AM</option>
                                <option value="06.50">06:30 AM</option>
                                <option value="06.58">06:35 AM</option>
                                <option value="06.66">06:40 AM</option>
                                <option value="06.75">06:45 AM</option>
                                <option value="06.83">06:50 AM</option>
                                <option value="06.91">06:55 AM</option>
                                <option value="07.00">07:00 AM - Normal</option>
                                <option value="07.8">07:05 AM</option>
                                <option value="07.16">07:10 AM</option>
                                <option value="07.25">07:15 AM</option>
                                <option value="07.33">07:20 AM</option>
                                <option value="07.41">07:25 AM</option>
                                <option value="07.50">07:30 AM</option>
                                <option value="07.58">07:35 AM</option>
                                <option value="07.66">07:40 AM</option>
                                <option value="07.75">07:45 AM</option>
                                <option value="07.83">07:50 AM</option>
                                <option value="07.91">07:55 AM</option>
                                <option value="08.00">08:00 AM - o'clock</option>
                                <option value="08.8">08:05 AM</option>
                                <option value="08.16">08:10 AM</option>
                                <option value="08.25">08:15 AM</option>
                                <option value="08.33">08:20 AM</option>
                                <option value="08.41">08:25 AM</option>
                                <option value="08.50">08:30 AM</option>
                                <option value="08.58">08:35 AM</option>
                                <option value="08.66">08:40 AM</option>
                                <option value="08.75">08:45 AM</option>
                                <option value="08.83">08:50 AM</option>
                                <option value="08.91">08:55 AM</option>
                                <option value="09.00">09:00 AM - o'clock</option>
                                <option value="09.8">09:05 AM</option>
                                <option value="09.16">09:10 AM</option>
                                <option value="09.25">09:15 AM</option>
                                <option value="09.33">09:20 AM</option>
                                <option value="09.41">09:25 AM</option>
                                <option value="09.50">09:30 AM</option>
                                <option value="09.58">09:35 AM</option>
                                <option value="09.66">09:40 AM</option>
                                <option value="09.75">09:45 AM</option>
                                <option value="09.83">09:50 AM</option>
                                <option value="09.91">09:55 AM</option>
                                <option value="10.00">10:00 AM - o'clock</option>
                                <option value="10.8">10:05 AM</option>
                                <option value="10.16">10:10 AM</option>
                                <option value="10.25">10:15 AM</option>
                                <option value="10.33">10:20 AM</option>
                                <option value="10.41">10:25 AM</option>
                                <option value="10.50">10:30 AM</option>
                                <option value="10.58">10:35 AM</option>
                                <option value="10.66">10:40 AM</option>
                                <option value="10.75">10:45 AM</option>
                                <option value="10.83">10:50 AM</option>
                                <option value="10.91">10:55 AM</option>
                                <option value="11.00">11:00 AM - o'clock</option>
                                <option value="11.8">11:05 AM</option>
                                <option value="11.16">11:10 AM</option>
                                <option value="11.25">11:15 AM</option>
                                <option value="11.33">11:20 AM</option>
                                <option value="11.41">11:25 AM</option>
                                <option value="11.50">11:30 AM</option>
                                <option value="11.58">11:35 AM</option>
                                <option value="11.66">11:40 AM</option>
                                <option value="11.75">11:45 AM</option>
                                <option value="11.83">11:50 AM</option>
                                <option value="11.91">11:55 AM</option>
                                <option value="12.00">12:00 PM  - o'clock</option>
                                <option value="12.8">12:05 PM</option>
                                <option value="12.16">12:10 PM</option>
                                <option value="12.25">12:15 PM</option>
                                <option value="12.33">12:20 PM</option>
                                <option value="12.41">12:25 PM</option>
                                <option value="12.50">12:30 PM</option>
                                <option value="12.58">12:35 PM</option>
                                <option value="12.66">12:40 PM</option>
                                <option value="12.75">12:45 PM</option>
                                <option value="12.83">12:50 PM</option>
                                <option value="12.91">12:55 PM</option>
                                <option value="13.00">13:00 PM - o'clock</option>
                                <option value="13.8">13:05 PM</option>
                                <option value="13.16">13:10 PM</option>
                                <option value="13.25">13:15 PM</option>
                                <option value="13.33">13:20 PM</option>
                                <option value="13.41">13:25 PM</option>
                                <option value="13.50">13:30 PM</option>
                                <option value="13.58">13:35 PM</option>
                                <option value="13.66">13:40 PM</option>
                                <option value="13.75">13:45 PM</option>
                                <option value="13.83">13:50 PM</option>
                                <option value="13.91">13:55 PM</option>
                                <option value="14.00">14:00 PM - o'clock</option>
                                <option value="14.8">14:05 PM</option>
                                <option value="14.16">14:10 PM</option>
                                <option value="14.25">14:15 PM</option>
                                <option value="14.33">14:20 PM</option>
                                <option value="14.41">14:25 PM</option>
                                <option value="14.50">14:30 PM</option>
                                <option value="14.58">14:35 PM</option>
                                <option value="14.66">14:40 PM</option>
                                <option value="14.75">14:45 PM</option>
                                <option value="14.83">14:50 PM</option>
                                <option value="14.91">14:55 PM</option>
                                <option value="15.00">15:00 PM - o'clock</option>
                                <option value="15.8">15:05 PM</option>
                                <option value="15.16">15:10 PM</option>
                                <option value="15.25">15:15 PM</option>
                                <option value="15.33">15:20 PM</option>
                                <option value="15.41">15:25 PM</option>
                                <option value="15.50">15:30 PM</option>
                                <option value="15.58">15:35 PM</option>
                                <option value="15.66">15:40 PM</option>
                                <option value="15.75">15:45 PM</option>
                                <option value="15.83">15:50 PM</option>
                                <option value="15.91">15:55 PM</option>
                                <option value="16.00">16:00 PM - o'clock</option>
                                <option value="16.8">16:05 PM</option>
                                <option value="16.16">16:10 PM</option>
                                <option value="16.25">16:15 PM</option>
                                <option value="16.33">16:20 PM</option>
                                <option value="16.41">16:25 PM</option>
                                <option value="16.50">16:30 PM</option>
                                <option value="16.58">16:35 PM</option>
                                <option value="16.66">16:40 PM</option>
                                <option value="16.75">16:45 PM</option>
                                <option value="16.83">16:50 PM</option>
                                <option value="16.91">16:55 PM</option>
                                <option value="17.00">17:00 PM - o'clock</option>
                                <option value="17.8">17:05 PM</option>
                                <option value="17.16">17:10 PM</option>
                                <option value="17.25">17:15 PM</option>
                                <option value="17.33">17:20 PM</option>
                                <option value="17.41">17:25 PM</option>
                                <option value="17.50">17:30 PM</option>
                                <option value="17.58">17:35 PM</option>
                                <option value="17.66">17:40 PM</option>
                                <option value="17.75">17:45 PM</option>
                                <option value="17.83">17:50 PM</option>
                                <option value="17.91">17:55 PM</option>
                                <option value="18.00">18:00 PM - Normal</option>
                                <option value="18.8">18:05 PM</option>
                                <option value="18.16">18:10 PM</option>
                                <option value="18.25">18:15 PM</option>
                                <option value="18.33">18:20 PM</option>
                                <option value="18.41">18:25 PM</option>
                                <option value="18.50">18:30 PM</option>
                                <option value="18.58">18:35 PM</option>
                                <option value="18.66">18:40 PM</option>
                                <option value="18.75">18:45 PM</option>
                                <option value="18.83">18:50 PM</option>
                                <option value="18.91">18:55 PM</option>
                                <option value="19.00">19:00 PM - o'clock</option>
                                <option value="19.8">19:05 PM</option>
                                <option value="19.16">19:10 PM</option>
                                <option value="19.25">19:15 PM</option>
                                <option value="19.33">19:20 PM</option>
                                <option value="19.41">19:25 PM</option>
                                <option value="19.50">19:30 PM</option>
                                <option value="19.58">19:35 PM</option>
                                <option value="19.66">19:40 PM</option>
                                <option value="19.75">19:45 PM</option>
                                <option value="19.83">19:50 PM</option>
                                <option value="19.91">19:55 PM</option>
                                <option value="20.00">20:00 PM - o'clock</option>
                                <option value="20.8">20:05 PM</option>
                                <option value="20.16">20:10 PM</option>
                                <option value="20.25">20:15 PM</option>
                                <option value="20.33">20:20 PM</option>
                                <option value="20.41">20:25 PM</option>
                                <option value="20.50">20:30 PM</option>
                                <option value="20.58">20:35 PM</option>
                                <option value="20.66">20:40 PM</option>
                                <option value="20.75">20:45 PM</option>
                                <option value="20.83">20:50 PM</option>
                                <option value="20.91">20:55 PM</option>
                                <option value="21.00">21:00 PM - o'clock</option>
                                <option value="21.8">21:05 PM</option>
                                <option value="21.16">21:10 PM</option>
                                <option value="21.25">21:15 PM</option>
                                <option value="21.33">21:20 PM</option>
                                <option value="21.41">21:25 PM</option>
                                <option value="21.50">21:30 PM</option>
                                <option value="21.58">21:35 PM</option>
                                <option value="21.66">21:40 PM</option>
                                <option value="21.75">21:45 PM</option>
                                <option value="21.83">21:50 PM</option>
                                <option value="21.91">21:55 PM</option>
                                <option value="22.00">22:00 PM - o'clock</option>
                                <option value="22.8">22:05 PM</option>
                                <option value="22.16">22:10 PM</option>
                                <option value="22.25">22:15 PM</option>
                                <option value="22.33">22:20 PM</option>
                                <option value="22.41">22:25 PM</option>
                                <option value="22.50">22:30 PM</option>
                                <option value="22.58">22:35 PM</option>
                                <option value="22.66">22:40 PM</option>
                                <option value="22.75">22:45 PM</option>
                                <option value="22.83">22:50 PM</option>
                                <option value="22.91">22:55 PM</option>
                                <option value="23.00">23:00 PM - o'clock</option>
                                <option value="23.8">23:05 PM</option>
                                <option value="23.16">23:10 PM</option>
                                <option value="23.25">23:15 PM</option>
                                <option value="23.33">23:20 PM</option>
                                <option value="23.41">23:25 PM</option>
                                <option value="23.50">23:30 PM</option>
                                <option value="23.58">23:35 PM</option>
                                <option value="23.66">23:40 PM</option>
                                <option value="23.75">23:45 PM</option>
                                <option value="23.83">23:50 PM</option>
                                <option value="23.91">23:55 PM</option>
                                <option value="24.00">24:00 PM - o'clock</option>
                                <option value="24.8">15:05 PM</option>
                                <option value="24.16">24:10 PM</option>
                                <option value="24.25">24:15 PM</option>
                                <option value="24.33">24:20 PM</option>
                                <option value="24.41">24:25 PM</option>
                                <option value="24.50">24:30 PM</option>
                                <option value="24.58">24:35 PM</option>
                                <option value="24.66">24:40 PM</option>
                                <option value="24.75">24:45 PM</option>
                                <option value="24.83">24:50 PM</option>
                                <option value="24.91">24:55 PM</option>
                            </select>
                            {data20.map((datas, index) =>{
                              return<div key={index}>
                                        <p>{datas.endhour}</p>
                                    </div>
                                })}
                        </div>
                        <div className="col-md-2">
                            <select name="type20" onChange={e =>  settype20(e.target.value)} className="form-control" required>
                                <option value="">Select Type</option>
                                <option value="Normal">Normal</option>
                                <option value="SJUK">SJUK</option>
                                <option value="Holiday">Holiday</option>
                            </select>
                            {data20.map((datas, index) =>{
                              return<div key={index}>
                                        <p>{datas.type}</p>
                                    </div>
                                })}
                          </div>
                          <div className="col-md-2"> 
                              <button type='submit' className="btn btn-primary">Submit</button>
                        </div>
                      </form>
                  </div>
                  <div className="d-flex flex-column p-2">
                     <form action="" className="row"  onSubmit={handleSubmit21}>
                        <div className="col-md-1"> 21 </div>
                        <div className="col-md-1"> Monday</div>
                        <div className="col-md-1" > 
                            {data21.map((types, index) =>{
                              return<tr key={index}>
                                   <p style={types.type === "Normal" ? {backgroundColor:'rgba(7, 190, 7, 0.648)', color:'#000', padding:'5px 10px'} : 
                                     (types.type === "Holiday" ? {backgroundColor:'rgba(255, 0, 0, 0.438)', color:'#000', padding:'5px 10px'} : 
                                      {backgroundColor:'#008cff5a', color:'#000', padding:'5px 10px'}) }> {types.type}</p>
                                    </tr>
                                })}
                        </div>
                        <div className="col-md-2">
                            <select name="starthour21" onChange={e =>  setStart21(e.target.value)} className="form-control">
                                <option value="">Start Time</option>
                                <option value="05.00">05:00 AM</option>
                                <option value="06.00">06:00 AM</option>
                                <option value="06.8">06:05 AM</option>
                                <option value="06.16">06:10 AM</option>
                                <option value="06.25">06:15 AM</option>
                                <option value="06.33">06:20 AM</option>
                                <option value="06.41">06:25 AM</option>
                                <option value="06.50">06:30 AM</option>
                                <option value="06.58">06:35 AM</option>
                                <option value="06.66">06:40 AM</option>
                                <option value="06.75">06:45 AM</option>
                                <option value="06.83">06:50 AM</option>
                                <option value="06.91">06:55 AM</option>
                                <option value="07.00">07:00 AM - Normal</option>
                                <option value="07.8">07:05 AM</option>
                                <option value="07.16">07:10 AM</option>
                                <option value="07.25">07:15 AM</option>
                                <option value="07.33">07:20 AM</option>
                                <option value="07.41">07:25 AM</option>
                                <option value="07.50">07:30 AM</option>
                                <option value="07.58">07:35 AM</option>
                                <option value="07.66">07:40 AM</option>
                                <option value="07.75">07:45 AM</option>
                                <option value="07.83">07:50 AM</option>
                                <option value="07.91">07:55 AM</option>
                                <option value="08.00">08:00 AM - o'clock</option>
                                <option value="08.8">08:05 AM</option>
                                <option value="08.16">08:10 AM</option>
                                <option value="08.25">08:15 AM</option>
                                <option value="08.33">08:20 AM</option>
                                <option value="08.41">08:25 AM</option>
                                <option value="08.50">08:30 AM</option>
                                <option value="08.58">08:35 AM</option>
                                <option value="08.66">08:40 AM</option>
                                <option value="08.75">08:45 AM</option>
                                <option value="08.83">08:50 AM</option>
                                <option value="08.91">08:55 AM</option>
                                <option value="09.00">09:00 AM - o'clock</option>
                                <option value="09.8">09:05 AM</option>
                                <option value="09.16">09:10 AM</option>
                                <option value="09.25">09:15 AM</option>
                                <option value="09.33">09:20 AM</option>
                                <option value="09.41">09:25 AM</option>
                                <option value="09.50">09:30 AM</option>
                                <option value="09.58">09:35 AM</option>
                                <option value="09.66">09:40 AM</option>
                                <option value="09.75">09:45 AM</option>
                                <option value="09.83">09:50 AM</option>
                                <option value="09.91">09:55 AM</option>
                                <option value="10.00">10:00 AM - o'clock</option>
                                <option value="10.8">10:05 AM</option>
                                <option value="10.16">10:10 AM</option>
                                <option value="10.25">10:15 AM</option>
                                <option value="10.33">10:20 AM</option>
                                <option value="10.41">10:25 AM</option>
                                <option value="10.50">10:30 AM</option>
                                <option value="10.58">10:35 AM</option>
                                <option value="10.66">10:40 AM</option>
                                <option value="10.75">10:45 AM</option>
                                <option value="10.83">10:50 AM</option>
                                <option value="10.91">10:55 AM</option>
                                <option value="11.00">11:00 AM - o'clock</option>
                                <option value="11.8">11:05 AM</option>
                                <option value="11.16">11:10 AM</option>
                                <option value="11.25">11:15 AM</option>
                                <option value="11.33">11:20 AM</option>
                                <option value="11.41">11:25 AM</option>
                                <option value="11.50">11:30 AM</option>
                                <option value="11.58">11:35 AM</option>
                                <option value="11.66">11:40 AM</option>
                                <option value="11.75">11:45 AM</option>
                                <option value="11.83">11:50 AM</option>
                                <option value="11.91">11:55 AM</option>
                                <option value="12.00">12:00 PM  - o'clock</option>
                                <option value="12.8">12:05 PM</option>
                                <option value="12.16">12:10 PM</option>
                                <option value="12.25">12:15 PM</option>
                                <option value="12.33">12:20 PM</option>
                                <option value="12.41">12:25 PM</option>
                                <option value="12.50">12:30 PM</option>
                                <option value="12.58">12:35 PM</option>
                                <option value="12.66">12:40 PM</option>
                                <option value="12.75">12:45 PM</option>
                                <option value="12.83">12:50 PM</option>
                                <option value="12.91">12:55 PM</option>
                                <option value="13.00">13:00 PM - o'clock</option>
                                <option value="13.8">13:05 PM</option>
                                <option value="13.16">13:10 PM</option>
                                <option value="13.25">13:15 PM</option>
                                <option value="13.33">13:20 PM</option>
                                <option value="13.41">13:25 PM</option>
                                <option value="13.50">13:30 PM</option>
                                <option value="13.58">13:35 PM</option>
                                <option value="13.66">13:40 PM</option>
                                <option value="13.75">13:45 PM</option>
                                <option value="13.83">13:50 PM</option>
                                <option value="13.91">13:55 PM</option>
                                <option value="14.00">14:00 PM - o'clock</option>
                                <option value="14.8">14:05 PM</option>
                                <option value="14.16">14:10 PM</option>
                                <option value="14.25">14:15 PM</option>
                                <option value="14.33">14:20 PM</option>
                                <option value="14.41">14:25 PM</option>
                                <option value="14.50">14:30 PM</option>
                                <option value="14.58">14:35 PM</option>
                                <option value="14.66">14:40 PM</option>
                                <option value="14.75">14:45 PM</option>
                                <option value="14.83">14:50 PM</option>
                                <option value="14.91">14:55 PM</option>
                                <option value="15.00">15:00 PM - o'clock</option>
                                <option value="15.8">15:05 PM</option>
                                <option value="15.16">15:10 PM</option>
                                <option value="15.25">15:15 PM</option>
                                <option value="15.33">15:20 PM</option>
                                <option value="15.41">15:25 PM</option>
                                <option value="15.50">15:30 PM</option>
                                <option value="15.58">15:35 PM</option>
                                <option value="15.66">15:40 PM</option>
                                <option value="15.75">15:45 PM</option>
                                <option value="15.83">15:50 PM</option>
                                <option value="15.91">15:55 PM</option>
                                <option value="16.00">16:00 PM - o'clock</option>
                                <option value="16.8">16:05 PM</option>
                                <option value="16.16">16:10 PM</option>
                                <option value="16.25">16:15 PM</option>
                                <option value="16.33">16:20 PM</option>
                                <option value="16.41">16:25 PM</option>
                                <option value="16.50">16:30 PM</option>
                                <option value="16.58">16:35 PM</option>
                                <option value="16.66">16:40 PM</option>
                                <option value="16.75">16:45 PM</option>
                                <option value="16.83">16:50 PM</option>
                                <option value="16.91">16:55 PM</option>
                                <option value="17.00">17:00 PM - o'clock</option>
                                <option value="17.8">17:05 PM</option>
                                <option value="17.16">17:10 PM</option>
                                <option value="17.25">17:15 PM</option>
                                <option value="17.33">17:20 PM</option>
                                <option value="17.41">17:25 PM</option>
                                <option value="17.50">17:30 PM</option>
                                <option value="17.58">17:35 PM</option>
                                <option value="17.66">17:40 PM</option>
                                <option value="17.75">17:45 PM</option>
                                <option value="17.83">17:50 PM</option>
                                <option value="17.91">17:55 PM</option>
                                <option value="18.00">18:00 PM - Normal</option>
                                <option value="18.8">18:05 PM</option>
                                <option value="18.16">18:10 PM</option>
                                <option value="18.25">18:15 PM</option>
                                <option value="18.33">18:20 PM</option>
                                <option value="18.41">18:25 PM</option>
                                <option value="18.50">18:30 PM</option>
                                <option value="18.58">18:35 PM</option>
                                <option value="18.66">18:40 PM</option>
                                <option value="18.75">18:45 PM</option>
                                <option value="18.83">18:50 PM</option>
                                <option value="18.91">18:55 PM</option>
                                <option value="19.00">19:00 PM - o'clock</option>
                                <option value="19.8">19:05 PM</option>
                                <option value="19.16">19:10 PM</option>
                                <option value="19.25">19:15 PM</option>
                                <option value="19.33">19:20 PM</option>
                                <option value="19.41">19:25 PM</option>
                                <option value="19.50">19:30 PM</option>
                                <option value="19.58">19:35 PM</option>
                                <option value="19.66">19:40 PM</option>
                                <option value="19.75">19:45 PM</option>
                                <option value="19.83">19:50 PM</option>
                                <option value="19.91">19:55 PM</option>
                                <option value="20.00">20:00 PM - o'clock</option>
                                <option value="20.8">20:05 PM</option>
                                <option value="20.16">20:10 PM</option>
                                <option value="20.25">20:15 PM</option>
                                <option value="20.33">20:20 PM</option>
                                <option value="20.41">20:25 PM</option>
                                <option value="20.50">20:30 PM</option>
                                <option value="20.58">20:35 PM</option>
                                <option value="20.66">20:40 PM</option>
                                <option value="20.75">20:45 PM</option>
                                <option value="20.83">20:50 PM</option>
                                <option value="20.91">20:55 PM</option>
                                <option value="21.00">21:00 PM - o'clock</option>
                                <option value="21.8">21:05 PM</option>
                                <option value="21.16">21:10 PM</option>
                                <option value="21.25">21:15 PM</option>
                                <option value="21.33">21:20 PM</option>
                                <option value="21.41">21:25 PM</option>
                                <option value="21.50">21:30 PM</option>
                                <option value="21.58">21:35 PM</option>
                                <option value="21.66">21:40 PM</option>
                                <option value="21.75">21:45 PM</option>
                                <option value="21.83">21:50 PM</option>
                                <option value="21.91">21:55 PM</option>
                                <option value="22.00">22:00 PM - o'clock</option>
                                <option value="22.8">22:05 PM</option>
                                <option value="22.16">22:10 PM</option>
                                <option value="22.25">22:15 PM</option>
                                <option value="22.33">22:20 PM</option>
                                <option value="22.41">22:25 PM</option>
                                <option value="22.50">22:30 PM</option>
                                <option value="22.58">22:35 PM</option>
                                <option value="22.66">22:40 PM</option>
                                <option value="22.75">22:45 PM</option>
                                <option value="22.83">22:50 PM</option>
                                <option value="22.91">22:55 PM</option>
                                <option value="23.00">23:00 PM - o'clock</option>
                                <option value="23.8">23:05 PM</option>
                                <option value="23.16">23:10 PM</option>
                                <option value="23.25">23:15 PM</option>
                                <option value="23.33">23:20 PM</option>
                                <option value="23.41">23:25 PM</option>
                                <option value="23.50">23:30 PM</option>
                                <option value="23.58">23:35 PM</option>
                                <option value="23.66">23:40 PM</option>
                                <option value="23.75">23:45 PM</option>
                                <option value="23.83">23:50 PM</option>
                                <option value="23.91">23:55 PM</option>
                                <option value="24.00">24:00 PM - o'clock</option>
                                <option value="24.8">15:05 PM</option>
                                <option value="24.16">24:10 PM</option>
                                <option value="24.25">24:15 PM</option>
                                <option value="24.33">24:20 PM</option>
                                <option value="24.41">24:25 PM</option>
                                <option value="24.50">24:30 PM</option>
                                <option value="24.58">24:35 PM</option>
                                <option value="24.66">24:40 PM</option>
                                <option value="24.75">24:45 PM</option>
                                <option value="24.83">24:50 PM</option>
                                <option value="24.91">24:55 PM</option>
                            </select>
                            {data21.map((datas, index) =>{
                              return<div key={index}>
                                        <p>{datas.starthour}</p>
                                    </div>
                                })}
                        </div>
                        <div className="col-md-2">
                            <select name="endhour21" onChange={e =>  setEnd21(e.target.value)} className="form-control">
                                <option value="">Finish Time</option>
                                <option value="05.00">05:00 AM</option>
                                <option value="06.00">06:00 AM</option>
                                <option value="06.8">06:05 AM</option>
                                <option value="06.16">06:10 AM</option>
                                <option value="06.25">06:15 AM</option>
                                <option value="06.33">06:20 AM</option>
                                <option value="06.41">06:25 AM</option>
                                <option value="06.50">06:30 AM</option>
                                <option value="06.58">06:35 AM</option>
                                <option value="06.66">06:40 AM</option>
                                <option value="06.75">06:45 AM</option>
                                <option value="06.83">06:50 AM</option>
                                <option value="06.91">06:55 AM</option>
                                <option value="07.00">07:00 AM - Normal</option>
                                <option value="07.8">07:05 AM</option>
                                <option value="07.16">07:10 AM</option>
                                <option value="07.25">07:15 AM</option>
                                <option value="07.33">07:20 AM</option>
                                <option value="07.41">07:25 AM</option>
                                <option value="07.50">07:30 AM</option>
                                <option value="07.58">07:35 AM</option>
                                <option value="07.66">07:40 AM</option>
                                <option value="07.75">07:45 AM</option>
                                <option value="07.83">07:50 AM</option>
                                <option value="07.91">07:55 AM</option>
                                <option value="08.00">08:00 AM - o'clock</option>
                                <option value="08.8">08:05 AM</option>
                                <option value="08.16">08:10 AM</option>
                                <option value="08.25">08:15 AM</option>
                                <option value="08.33">08:20 AM</option>
                                <option value="08.41">08:25 AM</option>
                                <option value="08.50">08:30 AM</option>
                                <option value="08.58">08:35 AM</option>
                                <option value="08.66">08:40 AM</option>
                                <option value="08.75">08:45 AM</option>
                                <option value="08.83">08:50 AM</option>
                                <option value="08.91">08:55 AM</option>
                                <option value="09.00">09:00 AM - o'clock</option>
                                <option value="09.8">09:05 AM</option>
                                <option value="09.16">09:10 AM</option>
                                <option value="09.25">09:15 AM</option>
                                <option value="09.33">09:20 AM</option>
                                <option value="09.41">09:25 AM</option>
                                <option value="09.50">09:30 AM</option>
                                <option value="09.58">09:35 AM</option>
                                <option value="09.66">09:40 AM</option>
                                <option value="09.75">09:45 AM</option>
                                <option value="09.83">09:50 AM</option>
                                <option value="09.91">09:55 AM</option>
                                <option value="10.00">10:00 AM - o'clock</option>
                                <option value="10.8">10:05 AM</option>
                                <option value="10.16">10:10 AM</option>
                                <option value="10.25">10:15 AM</option>
                                <option value="10.33">10:20 AM</option>
                                <option value="10.41">10:25 AM</option>
                                <option value="10.50">10:30 AM</option>
                                <option value="10.58">10:35 AM</option>
                                <option value="10.66">10:40 AM</option>
                                <option value="10.75">10:45 AM</option>
                                <option value="10.83">10:50 AM</option>
                                <option value="10.91">10:55 AM</option>
                                <option value="11.00">11:00 AM - o'clock</option>
                                <option value="11.8">11:05 AM</option>
                                <option value="11.16">11:10 AM</option>
                                <option value="11.25">11:15 AM</option>
                                <option value="11.33">11:20 AM</option>
                                <option value="11.41">11:25 AM</option>
                                <option value="11.50">11:30 AM</option>
                                <option value="11.58">11:35 AM</option>
                                <option value="11.66">11:40 AM</option>
                                <option value="11.75">11:45 AM</option>
                                <option value="11.83">11:50 AM</option>
                                <option value="11.91">11:55 AM</option>
                                <option value="12.00">12:00 PM  - o'clock</option>
                                <option value="12.8">12:05 PM</option>
                                <option value="12.16">12:10 PM</option>
                                <option value="12.25">12:15 PM</option>
                                <option value="12.33">12:20 PM</option>
                                <option value="12.41">12:25 PM</option>
                                <option value="12.50">12:30 PM</option>
                                <option value="12.58">12:35 PM</option>
                                <option value="12.66">12:40 PM</option>
                                <option value="12.75">12:45 PM</option>
                                <option value="12.83">12:50 PM</option>
                                <option value="12.91">12:55 PM</option>
                                <option value="13.00">13:00 PM - o'clock</option>
                                <option value="13.8">13:05 PM</option>
                                <option value="13.16">13:10 PM</option>
                                <option value="13.25">13:15 PM</option>
                                <option value="13.33">13:20 PM</option>
                                <option value="13.41">13:25 PM</option>
                                <option value="13.50">13:30 PM</option>
                                <option value="13.58">13:35 PM</option>
                                <option value="13.66">13:40 PM</option>
                                <option value="13.75">13:45 PM</option>
                                <option value="13.83">13:50 PM</option>
                                <option value="13.91">13:55 PM</option>
                                <option value="14.00">14:00 PM - o'clock</option>
                                <option value="14.8">14:05 PM</option>
                                <option value="14.16">14:10 PM</option>
                                <option value="14.25">14:15 PM</option>
                                <option value="14.33">14:20 PM</option>
                                <option value="14.41">14:25 PM</option>
                                <option value="14.50">14:30 PM</option>
                                <option value="14.58">14:35 PM</option>
                                <option value="14.66">14:40 PM</option>
                                <option value="14.75">14:45 PM</option>
                                <option value="14.83">14:50 PM</option>
                                <option value="14.91">14:55 PM</option>
                                <option value="15.00">15:00 PM - o'clock</option>
                                <option value="15.8">15:05 PM</option>
                                <option value="15.16">15:10 PM</option>
                                <option value="15.25">15:15 PM</option>
                                <option value="15.33">15:20 PM</option>
                                <option value="15.41">15:25 PM</option>
                                <option value="15.50">15:30 PM</option>
                                <option value="15.58">15:35 PM</option>
                                <option value="15.66">15:40 PM</option>
                                <option value="15.75">15:45 PM</option>
                                <option value="15.83">15:50 PM</option>
                                <option value="15.91">15:55 PM</option>
                                <option value="16.00">16:00 PM - o'clock</option>
                                <option value="16.8">16:05 PM</option>
                                <option value="16.16">16:10 PM</option>
                                <option value="16.25">16:15 PM</option>
                                <option value="16.33">16:20 PM</option>
                                <option value="16.41">16:25 PM</option>
                                <option value="16.50">16:30 PM</option>
                                <option value="16.58">16:35 PM</option>
                                <option value="16.66">16:40 PM</option>
                                <option value="16.75">16:45 PM</option>
                                <option value="16.83">16:50 PM</option>
                                <option value="16.91">16:55 PM</option>
                                <option value="17.00">17:00 PM - o'clock</option>
                                <option value="17.8">17:05 PM</option>
                                <option value="17.16">17:10 PM</option>
                                <option value="17.25">17:15 PM</option>
                                <option value="17.33">17:20 PM</option>
                                <option value="17.41">17:25 PM</option>
                                <option value="17.50">17:30 PM</option>
                                <option value="17.58">17:35 PM</option>
                                <option value="17.66">17:40 PM</option>
                                <option value="17.75">17:45 PM</option>
                                <option value="17.83">17:50 PM</option>
                                <option value="17.91">17:55 PM</option>
                                <option value="18.00">18:00 PM - Normal</option>
                                <option value="18.8">18:05 PM</option>
                                <option value="18.16">18:10 PM</option>
                                <option value="18.25">18:15 PM</option>
                                <option value="18.33">18:20 PM</option>
                                <option value="18.41">18:25 PM</option>
                                <option value="18.50">18:30 PM</option>
                                <option value="18.58">18:35 PM</option>
                                <option value="18.66">18:40 PM</option>
                                <option value="18.75">18:45 PM</option>
                                <option value="18.83">18:50 PM</option>
                                <option value="18.91">18:55 PM</option>
                                <option value="19.00">19:00 PM - o'clock</option>
                                <option value="19.8">19:05 PM</option>
                                <option value="19.16">19:10 PM</option>
                                <option value="19.25">19:15 PM</option>
                                <option value="19.33">19:20 PM</option>
                                <option value="19.41">19:25 PM</option>
                                <option value="19.50">19:30 PM</option>
                                <option value="19.58">19:35 PM</option>
                                <option value="19.66">19:40 PM</option>
                                <option value="19.75">19:45 PM</option>
                                <option value="19.83">19:50 PM</option>
                                <option value="19.91">19:55 PM</option>
                                <option value="20.00">20:00 PM - o'clock</option>
                                <option value="20.8">20:05 PM</option>
                                <option value="20.16">20:10 PM</option>
                                <option value="20.25">20:15 PM</option>
                                <option value="20.33">20:20 PM</option>
                                <option value="20.41">20:25 PM</option>
                                <option value="20.50">20:30 PM</option>
                                <option value="20.58">20:35 PM</option>
                                <option value="20.66">20:40 PM</option>
                                <option value="20.75">20:45 PM</option>
                                <option value="20.83">20:50 PM</option>
                                <option value="20.91">20:55 PM</option>
                                <option value="21.00">21:00 PM - o'clock</option>
                                <option value="21.8">21:05 PM</option>
                                <option value="21.16">21:10 PM</option>
                                <option value="21.25">21:15 PM</option>
                                <option value="21.33">21:20 PM</option>
                                <option value="21.41">21:25 PM</option>
                                <option value="21.50">21:30 PM</option>
                                <option value="21.58">21:35 PM</option>
                                <option value="21.66">21:40 PM</option>
                                <option value="21.75">21:45 PM</option>
                                <option value="21.83">21:50 PM</option>
                                <option value="21.91">21:55 PM</option>
                                <option value="22.00">22:00 PM - o'clock</option>
                                <option value="22.8">22:05 PM</option>
                                <option value="22.16">22:10 PM</option>
                                <option value="22.25">22:15 PM</option>
                                <option value="22.33">22:20 PM</option>
                                <option value="22.41">22:25 PM</option>
                                <option value="22.50">22:30 PM</option>
                                <option value="22.58">22:35 PM</option>
                                <option value="22.66">22:40 PM</option>
                                <option value="22.75">22:45 PM</option>
                                <option value="22.83">22:50 PM</option>
                                <option value="22.91">22:55 PM</option>
                                <option value="23.00">23:00 PM - o'clock</option>
                                <option value="23.8">23:05 PM</option>
                                <option value="23.16">23:10 PM</option>
                                <option value="23.25">23:15 PM</option>
                                <option value="23.33">23:20 PM</option>
                                <option value="23.41">23:25 PM</option>
                                <option value="23.50">23:30 PM</option>
                                <option value="23.58">23:35 PM</option>
                                <option value="23.66">23:40 PM</option>
                                <option value="23.75">23:45 PM</option>
                                <option value="23.83">23:50 PM</option>
                                <option value="23.91">23:55 PM</option>
                                <option value="24.00">24:00 PM - o'clock</option>
                                <option value="24.8">15:05 PM</option>
                                <option value="24.16">24:10 PM</option>
                                <option value="24.25">24:15 PM</option>
                                <option value="24.33">24:20 PM</option>
                                <option value="24.41">24:25 PM</option>
                                <option value="24.50">24:30 PM</option>
                                <option value="24.58">24:35 PM</option>
                                <option value="24.66">24:40 PM</option>
                                <option value="24.75">24:45 PM</option>
                                <option value="24.83">24:50 PM</option>
                                <option value="24.91">24:55 PM</option>
                            </select>
                            {data21.map((datas, index) =>{
                              return<div key={index}>
                                        <p>{datas.endhour}</p>
                                    </div>
                                })}
                        </div>
                        <div className="col-md-2">
                            <select name="type21" onChange={e =>  settype21(e.target.value)} className="form-control" required>
                                <option value="">Select Type</option>
                                <option value="Normal">Normal</option>
                                <option value="SJUK">SJUK</option>
                                <option value="Holiday">Holiday</option>
                            </select>
                            {data21.map((datas, index) =>{
                              return<div key={index}>
                                        <p>{datas.type}</p>
                                    </div>
                                })}
                          </div>
                          <div className="col-md-2"> 
                              <button type='submit' className="btn btn-primary">Submit</button>
                        </div>
                      </form>
                  </div>
                  <div className="d-flex flex-column p-2">
                     <form action="" className="row"  onSubmit={handleSubmit22}>
                        <div className="col-md-1"> 22 </div>
                        <div className="col-md-1"> Tuesday </div>
                        <div className="col-md-1" > 
                            {data22.map((types, index) =>{
                              return<tr key={index}>
                                   <p style={types.type === "Normal" ? {backgroundColor:'rgba(7, 190, 7, 0.648)', color:'#000', padding:'5px 10px'} : 
                                     (types.type === "Holiday" ? {backgroundColor:'rgba(255, 0, 0, 0.438)', color:'#000', padding:'5px 10px'} : 
                                      {backgroundColor:'#008cff5a', color:'#000', padding:'5px 10px'}) }> {types.type}</p>
                                    </tr>
                                })}
                        </div>
                        <div className="col-md-2">
                            <select name="starthour22" onChange={e =>  setStart22(e.target.value)} className="form-control">
                                <option value="">Start Time</option>
                                <option value="05.00">05:00 AM</option>
                                <option value="06.00">06:00 AM</option>
                                <option value="06.8">06:05 AM</option>
                                <option value="06.16">06:10 AM</option>
                                <option value="06.25">06:15 AM</option>
                                <option value="06.33">06:20 AM</option>
                                <option value="06.41">06:25 AM</option>
                                <option value="06.50">06:30 AM</option>
                                <option value="06.58">06:35 AM</option>
                                <option value="06.66">06:40 AM</option>
                                <option value="06.75">06:45 AM</option>
                                <option value="06.83">06:50 AM</option>
                                <option value="06.91">06:55 AM</option>
                                <option value="07.00">07:00 AM - Normal</option>
                                <option value="07.8">07:05 AM</option>
                                <option value="07.16">07:10 AM</option>
                                <option value="07.25">07:15 AM</option>
                                <option value="07.33">07:20 AM</option>
                                <option value="07.41">07:25 AM</option>
                                <option value="07.50">07:30 AM</option>
                                <option value="07.58">07:35 AM</option>
                                <option value="07.66">07:40 AM</option>
                                <option value="07.75">07:45 AM</option>
                                <option value="07.83">07:50 AM</option>
                                <option value="07.91">07:55 AM</option>
                                <option value="08.00">08:00 AM - o'clock</option>
                                <option value="08.8">08:05 AM</option>
                                <option value="08.16">08:10 AM</option>
                                <option value="08.25">08:15 AM</option>
                                <option value="08.33">08:20 AM</option>
                                <option value="08.41">08:25 AM</option>
                                <option value="08.50">08:30 AM</option>
                                <option value="08.58">08:35 AM</option>
                                <option value="08.66">08:40 AM</option>
                                <option value="08.75">08:45 AM</option>
                                <option value="08.83">08:50 AM</option>
                                <option value="08.91">08:55 AM</option>
                                <option value="09.00">09:00 AM - o'clock</option>
                                <option value="09.8">09:05 AM</option>
                                <option value="09.16">09:10 AM</option>
                                <option value="09.25">09:15 AM</option>
                                <option value="09.33">09:20 AM</option>
                                <option value="09.41">09:25 AM</option>
                                <option value="09.50">09:30 AM</option>
                                <option value="09.58">09:35 AM</option>
                                <option value="09.66">09:40 AM</option>
                                <option value="09.75">09:45 AM</option>
                                <option value="09.83">09:50 AM</option>
                                <option value="09.91">09:55 AM</option>
                                <option value="10.00">10:00 AM - o'clock</option>
                                <option value="10.8">10:05 AM</option>
                                <option value="10.16">10:10 AM</option>
                                <option value="10.25">10:15 AM</option>
                                <option value="10.33">10:20 AM</option>
                                <option value="10.41">10:25 AM</option>
                                <option value="10.50">10:30 AM</option>
                                <option value="10.58">10:35 AM</option>
                                <option value="10.66">10:40 AM</option>
                                <option value="10.75">10:45 AM</option>
                                <option value="10.83">10:50 AM</option>
                                <option value="10.91">10:55 AM</option>
                                <option value="11.00">11:00 AM - o'clock</option>
                                <option value="11.8">11:05 AM</option>
                                <option value="11.16">11:10 AM</option>
                                <option value="11.25">11:15 AM</option>
                                <option value="11.33">11:20 AM</option>
                                <option value="11.41">11:25 AM</option>
                                <option value="11.50">11:30 AM</option>
                                <option value="11.58">11:35 AM</option>
                                <option value="11.66">11:40 AM</option>
                                <option value="11.75">11:45 AM</option>
                                <option value="11.83">11:50 AM</option>
                                <option value="11.91">11:55 AM</option>
                                <option value="12.00">12:00 PM  - o'clock</option>
                                <option value="12.8">12:05 PM</option>
                                <option value="12.16">12:10 PM</option>
                                <option value="12.25">12:15 PM</option>
                                <option value="12.33">12:20 PM</option>
                                <option value="12.41">12:25 PM</option>
                                <option value="12.50">12:30 PM</option>
                                <option value="12.58">12:35 PM</option>
                                <option value="12.66">12:40 PM</option>
                                <option value="12.75">12:45 PM</option>
                                <option value="12.83">12:50 PM</option>
                                <option value="12.91">12:55 PM</option>
                                <option value="13.00">13:00 PM - o'clock</option>
                                <option value="13.8">13:05 PM</option>
                                <option value="13.16">13:10 PM</option>
                                <option value="13.25">13:15 PM</option>
                                <option value="13.33">13:20 PM</option>
                                <option value="13.41">13:25 PM</option>
                                <option value="13.50">13:30 PM</option>
                                <option value="13.58">13:35 PM</option>
                                <option value="13.66">13:40 PM</option>
                                <option value="13.75">13:45 PM</option>
                                <option value="13.83">13:50 PM</option>
                                <option value="13.91">13:55 PM</option>
                                <option value="14.00">14:00 PM - o'clock</option>
                                <option value="14.8">14:05 PM</option>
                                <option value="14.16">14:10 PM</option>
                                <option value="14.25">14:15 PM</option>
                                <option value="14.33">14:20 PM</option>
                                <option value="14.41">14:25 PM</option>
                                <option value="14.50">14:30 PM</option>
                                <option value="14.58">14:35 PM</option>
                                <option value="14.66">14:40 PM</option>
                                <option value="14.75">14:45 PM</option>
                                <option value="14.83">14:50 PM</option>
                                <option value="14.91">14:55 PM</option>
                                <option value="15.00">15:00 PM - o'clock</option>
                                <option value="15.8">15:05 PM</option>
                                <option value="15.16">15:10 PM</option>
                                <option value="15.25">15:15 PM</option>
                                <option value="15.33">15:20 PM</option>
                                <option value="15.41">15:25 PM</option>
                                <option value="15.50">15:30 PM</option>
                                <option value="15.58">15:35 PM</option>
                                <option value="15.66">15:40 PM</option>
                                <option value="15.75">15:45 PM</option>
                                <option value="15.83">15:50 PM</option>
                                <option value="15.91">15:55 PM</option>
                                <option value="16.00">16:00 PM - o'clock</option>
                                <option value="16.8">16:05 PM</option>
                                <option value="16.16">16:10 PM</option>
                                <option value="16.25">16:15 PM</option>
                                <option value="16.33">16:20 PM</option>
                                <option value="16.41">16:25 PM</option>
                                <option value="16.50">16:30 PM</option>
                                <option value="16.58">16:35 PM</option>
                                <option value="16.66">16:40 PM</option>
                                <option value="16.75">16:45 PM</option>
                                <option value="16.83">16:50 PM</option>
                                <option value="16.91">16:55 PM</option>
                                <option value="17.00">17:00 PM - o'clock</option>
                                <option value="17.8">17:05 PM</option>
                                <option value="17.16">17:10 PM</option>
                                <option value="17.25">17:15 PM</option>
                                <option value="17.33">17:20 PM</option>
                                <option value="17.41">17:25 PM</option>
                                <option value="17.50">17:30 PM</option>
                                <option value="17.58">17:35 PM</option>
                                <option value="17.66">17:40 PM</option>
                                <option value="17.75">17:45 PM</option>
                                <option value="17.83">17:50 PM</option>
                                <option value="17.91">17:55 PM</option>
                                <option value="18.00">18:00 PM - Normal</option>
                                <option value="18.8">18:05 PM</option>
                                <option value="18.16">18:10 PM</option>
                                <option value="18.25">18:15 PM</option>
                                <option value="18.33">18:20 PM</option>
                                <option value="18.41">18:25 PM</option>
                                <option value="18.50">18:30 PM</option>
                                <option value="18.58">18:35 PM</option>
                                <option value="18.66">18:40 PM</option>
                                <option value="18.75">18:45 PM</option>
                                <option value="18.83">18:50 PM</option>
                                <option value="18.91">18:55 PM</option>
                                <option value="19.00">19:00 PM - o'clock</option>
                                <option value="19.8">19:05 PM</option>
                                <option value="19.16">19:10 PM</option>
                                <option value="19.25">19:15 PM</option>
                                <option value="19.33">19:20 PM</option>
                                <option value="19.41">19:25 PM</option>
                                <option value="19.50">19:30 PM</option>
                                <option value="19.58">19:35 PM</option>
                                <option value="19.66">19:40 PM</option>
                                <option value="19.75">19:45 PM</option>
                                <option value="19.83">19:50 PM</option>
                                <option value="19.91">19:55 PM</option>
                                <option value="20.00">20:00 PM - o'clock</option>
                                <option value="20.8">20:05 PM</option>
                                <option value="20.16">20:10 PM</option>
                                <option value="20.25">20:15 PM</option>
                                <option value="20.33">20:20 PM</option>
                                <option value="20.41">20:25 PM</option>
                                <option value="20.50">20:30 PM</option>
                                <option value="20.58">20:35 PM</option>
                                <option value="20.66">20:40 PM</option>
                                <option value="20.75">20:45 PM</option>
                                <option value="20.83">20:50 PM</option>
                                <option value="20.91">20:55 PM</option>
                                <option value="21.00">21:00 PM - o'clock</option>
                                <option value="21.8">21:05 PM</option>
                                <option value="21.16">21:10 PM</option>
                                <option value="21.25">21:15 PM</option>
                                <option value="21.33">21:20 PM</option>
                                <option value="21.41">21:25 PM</option>
                                <option value="21.50">21:30 PM</option>
                                <option value="21.58">21:35 PM</option>
                                <option value="21.66">21:40 PM</option>
                                <option value="21.75">21:45 PM</option>
                                <option value="21.83">21:50 PM</option>
                                <option value="21.91">21:55 PM</option>
                                <option value="22.00">22:00 PM - o'clock</option>
                                <option value="22.8">22:05 PM</option>
                                <option value="22.16">22:10 PM</option>
                                <option value="22.25">22:15 PM</option>
                                <option value="22.33">22:20 PM</option>
                                <option value="22.41">22:25 PM</option>
                                <option value="22.50">22:30 PM</option>
                                <option value="22.58">22:35 PM</option>
                                <option value="22.66">22:40 PM</option>
                                <option value="22.75">22:45 PM</option>
                                <option value="22.83">22:50 PM</option>
                                <option value="22.91">22:55 PM</option>
                                <option value="23.00">23:00 PM - o'clock</option>
                                <option value="23.8">23:05 PM</option>
                                <option value="23.16">23:10 PM</option>
                                <option value="23.25">23:15 PM</option>
                                <option value="23.33">23:20 PM</option>
                                <option value="23.41">23:25 PM</option>
                                <option value="23.50">23:30 PM</option>
                                <option value="23.58">23:35 PM</option>
                                <option value="23.66">23:40 PM</option>
                                <option value="23.75">23:45 PM</option>
                                <option value="23.83">23:50 PM</option>
                                <option value="23.91">23:55 PM</option>
                                <option value="24.00">24:00 PM - o'clock</option>
                                <option value="24.8">15:05 PM</option>
                                <option value="24.16">24:10 PM</option>
                                <option value="24.25">24:15 PM</option>
                                <option value="24.33">24:20 PM</option>
                                <option value="24.41">24:25 PM</option>
                                <option value="24.50">24:30 PM</option>
                                <option value="24.58">24:35 PM</option>
                                <option value="24.66">24:40 PM</option>
                                <option value="24.75">24:45 PM</option>
                                <option value="24.83">24:50 PM</option>
                                <option value="24.91">24:55 PM</option>
                            </select>
                            {data22.map((datas, index) =>{
                              return<div key={index}>
                                        <p>{datas.starthour}</p>
                                    </div>
                                })}
                        </div>
                        <div className="col-md-2">
                            <select name="endhour22" onChange={e =>  setEnd22(e.target.value)} className="form-control">
                                <option value="">Finish Time</option>
                                <option value="05.00">05:00 AM</option>
                                <option value="06.00">06:00 AM</option>
                                <option value="06.8">06:05 AM</option>
                                <option value="06.16">06:10 AM</option>
                                <option value="06.25">06:15 AM</option>
                                <option value="06.33">06:20 AM</option>
                                <option value="06.41">06:25 AM</option>
                                <option value="06.50">06:30 AM</option>
                                <option value="06.58">06:35 AM</option>
                                <option value="06.66">06:40 AM</option>
                                <option value="06.75">06:45 AM</option>
                                <option value="06.83">06:50 AM</option>
                                <option value="06.91">06:55 AM</option>
                                <option value="07.00">07:00 AM - Normal</option>
                                <option value="07.8">07:05 AM</option>
                                <option value="07.16">07:10 AM</option>
                                <option value="07.25">07:15 AM</option>
                                <option value="07.33">07:20 AM</option>
                                <option value="07.41">07:25 AM</option>
                                <option value="07.50">07:30 AM</option>
                                <option value="07.58">07:35 AM</option>
                                <option value="07.66">07:40 AM</option>
                                <option value="07.75">07:45 AM</option>
                                <option value="07.83">07:50 AM</option>
                                <option value="07.91">07:55 AM</option>
                                <option value="08.00">08:00 AM - o'clock</option>
                                <option value="08.8">08:05 AM</option>
                                <option value="08.16">08:10 AM</option>
                                <option value="08.25">08:15 AM</option>
                                <option value="08.33">08:20 AM</option>
                                <option value="08.41">08:25 AM</option>
                                <option value="08.50">08:30 AM</option>
                                <option value="08.58">08:35 AM</option>
                                <option value="08.66">08:40 AM</option>
                                <option value="08.75">08:45 AM</option>
                                <option value="08.83">08:50 AM</option>
                                <option value="08.91">08:55 AM</option>
                                <option value="09.00">09:00 AM - o'clock</option>
                                <option value="09.8">09:05 AM</option>
                                <option value="09.16">09:10 AM</option>
                                <option value="09.25">09:15 AM</option>
                                <option value="09.33">09:20 AM</option>
                                <option value="09.41">09:25 AM</option>
                                <option value="09.50">09:30 AM</option>
                                <option value="09.58">09:35 AM</option>
                                <option value="09.66">09:40 AM</option>
                                <option value="09.75">09:45 AM</option>
                                <option value="09.83">09:50 AM</option>
                                <option value="09.91">09:55 AM</option>
                                <option value="10.00">10:00 AM - o'clock</option>
                                <option value="10.8">10:05 AM</option>
                                <option value="10.16">10:10 AM</option>
                                <option value="10.25">10:15 AM</option>
                                <option value="10.33">10:20 AM</option>
                                <option value="10.41">10:25 AM</option>
                                <option value="10.50">10:30 AM</option>
                                <option value="10.58">10:35 AM</option>
                                <option value="10.66">10:40 AM</option>
                                <option value="10.75">10:45 AM</option>
                                <option value="10.83">10:50 AM</option>
                                <option value="10.91">10:55 AM</option>
                                <option value="11.00">11:00 AM - o'clock</option>
                                <option value="11.8">11:05 AM</option>
                                <option value="11.16">11:10 AM</option>
                                <option value="11.25">11:15 AM</option>
                                <option value="11.33">11:20 AM</option>
                                <option value="11.41">11:25 AM</option>
                                <option value="11.50">11:30 AM</option>
                                <option value="11.58">11:35 AM</option>
                                <option value="11.66">11:40 AM</option>
                                <option value="11.75">11:45 AM</option>
                                <option value="11.83">11:50 AM</option>
                                <option value="11.91">11:55 AM</option>
                                <option value="12.00">12:00 PM  - o'clock</option>
                                <option value="12.8">12:05 PM</option>
                                <option value="12.16">12:10 PM</option>
                                <option value="12.25">12:15 PM</option>
                                <option value="12.33">12:20 PM</option>
                                <option value="12.41">12:25 PM</option>
                                <option value="12.50">12:30 PM</option>
                                <option value="12.58">12:35 PM</option>
                                <option value="12.66">12:40 PM</option>
                                <option value="12.75">12:45 PM</option>
                                <option value="12.83">12:50 PM</option>
                                <option value="12.91">12:55 PM</option>
                                <option value="13.00">13:00 PM - o'clock</option>
                                <option value="13.8">13:05 PM</option>
                                <option value="13.16">13:10 PM</option>
                                <option value="13.25">13:15 PM</option>
                                <option value="13.33">13:20 PM</option>
                                <option value="13.41">13:25 PM</option>
                                <option value="13.50">13:30 PM</option>
                                <option value="13.58">13:35 PM</option>
                                <option value="13.66">13:40 PM</option>
                                <option value="13.75">13:45 PM</option>
                                <option value="13.83">13:50 PM</option>
                                <option value="13.91">13:55 PM</option>
                                <option value="14.00">14:00 PM - o'clock</option>
                                <option value="14.8">14:05 PM</option>
                                <option value="14.16">14:10 PM</option>
                                <option value="14.25">14:15 PM</option>
                                <option value="14.33">14:20 PM</option>
                                <option value="14.41">14:25 PM</option>
                                <option value="14.50">14:30 PM</option>
                                <option value="14.58">14:35 PM</option>
                                <option value="14.66">14:40 PM</option>
                                <option value="14.75">14:45 PM</option>
                                <option value="14.83">14:50 PM</option>
                                <option value="14.91">14:55 PM</option>
                                <option value="15.00">15:00 PM - o'clock</option>
                                <option value="15.8">15:05 PM</option>
                                <option value="15.16">15:10 PM</option>
                                <option value="15.25">15:15 PM</option>
                                <option value="15.33">15:20 PM</option>
                                <option value="15.41">15:25 PM</option>
                                <option value="15.50">15:30 PM</option>
                                <option value="15.58">15:35 PM</option>
                                <option value="15.66">15:40 PM</option>
                                <option value="15.75">15:45 PM</option>
                                <option value="15.83">15:50 PM</option>
                                <option value="15.91">15:55 PM</option>
                                <option value="16.00">16:00 PM - o'clock</option>
                                <option value="16.8">16:05 PM</option>
                                <option value="16.16">16:10 PM</option>
                                <option value="16.25">16:15 PM</option>
                                <option value="16.33">16:20 PM</option>
                                <option value="16.41">16:25 PM</option>
                                <option value="16.50">16:30 PM</option>
                                <option value="16.58">16:35 PM</option>
                                <option value="16.66">16:40 PM</option>
                                <option value="16.75">16:45 PM</option>
                                <option value="16.83">16:50 PM</option>
                                <option value="16.91">16:55 PM</option>
                                <option value="17.00">17:00 PM - o'clock</option>
                                <option value="17.8">17:05 PM</option>
                                <option value="17.16">17:10 PM</option>
                                <option value="17.25">17:15 PM</option>
                                <option value="17.33">17:20 PM</option>
                                <option value="17.41">17:25 PM</option>
                                <option value="17.50">17:30 PM</option>
                                <option value="17.58">17:35 PM</option>
                                <option value="17.66">17:40 PM</option>
                                <option value="17.75">17:45 PM</option>
                                <option value="17.83">17:50 PM</option>
                                <option value="17.91">17:55 PM</option>
                                <option value="18.00">18:00 PM - Normal</option>
                                <option value="18.8">18:05 PM</option>
                                <option value="18.16">18:10 PM</option>
                                <option value="18.25">18:15 PM</option>
                                <option value="18.33">18:20 PM</option>
                                <option value="18.41">18:25 PM</option>
                                <option value="18.50">18:30 PM</option>
                                <option value="18.58">18:35 PM</option>
                                <option value="18.66">18:40 PM</option>
                                <option value="18.75">18:45 PM</option>
                                <option value="18.83">18:50 PM</option>
                                <option value="18.91">18:55 PM</option>
                                <option value="19.00">19:00 PM - o'clock</option>
                                <option value="19.8">19:05 PM</option>
                                <option value="19.16">19:10 PM</option>
                                <option value="19.25">19:15 PM</option>
                                <option value="19.33">19:20 PM</option>
                                <option value="19.41">19:25 PM</option>
                                <option value="19.50">19:30 PM</option>
                                <option value="19.58">19:35 PM</option>
                                <option value="19.66">19:40 PM</option>
                                <option value="19.75">19:45 PM</option>
                                <option value="19.83">19:50 PM</option>
                                <option value="19.91">19:55 PM</option>
                                <option value="20.00">20:00 PM - o'clock</option>
                                <option value="20.8">20:05 PM</option>
                                <option value="20.16">20:10 PM</option>
                                <option value="20.25">20:15 PM</option>
                                <option value="20.33">20:20 PM</option>
                                <option value="20.41">20:25 PM</option>
                                <option value="20.50">20:30 PM</option>
                                <option value="20.58">20:35 PM</option>
                                <option value="20.66">20:40 PM</option>
                                <option value="20.75">20:45 PM</option>
                                <option value="20.83">20:50 PM</option>
                                <option value="20.91">20:55 PM</option>
                                <option value="21.00">21:00 PM - o'clock</option>
                                <option value="21.8">21:05 PM</option>
                                <option value="21.16">21:10 PM</option>
                                <option value="21.25">21:15 PM</option>
                                <option value="21.33">21:20 PM</option>
                                <option value="21.41">21:25 PM</option>
                                <option value="21.50">21:30 PM</option>
                                <option value="21.58">21:35 PM</option>
                                <option value="21.66">21:40 PM</option>
                                <option value="21.75">21:45 PM</option>
                                <option value="21.83">21:50 PM</option>
                                <option value="21.91">21:55 PM</option>
                                <option value="22.00">22:00 PM - o'clock</option>
                                <option value="22.8">22:05 PM</option>
                                <option value="22.16">22:10 PM</option>
                                <option value="22.25">22:15 PM</option>
                                <option value="22.33">22:20 PM</option>
                                <option value="22.41">22:25 PM</option>
                                <option value="22.50">22:30 PM</option>
                                <option value="22.58">22:35 PM</option>
                                <option value="22.66">22:40 PM</option>
                                <option value="22.75">22:45 PM</option>
                                <option value="22.83">22:50 PM</option>
                                <option value="22.91">22:55 PM</option>
                                <option value="23.00">23:00 PM - o'clock</option>
                                <option value="23.8">23:05 PM</option>
                                <option value="23.16">23:10 PM</option>
                                <option value="23.25">23:15 PM</option>
                                <option value="23.33">23:20 PM</option>
                                <option value="23.41">23:25 PM</option>
                                <option value="23.50">23:30 PM</option>
                                <option value="23.58">23:35 PM</option>
                                <option value="23.66">23:40 PM</option>
                                <option value="23.75">23:45 PM</option>
                                <option value="23.83">23:50 PM</option>
                                <option value="23.91">23:55 PM</option>
                                <option value="24.00">24:00 PM - o'clock</option>
                                <option value="24.8">15:05 PM</option>
                                <option value="24.16">24:10 PM</option>
                                <option value="24.25">24:15 PM</option>
                                <option value="24.33">24:20 PM</option>
                                <option value="24.41">24:25 PM</option>
                                <option value="24.50">24:30 PM</option>
                                <option value="24.58">24:35 PM</option>
                                <option value="24.66">24:40 PM</option>
                                <option value="24.75">24:45 PM</option>
                                <option value="24.83">24:50 PM</option>
                                <option value="24.91">24:55 PM</option>
                            </select>
                            {data22.map((datas, index) =>{
                              return<div key={index}>
                                        <p>{datas.endhour}</p>
                                    </div>
                                })}
                        </div>
                        <div className="col-md-2">
                            <select name="type22" onChange={e =>  settype22(e.target.value)} className="form-control" required>
                                <option value="">Select Type</option>
                                <option value="Normal">Normal</option>
                                <option value="SJUK">SJUK</option>
                                <option value="Holiday">Holiday</option>
                            </select>
                            {data22.map((datas, index) =>{
                              return<div key={index}>
                                        <p>{datas.type}</p>
                                    </div>
                                })}
                          </div>
                          <div className="col-md-2"> 
                              <button type='submit' className="btn btn-primary">Submit</button>
                        </div>
                      </form>
                  </div>
                  <div className="d-flex flex-column p-2">
                     <form action="" className="row"  onSubmit={handleSubmit23}>
                        <div className="col-md-1"> 23 </div>
                        <div className="col-md-1"> Wednesday </div>
                        <div className="col-md-1" > 
                            {data23.map((types, index) =>{
                              return<tr key={index}>
                                   <p style={types.type === "Normal" ? {backgroundColor:'rgba(7, 190, 7, 0.648)', color:'#000', padding:'5px 10px'} : 
                                     (types.type === "Holiday" ? {backgroundColor:'rgba(255, 0, 0, 0.438)', color:'#000', padding:'5px 10px'} : 
                                      {backgroundColor:'#008cff5a', color:'#000', padding:'5px 10px'}) }> {types.type}</p>
                                    </tr>
                                })}
                        </div>
                        <div className="col-md-2">
                            <select name="starthour23" onChange={e =>  setStart23(e.target.value)} className="form-control">
                                <option value="">Start Time</option>
                                <option value="05.00">05:00 AM</option>
                                <option value="06.00">06:00 AM</option>
                                <option value="06.8">06:05 AM</option>
                                <option value="06.16">06:10 AM</option>
                                <option value="06.25">06:15 AM</option>
                                <option value="06.33">06:20 AM</option>
                                <option value="06.41">06:25 AM</option>
                                <option value="06.50">06:30 AM</option>
                                <option value="06.58">06:35 AM</option>
                                <option value="06.66">06:40 AM</option>
                                <option value="06.75">06:45 AM</option>
                                <option value="06.83">06:50 AM</option>
                                <option value="06.91">06:55 AM</option>
                                <option value="07.00">07:00 AM - Normal</option>
                                <option value="07.8">07:05 AM</option>
                                <option value="07.16">07:10 AM</option>
                                <option value="07.25">07:15 AM</option>
                                <option value="07.33">07:20 AM</option>
                                <option value="07.41">07:25 AM</option>
                                <option value="07.50">07:30 AM</option>
                                <option value="07.58">07:35 AM</option>
                                <option value="07.66">07:40 AM</option>
                                <option value="07.75">07:45 AM</option>
                                <option value="07.83">07:50 AM</option>
                                <option value="07.91">07:55 AM</option>
                                <option value="08.00">08:00 AM - o'clock</option>
                                <option value="08.8">08:05 AM</option>
                                <option value="08.16">08:10 AM</option>
                                <option value="08.25">08:15 AM</option>
                                <option value="08.33">08:20 AM</option>
                                <option value="08.41">08:25 AM</option>
                                <option value="08.50">08:30 AM</option>
                                <option value="08.58">08:35 AM</option>
                                <option value="08.66">08:40 AM</option>
                                <option value="08.75">08:45 AM</option>
                                <option value="08.83">08:50 AM</option>
                                <option value="08.91">08:55 AM</option>
                                <option value="09.00">09:00 AM - o'clock</option>
                                <option value="09.8">09:05 AM</option>
                                <option value="09.16">09:10 AM</option>
                                <option value="09.25">09:15 AM</option>
                                <option value="09.33">09:20 AM</option>
                                <option value="09.41">09:25 AM</option>
                                <option value="09.50">09:30 AM</option>
                                <option value="09.58">09:35 AM</option>
                                <option value="09.66">09:40 AM</option>
                                <option value="09.75">09:45 AM</option>
                                <option value="09.83">09:50 AM</option>
                                <option value="09.91">09:55 AM</option>
                                <option value="10.00">10:00 AM - o'clock</option>
                                <option value="10.8">10:05 AM</option>
                                <option value="10.16">10:10 AM</option>
                                <option value="10.25">10:15 AM</option>
                                <option value="10.33">10:20 AM</option>
                                <option value="10.41">10:25 AM</option>
                                <option value="10.50">10:30 AM</option>
                                <option value="10.58">10:35 AM</option>
                                <option value="10.66">10:40 AM</option>
                                <option value="10.75">10:45 AM</option>
                                <option value="10.83">10:50 AM</option>
                                <option value="10.91">10:55 AM</option>
                                <option value="11.00">11:00 AM - o'clock</option>
                                <option value="11.8">11:05 AM</option>
                                <option value="11.16">11:10 AM</option>
                                <option value="11.25">11:15 AM</option>
                                <option value="11.33">11:20 AM</option>
                                <option value="11.41">11:25 AM</option>
                                <option value="11.50">11:30 AM</option>
                                <option value="11.58">11:35 AM</option>
                                <option value="11.66">11:40 AM</option>
                                <option value="11.75">11:45 AM</option>
                                <option value="11.83">11:50 AM</option>
                                <option value="11.91">11:55 AM</option>
                                <option value="12.00">12:00 PM  - o'clock</option>
                                <option value="12.8">12:05 PM</option>
                                <option value="12.16">12:10 PM</option>
                                <option value="12.25">12:15 PM</option>
                                <option value="12.33">12:20 PM</option>
                                <option value="12.41">12:25 PM</option>
                                <option value="12.50">12:30 PM</option>
                                <option value="12.58">12:35 PM</option>
                                <option value="12.66">12:40 PM</option>
                                <option value="12.75">12:45 PM</option>
                                <option value="12.83">12:50 PM</option>
                                <option value="12.91">12:55 PM</option>
                                <option value="13.00">13:00 PM - o'clock</option>
                                <option value="13.8">13:05 PM</option>
                                <option value="13.16">13:10 PM</option>
                                <option value="13.25">13:15 PM</option>
                                <option value="13.33">13:20 PM</option>
                                <option value="13.41">13:25 PM</option>
                                <option value="13.50">13:30 PM</option>
                                <option value="13.58">13:35 PM</option>
                                <option value="13.66">13:40 PM</option>
                                <option value="13.75">13:45 PM</option>
                                <option value="13.83">13:50 PM</option>
                                <option value="13.91">13:55 PM</option>
                                <option value="14.00">14:00 PM - o'clock</option>
                                <option value="14.8">14:05 PM</option>
                                <option value="14.16">14:10 PM</option>
                                <option value="14.25">14:15 PM</option>
                                <option value="14.33">14:20 PM</option>
                                <option value="14.41">14:25 PM</option>
                                <option value="14.50">14:30 PM</option>
                                <option value="14.58">14:35 PM</option>
                                <option value="14.66">14:40 PM</option>
                                <option value="14.75">14:45 PM</option>
                                <option value="14.83">14:50 PM</option>
                                <option value="14.91">14:55 PM</option>
                                <option value="15.00">15:00 PM - o'clock</option>
                                <option value="15.8">15:05 PM</option>
                                <option value="15.16">15:10 PM</option>
                                <option value="15.25">15:15 PM</option>
                                <option value="15.33">15:20 PM</option>
                                <option value="15.41">15:25 PM</option>
                                <option value="15.50">15:30 PM</option>
                                <option value="15.58">15:35 PM</option>
                                <option value="15.66">15:40 PM</option>
                                <option value="15.75">15:45 PM</option>
                                <option value="15.83">15:50 PM</option>
                                <option value="15.91">15:55 PM</option>
                                <option value="16.00">16:00 PM - o'clock</option>
                                <option value="16.8">16:05 PM</option>
                                <option value="16.16">16:10 PM</option>
                                <option value="16.25">16:15 PM</option>
                                <option value="16.33">16:20 PM</option>
                                <option value="16.41">16:25 PM</option>
                                <option value="16.50">16:30 PM</option>
                                <option value="16.58">16:35 PM</option>
                                <option value="16.66">16:40 PM</option>
                                <option value="16.75">16:45 PM</option>
                                <option value="16.83">16:50 PM</option>
                                <option value="16.91">16:55 PM</option>
                                <option value="17.00">17:00 PM - o'clock</option>
                                <option value="17.8">17:05 PM</option>
                                <option value="17.16">17:10 PM</option>
                                <option value="17.25">17:15 PM</option>
                                <option value="17.33">17:20 PM</option>
                                <option value="17.41">17:25 PM</option>
                                <option value="17.50">17:30 PM</option>
                                <option value="17.58">17:35 PM</option>
                                <option value="17.66">17:40 PM</option>
                                <option value="17.75">17:45 PM</option>
                                <option value="17.83">17:50 PM</option>
                                <option value="17.91">17:55 PM</option>
                                <option value="18.00">18:00 PM - Normal</option>
                                <option value="18.8">18:05 PM</option>
                                <option value="18.16">18:10 PM</option>
                                <option value="18.25">18:15 PM</option>
                                <option value="18.33">18:20 PM</option>
                                <option value="18.41">18:25 PM</option>
                                <option value="18.50">18:30 PM</option>
                                <option value="18.58">18:35 PM</option>
                                <option value="18.66">18:40 PM</option>
                                <option value="18.75">18:45 PM</option>
                                <option value="18.83">18:50 PM</option>
                                <option value="18.91">18:55 PM</option>
                                <option value="19.00">19:00 PM - o'clock</option>
                                <option value="19.8">19:05 PM</option>
                                <option value="19.16">19:10 PM</option>
                                <option value="19.25">19:15 PM</option>
                                <option value="19.33">19:20 PM</option>
                                <option value="19.41">19:25 PM</option>
                                <option value="19.50">19:30 PM</option>
                                <option value="19.58">19:35 PM</option>
                                <option value="19.66">19:40 PM</option>
                                <option value="19.75">19:45 PM</option>
                                <option value="19.83">19:50 PM</option>
                                <option value="19.91">19:55 PM</option>
                                <option value="20.00">20:00 PM - o'clock</option>
                                <option value="20.8">20:05 PM</option>
                                <option value="20.16">20:10 PM</option>
                                <option value="20.25">20:15 PM</option>
                                <option value="20.33">20:20 PM</option>
                                <option value="20.41">20:25 PM</option>
                                <option value="20.50">20:30 PM</option>
                                <option value="20.58">20:35 PM</option>
                                <option value="20.66">20:40 PM</option>
                                <option value="20.75">20:45 PM</option>
                                <option value="20.83">20:50 PM</option>
                                <option value="20.91">20:55 PM</option>
                                <option value="21.00">21:00 PM - o'clock</option>
                                <option value="21.8">21:05 PM</option>
                                <option value="21.16">21:10 PM</option>
                                <option value="21.25">21:15 PM</option>
                                <option value="21.33">21:20 PM</option>
                                <option value="21.41">21:25 PM</option>
                                <option value="21.50">21:30 PM</option>
                                <option value="21.58">21:35 PM</option>
                                <option value="21.66">21:40 PM</option>
                                <option value="21.75">21:45 PM</option>
                                <option value="21.83">21:50 PM</option>
                                <option value="21.91">21:55 PM</option>
                                <option value="22.00">22:00 PM - o'clock</option>
                                <option value="22.8">22:05 PM</option>
                                <option value="22.16">22:10 PM</option>
                                <option value="22.25">22:15 PM</option>
                                <option value="22.33">22:20 PM</option>
                                <option value="22.41">22:25 PM</option>
                                <option value="22.50">22:30 PM</option>
                                <option value="22.58">22:35 PM</option>
                                <option value="22.66">22:40 PM</option>
                                <option value="22.75">22:45 PM</option>
                                <option value="22.83">22:50 PM</option>
                                <option value="22.91">22:55 PM</option>
                                <option value="23.00">23:00 PM - o'clock</option>
                                <option value="23.8">23:05 PM</option>
                                <option value="23.16">23:10 PM</option>
                                <option value="23.25">23:15 PM</option>
                                <option value="23.33">23:20 PM</option>
                                <option value="23.41">23:25 PM</option>
                                <option value="23.50">23:30 PM</option>
                                <option value="23.58">23:35 PM</option>
                                <option value="23.66">23:40 PM</option>
                                <option value="23.75">23:45 PM</option>
                                <option value="23.83">23:50 PM</option>
                                <option value="23.91">23:55 PM</option>
                                <option value="24.00">24:00 PM - o'clock</option>
                                <option value="24.8">15:05 PM</option>
                                <option value="24.16">24:10 PM</option>
                                <option value="24.25">24:15 PM</option>
                                <option value="24.33">24:20 PM</option>
                                <option value="24.41">24:25 PM</option>
                                <option value="24.50">24:30 PM</option>
                                <option value="24.58">24:35 PM</option>
                                <option value="24.66">24:40 PM</option>
                                <option value="24.75">24:45 PM</option>
                                <option value="24.83">24:50 PM</option>
                                <option value="24.91">24:55 PM</option>
                            </select>
                            {data23.map((datas, index) =>{
                              return<div key={index}>
                                        <p>{datas.starthour}</p>
                                    </div>
                                })}
                        </div>
                        <div className="col-md-2">
                            <select name="endhour23" onChange={e =>  setEnd23(e.target.value)} className="form-control">
                                <option value="">Finish Time</option>
                                <option value="05.00">05:00 AM</option>
                                <option value="06.00">06:00 AM</option>
                                <option value="06.8">06:05 AM</option>
                                <option value="06.16">06:10 AM</option>
                                <option value="06.25">06:15 AM</option>
                                <option value="06.33">06:20 AM</option>
                                <option value="06.41">06:25 AM</option>
                                <option value="06.50">06:30 AM</option>
                                <option value="06.58">06:35 AM</option>
                                <option value="06.66">06:40 AM</option>
                                <option value="06.75">06:45 AM</option>
                                <option value="06.83">06:50 AM</option>
                                <option value="06.91">06:55 AM</option>
                                <option value="07.00">07:00 AM - Normal</option>
                                <option value="07.8">07:05 AM</option>
                                <option value="07.16">07:10 AM</option>
                                <option value="07.25">07:15 AM</option>
                                <option value="07.33">07:20 AM</option>
                                <option value="07.41">07:25 AM</option>
                                <option value="07.50">07:30 AM</option>
                                <option value="07.58">07:35 AM</option>
                                <option value="07.66">07:40 AM</option>
                                <option value="07.75">07:45 AM</option>
                                <option value="07.83">07:50 AM</option>
                                <option value="07.91">07:55 AM</option>
                                <option value="08.00">08:00 AM - o'clock</option>
                                <option value="08.8">08:05 AM</option>
                                <option value="08.16">08:10 AM</option>
                                <option value="08.25">08:15 AM</option>
                                <option value="08.33">08:20 AM</option>
                                <option value="08.41">08:25 AM</option>
                                <option value="08.50">08:30 AM</option>
                                <option value="08.58">08:35 AM</option>
                                <option value="08.66">08:40 AM</option>
                                <option value="08.75">08:45 AM</option>
                                <option value="08.83">08:50 AM</option>
                                <option value="08.91">08:55 AM</option>
                                <option value="09.00">09:00 AM - o'clock</option>
                                <option value="09.8">09:05 AM</option>
                                <option value="09.16">09:10 AM</option>
                                <option value="09.25">09:15 AM</option>
                                <option value="09.33">09:20 AM</option>
                                <option value="09.41">09:25 AM</option>
                                <option value="09.50">09:30 AM</option>
                                <option value="09.58">09:35 AM</option>
                                <option value="09.66">09:40 AM</option>
                                <option value="09.75">09:45 AM</option>
                                <option value="09.83">09:50 AM</option>
                                <option value="09.91">09:55 AM</option>
                                <option value="10.00">10:00 AM - o'clock</option>
                                <option value="10.8">10:05 AM</option>
                                <option value="10.16">10:10 AM</option>
                                <option value="10.25">10:15 AM</option>
                                <option value="10.33">10:20 AM</option>
                                <option value="10.41">10:25 AM</option>
                                <option value="10.50">10:30 AM</option>
                                <option value="10.58">10:35 AM</option>
                                <option value="10.66">10:40 AM</option>
                                <option value="10.75">10:45 AM</option>
                                <option value="10.83">10:50 AM</option>
                                <option value="10.91">10:55 AM</option>
                                <option value="11.00">11:00 AM - o'clock</option>
                                <option value="11.8">11:05 AM</option>
                                <option value="11.16">11:10 AM</option>
                                <option value="11.25">11:15 AM</option>
                                <option value="11.33">11:20 AM</option>
                                <option value="11.41">11:25 AM</option>
                                <option value="11.50">11:30 AM</option>
                                <option value="11.58">11:35 AM</option>
                                <option value="11.66">11:40 AM</option>
                                <option value="11.75">11:45 AM</option>
                                <option value="11.83">11:50 AM</option>
                                <option value="11.91">11:55 AM</option>
                                <option value="12.00">12:00 PM  - o'clock</option>
                                <option value="12.8">12:05 PM</option>
                                <option value="12.16">12:10 PM</option>
                                <option value="12.25">12:15 PM</option>
                                <option value="12.33">12:20 PM</option>
                                <option value="12.41">12:25 PM</option>
                                <option value="12.50">12:30 PM</option>
                                <option value="12.58">12:35 PM</option>
                                <option value="12.66">12:40 PM</option>
                                <option value="12.75">12:45 PM</option>
                                <option value="12.83">12:50 PM</option>
                                <option value="12.91">12:55 PM</option>
                                <option value="13.00">13:00 PM - o'clock</option>
                                <option value="13.8">13:05 PM</option>
                                <option value="13.16">13:10 PM</option>
                                <option value="13.25">13:15 PM</option>
                                <option value="13.33">13:20 PM</option>
                                <option value="13.41">13:25 PM</option>
                                <option value="13.50">13:30 PM</option>
                                <option value="13.58">13:35 PM</option>
                                <option value="13.66">13:40 PM</option>
                                <option value="13.75">13:45 PM</option>
                                <option value="13.83">13:50 PM</option>
                                <option value="13.91">13:55 PM</option>
                                <option value="14.00">14:00 PM - o'clock</option>
                                <option value="14.8">14:05 PM</option>
                                <option value="14.16">14:10 PM</option>
                                <option value="14.25">14:15 PM</option>
                                <option value="14.33">14:20 PM</option>
                                <option value="14.41">14:25 PM</option>
                                <option value="14.50">14:30 PM</option>
                                <option value="14.58">14:35 PM</option>
                                <option value="14.66">14:40 PM</option>
                                <option value="14.75">14:45 PM</option>
                                <option value="14.83">14:50 PM</option>
                                <option value="14.91">14:55 PM</option>
                                <option value="15.00">15:00 PM - o'clock</option>
                                <option value="15.8">15:05 PM</option>
                                <option value="15.16">15:10 PM</option>
                                <option value="15.25">15:15 PM</option>
                                <option value="15.33">15:20 PM</option>
                                <option value="15.41">15:25 PM</option>
                                <option value="15.50">15:30 PM</option>
                                <option value="15.58">15:35 PM</option>
                                <option value="15.66">15:40 PM</option>
                                <option value="15.75">15:45 PM</option>
                                <option value="15.83">15:50 PM</option>
                                <option value="15.91">15:55 PM</option>
                                <option value="16.00">16:00 PM - o'clock</option>
                                <option value="16.8">16:05 PM</option>
                                <option value="16.16">16:10 PM</option>
                                <option value="16.25">16:15 PM</option>
                                <option value="16.33">16:20 PM</option>
                                <option value="16.41">16:25 PM</option>
                                <option value="16.50">16:30 PM</option>
                                <option value="16.58">16:35 PM</option>
                                <option value="16.66">16:40 PM</option>
                                <option value="16.75">16:45 PM</option>
                                <option value="16.83">16:50 PM</option>
                                <option value="16.91">16:55 PM</option>
                                <option value="17.00">17:00 PM - o'clock</option>
                                <option value="17.8">17:05 PM</option>
                                <option value="17.16">17:10 PM</option>
                                <option value="17.25">17:15 PM</option>
                                <option value="17.33">17:20 PM</option>
                                <option value="17.41">17:25 PM</option>
                                <option value="17.50">17:30 PM</option>
                                <option value="17.58">17:35 PM</option>
                                <option value="17.66">17:40 PM</option>
                                <option value="17.75">17:45 PM</option>
                                <option value="17.83">17:50 PM</option>
                                <option value="17.91">17:55 PM</option>
                                <option value="18.00">18:00 PM - Normal</option>
                                <option value="18.8">18:05 PM</option>
                                <option value="18.16">18:10 PM</option>
                                <option value="18.25">18:15 PM</option>
                                <option value="18.33">18:20 PM</option>
                                <option value="18.41">18:25 PM</option>
                                <option value="18.50">18:30 PM</option>
                                <option value="18.58">18:35 PM</option>
                                <option value="18.66">18:40 PM</option>
                                <option value="18.75">18:45 PM</option>
                                <option value="18.83">18:50 PM</option>
                                <option value="18.91">18:55 PM</option>
                                <option value="19.00">19:00 PM - o'clock</option>
                                <option value="19.8">19:05 PM</option>
                                <option value="19.16">19:10 PM</option>
                                <option value="19.25">19:15 PM</option>
                                <option value="19.33">19:20 PM</option>
                                <option value="19.41">19:25 PM</option>
                                <option value="19.50">19:30 PM</option>
                                <option value="19.58">19:35 PM</option>
                                <option value="19.66">19:40 PM</option>
                                <option value="19.75">19:45 PM</option>
                                <option value="19.83">19:50 PM</option>
                                <option value="19.91">19:55 PM</option>
                                <option value="20.00">20:00 PM - o'clock</option>
                                <option value="20.8">20:05 PM</option>
                                <option value="20.16">20:10 PM</option>
                                <option value="20.25">20:15 PM</option>
                                <option value="20.33">20:20 PM</option>
                                <option value="20.41">20:25 PM</option>
                                <option value="20.50">20:30 PM</option>
                                <option value="20.58">20:35 PM</option>
                                <option value="20.66">20:40 PM</option>
                                <option value="20.75">20:45 PM</option>
                                <option value="20.83">20:50 PM</option>
                                <option value="20.91">20:55 PM</option>
                                <option value="21.00">21:00 PM - o'clock</option>
                                <option value="21.8">21:05 PM</option>
                                <option value="21.16">21:10 PM</option>
                                <option value="21.25">21:15 PM</option>
                                <option value="21.33">21:20 PM</option>
                                <option value="21.41">21:25 PM</option>
                                <option value="21.50">21:30 PM</option>
                                <option value="21.58">21:35 PM</option>
                                <option value="21.66">21:40 PM</option>
                                <option value="21.75">21:45 PM</option>
                                <option value="21.83">21:50 PM</option>
                                <option value="21.91">21:55 PM</option>
                                <option value="22.00">22:00 PM - o'clock</option>
                                <option value="22.8">22:05 PM</option>
                                <option value="22.16">22:10 PM</option>
                                <option value="22.25">22:15 PM</option>
                                <option value="22.33">22:20 PM</option>
                                <option value="22.41">22:25 PM</option>
                                <option value="22.50">22:30 PM</option>
                                <option value="22.58">22:35 PM</option>
                                <option value="22.66">22:40 PM</option>
                                <option value="22.75">22:45 PM</option>
                                <option value="22.83">22:50 PM</option>
                                <option value="22.91">22:55 PM</option>
                                <option value="23.00">23:00 PM - o'clock</option>
                                <option value="23.8">23:05 PM</option>
                                <option value="23.16">23:10 PM</option>
                                <option value="23.25">23:15 PM</option>
                                <option value="23.33">23:20 PM</option>
                                <option value="23.41">23:25 PM</option>
                                <option value="23.50">23:30 PM</option>
                                <option value="23.58">23:35 PM</option>
                                <option value="23.66">23:40 PM</option>
                                <option value="23.75">23:45 PM</option>
                                <option value="23.83">23:50 PM</option>
                                <option value="23.91">23:55 PM</option>
                                <option value="24.00">24:00 PM - o'clock</option>
                                <option value="24.8">15:05 PM</option>
                                <option value="24.16">24:10 PM</option>
                                <option value="24.25">24:15 PM</option>
                                <option value="24.33">24:20 PM</option>
                                <option value="24.41">24:25 PM</option>
                                <option value="24.50">24:30 PM</option>
                                <option value="24.58">24:35 PM</option>
                                <option value="24.66">24:40 PM</option>
                                <option value="24.75">24:45 PM</option>
                                <option value="24.83">24:50 PM</option>
                                <option value="24.91">24:55 PM</option>
                            </select>
                            {data23.map((datas, index) =>{
                              return<div key={index}>
                                        <p>{datas.endhour}</p>
                                    </div>
                                })}
                        </div>
                        <div className="col-md-2">
                            <select name="type23" onChange={e =>  settype23(e.target.value)} className="form-control" required>
                                <option value="">Select Type</option>
                                <option value="Normal">Normal</option>
                                <option value="SJUK">SJUK</option>
                                <option value="Holiday">Holiday</option>
                            </select>
                            {data23.map((datas, index) =>{
                              return<div key={index}>
                                        <p>{datas.type}</p>
                                    </div>
                                })}
                          </div>
                          <div className="col-md-2"> 
                              <button type='submit' className="btn btn-primary">Submit</button>
                        </div>
                      </form>
                  </div>
                  <div className="d-flex flex-column p-2">
                     <form action="" className="row"  onSubmit={handleSubmit24}>
                        <div className="col-md-1"> 24 </div>
                        <div className="col-md-1"> Thursday </div>
                        <div className="col-md-1" > 
                            {data24.map((types, index) =>{
                              return<tr key={index}>
                                   <p style={types.type === "Normal" ? {backgroundColor:'rgba(7, 190, 7, 0.648)', color:'#000', padding:'5px 10px'} : 
                                     (types.type === "Holiday" ? {backgroundColor:'rgba(255, 0, 0, 0.438)', color:'#000', padding:'5px 10px'} : 
                                      {backgroundColor:'#008cff5a', color:'#000', padding:'5px 10px'}) }> {types.type}</p>
                                    </tr>
                                })}
                        </div>
                        <div className="col-md-2">
                            <select name="starthour24" onChange={e =>  setStart24(e.target.value)} className="form-control">
                                <option value="">Start Time</option>
                                <option value="05.00">05:00 AM</option>
                                <option value="06.00">06:00 AM</option>
                                <option value="06.8">06:05 AM</option>
                                <option value="06.16">06:10 AM</option>
                                <option value="06.25">06:15 AM</option>
                                <option value="06.33">06:20 AM</option>
                                <option value="06.41">06:25 AM</option>
                                <option value="06.50">06:30 AM</option>
                                <option value="06.58">06:35 AM</option>
                                <option value="06.66">06:40 AM</option>
                                <option value="06.75">06:45 AM</option>
                                <option value="06.83">06:50 AM</option>
                                <option value="06.91">06:55 AM</option>
                                <option value="07.00">07:00 AM - Normal</option>
                                <option value="07.8">07:05 AM</option>
                                <option value="07.16">07:10 AM</option>
                                <option value="07.25">07:15 AM</option>
                                <option value="07.33">07:20 AM</option>
                                <option value="07.41">07:25 AM</option>
                                <option value="07.50">07:30 AM</option>
                                <option value="07.58">07:35 AM</option>
                                <option value="07.66">07:40 AM</option>
                                <option value="07.75">07:45 AM</option>
                                <option value="07.83">07:50 AM</option>
                                <option value="07.91">07:55 AM</option>
                                <option value="08.00">08:00 AM - o'clock</option>
                                <option value="08.8">08:05 AM</option>
                                <option value="08.16">08:10 AM</option>
                                <option value="08.25">08:15 AM</option>
                                <option value="08.33">08:20 AM</option>
                                <option value="08.41">08:25 AM</option>
                                <option value="08.50">08:30 AM</option>
                                <option value="08.58">08:35 AM</option>
                                <option value="08.66">08:40 AM</option>
                                <option value="08.75">08:45 AM</option>
                                <option value="08.83">08:50 AM</option>
                                <option value="08.91">08:55 AM</option>
                                <option value="09.00">09:00 AM - o'clock</option>
                                <option value="09.8">09:05 AM</option>
                                <option value="09.16">09:10 AM</option>
                                <option value="09.25">09:15 AM</option>
                                <option value="09.33">09:20 AM</option>
                                <option value="09.41">09:25 AM</option>
                                <option value="09.50">09:30 AM</option>
                                <option value="09.58">09:35 AM</option>
                                <option value="09.66">09:40 AM</option>
                                <option value="09.75">09:45 AM</option>
                                <option value="09.83">09:50 AM</option>
                                <option value="09.91">09:55 AM</option>
                                <option value="10.00">10:00 AM - o'clock</option>
                                <option value="10.8">10:05 AM</option>
                                <option value="10.16">10:10 AM</option>
                                <option value="10.25">10:15 AM</option>
                                <option value="10.33">10:20 AM</option>
                                <option value="10.41">10:25 AM</option>
                                <option value="10.50">10:30 AM</option>
                                <option value="10.58">10:35 AM</option>
                                <option value="10.66">10:40 AM</option>
                                <option value="10.75">10:45 AM</option>
                                <option value="10.83">10:50 AM</option>
                                <option value="10.91">10:55 AM</option>
                                <option value="11.00">11:00 AM - o'clock</option>
                                <option value="11.8">11:05 AM</option>
                                <option value="11.16">11:10 AM</option>
                                <option value="11.25">11:15 AM</option>
                                <option value="11.33">11:20 AM</option>
                                <option value="11.41">11:25 AM</option>
                                <option value="11.50">11:30 AM</option>
                                <option value="11.58">11:35 AM</option>
                                <option value="11.66">11:40 AM</option>
                                <option value="11.75">11:45 AM</option>
                                <option value="11.83">11:50 AM</option>
                                <option value="11.91">11:55 AM</option>
                                <option value="12.00">12:00 PM  - o'clock</option>
                                <option value="12.8">12:05 PM</option>
                                <option value="12.16">12:10 PM</option>
                                <option value="12.25">12:15 PM</option>
                                <option value="12.33">12:20 PM</option>
                                <option value="12.41">12:25 PM</option>
                                <option value="12.50">12:30 PM</option>
                                <option value="12.58">12:35 PM</option>
                                <option value="12.66">12:40 PM</option>
                                <option value="12.75">12:45 PM</option>
                                <option value="12.83">12:50 PM</option>
                                <option value="12.91">12:55 PM</option>
                                <option value="13.00">13:00 PM - o'clock</option>
                                <option value="13.8">13:05 PM</option>
                                <option value="13.16">13:10 PM</option>
                                <option value="13.25">13:15 PM</option>
                                <option value="13.33">13:20 PM</option>
                                <option value="13.41">13:25 PM</option>
                                <option value="13.50">13:30 PM</option>
                                <option value="13.58">13:35 PM</option>
                                <option value="13.66">13:40 PM</option>
                                <option value="13.75">13:45 PM</option>
                                <option value="13.83">13:50 PM</option>
                                <option value="13.91">13:55 PM</option>
                                <option value="14.00">14:00 PM - o'clock</option>
                                <option value="14.8">14:05 PM</option>
                                <option value="14.16">14:10 PM</option>
                                <option value="14.25">14:15 PM</option>
                                <option value="14.33">14:20 PM</option>
                                <option value="14.41">14:25 PM</option>
                                <option value="14.50">14:30 PM</option>
                                <option value="14.58">14:35 PM</option>
                                <option value="14.66">14:40 PM</option>
                                <option value="14.75">14:45 PM</option>
                                <option value="14.83">14:50 PM</option>
                                <option value="14.91">14:55 PM</option>
                                <option value="15.00">15:00 PM - o'clock</option>
                                <option value="15.8">15:05 PM</option>
                                <option value="15.16">15:10 PM</option>
                                <option value="15.25">15:15 PM</option>
                                <option value="15.33">15:20 PM</option>
                                <option value="15.41">15:25 PM</option>
                                <option value="15.50">15:30 PM</option>
                                <option value="15.58">15:35 PM</option>
                                <option value="15.66">15:40 PM</option>
                                <option value="15.75">15:45 PM</option>
                                <option value="15.83">15:50 PM</option>
                                <option value="15.91">15:55 PM</option>
                                <option value="16.00">16:00 PM - o'clock</option>
                                <option value="16.8">16:05 PM</option>
                                <option value="16.16">16:10 PM</option>
                                <option value="16.25">16:15 PM</option>
                                <option value="16.33">16:20 PM</option>
                                <option value="16.41">16:25 PM</option>
                                <option value="16.50">16:30 PM</option>
                                <option value="16.58">16:35 PM</option>
                                <option value="16.66">16:40 PM</option>
                                <option value="16.75">16:45 PM</option>
                                <option value="16.83">16:50 PM</option>
                                <option value="16.91">16:55 PM</option>
                                <option value="17.00">17:00 PM - o'clock</option>
                                <option value="17.8">17:05 PM</option>
                                <option value="17.16">17:10 PM</option>
                                <option value="17.25">17:15 PM</option>
                                <option value="17.33">17:20 PM</option>
                                <option value="17.41">17:25 PM</option>
                                <option value="17.50">17:30 PM</option>
                                <option value="17.58">17:35 PM</option>
                                <option value="17.66">17:40 PM</option>
                                <option value="17.75">17:45 PM</option>
                                <option value="17.83">17:50 PM</option>
                                <option value="17.91">17:55 PM</option>
                                <option value="18.00">18:00 PM - Normal</option>
                                <option value="18.8">18:05 PM</option>
                                <option value="18.16">18:10 PM</option>
                                <option value="18.25">18:15 PM</option>
                                <option value="18.33">18:20 PM</option>
                                <option value="18.41">18:25 PM</option>
                                <option value="18.50">18:30 PM</option>
                                <option value="18.58">18:35 PM</option>
                                <option value="18.66">18:40 PM</option>
                                <option value="18.75">18:45 PM</option>
                                <option value="18.83">18:50 PM</option>
                                <option value="18.91">18:55 PM</option>
                                <option value="19.00">19:00 PM - o'clock</option>
                                <option value="19.8">19:05 PM</option>
                                <option value="19.16">19:10 PM</option>
                                <option value="19.25">19:15 PM</option>
                                <option value="19.33">19:20 PM</option>
                                <option value="19.41">19:25 PM</option>
                                <option value="19.50">19:30 PM</option>
                                <option value="19.58">19:35 PM</option>
                                <option value="19.66">19:40 PM</option>
                                <option value="19.75">19:45 PM</option>
                                <option value="19.83">19:50 PM</option>
                                <option value="19.91">19:55 PM</option>
                                <option value="20.00">20:00 PM - o'clock</option>
                                <option value="20.8">20:05 PM</option>
                                <option value="20.16">20:10 PM</option>
                                <option value="20.25">20:15 PM</option>
                                <option value="20.33">20:20 PM</option>
                                <option value="20.41">20:25 PM</option>
                                <option value="20.50">20:30 PM</option>
                                <option value="20.58">20:35 PM</option>
                                <option value="20.66">20:40 PM</option>
                                <option value="20.75">20:45 PM</option>
                                <option value="20.83">20:50 PM</option>
                                <option value="20.91">20:55 PM</option>
                                <option value="21.00">21:00 PM - o'clock</option>
                                <option value="21.8">21:05 PM</option>
                                <option value="21.16">21:10 PM</option>
                                <option value="21.25">21:15 PM</option>
                                <option value="21.33">21:20 PM</option>
                                <option value="21.41">21:25 PM</option>
                                <option value="21.50">21:30 PM</option>
                                <option value="21.58">21:35 PM</option>
                                <option value="21.66">21:40 PM</option>
                                <option value="21.75">21:45 PM</option>
                                <option value="21.83">21:50 PM</option>
                                <option value="21.91">21:55 PM</option>
                                <option value="22.00">22:00 PM - o'clock</option>
                                <option value="22.8">22:05 PM</option>
                                <option value="22.16">22:10 PM</option>
                                <option value="22.25">22:15 PM</option>
                                <option value="22.33">22:20 PM</option>
                                <option value="22.41">22:25 PM</option>
                                <option value="22.50">22:30 PM</option>
                                <option value="22.58">22:35 PM</option>
                                <option value="22.66">22:40 PM</option>
                                <option value="22.75">22:45 PM</option>
                                <option value="22.83">22:50 PM</option>
                                <option value="22.91">22:55 PM</option>
                                <option value="23.00">23:00 PM - o'clock</option>
                                <option value="23.8">23:05 PM</option>
                                <option value="23.16">23:10 PM</option>
                                <option value="23.25">23:15 PM</option>
                                <option value="23.33">23:20 PM</option>
                                <option value="23.41">23:25 PM</option>
                                <option value="23.50">23:30 PM</option>
                                <option value="23.58">23:35 PM</option>
                                <option value="23.66">23:40 PM</option>
                                <option value="23.75">23:45 PM</option>
                                <option value="23.83">23:50 PM</option>
                                <option value="23.91">23:55 PM</option>
                                <option value="24.00">24:00 PM - o'clock</option>
                                <option value="24.8">15:05 PM</option>
                                <option value="24.16">24:10 PM</option>
                                <option value="24.25">24:15 PM</option>
                                <option value="24.33">24:20 PM</option>
                                <option value="24.41">24:25 PM</option>
                                <option value="24.50">24:30 PM</option>
                                <option value="24.58">24:35 PM</option>
                                <option value="24.66">24:40 PM</option>
                                <option value="24.75">24:45 PM</option>
                                <option value="24.83">24:50 PM</option>
                                <option value="24.91">24:55 PM</option>
                            </select>
                            {data24.map((datas, index) =>{
                              return<div key={index}>
                                        <p>{datas.starthour}</p>
                                    </div>
                                })}
                        </div>
                        <div className="col-md-2">
                            <select name="endhour24" onChange={e =>  setEnd24(e.target.value)} className="form-control">
                                <option value="">Finish Time</option>
                                <option value="05.00">05:00 AM</option>
                                <option value="06.00">06:00 AM</option>
                                <option value="06.8">06:05 AM</option>
                                <option value="06.16">06:10 AM</option>
                                <option value="06.25">06:15 AM</option>
                                <option value="06.33">06:20 AM</option>
                                <option value="06.41">06:25 AM</option>
                                <option value="06.50">06:30 AM</option>
                                <option value="06.58">06:35 AM</option>
                                <option value="06.66">06:40 AM</option>
                                <option value="06.75">06:45 AM</option>
                                <option value="06.83">06:50 AM</option>
                                <option value="06.91">06:55 AM</option>
                                <option value="07.00">07:00 AM - Normal</option>
                                <option value="07.8">07:05 AM</option>
                                <option value="07.16">07:10 AM</option>
                                <option value="07.25">07:15 AM</option>
                                <option value="07.33">07:20 AM</option>
                                <option value="07.41">07:25 AM</option>
                                <option value="07.50">07:30 AM</option>
                                <option value="07.58">07:35 AM</option>
                                <option value="07.66">07:40 AM</option>
                                <option value="07.75">07:45 AM</option>
                                <option value="07.83">07:50 AM</option>
                                <option value="07.91">07:55 AM</option>
                                <option value="08.00">08:00 AM - o'clock</option>
                                <option value="08.8">08:05 AM</option>
                                <option value="08.16">08:10 AM</option>
                                <option value="08.25">08:15 AM</option>
                                <option value="08.33">08:20 AM</option>
                                <option value="08.41">08:25 AM</option>
                                <option value="08.50">08:30 AM</option>
                                <option value="08.58">08:35 AM</option>
                                <option value="08.66">08:40 AM</option>
                                <option value="08.75">08:45 AM</option>
                                <option value="08.83">08:50 AM</option>
                                <option value="08.91">08:55 AM</option>
                                <option value="09.00">09:00 AM - o'clock</option>
                                <option value="09.8">09:05 AM</option>
                                <option value="09.16">09:10 AM</option>
                                <option value="09.25">09:15 AM</option>
                                <option value="09.33">09:20 AM</option>
                                <option value="09.41">09:25 AM</option>
                                <option value="09.50">09:30 AM</option>
                                <option value="09.58">09:35 AM</option>
                                <option value="09.66">09:40 AM</option>
                                <option value="09.75">09:45 AM</option>
                                <option value="09.83">09:50 AM</option>
                                <option value="09.91">09:55 AM</option>
                                <option value="10.00">10:00 AM - o'clock</option>
                                <option value="10.8">10:05 AM</option>
                                <option value="10.16">10:10 AM</option>
                                <option value="10.25">10:15 AM</option>
                                <option value="10.33">10:20 AM</option>
                                <option value="10.41">10:25 AM</option>
                                <option value="10.50">10:30 AM</option>
                                <option value="10.58">10:35 AM</option>
                                <option value="10.66">10:40 AM</option>
                                <option value="10.75">10:45 AM</option>
                                <option value="10.83">10:50 AM</option>
                                <option value="10.91">10:55 AM</option>
                                <option value="11.00">11:00 AM - o'clock</option>
                                <option value="11.8">11:05 AM</option>
                                <option value="11.16">11:10 AM</option>
                                <option value="11.25">11:15 AM</option>
                                <option value="11.33">11:20 AM</option>
                                <option value="11.41">11:25 AM</option>
                                <option value="11.50">11:30 AM</option>
                                <option value="11.58">11:35 AM</option>
                                <option value="11.66">11:40 AM</option>
                                <option value="11.75">11:45 AM</option>
                                <option value="11.83">11:50 AM</option>
                                <option value="11.91">11:55 AM</option>
                                <option value="12.00">12:00 PM  - o'clock</option>
                                <option value="12.8">12:05 PM</option>
                                <option value="12.16">12:10 PM</option>
                                <option value="12.25">12:15 PM</option>
                                <option value="12.33">12:20 PM</option>
                                <option value="12.41">12:25 PM</option>
                                <option value="12.50">12:30 PM</option>
                                <option value="12.58">12:35 PM</option>
                                <option value="12.66">12:40 PM</option>
                                <option value="12.75">12:45 PM</option>
                                <option value="12.83">12:50 PM</option>
                                <option value="12.91">12:55 PM</option>
                                <option value="13.00">13:00 PM - o'clock</option>
                                <option value="13.8">13:05 PM</option>
                                <option value="13.16">13:10 PM</option>
                                <option value="13.25">13:15 PM</option>
                                <option value="13.33">13:20 PM</option>
                                <option value="13.41">13:25 PM</option>
                                <option value="13.50">13:30 PM</option>
                                <option value="13.58">13:35 PM</option>
                                <option value="13.66">13:40 PM</option>
                                <option value="13.75">13:45 PM</option>
                                <option value="13.83">13:50 PM</option>
                                <option value="13.91">13:55 PM</option>
                                <option value="14.00">14:00 PM - o'clock</option>
                                <option value="14.8">14:05 PM</option>
                                <option value="14.16">14:10 PM</option>
                                <option value="14.25">14:15 PM</option>
                                <option value="14.33">14:20 PM</option>
                                <option value="14.41">14:25 PM</option>
                                <option value="14.50">14:30 PM</option>
                                <option value="14.58">14:35 PM</option>
                                <option value="14.66">14:40 PM</option>
                                <option value="14.75">14:45 PM</option>
                                <option value="14.83">14:50 PM</option>
                                <option value="14.91">14:55 PM</option>
                                <option value="15.00">15:00 PM - o'clock</option>
                                <option value="15.8">15:05 PM</option>
                                <option value="15.16">15:10 PM</option>
                                <option value="15.25">15:15 PM</option>
                                <option value="15.33">15:20 PM</option>
                                <option value="15.41">15:25 PM</option>
                                <option value="15.50">15:30 PM</option>
                                <option value="15.58">15:35 PM</option>
                                <option value="15.66">15:40 PM</option>
                                <option value="15.75">15:45 PM</option>
                                <option value="15.83">15:50 PM</option>
                                <option value="15.91">15:55 PM</option>
                                <option value="16.00">16:00 PM - o'clock</option>
                                <option value="16.8">16:05 PM</option>
                                <option value="16.16">16:10 PM</option>
                                <option value="16.25">16:15 PM</option>
                                <option value="16.33">16:20 PM</option>
                                <option value="16.41">16:25 PM</option>
                                <option value="16.50">16:30 PM</option>
                                <option value="16.58">16:35 PM</option>
                                <option value="16.66">16:40 PM</option>
                                <option value="16.75">16:45 PM</option>
                                <option value="16.83">16:50 PM</option>
                                <option value="16.91">16:55 PM</option>
                                <option value="17.00">17:00 PM - o'clock</option>
                                <option value="17.8">17:05 PM</option>
                                <option value="17.16">17:10 PM</option>
                                <option value="17.25">17:15 PM</option>
                                <option value="17.33">17:20 PM</option>
                                <option value="17.41">17:25 PM</option>
                                <option value="17.50">17:30 PM</option>
                                <option value="17.58">17:35 PM</option>
                                <option value="17.66">17:40 PM</option>
                                <option value="17.75">17:45 PM</option>
                                <option value="17.83">17:50 PM</option>
                                <option value="17.91">17:55 PM</option>
                                <option value="18.00">18:00 PM - Normal</option>
                                <option value="18.8">18:05 PM</option>
                                <option value="18.16">18:10 PM</option>
                                <option value="18.25">18:15 PM</option>
                                <option value="18.33">18:20 PM</option>
                                <option value="18.41">18:25 PM</option>
                                <option value="18.50">18:30 PM</option>
                                <option value="18.58">18:35 PM</option>
                                <option value="18.66">18:40 PM</option>
                                <option value="18.75">18:45 PM</option>
                                <option value="18.83">18:50 PM</option>
                                <option value="18.91">18:55 PM</option>
                                <option value="19.00">19:00 PM - o'clock</option>
                                <option value="19.8">19:05 PM</option>
                                <option value="19.16">19:10 PM</option>
                                <option value="19.25">19:15 PM</option>
                                <option value="19.33">19:20 PM</option>
                                <option value="19.41">19:25 PM</option>
                                <option value="19.50">19:30 PM</option>
                                <option value="19.58">19:35 PM</option>
                                <option value="19.66">19:40 PM</option>
                                <option value="19.75">19:45 PM</option>
                                <option value="19.83">19:50 PM</option>
                                <option value="19.91">19:55 PM</option>
                                <option value="20.00">20:00 PM - o'clock</option>
                                <option value="20.8">20:05 PM</option>
                                <option value="20.16">20:10 PM</option>
                                <option value="20.25">20:15 PM</option>
                                <option value="20.33">20:20 PM</option>
                                <option value="20.41">20:25 PM</option>
                                <option value="20.50">20:30 PM</option>
                                <option value="20.58">20:35 PM</option>
                                <option value="20.66">20:40 PM</option>
                                <option value="20.75">20:45 PM</option>
                                <option value="20.83">20:50 PM</option>
                                <option value="20.91">20:55 PM</option>
                                <option value="21.00">21:00 PM - o'clock</option>
                                <option value="21.8">21:05 PM</option>
                                <option value="21.16">21:10 PM</option>
                                <option value="21.25">21:15 PM</option>
                                <option value="21.33">21:20 PM</option>
                                <option value="21.41">21:25 PM</option>
                                <option value="21.50">21:30 PM</option>
                                <option value="21.58">21:35 PM</option>
                                <option value="21.66">21:40 PM</option>
                                <option value="21.75">21:45 PM</option>
                                <option value="21.83">21:50 PM</option>
                                <option value="21.91">21:55 PM</option>
                                <option value="22.00">22:00 PM - o'clock</option>
                                <option value="22.8">22:05 PM</option>
                                <option value="22.16">22:10 PM</option>
                                <option value="22.25">22:15 PM</option>
                                <option value="22.33">22:20 PM</option>
                                <option value="22.41">22:25 PM</option>
                                <option value="22.50">22:30 PM</option>
                                <option value="22.58">22:35 PM</option>
                                <option value="22.66">22:40 PM</option>
                                <option value="22.75">22:45 PM</option>
                                <option value="22.83">22:50 PM</option>
                                <option value="22.91">22:55 PM</option>
                                <option value="23.00">23:00 PM - o'clock</option>
                                <option value="23.8">23:05 PM</option>
                                <option value="23.16">23:10 PM</option>
                                <option value="23.25">23:15 PM</option>
                                <option value="23.33">23:20 PM</option>
                                <option value="23.41">23:25 PM</option>
                                <option value="23.50">23:30 PM</option>
                                <option value="23.58">23:35 PM</option>
                                <option value="23.66">23:40 PM</option>
                                <option value="23.75">23:45 PM</option>
                                <option value="23.83">23:50 PM</option>
                                <option value="23.91">23:55 PM</option>
                                <option value="24.00">24:00 PM - o'clock</option>
                                <option value="24.8">15:05 PM</option>
                                <option value="24.16">24:10 PM</option>
                                <option value="24.25">24:15 PM</option>
                                <option value="24.33">24:20 PM</option>
                                <option value="24.41">24:25 PM</option>
                                <option value="24.50">24:30 PM</option>
                                <option value="24.58">24:35 PM</option>
                                <option value="24.66">24:40 PM</option>
                                <option value="24.75">24:45 PM</option>
                                <option value="24.83">24:50 PM</option>
                                <option value="24.91">24:55 PM</option>
                            </select>
                            {data24.map((datas, index) =>{
                              return<div key={index}>
                                        <p>{datas.endhour}</p>
                                    </div>
                                })}
                        </div>
                        <div className="col-md-2">
                            <select name="type24" onChange={e =>  settype24(e.target.value)} className="form-control" required>
                                <option value="">Select Type</option>
                                <option value="Normal">Normal</option>
                                <option value="SJUK">SJUK</option>
                                <option value="Holiday">Holiday</option>
                            </select>
                            {data24.map((datas, index) =>{
                              return<div key={index}>
                                        <p>{datas.type}</p>
                                    </div>
                                })}
                          </div>
                          <div className="col-md-2"> 
                              <button type='submit' className="btn btn-primary">Submit</button>
                        </div>
                      </form>
                  </div>
                  <div className="d-flex flex-column p-2">
                     <form action="" className="row"  onSubmit={handleSubmit25}>
                        <div className="col-md-1"> 25 </div>
                        <div className="col-md-1"> Friday  </div>
                        <div className="col-md-1" > 
                            {data25.map((types, index) =>{
                              return<tr key={index}>
                                   <p style={types.type === "Normal" ? {backgroundColor:'rgba(7, 190, 7, 0.648)', color:'#000', padding:'5px 10px'} : 
                                     (types.type === "Holiday" ? {backgroundColor:'rgba(255, 0, 0, 0.438)', color:'#000', padding:'5px 10px'} : 
                                      {backgroundColor:'#008cff5a', color:'#000', padding:'5px 10px'}) }> {types.type}</p>
                                    </tr>
                                })}
                        </div>
                        <div className="col-md-2">
                            <select name="starthour25" onChange={e =>  setStart25(e.target.value)} className="form-control">
                                <option value="">Start Time</option>
                                <option value="05.00">05:00 AM</option>
                                <option value="06.00">06:00 AM</option>
                                <option value="06.8">06:05 AM</option>
                                <option value="06.16">06:10 AM</option>
                                <option value="06.25">06:15 AM</option>
                                <option value="06.33">06:20 AM</option>
                                <option value="06.41">06:25 AM</option>
                                <option value="06.50">06:30 AM</option>
                                <option value="06.58">06:35 AM</option>
                                <option value="06.66">06:40 AM</option>
                                <option value="06.75">06:45 AM</option>
                                <option value="06.83">06:50 AM</option>
                                <option value="06.91">06:55 AM</option>
                                <option value="07.00">07:00 AM - Normal</option>
                                <option value="07.8">07:05 AM</option>
                                <option value="07.16">07:10 AM</option>
                                <option value="07.25">07:15 AM</option>
                                <option value="07.33">07:20 AM</option>
                                <option value="07.41">07:25 AM</option>
                                <option value="07.50">07:30 AM</option>
                                <option value="07.58">07:35 AM</option>
                                <option value="07.66">07:40 AM</option>
                                <option value="07.75">07:45 AM</option>
                                <option value="07.83">07:50 AM</option>
                                <option value="07.91">07:55 AM</option>
                                <option value="08.00">08:00 AM - o'clock</option>
                                <option value="08.8">08:05 AM</option>
                                <option value="08.16">08:10 AM</option>
                                <option value="08.25">08:15 AM</option>
                                <option value="08.33">08:20 AM</option>
                                <option value="08.41">08:25 AM</option>
                                <option value="08.50">08:30 AM</option>
                                <option value="08.58">08:35 AM</option>
                                <option value="08.66">08:40 AM</option>
                                <option value="08.75">08:45 AM</option>
                                <option value="08.83">08:50 AM</option>
                                <option value="08.91">08:55 AM</option>
                                <option value="09.00">09:00 AM - o'clock</option>
                                <option value="09.8">09:05 AM</option>
                                <option value="09.16">09:10 AM</option>
                                <option value="09.25">09:15 AM</option>
                                <option value="09.33">09:20 AM</option>
                                <option value="09.41">09:25 AM</option>
                                <option value="09.50">09:30 AM</option>
                                <option value="09.58">09:35 AM</option>
                                <option value="09.66">09:40 AM</option>
                                <option value="09.75">09:45 AM</option>
                                <option value="09.83">09:50 AM</option>
                                <option value="09.91">09:55 AM</option>
                                <option value="10.00">10:00 AM - o'clock</option>
                                <option value="10.8">10:05 AM</option>
                                <option value="10.16">10:10 AM</option>
                                <option value="10.25">10:15 AM</option>
                                <option value="10.33">10:20 AM</option>
                                <option value="10.41">10:25 AM</option>
                                <option value="10.50">10:30 AM</option>
                                <option value="10.58">10:35 AM</option>
                                <option value="10.66">10:40 AM</option>
                                <option value="10.75">10:45 AM</option>
                                <option value="10.83">10:50 AM</option>
                                <option value="10.91">10:55 AM</option>
                                <option value="11.00">11:00 AM - o'clock</option>
                                <option value="11.8">11:05 AM</option>
                                <option value="11.16">11:10 AM</option>
                                <option value="11.25">11:15 AM</option>
                                <option value="11.33">11:20 AM</option>
                                <option value="11.41">11:25 AM</option>
                                <option value="11.50">11:30 AM</option>
                                <option value="11.58">11:35 AM</option>
                                <option value="11.66">11:40 AM</option>
                                <option value="11.75">11:45 AM</option>
                                <option value="11.83">11:50 AM</option>
                                <option value="11.91">11:55 AM</option>
                                <option value="12.00">12:00 PM  - o'clock</option>
                                <option value="12.8">12:05 PM</option>
                                <option value="12.16">12:10 PM</option>
                                <option value="12.25">12:15 PM</option>
                                <option value="12.33">12:20 PM</option>
                                <option value="12.41">12:25 PM</option>
                                <option value="12.50">12:30 PM</option>
                                <option value="12.58">12:35 PM</option>
                                <option value="12.66">12:40 PM</option>
                                <option value="12.75">12:45 PM</option>
                                <option value="12.83">12:50 PM</option>
                                <option value="12.91">12:55 PM</option>
                                <option value="13.00">13:00 PM - o'clock</option>
                                <option value="13.8">13:05 PM</option>
                                <option value="13.16">13:10 PM</option>
                                <option value="13.25">13:15 PM</option>
                                <option value="13.33">13:20 PM</option>
                                <option value="13.41">13:25 PM</option>
                                <option value="13.50">13:30 PM</option>
                                <option value="13.58">13:35 PM</option>
                                <option value="13.66">13:40 PM</option>
                                <option value="13.75">13:45 PM</option>
                                <option value="13.83">13:50 PM</option>
                                <option value="13.91">13:55 PM</option>
                                <option value="14.00">14:00 PM - o'clock</option>
                                <option value="14.8">14:05 PM</option>
                                <option value="14.16">14:10 PM</option>
                                <option value="14.25">14:15 PM</option>
                                <option value="14.33">14:20 PM</option>
                                <option value="14.41">14:25 PM</option>
                                <option value="14.50">14:30 PM</option>
                                <option value="14.58">14:35 PM</option>
                                <option value="14.66">14:40 PM</option>
                                <option value="14.75">14:45 PM</option>
                                <option value="14.83">14:50 PM</option>
                                <option value="14.91">14:55 PM</option>
                                <option value="15.00">15:00 PM - o'clock</option>
                                <option value="15.8">15:05 PM</option>
                                <option value="15.16">15:10 PM</option>
                                <option value="15.25">15:15 PM</option>
                                <option value="15.33">15:20 PM</option>
                                <option value="15.41">15:25 PM</option>
                                <option value="15.50">15:30 PM</option>
                                <option value="15.58">15:35 PM</option>
                                <option value="15.66">15:40 PM</option>
                                <option value="15.75">15:45 PM</option>
                                <option value="15.83">15:50 PM</option>
                                <option value="15.91">15:55 PM</option>
                                <option value="16.00">16:00 PM - o'clock</option>
                                <option value="16.8">16:05 PM</option>
                                <option value="16.16">16:10 PM</option>
                                <option value="16.25">16:15 PM</option>
                                <option value="16.33">16:20 PM</option>
                                <option value="16.41">16:25 PM</option>
                                <option value="16.50">16:30 PM</option>
                                <option value="16.58">16:35 PM</option>
                                <option value="16.66">16:40 PM</option>
                                <option value="16.75">16:45 PM</option>
                                <option value="16.83">16:50 PM</option>
                                <option value="16.91">16:55 PM</option>
                                <option value="17.00">17:00 PM - o'clock</option>
                                <option value="17.8">17:05 PM</option>
                                <option value="17.16">17:10 PM</option>
                                <option value="17.25">17:15 PM</option>
                                <option value="17.33">17:20 PM</option>
                                <option value="17.41">17:25 PM</option>
                                <option value="17.50">17:30 PM</option>
                                <option value="17.58">17:35 PM</option>
                                <option value="17.66">17:40 PM</option>
                                <option value="17.75">17:45 PM</option>
                                <option value="17.83">17:50 PM</option>
                                <option value="17.91">17:55 PM</option>
                                <option value="18.00">18:00 PM - Normal</option>
                                <option value="18.8">18:05 PM</option>
                                <option value="18.16">18:10 PM</option>
                                <option value="18.25">18:15 PM</option>
                                <option value="18.33">18:20 PM</option>
                                <option value="18.41">18:25 PM</option>
                                <option value="18.50">18:30 PM</option>
                                <option value="18.58">18:35 PM</option>
                                <option value="18.66">18:40 PM</option>
                                <option value="18.75">18:45 PM</option>
                                <option value="18.83">18:50 PM</option>
                                <option value="18.91">18:55 PM</option>
                                <option value="19.00">19:00 PM - o'clock</option>
                                <option value="19.8">19:05 PM</option>
                                <option value="19.16">19:10 PM</option>
                                <option value="19.25">19:15 PM</option>
                                <option value="19.33">19:20 PM</option>
                                <option value="19.41">19:25 PM</option>
                                <option value="19.50">19:30 PM</option>
                                <option value="19.58">19:35 PM</option>
                                <option value="19.66">19:40 PM</option>
                                <option value="19.75">19:45 PM</option>
                                <option value="19.83">19:50 PM</option>
                                <option value="19.91">19:55 PM</option>
                                <option value="20.00">20:00 PM - o'clock</option>
                                <option value="20.8">20:05 PM</option>
                                <option value="20.16">20:10 PM</option>
                                <option value="20.25">20:15 PM</option>
                                <option value="20.33">20:20 PM</option>
                                <option value="20.41">20:25 PM</option>
                                <option value="20.50">20:30 PM</option>
                                <option value="20.58">20:35 PM</option>
                                <option value="20.66">20:40 PM</option>
                                <option value="20.75">20:45 PM</option>
                                <option value="20.83">20:50 PM</option>
                                <option value="20.91">20:55 PM</option>
                                <option value="21.00">21:00 PM - o'clock</option>
                                <option value="21.8">21:05 PM</option>
                                <option value="21.16">21:10 PM</option>
                                <option value="21.25">21:15 PM</option>
                                <option value="21.33">21:20 PM</option>
                                <option value="21.41">21:25 PM</option>
                                <option value="21.50">21:30 PM</option>
                                <option value="21.58">21:35 PM</option>
                                <option value="21.66">21:40 PM</option>
                                <option value="21.75">21:45 PM</option>
                                <option value="21.83">21:50 PM</option>
                                <option value="21.91">21:55 PM</option>
                                <option value="22.00">22:00 PM - o'clock</option>
                                <option value="22.8">22:05 PM</option>
                                <option value="22.16">22:10 PM</option>
                                <option value="22.25">22:15 PM</option>
                                <option value="22.33">22:20 PM</option>
                                <option value="22.41">22:25 PM</option>
                                <option value="22.50">22:30 PM</option>
                                <option value="22.58">22:35 PM</option>
                                <option value="22.66">22:40 PM</option>
                                <option value="22.75">22:45 PM</option>
                                <option value="22.83">22:50 PM</option>
                                <option value="22.91">22:55 PM</option>
                                <option value="23.00">23:00 PM - o'clock</option>
                                <option value="23.8">23:05 PM</option>
                                <option value="23.16">23:10 PM</option>
                                <option value="23.25">23:15 PM</option>
                                <option value="23.33">23:20 PM</option>
                                <option value="23.41">23:25 PM</option>
                                <option value="23.50">23:30 PM</option>
                                <option value="23.58">23:35 PM</option>
                                <option value="23.66">23:40 PM</option>
                                <option value="23.75">23:45 PM</option>
                                <option value="23.83">23:50 PM</option>
                                <option value="23.91">23:55 PM</option>
                                <option value="24.00">24:00 PM - o'clock</option>
                                <option value="24.8">15:05 PM</option>
                                <option value="24.16">24:10 PM</option>
                                <option value="24.25">24:15 PM</option>
                                <option value="24.33">24:20 PM</option>
                                <option value="24.41">24:25 PM</option>
                                <option value="24.50">24:30 PM</option>
                                <option value="24.58">24:35 PM</option>
                                <option value="24.66">24:40 PM</option>
                                <option value="24.75">24:45 PM</option>
                                <option value="24.83">24:50 PM</option>
                                <option value="24.91">24:55 PM</option>
                            </select>
                            {data25.map((datas, index) =>{
                              return<div key={index}>
                                        <p>{datas.starthour}</p>
                                    </div>
                                })}
                        </div>
                        <div className="col-md-2">
                            <select name="endhour25" onChange={e =>  setEnd25(e.target.value)} className="form-control">
                                <option value="">Finish Time</option>
                                <option value="05.00">05:00 AM</option>
                                <option value="06.00">06:00 AM</option>
                                <option value="06.8">06:05 AM</option>
                                <option value="06.16">06:10 AM</option>
                                <option value="06.25">06:15 AM</option>
                                <option value="06.33">06:20 AM</option>
                                <option value="06.41">06:25 AM</option>
                                <option value="06.50">06:30 AM</option>
                                <option value="06.58">06:35 AM</option>
                                <option value="06.66">06:40 AM</option>
                                <option value="06.75">06:45 AM</option>
                                <option value="06.83">06:50 AM</option>
                                <option value="06.91">06:55 AM</option>
                                <option value="07.00">07:00 AM - Normal</option>
                                <option value="07.8">07:05 AM</option>
                                <option value="07.16">07:10 AM</option>
                                <option value="07.25">07:15 AM</option>
                                <option value="07.33">07:20 AM</option>
                                <option value="07.41">07:25 AM</option>
                                <option value="07.50">07:30 AM</option>
                                <option value="07.58">07:35 AM</option>
                                <option value="07.66">07:40 AM</option>
                                <option value="07.75">07:45 AM</option>
                                <option value="07.83">07:50 AM</option>
                                <option value="07.91">07:55 AM</option>
                                <option value="08.00">08:00 AM - o'clock</option>
                                <option value="08.8">08:05 AM</option>
                                <option value="08.16">08:10 AM</option>
                                <option value="08.25">08:15 AM</option>
                                <option value="08.33">08:20 AM</option>
                                <option value="08.41">08:25 AM</option>
                                <option value="08.50">08:30 AM</option>
                                <option value="08.58">08:35 AM</option>
                                <option value="08.66">08:40 AM</option>
                                <option value="08.75">08:45 AM</option>
                                <option value="08.83">08:50 AM</option>
                                <option value="08.91">08:55 AM</option>
                                <option value="09.00">09:00 AM - o'clock</option>
                                <option value="09.8">09:05 AM</option>
                                <option value="09.16">09:10 AM</option>
                                <option value="09.25">09:15 AM</option>
                                <option value="09.33">09:20 AM</option>
                                <option value="09.41">09:25 AM</option>
                                <option value="09.50">09:30 AM</option>
                                <option value="09.58">09:35 AM</option>
                                <option value="09.66">09:40 AM</option>
                                <option value="09.75">09:45 AM</option>
                                <option value="09.83">09:50 AM</option>
                                <option value="09.91">09:55 AM</option>
                                <option value="10.00">10:00 AM - o'clock</option>
                                <option value="10.8">10:05 AM</option>
                                <option value="10.16">10:10 AM</option>
                                <option value="10.25">10:15 AM</option>
                                <option value="10.33">10:20 AM</option>
                                <option value="10.41">10:25 AM</option>
                                <option value="10.50">10:30 AM</option>
                                <option value="10.58">10:35 AM</option>
                                <option value="10.66">10:40 AM</option>
                                <option value="10.75">10:45 AM</option>
                                <option value="10.83">10:50 AM</option>
                                <option value="10.91">10:55 AM</option>
                                <option value="11.00">11:00 AM - o'clock</option>
                                <option value="11.8">11:05 AM</option>
                                <option value="11.16">11:10 AM</option>
                                <option value="11.25">11:15 AM</option>
                                <option value="11.33">11:20 AM</option>
                                <option value="11.41">11:25 AM</option>
                                <option value="11.50">11:30 AM</option>
                                <option value="11.58">11:35 AM</option>
                                <option value="11.66">11:40 AM</option>
                                <option value="11.75">11:45 AM</option>
                                <option value="11.83">11:50 AM</option>
                                <option value="11.91">11:55 AM</option>
                                <option value="12.00">12:00 PM  - o'clock</option>
                                <option value="12.8">12:05 PM</option>
                                <option value="12.16">12:10 PM</option>
                                <option value="12.25">12:15 PM</option>
                                <option value="12.33">12:20 PM</option>
                                <option value="12.41">12:25 PM</option>
                                <option value="12.50">12:30 PM</option>
                                <option value="12.58">12:35 PM</option>
                                <option value="12.66">12:40 PM</option>
                                <option value="12.75">12:45 PM</option>
                                <option value="12.83">12:50 PM</option>
                                <option value="12.91">12:55 PM</option>
                                <option value="13.00">13:00 PM - o'clock</option>
                                <option value="13.8">13:05 PM</option>
                                <option value="13.16">13:10 PM</option>
                                <option value="13.25">13:15 PM</option>
                                <option value="13.33">13:20 PM</option>
                                <option value="13.41">13:25 PM</option>
                                <option value="13.50">13:30 PM</option>
                                <option value="13.58">13:35 PM</option>
                                <option value="13.66">13:40 PM</option>
                                <option value="13.75">13:45 PM</option>
                                <option value="13.83">13:50 PM</option>
                                <option value="13.91">13:55 PM</option>
                                <option value="14.00">14:00 PM - o'clock</option>
                                <option value="14.8">14:05 PM</option>
                                <option value="14.16">14:10 PM</option>
                                <option value="14.25">14:15 PM</option>
                                <option value="14.33">14:20 PM</option>
                                <option value="14.41">14:25 PM</option>
                                <option value="14.50">14:30 PM</option>
                                <option value="14.58">14:35 PM</option>
                                <option value="14.66">14:40 PM</option>
                                <option value="14.75">14:45 PM</option>
                                <option value="14.83">14:50 PM</option>
                                <option value="14.91">14:55 PM</option>
                                <option value="15.00">15:00 PM - o'clock</option>
                                <option value="15.8">15:05 PM</option>
                                <option value="15.16">15:10 PM</option>
                                <option value="15.25">15:15 PM</option>
                                <option value="15.33">15:20 PM</option>
                                <option value="15.41">15:25 PM</option>
                                <option value="15.50">15:30 PM</option>
                                <option value="15.58">15:35 PM</option>
                                <option value="15.66">15:40 PM</option>
                                <option value="15.75">15:45 PM</option>
                                <option value="15.83">15:50 PM</option>
                                <option value="15.91">15:55 PM</option>
                                <option value="16.00">16:00 PM - o'clock</option>
                                <option value="16.8">16:05 PM</option>
                                <option value="16.16">16:10 PM</option>
                                <option value="16.25">16:15 PM</option>
                                <option value="16.33">16:20 PM</option>
                                <option value="16.41">16:25 PM</option>
                                <option value="16.50">16:30 PM</option>
                                <option value="16.58">16:35 PM</option>
                                <option value="16.66">16:40 PM</option>
                                <option value="16.75">16:45 PM</option>
                                <option value="16.83">16:50 PM</option>
                                <option value="16.91">16:55 PM</option>
                                <option value="17.00">17:00 PM - o'clock</option>
                                <option value="17.8">17:05 PM</option>
                                <option value="17.16">17:10 PM</option>
                                <option value="17.25">17:15 PM</option>
                                <option value="17.33">17:20 PM</option>
                                <option value="17.41">17:25 PM</option>
                                <option value="17.50">17:30 PM</option>
                                <option value="17.58">17:35 PM</option>
                                <option value="17.66">17:40 PM</option>
                                <option value="17.75">17:45 PM</option>
                                <option value="17.83">17:50 PM</option>
                                <option value="17.91">17:55 PM</option>
                                <option value="18.00">18:00 PM - Normal</option>
                                <option value="18.8">18:05 PM</option>
                                <option value="18.16">18:10 PM</option>
                                <option value="18.25">18:15 PM</option>
                                <option value="18.33">18:20 PM</option>
                                <option value="18.41">18:25 PM</option>
                                <option value="18.50">18:30 PM</option>
                                <option value="18.58">18:35 PM</option>
                                <option value="18.66">18:40 PM</option>
                                <option value="18.75">18:45 PM</option>
                                <option value="18.83">18:50 PM</option>
                                <option value="18.91">18:55 PM</option>
                                <option value="19.00">19:00 PM - o'clock</option>
                                <option value="19.8">19:05 PM</option>
                                <option value="19.16">19:10 PM</option>
                                <option value="19.25">19:15 PM</option>
                                <option value="19.33">19:20 PM</option>
                                <option value="19.41">19:25 PM</option>
                                <option value="19.50">19:30 PM</option>
                                <option value="19.58">19:35 PM</option>
                                <option value="19.66">19:40 PM</option>
                                <option value="19.75">19:45 PM</option>
                                <option value="19.83">19:50 PM</option>
                                <option value="19.91">19:55 PM</option>
                                <option value="20.00">20:00 PM - o'clock</option>
                                <option value="20.8">20:05 PM</option>
                                <option value="20.16">20:10 PM</option>
                                <option value="20.25">20:15 PM</option>
                                <option value="20.33">20:20 PM</option>
                                <option value="20.41">20:25 PM</option>
                                <option value="20.50">20:30 PM</option>
                                <option value="20.58">20:35 PM</option>
                                <option value="20.66">20:40 PM</option>
                                <option value="20.75">20:45 PM</option>
                                <option value="20.83">20:50 PM</option>
                                <option value="20.91">20:55 PM</option>
                                <option value="21.00">21:00 PM - o'clock</option>
                                <option value="21.8">21:05 PM</option>
                                <option value="21.16">21:10 PM</option>
                                <option value="21.25">21:15 PM</option>
                                <option value="21.33">21:20 PM</option>
                                <option value="21.41">21:25 PM</option>
                                <option value="21.50">21:30 PM</option>
                                <option value="21.58">21:35 PM</option>
                                <option value="21.66">21:40 PM</option>
                                <option value="21.75">21:45 PM</option>
                                <option value="21.83">21:50 PM</option>
                                <option value="21.91">21:55 PM</option>
                                <option value="22.00">22:00 PM - o'clock</option>
                                <option value="22.8">22:05 PM</option>
                                <option value="22.16">22:10 PM</option>
                                <option value="22.25">22:15 PM</option>
                                <option value="22.33">22:20 PM</option>
                                <option value="22.41">22:25 PM</option>
                                <option value="22.50">22:30 PM</option>
                                <option value="22.58">22:35 PM</option>
                                <option value="22.66">22:40 PM</option>
                                <option value="22.75">22:45 PM</option>
                                <option value="22.83">22:50 PM</option>
                                <option value="22.91">22:55 PM</option>
                                <option value="23.00">23:00 PM - o'clock</option>
                                <option value="23.8">23:05 PM</option>
                                <option value="23.16">23:10 PM</option>
                                <option value="23.25">23:15 PM</option>
                                <option value="23.33">23:20 PM</option>
                                <option value="23.41">23:25 PM</option>
                                <option value="23.50">23:30 PM</option>
                                <option value="23.58">23:35 PM</option>
                                <option value="23.66">23:40 PM</option>
                                <option value="23.75">23:45 PM</option>
                                <option value="23.83">23:50 PM</option>
                                <option value="23.91">23:55 PM</option>
                                <option value="24.00">24:00 PM - o'clock</option>
                                <option value="24.8">15:05 PM</option>
                                <option value="24.16">24:10 PM</option>
                                <option value="24.25">24:15 PM</option>
                                <option value="24.33">24:20 PM</option>
                                <option value="24.41">24:25 PM</option>
                                <option value="24.50">24:30 PM</option>
                                <option value="24.58">24:35 PM</option>
                                <option value="24.66">24:40 PM</option>
                                <option value="24.75">24:45 PM</option>
                                <option value="24.83">24:50 PM</option>
                                <option value="24.91">24:55 PM</option>
                            </select>
                            {data25.map((datas, index) =>{
                              return<div key={index}>
                                        <p>{datas.endhour}</p>
                                    </div>
                                })}
                        </div>
                        <div className="col-md-2">
                            <select name="type25" onChange={e =>  settype25(e.target.value)} className="form-control" required>
                                <option value="">Select Type</option>
                                <option value="Normal">Normal</option>
                                <option value="SJUK">SJUK</option>
                                <option value="Holiday">Holiday</option>
                            </select>
                            {data25.map((datas, index) =>{
                              return<div key={index}>
                                        <p>{datas.type}</p>
                                    </div>
                                })}
                          </div>
                          <div className="col-md-2"> 
                              <button type='submit' className="btn btn-primary">Submit</button>
                        </div>
                      </form>
                  </div>
                  <div className="d-flex flex-column p-2">
                     <form action="" className="row"  onSubmit={handleSubmit26}>
                        <div className="col-md-1"> 26 </div>
                        <div className="col-md-1"> Saturday </div>
                        <div className="col-md-1" > 
                            {data26.map((types, index) =>{
                              return<tr key={index}>
                                   <p style={types.type === "Normal" ? {backgroundColor:'rgba(7, 190, 7, 0.648)', color:'#000', padding:'5px 10px'} : 
                                     (types.type === "Holiday" ? {backgroundColor:'rgba(255, 0, 0, 0.438)', color:'#000', padding:'5px 10px'} : 
                                      {backgroundColor:'#008cff5a', color:'#000', padding:'5px 10px'}) }> {types.type}</p>
                                    </tr>
                                })}
                        </div>
                        <div className="col-md-2">
                            <select name="starthour26" onChange={e =>  setStart26(e.target.value)} className="form-control" >
                                <option value="">Start Time</option>
                                <option value="05.00">05:00 AM</option>
                                <option value="06.00">06:00 AM</option>
                                <option value="06.8">06:05 AM</option>
                                <option value="06.16">06:10 AM</option>
                                <option value="06.25">06:15 AM</option>
                                <option value="06.33">06:20 AM</option>
                                <option value="06.41">06:25 AM</option>
                                <option value="06.50">06:30 AM</option>
                                <option value="06.58">06:35 AM</option>
                                <option value="06.66">06:40 AM</option>
                                <option value="06.75">06:45 AM</option>
                                <option value="06.83">06:50 AM</option>
                                <option value="06.91">06:55 AM</option>
                                <option value="07.00">07:00 AM - Normal</option>
                                <option value="07.8">07:05 AM</option>
                                <option value="07.16">07:10 AM</option>
                                <option value="07.25">07:15 AM</option>
                                <option value="07.33">07:20 AM</option>
                                <option value="07.41">07:25 AM</option>
                                <option value="07.50">07:30 AM</option>
                                <option value="07.58">07:35 AM</option>
                                <option value="07.66">07:40 AM</option>
                                <option value="07.75">07:45 AM</option>
                                <option value="07.83">07:50 AM</option>
                                <option value="07.91">07:55 AM</option>
                                <option value="08.00">08:00 AM - o'clock</option>
                                <option value="08.8">08:05 AM</option>
                                <option value="08.16">08:10 AM</option>
                                <option value="08.25">08:15 AM</option>
                                <option value="08.33">08:20 AM</option>
                                <option value="08.41">08:25 AM</option>
                                <option value="08.50">08:30 AM</option>
                                <option value="08.58">08:35 AM</option>
                                <option value="08.66">08:40 AM</option>
                                <option value="08.75">08:45 AM</option>
                                <option value="08.83">08:50 AM</option>
                                <option value="08.91">08:55 AM</option>
                                <option value="09.00">09:00 AM - o'clock</option>
                                <option value="09.8">09:05 AM</option>
                                <option value="09.16">09:10 AM</option>
                                <option value="09.25">09:15 AM</option>
                                <option value="09.33">09:20 AM</option>
                                <option value="09.41">09:25 AM</option>
                                <option value="09.50">09:30 AM</option>
                                <option value="09.58">09:35 AM</option>
                                <option value="09.66">09:40 AM</option>
                                <option value="09.75">09:45 AM</option>
                                <option value="09.83">09:50 AM</option>
                                <option value="09.91">09:55 AM</option>
                                <option value="10.00">10:00 AM - o'clock</option>
                                <option value="10.8">10:05 AM</option>
                                <option value="10.16">10:10 AM</option>
                                <option value="10.25">10:15 AM</option>
                                <option value="10.33">10:20 AM</option>
                                <option value="10.41">10:25 AM</option>
                                <option value="10.50">10:30 AM</option>
                                <option value="10.58">10:35 AM</option>
                                <option value="10.66">10:40 AM</option>
                                <option value="10.75">10:45 AM</option>
                                <option value="10.83">10:50 AM</option>
                                <option value="10.91">10:55 AM</option>
                                <option value="11.00">11:00 AM - o'clock</option>
                                <option value="11.8">11:05 AM</option>
                                <option value="11.16">11:10 AM</option>
                                <option value="11.25">11:15 AM</option>
                                <option value="11.33">11:20 AM</option>
                                <option value="11.41">11:25 AM</option>
                                <option value="11.50">11:30 AM</option>
                                <option value="11.58">11:35 AM</option>
                                <option value="11.66">11:40 AM</option>
                                <option value="11.75">11:45 AM</option>
                                <option value="11.83">11:50 AM</option>
                                <option value="11.91">11:55 AM</option>
                                <option value="12.00">12:00 PM  - o'clock</option>
                                <option value="12.8">12:05 PM</option>
                                <option value="12.16">12:10 PM</option>
                                <option value="12.25">12:15 PM</option>
                                <option value="12.33">12:20 PM</option>
                                <option value="12.41">12:25 PM</option>
                                <option value="12.50">12:30 PM</option>
                                <option value="12.58">12:35 PM</option>
                                <option value="12.66">12:40 PM</option>
                                <option value="12.75">12:45 PM</option>
                                <option value="12.83">12:50 PM</option>
                                <option value="12.91">12:55 PM</option>
                                <option value="13.00">13:00 PM - o'clock</option>
                                <option value="13.8">13:05 PM</option>
                                <option value="13.16">13:10 PM</option>
                                <option value="13.25">13:15 PM</option>
                                <option value="13.33">13:20 PM</option>
                                <option value="13.41">13:25 PM</option>
                                <option value="13.50">13:30 PM</option>
                                <option value="13.58">13:35 PM</option>
                                <option value="13.66">13:40 PM</option>
                                <option value="13.75">13:45 PM</option>
                                <option value="13.83">13:50 PM</option>
                                <option value="13.91">13:55 PM</option>
                                <option value="14.00">14:00 PM - o'clock</option>
                                <option value="14.8">14:05 PM</option>
                                <option value="14.16">14:10 PM</option>
                                <option value="14.25">14:15 PM</option>
                                <option value="14.33">14:20 PM</option>
                                <option value="14.41">14:25 PM</option>
                                <option value="14.50">14:30 PM</option>
                                <option value="14.58">14:35 PM</option>
                                <option value="14.66">14:40 PM</option>
                                <option value="14.75">14:45 PM</option>
                                <option value="14.83">14:50 PM</option>
                                <option value="14.91">14:55 PM</option>
                                <option value="15.00">15:00 PM - o'clock</option>
                                <option value="15.8">15:05 PM</option>
                                <option value="15.16">15:10 PM</option>
                                <option value="15.25">15:15 PM</option>
                                <option value="15.33">15:20 PM</option>
                                <option value="15.41">15:25 PM</option>
                                <option value="15.50">15:30 PM</option>
                                <option value="15.58">15:35 PM</option>
                                <option value="15.66">15:40 PM</option>
                                <option value="15.75">15:45 PM</option>
                                <option value="15.83">15:50 PM</option>
                                <option value="15.91">15:55 PM</option>
                                <option value="16.00">16:00 PM - o'clock</option>
                                <option value="16.8">16:05 PM</option>
                                <option value="16.16">16:10 PM</option>
                                <option value="16.25">16:15 PM</option>
                                <option value="16.33">16:20 PM</option>
                                <option value="16.41">16:25 PM</option>
                                <option value="16.50">16:30 PM</option>
                                <option value="16.58">16:35 PM</option>
                                <option value="16.66">16:40 PM</option>
                                <option value="16.75">16:45 PM</option>
                                <option value="16.83">16:50 PM</option>
                                <option value="16.91">16:55 PM</option>
                                <option value="17.00">17:00 PM - o'clock</option>
                                <option value="17.8">17:05 PM</option>
                                <option value="17.16">17:10 PM</option>
                                <option value="17.25">17:15 PM</option>
                                <option value="17.33">17:20 PM</option>
                                <option value="17.41">17:25 PM</option>
                                <option value="17.50">17:30 PM</option>
                                <option value="17.58">17:35 PM</option>
                                <option value="17.66">17:40 PM</option>
                                <option value="17.75">17:45 PM</option>
                                <option value="17.83">17:50 PM</option>
                                <option value="17.91">17:55 PM</option>
                                <option value="18.00">18:00 PM - Normal</option>
                                <option value="18.8">18:05 PM</option>
                                <option value="18.16">18:10 PM</option>
                                <option value="18.25">18:15 PM</option>
                                <option value="18.33">18:20 PM</option>
                                <option value="18.41">18:25 PM</option>
                                <option value="18.50">18:30 PM</option>
                                <option value="18.58">18:35 PM</option>
                                <option value="18.66">18:40 PM</option>
                                <option value="18.75">18:45 PM</option>
                                <option value="18.83">18:50 PM</option>
                                <option value="18.91">18:55 PM</option>
                                <option value="19.00">19:00 PM - o'clock</option>
                                <option value="19.8">19:05 PM</option>
                                <option value="19.16">19:10 PM</option>
                                <option value="19.25">19:15 PM</option>
                                <option value="19.33">19:20 PM</option>
                                <option value="19.41">19:25 PM</option>
                                <option value="19.50">19:30 PM</option>
                                <option value="19.58">19:35 PM</option>
                                <option value="19.66">19:40 PM</option>
                                <option value="19.75">19:45 PM</option>
                                <option value="19.83">19:50 PM</option>
                                <option value="19.91">19:55 PM</option>
                                <option value="20.00">20:00 PM - o'clock</option>
                                <option value="20.8">20:05 PM</option>
                                <option value="20.16">20:10 PM</option>
                                <option value="20.25">20:15 PM</option>
                                <option value="20.33">20:20 PM</option>
                                <option value="20.41">20:25 PM</option>
                                <option value="20.50">20:30 PM</option>
                                <option value="20.58">20:35 PM</option>
                                <option value="20.66">20:40 PM</option>
                                <option value="20.75">20:45 PM</option>
                                <option value="20.83">20:50 PM</option>
                                <option value="20.91">20:55 PM</option>
                                <option value="21.00">21:00 PM - o'clock</option>
                                <option value="21.8">21:05 PM</option>
                                <option value="21.16">21:10 PM</option>
                                <option value="21.25">21:15 PM</option>
                                <option value="21.33">21:20 PM</option>
                                <option value="21.41">21:25 PM</option>
                                <option value="21.50">21:30 PM</option>
                                <option value="21.58">21:35 PM</option>
                                <option value="21.66">21:40 PM</option>
                                <option value="21.75">21:45 PM</option>
                                <option value="21.83">21:50 PM</option>
                                <option value="21.91">21:55 PM</option>
                                <option value="22.00">22:00 PM - o'clock</option>
                                <option value="22.8">22:05 PM</option>
                                <option value="22.16">22:10 PM</option>
                                <option value="22.25">22:15 PM</option>
                                <option value="22.33">22:20 PM</option>
                                <option value="22.41">22:25 PM</option>
                                <option value="22.50">22:30 PM</option>
                                <option value="22.58">22:35 PM</option>
                                <option value="22.66">22:40 PM</option>
                                <option value="22.75">22:45 PM</option>
                                <option value="22.83">22:50 PM</option>
                                <option value="22.91">22:55 PM</option>
                                <option value="23.00">23:00 PM - o'clock</option>
                                <option value="23.8">23:05 PM</option>
                                <option value="23.16">23:10 PM</option>
                                <option value="23.25">23:15 PM</option>
                                <option value="23.33">23:20 PM</option>
                                <option value="23.41">23:25 PM</option>
                                <option value="23.50">23:30 PM</option>
                                <option value="23.58">23:35 PM</option>
                                <option value="23.66">23:40 PM</option>
                                <option value="23.75">23:45 PM</option>
                                <option value="23.83">23:50 PM</option>
                                <option value="23.91">23:55 PM</option>
                                <option value="24.00">24:00 PM - o'clock</option>
                                <option value="24.8">15:05 PM</option>
                                <option value="24.16">24:10 PM</option>
                                <option value="24.25">24:15 PM</option>
                                <option value="24.33">24:20 PM</option>
                                <option value="24.41">24:25 PM</option>
                                <option value="24.50">24:30 PM</option>
                                <option value="24.58">24:35 PM</option>
                                <option value="24.66">24:40 PM</option>
                                <option value="24.75">24:45 PM</option>
                                <option value="24.83">24:50 PM</option>
                                <option value="24.91">24:55 PM</option>
                            </select>
                            {data26.map((datas, index) =>{
                              return<div key={index}>
                                        <p>{datas.starthour}</p>
                                    </div>
                                })}
                        </div>
                        <div className="col-md-2">
                            <select name="endhour26" onChange={e =>  setEnd26(e.target.value)} className="form-control">
                                <option value="">Finish Time</option>
                                <option value="05.00">05:00 AM</option>
                                <option value="06.00">06:00 AM</option>
                                <option value="06.8">06:05 AM</option>
                                <option value="06.16">06:10 AM</option>
                                <option value="06.25">06:15 AM</option>
                                <option value="06.33">06:20 AM</option>
                                <option value="06.41">06:25 AM</option>
                                <option value="06.50">06:30 AM</option>
                                <option value="06.58">06:35 AM</option>
                                <option value="06.66">06:40 AM</option>
                                <option value="06.75">06:45 AM</option>
                                <option value="06.83">06:50 AM</option>
                                <option value="06.91">06:55 AM</option>
                                <option value="07.00">07:00 AM - Normal</option>
                                <option value="07.8">07:05 AM</option>
                                <option value="07.16">07:10 AM</option>
                                <option value="07.25">07:15 AM</option>
                                <option value="07.33">07:20 AM</option>
                                <option value="07.41">07:25 AM</option>
                                <option value="07.50">07:30 AM</option>
                                <option value="07.58">07:35 AM</option>
                                <option value="07.66">07:40 AM</option>
                                <option value="07.75">07:45 AM</option>
                                <option value="07.83">07:50 AM</option>
                                <option value="07.91">07:55 AM</option>
                                <option value="08.00">08:00 AM - o'clock</option>
                                <option value="08.8">08:05 AM</option>
                                <option value="08.16">08:10 AM</option>
                                <option value="08.25">08:15 AM</option>
                                <option value="08.33">08:20 AM</option>
                                <option value="08.41">08:25 AM</option>
                                <option value="08.50">08:30 AM</option>
                                <option value="08.58">08:35 AM</option>
                                <option value="08.66">08:40 AM</option>
                                <option value="08.75">08:45 AM</option>
                                <option value="08.83">08:50 AM</option>
                                <option value="08.91">08:55 AM</option>
                                <option value="09.00">09:00 AM - o'clock</option>
                                <option value="09.8">09:05 AM</option>
                                <option value="09.16">09:10 AM</option>
                                <option value="09.25">09:15 AM</option>
                                <option value="09.33">09:20 AM</option>
                                <option value="09.41">09:25 AM</option>
                                <option value="09.50">09:30 AM</option>
                                <option value="09.58">09:35 AM</option>
                                <option value="09.66">09:40 AM</option>
                                <option value="09.75">09:45 AM</option>
                                <option value="09.83">09:50 AM</option>
                                <option value="09.91">09:55 AM</option>
                                <option value="10.00">10:00 AM - o'clock</option>
                                <option value="10.8">10:05 AM</option>
                                <option value="10.16">10:10 AM</option>
                                <option value="10.25">10:15 AM</option>
                                <option value="10.33">10:20 AM</option>
                                <option value="10.41">10:25 AM</option>
                                <option value="10.50">10:30 AM</option>
                                <option value="10.58">10:35 AM</option>
                                <option value="10.66">10:40 AM</option>
                                <option value="10.75">10:45 AM</option>
                                <option value="10.83">10:50 AM</option>
                                <option value="10.91">10:55 AM</option>
                                <option value="11.00">11:00 AM - o'clock</option>
                                <option value="11.8">11:05 AM</option>
                                <option value="11.16">11:10 AM</option>
                                <option value="11.25">11:15 AM</option>
                                <option value="11.33">11:20 AM</option>
                                <option value="11.41">11:25 AM</option>
                                <option value="11.50">11:30 AM</option>
                                <option value="11.58">11:35 AM</option>
                                <option value="11.66">11:40 AM</option>
                                <option value="11.75">11:45 AM</option>
                                <option value="11.83">11:50 AM</option>
                                <option value="11.91">11:55 AM</option>
                                <option value="12.00">12:00 PM  - o'clock</option>
                                <option value="12.8">12:05 PM</option>
                                <option value="12.16">12:10 PM</option>
                                <option value="12.25">12:15 PM</option>
                                <option value="12.33">12:20 PM</option>
                                <option value="12.41">12:25 PM</option>
                                <option value="12.50">12:30 PM</option>
                                <option value="12.58">12:35 PM</option>
                                <option value="12.66">12:40 PM</option>
                                <option value="12.75">12:45 PM</option>
                                <option value="12.83">12:50 PM</option>
                                <option value="12.91">12:55 PM</option>
                                <option value="13.00">13:00 PM - o'clock</option>
                                <option value="13.8">13:05 PM</option>
                                <option value="13.16">13:10 PM</option>
                                <option value="13.25">13:15 PM</option>
                                <option value="13.33">13:20 PM</option>
                                <option value="13.41">13:25 PM</option>
                                <option value="13.50">13:30 PM</option>
                                <option value="13.58">13:35 PM</option>
                                <option value="13.66">13:40 PM</option>
                                <option value="13.75">13:45 PM</option>
                                <option value="13.83">13:50 PM</option>
                                <option value="13.91">13:55 PM</option>
                                <option value="14.00">14:00 PM - o'clock</option>
                                <option value="14.8">14:05 PM</option>
                                <option value="14.16">14:10 PM</option>
                                <option value="14.25">14:15 PM</option>
                                <option value="14.33">14:20 PM</option>
                                <option value="14.41">14:25 PM</option>
                                <option value="14.50">14:30 PM</option>
                                <option value="14.58">14:35 PM</option>
                                <option value="14.66">14:40 PM</option>
                                <option value="14.75">14:45 PM</option>
                                <option value="14.83">14:50 PM</option>
                                <option value="14.91">14:55 PM</option>
                                <option value="15.00">15:00 PM - o'clock</option>
                                <option value="15.8">15:05 PM</option>
                                <option value="15.16">15:10 PM</option>
                                <option value="15.25">15:15 PM</option>
                                <option value="15.33">15:20 PM</option>
                                <option value="15.41">15:25 PM</option>
                                <option value="15.50">15:30 PM</option>
                                <option value="15.58">15:35 PM</option>
                                <option value="15.66">15:40 PM</option>
                                <option value="15.75">15:45 PM</option>
                                <option value="15.83">15:50 PM</option>
                                <option value="15.91">15:55 PM</option>
                                <option value="16.00">16:00 PM - o'clock</option>
                                <option value="16.8">16:05 PM</option>
                                <option value="16.16">16:10 PM</option>
                                <option value="16.25">16:15 PM</option>
                                <option value="16.33">16:20 PM</option>
                                <option value="16.41">16:25 PM</option>
                                <option value="16.50">16:30 PM</option>
                                <option value="16.58">16:35 PM</option>
                                <option value="16.66">16:40 PM</option>
                                <option value="16.75">16:45 PM</option>
                                <option value="16.83">16:50 PM</option>
                                <option value="16.91">16:55 PM</option>
                                <option value="17.00">17:00 PM - o'clock</option>
                                <option value="17.8">17:05 PM</option>
                                <option value="17.16">17:10 PM</option>
                                <option value="17.25">17:15 PM</option>
                                <option value="17.33">17:20 PM</option>
                                <option value="17.41">17:25 PM</option>
                                <option value="17.50">17:30 PM</option>
                                <option value="17.58">17:35 PM</option>
                                <option value="17.66">17:40 PM</option>
                                <option value="17.75">17:45 PM</option>
                                <option value="17.83">17:50 PM</option>
                                <option value="17.91">17:55 PM</option>
                                <option value="18.00">18:00 PM - Normal</option>
                                <option value="18.8">18:05 PM</option>
                                <option value="18.16">18:10 PM</option>
                                <option value="18.25">18:15 PM</option>
                                <option value="18.33">18:20 PM</option>
                                <option value="18.41">18:25 PM</option>
                                <option value="18.50">18:30 PM</option>
                                <option value="18.58">18:35 PM</option>
                                <option value="18.66">18:40 PM</option>
                                <option value="18.75">18:45 PM</option>
                                <option value="18.83">18:50 PM</option>
                                <option value="18.91">18:55 PM</option>
                                <option value="19.00">19:00 PM - o'clock</option>
                                <option value="19.8">19:05 PM</option>
                                <option value="19.16">19:10 PM</option>
                                <option value="19.25">19:15 PM</option>
                                <option value="19.33">19:20 PM</option>
                                <option value="19.41">19:25 PM</option>
                                <option value="19.50">19:30 PM</option>
                                <option value="19.58">19:35 PM</option>
                                <option value="19.66">19:40 PM</option>
                                <option value="19.75">19:45 PM</option>
                                <option value="19.83">19:50 PM</option>
                                <option value="19.91">19:55 PM</option>
                                <option value="20.00">20:00 PM - o'clock</option>
                                <option value="20.8">20:05 PM</option>
                                <option value="20.16">20:10 PM</option>
                                <option value="20.25">20:15 PM</option>
                                <option value="20.33">20:20 PM</option>
                                <option value="20.41">20:25 PM</option>
                                <option value="20.50">20:30 PM</option>
                                <option value="20.58">20:35 PM</option>
                                <option value="20.66">20:40 PM</option>
                                <option value="20.75">20:45 PM</option>
                                <option value="20.83">20:50 PM</option>
                                <option value="20.91">20:55 PM</option>
                                <option value="21.00">21:00 PM - o'clock</option>
                                <option value="21.8">21:05 PM</option>
                                <option value="21.16">21:10 PM</option>
                                <option value="21.25">21:15 PM</option>
                                <option value="21.33">21:20 PM</option>
                                <option value="21.41">21:25 PM</option>
                                <option value="21.50">21:30 PM</option>
                                <option value="21.58">21:35 PM</option>
                                <option value="21.66">21:40 PM</option>
                                <option value="21.75">21:45 PM</option>
                                <option value="21.83">21:50 PM</option>
                                <option value="21.91">21:55 PM</option>
                                <option value="22.00">22:00 PM - o'clock</option>
                                <option value="22.8">22:05 PM</option>
                                <option value="22.16">22:10 PM</option>
                                <option value="22.25">22:15 PM</option>
                                <option value="22.33">22:20 PM</option>
                                <option value="22.41">22:25 PM</option>
                                <option value="22.50">22:30 PM</option>
                                <option value="22.58">22:35 PM</option>
                                <option value="22.66">22:40 PM</option>
                                <option value="22.75">22:45 PM</option>
                                <option value="22.83">22:50 PM</option>
                                <option value="22.91">22:55 PM</option>
                                <option value="23.00">23:00 PM - o'clock</option>
                                <option value="23.8">23:05 PM</option>
                                <option value="23.16">23:10 PM</option>
                                <option value="23.25">23:15 PM</option>
                                <option value="23.33">23:20 PM</option>
                                <option value="23.41">23:25 PM</option>
                                <option value="23.50">23:30 PM</option>
                                <option value="23.58">23:35 PM</option>
                                <option value="23.66">23:40 PM</option>
                                <option value="23.75">23:45 PM</option>
                                <option value="23.83">23:50 PM</option>
                                <option value="23.91">23:55 PM</option>
                                <option value="24.00">24:00 PM - o'clock</option>
                                <option value="24.8">15:05 PM</option>
                                <option value="24.16">24:10 PM</option>
                                <option value="24.25">24:15 PM</option>
                                <option value="24.33">24:20 PM</option>
                                <option value="24.41">24:25 PM</option>
                                <option value="24.50">24:30 PM</option>
                                <option value="24.58">24:35 PM</option>
                                <option value="24.66">24:40 PM</option>
                                <option value="24.75">24:45 PM</option>
                                <option value="24.83">24:50 PM</option>
                                <option value="24.91">24:55 PM</option>
                            </select>
                            {data26.map((datas, index) =>{
                              return<div key={index}>
                                        <p>{datas.endhour}</p>
                                    </div>
                                })}
                        </div>
                        <div className="col-md-2">
                            <select name="type26" onChange={e =>  settype26(e.target.value)} className="form-control" required>
                                <option value="">Select Type</option>
                                <option value="Normal">Normal</option>
                                <option value="SJUK">SJUK</option>
                                <option value="Holiday">Holiday</option>
                            </select>
                            {data26.map((datas, index) =>{
                              return<div key={index}>
                                        <p>{datas.type}</p>
                                    </div>
                                })}
                          </div>
                          <div className="col-md-2"> 
                              <button type='submit' className="btn btn-primary">Submit</button>
                        </div>
                      </form>
                  </div>
                  <div className="d-flex flex-column p-2">
                     <form action="" className="row"  onSubmit={handleSubmit27}>
                        <div className="col-md-1"> 27 </div>
                        <div className="col-md-1"> Sunday </div>
                        <div className="col-md-1" > 
                            {data27.map((types, index) =>{
                              return<tr key={index}>
                                   <p style={types.type === "Normal" ? {backgroundColor:'rgba(7, 190, 7, 0.648)', color:'#000', padding:'5px 10px'} : 
                                     (types.type === "Holiday" ? {backgroundColor:'rgba(255, 0, 0, 0.438)', color:'#000', padding:'5px 10px'} : 
                                      {backgroundColor:'#008cff5a', color:'#000', padding:'5px 10px'}) }> {types.type}</p>
                                    </tr>
                                })}
                        </div>
                        <div className="col-md-2">
                            <select name="starthour27" onChange={e =>  setStart27(e.target.value)} className="form-control">
                                <option value="">Start Time</option>
                                <option value="05.00">05:00 AM</option>
                                <option value="06.00">06:00 AM</option>
                                <option value="06.8">06:05 AM</option>
                                <option value="06.16">06:10 AM</option>
                                <option value="06.25">06:15 AM</option>
                                <option value="06.33">06:20 AM</option>
                                <option value="06.41">06:25 AM</option>
                                <option value="06.50">06:30 AM</option>
                                <option value="06.58">06:35 AM</option>
                                <option value="06.66">06:40 AM</option>
                                <option value="06.75">06:45 AM</option>
                                <option value="06.83">06:50 AM</option>
                                <option value="06.91">06:55 AM</option>
                                <option value="07.00">07:00 AM - Normal</option>
                                <option value="07.8">07:05 AM</option>
                                <option value="07.16">07:10 AM</option>
                                <option value="07.25">07:15 AM</option>
                                <option value="07.33">07:20 AM</option>
                                <option value="07.41">07:25 AM</option>
                                <option value="07.50">07:30 AM</option>
                                <option value="07.58">07:35 AM</option>
                                <option value="07.66">07:40 AM</option>
                                <option value="07.75">07:45 AM</option>
                                <option value="07.83">07:50 AM</option>
                                <option value="07.91">07:55 AM</option>
                                <option value="08.00">08:00 AM - o'clock</option>
                                <option value="08.8">08:05 AM</option>
                                <option value="08.16">08:10 AM</option>
                                <option value="08.25">08:15 AM</option>
                                <option value="08.33">08:20 AM</option>
                                <option value="08.41">08:25 AM</option>
                                <option value="08.50">08:30 AM</option>
                                <option value="08.58">08:35 AM</option>
                                <option value="08.66">08:40 AM</option>
                                <option value="08.75">08:45 AM</option>
                                <option value="08.83">08:50 AM</option>
                                <option value="08.91">08:55 AM</option>
                                <option value="09.00">09:00 AM - o'clock</option>
                                <option value="09.8">09:05 AM</option>
                                <option value="09.16">09:10 AM</option>
                                <option value="09.25">09:15 AM</option>
                                <option value="09.33">09:20 AM</option>
                                <option value="09.41">09:25 AM</option>
                                <option value="09.50">09:30 AM</option>
                                <option value="09.58">09:35 AM</option>
                                <option value="09.66">09:40 AM</option>
                                <option value="09.75">09:45 AM</option>
                                <option value="09.83">09:50 AM</option>
                                <option value="09.91">09:55 AM</option>
                                <option value="10.00">10:00 AM - o'clock</option>
                                <option value="10.8">10:05 AM</option>
                                <option value="10.16">10:10 AM</option>
                                <option value="10.25">10:15 AM</option>
                                <option value="10.33">10:20 AM</option>
                                <option value="10.41">10:25 AM</option>
                                <option value="10.50">10:30 AM</option>
                                <option value="10.58">10:35 AM</option>
                                <option value="10.66">10:40 AM</option>
                                <option value="10.75">10:45 AM</option>
                                <option value="10.83">10:50 AM</option>
                                <option value="10.91">10:55 AM</option>
                                <option value="11.00">11:00 AM - o'clock</option>
                                <option value="11.8">11:05 AM</option>
                                <option value="11.16">11:10 AM</option>
                                <option value="11.25">11:15 AM</option>
                                <option value="11.33">11:20 AM</option>
                                <option value="11.41">11:25 AM</option>
                                <option value="11.50">11:30 AM</option>
                                <option value="11.58">11:35 AM</option>
                                <option value="11.66">11:40 AM</option>
                                <option value="11.75">11:45 AM</option>
                                <option value="11.83">11:50 AM</option>
                                <option value="11.91">11:55 AM</option>
                                <option value="12.00">12:00 PM  - o'clock</option>
                                <option value="12.8">12:05 PM</option>
                                <option value="12.16">12:10 PM</option>
                                <option value="12.25">12:15 PM</option>
                                <option value="12.33">12:20 PM</option>
                                <option value="12.41">12:25 PM</option>
                                <option value="12.50">12:30 PM</option>
                                <option value="12.58">12:35 PM</option>
                                <option value="12.66">12:40 PM</option>
                                <option value="12.75">12:45 PM</option>
                                <option value="12.83">12:50 PM</option>
                                <option value="12.91">12:55 PM</option>
                                <option value="13.00">13:00 PM - o'clock</option>
                                <option value="13.8">13:05 PM</option>
                                <option value="13.16">13:10 PM</option>
                                <option value="13.25">13:15 PM</option>
                                <option value="13.33">13:20 PM</option>
                                <option value="13.41">13:25 PM</option>
                                <option value="13.50">13:30 PM</option>
                                <option value="13.58">13:35 PM</option>
                                <option value="13.66">13:40 PM</option>
                                <option value="13.75">13:45 PM</option>
                                <option value="13.83">13:50 PM</option>
                                <option value="13.91">13:55 PM</option>
                                <option value="14.00">14:00 PM - o'clock</option>
                                <option value="14.8">14:05 PM</option>
                                <option value="14.16">14:10 PM</option>
                                <option value="14.25">14:15 PM</option>
                                <option value="14.33">14:20 PM</option>
                                <option value="14.41">14:25 PM</option>
                                <option value="14.50">14:30 PM</option>
                                <option value="14.58">14:35 PM</option>
                                <option value="14.66">14:40 PM</option>
                                <option value="14.75">14:45 PM</option>
                                <option value="14.83">14:50 PM</option>
                                <option value="14.91">14:55 PM</option>
                                <option value="15.00">15:00 PM - o'clock</option>
                                <option value="15.8">15:05 PM</option>
                                <option value="15.16">15:10 PM</option>
                                <option value="15.25">15:15 PM</option>
                                <option value="15.33">15:20 PM</option>
                                <option value="15.41">15:25 PM</option>
                                <option value="15.50">15:30 PM</option>
                                <option value="15.58">15:35 PM</option>
                                <option value="15.66">15:40 PM</option>
                                <option value="15.75">15:45 PM</option>
                                <option value="15.83">15:50 PM</option>
                                <option value="15.91">15:55 PM</option>
                                <option value="16.00">16:00 PM - o'clock</option>
                                <option value="16.8">16:05 PM</option>
                                <option value="16.16">16:10 PM</option>
                                <option value="16.25">16:15 PM</option>
                                <option value="16.33">16:20 PM</option>
                                <option value="16.41">16:25 PM</option>
                                <option value="16.50">16:30 PM</option>
                                <option value="16.58">16:35 PM</option>
                                <option value="16.66">16:40 PM</option>
                                <option value="16.75">16:45 PM</option>
                                <option value="16.83">16:50 PM</option>
                                <option value="16.91">16:55 PM</option>
                                <option value="17.00">17:00 PM - o'clock</option>
                                <option value="17.8">17:05 PM</option>
                                <option value="17.16">17:10 PM</option>
                                <option value="17.25">17:15 PM</option>
                                <option value="17.33">17:20 PM</option>
                                <option value="17.41">17:25 PM</option>
                                <option value="17.50">17:30 PM</option>
                                <option value="17.58">17:35 PM</option>
                                <option value="17.66">17:40 PM</option>
                                <option value="17.75">17:45 PM</option>
                                <option value="17.83">17:50 PM</option>
                                <option value="17.91">17:55 PM</option>
                                <option value="18.00">18:00 PM - Normal</option>
                                <option value="18.8">18:05 PM</option>
                                <option value="18.16">18:10 PM</option>
                                <option value="18.25">18:15 PM</option>
                                <option value="18.33">18:20 PM</option>
                                <option value="18.41">18:25 PM</option>
                                <option value="18.50">18:30 PM</option>
                                <option value="18.58">18:35 PM</option>
                                <option value="18.66">18:40 PM</option>
                                <option value="18.75">18:45 PM</option>
                                <option value="18.83">18:50 PM</option>
                                <option value="18.91">18:55 PM</option>
                                <option value="19.00">19:00 PM - o'clock</option>
                                <option value="19.8">19:05 PM</option>
                                <option value="19.16">19:10 PM</option>
                                <option value="19.25">19:15 PM</option>
                                <option value="19.33">19:20 PM</option>
                                <option value="19.41">19:25 PM</option>
                                <option value="19.50">19:30 PM</option>
                                <option value="19.58">19:35 PM</option>
                                <option value="19.66">19:40 PM</option>
                                <option value="19.75">19:45 PM</option>
                                <option value="19.83">19:50 PM</option>
                                <option value="19.91">19:55 PM</option>
                                <option value="20.00">20:00 PM - o'clock</option>
                                <option value="20.8">20:05 PM</option>
                                <option value="20.16">20:10 PM</option>
                                <option value="20.25">20:15 PM</option>
                                <option value="20.33">20:20 PM</option>
                                <option value="20.41">20:25 PM</option>
                                <option value="20.50">20:30 PM</option>
                                <option value="20.58">20:35 PM</option>
                                <option value="20.66">20:40 PM</option>
                                <option value="20.75">20:45 PM</option>
                                <option value="20.83">20:50 PM</option>
                                <option value="20.91">20:55 PM</option>
                                <option value="21.00">21:00 PM - o'clock</option>
                                <option value="21.8">21:05 PM</option>
                                <option value="21.16">21:10 PM</option>
                                <option value="21.25">21:15 PM</option>
                                <option value="21.33">21:20 PM</option>
                                <option value="21.41">21:25 PM</option>
                                <option value="21.50">21:30 PM</option>
                                <option value="21.58">21:35 PM</option>
                                <option value="21.66">21:40 PM</option>
                                <option value="21.75">21:45 PM</option>
                                <option value="21.83">21:50 PM</option>
                                <option value="21.91">21:55 PM</option>
                                <option value="22.00">22:00 PM - o'clock</option>
                                <option value="22.8">22:05 PM</option>
                                <option value="22.16">22:10 PM</option>
                                <option value="22.25">22:15 PM</option>
                                <option value="22.33">22:20 PM</option>
                                <option value="22.41">22:25 PM</option>
                                <option value="22.50">22:30 PM</option>
                                <option value="22.58">22:35 PM</option>
                                <option value="22.66">22:40 PM</option>
                                <option value="22.75">22:45 PM</option>
                                <option value="22.83">22:50 PM</option>
                                <option value="22.91">22:55 PM</option>
                                <option value="23.00">23:00 PM - o'clock</option>
                                <option value="23.8">23:05 PM</option>
                                <option value="23.16">23:10 PM</option>
                                <option value="23.25">23:15 PM</option>
                                <option value="23.33">23:20 PM</option>
                                <option value="23.41">23:25 PM</option>
                                <option value="23.50">23:30 PM</option>
                                <option value="23.58">23:35 PM</option>
                                <option value="23.66">23:40 PM</option>
                                <option value="23.75">23:45 PM</option>
                                <option value="23.83">23:50 PM</option>
                                <option value="23.91">23:55 PM</option>
                                <option value="24.00">24:00 PM - o'clock</option>
                                <option value="24.8">15:05 PM</option>
                                <option value="24.16">24:10 PM</option>
                                <option value="24.25">24:15 PM</option>
                                <option value="24.33">24:20 PM</option>
                                <option value="24.41">24:25 PM</option>
                                <option value="24.50">24:30 PM</option>
                                <option value="24.58">24:35 PM</option>
                                <option value="24.66">24:40 PM</option>
                                <option value="24.75">24:45 PM</option>
                                <option value="24.83">24:50 PM</option>
                                <option value="24.91">24:55 PM</option>
                            </select>
                            {data27.map((datas, index) =>{
                              return<div key={index}>
                                        <p>{datas.starthour}</p>
                                    </div>
                                })}
                        </div>
                        <div className="col-md-2">
                            <select name="endhour27" onChange={e =>  setEnd27(e.target.value)} className="form-control">
                                <option value="">Finish Time</option>
                                <option value="05.00">05:00 AM</option>
                                <option value="06.00">06:00 AM</option>
                                <option value="06.8">06:05 AM</option>
                                <option value="06.16">06:10 AM</option>
                                <option value="06.25">06:15 AM</option>
                                <option value="06.33">06:20 AM</option>
                                <option value="06.41">06:25 AM</option>
                                <option value="06.50">06:30 AM</option>
                                <option value="06.58">06:35 AM</option>
                                <option value="06.66">06:40 AM</option>
                                <option value="06.75">06:45 AM</option>
                                <option value="06.83">06:50 AM</option>
                                <option value="06.91">06:55 AM</option>
                                <option value="07.00">07:00 AM - Normal</option>
                                <option value="07.8">07:05 AM</option>
                                <option value="07.16">07:10 AM</option>
                                <option value="07.25">07:15 AM</option>
                                <option value="07.33">07:20 AM</option>
                                <option value="07.41">07:25 AM</option>
                                <option value="07.50">07:30 AM</option>
                                <option value="07.58">07:35 AM</option>
                                <option value="07.66">07:40 AM</option>
                                <option value="07.75">07:45 AM</option>
                                <option value="07.83">07:50 AM</option>
                                <option value="07.91">07:55 AM</option>
                                <option value="08.00">08:00 AM - o'clock</option>
                                <option value="08.8">08:05 AM</option>
                                <option value="08.16">08:10 AM</option>
                                <option value="08.25">08:15 AM</option>
                                <option value="08.33">08:20 AM</option>
                                <option value="08.41">08:25 AM</option>
                                <option value="08.50">08:30 AM</option>
                                <option value="08.58">08:35 AM</option>
                                <option value="08.66">08:40 AM</option>
                                <option value="08.75">08:45 AM</option>
                                <option value="08.83">08:50 AM</option>
                                <option value="08.91">08:55 AM</option>
                                <option value="09.00">09:00 AM - o'clock</option>
                                <option value="09.8">09:05 AM</option>
                                <option value="09.16">09:10 AM</option>
                                <option value="09.25">09:15 AM</option>
                                <option value="09.33">09:20 AM</option>
                                <option value="09.41">09:25 AM</option>
                                <option value="09.50">09:30 AM</option>
                                <option value="09.58">09:35 AM</option>
                                <option value="09.66">09:40 AM</option>
                                <option value="09.75">09:45 AM</option>
                                <option value="09.83">09:50 AM</option>
                                <option value="09.91">09:55 AM</option>
                                <option value="10.00">10:00 AM - o'clock</option>
                                <option value="10.8">10:05 AM</option>
                                <option value="10.16">10:10 AM</option>
                                <option value="10.25">10:15 AM</option>
                                <option value="10.33">10:20 AM</option>
                                <option value="10.41">10:25 AM</option>
                                <option value="10.50">10:30 AM</option>
                                <option value="10.58">10:35 AM</option>
                                <option value="10.66">10:40 AM</option>
                                <option value="10.75">10:45 AM</option>
                                <option value="10.83">10:50 AM</option>
                                <option value="10.91">10:55 AM</option>
                                <option value="11.00">11:00 AM - o'clock</option>
                                <option value="11.8">11:05 AM</option>
                                <option value="11.16">11:10 AM</option>
                                <option value="11.25">11:15 AM</option>
                                <option value="11.33">11:20 AM</option>
                                <option value="11.41">11:25 AM</option>
                                <option value="11.50">11:30 AM</option>
                                <option value="11.58">11:35 AM</option>
                                <option value="11.66">11:40 AM</option>
                                <option value="11.75">11:45 AM</option>
                                <option value="11.83">11:50 AM</option>
                                <option value="11.91">11:55 AM</option>
                                <option value="12.00">12:00 PM  - o'clock</option>
                                <option value="12.8">12:05 PM</option>
                                <option value="12.16">12:10 PM</option>
                                <option value="12.25">12:15 PM</option>
                                <option value="12.33">12:20 PM</option>
                                <option value="12.41">12:25 PM</option>
                                <option value="12.50">12:30 PM</option>
                                <option value="12.58">12:35 PM</option>
                                <option value="12.66">12:40 PM</option>
                                <option value="12.75">12:45 PM</option>
                                <option value="12.83">12:50 PM</option>
                                <option value="12.91">12:55 PM</option>
                                <option value="13.00">13:00 PM - o'clock</option>
                                <option value="13.8">13:05 PM</option>
                                <option value="13.16">13:10 PM</option>
                                <option value="13.25">13:15 PM</option>
                                <option value="13.33">13:20 PM</option>
                                <option value="13.41">13:25 PM</option>
                                <option value="13.50">13:30 PM</option>
                                <option value="13.58">13:35 PM</option>
                                <option value="13.66">13:40 PM</option>
                                <option value="13.75">13:45 PM</option>
                                <option value="13.83">13:50 PM</option>
                                <option value="13.91">13:55 PM</option>
                                <option value="14.00">14:00 PM - o'clock</option>
                                <option value="14.8">14:05 PM</option>
                                <option value="14.16">14:10 PM</option>
                                <option value="14.25">14:15 PM</option>
                                <option value="14.33">14:20 PM</option>
                                <option value="14.41">14:25 PM</option>
                                <option value="14.50">14:30 PM</option>
                                <option value="14.58">14:35 PM</option>
                                <option value="14.66">14:40 PM</option>
                                <option value="14.75">14:45 PM</option>
                                <option value="14.83">14:50 PM</option>
                                <option value="14.91">14:55 PM</option>
                                <option value="15.00">15:00 PM - o'clock</option>
                                <option value="15.8">15:05 PM</option>
                                <option value="15.16">15:10 PM</option>
                                <option value="15.25">15:15 PM</option>
                                <option value="15.33">15:20 PM</option>
                                <option value="15.41">15:25 PM</option>
                                <option value="15.50">15:30 PM</option>
                                <option value="15.58">15:35 PM</option>
                                <option value="15.66">15:40 PM</option>
                                <option value="15.75">15:45 PM</option>
                                <option value="15.83">15:50 PM</option>
                                <option value="15.91">15:55 PM</option>
                                <option value="16.00">16:00 PM - o'clock</option>
                                <option value="16.8">16:05 PM</option>
                                <option value="16.16">16:10 PM</option>
                                <option value="16.25">16:15 PM</option>
                                <option value="16.33">16:20 PM</option>
                                <option value="16.41">16:25 PM</option>
                                <option value="16.50">16:30 PM</option>
                                <option value="16.58">16:35 PM</option>
                                <option value="16.66">16:40 PM</option>
                                <option value="16.75">16:45 PM</option>
                                <option value="16.83">16:50 PM</option>
                                <option value="16.91">16:55 PM</option>
                                <option value="17.00">17:00 PM - o'clock</option>
                                <option value="17.8">17:05 PM</option>
                                <option value="17.16">17:10 PM</option>
                                <option value="17.25">17:15 PM</option>
                                <option value="17.33">17:20 PM</option>
                                <option value="17.41">17:25 PM</option>
                                <option value="17.50">17:30 PM</option>
                                <option value="17.58">17:35 PM</option>
                                <option value="17.66">17:40 PM</option>
                                <option value="17.75">17:45 PM</option>
                                <option value="17.83">17:50 PM</option>
                                <option value="17.91">17:55 PM</option>
                                <option value="18.00">18:00 PM - Normal</option>
                                <option value="18.8">18:05 PM</option>
                                <option value="18.16">18:10 PM</option>
                                <option value="18.25">18:15 PM</option>
                                <option value="18.33">18:20 PM</option>
                                <option value="18.41">18:25 PM</option>
                                <option value="18.50">18:30 PM</option>
                                <option value="18.58">18:35 PM</option>
                                <option value="18.66">18:40 PM</option>
                                <option value="18.75">18:45 PM</option>
                                <option value="18.83">18:50 PM</option>
                                <option value="18.91">18:55 PM</option>
                                <option value="19.00">19:00 PM - o'clock</option>
                                <option value="19.8">19:05 PM</option>
                                <option value="19.16">19:10 PM</option>
                                <option value="19.25">19:15 PM</option>
                                <option value="19.33">19:20 PM</option>
                                <option value="19.41">19:25 PM</option>
                                <option value="19.50">19:30 PM</option>
                                <option value="19.58">19:35 PM</option>
                                <option value="19.66">19:40 PM</option>
                                <option value="19.75">19:45 PM</option>
                                <option value="19.83">19:50 PM</option>
                                <option value="19.91">19:55 PM</option>
                                <option value="20.00">20:00 PM - o'clock</option>
                                <option value="20.8">20:05 PM</option>
                                <option value="20.16">20:10 PM</option>
                                <option value="20.25">20:15 PM</option>
                                <option value="20.33">20:20 PM</option>
                                <option value="20.41">20:25 PM</option>
                                <option value="20.50">20:30 PM</option>
                                <option value="20.58">20:35 PM</option>
                                <option value="20.66">20:40 PM</option>
                                <option value="20.75">20:45 PM</option>
                                <option value="20.83">20:50 PM</option>
                                <option value="20.91">20:55 PM</option>
                                <option value="21.00">21:00 PM - o'clock</option>
                                <option value="21.8">21:05 PM</option>
                                <option value="21.16">21:10 PM</option>
                                <option value="21.25">21:15 PM</option>
                                <option value="21.33">21:20 PM</option>
                                <option value="21.41">21:25 PM</option>
                                <option value="21.50">21:30 PM</option>
                                <option value="21.58">21:35 PM</option>
                                <option value="21.66">21:40 PM</option>
                                <option value="21.75">21:45 PM</option>
                                <option value="21.83">21:50 PM</option>
                                <option value="21.91">21:55 PM</option>
                                <option value="22.00">22:00 PM - o'clock</option>
                                <option value="22.8">22:05 PM</option>
                                <option value="22.16">22:10 PM</option>
                                <option value="22.25">22:15 PM</option>
                                <option value="22.33">22:20 PM</option>
                                <option value="22.41">22:25 PM</option>
                                <option value="22.50">22:30 PM</option>
                                <option value="22.58">22:35 PM</option>
                                <option value="22.66">22:40 PM</option>
                                <option value="22.75">22:45 PM</option>
                                <option value="22.83">22:50 PM</option>
                                <option value="22.91">22:55 PM</option>
                                <option value="23.00">23:00 PM - o'clock</option>
                                <option value="23.8">23:05 PM</option>
                                <option value="23.16">23:10 PM</option>
                                <option value="23.25">23:15 PM</option>
                                <option value="23.33">23:20 PM</option>
                                <option value="23.41">23:25 PM</option>
                                <option value="23.50">23:30 PM</option>
                                <option value="23.58">23:35 PM</option>
                                <option value="23.66">23:40 PM</option>
                                <option value="23.75">23:45 PM</option>
                                <option value="23.83">23:50 PM</option>
                                <option value="23.91">23:55 PM</option>
                                <option value="24.00">24:00 PM - o'clock</option>
                                <option value="24.8">15:05 PM</option>
                                <option value="24.16">24:10 PM</option>
                                <option value="24.25">24:15 PM</option>
                                <option value="24.33">24:20 PM</option>
                                <option value="24.41">24:25 PM</option>
                                <option value="24.50">24:30 PM</option>
                                <option value="24.58">24:35 PM</option>
                                <option value="24.66">24:40 PM</option>
                                <option value="24.75">24:45 PM</option>
                                <option value="24.83">24:50 PM</option>
                                <option value="24.91">24:55 PM</option>
                            </select>
                            {data27.map((datas, index) =>{
                              return<div key={index}>
                                        <p>{datas.endhour}</p>
                                    </div>
                                })}
                        </div>
                        <div className="col-md-2">
                            <select name="type27" onChange={e =>  settype27(e.target.value)} className="form-control" required>
                                <option value="">Select Type</option>
                                <option value="Normal">Normal</option>
                                <option value="SJUK">SJUK</option>
                                <option value="Holiday">Holiday</option>
                            </select>
                            {data27.map((datas, index) =>{
                              return<div key={index}>
                                        <p>{datas.type}</p>
                                    </div>
                                })}
                          </div>
                          <div className="col-md-2"> 
                              <button type='submit' className="btn btn-primary">Submit</button>
                        </div>
                      </form>
                  </div>
                  <div className="d-flex flex-column p-2">
                     <form action="" className="row"  onSubmit={handleSubmit28}>
                        <div className="col-md-1"> 28 </div>
                        <div className="col-md-1"> Monday </div>
                        <div className="col-md-1" > 
                            {data28.map((types, index) =>{
                              return<tr key={index}>
                                   <p style={types.type === "Normal" ? {backgroundColor:'rgba(7, 190, 7, 0.648)', color:'#000', padding:'5px 10px'} : 
                                     (types.type === "Holiday" ? {backgroundColor:'rgba(255, 0, 0, 0.438)', color:'#000', padding:'5px 10px'} : 
                                      {backgroundColor:'#008cff5a', color:'#000', padding:'5px 10px'}) }> {types.type}</p>
                                    </tr>
                                })}
                        </div>
                        <div className="col-md-2">
                            <select name="starthour28" onChange={e =>  setStart28(e.target.value)} className="form-control">
                                <option value="">Start Time</option>
                                <option value="05.00">05:00 AM</option>
                                <option value="06.00">06:00 AM</option>
                                <option value="06.8">06:05 AM</option>
                                <option value="06.16">06:10 AM</option>
                                <option value="06.25">06:15 AM</option>
                                <option value="06.33">06:20 AM</option>
                                <option value="06.41">06:25 AM</option>
                                <option value="06.50">06:30 AM</option>
                                <option value="06.58">06:35 AM</option>
                                <option value="06.66">06:40 AM</option>
                                <option value="06.75">06:45 AM</option>
                                <option value="06.83">06:50 AM</option>
                                <option value="06.91">06:55 AM</option>
                                <option value="07.00">07:00 AM - Normal</option>
                                <option value="07.8">07:05 AM</option>
                                <option value="07.16">07:10 AM</option>
                                <option value="07.25">07:15 AM</option>
                                <option value="07.33">07:20 AM</option>
                                <option value="07.41">07:25 AM</option>
                                <option value="07.50">07:30 AM</option>
                                <option value="07.58">07:35 AM</option>
                                <option value="07.66">07:40 AM</option>
                                <option value="07.75">07:45 AM</option>
                                <option value="07.83">07:50 AM</option>
                                <option value="07.91">07:55 AM</option>
                                <option value="08.00">08:00 AM - o'clock</option>
                                <option value="08.8">08:05 AM</option>
                                <option value="08.16">08:10 AM</option>
                                <option value="08.25">08:15 AM</option>
                                <option value="08.33">08:20 AM</option>
                                <option value="08.41">08:25 AM</option>
                                <option value="08.50">08:30 AM</option>
                                <option value="08.58">08:35 AM</option>
                                <option value="08.66">08:40 AM</option>
                                <option value="08.75">08:45 AM</option>
                                <option value="08.83">08:50 AM</option>
                                <option value="08.91">08:55 AM</option>
                                <option value="09.00">09:00 AM - o'clock</option>
                                <option value="09.8">09:05 AM</option>
                                <option value="09.16">09:10 AM</option>
                                <option value="09.25">09:15 AM</option>
                                <option value="09.33">09:20 AM</option>
                                <option value="09.41">09:25 AM</option>
                                <option value="09.50">09:30 AM</option>
                                <option value="09.58">09:35 AM</option>
                                <option value="09.66">09:40 AM</option>
                                <option value="09.75">09:45 AM</option>
                                <option value="09.83">09:50 AM</option>
                                <option value="09.91">09:55 AM</option>
                                <option value="10.00">10:00 AM - o'clock</option>
                                <option value="10.8">10:05 AM</option>
                                <option value="10.16">10:10 AM</option>
                                <option value="10.25">10:15 AM</option>
                                <option value="10.33">10:20 AM</option>
                                <option value="10.41">10:25 AM</option>
                                <option value="10.50">10:30 AM</option>
                                <option value="10.58">10:35 AM</option>
                                <option value="10.66">10:40 AM</option>
                                <option value="10.75">10:45 AM</option>
                                <option value="10.83">10:50 AM</option>
                                <option value="10.91">10:55 AM</option>
                                <option value="11.00">11:00 AM - o'clock</option>
                                <option value="11.8">11:05 AM</option>
                                <option value="11.16">11:10 AM</option>
                                <option value="11.25">11:15 AM</option>
                                <option value="11.33">11:20 AM</option>
                                <option value="11.41">11:25 AM</option>
                                <option value="11.50">11:30 AM</option>
                                <option value="11.58">11:35 AM</option>
                                <option value="11.66">11:40 AM</option>
                                <option value="11.75">11:45 AM</option>
                                <option value="11.83">11:50 AM</option>
                                <option value="11.91">11:55 AM</option>
                                <option value="12.00">12:00 PM  - o'clock</option>
                                <option value="12.8">12:05 PM</option>
                                <option value="12.16">12:10 PM</option>
                                <option value="12.25">12:15 PM</option>
                                <option value="12.33">12:20 PM</option>
                                <option value="12.41">12:25 PM</option>
                                <option value="12.50">12:30 PM</option>
                                <option value="12.58">12:35 PM</option>
                                <option value="12.66">12:40 PM</option>
                                <option value="12.75">12:45 PM</option>
                                <option value="12.83">12:50 PM</option>
                                <option value="12.91">12:55 PM</option>
                                <option value="13.00">13:00 PM - o'clock</option>
                                <option value="13.8">13:05 PM</option>
                                <option value="13.16">13:10 PM</option>
                                <option value="13.25">13:15 PM</option>
                                <option value="13.33">13:20 PM</option>
                                <option value="13.41">13:25 PM</option>
                                <option value="13.50">13:30 PM</option>
                                <option value="13.58">13:35 PM</option>
                                <option value="13.66">13:40 PM</option>
                                <option value="13.75">13:45 PM</option>
                                <option value="13.83">13:50 PM</option>
                                <option value="13.91">13:55 PM</option>
                                <option value="14.00">14:00 PM - o'clock</option>
                                <option value="14.8">14:05 PM</option>
                                <option value="14.16">14:10 PM</option>
                                <option value="14.25">14:15 PM</option>
                                <option value="14.33">14:20 PM</option>
                                <option value="14.41">14:25 PM</option>
                                <option value="14.50">14:30 PM</option>
                                <option value="14.58">14:35 PM</option>
                                <option value="14.66">14:40 PM</option>
                                <option value="14.75">14:45 PM</option>
                                <option value="14.83">14:50 PM</option>
                                <option value="14.91">14:55 PM</option>
                                <option value="15.00">15:00 PM - o'clock</option>
                                <option value="15.8">15:05 PM</option>
                                <option value="15.16">15:10 PM</option>
                                <option value="15.25">15:15 PM</option>
                                <option value="15.33">15:20 PM</option>
                                <option value="15.41">15:25 PM</option>
                                <option value="15.50">15:30 PM</option>
                                <option value="15.58">15:35 PM</option>
                                <option value="15.66">15:40 PM</option>
                                <option value="15.75">15:45 PM</option>
                                <option value="15.83">15:50 PM</option>
                                <option value="15.91">15:55 PM</option>
                                <option value="16.00">16:00 PM - o'clock</option>
                                <option value="16.8">16:05 PM</option>
                                <option value="16.16">16:10 PM</option>
                                <option value="16.25">16:15 PM</option>
                                <option value="16.33">16:20 PM</option>
                                <option value="16.41">16:25 PM</option>
                                <option value="16.50">16:30 PM</option>
                                <option value="16.58">16:35 PM</option>
                                <option value="16.66">16:40 PM</option>
                                <option value="16.75">16:45 PM</option>
                                <option value="16.83">16:50 PM</option>
                                <option value="16.91">16:55 PM</option>
                                <option value="17.00">17:00 PM - o'clock</option>
                                <option value="17.8">17:05 PM</option>
                                <option value="17.16">17:10 PM</option>
                                <option value="17.25">17:15 PM</option>
                                <option value="17.33">17:20 PM</option>
                                <option value="17.41">17:25 PM</option>
                                <option value="17.50">17:30 PM</option>
                                <option value="17.58">17:35 PM</option>
                                <option value="17.66">17:40 PM</option>
                                <option value="17.75">17:45 PM</option>
                                <option value="17.83">17:50 PM</option>
                                <option value="17.91">17:55 PM</option>
                                <option value="18.00">18:00 PM - Normal</option>
                                <option value="18.8">18:05 PM</option>
                                <option value="18.16">18:10 PM</option>
                                <option value="18.25">18:15 PM</option>
                                <option value="18.33">18:20 PM</option>
                                <option value="18.41">18:25 PM</option>
                                <option value="18.50">18:30 PM</option>
                                <option value="18.58">18:35 PM</option>
                                <option value="18.66">18:40 PM</option>
                                <option value="18.75">18:45 PM</option>
                                <option value="18.83">18:50 PM</option>
                                <option value="18.91">18:55 PM</option>
                                <option value="19.00">19:00 PM - o'clock</option>
                                <option value="19.8">19:05 PM</option>
                                <option value="19.16">19:10 PM</option>
                                <option value="19.25">19:15 PM</option>
                                <option value="19.33">19:20 PM</option>
                                <option value="19.41">19:25 PM</option>
                                <option value="19.50">19:30 PM</option>
                                <option value="19.58">19:35 PM</option>
                                <option value="19.66">19:40 PM</option>
                                <option value="19.75">19:45 PM</option>
                                <option value="19.83">19:50 PM</option>
                                <option value="19.91">19:55 PM</option>
                                <option value="20.00">20:00 PM - o'clock</option>
                                <option value="20.8">20:05 PM</option>
                                <option value="20.16">20:10 PM</option>
                                <option value="20.25">20:15 PM</option>
                                <option value="20.33">20:20 PM</option>
                                <option value="20.41">20:25 PM</option>
                                <option value="20.50">20:30 PM</option>
                                <option value="20.58">20:35 PM</option>
                                <option value="20.66">20:40 PM</option>
                                <option value="20.75">20:45 PM</option>
                                <option value="20.83">20:50 PM</option>
                                <option value="20.91">20:55 PM</option>
                                <option value="21.00">21:00 PM - o'clock</option>
                                <option value="21.8">21:05 PM</option>
                                <option value="21.16">21:10 PM</option>
                                <option value="21.25">21:15 PM</option>
                                <option value="21.33">21:20 PM</option>
                                <option value="21.41">21:25 PM</option>
                                <option value="21.50">21:30 PM</option>
                                <option value="21.58">21:35 PM</option>
                                <option value="21.66">21:40 PM</option>
                                <option value="21.75">21:45 PM</option>
                                <option value="21.83">21:50 PM</option>
                                <option value="21.91">21:55 PM</option>
                                <option value="22.00">22:00 PM - o'clock</option>
                                <option value="22.8">22:05 PM</option>
                                <option value="22.16">22:10 PM</option>
                                <option value="22.25">22:15 PM</option>
                                <option value="22.33">22:20 PM</option>
                                <option value="22.41">22:25 PM</option>
                                <option value="22.50">22:30 PM</option>
                                <option value="22.58">22:35 PM</option>
                                <option value="22.66">22:40 PM</option>
                                <option value="22.75">22:45 PM</option>
                                <option value="22.83">22:50 PM</option>
                                <option value="22.91">22:55 PM</option>
                                <option value="23.00">23:00 PM - o'clock</option>
                                <option value="23.8">23:05 PM</option>
                                <option value="23.16">23:10 PM</option>
                                <option value="23.25">23:15 PM</option>
                                <option value="23.33">23:20 PM</option>
                                <option value="23.41">23:25 PM</option>
                                <option value="23.50">23:30 PM</option>
                                <option value="23.58">23:35 PM</option>
                                <option value="23.66">23:40 PM</option>
                                <option value="23.75">23:45 PM</option>
                                <option value="23.83">23:50 PM</option>
                                <option value="23.91">23:55 PM</option>
                                <option value="24.00">24:00 PM - o'clock</option>
                                <option value="24.8">15:05 PM</option>
                                <option value="24.16">24:10 PM</option>
                                <option value="24.25">24:15 PM</option>
                                <option value="24.33">24:20 PM</option>
                                <option value="24.41">24:25 PM</option>
                                <option value="24.50">24:30 PM</option>
                                <option value="24.58">24:35 PM</option>
                                <option value="24.66">24:40 PM</option>
                                <option value="24.75">24:45 PM</option>
                                <option value="24.83">24:50 PM</option>
                                <option value="24.91">24:55 PM</option>
                            </select>
                            {data28.map((datas, index) =>{
                              return<div key={index}>
                                        <p>{datas.starthour}</p>
                                    </div>
                                })}
                        </div>
                        <div className="col-md-2">
                            <select name="endhour28" onChange={e =>  setEnd28(e.target.value)} className="form-control">
                                <option value="">Finish Time</option>
                                <option value="05.00">05:00 AM</option>
                                <option value="06.00">06:00 AM</option>
                                <option value="06.8">06:05 AM</option>
                                <option value="06.16">06:10 AM</option>
                                <option value="06.25">06:15 AM</option>
                                <option value="06.33">06:20 AM</option>
                                <option value="06.41">06:25 AM</option>
                                <option value="06.50">06:30 AM</option>
                                <option value="06.58">06:35 AM</option>
                                <option value="06.66">06:40 AM</option>
                                <option value="06.75">06:45 AM</option>
                                <option value="06.83">06:50 AM</option>
                                <option value="06.91">06:55 AM</option>
                                <option value="07.00">07:00 AM - Normal</option>
                                <option value="07.8">07:05 AM</option>
                                <option value="07.16">07:10 AM</option>
                                <option value="07.25">07:15 AM</option>
                                <option value="07.33">07:20 AM</option>
                                <option value="07.41">07:25 AM</option>
                                <option value="07.50">07:30 AM</option>
                                <option value="07.58">07:35 AM</option>
                                <option value="07.66">07:40 AM</option>
                                <option value="07.75">07:45 AM</option>
                                <option value="07.83">07:50 AM</option>
                                <option value="07.91">07:55 AM</option>
                                <option value="08.00">08:00 AM - o'clock</option>
                                <option value="08.8">08:05 AM</option>
                                <option value="08.16">08:10 AM</option>
                                <option value="08.25">08:15 AM</option>
                                <option value="08.33">08:20 AM</option>
                                <option value="08.41">08:25 AM</option>
                                <option value="08.50">08:30 AM</option>
                                <option value="08.58">08:35 AM</option>
                                <option value="08.66">08:40 AM</option>
                                <option value="08.75">08:45 AM</option>
                                <option value="08.83">08:50 AM</option>
                                <option value="08.91">08:55 AM</option>
                                <option value="09.00">09:00 AM - o'clock</option>
                                <option value="09.8">09:05 AM</option>
                                <option value="09.16">09:10 AM</option>
                                <option value="09.25">09:15 AM</option>
                                <option value="09.33">09:20 AM</option>
                                <option value="09.41">09:25 AM</option>
                                <option value="09.50">09:30 AM</option>
                                <option value="09.58">09:35 AM</option>
                                <option value="09.66">09:40 AM</option>
                                <option value="09.75">09:45 AM</option>
                                <option value="09.83">09:50 AM</option>
                                <option value="09.91">09:55 AM</option>
                                <option value="10.00">10:00 AM - o'clock</option>
                                <option value="10.8">10:05 AM</option>
                                <option value="10.16">10:10 AM</option>
                                <option value="10.25">10:15 AM</option>
                                <option value="10.33">10:20 AM</option>
                                <option value="10.41">10:25 AM</option>
                                <option value="10.50">10:30 AM</option>
                                <option value="10.58">10:35 AM</option>
                                <option value="10.66">10:40 AM</option>
                                <option value="10.75">10:45 AM</option>
                                <option value="10.83">10:50 AM</option>
                                <option value="10.91">10:55 AM</option>
                                <option value="11.00">11:00 AM - o'clock</option>
                                <option value="11.8">11:05 AM</option>
                                <option value="11.16">11:10 AM</option>
                                <option value="11.25">11:15 AM</option>
                                <option value="11.33">11:20 AM</option>
                                <option value="11.41">11:25 AM</option>
                                <option value="11.50">11:30 AM</option>
                                <option value="11.58">11:35 AM</option>
                                <option value="11.66">11:40 AM</option>
                                <option value="11.75">11:45 AM</option>
                                <option value="11.83">11:50 AM</option>
                                <option value="11.91">11:55 AM</option>
                                <option value="12.00">12:00 PM  - o'clock</option>
                                <option value="12.8">12:05 PM</option>
                                <option value="12.16">12:10 PM</option>
                                <option value="12.25">12:15 PM</option>
                                <option value="12.33">12:20 PM</option>
                                <option value="12.41">12:25 PM</option>
                                <option value="12.50">12:30 PM</option>
                                <option value="12.58">12:35 PM</option>
                                <option value="12.66">12:40 PM</option>
                                <option value="12.75">12:45 PM</option>
                                <option value="12.83">12:50 PM</option>
                                <option value="12.91">12:55 PM</option>
                                <option value="13.00">13:00 PM - o'clock</option>
                                <option value="13.8">13:05 PM</option>
                                <option value="13.16">13:10 PM</option>
                                <option value="13.25">13:15 PM</option>
                                <option value="13.33">13:20 PM</option>
                                <option value="13.41">13:25 PM</option>
                                <option value="13.50">13:30 PM</option>
                                <option value="13.58">13:35 PM</option>
                                <option value="13.66">13:40 PM</option>
                                <option value="13.75">13:45 PM</option>
                                <option value="13.83">13:50 PM</option>
                                <option value="13.91">13:55 PM</option>
                                <option value="14.00">14:00 PM - o'clock</option>
                                <option value="14.8">14:05 PM</option>
                                <option value="14.16">14:10 PM</option>
                                <option value="14.25">14:15 PM</option>
                                <option value="14.33">14:20 PM</option>
                                <option value="14.41">14:25 PM</option>
                                <option value="14.50">14:30 PM</option>
                                <option value="14.58">14:35 PM</option>
                                <option value="14.66">14:40 PM</option>
                                <option value="14.75">14:45 PM</option>
                                <option value="14.83">14:50 PM</option>
                                <option value="14.91">14:55 PM</option>
                                <option value="15.00">15:00 PM - o'clock</option>
                                <option value="15.8">15:05 PM</option>
                                <option value="15.16">15:10 PM</option>
                                <option value="15.25">15:15 PM</option>
                                <option value="15.33">15:20 PM</option>
                                <option value="15.41">15:25 PM</option>
                                <option value="15.50">15:30 PM</option>
                                <option value="15.58">15:35 PM</option>
                                <option value="15.66">15:40 PM</option>
                                <option value="15.75">15:45 PM</option>
                                <option value="15.83">15:50 PM</option>
                                <option value="15.91">15:55 PM</option>
                                <option value="16.00">16:00 PM - o'clock</option>
                                <option value="16.8">16:05 PM</option>
                                <option value="16.16">16:10 PM</option>
                                <option value="16.25">16:15 PM</option>
                                <option value="16.33">16:20 PM</option>
                                <option value="16.41">16:25 PM</option>
                                <option value="16.50">16:30 PM</option>
                                <option value="16.58">16:35 PM</option>
                                <option value="16.66">16:40 PM</option>
                                <option value="16.75">16:45 PM</option>
                                <option value="16.83">16:50 PM</option>
                                <option value="16.91">16:55 PM</option>
                                <option value="17.00">17:00 PM - o'clock</option>
                                <option value="17.8">17:05 PM</option>
                                <option value="17.16">17:10 PM</option>
                                <option value="17.25">17:15 PM</option>
                                <option value="17.33">17:20 PM</option>
                                <option value="17.41">17:25 PM</option>
                                <option value="17.50">17:30 PM</option>
                                <option value="17.58">17:35 PM</option>
                                <option value="17.66">17:40 PM</option>
                                <option value="17.75">17:45 PM</option>
                                <option value="17.83">17:50 PM</option>
                                <option value="17.91">17:55 PM</option>
                                <option value="18.00">18:00 PM - Normal</option>
                                <option value="18.8">18:05 PM</option>
                                <option value="18.16">18:10 PM</option>
                                <option value="18.25">18:15 PM</option>
                                <option value="18.33">18:20 PM</option>
                                <option value="18.41">18:25 PM</option>
                                <option value="18.50">18:30 PM</option>
                                <option value="18.58">18:35 PM</option>
                                <option value="18.66">18:40 PM</option>
                                <option value="18.75">18:45 PM</option>
                                <option value="18.83">18:50 PM</option>
                                <option value="18.91">18:55 PM</option>
                                <option value="19.00">19:00 PM - o'clock</option>
                                <option value="19.8">19:05 PM</option>
                                <option value="19.16">19:10 PM</option>
                                <option value="19.25">19:15 PM</option>
                                <option value="19.33">19:20 PM</option>
                                <option value="19.41">19:25 PM</option>
                                <option value="19.50">19:30 PM</option>
                                <option value="19.58">19:35 PM</option>
                                <option value="19.66">19:40 PM</option>
                                <option value="19.75">19:45 PM</option>
                                <option value="19.83">19:50 PM</option>
                                <option value="19.91">19:55 PM</option>
                                <option value="20.00">20:00 PM - o'clock</option>
                                <option value="20.8">20:05 PM</option>
                                <option value="20.16">20:10 PM</option>
                                <option value="20.25">20:15 PM</option>
                                <option value="20.33">20:20 PM</option>
                                <option value="20.41">20:25 PM</option>
                                <option value="20.50">20:30 PM</option>
                                <option value="20.58">20:35 PM</option>
                                <option value="20.66">20:40 PM</option>
                                <option value="20.75">20:45 PM</option>
                                <option value="20.83">20:50 PM</option>
                                <option value="20.91">20:55 PM</option>
                                <option value="21.00">21:00 PM - o'clock</option>
                                <option value="21.8">21:05 PM</option>
                                <option value="21.16">21:10 PM</option>
                                <option value="21.25">21:15 PM</option>
                                <option value="21.33">21:20 PM</option>
                                <option value="21.41">21:25 PM</option>
                                <option value="21.50">21:30 PM</option>
                                <option value="21.58">21:35 PM</option>
                                <option value="21.66">21:40 PM</option>
                                <option value="21.75">21:45 PM</option>
                                <option value="21.83">21:50 PM</option>
                                <option value="21.91">21:55 PM</option>
                                <option value="22.00">22:00 PM - o'clock</option>
                                <option value="22.8">22:05 PM</option>
                                <option value="22.16">22:10 PM</option>
                                <option value="22.25">22:15 PM</option>
                                <option value="22.33">22:20 PM</option>
                                <option value="22.41">22:25 PM</option>
                                <option value="22.50">22:30 PM</option>
                                <option value="22.58">22:35 PM</option>
                                <option value="22.66">22:40 PM</option>
                                <option value="22.75">22:45 PM</option>
                                <option value="22.83">22:50 PM</option>
                                <option value="22.91">22:55 PM</option>
                                <option value="23.00">23:00 PM - o'clock</option>
                                <option value="23.8">23:05 PM</option>
                                <option value="23.16">23:10 PM</option>
                                <option value="23.25">23:15 PM</option>
                                <option value="23.33">23:20 PM</option>
                                <option value="23.41">23:25 PM</option>
                                <option value="23.50">23:30 PM</option>
                                <option value="23.58">23:35 PM</option>
                                <option value="23.66">23:40 PM</option>
                                <option value="23.75">23:45 PM</option>
                                <option value="23.83">23:50 PM</option>
                                <option value="23.91">23:55 PM</option>
                                <option value="24.00">24:00 PM - o'clock</option>
                                <option value="24.8">15:05 PM</option>
                                <option value="24.16">24:10 PM</option>
                                <option value="24.25">24:15 PM</option>
                                <option value="24.33">24:20 PM</option>
                                <option value="24.41">24:25 PM</option>
                                <option value="24.50">24:30 PM</option>
                                <option value="24.58">24:35 PM</option>
                                <option value="24.66">24:40 PM</option>
                                <option value="24.75">24:45 PM</option>
                                <option value="24.83">24:50 PM</option>
                                <option value="24.91">24:55 PM</option>
                            </select>
                            {data28.map((datas, index) =>{
                              return<div key={index}>
                                        <p>{datas.endhour}</p>
                                    </div>
                                })}
                        </div>
                        <div className="col-md-2">
                            <select name="type28" onChange={e =>  settype28(e.target.value)} className="form-control" required>
                                <option value="">Select Type</option>
                                <option value="Normal">Normal</option>
                                <option value="SJUK">SJUK</option>
                                <option value="Holiday">Holiday</option>
                            </select>
                          </div>
                          <div className="col-md-2"> 
                              <button type='submit' className="btn btn-primary">Submit</button>
                        </div>
                      </form>
                  </div>
                  <div className="d-flex flex-column p-2">
                     <form action="" className="row"  onSubmit={handleSubmit29}>
                        <div className="col-md-1"> 29 </div>
                        <div className="col-md-1"> Tuesday</div>
                        <div className="col-md-1" > 
                            {data29.map((types, index) =>{
                              return<tr key={index}>
                                   <p style={types.type === "Normal" ? {backgroundColor:'rgba(7, 190, 7, 0.648)', color:'#000', padding:'5px 10px'} : 
                                     (types.type === "Holiday" ? {backgroundColor:'rgba(255, 0, 0, 0.438)', color:'#000', padding:'5px 10px'} : 
                                      {backgroundColor:'#008cff5a', color:'#000', padding:'5px 10px'}) }> {types.type}</p>
                                    </tr>
                                })}
                        </div>
                        <div className="col-md-2">
                            <select name="starthour29" onChange={e =>  setStart29(e.target.value)} className="form-control">
                                <option value="">Start Time</option>
                                <option value="05.00">05:00 AM</option>
                                <option value="06.00">06:00 AM</option>
                                <option value="06.8">06:05 AM</option>
                                <option value="06.16">06:10 AM</option>
                                <option value="06.25">06:15 AM</option>
                                <option value="06.33">06:20 AM</option>
                                <option value="06.41">06:25 AM</option>
                                <option value="06.50">06:30 AM</option>
                                <option value="06.58">06:35 AM</option>
                                <option value="06.66">06:40 AM</option>
                                <option value="06.75">06:45 AM</option>
                                <option value="06.83">06:50 AM</option>
                                <option value="06.91">06:55 AM</option>
                                <option value="07.00">07:00 AM - Normal</option>
                                <option value="07.8">07:05 AM</option>
                                <option value="07.16">07:10 AM</option>
                                <option value="07.25">07:15 AM</option>
                                <option value="07.33">07:20 AM</option>
                                <option value="07.41">07:25 AM</option>
                                <option value="07.50">07:30 AM</option>
                                <option value="07.58">07:35 AM</option>
                                <option value="07.66">07:40 AM</option>
                                <option value="07.75">07:45 AM</option>
                                <option value="07.83">07:50 AM</option>
                                <option value="07.91">07:55 AM</option>
                                <option value="08.00">08:00 AM - o'clock</option>
                                <option value="08.8">08:05 AM</option>
                                <option value="08.16">08:10 AM</option>
                                <option value="08.25">08:15 AM</option>
                                <option value="08.33">08:20 AM</option>
                                <option value="08.41">08:25 AM</option>
                                <option value="08.50">08:30 AM</option>
                                <option value="08.58">08:35 AM</option>
                                <option value="08.66">08:40 AM</option>
                                <option value="08.75">08:45 AM</option>
                                <option value="08.83">08:50 AM</option>
                                <option value="08.91">08:55 AM</option>
                                <option value="09.00">09:00 AM - o'clock</option>
                                <option value="09.8">09:05 AM</option>
                                <option value="09.16">09:10 AM</option>
                                <option value="09.25">09:15 AM</option>
                                <option value="09.33">09:20 AM</option>
                                <option value="09.41">09:25 AM</option>
                                <option value="09.50">09:30 AM</option>
                                <option value="09.58">09:35 AM</option>
                                <option value="09.66">09:40 AM</option>
                                <option value="09.75">09:45 AM</option>
                                <option value="09.83">09:50 AM</option>
                                <option value="09.91">09:55 AM</option>
                                <option value="10.00">10:00 AM - o'clock</option>
                                <option value="10.8">10:05 AM</option>
                                <option value="10.16">10:10 AM</option>
                                <option value="10.25">10:15 AM</option>
                                <option value="10.33">10:20 AM</option>
                                <option value="10.41">10:25 AM</option>
                                <option value="10.50">10:30 AM</option>
                                <option value="10.58">10:35 AM</option>
                                <option value="10.66">10:40 AM</option>
                                <option value="10.75">10:45 AM</option>
                                <option value="10.83">10:50 AM</option>
                                <option value="10.91">10:55 AM</option>
                                <option value="11.00">11:00 AM - o'clock</option>
                                <option value="11.8">11:05 AM</option>
                                <option value="11.16">11:10 AM</option>
                                <option value="11.25">11:15 AM</option>
                                <option value="11.33">11:20 AM</option>
                                <option value="11.41">11:25 AM</option>
                                <option value="11.50">11:30 AM</option>
                                <option value="11.58">11:35 AM</option>
                                <option value="11.66">11:40 AM</option>
                                <option value="11.75">11:45 AM</option>
                                <option value="11.83">11:50 AM</option>
                                <option value="11.91">11:55 AM</option>
                                <option value="12.00">12:00 PM  - o'clock</option>
                                <option value="12.8">12:05 PM</option>
                                <option value="12.16">12:10 PM</option>
                                <option value="12.25">12:15 PM</option>
                                <option value="12.33">12:20 PM</option>
                                <option value="12.41">12:25 PM</option>
                                <option value="12.50">12:30 PM</option>
                                <option value="12.58">12:35 PM</option>
                                <option value="12.66">12:40 PM</option>
                                <option value="12.75">12:45 PM</option>
                                <option value="12.83">12:50 PM</option>
                                <option value="12.91">12:55 PM</option>
                                <option value="13.00">13:00 PM - o'clock</option>
                                <option value="13.8">13:05 PM</option>
                                <option value="13.16">13:10 PM</option>
                                <option value="13.25">13:15 PM</option>
                                <option value="13.33">13:20 PM</option>
                                <option value="13.41">13:25 PM</option>
                                <option value="13.50">13:30 PM</option>
                                <option value="13.58">13:35 PM</option>
                                <option value="13.66">13:40 PM</option>
                                <option value="13.75">13:45 PM</option>
                                <option value="13.83">13:50 PM</option>
                                <option value="13.91">13:55 PM</option>
                                <option value="14.00">14:00 PM - o'clock</option>
                                <option value="14.8">14:05 PM</option>
                                <option value="14.16">14:10 PM</option>
                                <option value="14.25">14:15 PM</option>
                                <option value="14.33">14:20 PM</option>
                                <option value="14.41">14:25 PM</option>
                                <option value="14.50">14:30 PM</option>
                                <option value="14.58">14:35 PM</option>
                                <option value="14.66">14:40 PM</option>
                                <option value="14.75">14:45 PM</option>
                                <option value="14.83">14:50 PM</option>
                                <option value="14.91">14:55 PM</option>
                                <option value="15.00">15:00 PM - o'clock</option>
                                <option value="15.8">15:05 PM</option>
                                <option value="15.16">15:10 PM</option>
                                <option value="15.25">15:15 PM</option>
                                <option value="15.33">15:20 PM</option>
                                <option value="15.41">15:25 PM</option>
                                <option value="15.50">15:30 PM</option>
                                <option value="15.58">15:35 PM</option>
                                <option value="15.66">15:40 PM</option>
                                <option value="15.75">15:45 PM</option>
                                <option value="15.83">15:50 PM</option>
                                <option value="15.91">15:55 PM</option>
                                <option value="16.00">16:00 PM - o'clock</option>
                                <option value="16.8">16:05 PM</option>
                                <option value="16.16">16:10 PM</option>
                                <option value="16.25">16:15 PM</option>
                                <option value="16.33">16:20 PM</option>
                                <option value="16.41">16:25 PM</option>
                                <option value="16.50">16:30 PM</option>
                                <option value="16.58">16:35 PM</option>
                                <option value="16.66">16:40 PM</option>
                                <option value="16.75">16:45 PM</option>
                                <option value="16.83">16:50 PM</option>
                                <option value="16.91">16:55 PM</option>
                                <option value="17.00">17:00 PM - o'clock</option>
                                <option value="17.8">17:05 PM</option>
                                <option value="17.16">17:10 PM</option>
                                <option value="17.25">17:15 PM</option>
                                <option value="17.33">17:20 PM</option>
                                <option value="17.41">17:25 PM</option>
                                <option value="17.50">17:30 PM</option>
                                <option value="17.58">17:35 PM</option>
                                <option value="17.66">17:40 PM</option>
                                <option value="17.75">17:45 PM</option>
                                <option value="17.83">17:50 PM</option>
                                <option value="17.91">17:55 PM</option>
                                <option value="18.00">18:00 PM - Normal</option>
                                <option value="18.8">18:05 PM</option>
                                <option value="18.16">18:10 PM</option>
                                <option value="18.25">18:15 PM</option>
                                <option value="18.33">18:20 PM</option>
                                <option value="18.41">18:25 PM</option>
                                <option value="18.50">18:30 PM</option>
                                <option value="18.58">18:35 PM</option>
                                <option value="18.66">18:40 PM</option>
                                <option value="18.75">18:45 PM</option>
                                <option value="18.83">18:50 PM</option>
                                <option value="18.91">18:55 PM</option>
                                <option value="19.00">19:00 PM - o'clock</option>
                                <option value="19.8">19:05 PM</option>
                                <option value="19.16">19:10 PM</option>
                                <option value="19.25">19:15 PM</option>
                                <option value="19.33">19:20 PM</option>
                                <option value="19.41">19:25 PM</option>
                                <option value="19.50">19:30 PM</option>
                                <option value="19.58">19:35 PM</option>
                                <option value="19.66">19:40 PM</option>
                                <option value="19.75">19:45 PM</option>
                                <option value="19.83">19:50 PM</option>
                                <option value="19.91">19:55 PM</option>
                                <option value="20.00">20:00 PM - o'clock</option>
                                <option value="20.8">20:05 PM</option>
                                <option value="20.16">20:10 PM</option>
                                <option value="20.25">20:15 PM</option>
                                <option value="20.33">20:20 PM</option>
                                <option value="20.41">20:25 PM</option>
                                <option value="20.50">20:30 PM</option>
                                <option value="20.58">20:35 PM</option>
                                <option value="20.66">20:40 PM</option>
                                <option value="20.75">20:45 PM</option>
                                <option value="20.83">20:50 PM</option>
                                <option value="20.91">20:55 PM</option>
                                <option value="21.00">21:00 PM - o'clock</option>
                                <option value="21.8">21:05 PM</option>
                                <option value="21.16">21:10 PM</option>
                                <option value="21.25">21:15 PM</option>
                                <option value="21.33">21:20 PM</option>
                                <option value="21.41">21:25 PM</option>
                                <option value="21.50">21:30 PM</option>
                                <option value="21.58">21:35 PM</option>
                                <option value="21.66">21:40 PM</option>
                                <option value="21.75">21:45 PM</option>
                                <option value="21.83">21:50 PM</option>
                                <option value="21.91">21:55 PM</option>
                                <option value="22.00">22:00 PM - o'clock</option>
                                <option value="22.8">22:05 PM</option>
                                <option value="22.16">22:10 PM</option>
                                <option value="22.25">22:15 PM</option>
                                <option value="22.33">22:20 PM</option>
                                <option value="22.41">22:25 PM</option>
                                <option value="22.50">22:30 PM</option>
                                <option value="22.58">22:35 PM</option>
                                <option value="22.66">22:40 PM</option>
                                <option value="22.75">22:45 PM</option>
                                <option value="22.83">22:50 PM</option>
                                <option value="22.91">22:55 PM</option>
                                <option value="23.00">23:00 PM - o'clock</option>
                                <option value="23.8">23:05 PM</option>
                                <option value="23.16">23:10 PM</option>
                                <option value="23.25">23:15 PM</option>
                                <option value="23.33">23:20 PM</option>
                                <option value="23.41">23:25 PM</option>
                                <option value="23.50">23:30 PM</option>
                                <option value="23.58">23:35 PM</option>
                                <option value="23.66">23:40 PM</option>
                                <option value="23.75">23:45 PM</option>
                                <option value="23.83">23:50 PM</option>
                                <option value="23.91">23:55 PM</option>
                                <option value="24.00">24:00 PM - o'clock</option>
                                <option value="24.8">15:05 PM</option>
                                <option value="24.16">24:10 PM</option>
                                <option value="24.25">24:15 PM</option>
                                <option value="24.33">24:20 PM</option>
                                <option value="24.41">24:25 PM</option>
                                <option value="24.50">24:30 PM</option>
                                <option value="24.58">24:35 PM</option>
                                <option value="24.66">24:40 PM</option>
                                <option value="24.75">24:45 PM</option>
                                <option value="24.83">24:50 PM</option>
                                <option value="24.91">24:55 PM</option>
                            </select>
                            {data29.map((datas, index) =>{
                              return<div key={index}>
                                        <p>{datas.starthour}</p>
                                    </div>
                                })}
                        </div>
                        <div className="col-md-2">
                            <select name="endhour29" onChange={e =>  setEnd29(e.target.value)} className="form-control">
                                <option value="">Finish Time</option>
                                <option value="05.00">05:00 AM</option>
                                <option value="06.00">06:00 AM</option>
                                <option value="06.8">06:05 AM</option>
                                <option value="06.16">06:10 AM</option>
                                <option value="06.25">06:15 AM</option>
                                <option value="06.33">06:20 AM</option>
                                <option value="06.41">06:25 AM</option>
                                <option value="06.50">06:30 AM</option>
                                <option value="06.58">06:35 AM</option>
                                <option value="06.66">06:40 AM</option>
                                <option value="06.75">06:45 AM</option>
                                <option value="06.83">06:50 AM</option>
                                <option value="06.91">06:55 AM</option>
                                <option value="07.00">07:00 AM - Normal</option>
                                <option value="07.8">07:05 AM</option>
                                <option value="07.16">07:10 AM</option>
                                <option value="07.25">07:15 AM</option>
                                <option value="07.33">07:20 AM</option>
                                <option value="07.41">07:25 AM</option>
                                <option value="07.50">07:30 AM</option>
                                <option value="07.58">07:35 AM</option>
                                <option value="07.66">07:40 AM</option>
                                <option value="07.75">07:45 AM</option>
                                <option value="07.83">07:50 AM</option>
                                <option value="07.91">07:55 AM</option>
                                <option value="08.00">08:00 AM - o'clock</option>
                                <option value="08.8">08:05 AM</option>
                                <option value="08.16">08:10 AM</option>
                                <option value="08.25">08:15 AM</option>
                                <option value="08.33">08:20 AM</option>
                                <option value="08.41">08:25 AM</option>
                                <option value="08.50">08:30 AM</option>
                                <option value="08.58">08:35 AM</option>
                                <option value="08.66">08:40 AM</option>
                                <option value="08.75">08:45 AM</option>
                                <option value="08.83">08:50 AM</option>
                                <option value="08.91">08:55 AM</option>
                                <option value="09.00">09:00 AM - o'clock</option>
                                <option value="09.8">09:05 AM</option>
                                <option value="09.16">09:10 AM</option>
                                <option value="09.25">09:15 AM</option>
                                <option value="09.33">09:20 AM</option>
                                <option value="09.41">09:25 AM</option>
                                <option value="09.50">09:30 AM</option>
                                <option value="09.58">09:35 AM</option>
                                <option value="09.66">09:40 AM</option>
                                <option value="09.75">09:45 AM</option>
                                <option value="09.83">09:50 AM</option>
                                <option value="09.91">09:55 AM</option>
                                <option value="10.00">10:00 AM - o'clock</option>
                                <option value="10.8">10:05 AM</option>
                                <option value="10.16">10:10 AM</option>
                                <option value="10.25">10:15 AM</option>
                                <option value="10.33">10:20 AM</option>
                                <option value="10.41">10:25 AM</option>
                                <option value="10.50">10:30 AM</option>
                                <option value="10.58">10:35 AM</option>
                                <option value="10.66">10:40 AM</option>
                                <option value="10.75">10:45 AM</option>
                                <option value="10.83">10:50 AM</option>
                                <option value="10.91">10:55 AM</option>
                                <option value="11.00">11:00 AM - o'clock</option>
                                <option value="11.8">11:05 AM</option>
                                <option value="11.16">11:10 AM</option>
                                <option value="11.25">11:15 AM</option>
                                <option value="11.33">11:20 AM</option>
                                <option value="11.41">11:25 AM</option>
                                <option value="11.50">11:30 AM</option>
                                <option value="11.58">11:35 AM</option>
                                <option value="11.66">11:40 AM</option>
                                <option value="11.75">11:45 AM</option>
                                <option value="11.83">11:50 AM</option>
                                <option value="11.91">11:55 AM</option>
                                <option value="12.00">12:00 PM  - o'clock</option>
                                <option value="12.8">12:05 PM</option>
                                <option value="12.16">12:10 PM</option>
                                <option value="12.25">12:15 PM</option>
                                <option value="12.33">12:20 PM</option>
                                <option value="12.41">12:25 PM</option>
                                <option value="12.50">12:30 PM</option>
                                <option value="12.58">12:35 PM</option>
                                <option value="12.66">12:40 PM</option>
                                <option value="12.75">12:45 PM</option>
                                <option value="12.83">12:50 PM</option>
                                <option value="12.91">12:55 PM</option>
                                <option value="13.00">13:00 PM - o'clock</option>
                                <option value="13.8">13:05 PM</option>
                                <option value="13.16">13:10 PM</option>
                                <option value="13.25">13:15 PM</option>
                                <option value="13.33">13:20 PM</option>
                                <option value="13.41">13:25 PM</option>
                                <option value="13.50">13:30 PM</option>
                                <option value="13.58">13:35 PM</option>
                                <option value="13.66">13:40 PM</option>
                                <option value="13.75">13:45 PM</option>
                                <option value="13.83">13:50 PM</option>
                                <option value="13.91">13:55 PM</option>
                                <option value="14.00">14:00 PM - o'clock</option>
                                <option value="14.8">14:05 PM</option>
                                <option value="14.16">14:10 PM</option>
                                <option value="14.25">14:15 PM</option>
                                <option value="14.33">14:20 PM</option>
                                <option value="14.41">14:25 PM</option>
                                <option value="14.50">14:30 PM</option>
                                <option value="14.58">14:35 PM</option>
                                <option value="14.66">14:40 PM</option>
                                <option value="14.75">14:45 PM</option>
                                <option value="14.83">14:50 PM</option>
                                <option value="14.91">14:55 PM</option>
                                <option value="15.00">15:00 PM - o'clock</option>
                                <option value="15.8">15:05 PM</option>
                                <option value="15.16">15:10 PM</option>
                                <option value="15.25">15:15 PM</option>
                                <option value="15.33">15:20 PM</option>
                                <option value="15.41">15:25 PM</option>
                                <option value="15.50">15:30 PM</option>
                                <option value="15.58">15:35 PM</option>
                                <option value="15.66">15:40 PM</option>
                                <option value="15.75">15:45 PM</option>
                                <option value="15.83">15:50 PM</option>
                                <option value="15.91">15:55 PM</option>
                                <option value="16.00">16:00 PM - o'clock</option>
                                <option value="16.8">16:05 PM</option>
                                <option value="16.16">16:10 PM</option>
                                <option value="16.25">16:15 PM</option>
                                <option value="16.33">16:20 PM</option>
                                <option value="16.41">16:25 PM</option>
                                <option value="16.50">16:30 PM</option>
                                <option value="16.58">16:35 PM</option>
                                <option value="16.66">16:40 PM</option>
                                <option value="16.75">16:45 PM</option>
                                <option value="16.83">16:50 PM</option>
                                <option value="16.91">16:55 PM</option>
                                <option value="17.00">17:00 PM - o'clock</option>
                                <option value="17.8">17:05 PM</option>
                                <option value="17.16">17:10 PM</option>
                                <option value="17.25">17:15 PM</option>
                                <option value="17.33">17:20 PM</option>
                                <option value="17.41">17:25 PM</option>
                                <option value="17.50">17:30 PM</option>
                                <option value="17.58">17:35 PM</option>
                                <option value="17.66">17:40 PM</option>
                                <option value="17.75">17:45 PM</option>
                                <option value="17.83">17:50 PM</option>
                                <option value="17.91">17:55 PM</option>
                                <option value="18.00">18:00 PM - Normal</option>
                                <option value="18.8">18:05 PM</option>
                                <option value="18.16">18:10 PM</option>
                                <option value="18.25">18:15 PM</option>
                                <option value="18.33">18:20 PM</option>
                                <option value="18.41">18:25 PM</option>
                                <option value="18.50">18:30 PM</option>
                                <option value="18.58">18:35 PM</option>
                                <option value="18.66">18:40 PM</option>
                                <option value="18.75">18:45 PM</option>
                                <option value="18.83">18:50 PM</option>
                                <option value="18.91">18:55 PM</option>
                                <option value="19.00">19:00 PM - o'clock</option>
                                <option value="19.8">19:05 PM</option>
                                <option value="19.16">19:10 PM</option>
                                <option value="19.25">19:15 PM</option>
                                <option value="19.33">19:20 PM</option>
                                <option value="19.41">19:25 PM</option>
                                <option value="19.50">19:30 PM</option>
                                <option value="19.58">19:35 PM</option>
                                <option value="19.66">19:40 PM</option>
                                <option value="19.75">19:45 PM</option>
                                <option value="19.83">19:50 PM</option>
                                <option value="19.91">19:55 PM</option>
                                <option value="20.00">20:00 PM - o'clock</option>
                                <option value="20.8">20:05 PM</option>
                                <option value="20.16">20:10 PM</option>
                                <option value="20.25">20:15 PM</option>
                                <option value="20.33">20:20 PM</option>
                                <option value="20.41">20:25 PM</option>
                                <option value="20.50">20:30 PM</option>
                                <option value="20.58">20:35 PM</option>
                                <option value="20.66">20:40 PM</option>
                                <option value="20.75">20:45 PM</option>
                                <option value="20.83">20:50 PM</option>
                                <option value="20.91">20:55 PM</option>
                                <option value="21.00">21:00 PM - o'clock</option>
                                <option value="21.8">21:05 PM</option>
                                <option value="21.16">21:10 PM</option>
                                <option value="21.25">21:15 PM</option>
                                <option value="21.33">21:20 PM</option>
                                <option value="21.41">21:25 PM</option>
                                <option value="21.50">21:30 PM</option>
                                <option value="21.58">21:35 PM</option>
                                <option value="21.66">21:40 PM</option>
                                <option value="21.75">21:45 PM</option>
                                <option value="21.83">21:50 PM</option>
                                <option value="21.91">21:55 PM</option>
                                <option value="22.00">22:00 PM - o'clock</option>
                                <option value="22.8">22:05 PM</option>
                                <option value="22.16">22:10 PM</option>
                                <option value="22.25">22:15 PM</option>
                                <option value="22.33">22:20 PM</option>
                                <option value="22.41">22:25 PM</option>
                                <option value="22.50">22:30 PM</option>
                                <option value="22.58">22:35 PM</option>
                                <option value="22.66">22:40 PM</option>
                                <option value="22.75">22:45 PM</option>
                                <option value="22.83">22:50 PM</option>
                                <option value="22.91">22:55 PM</option>
                                <option value="23.00">23:00 PM - o'clock</option>
                                <option value="23.8">23:05 PM</option>
                                <option value="23.16">23:10 PM</option>
                                <option value="23.25">23:15 PM</option>
                                <option value="23.33">23:20 PM</option>
                                <option value="23.41">23:25 PM</option>
                                <option value="23.50">23:30 PM</option>
                                <option value="23.58">23:35 PM</option>
                                <option value="23.66">23:40 PM</option>
                                <option value="23.75">23:45 PM</option>
                                <option value="23.83">23:50 PM</option>
                                <option value="23.91">23:55 PM</option>
                                <option value="24.00">24:00 PM - o'clock</option>
                                <option value="24.8">15:05 PM</option>
                                <option value="24.16">24:10 PM</option>
                                <option value="24.25">24:15 PM</option>
                                <option value="24.33">24:20 PM</option>
                                <option value="24.41">24:25 PM</option>
                                <option value="24.50">24:30 PM</option>
                                <option value="24.58">24:35 PM</option>
                                <option value="24.66">24:40 PM</option>
                                <option value="24.75">24:45 PM</option>
                                <option value="24.83">24:50 PM</option>
                                <option value="24.91">24:55 PM</option>
                            </select>
                            {data29.map((datas, index) =>{
                              return<div key={index}>
                                        <p>{datas.endhour}</p>
                                    </div>
                                })}
                        </div>
                        <div className="col-md-2">
                            <select name="type29" onChange={e =>  settype29(e.target.value)} className="form-control" required>
                                <option value="">Select Type</option>
                                <option value="Normal">Normal</option>
                                <option value="SJUK">SJUK</option>
                                <option value="Holiday">Holiday</option>
                            </select>
                            {data29.map((datas, index) =>{
                              return<div key={index}>
                                        <p>{datas.type}</p>
                                    </div>
                                })}
                          </div>
                          <div className="col-md-2"> 
                              <button type='submit' className="btn btn-primary">Submit</button>
                        </div>
                      </form>
                  </div>
                  <div className="d-flex flex-column p-2">
                     <form action="" className="row"  onSubmit={handleSubmit30}>
                        <div className="col-md-1"> 30 </div>
                        <div className="col-md-1"> Wednesday </div>
                        <div className="col-md-1" > 
                            {data30.map((types, index) =>{
                              return<tr key={index}>
                                   <p style={types.type === "Normal" ? {backgroundColor:'rgba(7, 190, 7, 0.648)', color:'#000', padding:'5px 10px'} : 
                                     (types.type === "Holiday" ? {backgroundColor:'rgba(255, 0, 0, 0.438)', color:'#000', padding:'5px 10px'} : 
                                      {backgroundColor:'#008cff5a', color:'#000', padding:'5px 10px'}) }> {types.type}</p>
                                    </tr>
                                })}
                        </div>
                        <div className="col-md-2">
                            <select name="starthour30" onChange={e =>  setStart30(e.target.value)} className="form-control">
                                <option value="">Start Time</option>
                                <option value="05.00">05:00 AM</option>
                                <option value="06.00">06:00 AM</option>
                                <option value="06.8">06:05 AM</option>
                                <option value="06.16">06:10 AM</option>
                                <option value="06.25">06:15 AM</option>
                                <option value="06.33">06:20 AM</option>
                                <option value="06.41">06:25 AM</option>
                                <option value="06.50">06:30 AM</option>
                                <option value="06.58">06:35 AM</option>
                                <option value="06.66">06:40 AM</option>
                                <option value="06.75">06:45 AM</option>
                                <option value="06.83">06:50 AM</option>
                                <option value="06.91">06:55 AM</option>
                                <option value="07.00">07:00 AM - Normal</option>
                                <option value="07.8">07:05 AM</option>
                                <option value="07.16">07:10 AM</option>
                                <option value="07.25">07:15 AM</option>
                                <option value="07.33">07:20 AM</option>
                                <option value="07.41">07:25 AM</option>
                                <option value="07.50">07:30 AM</option>
                                <option value="07.58">07:35 AM</option>
                                <option value="07.66">07:40 AM</option>
                                <option value="07.75">07:45 AM</option>
                                <option value="07.83">07:50 AM</option>
                                <option value="07.91">07:55 AM</option>
                                <option value="08.00">08:00 AM - o'clock</option>
                                <option value="08.8">08:05 AM</option>
                                <option value="08.16">08:10 AM</option>
                                <option value="08.25">08:15 AM</option>
                                <option value="08.33">08:20 AM</option>
                                <option value="08.41">08:25 AM</option>
                                <option value="08.50">08:30 AM</option>
                                <option value="08.58">08:35 AM</option>
                                <option value="08.66">08:40 AM</option>
                                <option value="08.75">08:45 AM</option>
                                <option value="08.83">08:50 AM</option>
                                <option value="08.91">08:55 AM</option>
                                <option value="09.00">09:00 AM - o'clock</option>
                                <option value="09.8">09:05 AM</option>
                                <option value="09.16">09:10 AM</option>
                                <option value="09.25">09:15 AM</option>
                                <option value="09.33">09:20 AM</option>
                                <option value="09.41">09:25 AM</option>
                                <option value="09.50">09:30 AM</option>
                                <option value="09.58">09:35 AM</option>
                                <option value="09.66">09:40 AM</option>
                                <option value="09.75">09:45 AM</option>
                                <option value="09.83">09:50 AM</option>
                                <option value="09.91">09:55 AM</option>
                                <option value="10.00">10:00 AM - o'clock</option>
                                <option value="10.8">10:05 AM</option>
                                <option value="10.16">10:10 AM</option>
                                <option value="10.25">10:15 AM</option>
                                <option value="10.33">10:20 AM</option>
                                <option value="10.41">10:25 AM</option>
                                <option value="10.50">10:30 AM</option>
                                <option value="10.58">10:35 AM</option>
                                <option value="10.66">10:40 AM</option>
                                <option value="10.75">10:45 AM</option>
                                <option value="10.83">10:50 AM</option>
                                <option value="10.91">10:55 AM</option>
                                <option value="11.00">11:00 AM - o'clock</option>
                                <option value="11.8">11:05 AM</option>
                                <option value="11.16">11:10 AM</option>
                                <option value="11.25">11:15 AM</option>
                                <option value="11.33">11:20 AM</option>
                                <option value="11.41">11:25 AM</option>
                                <option value="11.50">11:30 AM</option>
                                <option value="11.58">11:35 AM</option>
                                <option value="11.66">11:40 AM</option>
                                <option value="11.75">11:45 AM</option>
                                <option value="11.83">11:50 AM</option>
                                <option value="11.91">11:55 AM</option>
                                <option value="12.00">12:00 PM  - o'clock</option>
                                <option value="12.8">12:05 PM</option>
                                <option value="12.16">12:10 PM</option>
                                <option value="12.25">12:15 PM</option>
                                <option value="12.33">12:20 PM</option>
                                <option value="12.41">12:25 PM</option>
                                <option value="12.50">12:30 PM</option>
                                <option value="12.58">12:35 PM</option>
                                <option value="12.66">12:40 PM</option>
                                <option value="12.75">12:45 PM</option>
                                <option value="12.83">12:50 PM</option>
                                <option value="12.91">12:55 PM</option>
                                <option value="13.00">13:00 PM - o'clock</option>
                                <option value="13.8">13:05 PM</option>
                                <option value="13.16">13:10 PM</option>
                                <option value="13.25">13:15 PM</option>
                                <option value="13.33">13:20 PM</option>
                                <option value="13.41">13:25 PM</option>
                                <option value="13.50">13:30 PM</option>
                                <option value="13.58">13:35 PM</option>
                                <option value="13.66">13:40 PM</option>
                                <option value="13.75">13:45 PM</option>
                                <option value="13.83">13:50 PM</option>
                                <option value="13.91">13:55 PM</option>
                                <option value="14.00">14:00 PM - o'clock</option>
                                <option value="14.8">14:05 PM</option>
                                <option value="14.16">14:10 PM</option>
                                <option value="14.25">14:15 PM</option>
                                <option value="14.33">14:20 PM</option>
                                <option value="14.41">14:25 PM</option>
                                <option value="14.50">14:30 PM</option>
                                <option value="14.58">14:35 PM</option>
                                <option value="14.66">14:40 PM</option>
                                <option value="14.75">14:45 PM</option>
                                <option value="14.83">14:50 PM</option>
                                <option value="14.91">14:55 PM</option>
                                <option value="15.00">15:00 PM - o'clock</option>
                                <option value="15.8">15:05 PM</option>
                                <option value="15.16">15:10 PM</option>
                                <option value="15.25">15:15 PM</option>
                                <option value="15.33">15:20 PM</option>
                                <option value="15.41">15:25 PM</option>
                                <option value="15.50">15:30 PM</option>
                                <option value="15.58">15:35 PM</option>
                                <option value="15.66">15:40 PM</option>
                                <option value="15.75">15:45 PM</option>
                                <option value="15.83">15:50 PM</option>
                                <option value="15.91">15:55 PM</option>
                                <option value="16.00">16:00 PM - o'clock</option>
                                <option value="16.8">16:05 PM</option>
                                <option value="16.16">16:10 PM</option>
                                <option value="16.25">16:15 PM</option>
                                <option value="16.33">16:20 PM</option>
                                <option value="16.41">16:25 PM</option>
                                <option value="16.50">16:30 PM</option>
                                <option value="16.58">16:35 PM</option>
                                <option value="16.66">16:40 PM</option>
                                <option value="16.75">16:45 PM</option>
                                <option value="16.83">16:50 PM</option>
                                <option value="16.91">16:55 PM</option>
                                <option value="17.00">17:00 PM - o'clock</option>
                                <option value="17.8">17:05 PM</option>
                                <option value="17.16">17:10 PM</option>
                                <option value="17.25">17:15 PM</option>
                                <option value="17.33">17:20 PM</option>
                                <option value="17.41">17:25 PM</option>
                                <option value="17.50">17:30 PM</option>
                                <option value="17.58">17:35 PM</option>
                                <option value="17.66">17:40 PM</option>
                                <option value="17.75">17:45 PM</option>
                                <option value="17.83">17:50 PM</option>
                                <option value="17.91">17:55 PM</option>
                                <option value="18.00">18:00 PM - Normal</option>
                                <option value="18.8">18:05 PM</option>
                                <option value="18.16">18:10 PM</option>
                                <option value="18.25">18:15 PM</option>
                                <option value="18.33">18:20 PM</option>
                                <option value="18.41">18:25 PM</option>
                                <option value="18.50">18:30 PM</option>
                                <option value="18.58">18:35 PM</option>
                                <option value="18.66">18:40 PM</option>
                                <option value="18.75">18:45 PM</option>
                                <option value="18.83">18:50 PM</option>
                                <option value="18.91">18:55 PM</option>
                                <option value="19.00">19:00 PM - o'clock</option>
                                <option value="19.8">19:05 PM</option>
                                <option value="19.16">19:10 PM</option>
                                <option value="19.25">19:15 PM</option>
                                <option value="19.33">19:20 PM</option>
                                <option value="19.41">19:25 PM</option>
                                <option value="19.50">19:30 PM</option>
                                <option value="19.58">19:35 PM</option>
                                <option value="19.66">19:40 PM</option>
                                <option value="19.75">19:45 PM</option>
                                <option value="19.83">19:50 PM</option>
                                <option value="19.91">19:55 PM</option>
                                <option value="20.00">20:00 PM - o'clock</option>
                                <option value="20.8">20:05 PM</option>
                                <option value="20.16">20:10 PM</option>
                                <option value="20.25">20:15 PM</option>
                                <option value="20.33">20:20 PM</option>
                                <option value="20.41">20:25 PM</option>
                                <option value="20.50">20:30 PM</option>
                                <option value="20.58">20:35 PM</option>
                                <option value="20.66">20:40 PM</option>
                                <option value="20.75">20:45 PM</option>
                                <option value="20.83">20:50 PM</option>
                                <option value="20.91">20:55 PM</option>
                                <option value="21.00">21:00 PM - o'clock</option>
                                <option value="21.8">21:05 PM</option>
                                <option value="21.16">21:10 PM</option>
                                <option value="21.25">21:15 PM</option>
                                <option value="21.33">21:20 PM</option>
                                <option value="21.41">21:25 PM</option>
                                <option value="21.50">21:30 PM</option>
                                <option value="21.58">21:35 PM</option>
                                <option value="21.66">21:40 PM</option>
                                <option value="21.75">21:45 PM</option>
                                <option value="21.83">21:50 PM</option>
                                <option value="21.91">21:55 PM</option>
                                <option value="22.00">22:00 PM - o'clock</option>
                                <option value="22.8">22:05 PM</option>
                                <option value="22.16">22:10 PM</option>
                                <option value="22.25">22:15 PM</option>
                                <option value="22.33">22:20 PM</option>
                                <option value="22.41">22:25 PM</option>
                                <option value="22.50">22:30 PM</option>
                                <option value="22.58">22:35 PM</option>
                                <option value="22.66">22:40 PM</option>
                                <option value="22.75">22:45 PM</option>
                                <option value="22.83">22:50 PM</option>
                                <option value="22.91">22:55 PM</option>
                                <option value="23.00">23:00 PM - o'clock</option>
                                <option value="23.8">23:05 PM</option>
                                <option value="23.16">23:10 PM</option>
                                <option value="23.25">23:15 PM</option>
                                <option value="23.33">23:20 PM</option>
                                <option value="23.41">23:25 PM</option>
                                <option value="23.50">23:30 PM</option>
                                <option value="23.58">23:35 PM</option>
                                <option value="23.66">23:40 PM</option>
                                <option value="23.75">23:45 PM</option>
                                <option value="23.83">23:50 PM</option>
                                <option value="23.91">23:55 PM</option>
                                <option value="24.00">24:00 PM - o'clock</option>
                                <option value="24.8">15:05 PM</option>
                                <option value="24.16">24:10 PM</option>
                                <option value="24.25">24:15 PM</option>
                                <option value="24.33">24:20 PM</option>
                                <option value="24.41">24:25 PM</option>
                                <option value="24.50">24:30 PM</option>
                                <option value="24.58">24:35 PM</option>
                                <option value="24.66">24:40 PM</option>
                                <option value="24.75">24:45 PM</option>
                                <option value="24.83">24:50 PM</option>
                                <option value="24.91">24:55 PM</option>
                            </select>
                            {data30.map((datas, index) =>{
                              return<div key={index}>
                                        <p>{datas.starthour}</p>
                                    </div>
                                })}
                        </div>
                        <div className="col-md-2">
                            <select name="endhour30" onChange={e =>  setEnd30(e.target.value)} className="form-control">
                                <option value="">Finish Time</option>
                                <option value="05.00">05:00 AM</option>
                                <option value="06.00">06:00 AM</option>
                                <option value="06.8">06:05 AM</option>
                                <option value="06.16">06:10 AM</option>
                                <option value="06.25">06:15 AM</option>
                                <option value="06.33">06:20 AM</option>
                                <option value="06.41">06:25 AM</option>
                                <option value="06.50">06:30 AM</option>
                                <option value="06.58">06:35 AM</option>
                                <option value="06.66">06:40 AM</option>
                                <option value="06.75">06:45 AM</option>
                                <option value="06.83">06:50 AM</option>
                                <option value="06.91">06:55 AM</option>
                                <option value="07.00">07:00 AM - Normal</option>
                                <option value="07.8">07:05 AM</option>
                                <option value="07.16">07:10 AM</option>
                                <option value="07.25">07:15 AM</option>
                                <option value="07.33">07:20 AM</option>
                                <option value="07.41">07:25 AM</option>
                                <option value="07.50">07:30 AM</option>
                                <option value="07.58">07:35 AM</option>
                                <option value="07.66">07:40 AM</option>
                                <option value="07.75">07:45 AM</option>
                                <option value="07.83">07:50 AM</option>
                                <option value="07.91">07:55 AM</option>
                                <option value="08.00">08:00 AM - o'clock</option>
                                <option value="08.8">08:05 AM</option>
                                <option value="08.16">08:10 AM</option>
                                <option value="08.25">08:15 AM</option>
                                <option value="08.33">08:20 AM</option>
                                <option value="08.41">08:25 AM</option>
                                <option value="08.50">08:30 AM</option>
                                <option value="08.58">08:35 AM</option>
                                <option value="08.66">08:40 AM</option>
                                <option value="08.75">08:45 AM</option>
                                <option value="08.83">08:50 AM</option>
                                <option value="08.91">08:55 AM</option>
                                <option value="09.00">09:00 AM - o'clock</option>
                                <option value="09.8">09:05 AM</option>
                                <option value="09.16">09:10 AM</option>
                                <option value="09.25">09:15 AM</option>
                                <option value="09.33">09:20 AM</option>
                                <option value="09.41">09:25 AM</option>
                                <option value="09.50">09:30 AM</option>
                                <option value="09.58">09:35 AM</option>
                                <option value="09.66">09:40 AM</option>
                                <option value="09.75">09:45 AM</option>
                                <option value="09.83">09:50 AM</option>
                                <option value="09.91">09:55 AM</option>
                                <option value="10.00">10:00 AM - o'clock</option>
                                <option value="10.8">10:05 AM</option>
                                <option value="10.16">10:10 AM</option>
                                <option value="10.25">10:15 AM</option>
                                <option value="10.33">10:20 AM</option>
                                <option value="10.41">10:25 AM</option>
                                <option value="10.50">10:30 AM</option>
                                <option value="10.58">10:35 AM</option>
                                <option value="10.66">10:40 AM</option>
                                <option value="10.75">10:45 AM</option>
                                <option value="10.83">10:50 AM</option>
                                <option value="10.91">10:55 AM</option>
                                <option value="11.00">11:00 AM - o'clock</option>
                                <option value="11.8">11:05 AM</option>
                                <option value="11.16">11:10 AM</option>
                                <option value="11.25">11:15 AM</option>
                                <option value="11.33">11:20 AM</option>
                                <option value="11.41">11:25 AM</option>
                                <option value="11.50">11:30 AM</option>
                                <option value="11.58">11:35 AM</option>
                                <option value="11.66">11:40 AM</option>
                                <option value="11.75">11:45 AM</option>
                                <option value="11.83">11:50 AM</option>
                                <option value="11.91">11:55 AM</option>
                                <option value="12.00">12:00 PM  - o'clock</option>
                                <option value="12.8">12:05 PM</option>
                                <option value="12.16">12:10 PM</option>
                                <option value="12.25">12:15 PM</option>
                                <option value="12.33">12:20 PM</option>
                                <option value="12.41">12:25 PM</option>
                                <option value="12.50">12:30 PM</option>
                                <option value="12.58">12:35 PM</option>
                                <option value="12.66">12:40 PM</option>
                                <option value="12.75">12:45 PM</option>
                                <option value="12.83">12:50 PM</option>
                                <option value="12.91">12:55 PM</option>
                                <option value="13.00">13:00 PM - o'clock</option>
                                <option value="13.8">13:05 PM</option>
                                <option value="13.16">13:10 PM</option>
                                <option value="13.25">13:15 PM</option>
                                <option value="13.33">13:20 PM</option>
                                <option value="13.41">13:25 PM</option>
                                <option value="13.50">13:30 PM</option>
                                <option value="13.58">13:35 PM</option>
                                <option value="13.66">13:40 PM</option>
                                <option value="13.75">13:45 PM</option>
                                <option value="13.83">13:50 PM</option>
                                <option value="13.91">13:55 PM</option>
                                <option value="14.00">14:00 PM - o'clock</option>
                                <option value="14.8">14:05 PM</option>
                                <option value="14.16">14:10 PM</option>
                                <option value="14.25">14:15 PM</option>
                                <option value="14.33">14:20 PM</option>
                                <option value="14.41">14:25 PM</option>
                                <option value="14.50">14:30 PM</option>
                                <option value="14.58">14:35 PM</option>
                                <option value="14.66">14:40 PM</option>
                                <option value="14.75">14:45 PM</option>
                                <option value="14.83">14:50 PM</option>
                                <option value="14.91">14:55 PM</option>
                                <option value="15.00">15:00 PM - o'clock</option>
                                <option value="15.8">15:05 PM</option>
                                <option value="15.16">15:10 PM</option>
                                <option value="15.25">15:15 PM</option>
                                <option value="15.33">15:20 PM</option>
                                <option value="15.41">15:25 PM</option>
                                <option value="15.50">15:30 PM</option>
                                <option value="15.58">15:35 PM</option>
                                <option value="15.66">15:40 PM</option>
                                <option value="15.75">15:45 PM</option>
                                <option value="15.83">15:50 PM</option>
                                <option value="15.91">15:55 PM</option>
                                <option value="16.00">16:00 PM - o'clock</option>
                                <option value="16.8">16:05 PM</option>
                                <option value="16.16">16:10 PM</option>
                                <option value="16.25">16:15 PM</option>
                                <option value="16.33">16:20 PM</option>
                                <option value="16.41">16:25 PM</option>
                                <option value="16.50">16:30 PM</option>
                                <option value="16.58">16:35 PM</option>
                                <option value="16.66">16:40 PM</option>
                                <option value="16.75">16:45 PM</option>
                                <option value="16.83">16:50 PM</option>
                                <option value="16.91">16:55 PM</option>
                                <option value="17.00">17:00 PM - o'clock</option>
                                <option value="17.8">17:05 PM</option>
                                <option value="17.16">17:10 PM</option>
                                <option value="17.25">17:15 PM</option>
                                <option value="17.33">17:20 PM</option>
                                <option value="17.41">17:25 PM</option>
                                <option value="17.50">17:30 PM</option>
                                <option value="17.58">17:35 PM</option>
                                <option value="17.66">17:40 PM</option>
                                <option value="17.75">17:45 PM</option>
                                <option value="17.83">17:50 PM</option>
                                <option value="17.91">17:55 PM</option>
                                <option value="18.00">18:00 PM - Normal</option>
                                <option value="18.8">18:05 PM</option>
                                <option value="18.16">18:10 PM</option>
                                <option value="18.25">18:15 PM</option>
                                <option value="18.33">18:20 PM</option>
                                <option value="18.41">18:25 PM</option>
                                <option value="18.50">18:30 PM</option>
                                <option value="18.58">18:35 PM</option>
                                <option value="18.66">18:40 PM</option>
                                <option value="18.75">18:45 PM</option>
                                <option value="18.83">18:50 PM</option>
                                <option value="18.91">18:55 PM</option>
                                <option value="19.00">19:00 PM - o'clock</option>
                                <option value="19.8">19:05 PM</option>
                                <option value="19.16">19:10 PM</option>
                                <option value="19.25">19:15 PM</option>
                                <option value="19.33">19:20 PM</option>
                                <option value="19.41">19:25 PM</option>
                                <option value="19.50">19:30 PM</option>
                                <option value="19.58">19:35 PM</option>
                                <option value="19.66">19:40 PM</option>
                                <option value="19.75">19:45 PM</option>
                                <option value="19.83">19:50 PM</option>
                                <option value="19.91">19:55 PM</option>
                                <option value="20.00">20:00 PM - o'clock</option>
                                <option value="20.8">20:05 PM</option>
                                <option value="20.16">20:10 PM</option>
                                <option value="20.25">20:15 PM</option>
                                <option value="20.33">20:20 PM</option>
                                <option value="20.41">20:25 PM</option>
                                <option value="20.50">20:30 PM</option>
                                <option value="20.58">20:35 PM</option>
                                <option value="20.66">20:40 PM</option>
                                <option value="20.75">20:45 PM</option>
                                <option value="20.83">20:50 PM</option>
                                <option value="20.91">20:55 PM</option>
                                <option value="21.00">21:00 PM - o'clock</option>
                                <option value="21.8">21:05 PM</option>
                                <option value="21.16">21:10 PM</option>
                                <option value="21.25">21:15 PM</option>
                                <option value="21.33">21:20 PM</option>
                                <option value="21.41">21:25 PM</option>
                                <option value="21.50">21:30 PM</option>
                                <option value="21.58">21:35 PM</option>
                                <option value="21.66">21:40 PM</option>
                                <option value="21.75">21:45 PM</option>
                                <option value="21.83">21:50 PM</option>
                                <option value="21.91">21:55 PM</option>
                                <option value="22.00">22:00 PM - o'clock</option>
                                <option value="22.8">22:05 PM</option>
                                <option value="22.16">22:10 PM</option>
                                <option value="22.25">22:15 PM</option>
                                <option value="22.33">22:20 PM</option>
                                <option value="22.41">22:25 PM</option>
                                <option value="22.50">22:30 PM</option>
                                <option value="22.58">22:35 PM</option>
                                <option value="22.66">22:40 PM</option>
                                <option value="22.75">22:45 PM</option>
                                <option value="22.83">22:50 PM</option>
                                <option value="22.91">22:55 PM</option>
                                <option value="23.00">23:00 PM - o'clock</option>
                                <option value="23.8">23:05 PM</option>
                                <option value="23.16">23:10 PM</option>
                                <option value="23.25">23:15 PM</option>
                                <option value="23.33">23:20 PM</option>
                                <option value="23.41">23:25 PM</option>
                                <option value="23.50">23:30 PM</option>
                                <option value="23.58">23:35 PM</option>
                                <option value="23.66">23:40 PM</option>
                                <option value="23.75">23:45 PM</option>
                                <option value="23.83">23:50 PM</option>
                                <option value="23.91">23:55 PM</option>
                                <option value="24.00">24:00 PM - o'clock</option>
                                <option value="24.8">15:05 PM</option>
                                <option value="24.16">24:10 PM</option>
                                <option value="24.25">24:15 PM</option>
                                <option value="24.33">24:20 PM</option>
                                <option value="24.41">24:25 PM</option>
                                <option value="24.50">24:30 PM</option>
                                <option value="24.58">24:35 PM</option>
                                <option value="24.66">24:40 PM</option>
                                <option value="24.75">24:45 PM</option>
                                <option value="24.83">24:50 PM</option>
                                <option value="24.91">24:55 PM</option>
                            </select>
                            {data30.map((datas, index) =>{
                              return<div key={index}>
                                        <p>{datas.endhour}</p>
                                    </div>
                                })}
                        </div>
                        <div className="col-md-2">
                            <select name="type30" onChange={e =>  settype30(e.target.value)} className="form-control" required>
                                <option value="">Select Type</option>
                                <option value="Normal">Normal</option>
                                <option value="SJUK">SJUK</option>
                                <option value="Holiday">Holiday</option>
                            </select>
                            {data30.map((datas, index) =>{
                              return<div key={index}>
                                        <p>{datas.type}</p>
                                    </div>
                                })}
                          </div>
                          <div className="col-md-2"> 
                              <button type='submit' className="btn btn-primary">Submit</button>
                        </div>
                      </form>
                  </div>
                  <div className="d-flex flex-column p-2">
                     <form action="" className="row"  onSubmit={handleSubmit31}>
                        <div className="col-md-1"> 31 </div>
                        <div className="col-md-1"> Thursday </div>
                        <div className="col-md-1" > 
                            {data31.map((types, index) =>{
                              return<tr key={index}>
                                   <p style={types.type === "Normal" ? {backgroundColor:'rgba(7, 190, 7, 0.648)', color:'#000', padding:'5px 10px'} : 
                                     (types.type === "Holiday" ? {backgroundColor:'rgba(255, 0, 0, 0.438)', color:'#000', padding:'5px 10px'} : 
                                      {backgroundColor:'#008cff5a', color:'#000', padding:'5px 10px'}) }> {types.type}</p>
                                    </tr>
                                })}
                        </div>
                        <div className="col-md-2">
                            <select name="starthour31" onChange={e =>  setStart31(e.target.value)} className="form-control">
                                <option value="">Start Time</option>
                                <option value="05.00">05:00 AM</option>
                                <option value="06.00">06:00 AM</option>
                                <option value="06.8">06:05 AM</option>
                                <option value="06.16">06:10 AM</option>
                                <option value="06.25">06:15 AM</option>
                                <option value="06.33">06:20 AM</option>
                                <option value="06.41">06:25 AM</option>
                                <option value="06.50">06:30 AM</option>
                                <option value="06.58">06:35 AM</option>
                                <option value="06.66">06:40 AM</option>
                                <option value="06.75">06:45 AM</option>
                                <option value="06.83">06:50 AM</option>
                                <option value="06.91">06:55 AM</option>
                                <option value="07.00">07:00 AM - Normal</option>
                                <option value="07.8">07:05 AM</option>
                                <option value="07.16">07:10 AM</option>
                                <option value="07.25">07:15 AM</option>
                                <option value="07.33">07:20 AM</option>
                                <option value="07.41">07:25 AM</option>
                                <option value="07.50">07:30 AM</option>
                                <option value="07.58">07:35 AM</option>
                                <option value="07.66">07:40 AM</option>
                                <option value="07.75">07:45 AM</option>
                                <option value="07.83">07:50 AM</option>
                                <option value="07.91">07:55 AM</option>
                                <option value="08.00">08:00 AM - o'clock</option>
                                <option value="08.8">08:05 AM</option>
                                <option value="08.16">08:10 AM</option>
                                <option value="08.25">08:15 AM</option>
                                <option value="08.33">08:20 AM</option>
                                <option value="08.41">08:25 AM</option>
                                <option value="08.50">08:30 AM</option>
                                <option value="08.58">08:35 AM</option>
                                <option value="08.66">08:40 AM</option>
                                <option value="08.75">08:45 AM</option>
                                <option value="08.83">08:50 AM</option>
                                <option value="08.91">08:55 AM</option>
                                <option value="09.00">09:00 AM - o'clock</option>
                                <option value="09.8">09:05 AM</option>
                                <option value="09.16">09:10 AM</option>
                                <option value="09.25">09:15 AM</option>
                                <option value="09.33">09:20 AM</option>
                                <option value="09.41">09:25 AM</option>
                                <option value="09.50">09:30 AM</option>
                                <option value="09.58">09:35 AM</option>
                                <option value="09.66">09:40 AM</option>
                                <option value="09.75">09:45 AM</option>
                                <option value="09.83">09:50 AM</option>
                                <option value="09.91">09:55 AM</option>
                                <option value="10.00">10:00 AM - o'clock</option>
                                <option value="10.8">10:05 AM</option>
                                <option value="10.16">10:10 AM</option>
                                <option value="10.25">10:15 AM</option>
                                <option value="10.33">10:20 AM</option>
                                <option value="10.41">10:25 AM</option>
                                <option value="10.50">10:30 AM</option>
                                <option value="10.58">10:35 AM</option>
                                <option value="10.66">10:40 AM</option>
                                <option value="10.75">10:45 AM</option>
                                <option value="10.83">10:50 AM</option>
                                <option value="10.91">10:55 AM</option>
                                <option value="11.00">11:00 AM - o'clock</option>
                                <option value="11.8">11:05 AM</option>
                                <option value="11.16">11:10 AM</option>
                                <option value="11.25">11:15 AM</option>
                                <option value="11.33">11:20 AM</option>
                                <option value="11.41">11:25 AM</option>
                                <option value="11.50">11:30 AM</option>
                                <option value="11.58">11:35 AM</option>
                                <option value="11.66">11:40 AM</option>
                                <option value="11.75">11:45 AM</option>
                                <option value="11.83">11:50 AM</option>
                                <option value="11.91">11:55 AM</option>
                                <option value="12.00">12:00 PM  - o'clock</option>
                                <option value="12.8">12:05 PM</option>
                                <option value="12.16">12:10 PM</option>
                                <option value="12.25">12:15 PM</option>
                                <option value="12.33">12:20 PM</option>
                                <option value="12.41">12:25 PM</option>
                                <option value="12.50">12:30 PM</option>
                                <option value="12.58">12:35 PM</option>
                                <option value="12.66">12:40 PM</option>
                                <option value="12.75">12:45 PM</option>
                                <option value="12.83">12:50 PM</option>
                                <option value="12.91">12:55 PM</option>
                                <option value="13.00">13:00 PM - o'clock</option>
                                <option value="13.8">13:05 PM</option>
                                <option value="13.16">13:10 PM</option>
                                <option value="13.25">13:15 PM</option>
                                <option value="13.33">13:20 PM</option>
                                <option value="13.41">13:25 PM</option>
                                <option value="13.50">13:30 PM</option>
                                <option value="13.58">13:35 PM</option>
                                <option value="13.66">13:40 PM</option>
                                <option value="13.75">13:45 PM</option>
                                <option value="13.83">13:50 PM</option>
                                <option value="13.91">13:55 PM</option>
                                <option value="14.00">14:00 PM - o'clock</option>
                                <option value="14.8">14:05 PM</option>
                                <option value="14.16">14:10 PM</option>
                                <option value="14.25">14:15 PM</option>
                                <option value="14.33">14:20 PM</option>
                                <option value="14.41">14:25 PM</option>
                                <option value="14.50">14:30 PM</option>
                                <option value="14.58">14:35 PM</option>
                                <option value="14.66">14:40 PM</option>
                                <option value="14.75">14:45 PM</option>
                                <option value="14.83">14:50 PM</option>
                                <option value="14.91">14:55 PM</option>
                                <option value="15.00">15:00 PM - o'clock</option>
                                <option value="15.8">15:05 PM</option>
                                <option value="15.16">15:10 PM</option>
                                <option value="15.25">15:15 PM</option>
                                <option value="15.33">15:20 PM</option>
                                <option value="15.41">15:25 PM</option>
                                <option value="15.50">15:30 PM</option>
                                <option value="15.58">15:35 PM</option>
                                <option value="15.66">15:40 PM</option>
                                <option value="15.75">15:45 PM</option>
                                <option value="15.83">15:50 PM</option>
                                <option value="15.91">15:55 PM</option>
                                <option value="16.00">16:00 PM - o'clock</option>
                                <option value="16.8">16:05 PM</option>
                                <option value="16.16">16:10 PM</option>
                                <option value="16.25">16:15 PM</option>
                                <option value="16.33">16:20 PM</option>
                                <option value="16.41">16:25 PM</option>
                                <option value="16.50">16:30 PM</option>
                                <option value="16.58">16:35 PM</option>
                                <option value="16.66">16:40 PM</option>
                                <option value="16.75">16:45 PM</option>
                                <option value="16.83">16:50 PM</option>
                                <option value="16.91">16:55 PM</option>
                                <option value="17.00">17:00 PM - o'clock</option>
                                <option value="17.8">17:05 PM</option>
                                <option value="17.16">17:10 PM</option>
                                <option value="17.25">17:15 PM</option>
                                <option value="17.33">17:20 PM</option>
                                <option value="17.41">17:25 PM</option>
                                <option value="17.50">17:30 PM</option>
                                <option value="17.58">17:35 PM</option>
                                <option value="17.66">17:40 PM</option>
                                <option value="17.75">17:45 PM</option>
                                <option value="17.83">17:50 PM</option>
                                <option value="17.91">17:55 PM</option>
                                <option value="18.00">18:00 PM - Normal</option>
                                <option value="18.8">18:05 PM</option>
                                <option value="18.16">18:10 PM</option>
                                <option value="18.25">18:15 PM</option>
                                <option value="18.33">18:20 PM</option>
                                <option value="18.41">18:25 PM</option>
                                <option value="18.50">18:30 PM</option>
                                <option value="18.58">18:35 PM</option>
                                <option value="18.66">18:40 PM</option>
                                <option value="18.75">18:45 PM</option>
                                <option value="18.83">18:50 PM</option>
                                <option value="18.91">18:55 PM</option>
                                <option value="19.00">19:00 PM - o'clock</option>
                                <option value="19.8">19:05 PM</option>
                                <option value="19.16">19:10 PM</option>
                                <option value="19.25">19:15 PM</option>
                                <option value="19.33">19:20 PM</option>
                                <option value="19.41">19:25 PM</option>
                                <option value="19.50">19:30 PM</option>
                                <option value="19.58">19:35 PM</option>
                                <option value="19.66">19:40 PM</option>
                                <option value="19.75">19:45 PM</option>
                                <option value="19.83">19:50 PM</option>
                                <option value="19.91">19:55 PM</option>
                                <option value="20.00">20:00 PM - o'clock</option>
                                <option value="20.8">20:05 PM</option>
                                <option value="20.16">20:10 PM</option>
                                <option value="20.25">20:15 PM</option>
                                <option value="20.33">20:20 PM</option>
                                <option value="20.41">20:25 PM</option>
                                <option value="20.50">20:30 PM</option>
                                <option value="20.58">20:35 PM</option>
                                <option value="20.66">20:40 PM</option>
                                <option value="20.75">20:45 PM</option>
                                <option value="20.83">20:50 PM</option>
                                <option value="20.91">20:55 PM</option>
                                <option value="21.00">21:00 PM - o'clock</option>
                                <option value="21.8">21:05 PM</option>
                                <option value="21.16">21:10 PM</option>
                                <option value="21.25">21:15 PM</option>
                                <option value="21.33">21:20 PM</option>
                                <option value="21.41">21:25 PM</option>
                                <option value="21.50">21:30 PM</option>
                                <option value="21.58">21:35 PM</option>
                                <option value="21.66">21:40 PM</option>
                                <option value="21.75">21:45 PM</option>
                                <option value="21.83">21:50 PM</option>
                                <option value="21.91">21:55 PM</option>
                                <option value="22.00">22:00 PM - o'clock</option>
                                <option value="22.8">22:05 PM</option>
                                <option value="22.16">22:10 PM</option>
                                <option value="22.25">22:15 PM</option>
                                <option value="22.33">22:20 PM</option>
                                <option value="22.41">22:25 PM</option>
                                <option value="22.50">22:30 PM</option>
                                <option value="22.58">22:35 PM</option>
                                <option value="22.66">22:40 PM</option>
                                <option value="22.75">22:45 PM</option>
                                <option value="22.83">22:50 PM</option>
                                <option value="22.91">22:55 PM</option>
                                <option value="23.00">23:00 PM - o'clock</option>
                                <option value="23.8">23:05 PM</option>
                                <option value="23.16">23:10 PM</option>
                                <option value="23.25">23:15 PM</option>
                                <option value="23.33">23:20 PM</option>
                                <option value="23.41">23:25 PM</option>
                                <option value="23.50">23:30 PM</option>
                                <option value="23.58">23:35 PM</option>
                                <option value="23.66">23:40 PM</option>
                                <option value="23.75">23:45 PM</option>
                                <option value="23.83">23:50 PM</option>
                                <option value="23.91">23:55 PM</option>
                                <option value="24.00">24:00 PM - o'clock</option>
                                <option value="24.8">15:05 PM</option>
                                <option value="24.16">24:10 PM</option>
                                <option value="24.25">24:15 PM</option>
                                <option value="24.33">24:20 PM</option>
                                <option value="24.41">24:25 PM</option>
                                <option value="24.50">24:30 PM</option>
                                <option value="24.58">24:35 PM</option>
                                <option value="24.66">24:40 PM</option>
                                <option value="24.75">24:45 PM</option>
                                <option value="24.83">24:50 PM</option>
                                <option value="24.91">24:55 PM</option>
                            </select>
                            {data31.map((datas, index) =>{
                              return<div key={index}>
                                        <p>{datas.starthour}</p>
                                    </div>
                                })}
                        </div>
                        <div className="col-md-2">
                            <select name="endhour31" onChange={e =>  setEnd31(e.target.value)} className="form-control">
                                <option value="">Finish Time</option>
                                <option value="05.00">05:00 AM</option>
                                <option value="06.00">06:00 AM</option>
                                <option value="06.8">06:05 AM</option>
                                <option value="06.16">06:10 AM</option>
                                <option value="06.25">06:15 AM</option>
                                <option value="06.33">06:20 AM</option>
                                <option value="06.41">06:25 AM</option>
                                <option value="06.50">06:30 AM</option>
                                <option value="06.58">06:35 AM</option>
                                <option value="06.66">06:40 AM</option>
                                <option value="06.75">06:45 AM</option>
                                <option value="06.83">06:50 AM</option>
                                <option value="06.91">06:55 AM</option>
                                <option value="07.00">07:00 AM - Normal</option>
                                <option value="07.8">07:05 AM</option>
                                <option value="07.16">07:10 AM</option>
                                <option value="07.25">07:15 AM</option>
                                <option value="07.33">07:20 AM</option>
                                <option value="07.41">07:25 AM</option>
                                <option value="07.50">07:30 AM</option>
                                <option value="07.58">07:35 AM</option>
                                <option value="07.66">07:40 AM</option>
                                <option value="07.75">07:45 AM</option>
                                <option value="07.83">07:50 AM</option>
                                <option value="07.91">07:55 AM</option>
                                <option value="08.00">08:00 AM - o'clock</option>
                                <option value="08.8">08:05 AM</option>
                                <option value="08.16">08:10 AM</option>
                                <option value="08.25">08:15 AM</option>
                                <option value="08.33">08:20 AM</option>
                                <option value="08.41">08:25 AM</option>
                                <option value="08.50">08:30 AM</option>
                                <option value="08.58">08:35 AM</option>
                                <option value="08.66">08:40 AM</option>
                                <option value="08.75">08:45 AM</option>
                                <option value="08.83">08:50 AM</option>
                                <option value="08.91">08:55 AM</option>
                                <option value="09.00">09:00 AM - o'clock</option>
                                <option value="09.8">09:05 AM</option>
                                <option value="09.16">09:10 AM</option>
                                <option value="09.25">09:15 AM</option>
                                <option value="09.33">09:20 AM</option>
                                <option value="09.41">09:25 AM</option>
                                <option value="09.50">09:30 AM</option>
                                <option value="09.58">09:35 AM</option>
                                <option value="09.66">09:40 AM</option>
                                <option value="09.75">09:45 AM</option>
                                <option value="09.83">09:50 AM</option>
                                <option value="09.91">09:55 AM</option>
                                <option value="10.00">10:00 AM - o'clock</option>
                                <option value="10.8">10:05 AM</option>
                                <option value="10.16">10:10 AM</option>
                                <option value="10.25">10:15 AM</option>
                                <option value="10.33">10:20 AM</option>
                                <option value="10.41">10:25 AM</option>
                                <option value="10.50">10:30 AM</option>
                                <option value="10.58">10:35 AM</option>
                                <option value="10.66">10:40 AM</option>
                                <option value="10.75">10:45 AM</option>
                                <option value="10.83">10:50 AM</option>
                                <option value="10.91">10:55 AM</option>
                                <option value="11.00">11:00 AM - o'clock</option>
                                <option value="11.8">11:05 AM</option>
                                <option value="11.16">11:10 AM</option>
                                <option value="11.25">11:15 AM</option>
                                <option value="11.33">11:20 AM</option>
                                <option value="11.41">11:25 AM</option>
                                <option value="11.50">11:30 AM</option>
                                <option value="11.58">11:35 AM</option>
                                <option value="11.66">11:40 AM</option>
                                <option value="11.75">11:45 AM</option>
                                <option value="11.83">11:50 AM</option>
                                <option value="11.91">11:55 AM</option>
                                <option value="12.00">12:00 PM  - o'clock</option>
                                <option value="12.8">12:05 PM</option>
                                <option value="12.16">12:10 PM</option>
                                <option value="12.25">12:15 PM</option>
                                <option value="12.33">12:20 PM</option>
                                <option value="12.41">12:25 PM</option>
                                <option value="12.50">12:30 PM</option>
                                <option value="12.58">12:35 PM</option>
                                <option value="12.66">12:40 PM</option>
                                <option value="12.75">12:45 PM</option>
                                <option value="12.83">12:50 PM</option>
                                <option value="12.91">12:55 PM</option>
                                <option value="13.00">13:00 PM - o'clock</option>
                                <option value="13.8">13:05 PM</option>
                                <option value="13.16">13:10 PM</option>
                                <option value="13.25">13:15 PM</option>
                                <option value="13.33">13:20 PM</option>
                                <option value="13.41">13:25 PM</option>
                                <option value="13.50">13:30 PM</option>
                                <option value="13.58">13:35 PM</option>
                                <option value="13.66">13:40 PM</option>
                                <option value="13.75">13:45 PM</option>
                                <option value="13.83">13:50 PM</option>
                                <option value="13.91">13:55 PM</option>
                                <option value="14.00">14:00 PM - o'clock</option>
                                <option value="14.8">14:05 PM</option>
                                <option value="14.16">14:10 PM</option>
                                <option value="14.25">14:15 PM</option>
                                <option value="14.33">14:20 PM</option>
                                <option value="14.41">14:25 PM</option>
                                <option value="14.50">14:30 PM</option>
                                <option value="14.58">14:35 PM</option>
                                <option value="14.66">14:40 PM</option>
                                <option value="14.75">14:45 PM</option>
                                <option value="14.83">14:50 PM</option>
                                <option value="14.91">14:55 PM</option>
                                <option value="15.00">15:00 PM - o'clock</option>
                                <option value="15.8">15:05 PM</option>
                                <option value="15.16">15:10 PM</option>
                                <option value="15.25">15:15 PM</option>
                                <option value="15.33">15:20 PM</option>
                                <option value="15.41">15:25 PM</option>
                                <option value="15.50">15:30 PM</option>
                                <option value="15.58">15:35 PM</option>
                                <option value="15.66">15:40 PM</option>
                                <option value="15.75">15:45 PM</option>
                                <option value="15.83">15:50 PM</option>
                                <option value="15.91">15:55 PM</option>
                                <option value="16.00">16:00 PM - o'clock</option>
                                <option value="16.8">16:05 PM</option>
                                <option value="16.16">16:10 PM</option>
                                <option value="16.25">16:15 PM</option>
                                <option value="16.33">16:20 PM</option>
                                <option value="16.41">16:25 PM</option>
                                <option value="16.50">16:30 PM</option>
                                <option value="16.58">16:35 PM</option>
                                <option value="16.66">16:40 PM</option>
                                <option value="16.75">16:45 PM</option>
                                <option value="16.83">16:50 PM</option>
                                <option value="16.91">16:55 PM</option>
                                <option value="17.00">17:00 PM - o'clock</option>
                                <option value="17.8">17:05 PM</option>
                                <option value="17.16">17:10 PM</option>
                                <option value="17.25">17:15 PM</option>
                                <option value="17.33">17:20 PM</option>
                                <option value="17.41">17:25 PM</option>
                                <option value="17.50">17:30 PM</option>
                                <option value="17.58">17:35 PM</option>
                                <option value="17.66">17:40 PM</option>
                                <option value="17.75">17:45 PM</option>
                                <option value="17.83">17:50 PM</option>
                                <option value="17.91">17:55 PM</option>
                                <option value="18.00">18:00 PM - Normal</option>
                                <option value="18.8">18:05 PM</option>
                                <option value="18.16">18:10 PM</option>
                                <option value="18.25">18:15 PM</option>
                                <option value="18.33">18:20 PM</option>
                                <option value="18.41">18:25 PM</option>
                                <option value="18.50">18:30 PM</option>
                                <option value="18.58">18:35 PM</option>
                                <option value="18.66">18:40 PM</option>
                                <option value="18.75">18:45 PM</option>
                                <option value="18.83">18:50 PM</option>
                                <option value="18.91">18:55 PM</option>
                                <option value="19.00">19:00 PM - o'clock</option>
                                <option value="19.8">19:05 PM</option>
                                <option value="19.16">19:10 PM</option>
                                <option value="19.25">19:15 PM</option>
                                <option value="19.33">19:20 PM</option>
                                <option value="19.41">19:25 PM</option>
                                <option value="19.50">19:30 PM</option>
                                <option value="19.58">19:35 PM</option>
                                <option value="19.66">19:40 PM</option>
                                <option value="19.75">19:45 PM</option>
                                <option value="19.83">19:50 PM</option>
                                <option value="19.91">19:55 PM</option>
                                <option value="20.00">20:00 PM - o'clock</option>
                                <option value="20.8">20:05 PM</option>
                                <option value="20.16">20:10 PM</option>
                                <option value="20.25">20:15 PM</option>
                                <option value="20.33">20:20 PM</option>
                                <option value="20.41">20:25 PM</option>
                                <option value="20.50">20:30 PM</option>
                                <option value="20.58">20:35 PM</option>
                                <option value="20.66">20:40 PM</option>
                                <option value="20.75">20:45 PM</option>
                                <option value="20.83">20:50 PM</option>
                                <option value="20.91">20:55 PM</option>
                                <option value="21.00">21:00 PM - o'clock</option>
                                <option value="21.8">21:05 PM</option>
                                <option value="21.16">21:10 PM</option>
                                <option value="21.25">21:15 PM</option>
                                <option value="21.33">21:20 PM</option>
                                <option value="21.41">21:25 PM</option>
                                <option value="21.50">21:30 PM</option>
                                <option value="21.58">21:35 PM</option>
                                <option value="21.66">21:40 PM</option>
                                <option value="21.75">21:45 PM</option>
                                <option value="21.83">21:50 PM</option>
                                <option value="21.91">21:55 PM</option>
                                <option value="22.00">22:00 PM - o'clock</option>
                                <option value="22.8">22:05 PM</option>
                                <option value="22.16">22:10 PM</option>
                                <option value="22.25">22:15 PM</option>
                                <option value="22.33">22:20 PM</option>
                                <option value="22.41">22:25 PM</option>
                                <option value="22.50">22:30 PM</option>
                                <option value="22.58">22:35 PM</option>
                                <option value="22.66">22:40 PM</option>
                                <option value="22.75">22:45 PM</option>
                                <option value="22.83">22:50 PM</option>
                                <option value="22.91">22:55 PM</option>
                                <option value="23.00">23:00 PM - o'clock</option>
                                <option value="23.8">23:05 PM</option>
                                <option value="23.16">23:10 PM</option>
                                <option value="23.25">23:15 PM</option>
                                <option value="23.33">23:20 PM</option>
                                <option value="23.41">23:25 PM</option>
                                <option value="23.50">23:30 PM</option>
                                <option value="23.58">23:35 PM</option>
                                <option value="23.66">23:40 PM</option>
                                <option value="23.75">23:45 PM</option>
                                <option value="23.83">23:50 PM</option>
                                <option value="23.91">23:55 PM</option>
                                <option value="24.00">24:00 PM - o'clock</option>
                                <option value="24.8">15:05 PM</option>
                                <option value="24.16">24:10 PM</option>
                                <option value="24.25">24:15 PM</option>
                                <option value="24.33">24:20 PM</option>
                                <option value="24.41">24:25 PM</option>
                                <option value="24.50">24:30 PM</option>
                                <option value="24.58">24:35 PM</option>
                                <option value="24.66">24:40 PM</option>
                                <option value="24.75">24:45 PM</option>
                                <option value="24.83">24:50 PM</option>
                                <option value="24.91">24:55 PM</option>
                            </select>
                            {data31.map((datas, index) =>{
                              return<div key={index}>
                                        <p>{datas.endhour}</p>
                                    </div>
                                })}
                        </div>
                        <div className="col-md-2">
                            <select name="type31" onChange={e =>  settype31(e.target.value)} className="form-control" required>
                                <option value="">Select Type</option>
                                <option value="Normal">Normal</option>
                                <option value="SJUK">SJUK</option>
                                <option value="Holiday">Holiday</option>
                            </select>
                            {data31.map((datas, index) =>{
                              return<div key={index}>
                                        <p>{datas.type}</p>
                                    </div>
                                })}
                          </div>
                          <div className="col-md-2"> 
                              <button type='submit' className="btn btn-primary">Submit</button>
                        </div>
                      </form>
                  </div>

                </div>
            </div>
      </div>
    </div>
  )
}

export default Aug

