import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom';
import "./timetable.scss";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';


function Report() {
  const {id} = useParams();
  const [employee, setEmployee] = useState([])
  const [taxing, setTAX] = useState([])
  const [overtime, setOver] = useState([]);
  const [totalob1, setOB1] = useState();
  const [totalob11, setOB11] = useState()
  const [totalob2, setOB2] = useState();
  const [totalob3jun, setOB3jun] = useState();
  const [totalob3sjun, setOB3sjun] = useState();
  const [totalob3jul, setOB3jul] = useState();
  const [totalob3sjul, setOB3sjul] = useState();
  const [totalob3aug, setOB3aug] = useState();
  const [totalob3saug, setOB3saug] = useState();
  const [totalob3, setOB3] = useState();
  const [totalob3s, setOB3s] = useState();


  useEffect(()=> {
      axios.get('http://localhost:8800/get/'+id)
      .then(res => setEmployee(res.data.Result[0]))
      .catch(err => console.log(err));
  });


useEffect(() => {
  axios.get('http://localhost:8800/gettax/')
  .then(res => {
    if(res.data.Status === "Success"){
      setTAX(res.data.Result[0]);
    }else{
      alert("Error")
    }
  })
  .catch(err => console.log(err));
}, [])


  useEffect(()=> {
    axios.get('http://localhost:8800/getovertime/'+id)
    .then(res => setOver(res.data.Result[0]))
    .catch(err => console.log(err));
});

 // Total Hours
 const [totalhours, setHours] = useState();
 const [totalhoursjun, setHoursJun] = useState();
 const [totalhoursjul, setHoursJul] = useState();
 const [totalhoursaug, setHoursaug] = useState();


 useEffect(() => {
   axios.get('http://localhost:8800/totalhours/'+id)
     .then(res => {
       setHours(res.data[0].sumOfhours)
     }).catch(err => console.log(err));

     axios.get('http://localhost:8800/totalhoursjun/'+id)
     .then(res => {
      setHoursJun(res.data[0].sumOfhours)
     }).catch(err => console.log(err));

     axios.get('http://localhost:8800/totalhoursjul/'+id)
     .then(res => {
      setHoursJul(res.data[0].sumOfhours)
     }).catch(err => console.log(err));

     axios.get('http://localhost:8800/totalhoursaug/'+id)
     .then(res => {
      setHoursaug(res.data[0].sumOfhours)
     }).catch(err => console.log(err));
     /// End of Total Hours

     axios.get('http://localhost:8800/totalob1/'+id)
       .then(res => {
         setOB1(res.data[0].sumOfob1)
       }).catch(err => console.log(err));
       axios.get('http://localhost:8800/totalob11/'+id)
       .then(res => {
         setOB11(res.data[0].sumOfob11)
       }).catch(err => console.log(err));


      axios.get('http://localhost:8800/totalob2/'+id)
        .then(res => {
          setOB2(res.data[0].sumOfob2)
        }).catch(err => console.log(err));

        axios.get('http://localhost:8800/totalob3/'+id)
          .then(res => {
            setOB3(res.data[0].sumOfob3)
          }).catch(err => console.log(err));

          axios.get('http://localhost:8800/totalob3s/'+id)
            .then(res => {
              setOB3s(res.data[0].sumOfob3s)
            }).catch(err => console.log(err));

      ///// End of Total OB

          axios.get('http://localhost:8800/totalob3jun/'+id)
          .then(res => {
            setOB3jun(res.data[0].sumOfob3jun)
          }).catch(err => console.log(err));

          axios.get('http://localhost:8800/totalob3jul/'+id)
          .then(res => {
            setOB3jul(res.data[0].sumOfob3jul)
          }).catch(err => console.log(err));

          axios.get('http://localhost:8800/totalob3aug/'+id)
          .then(res => {
            setOB3aug(res.data[0].sumOfob3aug)
          }).catch(err => console.log(err));

      ///////// End of OBs

          axios.get('http://localhost:8800/totalob3sjun/'+id)
            .then(res => {
              setOB3sjun(res.data[0].sumOfob3sjun)
            }).catch(err => console.log(err));

          axios.get('http://localhost:8800/totalob3sjul/'+id)
            .then(res => {
              setOB3sjul(res.data[0].sumOfob3sjul)
            }).catch(err => console.log(err));

          axios.get('http://localhost:8800/totalob3saug/'+id)
            .then(res => {
              setOB3saug(res.data[0].sumOfob3saug)
            }).catch(err => console.log(err));
          }, [id])
     
  return (
    <div className="timetable">
      <div className="top">
        <div className="title">
        <h4>Reports of {employee.firstname} {employee.lastname}</h4>
        </div>
        <div className="button">
            <Link to="/reports" className='btn btn-back btn-sm me-2'><ArrowBackIosIcon />Back</Link>
        </div>
      </div><hr />
        <div className="row">
          <div className="col-md-12">
            <div className="row">
              <div className="col-md-3">
              <div className="box">
                <h2>June</h2><hr/>
                  <table className='table'>
                    <thead>
                        <tr>
                          <td><strong>Normal Hours:</strong></td>
                          <td>{Math.round(totalhoursjun - totalob1 - totalob2 - (totalob3jun + totalob3sjun))}</td>
                        </tr>
                        <tr>
                            <td><strong>OB1:</strong></td>
                            <td>{totalob1 - totalob11}</td>
                        </tr>
                        <tr>
                           <td><strong> OB2:</strong></td>
                           <td>{totalob2}</td>
                        </tr>
                        <tr>
                           <td><strong> OB3:</strong></td>
                           <td>{Math.round(totalob3jun + totalob3sjun)}</td>
                        </tr>
                        <tr>
                           <td><strong> Total Hours:</strong></td>
                           <td>{Math.round(totalhoursjun)}</td>
                        </tr>
                        <tr>
                           <td><strong> Salary:</strong></td>
                           <td>{ Math.round(employee.salary * totalhoursjun + (totalob1 * overtime.OB1) + (totalob2 * overtime.OB2) + (totalob3jun * overtime.OB3 + (totalob3sjun * overtime.OB3))) }</td>
                        </tr>
                        <tr>
                           <td><strong> Holiday:</strong></td>
                           <td>{ Math.round(employee.salary * totalhoursjun + (totalob1 * overtime.OB1) + (totalob2 * overtime.OB2) + (totalob3jun * overtime.OB3 + (totalob3sjun * overtime.OB3))) * taxing.holiday / 100 }</td>
                        </tr>
                        <tr>
                           <td><strong> TAX:</strong></td>
                           <td>{ Math.round(employee.salary * totalhoursjun + (totalob1 * overtime.OB1) + (totalob2 * overtime.OB2) + (totalob3jun * overtime.OB3 + (totalob3sjun * overtime.OB3))) * taxing.taxrate / 100 }</td>
                        </tr>
                        <tr>
                           <td><strong> Total:</strong></td>
                           <td>{ Math.round(employee.salary * totalhoursjun + (totalob1 * overtime.OB1) + (totalob2 * overtime.OB2) + (totalob3jun * overtime.OB3 + (totalob3sjun * overtime.OB3))) * taxing.holiday / 100 + 
                                  Math.round(employee.salary * totalhoursjun + (totalob1 * overtime.OB1) + (totalob2 * overtime.OB2) + (totalob3jun * overtime.OB3 + (totalob3sjun * overtime.OB3))) +
                                  Math.round(employee.salary * totalhoursjun + (totalob1 * overtime.OB1) + (totalob2 * overtime.OB2) + (totalob3jun * overtime.OB3 + (totalob3sjun * overtime.OB3))) * taxing.taxrate / 100
                                  }</td>
                        </tr>
                    </thead>
                    </table>
                </div>
              </div>
              <div className="col-md-3">
              <div className="box">
                <h2>July</h2><hr/>
                  <table className='table'>
                    <thead>
                        <tr>
                          <td><strong>Normal Hours:</strong></td>
                          <td>{Math.round(totalhoursjul - totalob1 - totalob2 - (totalob3jul + totalob3sjul))}</td>
                        </tr>
                        <tr>
                            <td><strong>OB1:</strong></td>
                            <td>{totalob1}</td>
                        </tr>
                        <tr>
                           <td><strong> OB2:</strong></td>
                           <td>{totalob2}</td>
                        </tr>
                        <tr>
                           <td><strong> OB3:</strong></td>
                           <td>{Math.round(totalob3jul + totalob3sjul)}</td>
                        </tr>
                        <tr>
                           <td><strong> Total Hours:</strong></td>
                           <td>{Math.round(totalhoursjul)}</td>
                        </tr>
                        <tr>
                           <td><strong> Salary:</strong></td>
                           <td>{ Math.round(employee.salary * totalhoursjun + (totalob1 * overtime.OB1) + (totalob2 * overtime.OB2) + (totalob3jul * overtime.OB3 + (totalob3sjul * overtime.OB3))) }</td>
                        </tr>
                        <tr>
                           <td><strong> Holiday:</strong></td>
                           <td>{ Math.round(employee.salary * totalhoursjun + (totalob1 * overtime.OB1) + (totalob2 * overtime.OB2) + (totalob3jul * overtime.OB3 + (totalob3sjul * overtime.OB3))) * taxing.holiday / 100 }</td>
                        </tr>
                        <tr>
                           <td><strong> TAX:</strong></td>
                           <td>{ Math.round(employee.salary * totalhoursjun + (totalob1 * overtime.OB1) + (totalob2 * overtime.OB2) + (totalob3jul * overtime.OB3 + (totalob3sjul * overtime.OB3))) * taxing.taxrate / 100 }</td>
                        </tr>
                        <tr>
                           <td><strong> Total:</strong></td>
                           <td>{ Math.round(employee.salary * totalhoursjun + (totalob1 * overtime.OB1) + (totalob2 * overtime.OB2) + (totalob3jul * overtime.OB3 + (totalob3sjul * overtime.OB3))) * taxing.holiday / 100 + 
                                  Math.round(employee.salary * totalhoursjun + (totalob1 * overtime.OB1) + (totalob2 * overtime.OB2) + (totalob3jul * overtime.OB3 + (totalob3sjul * overtime.OB3))) +
                                  Math.round(employee.salary * totalhoursjun + (totalob1 * overtime.OB1) + (totalob2 * overtime.OB2) + (totalob3jul * overtime.OB3 + (totalob3sjul * overtime.OB3))) * taxing.taxrate / 100
                                  }</td>
                        </tr>
                    </thead>
                    </table>
                </div>
              </div>
              <div className="col-md-3">
              <div className="box">
                <h2>August</h2><hr/>
                  <table className='table'>
                    <thead>
                        <tr>
                          <td><strong>Normal Hours:</strong></td>
                          <td>{Math.round(totalhoursaug - totalob1 - totalob2 - (totalob3aug + totalob3saug))}</td>
                        </tr>
                        <tr>
                            <td><strong>OB1:</strong></td>
                            <td>{totalob1}</td>
                        </tr>
                        <tr>
                           <td><strong> OB2:</strong></td>
                           <td>{totalob2}</td>
                        </tr>
                        <tr>
                           <td><strong> OB3:</strong></td>
                           <td>{Math.round(totalob3aug + totalob3saug)}</td>
                        </tr>
                        <tr>
                           <td><strong> Total Hours:</strong></td>
                           <td>{Math.round(totalhoursjun)}</td>
                        </tr>
                        <tr>
                           <td><strong> Salary:</strong></td>
                           <td>{ Math.round(employee.salary * totalhoursjun + (totalob1 * overtime.OB1) + (totalob2 * overtime.OB2) + (totalob3aug * overtime.OB3 + (totalob3saug * overtime.OB3))) }</td>
                        </tr>
                        <tr>
                           <td><strong> Holiday:</strong></td>
                           <td>{ Math.round(employee.salary * totalhoursjun + (totalob1 * overtime.OB1) + (totalob2 * overtime.OB2) + (totalob3aug * overtime.OB3 + (totalob3saug * overtime.OB3))) * taxing.holiday / 100 }</td>
                        </tr>
                        <tr>
                           <td><strong> TAX:</strong></td>
                           <td>{ Math.round(employee.salary * totalhoursjun + (totalob1 * overtime.OB1) + (totalob2 * overtime.OB2) + (totalob3aug * overtime.OB3 + (totalob3saug * overtime.OB3))) * taxing.taxrate / 100 }</td>
                        </tr>
                        <tr>
                           <td><strong> Total:</strong></td>
                           <td>{ Math.round(employee.salary * totalhoursjun + (totalob1 * overtime.OB1) + (totalob2 * overtime.OB2) + (totalob3aug * overtime.OB3 + (totalob3saug * overtime.OB3))) * taxing.holiday / 100 + 
                                  Math.round(employee.salary * totalhoursjun + (totalob1 * overtime.OB1) + (totalob2 * overtime.OB2) + (totalob3aug * overtime.OB3 + (totalob3saug * overtime.OB3))) +
                                  Math.round(employee.salary * totalhoursjun + (totalob1 * overtime.OB1) + (totalob2 * overtime.OB2) + (totalob3aug * overtime.OB3 + (totalob3saug * overtime.OB3))) * taxing.taxrate / 100
                                  }</td>
                        </tr>
                    </thead>
                    </table>
                </div>
              </div>
              <div className="col-md-3">
                  <div className="bottom"> 
                  <h2>Total</h2> <hr />
                      <h6>Total OB1 Salary: <strong>{ totalob1 * overtime.OB1}</strong></h6>
                      <h6>Total OB2 Salary: <strong>{ totalob2 * overtime.OB2}</strong></h6>
                      <h6>Total OB3 Salary: <strong>{ totalob3 * overtime.OB3 + (totalob3s * overtime.OB3)}</strong></h6>
                  </div>
                  
                  <div className="box">
                  <table className='table'>
                    <thead>
                        <tr>
                          <td><strong>Normal Hours:</strong></td>
                          <td>{Math.round(totalhours - totalob1 - totalob2 - (totalob3 + totalob3s))}</td>
                        </tr>
                        <tr>
                            <td><strong>OB1:</strong></td>
                            <td>{totalob1}</td>
                        </tr>
                        <tr>
                           <td><strong> OB2:</strong></td>
                           <td>{totalob2}</td>
                        </tr>
                        <tr>
                           <td><strong> OB3:</strong></td>
                           <td>{totalob3 + totalob3s}</td>
                        </tr>
                        <tr>
                           <td><strong> Total Hours:</strong></td>
                           <td>{Math.round(totalhours)}</td>
                        </tr>
                        <tr>
                           <td><strong> Salary:</strong></td>
                           <td>{ Math.round(employee.salary * totalhours + (totalob1 * overtime.OB1) + (totalob2 * overtime.OB2) + (totalob3 * overtime.OB3 + (totalob3s * overtime.OB3))) }</td>
                        </tr>
                        <tr>
                           <td><strong> Holiday:</strong></td>
                           <td>{ Math.round(employee.salary * totalhours + (totalob1 * overtime.OB1) + (totalob2 * overtime.OB2) + (totalob3 * overtime.OB3 + (totalob3s * overtime.OB3))) * taxing.holiday / 100 }</td>
                        </tr>
                        <tr>
                           <td><strong> TAX:</strong></td>
                           <td>{ Math.round(employee.salary * totalhours + (totalob1 * overtime.OB1) + (totalob2 * overtime.OB2) + (totalob3 * overtime.OB3 + (totalob3s * overtime.OB3))) * taxing.taxrate / 100 }</td>
                        </tr>
                        <tr>
                           <td><strong> Total:</strong></td>
                           <td>{ Math.round(employee.salary * totalhours + (totalob1 * overtime.OB1) + (totalob2 * overtime.OB2) + (totalob3 * overtime.OB3 + (totalob3s * overtime.OB3))) * taxing.holiday / 100 + 
                                  Math.round(employee.salary * totalhours + (totalob1 * overtime.OB1) + (totalob2 * overtime.OB2) + (totalob3 * overtime.OB3 + (totalob3s * overtime.OB3))) +
                                  Math.round(employee.salary * totalhours + (totalob1 * overtime.OB1) + (totalob2 * overtime.OB2) + (totalob3 * overtime.OB3 + (totalob3s * overtime.OB3))) * taxing.taxrate / 100
                                  }</td>
                        </tr>
                    </thead>
                    </table>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
    
  )
}

export default Report
