import axios from 'axios';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import "./employee.scss";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom';

function Viewemp() {

  const {id} = useParams();

  const [employee, setEmployee] = useState([])
  const [overtime, setovertime] = useState([])


  useEffect(()=> {
      axios.get('http://localhost:8800/get/'+id)
      .then(res => setEmployee(res.data.Result[0]))
      .catch(err => console.log(err));
  });
  
  useEffect(() => {
    axios.get('http://localhost:8800/getovertime/'+id)
    .then(res => {
      if(res.data.Status === "Success"){
        setovertime(res.data.Result);
      }else{
        alert("Error")
      }
    })
    .catch(err => console.log(err));
  }, [])
  

  return (
    <div className="employee">
      <div className="top">
        <div className="title">
        <h4>Employee</h4>
        </div>
        <div className="button">
        <Link to={`/editemp/` + employee.id} className='btn btn-color btn-sm me-2'><EditIcon/>Edit Employee</Link> 
        <Link to="/employee" className='btn btn-back btn-sm'><ArrowBackIosNewIcon/>Back</Link>
        </div>
      </div>
      <div className="row">
          <div className="col-12">
            <div className="bottom">
                <h5>Personal Information</h5>
            <hr />
                  <table className='table'>
                    <thead>
                        <tr>
                          <td><strong>Name:</strong></td>
                          <td>{employee.firstname} {employee.lastname}</td>
                        </tr>
                        <tr>
                            <td><strong>Position:</strong></td>
                            <td>{employee.position}</td>
                        </tr>
                        <tr>
                           <td><strong> Salary:</strong></td>
                           <td>{employee.salary}</td>
                        </tr>
                        <tr>
                           <td><strong> Salary Type:</strong></td>
                           <td>Per {employee.stype}</td>
                        </tr>
                        <tr>
                           <td><strong> Percentage:</strong></td>
                           <td>{employee.percentage}%</td>
                        </tr>
                        <tr>
                           <td><strong> Cost Center:</strong></td>
                           <td>{employee.center}</td>
                        </tr>
                        <tr>
                           <td><strong> Mot Part:</strong></td>
                           <td>{employee.motpart}</td>
                        </tr>
                    </thead>
                    </table>
                    <p>{employee.jobdes}</p>
                </div>
          </div>
        </div>
          <div className="bottom">
              <div className="row">
                <div className="col-md-12">
                  <div className="box">
                      <div className='d-flex'> <Link to={`/overtime/`+employee.id} className='btn btn-color btn-sm me-2'><AddIcon/>Overtime</Link> </div>
                      <hr />
                        <table className="table">
                          <thead> 
                            <tr>
                              <th>OB1</th>
                              <th>OB2</th>
                              <th>OB3</th>
                              <th>SJUK</th>
                              <th>SJOB1</th>
                              <th>SJOB2</th>
                              <th>SJOB3</th>
                              <th>Edit</th>
                            </tr>
                          </thead>
                          <tbody>
                               <tr>
                                    
                                </tr>
                                </tbody>
                            <tbody>
                            {overtime.map((overtime, index) =>{
                                return <tr key={index}>
                                        <td>{overtime.OB1}</td>
                                        <td>{overtime.OB2}</td>
                                        <td>{overtime.OB3}</td>
                                        <td>{overtime.SJUK}</td>
                                        <td>{overtime.SJOB1}</td>
                                        <td>{overtime.SJOB2}</td>
                                        <td>{overtime.SJOB3}</td>
                                        <td><Link to={`/editovertime/` + overtime.userid} className='btn btn-color btn-sm me-2'><EditIcon/></Link></td>
                                    </tr>
                                })}
                            </tbody>
                        </table>
                      </div>
                  </div>
                </div>
      </div>
     
    </div>
  )
}

export default Viewemp
