import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom';
import "./employee.scss";
function Editemployee() {

    const [data, setData] = useState({
        firstname: "",
        lastname: "",
        age: "",
        salary: "",
        position: "",
        percentage: "",
    })

    const navigate = useNavigate();

    const {id} = useParams();

    useEffect(() => {
      axios.get('http://localhost:8800/get/'+id)
      .then(res => {
          setData({...data, 
              firstname: res.data.Result[0].firstname,
              lastname: res.data.Result[0].lastname,
              age: res.data.Result[0].age,
              salary: res.data.Result[0].salary,
              position: res.data.Result[0].position,
              percentage: res.data.Result[0].percentage,
          })
      })
      .catch(err => console.log(err));
    }, [id])

    const handleSubmit = (event) => {
        event.preventDefault(); 
        axios.put('http://localhost:8800/update/'+id, data)
        .then(res => {
            if(res.data.Status === "Success"){
              navigate('/employee')
            }
        })
        .catch(err => console.log(err));

    }
  return (
    <div className='employee'>
      <div className="top">
        <div className="title">
        <h4>Update Employee</h4>
        </div>
        <div className="button">
            <Link to="/employee" className='btn btn-info'>Back</Link>
        </div>
      </div>
      <div className="bottom">
        <form action="" className='row g-3 w-50' onSubmit={handleSubmit}>
            <div className="col-6">
                <label htmlFor="inputFirstName" className='form-label'>First Name</label>
                <input type="text" className='form-control' id='inputFirstName' placeholder='Enter First Name'
                onChange={e => setData({...data, firstname: e.target.value})} name='firstname' value={data.firstname}/>
            </div>
            <div className="col-6">
                <label htmlFor="inputLastName" className='form-label'>First Name</label>
                <input type="text" className='form-control' id='inputLastName' placeholder='Enter Last Name'
                onChange={e => setData({...data, lastname: e.target.value})} name='lastname' value={data.lastname}/>
            </div>
            <div className="col-6">
                <label htmlFor="inputAge" className='form-label'>Age</label>
                <input type="number" className='form-control' id='inputAge' placeholder='Enter Age'
                onChange={e => setData({...data, age: e.target.value})} name='age' value={data.age}/>
            </div>
            <div className="col-6">
                <label htmlFor="salary" className='form-label'>Salary</label>
                <input type="number" className='form-control' id='inputAge' placeholder='Enter Age'
                onChange={e => setData({...data, salary: e.target.value})} name='salary' value={data.salary}/>
            </div>
            <div className="col-6">
                <label htmlFor="position" className='form-label'>Position</label>
                <input type="text" className='form-control' id='posation' placeholder='Enter Position'
                onChange={e => setData({...data, position: e.target.value})} name='position' value={data.position}/>
            </div>
            <div className="col-6">
                <label htmlFor="percentage" className='form-label'>Percentage</label>
                <input type="text" className='form-control' id='percentage' placeholder='Enter Percentage'
                onChange={e => setData({...data, percentage: e.target.value})} name='percentage' value={data.percentage}/>
            </div>
            <div className="col-12">
                <button type='submit' className='btn btn-primary'>Update</button>
            </div>
        </form>
        </div>
    </div>
  )
}

export default Editemployee
