import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
function NewEmployee() {
    
  const navigate = useNavigate();

  const [firstname, setfirstname] = useState('')
  const [lastname, setlastname] = useState('')
  const [age, setage] = useState('')
  const [salary, setsalary] = useState('')
  const [stype, setstype] = useState('')
  const [position, setposition] = useState('')
  const [percentage, setpercentage] = useState('')
  const [cdate, setcdate] = useState('')
  const [gender, setgender] = useState('')
  const [jobdes, setjobdes] = useState('')
  const [center, setcenter] = useState('')
  const [motpart, setmotpart] = useState('')

  const handleSubmit = (event) => {
      event.preventDefault(); 
      axios.post('http://localhost:8800/create', {firstname, lastname, age, salary, stype, position, percentage, cdate, gender,center, motpart, jobdes})
      .then(res => {
        navigate('/employee')
      })
      .catch(err => console.log(err));
  }


  return (
    <div className="employee">
      <div className="top">
        <div className="title">
        <h4>Employee</h4>
        </div>
        <div className="button">
            <Link to="/employee" className='btn btn-info'>Back</Link>
        </div>
      </div>
      <div className="bottom">
      <form action="" className='row g-3' onSubmit={handleSubmit}>
            <div className="col-6">
                <label htmlFor="inputFirstName" className='form-label'>First Name</label>
                <input type="text" className='form-control' id='inputFirstName' placeholder='Enter First Name'
                onChange={e =>  setfirstname(e.target.value)} name='firstname'/>
            </div>
            <div className="col-6">
                <label htmlFor="inputLastName" className='form-label'>Last Name</label>
                <input type="text" className='form-control' id='inputLastName' placeholder='Enter Last Name'
                onChange={e =>  setlastname(e.target.value)} name='lastname'/>
            </div>
            <div className="col-6">
                <label htmlFor="gender" className='form-label'>Gender</label>
                <select name="gender" onChange={e =>  setgender(e.target.value)} className='form-control' required>
                    <option value="">Select Gender</option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                    <option value="Other">Other</option>
                </select>
            </div>
            <div className="col-6">
                <label htmlFor="salary" className='form-label'>Salary</label>
                <input type="number" className='form-control' id='inputLastName' placeholder='Enter Salary'
                onChange={e =>  setsalary(e.target.value)} name='salary'/>
            </div>
            <div className="col-6">
                <label htmlFor="salarytype" className='form-label'>Salary Type</label>
                <select name="stype" onChange={e =>  setstype(e.target.value)} className='form-control' required>
                    <option value="">Salary Type</option>
                    <option value="Month">Per Month</option>
                    <option value="Hour">Per Hour</option>
                </select>
            </div>
            <div className="col-6">
                <label htmlFor="inputAge" className='form-label'>Age</label>
                <input type="number" className='form-control' id='inputAge' placeholder='Enter Age'
                onChange={e =>  setage(e.target.value)} name='age'/>
            </div>
            <div className="col-6">
                <label htmlFor="position" className='form-label'>Position</label>
                <input type="text" className='form-control' id='posation' placeholder='Enter Position'
                onChange={e =>  setposition(e.target.value)} name='position'/>
            </div>
            <div className="col-6">
                <label htmlFor="percentage" className='form-label'>Percentage</label>
                <input type="number" className='form-control' id='percentage' placeholder='Enter Percentage%'
                onChange={e =>  setpercentage(e.target.value)} name='percentage'/>
            </div>
            <div className="col-6">
                <label htmlFor="center" className='form-label'>Cost Center</label>
                <input type="number" className='form-control' id='center' placeholder='Enter Cost Center'
                onChange={e =>  setcenter(e.target.value)} name='center'/>
            </div>
            <div className="col-6">
                <label htmlFor="motpart" className='form-label'>Mot Part</label>
                <input type="number" className='form-control' id='motpart' placeholder='Enter Mot Part'
                onChange={e =>  setmotpart(e.target.value)} name='motpart'/>
            </div>
            <div className="col-12">
                <label htmlFor="inputeImage" className='form-label'>Job Description</label>
                <textarea className='form-control' id='jobdes'
                onChange={e =>  setjobdes(e.target.value)} name='jobdes' placeholder='Job Description here...' rows={5}></textarea>
            </div>
            <div className="col-12">
                <label htmlFor="cdate" className='form-label'>Contract Date</label>
                <input type="date" className='form-control' id='cdate'
                onChange={e =>  setcdate(e.target.value)} name='cdate'/>
            </div>
            <div className="col-12">
                <button type='submit' className='btn btn-primary'>Create</button>
            </div>
        </form>
      </div>
      
    </div>
  )
}

export default NewEmployee
