import "./timetable.scss";
import AddIcon from '@mui/icons-material/Add';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom';


function Timetable() {
  const {id} = useParams();
 
  const [datajun, setDataJun] = useState([]);
  const [totaljun, setJun] = useState();

  const [datajul, setDataJul] = useState([]);
  const [totaljul, setJul] = useState();

  const [dataaug, setDataAug] = useState([]);
  const [totalaug, setAug] = useState();

  axios.defaults.withCredentials = true;
  
 
  // June
  useEffect(() => {
    axios.get('http://localhost:8800/getjun/'+id)
    .then(res => {
      if(res.data.Status === "Success"){
        setDataJun(res.data.Result);
      }else{
        alert("Error")
      }
    })
    .catch(err => console.log(err));
  }, [id])
  useEffect(() => {
  axios.get('http://localhost:8800/totaljun/'+id)
		.then(res => {
			setJun(res.data[0].sumOfjun)
		}).catch(err => console.log(err));
  }, [id])

  // July
  useEffect(() => {
    axios.get('http://localhost:8800/getjul/'+id)
    .then(res => {
      if(res.data.Status === "Success"){
        setDataJul(res.data.Result);
      }else{
        alert("Error")
      }
    })
    .catch(err => console.log(err));
  }, [id])
  useEffect(() => {
  axios.get('http://localhost:8800/totaljul/'+id)
		.then(res => {
			setJul(res.data[0].sumOfjul)
		}).catch(err => console.log(err));
  }, [id])

 
  // Aug
  useEffect(() => {
    axios.get('http://localhost:8800/getaug/'+id)
    .then(res => {
      if(res.data.Status === "Success"){
        setDataAug(res.data.Result);
      }else{
        alert("Error")
      }
    })
    .catch(err => console.log(err));
  }, [id])
  useEffect(() => {
  axios.get('http://localhost:8800/totalaug/'+id)
		.then(res => {
			setAug(res.data[0].sumOfaug)
		}).catch(err => console.log(err));
  }, [id])

  ///
  const handleDelete = (id) => {
    axios.delete('http://localhost:8800/deletetable/'+id)
    .then(res => {
      if(res.data.Status === "Success") {
        window.location.reload(true)
      }
    })
    .catch(err => console.log(err));
  }
  return (
    <div className="timetable">
      <div className="top">
        <div className="title">
        <h4>Time Table / Per Hours</h4>
        </div>
        <div className="button">
            <Link to="/tablelist" className='btn btn-back btn-sm me-2'><ArrowBackIosIcon />Back</Link>
        </div>
      </div><hr />
      <div className="row">
            <div className="col-md-4">
              <div className="box">
                  <div className='d-flex'> June  / <Link to={`/Jun/` + id} className="btn-add"> <AddIcon/>Add Record</Link> </div> <hr />
                    <table className="table">
                      <thead> 
                        <tr>
                          <th>Day</th>
                          <th>Week</th>
                          <th>Start</th>
                          <th>Finish</th>
                          <th>Difference</th>
                          <th>Del</th>
                        </tr>
                      </thead>
                      <tbody>
                            {datajun.map((timetable, index) =>{
                                return <tr key={index}>
                                        <td style={timetable.type === "Normal" ? {backgroundColor:'rgba(7, 190, 7, 0.648)', color:'#ffffff'} : (timetable.type === "Holiday" ? {backgroundColor:'rgba(255, 0, 0, 0.438)', color:'#ffffff'} : {backgroundColor:'#008cff5a', color:'#ffffff'}) }> {timetable.day}</td>
                                        <td>{timetable.week}</td>
                                        <td>{timetable.starthour}</td>
                                        <td>{timetable.endhour}</td>
                                        <td>{Math.round(timetable.difference)}</td>
                                        <td><button onClick={e => handleDelete(timetable.id)} className='btn btn-sm btn-danger'><DeleteForeverIcon className="icon"/></button></td>
                                    </tr>
                                })}
                                <tr>
                                      <td colspan="4"><b>Total:</b> </td>
                                      <td><b>{Math.round(totaljun)} Hours</b></td>
                                      <td></td>
                                </tr>
                            </tbody>
                    </table>
                  </div>
            </div>
            <div className="col-md-4">
              <div className="box">
                  <div className='d-flex'> July  / <Link to={`/Jul/` + id} className="btn-add"> <AddIcon/>Add Record</Link> </div> <hr />
                    <table className="table">
                      <thead> 
                        <tr>
                          <th>Day</th>
                          <th>Week</th>
                          <th>Start</th>
                          <th>Finish</th>
                          <th>Difference</th>
                          <th>Del</th>
                        </tr>
                      </thead>
                      <tbody>
                            {datajul.map((timetable, index) =>{
                                return <tr key={index}>
                                        <td style={timetable.type === "Normal" ? {backgroundColor:'rgba(7, 190, 7, 0.648)', color:'#ffffff'} : (timetable.type === "Holiday" ? {backgroundColor:'rgba(255, 0, 0, 0.438)', color:'#ffffff'} : {backgroundColor:'#008cff5a', color:'#ffffff'}) }> {timetable.day}</td>
                                        <td>{timetable.week}</td>
                                        <td>{timetable.starthour}</td>
                                        <td>{timetable.endhour}</td>
                                        <td>{Math.round(timetable.difference)}</td>
                                        <td><button onClick={e => handleDelete(timetable.id)} className='btn btn-sm btn-danger'><DeleteForeverIcon className="icon"/></button></td>
                                    </tr>
                                })}
                                <tr>
                                      <td colspan="4"><b>Total:</b> </td>
                                      <td><b>{Math.round(totaljul)} Hours</b></td>
                                      <td></td>
                                </tr>
                            </tbody>
                    </table>
                  </div>
            </div>
            <div className="col-md-4">
              <div className="box">
                  <div className='d-flex'> August  / <Link to={`/Aug/` + id} className="btn-add"> <AddIcon/>Add Record</Link> </div> <hr />
                    <table className="table">
                      <thead> 
                        <tr>
                          <th>Day</th>
                          <th>Week</th>
                          <th>Start</th>
                          <th>Finish</th>
                          <th>Difference</th>
                          <th>Del</th>
                        </tr>
                      </thead>
                      <tbody>
                            {dataaug.map((timetable, index) =>{
                                return <tr key={index}>
                                        <td style={timetable.type === "Normal" ? {backgroundColor:'rgba(7, 190, 7, 0.648)', color:'#ffffff'} : (timetable.type === "Holiday" ? {backgroundColor:'rgba(255, 0, 0, 0.438)', color:'#ffffff'} : {backgroundColor:'#008cff5a', color:'#ffffff'}) }> {timetable.day}</td>
                                        <td>{timetable.week}</td>
                                        <td>{timetable.starthour}</td>
                                        <td>{timetable.endhour}</td>
                                        <td>{Math.round(timetable.difference)}</td>
                                        <td><button onClick={e => handleDelete(timetable.id)} className='btn btn-sm btn-danger'><DeleteForeverIcon className="icon"/></button></td>
                                    </tr>
                                })}
                                <tr>
                                      <td colspan="4"><b>Total:</b> </td>
                                      <td><b>{Math.round(totalaug)} Hours</b></td>
                                      <td></td>
                                </tr>
                            </tbody>
                    </table>
                  </div>
            </div>
      </div>
    </div>
  )
}

export default Timetable

