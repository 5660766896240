import React, { useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';

function Overtime() {
  const {id} = useParams();
  const navigate = useNavigate();
  const [OB1, setOB1] = useState('')
  const [OB2, setOB2] = useState('')
  const [OB3, setOB3] = useState('')
  const [SJUK, setSJUK] = useState('')
  const [SJOB1, setSJOB1] = useState('')
  const [SJOB2, setSJOB2] = useState('')
  const [SJOB3, setSJOB3] = useState('')

  const handleSubmit = (event) => {
      event.preventDefault(); 
      axios.post('http://localhost:8800/overtime/'+id, {OB1, OB2, OB3, SJUK ,SJOB1 ,SJOB2 ,SJOB3})
      .then(res => {
        navigate('/viewemp/'+id)
      })
      .catch(err => console.log(err));
  }

  return (
    <div className="employee">
      <div className="top">
            <div className="title">
                <h4>Overtime Price</h4>
            </div>
            <div className="button">
                <Link to={`/viewemp/`+id} className='btn btn-info btn-sm me-2'>Back</Link>
            </div>
      </div>
      <div className="bottom">
        <form action="" className='row g-3' onSubmit={handleSubmit}>
                <div className="col-4">
                    <label htmlFor="OB1" className='form-label'>OB1</label>
                    <input type="number" className='form-control' id='OB1' placeholder='Enter OB1'
                    onChange={e =>  setOB1(e.target.value)} name='OB1'/>
                </div>
                <div className="col-4">
                    <label htmlFor="OB2" className='form-label'>OB2</label>
                    <input type="number" className='form-control' id='OB2' placeholder='Enter OB2'
                    onChange={e =>  setOB2(e.target.value)} name='OB2'/>
                </div>
                <div className="col-4">
                    <label htmlFor="OB3" className='form-label'>OB3</label>
                    <input type="number" className='form-control' id='OB3' placeholder='Enter OB3'
                    onChange={e =>  setOB3(e.target.value)} name='OB3'/>
                </div>
                <div className="col-6">
                    <label htmlFor="SJUK" className='form-label'>SJUK</label>
                    <input type="number" className='form-control' id='SJUK' placeholder='Enter SJUK'
                    onChange={e =>  setSJUK(e.target.value)} name='SJUK'/>
                </div>
                <div className="col-6">
                    <label htmlFor="SJOB1" className='form-label'>SJOB1</label>
                    <input type="number" className='form-control' id='SJOB1' placeholder='Enter SJOB1'
                    onChange={e =>  setSJOB1(e.target.value)} name='SJOB1'/>
                </div>
                <div className="col-6">
                    <label htmlFor="SJOB2" className='form-label'>SJOB2</label>
                    <input type="number" className='form-control' id='SJOB2' placeholder='Enter SJOB2'
                    onChange={e =>  setSJOB2(e.target.value)} name='SJOB2'/>
                </div>
                <div className="col-6">
                    <label htmlFor="SJOB3" className='form-label'>SJOB3</label>
                    <input type="number" className='form-control' id='SJOB3' placeholder='Enter SJOB3'
                    onChange={e =>  setSJOB3(e.target.value)} name='SJOB3'/>
                </div>
                <div className="col-12">
                    <button type='submit' className='btn btn-primary'>Submit</button>
                </div>
            </form>
      </div>
      
    </div>
  )
}

export default Overtime
