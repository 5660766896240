import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';

function EditTax() {

  const navigate = useNavigate();
  const {id} = useParams();

  const [data, setData] = useState({
    holiday: "",
    taxrate: "",
})

useEffect(() => {
  axios.get('http://localhost:8800/gettax/'+id)
  .then(res => {
      setData({...data, 
        holiday: res.data.Result[0].holiday,
        taxrate: res.data.Result[0].taxrate,
      })
  })
  .catch(err => console.log(err));
}, [])

const handleSubmit = (event) => {
    event.preventDefault(); 
    axios.put('http://localhost:8800/updatetax/'+id, data)
    .then(res => {
        if(res.data.Status === "Success"){
          navigate('/taxing/')
        }
    })
    .catch(err => console.log(err));

}
  return (
    <div className="employee">
      <div className="top">
            <div className="title">
                <h4>Edit TAX</h4>
            </div>
            <div className="button">
                <Link to="/taxing" className='btn btn-info'>Back</Link>
            </div>
      </div>
      <div className="bottom">
        <form action="" className='row g-3' onSubmit={handleSubmit}>
                <div className="col-3">
                    <label htmlFor="holiday" className='form-label'>Holiday</label>
                    <input type="number" className='form-control' placeholder='Enter Holiday' onChange={e => setData({...data, holiday: e.target.value})} name='holiday' value={data.holiday} />
                </div>
                <div className="col-3">
                    <label htmlFor="taxrate" className='form-label'>Active Code</label>
                    <input type="number" className='form-control' placeholder='Enter Taxrate' onChange={e => setData({...data, taxrate: e.target.value})} name='taxrate' value={data.taxrate} />
                </div>
                <div className="col-12">
                    <button type='submit' className='btn btn-primary'>Update</button>
                </div>
            </form>
      </div>
      
    </div>
  )
}

export default EditTax
