import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';

function Editrating() {

  const navigate = useNavigate();
  const {id} = useParams();

  const [data, setData] = useState({
    account: "",
    ocode: "",
    acode: "",
    pcode: "",
    jan: "",
    feb: "",
    mar: "",
    apr: "",
    may: "",
    jun: "",
    jul: "",
    aug: "",
    sep: "",
    oct: "",
    nov: "",
    dec: "",
})

useEffect(() => {
  axios.get('http://localhost:8800/getrating/'+id)
  .then(res => {
      setData({...data, 
          account: res.data.Result[0].account,
          ocode: res.data.Result[0].ocode,
          acode: res.data.Result[0].acode,
          pcode: res.data.Result[0].pcode,
          jan: res.data.Result[0].jan,
          feb: res.data.Result[0].feb,
          mar: res.data.Result[0].mar,
          apr: res.data.Result[0].apr,
          may: res.data.Result[0].may,
          jun: res.data.Result[0].jun,
          jul: res.data.Result[0].jul,
          aug: res.data.Result[0].aug,
          sep: res.data.Result[0].sep,
          oct: res.data.Result[0].oct,
          nov: res.data.Result[0].nov,
          dec: res.data.Result[0].dec,
      })
  })
  .catch(err => console.log(err));
}, [])

const handleSubmit = (event) => {
    event.preventDefault(); 
    axios.put('http://localhost:8800/updaterate/'+id, data)
    .then(res => {
        if(res.data.Status === "Success"){
          navigate('/rating')
        }
    })
    .catch(err => console.log(err));

}
  return (
    <div className="employee">
      <div className="top">
            <div className="title">
                <h4>Add Rate to Employee</h4>
            </div>
            <div className="button">
                <Link to="/employee" className='btn btn-info'>Back</Link>
            </div>
      </div>
      <div className="bottom">
        <form action="" className='row g-3' onSubmit={handleSubmit}>
                <div className="col-2">
                    <label htmlFor="ocode" className='form-label'>Account Number</label>
                    <input type="number" className='form-control' placeholder='Enter Opration' onChange={e => setData({...data, account: e.target.value})} name='account' value={data.account} />
                </div>
                <div className="col-2">
                    <label htmlFor="ocode" className='form-label'>Opration Code</label>
                    <input type="number" className='form-control' placeholder='Enter Opration' onChange={e => setData({...data, ocode: e.target.value})} name='ocode' value={data.ocode} />
                </div>
                <div className="col-2">
                    <label htmlFor="feb" className='form-label'>Active Code</label>
                    <input type="number" className='form-control' placeholder='Enter Active Code' onChange={e => setData({...data, acode: e.target.value})} name='acode' value={data.acode} />
                </div>
                <div className="col-2">
                    <label htmlFor="Mar" className='form-label'>Project Code</label>
                    <input type="number" className='form-control' placeholder='Enter Project Code' onChange={e => setData({...data, pcode: e.target.value})} name='pcode' value={data.pcode} />
                </div>
                <div className="col-2">
                    <label htmlFor="jan" className='form-label'>Jan</label>
                    <input type="number" className='form-control' placeholder='Enter Jan' onChange={e => setData({...data, jan: e.target.value})} name='jan' value={data.jan} />
                </div>
                <div className="col-2">
                    <label htmlFor="feb" className='form-label'>Feb</label>
                    <input type="number" className='form-control' placeholder='Enter Feb' onChange={e => setData({...data, feb: e.target.value})} name='feb' value={data.feb} />
                </div>
                <div className="col-2">
                    <label htmlFor="Mar" className='form-label'>Mar</label>
                    <input type="number" className='form-control' placeholder='Enter March' onChange={e => setData({...data, mar: e.target.value})} name='mar' value={data.mar} />
                </div>
                <div className="col-2">
                    <label htmlFor="Apr" className='form-label'>Apr</label>
                    <input type="number" className='form-control' placeholder='Enter April' onChange={e => setData({...data, apr: e.target.value})} name='apr' value={data.apr} />
                </div>
                <div className="col-2">
                    <label htmlFor="May" className='form-label'>May</label>
                    <input type="number" className='form-control' placeholder='Enter May' onChange={e => setData({...data, may: e.target.value})} name='may' value={data.may} />
                </div>
                <div className="col-2">
                    <label htmlFor="Jun" className='form-label'>Jun</label>
                    <input type="number" className='form-control' placeholder='Enter Jun' onChange={e => setData({...data, jun: e.target.value})} name='jun' value={data.jun} />
                </div>
                <div className="col-2">
                    <label htmlFor="Jul" className='form-label'>Jul</label>
                    <input type="number" className='form-control' placeholder='Enter Jul' onChange={e => setData({...data, jul: e.target.value})} name='jul' value={data.jul} />
                </div>
                <div className="col-2">
                    <label htmlFor="Aug" className='form-label'>Aug</label>
                    <input type="number" className='form-control' placeholder='Enter Aug' onChange={e => setData({...data, aug: e.target.value})} name='aug' value={data.aug} />
                </div>
                <div className="col-2">
                    <label htmlFor="Sep" className='form-label'>Sep</label>
                    <input type="number" className='form-control' placeholder='Enter Sep' onChange={e => setData({...data, sep: e.target.value})} name='sep' value={data.sep} />
                </div>
                <div className="col-2">
                    <label htmlFor="Oct" className='form-label'>Oct</label>
                    <input type="number" className='form-control' placeholder='Enter Oct' onChange={e => setData({...data, oct: e.target.value})} name='oct' value={data.oct} />
                </div>
                <div className="col-2">
                    <label htmlFor="Nov" className='form-label'>Nov</label>
                    <input type="number" className='form-control' placeholder='Enter Nov' onChange={e => setData({...data, nov: e.target.value})} name='nov' value={data.nov} />
                </div>
                <div className="col-2">
                    <label htmlFor="Dec" className='form-label'>Dec</label>
                    <input type="number" className='form-control' placeholder='Enter Dec' onChange={e => setData({...data, dec: e.target.value})} name='dec' value={data.dec} />
                </div>
                <div className="col-12">
                    <button type='submit' className='btn btn-primary'>Update</button>
                </div>
            </form>
      </div>
      
    </div>
  )
}

export default Editrating
