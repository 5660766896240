import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';
import EditIcon from '@mui/icons-material/Edit';
import "./employee.scss";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

function Rating() {
  
  const {id} = useParams();
  const [account, setAccount] = useState('')
  const [ocode, setOcode] = useState('')
  const [acode, setAcode] = useState('')
  const [pcode, setPcode] = useState('')
  const [jan, setJan] = useState('')
  const [employee, setEmployee] = useState([])

  useEffect(()=> {
    axios.get('http://localhost:8800/get/'+id)
    .then(res => setEmployee(res.data.Result[0]))
    .catch(err => console.log(err));
});

  const handleSubmitAll = (event) => {
      event.preventDefault(); 
      axios.post('http://localhost:8800/rateall/'+id, {account, ocode, acode, pcode, jan })
      .then(res => {
        window.location.reload(true)
      })
      .catch(err => console.log(err));
  }
  
  const [rate, setRate] = useState([])
  useEffect(() => {
    axios.get('http://localhost:8800/getrate/'+id)
    .then(res => {
      if(res.data.Status === "Success"){
        setRate(res.data.Result);
      }else{
        alert("Error")
      }
    })
    .catch(err => console.log(err));
  }, [id])
  const handleDelete = (id) => {
    axios.delete('http://localhost:8800/delrate/'+id)
    .then(res => {
      if(res.data.Status === "Success") {
        window.location.reload(true)
      }
    })
    .catch(err => console.log(err));
  }

  return (
    <div className="employee">
      <div className="top">
            <div className="title">
                <h4>Rating</h4>
            </div>
            <div className="button">
                <Link to="/rating" className='btn btn-back btn-sm me-2'>Back</Link>
            </div>
      </div>
      <hr />
      <div className="bottom">
        <h3>Fill All</h3><hr />
        <form action="" className='row g-3' onSubmit={handleSubmitAll}>
                <div className="col-2">
                    <input type="number" className='form-control' id='ocode' placeholder='Account Number'
                    onChange={e =>  setAccount(e.target.value)} name='account'/>
                </div>
                <div className="col-2">
                    <input type="number" className='form-control' id='ocode' placeholder='Opration Code'
                    onChange={e =>  setOcode(e.target.value)} name='ocode'/>
                </div>
                <div className="col-2">
                    <input type="number" className='form-control' id='acode' placeholder='Active Code'
                    onChange={e =>  setAcode(e.target.value)} name='acode'/>
                </div>
                <div className="col-2">
                    <input type="number" className='form-control' id='pcode' placeholder='Project Code'
                    onChange={e =>  setPcode(e.target.value)} name='pcode'/>
                </div>
                <div className="col-2">
                    <input type="number" className='form-control' id='all' placeholder='Percentage/%'
                    onChange={e =>  setJan(e.target.value)} name='jan'/>
                </div>
                <div className="col-2">
                    <button type='submit' className='btn btn-primary'>Submit</button>
                </div>
            </form>
      </div>

      <div className="bottom">
              <div className="row">
                <div className="col-md-12">
                  <div className="box">
                        <table className="table">
                          <thead> 
                            <tr>
                              <th>Account</th>
                              <th>Opraton Code</th>
                              <th>Active Code</th>
                              <th>Project Code</th>
                              <th>Cost Center</th>
                              <th>Mot Part</th>
                              <th>Jan</th>
                              <th>Feb</th>
                              <th>Mar</th>
                              <th>Apr</th>
                              <th>May</th>
                              <th>Jun</th>
                              <th>Jul</th>
                              <th>Aug</th>
                              <th>Sep</th>
                              <th>Oct</th>
                              <th>Nov</th>
                              <th>Dec</th>
                              <th>Edit</th>
                            </tr>
                          </thead>
                            <tbody>
                            {rate.map((rate, index) =>{
                                return <tr key={index}>
                                        
                                        <td>{rate.account}</td>
                                        <td>{rate.ocode}</td>
                                        <td>{rate.acode}</td>
                                        <td>{rate.pcode}</td>
                                        <td>{employee.center}</td>
                                        <td>{employee.motpart}</td>
                                        <td style={rate.jan === "100" ? {backgroundColor:'#01C15E', color:'#ffffff'} : (rate.jan >= "50"? {backgroundColor:'#1580ee', color:'#ffffff'} : {backgroundColor:'#d7321a', color:'#ffffff'}) }>{rate.jan}%</td>
                                        <td style={rate.feb === "100" ? {backgroundColor:'#01C15E', color:'#ffffff'} : (rate.feb >= "50" ? {backgroundColor:'#1580ee', color:'#ffffff'} : {backgroundColor:'#d7321a', color:'#ffffff'})}>{rate.feb}%</td>
                                        <td style={rate.mar === "100" ? {backgroundColor:'#01C15E', color:'#ffffff'} : (rate.mar >= "50" ? {backgroundColor:'#1580ee', color:'#ffffff'} : {backgroundColor:'#d7321a', color:'#ffffff'})}>{rate.mar}%</td>
                                        <td style={rate.apr === "100" ? {backgroundColor:'#01C15E', color:'#ffffff'} : (rate.apr >= "50" ? {backgroundColor:'#1580ee', color:'#ffffff'} : {backgroundColor:'#d7321a', color:'#ffffff'})}>{rate.apr}%</td>
                                        <td style={rate.may === "100" ? {backgroundColor:'#01C15E', color:'#ffffff'} : (rate.may >= "50" ? {backgroundColor:'#1580ee', color:'#ffffff'} : {backgroundColor:'#d7321a', color:'#ffffff'})}>{rate.may}%</td>
                                        <td style={rate.jun === "100" ? {backgroundColor:'#01C15E', color:'#ffffff'} : (rate.jun >= "50" ? {backgroundColor:'#1580ee', color:'#ffffff'} : {backgroundColor:'#d7321a', color:'#ffffff'})}>{rate.jun}%</td>
                                        <td style={rate.jul === "100" ? {backgroundColor:'#01C15E', color:'#ffffff'} : (rate.jul >= "50" ? {backgroundColor:'#1580ee', color:'#ffffff'} : {backgroundColor:'#d7321a', color:'#ffffff'})}>{rate.jul}%</td>
                                        <td style={rate.aug === "100" ? {backgroundColor:'#01C15E', color:'#ffffff'} : (rate.aug >= "50" ? {backgroundColor:'#1580ee', color:'#ffffff'} : {backgroundColor:'#d7321a', color:'#ffffff'})}>{rate.aug}%</td>
                                        <td style={rate.sep === "100" ? {backgroundColor:'#01C15E', color:'#ffffff'} : (rate.sep >= "50" ? {backgroundColor:'#1580ee', color:'#ffffff'} : {backgroundColor:'#d7321a', color:'#ffffff'})}>{rate.sep}%</td>
                                        <td style={rate.oct === "100" ? {backgroundColor:'#01C15E', color:'#ffffff'} : (rate.oct >= "50" ? {backgroundColor:'#1580ee', color:'#ffffff'} : {backgroundColor:'#d7321a', color:'#ffffff'})}>{rate.oct}%</td>
                                        <td style={rate.nov === "100" ? {backgroundColor:'#01C15E', color:'#ffffff'} : (rate.nov >= "50" ? {backgroundColor:'#1580ee', color:'#ffffff'} : {backgroundColor:'#d7321a', color:'#ffffff'})}>{rate.nov}%</td>
                                        <td style={rate.dec === "100" ? {backgroundColor:'#01C15E', color:'#ffffff'} : (rate.dec >= "50" ? {backgroundColor:'#1580ee', color:'#ffffff'} : {backgroundColor:'#d7321a', color:'#ffffff'})}>{rate.dec}%</td>
                                        <td><Link to={`/updaterate/`+rate.id} className='btn btn-color btn-sm me-2'><EditIcon/></Link>
                                            <button onClick={e => handleDelete(rate.id)} className='btn btn-sm btn-danger'><DeleteForeverIcon className="icon"/></button>
                                        </td>
                                    </tr>
                                })}
                            </tbody>
                        </table><hr />
                        <strong>Default Percentage {employee.percentage}%</strong>
                      </div>
                  </div>
                </div>
      </div>
      
    </div>
  )
}

export default Rating
