import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom';
import "./timetable.scss";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';


function Monthreport() {
  const {id} = useParams();
  const [employee, setEmployee] = useState([])
  const [taxing, setTAX] = useState([])

  useEffect(()=> {
      axios.get('http://localhost:8800/get/'+id)
      .then(res => setEmployee(res.data.Result[0]))
      .catch(err => console.log(err));
  });
  
  const [rate, setRate] = useState([])
  useEffect(() => {
    axios.get('http://localhost:8800/getrate/'+id)
    .then(res => {
      if(res.data.Status === "Success"){
        setRate(res.data.Result);
      }else{
        alert("Error")
      }
    })
    .catch(err => console.log(err));
  }, [id])

useEffect(() => {
  axios.get('http://localhost:8800/gettax/')
  .then(res => {
    if(res.data.Status === "Success"){
      setTAX(res.data.Result[0]);
    }else{
      alert("Error")
    }
  })
  .catch(err => console.log(err));
}, [])

     
  return (
    <div className="timetable">
      <div className="top">
        <div className="title">
        <h4>Timetable Reports of {employee.firstname} {employee.lastname}</h4>
        </div>
        <div className="button">
            <Link to="/reports" className='btn btn-back btn-sm me-2'><ArrowBackIosIcon />Back</Link>
        </div>
      </div><hr />
          <div className="row">
              
              <div className="col-12">
                <div className="box">
                <table className='table '>
                        <thead>
                        <tr>
                              <th>Base Salary</th>
                              <th>Account</th>
                              <th>Opraton Code</th>
                              <th>Active Code</th>
                              <th>Project Code</th>
                              <th>Cost Center</th>
                              <th>Mot Part</th>
                              <th>Jan</th>
                              <th>Feb</th>
                              <th>Mar</th>
                              <th>Apr</th>
                              <th>May</th>
                              <th>Jun</th>
                              <th>Jul</th>
                              <th>Aug</th>
                              <th>Sep</th>
                              <th>Oct</th>
                              <th>Nov</th>
                              <th>Dec</th>
                              <th>Normal Salary</th>
                              <th>Holiday</th>
                              <th>TAX</th>
                              <th>Total Salary</th>
                            </tr>
                        </thead>
                        <tbody>
                            {rate.map((rate, index) =>{
                                return <tr key={index}>
                                        <td>{employee.salary}</td>
                                        <td>{rate.account}</td>
                                        <td>{rate.ocode}</td>
                                        <td>{rate.acode}</td>
                                        <td>{rate.pcode}</td>
                                        <td>{employee.center}</td>
                                        <td>{employee.motpart}</td>
                                        <td>{rate.jan * employee.salary / 100}</td>
                                        <td>{rate.feb * employee.salary / 100}</td>
                                        <td>{rate.mar * employee.salary / 100}</td>
                                        <td>{rate.apr * employee.salary / 100}</td>
                                        <td>{rate.may * employee.salary / 100}</td>
                                        <td>{rate.jun * employee.salary / 100}</td>
                                        <td>{rate.jul * employee.salary / 100}</td>
                                        <td>{rate.aug * employee.salary / 100}</td>
                                        <td>{rate.sep * employee.salary / 100}</td>
                                        <td>{rate.oct * employee.salary / 100}</td>
                                        <td>{rate.nov * employee.salary / 100}</td>
                                        <td>{rate.dec * employee.salary / 100}</td>
                                        <td>
                                            {employee.salary * rate.jan / 100 + (employee.salary * rate.feb / 100) + (employee.salary * rate.mar / 100) + (employee.salary * rate.apr / 100) + (employee.salary * rate.may / 100) + 
                                              (employee.salary * rate.jun / 100) + (employee.salary * rate.jul / 100) + (employee.salary * rate.aug / 100) + (employee.salary * rate.sep / 100) + (employee.salary * rate.oct / 100) + 
                                              (employee.salary * rate.nov / 100) + (employee.salary * rate.dec / 100) }
                                        </td>
                                        <td>
                                            {(employee.salary * rate.jan / 100 + (employee.salary * rate.feb / 100) + (employee.salary * rate.mar / 100) + (employee.salary * rate.apr / 100) + (employee.salary * rate.may / 100) + 
                                              (employee.salary * rate.jun / 100) + (employee.salary * rate.jul / 100) + (employee.salary * rate.aug / 100) + (employee.salary * rate.sep / 100) + (employee.salary * rate.oct / 100) + 
                                              (employee.salary * rate.nov / 100) + (employee.salary * rate.dec / 100)) * taxing.holiday / 100 }
                                        </td>
                                        <td>
                                            {(employee.salary * rate.jan / 100 + (employee.salary * rate.feb / 100) + (employee.salary * rate.mar / 100) + (employee.salary * rate.apr / 100) + (employee.salary * rate.may / 100) + 
                                              (employee.salary * rate.jun / 100) + (employee.salary * rate.jul / 100) + (employee.salary * rate.aug / 100) + (employee.salary * rate.sep / 100) + (employee.salary * rate.oct / 100) + 
                                              (employee.salary * rate.nov / 100) + (employee.salary * rate.dec / 100)) * taxing.taxrate / 100 }
                                        </td>
                                        <td>
                                        {(employee.salary * rate.jan / 100 + (employee.salary * rate.feb / 100) + (employee.salary * rate.mar / 100) + (employee.salary * rate.apr / 100) + (employee.salary * rate.may / 100) + 
                                              (employee.salary * rate.jun / 100) + (employee.salary * rate.jul / 100) + (employee.salary * rate.aug / 100) + (employee.salary * rate.sep / 100) + (employee.salary * rate.oct / 100) + 
                                              (employee.salary * rate.nov / 100) + (employee.salary * rate.dec / 100)) * taxing.holiday / 100  + (employee.salary * rate.jan / 100 + (employee.salary * rate.feb / 100) + (employee.salary * rate.mar / 100) + (employee.salary * rate.apr / 100) + (employee.salary * rate.may / 100) + 
                                              (employee.salary * rate.jun / 100) + (employee.salary * rate.jul / 100) + (employee.salary * rate.aug / 100) + (employee.salary * rate.sep / 100) + (employee.salary * rate.oct / 100) + 
                                              (employee.salary * rate.nov / 100) + (employee.salary * rate.dec / 100)) + (employee.salary * rate.jan / 100 + (employee.salary * rate.feb / 100) + (employee.salary * rate.mar / 100) + (employee.salary * rate.apr / 100) + (employee.salary * rate.may / 100) + 
                                              (employee.salary * rate.jun / 100) + (employee.salary * rate.jul / 100) + (employee.salary * rate.aug / 100) + (employee.salary * rate.sep / 100) + (employee.salary * rate.oct / 100) + 
                                              (employee.salary * rate.nov / 100) + (employee.salary * rate.dec / 100)) * taxing.taxrate / 100}
                                        </td>
                                    </tr>
                                })}
                            </tbody>
                </table>
                </div>
              </div>
          </div>
    </div>
    
  )
}

export default Monthreport
