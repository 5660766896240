import { useEffect, useState } from "react";
import "./timetable.scss";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import AssessmentIcon from '@mui/icons-material/Assessment';
import axios from 'axios'
import { Link } from "react-router-dom"

function Reports () {
  const [data, setData] = useState([])
  const [overtime, setovertime] = useState([])

  axios.defaults.withCredentials = true;
  useEffect(() => {
    axios.get('http://localhost:8800/getEmployees')
    .then(res => {
      if(res.data.Status === "Success"){
        setData(res.data.Result);
      }else{
        alert("Error")
      }
    })
    .catch(err => console.log(err));
  }, []);
 
  useEffect(() => {
    axios.get('http://localhost:8800/getovertimes/')
    .then(res => {
      if(res.data.Status === "Success"){
        setovertime(res.data.Result);
      }else{
        alert("Error")
      }
    })
    .catch(err => console.log(err));
  }, [])
  
  return (
    <div className="employee">
      <div className="top">
        <div className="title">
        <h4>Reports</h4>
        </div>
        <div className="button">
            <Link to="/" className='btn btn-primary btn-sm me-2'><ArrowBackIosIcon />Back</Link>
        </div>
      </div>
      <div className="bottom">
        <table className='table'>
            <thead>
              <tr className="tr">
                <th>ID</th>
                <th>Full Name</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
          {data.map((employee, index) =>{
            return <tr key={index}>
                  <td>{employee.id}</td>
                  <td>{employee.firstname} {employee.lastname}</td>
                  <td>
                      {employee.stype === "Month" ? <Link to={`/monthreport/` + employee.id} className='btn btn-color btn-sm me-2'><AssessmentIcon className="icon"/> Details</Link> :  <Link to={`/report/` + employee.id} className='btn btn-color btn-sm me-2'><AssessmentIcon className="icon"/> Details</Link>} 
                  </td>
                </tr>
              })}
          </tbody>
        </table>
      </div>
      
    </div>
  )
}
export default Reports

