import "./navbar.scss";
import DarkModeIcon from '@mui/icons-material/DarkMode';
import WbSunnyIcon from '@mui/icons-material/WbSunny';
import LogoutIcon from '@mui/icons-material/Logout';
import { useContext } from "react";
import { Link } from "react-router-dom";
import { DarkModeContext } from "../../context/darkModeContext";

function Navbar () {
    const {toggle, darkMode} = useContext(DarkModeContext);
    return (
        <div className="navbars">
            <div className="wrapper">
                <div className="top">
                <Link to="/" style={{textDecoration: "none"}}>
                    <span className="logo">Avvikelse Analys</span>
                </Link>
                </div> 
                <div className="items">
                    <div className="item">
                        {darkMode ? <WbSunnyIcon className="icon" onClick={toggle}/> : <DarkModeIcon onClick={toggle}/>}
                    </div> 
                    <div className="item">
                        Administrator
                    </div> 
                </div>   
            </div>   
        </div>
    )
}
export default Navbar
