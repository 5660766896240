import { useEffect, useState } from "react"
import "./home.scss"
import axios from "axios";
import { useNavigate } from "react-router-dom";

function Home () {
  const [auth, setAuth] = useState(false);
  const [name, setName] = useState('');
  const navigate = useNavigate();
  axios.defaults.withCredentials = true;

  useEffect(() => {
    axios.get('https://aaaa.psmile.xyz/api/')
    .then(res => {
      if(res.data.Status === "Success") {
        setAuth(true)
        setName(res.data.name)
      } else {
        setAuth(false)
        navigate('/login')
      };
    })
    .then(err => console.log(err));

  }, [navigate])

  return (
    <div className="home">
      <div className="box">
      {
        auth ? 
        <div>
          Welcome: {name}
        </div>
        :
      
          <div>Test</div>
        
      }
      </div>
        
    </div>
  )
}

export default Home