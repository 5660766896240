import { useEffect, useState } from "react";
import "./employee.scss";
import AddIcon from '@mui/icons-material/Add';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import axios from 'axios'
import { Link } from "react-router-dom"

function Employeelist () {
  const [data, setData] = useState([])
  axios.defaults.withCredentials = true;
  useEffect(() => {
    axios.get('http://localhost:8800/getEmployees')
    .then(res => {
      if(res.data.Status === "Success"){
        setData(res.data.Result);
      }else{
        alert("Error")
      }
    })
    .catch(err => console.log(err));
  }, []);

  const handleDelete = (id) => {
    axios.delete('http://localhost:8800/delete/'+id)
    .then(res => {
      if(res.data.Status === "Success") {
        window.location.reload(true)
      }
    })
    .catch(err => console.log(err));
  }
  return (
    <div className="employee">
      <div className="top">
        <div className="title">
        <h4>Employee</h4>
        </div>
        <div className="button">
            <Link to="/create" className='btn btn-primary btn-sm me-2'><AddIcon/>Add New</Link>
        </div>
      </div>
      <div className="bottom">
        <table className='table'>
            <thead>
              <tr className="tr">
                <th>ID</th>
                <th>Full Name</th>
                <th>Gender</th>
                <th>Age</th>
                <th>Salary</th>
                <th>Salary Type</th>
                <th>Percentage</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
          {data.map((employee, index) =>{
            return <tr key={index}>
                  <td>{employee.id}</td>
                  <td>{employee.firstname} {employee.lastname}</td>
                  <td>{employee.gender}</td>
                  <td>{employee.age}</td>
                  <td>{employee.salary}</td>
                  <td>Per {employee.stype }</td>
                  <td>{employee.percentage}%</td>
                  <td>
                      <Link to={`/viewemp/` + employee.id} className='btn btn-color btn-sm me-2 text-white'><VisibilityIcon className="icon"/>View</Link>
                      <button onClick={e => handleDelete(employee.id)} className='btn btn-sm btn-danger'><DeleteForeverIcon className="icon"/>Delete</button>
                  </td>
                </tr>
              })}
          </tbody>
        </table>
      </div>
      
    </div>
  )
}
export default Employeelist
