
function Overview() {


  return (
    <div className="timetable">
      <p>Coming Soon..</p>
    </div>
  )
}

export default Overview
