import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom';
import "./employee.scss";
function Editovertime() {

    const [data, setData] = useState({
        OB1: "",
        OB2: "",
        OB3: "",
        SJUK: "",
        SJOB1: "",
        SJOB2: "",
        SJOB3: "",
    })

    const navigate = useNavigate();

    const {id} = useParams();

    useEffect(() => {
      axios.get('http://localhost:8800/getovertime/'+id)
      .then(res => {
          setData({...data, 
              OB1: res.data.Result[0].OB1,
              OB2: res.data.Result[0].OB2,
              OB3: res.data.Result[0].OB3,
              SJUK: res.data.Result[0].SJUK,
              SJOB1: res.data.Result[0].SJOB1,
              SJOB2: res.data.Result[0].SJOB2,
              SJOB3: res.data.Result[0].SJOB3,
          })
      })
      .catch(err => console.log(err));
    }, [])

    const handleSubmit = (event) => {
        event.preventDefault(); 
        axios.put('http://localhost:8800/updateovertime/' + id, data)
        .then(res => {
            if(res.data.Status === "Success"){
              navigate('/viewemp/' + id)
            }
        })
        .catch(err => console.log(err));

    }
  return (
    <div className='employee'>
      <div className="top">
        <div className="title">
        <h4>Update Salary</h4>
        </div>
        <div className="button">
            <Link to={`/viewemp/`+ id}  className='btn btn-info'>Back</Link>
        </div>
      </div>
      <div className="bottom">
        <form action="" className='row g-3' onSubmit={handleSubmit}>
            
            <div className="col-3">
                <label htmlFor="OB1" className='form-label'>OB1</label>
                <input type="number" className='form-control' id='inputLastName' placeholder='Enter OB1'
                onChange={e => setData({...data, OB1: e.target.value})} name='OB1' value={data.OB1}/>
            </div>
            <div className="col-3">
                <label htmlFor="OB2" className='form-label'>OB2</label>
                <input type="number" className='form-control' id='OB2' placeholder='Enter OB2'
                onChange={e => setData({...data, OB2: e.target.value})} name='OB2' value={data.OB2}/>
            </div>
            <div className="col-3">
                <label htmlFor="OB3" className='form-label'>OB3</label>
                <input type="number" className='form-control' id='inputLastName' placeholder='Enter OB3'
                onChange={e => setData({...data, OB3: e.target.value})} name='OB3' value={data.OB3}/>
            </div>
            <div className="col-3">
                <label htmlFor="SJUK" className='form-label'>SJUK</label>
                <input type="number" className='form-control' id='SJUK' placeholder='Enter SJUK'
                onChange={e => setData({...data, SJUK: e.target.value})} name='SJUK' value={data.SJUK}/>
            </div>
            <div className="col-3">
                <label htmlFor="SJOB1" className='form-label'>SJOB1</label>
                <input type="number" className='form-control' id='SJOB1' placeholder='Enter SJOB1'
                onChange={e => setData({...data, SJOB1: e.target.value})} name='SJOB1' value={data.SJOB1}/>
            </div>
            <div className="col-3">
                <label htmlFor="SJOB2" className='form-label'>SJOB2</label>
                <input type="number" className='form-control' id='SJOB2' placeholder='Enter SJOB2'
                onChange={e => setData({...data, SJOB2: e.target.value})} name='SJOB2' value={data.SJOB2}/>
            </div>
            <div className="col-3">
                <label htmlFor="SJOB3" className='form-label'>SJOB3</label>
                <input type="number" className='form-control' id='SJOB3' placeholder='Enter SJOB3'
                onChange={e => setData({...data, SJOB3: e.target.value})} name='SJOB3' value={data.SJOB3}/>
            </div>
            <div className="col-12">
                <button type='submit' className='btn btn-primary'>Update</button>
            </div>
        </form>
        </div>
    </div>
  )
}

export default Editovertime
