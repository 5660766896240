import "./style.scss";
import Login from './pages/login/Login';
import Register from './pages/register/Register';
import Navbar from "./components/navbar/Navbar";
import Sidebar from "./components/sidebar/Sidebar";
import Home from "./pages/home/Home";
import Employee from "./pages/employee/Employeelist";
import NewEmployee from "./pages/employee/NewEmployee";
import ViewEmployee from "./pages/employee/viewemp";
import { createBrowserRouter, RouterProvider, Outlet, Navigate } from "react-router-dom";
import { useContext } from 'react';
import { DarkModeContext } from './context/darkModeContext';
import { AuthContext } from './context/authContext';
import Timetable from './pages/timetable/Timetable';
import Tablelist from './pages/timetable/Tablelist';
import Editemployee from './pages/employee/Editemployee';
import Overtime from './pages/employee/Overtime';
import Editovertime from './pages/employee/Editovertime';
import Rating from './pages/employee/Rating';
import Ratelist from './pages/employee/Ratelist';
import Editrating from './pages/employee/Editrating';
import Jun from './pages/month/Jun';
import Jul from './pages/month/Jul';
import Aug from './pages/month/Aug';
import Report from './pages/timetable/Report';
import Taxing from './pages/timetable/Taxing';
import EditTax from "./pages/timetable/EditTax";
import Reports from "./pages/timetable/Reports";
import Timetables from "./pages/timetable/Timetables";
import Monthreport from "./pages/timetable/Monthreport";
import Overview from "./pages/timetable/Overview";


function App() {
  const currentUser = useContext(AuthContext);
  const {darkMode} = useContext(DarkModeContext);
  const Layout = () => {

    return(
      <div className={`theme-${darkMode ? "dark" : "light"}`}>
        <Navbar />
        <div style={{display: "flex"}}>
            <Sidebar/>
            <div style={{flex: 6 }}>
               <Outlet />
            </div>
        </div>
      </div>
    );
  };

  const ProtectedRoute = ({children}) => {
    if(!currentUser){
      return <Navigate to="/login" />
    }
    return children
  }
  const router = createBrowserRouter([
    {
      path:"/",
      element: <ProtectedRoute> <Layout /> </ProtectedRoute>,
      children: [
        {
          path: "/",
          element: <Home />,
        },
        {
          path: "/employee",
          element: <Employee />,
        },
        {
          path: "/viewemp/:id",
          element: <ViewEmployee />,
        },
        {
          path: "/editemp/:id",
          element: <Editemployee />,
        },
        {
          path: "/create",
          element: <NewEmployee />,
        },
        {
          path: "/overtime/:id",
          element: <Overtime />,
        },
        {
          path: "/rate/userid/:id",
          element: <Rating />,
        },
        {
          path: "/rating",
          element: <Ratelist />,
        },
        {
          path: "/updaterate/:id",
          element: <Editrating />,
        },
        {
          path: "/editovertime/:id",
          element: <Editovertime />,
        },
        {
          path: "/timetable/:id",
          element: <Timetable />,
        },
        {
          path: "/tablelist/",
          element: <Tablelist />,
        },
        {
          path: "/timetables/:id",
          element: <Timetables />,
        },
        {
          path: "/overview",
          element: <Overview />,
        },
        {
          path: "/report/:id",
          element: <Report />,
        },
        {
          path: "/reports",
          element: <Reports />,
        },
        {
          path: "/monthreport/:id",
          element: <Monthreport />,
        },
        {
          path: "/Jun/:id",
          element: <Jun />,
        },
        {
          path: "/Jul/:id",
          element: <Jul />,
        },
        {
          path: "/Aug/:id",
          element: <Aug />,
        },
        {
          path: "/taxing",
          element: <Taxing />,
        },
        {
          path: "/updatetax/:id",
          element: <EditTax />,
        },
      ]
    },
    {
      path: "/login",
      element: <Login />,
    },
    {
      path: "/register",
      element: <Register />,
    },
  ]);


  return (
   <div>
      <RouterProvider router={router} />
   </div>
  );
}

export default App;
