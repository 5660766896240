import { useEffect, useState } from "react";
import "./timetable.scss";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import WatchLaterIcon from '@mui/icons-material/WatchLater';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import axios from 'axios'
import { Link } from "react-router-dom"

function Tablelist () {
  const [data, setData] = useState([])
  axios.defaults.withCredentials = true;
  
  useEffect(() => {
    axios.get('http://localhost:8800/getEmployees')
    .then(res => {
      if(res.data.Status === "Success"){
        setData(res.data.Result);
      }else{
        alert("Error")
      }
    })
    .catch(err => console.log(err));
  }, []);

  return (
    <div className="employee">
      <div className="top">
        <div className="title">
        <h4>Timetable List</h4>
        </div>
        <div className="button">
            <Link to="/overview" className='btn btn-primary btn-sm me-2'><RemoveRedEyeIcon /> Overview</Link>
        </div>
      </div>
      <div className="bottom">
        <table className='table'>
            <thead>
              <tr className="tr">
                <th>ID</th>
                <th>Full Name</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
          {data.map((employee, index) =>{
            return <tr key={index}>
                  <td>{employee.id}</td>
                  <td>{employee.firstname} {employee.lastname}</td>
                  <td>
                      {employee.stype === "Month" ? <Link to={`/timetables/` + employee.id} className='btn btn-color btn-sm me-2'><CalendarMonthIcon className="icon"/>TimeTable</Link> : <Link to={`/timetable/` + employee.id} className='btn btn-color btn-sm me-2'><WatchLaterIcon className="icon"/>TimeTable</Link>}
                  </td>
                </tr>
              })}
          </tbody>
        </table>
      </div>
      
    </div>
  )
}
export default Tablelist

