import "./sidebar.scss";
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import BarChartIcon from '@mui/icons-material/BarChart';
import LogoutIcon from '@mui/icons-material/Logout';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import { Link, useNavigate } from "react-router-dom";
import InsightsIcon from '@mui/icons-material/Insights';
import axios from "axios";

function Sidebar () {

    const navigate = useNavigate()
    
    const handleLogout = () => {
		axios.get('http://localhost:8800/logout')
		.then(res => {
			navigate('/login')
		}).catch(err => console.log(err));
	}
  return (
    <div className="sidebar">
        <div className="center">
            <ul>
                <p className="title">Users</p>
                <Link to="/employee" style={{textDecoration: "none"}}>
                <li>
                    <PeopleAltIcon className="icon"/>
                    <span>Employee List</span>
                </li>
                </Link>
                <p className="title">SERVICE</p>
                <Link to="/rating" style={{textDecoration: "none"}}>
                    <li>
                        <TrendingUpIcon className="icon"/>
                        <span>Rating List</span>
                    </li>
                </Link>
                <Link to="/taxing" style={{textDecoration: "none"}}>
                    <li>
                        <InsightsIcon className="icon"/>
                        <span>Taxing</span>
                    </li>
                </Link>
                <Link to="/tablelist" style={{textDecoration: "none"}}>
                    <li>
                        <EventAvailableIcon className="icon"/>
                        <span>Time Table</span>
                    </li>
                </Link>
                <Link to="/reports" style={{textDecoration: "none"}}>
                    <li>
                        <BarChartIcon className="icon"/>
                        <span>Reports</span>
                    </li>
                </Link>
                <p className="title">USER</p>
                
                <li onClick={handleLogout}>
                    <LogoutIcon className="icon"/>
                    <span>Logout</span>
                </li>
            </ul>
        </div>
</div>
  )
}

export default Sidebar
