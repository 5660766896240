import { useEffect, useState } from "react";
import "./employee.scss";
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import AddIcon from '@mui/icons-material/Add';
import axios from 'axios'
import { Link } from "react-router-dom"

function Ratelist () {
  const [data, setData] = useState([])
  axios.defaults.withCredentials = true;
  useEffect(() => {
    axios.get('http://localhost:8800/getEmployees')
    .then(res => {
      if(res.data.Status === "Success"){
        setData(res.data.Result);
      }else{
        alert("Error")
      }
    })
    .catch(err => console.log(err));
  }, []);


return (
  <div className="employee">
    <div className="top">
        <div className="title">
        <h4>Rating List</h4>
        </div>
        <div className="button">
             <Link to="/overview" className='btn btn-primary btn-sm me-2'><RemoveRedEyeIcon /> Overview</Link>
        </div>
      </div>
    <div className="bottom">
      <table className='table'>
          <thead>
            <tr className="tr">
              <th>ID</th>
              <th>Full Name</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
        {data.map((employee, index) =>{
          return <tr key={index}>
                <td>{employee.id}</td>
                <td>{employee.firstname} {employee.lastname}</td>
                <td>
                  <Link to={`/rate/userid/` + employee.id} className='btn btn-color btn-sm me-2'><AddIcon/> Rating</Link>
                </td>
              </tr>
            })}
        </tbody>
      </table>
    </div>
    
  </div>
)
}
export default Ratelist
