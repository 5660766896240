import React, { useState } from 'react';
import './login.scss';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

function Login() {

  const [values, setValues] = useState({
      email: '',
      password: ''
  })
  const navigate = useNavigate()
  axios.defaults.withCredentials = true;
  const [error, setError] = useState('')

  const handleSubmit = (event) => {
      event.preventDefault();
      axios.post('https://aaaa.psmile.xyz/api/login', values)
      .then(res => {
          if(res.data.Status === 'Success') {
              navigate('/employee');
          } else {
              setError(res.data.Error);
          }
      })
      .catch(err => console.log(err));
  }
  return (
    <div className="login">
      <div className="card">
        <div className="left">
          <h1>Avvikelse Analys</h1>
          <p>Time Table & Budget Managment System</p>
        </div>
        <div className="right">
          <h1>Login</h1>
          <form onSubmit={handleSubmit}>
          <input type="email" placeholder='Enter Email' name='email' onChange={e => setValues({...values, email: e.target.value})} />
            <input type="password" placeholder='Enter Password' name='password' onChange={e => setValues({...values, password: e.target.value})} />
            {error && error}
            <button type='submit'>Login</button>
          </form>
        </div>
      </div>
    </div>
  )
}

export default Login
